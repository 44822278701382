.wrapper-1HJDy {
	display: flex;
}

.left-2x4OC {
	justify-content: flex-start;
}

.center-434dG {
	justify-content: center;
}

.right-2ARJf {
	justify-content: flex-end;
}

button.button-hkoUO,
button.backButton-9qbUF {
	min-width: 160px;
}

button.backButton-9qbUF:not(:last-child) {
	margin-right: 12px;
}

@media screen and (max-width: 600px) {
	button.button-hkoUO {
		width: 100%;
	}
}
