

div.paper-1ckg1 {
	background: var(--cart-background);
}

.additional-Y_997 {
	display: flex;
	align-items: center;

	height: 100%;

	font-size: 18px;
	font-weight: 500;
	color: #ffffff;
}
