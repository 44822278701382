.button-3WZAs {
	display: flex;

	padding: 4px 4px 4px 12px;

	border: none;
	border-radius: 100px;

	color: var(--navigation-edit-color);

	font: var(--medium-desktop-accent);

	white-space: nowrap;

	cursor: pointer;
	background-color: var(--navigation-edit-background-color);
}

.button__icon-k8GVV {
	display: inline-flex;
	align-items: center;

	color: var(--navigation-edit-icon-color);

	transition: transform 0.15s;
}

.button__icon_reversed-pOOb1 {
	transform: rotate(-180deg);
}

@media screen and (max-width: 600px) {
	.button-3WZAs {
		padding: 0;
		padding: var(--navigation-edit-mobile-icon-padding, 0);

		background-color: initial;

		background-color: var(--navigation-edit-mobile-background-color, initial);
	}

	.button__text-1Bj_h {
		display: none;
	}

	.button__icon_reversed-pOOb1 {
		transform: none;
		transform: initial;
	}
}
