

.container-25BmM {
	position: relative;

	width: 100%;
	padding-right: 30px;
	padding-left: 28px;
}

.slider-1Hl9h {
	max-width: 100%;
	width: 100%;
	min-width: 0;
	overflow: hidden;
}

.inside-2uFEi {
	position: relative;
	left: 0;

	display: inline-flex;
	min-width: 100%;
}

.inside_transition-GoMwh {
	transition: 0.3s left ease-in;
}

.elementsWrapper-1wwXj {
	display: flex;
	min-width: 100%;
}

.arrow-2VBbc:after {
	content: '';

	position: absolute;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
}

.arrow_next-oCLAR,
.arrow_prev-2YONd {
	position: absolute;
	top: 0;

	width: 26px;
	height: 100%;

	cursor: pointer;
	background: #cccccc;
}

.arrow_next-oCLAR {
	position: absolute;
	right: 0;
}

.hide-44-cv {
	display: none;
}

.arrow_next-oCLAR:after {
	transform: rotate(-180deg);
}

.arrow_prev-2YONd {
	position: absolute;
	left: 0;
}

.disabled-AIdqR {
	opacity: 0.5;
	cursor: default;
}
