

.timer-2vhMo {
	font-size: 15px;
	color: var(--common-gray);
}

.sliding-22OFA {
	font-size: 13px;
	line-height: 24px;

	animation: slideUp-2RLJ5 0.3s ease;
}

.link-3hvuT {
	margin-left: 3px;

	color: var(--cabinet-action);

	cursor: pointer;
}

.sent-1NJGy {
	display: flex;
	align-items: center;

	color: var(--common-green);
}

.sent-1NJGy svg {
	margin-right: 8px;
}

.wait-cHHxb {
	display: flex;
	align-items: center;
}

.wait-cHHxb svg {
	margin-right: 8px;
}

.wait-cHHxb span {
	margin: 0 4px;
}

@keyframes slideUp-2RLJ5 {
	from {
		transform: translateY(10px);
		opacity: 0;
	}

	to {
		transform: translateY(0);
		opacity: 1;
	}
}
