.month-R2lBj {

	padding-right: 15px;
}

.week-2TXIq {
	margin-top: 4px;
	display: flex;
}

.week_withPrice-2pAV7 {
	margin-top: 0;
}

@media (max-width: 1024px) {
	.month-R2lBj {
		padding-right: 0;
	}
}
