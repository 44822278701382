.tabs__segmentGroupWrapper-29QEI {
	display: flex;
	padding: 22px 40px;
}

.tabs__header-1LN73,
.tabs__value-22VSJ {
	margin: 0;
}

.tabs__segment-2Mxmg .tabs__header-1LN73 {
	margin-bottom: 5px;

	color: var(--text-mid);
	font-weight: 400;
	font-size: 14px;
}

.tabs__segment-2Mxmg .tabs__value-22VSJ {
	font-size: 22px;
	font-weight: 700;
	color: var(--text-base);
}

.tabs__segment-2Mxmg.tabs__segmentDate-3QusX {
	position: relative;

	display: flex;
	align-items: center;

	margin-right: 24px;
	padding-right: 24px;

	border-right: 1px solid var(--gray-line);
}

.tabs__logo-2Q8hE {
	display: inline-block;
	width: 33px;
	height: 33px;
	margin-right: 24px;
}

.tabs__segment-2Mxmg.tabs__segmentRoute-2EJqi {
	position: relative;

	flex: 1;
	display: flex;
	height: 100%;
	justify-content: flex-end;
}

.tabs__timeWrapper-tQM7k {
	margin: 3px 17px 0;

	text-align: center;
	font-size: 14px;
}

.tabs__timeWrapper-tQM7k .tabs__timeInRoute-_WMVf {
	width: 57px;

	color: var(--text-mid);
	font-weight: 700;

	transform: translate(32px, 11px);
	background: var(--white);
}

.tabs__timeWrapper-tQM7k .tabs__timeLine-2npCa {
	position: relative;
	z-index: -1;

	width: 120px;
	height: 1px;
	margin: 3px 0 6px;

	background: var(--text-pale);
}

.tabs__timeWrapper-tQM7k .tabs__timeLine-2npCa:before,
.tabs__timeWrapper-tQM7k .tabs__timeLine-2npCa:after {
	position: absolute;
	top: -6px;

	width: 10px;
	height: 10px;

	border: 1.5px solid var(--text-pale);
	border-radius: 50%;

	background: var(--white);
	content: '';
}

.tabs__timeWrapper-tQM7k .tabs__timeLine-2npCa:before {
	left: 0;
}

.tabs__timeWrapper-tQM7k .tabs__timeLine-2npCa:after {
	right: 0;
}

.tab__airline_detail-iFqdp {
	display: flex;
	width: 100%;
	justify-content: center;
	align-items: center;
}

.tab__airline_name-IYnFf {
	display: flex;
	grid-column-gap: 5.5px;
	align-items: center;
	margin-right: 12px;
	padding-right: 12px;

	border-right: 1px solid var(--gray-line);

	color: var(--text-light);
	font-size: 13px;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
}

.tab__airline_name-IYnFf svg {
	flex-shrink: 0;

	color: var(--text-pale);
}

.tab__airline_name-IYnFf > div {
	text-overflow: ellipsis;
	overflow: hidden;
}

@media (max-width: 1024px) {
	.tab__container-1dwWg {
		padding: 12px 12px 24px 12px;
	}

	.tab__airline-1La9X {
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.tab__airline-1La9X img {
		height: 72px;
		width: auto;
		margin: 24px 0;
	}

	.tabs__segmentGroupWrapper_mobile-1HABm {
		padding: 12px;

		border-radius: 4px;

		background: #ffffff;
		box-shadow: 4px 4px 29px rgba(0, 0, 0, 0.09);
	}

	.tabs__segmentGroupWrapper_mobile-1HABm .tabs__header-1LN73 {
		padding-bottom: 12px;
		margin-bottom: 12px;

		border-bottom: 1px solid var(--gray-line);
	}

	.tabs__segmentGroupWrapper_mobile-1HABm .tabs__header-1LN73,
	.tabs__segmentGroupWrapper_mobile-1HABm .tabs__header-1LN73 .tabs__headerWrapper-3WGC1 {
		display: flex;
		align-items: center;
	}

	.tabs__segmentGroupWrapper_mobile-1HABm .tabs__header-1LN73 .tabs__headerWrapper-3WGC1 {
		margin-bottom: 4px;
	}

	.tabs__segmentGroupWrapper_mobile-1HABm .tabs__header-1LN73 .tabs__value-22VSJ {
		font-size: 13px;
		color: var(--text-mid);
	}

	.tabs__segmentGroupWrapper_mobile-1HABm .tabs__header-1LN73 .tabs__headerWrapper-3WGC1 .tabs__value-22VSJ {
		font-size: 16px;
		color: var(--text-base);
	}

	.tabs__segmentGroupWrapper_mobile-1HABm .tabs__header-1LN73 .tabs__segment-2Mxmg.tabs__segmentDate-3QusX {
		padding-right: 12px;
		margin-right: 12px;
	}

	.tabs__segmentGroupWrapper_mobile-1HABm .tabs__logo-2Q8hE {
		margin-right: 12px;
		width: 28px;
		height: 28px;
	}

	.tabs__segmentGroupWrapper_mobile-1HABm .tabs__segment-2Mxmg.tabs__segmentRoute-2EJqi {
		display: block;
	}
}
