

.autocomplete-3tKwp {
	position: relative;
}

.autocomplete-3tKwp > div {
	min-width: 100%;
	width: auto;
}

.input-2O9Gz {
	display: inline-block;
}

.options-1qaee {
	position: absolute;
	z-index: 1000;

	width: 100%;
	max-height: 200px;
	overflow-y: auto;
}

.group-1NtSC:not(:last-of-type) {
	padding-bottom: 8px;
	margin-bottom: 5px;

	border-bottom: 1px solid var(--line-separator);
}

.group__label-3MBgR {
	padding: 6px 16px 0;
	width: 100%;

	color: var(--autocomplete-group-label);
	font-weight: 500;
	font-size: 12px;
	font-size: var(--autocomplete-group-label-fz, 12px);
	line-height: 24px;
	text-transform: uppercase;
}

.group__options-sgXWJ {
	display: block;
}

li.option-3bMzX {
	display: flex;
	align-items: center;

	font-family: inherit;
}

label.autocomplete__root-14nyc {
	z-index: 2;

	width: 100%;

	color: #9a9a9a;
	font-family: inherit;
	font-size: 16px;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;

	transform: translate(10px, 30px) scale(1);

	pointer-events: none;
}

label.label_shrink-326fS {
	transform: translate(0, -1.5px) scale(0.75);
}

label.autocomplete__root-14nyc.focused-3lmhO:not(.label_error-1xAJR) {
	color: var(--passengers-input-bg);
}

div.input-2O9Gz {
	z-index: 1;

	font-family: inherit;
}

div.inner_input-3jsSi:before {
	content: '';

	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: -1;

	display: block;
}

.loader-r6VV8 {
	position: absolute;
	right: 0;
	top: 0;
	bottom: 0;

	margin: auto;
}

p.hint_error-2cus8 {
	position: absolute;
	bottom: 0;

	width: 100%;

	font-family: inherit;
	white-space: nowrap;
	text-overflow: ellipsis;

	transform: translateY(140%);
	overflow: hidden;
}

.input_focused-2POgt.inner_input-3jsSi:before {
	background: var(--input-background-active);
}

.input_focused-2POgt input {
	border-color: var(--base) !important;
}
