.container-3w1bJ {
	position: absolute;
	top: 0;
	left: 0;

	display: flex;
	align-items: center;
	width: 100%;
	height: 100%;
	padding: 14px 12px;

	font-size: 16px;
	line-height: 1.2;

	pointer-events: none;
}

.name-3qJO0 {
	text-align: center;
	color: var(--text-base);
	font-weight: 500;
}

.iata-c0fMc {
	margin-left: auto;
	padding-right: 24px;

	color: var(--text-light);
	font-weight: 500;
}

.loader-3UcED {
	margin-left: auto;
}

div.colorPrimary-2caV0 {
	color: var(--text-light);
}

@media screen and (max-width: 600px) {
	.container-3w1bJ {
		flex-direction: column-reverse;
		justify-content: center;
		padding: 14px 24px;
	}

	.name-3qJO0 {
		color: var(--text-mid);
		font-size: 13px;
		font-weight: 400;
		line-height: 1.3;
	}

	.iata-c0fMc {
		margin-bottom: 4px;
		margin-left: 0;

		color: var(--text-base);
		font-weight: 700;
		font-size: 20px;
		line-height: 1.3;
	}

	.placeholder-1wLAt {
		display: none;
	}
}
