.passengers-tsibJ {
	position: relative;

	flex-grow: 1;
}

.passengers_isMultiCity-2H-nm {
	flex-basis: 434px;
	flex-grow: 0;
}

.passengers_isMultiCity-2H-nm.passengers_withCurrency-342gK {
	border-right: 1px solid var(--line-separator);
}

@media screen and (max-width: 600px) {
	.passengers-tsibJ {
		width: 100%;
		margin-right: 0;
		margin-top: 4px;
	}

	.passengers_isMultiCity-2H-nm {
		margin-top: 4px;
	}
}

.selector-3wUyI {
	display: inline-flex;
	justify-content: space-between;
	align-items: center;

	cursor: pointer;
	overflow: hidden;
}

.selector_focused-3vc8N {
}

@media (min-width: 1024px) {
	div.tooltip__popper-1_Eo1 {
		white-space: nowrap;
	}

	div.tooltip-24NLW {
		max-width: -moz-fit-content;
		max-width: fit-content;
	}
}

.passengers_isMultiCity-2H-nm .selector-3wUyI {
	border-radius: 4px;
}

.passengers_withCurrency-342gK.passengers_isMultiCity-2H-nm .selector-3wUyI {
	border-radius: 4px 0 0 4px;
}

.arrowIcon-NlJ6k {
	display: inline-flex;
	justify-content: center;
	align-items: center;
	width: calc(12px * 2);
	height: calc(12px * 2);

	color: var(--text-pale);

	cursor: pointer;

	transition: transform 0.15s;

	pointer-events: none;
}

.arrowIcon_flipped-1_7dq {
	transform: rotate(180deg);
}

.arrowIcon_flipped-1_7dq svg path {
	fill: var(--light-gray);
}

@media screen and (max-width: 600px) {
	.arrowIcon-NlJ6k {
		display: none;
	}
}

.title-16osE {
	white-space: nowrap;
	overflow: hidden;

	text-overflow: ellipsis;

	pointer-events: none;
}

@media screen and (max-width: 600px) {
	.selector-3wUyI {
		width: 100%;
		padding-left: 8px;
	}

	.passengers_isMultiCity-2H-nm .selector-3wUyI {
		border-radius: 0;
	}
}

@media screen and (max-width: 600px) {
	.title-16osE {
		padding-left: 36px;
	}
}

.menu-3Eofd {
	position: absolute;
	z-index: 3;
	right: 0;

	min-width: 230px;
	margin-top: 4px;

	padding: 16px;

	border-radius: 2px;

	box-shadow: 0 1px 10px rgba(0, 0, 0, 0.15);

	background: var(--bg-white);
}

.list-100gj {
	padding: 0;
	margin: 0;

	background: red !important;
}

.item-1SqA3 {
	display: flex;
	justify-content: space-between;

	list-style-type: none;
}

.item-1SqA3:not(:first-of-type) {
	padding-top: 12px;
}

.item-1SqA3:not(:last-of-type) {
	padding-bottom: 12px;

	border-bottom: 1px solid var(--line-separator);
}

.controls-2bScv {
	display: flex;
	align-items: center;
}

.info-3gCdM {
	position: relative;

	display: block;
	margin-right: 10px;
}

.info__icon-ebqbI {
	margin-left: 4px;
	display: flex;
	align-items: center;
}

.passengerType-LfKiN {
	color: var(--text-base);
	font: var(--medium--1-desktop-accent);
}

.age-2AuEr {
	display: inline-flex;
	align-items: center;

	color: var(--text-light);
	font: var(--small-mobile);

	transition: color 0.15s;
}

.age-2AuEr:hover {
	cursor: help;
}

.increase-1HwJB,
.decrease-2RU5i {
	display: flex;
	justify-content: center;
	align-items: center;

	width: calc(12px * 2);
	height: calc(12px * 2);
	padding: 0;

	border: none;

	border-radius: 50%;

	cursor: pointer;

	background-color: var(--common-blue);

	transition: background-color 0.15s;

	appearance: none;
}

.increase-1HwJB svg,
.decrease-2RU5i svg {
	fill: #ffffff;
}

.increase-1HwJB:hover,
.decrease-2RU5i:hover {
	background-color: var(--glassy-common-blue);
}

.increase-1HwJB:disabled,
.decrease-2RU5i:disabled {
	background-color: #ededed;
	cursor: default;
}

.increase-1HwJB:disabled svg,
.decrease-2RU5i:disabled svg {
	fill: #cdcdcd;
}

.counter-34SuN {
	width: 38px;

	color: var(--brand-1-0);

	font-weight: 500;
	font-size: 18px;
	text-align: center;

	transition: color 0.15s;
}

.counter_disabled-3o8f4 {
	color: var(--text-pale);
}

.mobileMenu-2ZxLQ {
	font-size: 16px;
}

.mobileMenu-2ZxLQ .passengerType-LfKiN {
	font-size: 16px;
}

.mobileMenu-2ZxLQ .increase-1HwJB,
.mobileMenu-2ZxLQ .decrease-2RU5i {
	width: 32px;
	height: 32px;
}

.mobileMenu-2ZxLQ .info-3gCdM {
	flex-basis: 45%;
}

.mobileMenu-2ZxLQ .age-2AuEr:hover {
	color: var(--light-gray);

	cursor: text;
}

.mobileMenu-2ZxLQ .age-2AuEr:hover .info__icon-ebqbI path {
	cursor: text;

	fill: #cdcdcd;
}

.mobileMenu-2ZxLQ .info__icon-ebqbI {
	position: absolute;
	top: 50%;
	right: 0;

	margin-left: 0;

	transform: translateY(-50%);
}

.mobileTitle-3_gwD {
	margin: 0 0 16px 0;
	padding-bottom: 16px;

	border-bottom: 1px solid var(--line-separator);

	color: var(--text-base);
	font: var(--medium-1-desktop-bold);
}

.mobileContent-3Xfvc {
	display: flex;
	flex-direction: column;
	grid-gap: 20px;
	gap: 20px;
}

.modal__Title-1IrOR {
	flex-grow: 1;
}

/* stylelint-disable */
button.modal__closeButton-2az7a,
.modal__swipeBlock-wtqoX {
}

/* stylelint-enable */
.passengerIcon-3pbXt {
	position: absolute;

	display: flex;
	align-items: center;
}

/* stylelint-disable */
.button-3emFX {
	width: 100%;
}

/* stylelint-enable */
@media screen and (max-width: 600px) {
	.alternative-BXuCf .passengerIcon-3pbXt {
		display: none;
	}

	.alternative-BXuCf .title-16osE {
		padding: 0;
		width: 100%;

		text-align: center;
		font-size: 16px;
		font-weight: 600;
	}

	.alternative-BXuCf .selector-3wUyI {
		padding: 0;

		height: 56px;
	}
}

@media (max-width: 1024px) {
	.passengers_isMultiCity-2H-nm .selector-3wUyI {
		border-radius: 4px 0 0 4px;
	}

	.passengers_isMultiCity-2H-nm {
		flex-basis: auto;
	}
}
