.input-l5zcq:focus {
	position: relative;
	z-index: 1;

	background: transparent;
}

.input-l5zcq:focus:-ms-input-placeholder, .input_withoutPlaceholder-1uoVw:-ms-input-placeholder {
	opacity: 0;
}

.input-l5zcq:focus::placeholder,
.input_withoutPlaceholder-1uoVw::placeholder {
	opacity: 0;
}

.input_withoutPlaceholder-1uoVw {
	color: transparent;
}

.input_departure-dQsvg {
	padding-right: 42px;

	text-overflow: ellipsis;
}

.input_arrival-IwYl3 {
	text-overflow: ellipsis;
}

@media screen and (max-width: 600px) {
	.input-l5zcq {
		display: flex;
		flex-direction: column;
		padding-left: 44px;
		justify-content: space-evenly;
	}
}

.switcher-q2SIE {
	position: absolute;
	top: 50%;
	right: 12px;
	z-index: 1;

	display: flex;
	justify-content: center;
	align-items: center;
	width: 24px;
	height: 24px;
	padding: 0;

	border: none;

	color: var(--text-pale);

	outline: none;

	transform: translateY(-50%);
	cursor: pointer;
	background: var(--bg-fill);

	transition: all 0.15s;
}

.switcher-q2SIE:hover {
	opacity: 1;
}

.switcher_highlighted-3SHPg {
	opacity: 1;
}

@media screen and (max-width: 600px) {
	.switcher-q2SIE.switcher_alternative-TjxGk {
		right: 0;

		width: 32px;
		height: 32px;

		border-radius: 50%;

		color: var(--white);

		background: var(--common-blue);
		transform: translate(61%, -50%);
		opacity: 1;
	}
}

.switcher_highlighted-3SHPg svg path {
	fill: var(--common-blue);
}

.input_icon-2R8Zw {
	display: flex;
	align-items: center;
	justify-content: center;
}

@media screen and (max-width: 600px) {
	.autocomplete_alternative-evVvh {
		cursor: pointer;
	}

	.autocomplete_alternative-evVvh .input-l5zcq {
		padding: 0;

		font-size: 21px;
		font-weight: 600;
		color: var(--text-light);
	}

	.autocomplete_alternative-evVvh .input_withoutPlaceholder-1uoVw {
		color: transparent;
	}

	.autocomplete-3Qxxz:not(.autocomplete_alternative-evVvh):before {
		content: '';

		position: absolute;
		top: 50%;
		left: 8px;

		width: 24px;
		height: 24px;

		transform: translateY(-50%);
	}
}
