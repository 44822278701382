

.container-1Bov4 {
	position: relative;
}

div.notification-2lrt3:not(.notification__html-Ipxso) {
	width: 100%;
	height: 100%;
}

.notification-2lrt3 .notification__content-1xJ1_ {
	width: inherit;
	height: inherit;
}

.notification__html-Ipxso {
	overflow: hidden;
}

.notifications-2lDAU {
	display: flex;
	grid-gap: 5px;
	gap: 5px;
	flex-direction: column;
	align-items: center;
}
