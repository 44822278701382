

.disclaimer__wrp-JHX08 {
	margin-top: 20px;
}

.disclaimer_columnContent-VyMao {
	display: flex;
	flex-direction: column;
}

.disclaimer-YingW p {
	margin: 0;

	font-size: 12px;
	line-height: 18px;
	color: #292929;
}

.disclaimer__icon_info-1_A2I {
	background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyMCAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgb3BhY2l0eT0iMC43Ij4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xLjY2Njk5IDkuOTk5OTZDMS42NjY5OSA1LjM5OTk2IDUuNDAwMzMgMS42NjY2MyAxMC4wMDAzIDEuNjY2NjNDMTQuNjAwMyAxLjY2NjYzIDE4LjMzMzcgNS4zOTk5NiAxOC4zMzM3IDkuOTk5OTZDMTguMzMzNyAxNC42IDE0LjYwMDMgMTguMzMzMyAxMC4wMDAzIDE4LjMzMzNDNS40MDAzMyAxOC4zMzMzIDEuNjY2OTkgMTQuNiAxLjY2Njk5IDkuOTk5OTZaTTEwLjgzMzcgOS4xNjY2M1YxNC4xNjY2SDkuMTY2OTlWOS4xNjY2M0gxMC44MzM3Wk0xMC4wMDAzIDE2LjY2NjZDNi4zMjUzMiAxNi42NjY2IDMuMzMzNjUgMTMuNjc1IDMuMzMzNjUgOS45OTk5NkMzLjMzMzY1IDYuMzI0OTYgNi4zMjUzMiAzLjMzMzI5IDEwLjAwMDMgMy4zMzMyOUMxMy42NzUzIDMuMzMzMjkgMTYuNjY3IDYuMzI0OTYgMTYuNjY3IDkuOTk5OTZDMTYuNjY3IDEzLjY3NSAxMy42NzUzIDE2LjY2NjYgMTAuMDAwMyAxNi42NjY2Wk0xMC44MzM3IDUuODMzMjlWNy40OTk5Nkg5LjE2Njk5VjUuODMzMjlIMTAuODMzN1oiIAogICAgZmlsbD0iY3VycmVudENvbG9yIi8+CjwvZz4KPC9zdmc+Cg==) center no-repeat;
}
