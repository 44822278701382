.container-2nsnN {
	position: relative;
}

.title-1eimb {
	margin-bottom: 28px;

	color: var(--text-base);
	font-weight: 700;
	font-size: 18px;
	line-height: 1.3;
}

.locations-1zU7O {
	column-count: 2;
}

button.location-2337V {
	width: 100%;
	margin-bottom: 6px;
}

div.progress-36SJp {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
}

div.barColorPrimary-3FE9F {
	background: var(--results-loader-progress-background);
}

div.colorPrimary-2jcK- {
	background: var(--results-loader-progress-color);
}

@media screen and (max-width: 600px) {
	.locations-1zU7O {
		column-count: 1;
	}
}
