

.container-1mkCz {
	padding: 32px;
}

.header-1aV9o {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.header__text-34Kxi {
	font-size: 22px;
	font-weight: 700;
	color: var(--text-base);
}

.notification-1KJz6 {
	font-weight: 500;
}

.notification-1KJz6 svg {
	display: none;
}

button.closeButton-t76LW {
	width: 28px;
	height: 28px;
}

.description-3UB5J {
	font-size: 16px;
	color: var(--text-light);
	line-height: 130%;
}

.description-3UB5J,
.passengers-pchgf {
	margin: 16px 0;
}

.passengerType-2JT1H {
	font-weight: 700;
	color: var(--text-base);
}

.age-3MNw- {
	font-size: 12px;
	color: var(--text-light);
}

/* stylelint-disable */
.info__icon-1HLsz {
}

.item-2UnW7 {
}

/* stylelint-enable */
.buttons-3PLWI {
	width: 100%;
	margin-top: 16px;
}

.buttons-3PLWI button:not(:last-child) {
	margin-bottom: 16px;
}

button.newSearch-3cNWc,
button.continueButton-3zCxe {
	width: 100%;
}

button.increase-FuKnd,
button.decrease-1NMTE {
	position: relative;

	color: var(--brand-1-0);

	opacity: 1;
	background-color: var(--brand-1-5);
}

button.increase-FuKnd:disabled,
button.decrease-1NMTE:disabled {
	color: var(--line-dot-line);

	background-color: var(--bg-fill);
}

button.increase-FuKnd:not(:disabled):hover,
button.decrease-1NMTE:not(:disabled):hover {
	color: #ffffff;

	background-color: var(--brand-1-0);
}

div.counter-jimm3 {
	font-size: 16px;
	font-weight: 500;
	color: var(--text-base);
}
