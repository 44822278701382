.card-3YIx3 {
	max-width: 360px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	border-radius: var(--service-border-radius);

	background-color: #ffffff;
	box-shadow: 0 1px 8px rgba(0, 0, 0, 0.07);
}

.card_wide-3b4ZV {
	width: 100%;
	min-height: 210px;
	max-width: none;

	flex-direction: row-reverse;
}

.card-3YIx3.card_inLanding-1mH1Q {
	width: 100%;
	max-width: 350px;
	min-height: 410px;
}

.image-1MUj8 {
	width: 100%;
	height: 180px;

	border-radius: var(--order-header-border-radius);

	background-repeat: no-repeat;
	background-position: right center;
	background-size: cover;
}

.card_inLanding-1mH1Q .image-1MUj8 {
	height: 143px;
}

.card_wide-3b4ZV .image-1MUj8 {
	width: 450px;
	height: auto;
	flex-shrink: 0;

	border-radius: 0 4px 4px 0;

	background-size: cover;
}

.body-2ZRTe {
	flex-grow: 1;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.body__inner-2EVb3 {
	padding: 20px 24px;
	flex-grow: 1;
}

.card_inLanding-1mH1Q .body__inner-2EVb3 {
	padding: 22px 24px 22px;

	border-bottom: 1px dashed #d9d9d9;
}

.title-hZeqy {
	margin: 0 0 24px 0;

	color: var(--dark-gray);
	font-size: 20px;
	font-weight: 600;
	line-height: 23px;
}

.card_inLanding-1mH1Q .title-hZeqy {
	margin-bottom: 12px;

	font-size: 24px;
	font-weight: 500;
	line-height: 150%;
}

.text-1XLJ3 {
	font-size: 15px;
	font-weight: 400;
	line-height: 23px;
	text-align: justify;
}

@media screen and (max-width: 600px) {
	.card-3YIx3 {
		max-width: unset;
		overflow: hidden;

		border-radius: 8px 8px 0 0;
	}

	.card-3YIx3.card_inLanding-1mH1Q {
		max-width: unset;
	}
}
