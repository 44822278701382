

div.root-299kl {
	top: 30vh;
}

div.content-ATfIL {
	min-width: 100px;
}

/* stylelint-disable */

.message-39Xsd {
}
