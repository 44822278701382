.modal__root-LDcX- .modal__paper-1syjd {
	max-width: 800px;
	padding: 0;
}

@media (max-width: 1024px) {
	.modal__root-LDcX- .modal__paper-1syjd {
		height: 100%;
		max-height: none;
		max-height: initial;
	}
}
