.wrapper-2zgG7 {
	position: relative;

	min-width: 482px;

	padding: 30px 25px 40px;

	border-radius: 20px;

	background-color: var(--white);
}

.title-3UCa0 {
	margin-bottom: 30px;

	padding-right: 46px;

	color: #262626;
	font-weight: 700;
	font-size: 22px;
	line-height: 1.3;
}

.footer-1b6Fk {
	display: flex;
	justify-content: flex-end;
	margin-top: 24px;
}

.footer-1b6Fk button {
	margin-right: 12px;

	border-radius: 4px;
}

.footer-1b6Fk button:last-child {
	margin-right: 0;
}

/* stylelint-disable */
.input-2_Q1W {
}

.input_underline-3EVl- {
}

.cancel-1MJk7 {
}

.submit-3c3_v {
}

/* stylelint-enable */

@media screen and (max-width: 600px) {
	.wrapper-2zgG7 {
		min-width: auto;

		padding: 20px;

		border-radius: 20px 20px 0 0;
	}

	.title-3UCa0 {
		max-width: 90%;
	}
}
