.passenger-2tqjl {
	display: flex;
	justify-content: space-between;
}

.controls-2dQCf {
	display: flex;
	align-items: center;
}

.info-2-lO9 {
	position: relative;

	display: block;
	margin-right: 10px;
}

.info__icon-2DrJt {
	margin-left: 2px;
	display: flex;
	align-items: center;
}

.passengerType-bnh_F {
	color: var(--text-base);
	font: var(--medium--1-desktop-accent);
}

.age-3rUJY {
	display: inline-flex;
	align-items: center;

	color: var(--text-light);
	font: var(--small-mobile);

	transition: color 0.15s;
}

.age-3rUJY:hover {
	cursor: help;
}

button.control-3X3R6 {
	flex-shrink: 0;
}

button.increase-178VB,
button.decrease-2YAxO {
	display: flex;
	justify-content: center;
	align-items: center;

	width: calc(12px * 2);
	height: calc(12px * 2);
	padding: 0;

	border: none;

	border-radius: 50%;

	color: #ffffff;

	cursor: pointer;

	background: var(--brand-1-0);

	transition: background 0.15s;

	appearance: none;
}

.increase-178VB svg,
.decrease-2YAxO svg {
	fill: #ffffff;
}

button.increase-178VB:hover,
button.decrease-2YAxO:hover {
	background: var(--brand-1-1);
}

button.increase-178VB:disabled,
button.decrease-2YAxO:disabled {
	background: var(--bg-fill);
	cursor: default;
}

.increase-178VB:disabled path,
.decrease-2YAxO:disabled path {
	fill: var(--text-pale);
}

.counter-2AgaU {
	width: 38px;

	color: var(--brand-1-0);

	font-weight: 500;
	font-size: 18px;
	text-align: center;

	transition: color 0.15s;
}

.counter_disabled-2cv_B {
	color: var(--text-pale);
}

.description-1a8Gs {
	display: none;

	color: var(--text-light);
	font-size: 13px;
	font-weight: 500;
	line-height: 1.3;
}

@media screen and (max-width: 600px) {
	.passenger-2tqjl {
		font-size: 16px;
	}

	.passengerType-bnh_F {
		font-size: 16px;
	}

	button.increase-178VB,
	button.decrease-2YAxO {
		width: 32px;
		height: 32px;
	}

	.info-2-lO9 {
		flex-basis: 45%;
	}

	.age-3rUJY:hover {
		cursor: text;
	}
}
