.header-2dVLT {
	z-index: 11;

	position: absolute;
	top: 0;
	right: 0;
	left: 0;

	display: flex;
	align-items: center;
	height: 72px;

	background: var(--white);
	box-shadow: 0 7px 20px rgba(0, 0, 0, 0.03);
}

.header__wrapper-WzQl6 {
	display: flex;
	align-items: center;

	max-width: 1100px;
	width: 100%;

	margin: 0 auto;
}

.header__title-1T2I_ {
	display: flex;
	align-items: center;

	color: var(--text-base);

	font: var(--big--1-desktop-bold);
}

.back-2cCSC {
	display: none;
}

.back-2cCSC > svg {
	transform: rotate(180deg);
}

.close-QPtkk {
	z-index: 11;

	display: flex;
	align-items: center;

	margin-left: auto;

	cursor: pointer;
}

.close-QPtkk.close_fixed-1LIUs {
	position: fixed;
	top: 24px;
	right: 20px;
}

.close__text-1kXp1 {
	color: var(--brand-1-0);
	font: var(--small-desktop);
}

button.close__icon-1f5cS {
	position: relative;

	display: flex;
	flex-shrink: 0;

	margin-left: 12px;
}

.close__icon-1f5cS:before,
.close__icon-1f5cS:after {
	content: '';

	position: absolute;
	top: 50%;
	left: 50%;

	width: 50%;
	height: 2px;

	background: currentColor;
	transform: translate(-50%, -50%) rotate(45deg);
}

.close__icon-1f5cS:after {
	transform: translate(-50%, -50%) rotate(-45deg);
}

.content-14ZeU {
	margin-top: 72px;
	max-height: 100%;
	overflow: auto;
	padding: 20px;

	height: 100%;
}

.content-14ZeU.content_withoutOffset-jdxRE {
	margin-top: 0;
}

/* stylelint-disable */
div.withoutPadding-BqHcZ {
	padding-right: 0 !important; /* for .mui-fixes */
}

/* stylelint-enable */

@media screen and (max-width: 600px) {
	.header-2dVLT {
		height: 56px;
		padding: 0 12px;
	}

	.back-2cCSC {
		display: flex;
		align-items: center;
		justify-content: center;

		margin-right: 12px;

		color: var(--text-light);
	}

	.header__title-1T2I_ {
		font: var(--medium-desktop-bold);
	}

	.close-QPtkk.close_fixed-1LIUs {
		top: 20px;
	}

	.close__text-1kXp1 {
		display: none;
	}

	button.close__icon-1f5cS {
		margin: 0;

		color: var(--text-light);

		background: var(--bg-fill);
	}

	button.close__icon-1f5cS:hover {
		background: var(--bg-elements);
	}

	.content-14ZeU {
		margin-top: 56px;
	}

	.content-14ZeU.content_withoutOffset-jdxRE {
		margin-top: 0;
	}
}

@media (max-width: 1024px) and (min-width: 601px) {
	.header__wrapper-WzQl6 {
		margin: 0 20px;
	}
}
