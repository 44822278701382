

.root-SNZZV {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	min-width: 0;
	min-height: 26px;
}

.wrapper-3tZPQ {
	display: flex;
	align-items: center;
}

.minus-3e7Ox,
.plus-1qYzz {
	min-width: 22px;
	max-width: 22px;
	min-height: 22px;
	max-height: 22px;

	cursor: pointer;

	transition: opacity 0.1s linear;
}

.minus-3e7Ox svg,
.plus-1qYzz svg {
	display: block;
	width: 22px;
	height: 22px;
}

.minus-3e7Ox:hover,
.plus-1qYzz:hover {
	opacity: 0.8;
}

.value-2hjjr {
	position: static;

	margin: 0 13px;

	font-weight: 700;
	font-size: 20px;
	line-height: 26px;
	color: var(--gray80);

	transform: none;
}

.hidden-3-WGp {
	display: none;
}

.disabled-oKwk-.root-SNZZV {
	opacity: 0.6;
}

.disabled-oKwk- .minus-3e7Ox,
.disabled-oKwk- .plus-1qYzz {
	cursor: pointer;

	pointer-events: none;
}
