.container-1da-F {
	max-width: 1100px;
	margin: 12px auto;
}

.buttons-2KIhq {
	max-width: 1100px;
	margin: 0 auto;
	width: 100%;
}

button.next-ieZnZ {
	justify-content: flex-start;
	width: 100%;
	margin: 16px 0;
	padding: 12px 60px 12px 12px;

	border-radius: 4px;

	color: var(--text-link);
	font-weight: 400;
	font-size: 16px;
	text-align: left;

	background: var(--brand-1-5);
}

button.next-ieZnZ svg {
	position: absolute;
	right: 12px;
}

.seatsService-3P7jM span:not(:last-child):after {
	content: ', ';
}

div.hideServiceCard-1VbL3 {
	display: none;
}

div.paper-3tZ47 {
	max-width: 728px;
}

.aggregatorService__container-2qyly .serviceSummaryDivider-3ndGJ:last-of-type:after {
	display: none;
}

@media screen and (max-width: 600px) {
	.buttons-2KIhq {
		padding: 0 12px;
	}

	.wrapper-29Djy {
		background: #ffffff;
	}
}
