

.container-1PY1N {
	padding: 24px;

	border-radius: 4px;

	background-color: #ffffff;
	box-shadow: 0 1px 2px rgba(0, 0, 0, 0.08);

	transition: box-shadow 0.15s;
}

.container-1PY1N:hover {
	box-shadow: 0 1px 2px rgba(0, 0, 0, 0.08), 0 0 25px rgba(0, 0, 0, 0.12);
}

.title-17Jvq {
	margin: 0;

	font-size: 20px;
	line-height: 28px;
}

.info-3ZT5P {
	margin-top: 24px;
	display: flex;
	align-items: center;
}

.info__icon-1ktHq {
	display: flex;
	align-items: center;
	flex-shrink: 0;
}

.info__text-1SHv3 {
	margin: 0 0 0 20px;

	color: var(--common-gray);
	font-size: 14px;
	line-height: 20px;
	white-space: pre-wrap;
}

.ticket-1HFS4 {
	margin-top: 20px;
}
