.currency-34lvK {
	position: relative;

	flex-grow: 1;
	min-width: 112px;
}

.selector-hEo18 {
	display: inline-flex;
	align-items: center;

	cursor: pointer;
	overflow: hidden;
}

.selector-hEo18[disabled] {
	cursor: auto;
}

/* stylelint-disable */
.selector_disabled-iEhPo {
}
/* stylelint-enable */
.selector__inner-2OFI6 span:first-of-type:after {
	content: ' - ';
}

.dropdown-2vPJx {
	position: absolute;

	z-index: 10;

	width: 100%;
	margin-top: 2px;
	padding: 4px;

	border-radius: 4px;

	color: var(--brand-1-0);
	font-weight: 500;
	font-size: 15px;

	background: #ffffff;
}

.item-1i_V2 {
	padding: 12px;

	cursor: pointer;

	transition: background-color 0.2s;
}

.item-1i_V2:hover {
	background: var(--brand1-5);
}

.arrowIcon-x9Dmk {
	position: absolute;
	right: 12px;

	display: inline-flex;
	justify-content: center;
	align-items: center;
	width: calc(12px * 2);
	height: calc(12px * 2);

	color: #ffffff;

	cursor: pointer;

	transition: transform 0.15s;

	pointer-events: none;
}

.arrowIcon_flipped-2S8os {
	transform: rotate(180deg);
}

.selector-hEo18[disabled] .arrowIcon-x9Dmk {
	display: none;
}

@media screen and (max-width: 600px) {
	.dropdown-2vPJx {
		position: relative;
	}

	.selector-hEo18 {
		justify-content: center;

		font-size: 16px;
	}

	.selector-hEo18:focus {
		color: var(--text-base);
	}

	.currency-34lvK {
		margin-right: 0;
		margin-top: 4px;
	}
}
