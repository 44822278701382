

.root-2fghs .labelIn-BGRQ5:not(.withoutLabel-1In-B) .outlined-3vBhE .input-2MQSx {
	padding-top: 15px;
	padding-bottom: 0;
}

div.paper-PpKVE {
	box-shadow: 4px 7px 26px rgba(0, 0, 0, 0.1);
}

ul.listbox-2CdiO .option-2bZRg {
	padding-top: 10px;
	padding-bottom: 10px;
}

ul.listbox-2CdiO .option-2bZRg:not(:last-of-type) {
	border-bottom: 1px solid var(--bg-elements);
}

div.endAdornment-2saJy {
	top: 50%;

	transform: translate(0, -50%);
}

button.popupIndicator-1Uoz1 {
	padding: 2px;
}
