.container-1jbpQ {
	margin-top: 24px;
}

.disabilities-boNX_ {
	margin-top: 11px;
	padding: 28px;

	border-radius: 4px;

	color: var(--text-light);

	background: var(--bg-fill);
}

.disabilities_error-27bxM {
	border: 1px solid var(--error-stroke--hover);

	background: var(--error-bg);
}

.checkboxes-3YPfc {
	margin-top: 13px;
	display: grid;
	grid-template-columns: 1fr 1fr;
}

.disabilities__text-3-dLF {
	display: flex;
	grid-gap: 8px;
	gap: 8px;
}

.disabilities__text-3-dLF svg {
	color: var(--text-pale);
}

.disabilities__text-3-dLF > div:nth-child(2) {
	padding-top: 2px;
}

span.checkbox-tCv6S.checkbox_checked-3muQa {
	color: var(--brand-1-0);
}

span.checkbox-tCv6S:not(.checkbox_checked-3muQa) svg {
	color: var(--text-pale);
}

span.checkbox-tCv6S:not(.checkbox_checked-3muQa):before {
	position: absolute;

	width: 14px;
	height: 14px;

	background-color: var(--white);
	content: '';
}

span.checkbox_root-2Osfm.checkbox-tCv6S.checkbox_disabled-2Zv6Z + span {
	opacity: 0.8;
}

.switchWrapper-1fOhS {
	display: flex;
	grid-gap: 12px;
	gap: 12px;
	align-items: baseline;
}

.switchWrapper-1fOhS .icon-2b9UV {
	width: 40px;
}

.switchControl__errorText-2kJgq {
	color: var(--error);
	font-size: 12px;
}

@media screen and (max-width: 600px) {
	.checkboxes-3YPfc {
		grid-template-columns: 1fr;
	}

	.switchWrapper-1fOhS {
		flex-direction: column;
		grid-gap: 18px;
		gap: 18px;
	}

	div.switchControl-20CXM {
		margin-left: 12px;
		margin-bottom: 0;
	}

	.disabilities-boNX_ {
		width: calc(100% - 24px);

		margin: 12px auto 0;
		padding: 12px;
	}

	span.checkbox-tCv6S:not(.checkbox_checked-3muQa):before {
		background-color: inherit;
	}

	.switchControl__errorText-2kJgq {
		display: flex;
		align-items: center;
		margin-left: 12px;
	}
}
