

.container-1CUF3 {
	font-size: 14px;
	color: var(--text-base);
}

.group-kK5Z6:not(:last-child) {
	border-bottom: 1px solid var(--text-base);
}

.header-3Je_V {
	margin-bottom: 3px;

	color: var(--text-base);
	font-weight: 700;
	font-size: 14px;
}

.item-3Ih2c {
	padding: 12px 0;
}

.item__content-3IT1y {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.item-3Ih2c:not(:last-child) {
	border-bottom: 1px dashed var(--line-dot-line);
}

.group-kK5Z6:first-child .item-3Ih2c:first-child {
	padding-top: 0;
}
