.root-3Mhpr {
	color: var(--error);
	font-weight: 500;
}

.icon-1gDeC {
	color: var(--error);
}

.root-3Mhpr.account-1SXb1 {
	background: var(--errorBG);
}

@media screen and (max-width: 600px) {
	.root-3Mhpr {
		min-height: 36px;
	}

	.payload-1Xks6 {
		align-items: center;
	}
}
