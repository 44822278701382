.root-1NhKm {
	display: grid;

	grid-column-gap: 32px;
	grid-template: 'icon title' min-content
		'icon description' min-content
		/ 76px 1fr;

	align-items: center;

	padding: 20px 28px;
}

.icon-16_9t {
	grid-area: icon;
}

.title-1lqME {
	grid-area: title;

	font-size: 20px;
	font-weight: 700;
	line-height: 26px;
	color: var(--gray80);
}

.confirmedIcon-3WPF8 {
	position: relative;
	top: 6px;

	display: inline-flex;
	justify-content: center;
	align-items: center;
	width: 20px;
	height: 20px;
	margin-right: 12px;

	border-radius: 50%;

	color: #ffffff;

	background: var(--success);
}

.confirmedIcon-3WPF8 svg {
	transform: scale(0.6);
}

.description-_ZUGV {
	grid-area: description;

	white-space: pre-line;

	font-size: 16px;
	line-height: 21px;
	color: #595959;
}

.description-_ZUGV .warning-2Yssw {
	margin-top: 16px;

	color: var(--error-icon);
}

.description-_ZUGV a.rules-1aDFi {
	margin-top: 16px;
	display: block;

	font-size: 14px;
}

@media screen and (max-width: 600px), (max-width: 768px) {
	.root-1NhKm {
		grid-gap: 18px 20px;
		grid-template-areas: 'icon icon' 'title title' 'description description';

		padding: 22px 20px 27px;

		text-align: center;
	}

	.title-1lqME {
		font-size: 19px;
		line-height: 130%;
	}

	.description-_ZUGV {
		font-size: 15px;
		line-height: 140%;
	}
}
