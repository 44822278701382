

.root-1T3Qb {
	margin-bottom: 20px;
}

.ul-36FLQ {
	display: flex;
	justify-content: center;
}

.icon-2SE-Q {
	border-radius: 4px;

	color: var(--text-light);

	cursor: pointer;
	opacity: 1;

	pointer-events: all;
}

.icon-2SE-Q.icon-2SE-Q:disabled,
.icon-2SE-Q.icon-2SE-Q:disabled svg {
	cursor: not-allowed;
}

button.button-3Oki8 {
	display: flex;

	border-radius: 4px;

	color: var(--text-mid);

	opacity: 1;
	background: var(--bg-elements);

	transition: all 0.3s;
}

button.button-3Oki8:not(:last-child) {
	margin-right: 10px;
}

button.button-3Oki8:hover:not(:disabled) {
	background: var(--brand-1-3);
}

div.page-3oMem {
	border-radius: 4px;

	color: var(--text-mid);
	font-size: 14px;
	line-height: 2rem;

	background: var(--bg-white);
}

button.button-3Oki8.selected-2U3-A,
button.button-3Oki8.selected-2U3-A:hover {
	color: var(--bg-white);

	background: var(--brand-1-0);
}
