.wrapper-1fnGu {
	max-width: 1100px;
	margin: 0 auto;

	border-radius: 4px;

	color: #ffffff;

	background: var(--mobileSummaryFlight--background);
}

.wrapper-1fnGu.wrapper_business-pfQyo {
	background: linear-gradient(180deg, #6a86e6 0%, #3b56b2 100%);
}

.header-3SuMs {
	position: relative;

	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 20px;

	border-bottom: 1px dashed rgba(255, 255, 255, 0.5);
}

.header_withWeather-12EJu {
	padding: 20px 20px 34px;
}

.cities-1mLwG {
	display: flex;
	align-items: center;
}

.plane__icon-3qVY8 {
	margin: 0 20px;

	transform: rotate(180deg) translateY(-5px);
}

.city-6PZfJ {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}

.city__airport-3oGZm {
	margin-bottom: 5px;

	font-size: 14px;
	font-weight: 400;
	line-height: 16px;
}

.city__info-5J7_3 {
	font-size: 40px;
	line-height: 48px;
}

.city__iata-345lO {
	font-weight: 600;
}

.city__time-1Xzph {
	margin-left: 8px;

	font-weight: 300;
}

.city__weather-27821 {
	position: absolute;
	bottom: 10px;

	display: flex;
	align-items: center;

	font-size: 14px;
	line-height: 16px;
	font-weight: 400;
}

.weather__icon-1WRJG {
	display: flex;
	align-items: center;
	margin-right: 6px;
}

.flightInfo-3ZBIv {
	display: flex;
	align-items: center;
}

.flightInfo__item-ausF_ {
	display: flex;
	flex-direction: column;
}

.flightInfo__item-ausF_:last-child {
	margin-left: 61px;
}

.flightInfo__title-2bmjP {
	margin-bottom: 5px;

	font-size: 14px;
	line-height: 16px;
	font-weight: 400;
}

.flightInfo__value-3Tj4g {
	font-size: 40px;
	font-weight: 300;
	line-height: 48px;
}

.footer-2kjgv {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 20px;
}

.footer__airline-vx4aU {
	display: flex;
	align-items: center;
}

.airline__logo-2dy6Z {
	display: inline-block;
	height: 28px;
	width: 28px;
	margin-right: 12px;

	background-position: center;
	background-size: cover;
}

.airline__name-2Tz3d {
	font-size: 16px;
	line-height: 19px;
	text-transform: capitalize;
}

.footer__airline-vx4aU > span:first-child {
	display: flex;
	align-items: center;
}

.footer__item-1QBm- {
	font-size: 16px;
	font-weight: 400;
	line-height: 19px;
}

.footer__item-1QBm-:not(:last-child) {
	padding-right: 16px;
	margin-right: 16px;

	border-right: 1px solid rgba(255, 255, 255, 0.5);
}

.footer__items-2IZ3D {
	display: flex;
}

.footer__itemTitle-3N34D:after {
	content: ':';

	margin-right: 4px;
}

.footer__itemValue-3QWh7 {
	font-size: 16px;
}

@media (max-width: 1024px) {
	.footer-2kjgv {
		flex-direction: column;
		align-items: flex-start;
		padding: 0;
	}

	.footer__airline-vx4aU {
		justify-content: space-between;
		width: 100%;
		padding: 16px 20px;

		border-bottom: 1px dashed rgba(255, 255, 255, 0.5);
	}

	.footer-2kjgv > div:last-of-type {
		padding: 16px 20px;
		display: flex;
		width: 100%;
	}

	.airline__name-2Tz3d {
		font-size: 14px;
		line-height: 16px;
	}

	.footer-2kjgv .footer__item-1QBm- {
		display: flex;
		flex-direction: column;

		border-right: none;
	}

	.footer-2kjgv .footer__item-1QBm-:nth-child(2) {
		margin-right: auto;
	}

	.footer__item-1QBm- > span:first-child {
		margin-bottom: 9px;

		font-size: 14px;
		line-height: 16px;
	}

	.footer__item-1QBm- > span:last-child {
		font-size: 20px;
		line-height: 150%;
		font-weight: 500;
	}
}

@media screen and (max-width: 600px) {
	.footer__itemTitle-3N34D:after {
		display: none;
	}
}

@media screen and (max-width: 768px) {
	.cities-1mLwG {
		width: 100%;
		justify-content: space-between;
	}
}
