.download-3P2aO {
	display: flex;
	justify-content: space-between;
	max-width: 1100px;
	margin: 0 auto;
	margin-bottom: 12px;
	padding: 22px 20px;

	border-radius: 4px;

	background: linear-gradient(180deg, #ff6767 0%, #e44545 100%);
}

.logo-3Ju1X {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 5px 12px;

	border-radius: 7px;

	background: #ffffff;
}

@media screen and (max-width: 600px) {
	.logo-3Ju1X {
		padding: 5px 5px;
		width: 94px;
		height: 38px;
	}
}

.downloadIcon-3KK-G {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 24px;
	height: 24px;

	color: #ffffff;
}

button.button-1ohva {
	border-radius: 7px;

	background: rgba(0, 0, 0, 0.2);
}

button.button-1ohva:focus,
button.button-1ohva:active,
button.button-1ohva:hover {
	background: rgba(0, 0, 0, 0.1);
}
