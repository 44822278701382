.multiCity-32iPz {
	position: relative;
}

.label-2fJX5 {
	display: flex;

	cursor: pointer;
}

.caption-3Yyl4 {
	font-size: 14px;
	color: #ffffff;

	opacity: 0.7;
}

.input-1Ys4H {
	display: none;
}

@media screen and (max-width: 600px) {
	.multiCity-32iPz {
		line-height: 28px;
	}
}
