

.dialog-3VBQK {
	max-height: 100%;
	padding-top: 56px;

	box-shadow: none;
	transform: none;
}

.header-Cyvoq {
	position: fixed;
	top: 0;

	display: flex;
	align-items: center;
	width: 100%;
	height: 56px;

	border-bottom: 1px solid #d9d9d9;

	background-color: #ededed;

	transition: background-color 0.2s;
}

.header_white-9yXy5 {
	background-color: #ffffff;
}

.content-1Y7X- {
	height: 100%;
	width: 100%;

	overflow-y: auto;
	padding-top: 8px;
}

.input-2rgJC {
	padding-left: 20px;

	border: none;

	font-weight: 600;
	font-size: 24px;

	line-height: 34px;

	background: none;

	appearance: none;
}

.input-2rgJC:-ms-input-placeholder {
	color: #9a9a9a;
}

.input-2rgJC::placeholder {
	color: #9a9a9a;
}

.hint-VNgqZ {
	position: absolute;
	right: 52px;

	color: #9a9a9a;
	font-size: 12px;
	line-height: 56px;
	text-align: right;

	transition: opacity 0.2s;

	-webkit-user-select: none;

	    -ms-user-select: none;

	        user-select: none;
	pointer-events: none;
}

.hint_hidden-2AEhY {
	opacity: 0;
}

.closer-3ivAk {
	position: absolute;
	top: 50%;
	right: 12px;

	display: flex;

	transform: translateY(-50%);
}

/* stylelint-disable */
.modal-3ETF7 {
	z-index: 1501 !important; /* for .mui-fixed */

	padding-right: 0 !important; /* for .mui-fixes */
}

/* stylelint-enable */
