.container-1kMGt {
	position: relative;

	display: flex;
	flex-direction: column;
}

.header-Za6y7 {
	display: flex;

	grid-gap: 20px;

	gap: 20px;
	align-items: center;

	margin-bottom: 12px;
	padding: 8px 12px;

	border-radius: 4px 4px 0 0;

	background: var(--brand-1-5);
}

.info-gYem3 {
	flex: 0 1 50%;
	display: flex;
	align-items: center;
}

.date-75bMy {
	color: var(--text-mid);
	font-size: 16px;
	font-weight: 400;
	line-height: 1.3;
}

.direction-1ktEg {
	display: flex;
	flex-direction: column;
	margin-left: 20px;
	padding-left: 20px;

	border-left: 1px solid var(--line-separator);

	color: var(--text-base);
	font-weight: 700;
	font-size: 16px;
	line-height: 1.3;
}

.type-3BAIX {
	color: var(--text-mid);
	font-size: 14px;
	font-weight: 400;
	line-height: 1.3;
}

.prices-1XI9V {
	flex: 1;
	display: flex;
	align-items: center;
	grid-gap: 14px;
	gap: 14px;
}

.price-3m6Vf {
	flex: 1;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	color: var(--text-mid);
	font-size: 14px;
	font-weight: 400;
	line-height: 1.3;
}

.money-1sfQQ {
	padding-left: 0;

	color: var(--text-base);
	font-weight: 500;
	font-size: 16px;
	line-height: 1.3;
}

.passengers-20RgS {
	display: flex;
	flex-direction: column;
}

.passenger-3z05P:not(:last-child) {
	margin-bottom: 12px;
	padding-bottom: 12px;

	border-bottom: 1px dashed var(--line-separator);
}

.number-jAX6s {
	display: flex;
	justify-content: center;
	align-items: center;

	min-width: 24px;
	min-height: 24px;

	margin-right: 12px;

	border-radius: 50%;

	color: var(--text-light);
	font-weight: 500;
	font-size: 14px;
	line-height: 1.2;

	background: var(--bg-fill);
}

.label-2q96d {
	color: var(--text-base);
	font-size: 16px;
	font-weight: 400;
	line-height: 1.3;
}

.label-2q96d span:before {
	content: ', ';
}

@media (max-width: 1024px) {
	.info-gYem3 {
		flex: 0 1 35%;
		min-width: 35%;

		flex-direction: column;
		align-items: flex-start;
	}

	.direction-1ktEg {
		margin-left: 0;
		padding-left: 0;

		border: none;
	}
}

@media screen and (max-width: 600px) {
	.header-Za6y7 {
		grid-gap: 10px;
		gap: 10px;
	}

	.info-gYem3 {
		flex: 0 1 40%;
		min-width: 40%;
	}

	.prices-1XI9V {
		grid-gap: 8px;
		gap: 8px;
	}
}
