

.fields-2V52u {
	flex: 1;
	overflow: auto;
}

.controls-CHmtU {
	display: grid;
	grid-gap: 12px;
	grid-template-columns: 1fr 1fr;
	height: 65px;
	padding: 12px;

	box-shadow: 0 -7px 20px rgba(0, 0, 0, 0.03);
	background: #ffffff;
}

button.cancel-3QBTj,
button.cancel-3QBTj:hover {
	border-radius: 4px;

	color: var(--text-light);
	font-weight: 400;

	background: var(--bg-fill);
}

button.confirm-3D1DU,
button.confirm-3D1DU:hover {
	background: var(--brand-3-0);
}

button.confirm-3D1DU.disabled-1sQa2 {
	background: var(--brand-3-3);
}
