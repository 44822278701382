

.day-2DwOy {
	display: inline-block;
	width: 30px;
	height: 30px;

	line-height: 30px;
	text-align: center;

	cursor: pointer;
}

.dayOfWeekName-1p20A span {
	border-bottom: 1px dashed #656565;

	color: #656565;
	font-weight: 600;
}

.week-1J5TD {
	display: flex;
	justify-content: space-evenly;
}
