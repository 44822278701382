.root-1gAyJ {
	padding: 14px 0 20px;

	color: var(--white);

	background: var(--base);
}

.switch_wrapper-qVUmV {
	padding-right: 20px;

	text-align: right;
}

.switch_control-1151N:before {
	opacity: 1;
}

.switch_control-1151N:after {
	background: var(--gray90);
	opacity: 0.2;
}

.switch_label-8JYIA {
	margin-right: 4px;

	font-size: 18px;
	font-weight: 700;
	line-height: 30px;

	opacity: 1;
}

.switch_active-18GML:before {
	background: var(--base);
}

.switch_active-18GML:after {
	background: var(--white);
	opacity: 1;
}

.price_wrapper-3eXFW {
	display: flex;
	align-items: flex-end;
	justify-content: flex-end;

	margin-top: 21px;
	padding-right: 20px;
}

.price_label-gBGxa {
	margin-right: 4px;

	font-size: 18px;
	line-height: 1.2;
}

.price-3GSzW {
	font-size: 30px;
	font-weight: 700;
	line-height: 1;
}

@media screen and (max-width: 600px), (max-width: 768px) {
	.switch_label-8JYIA {
		font-size: 15px;
	}

	.price-3GSzW {
		font-size: 21px;
	}

	.root-1gAyJ {
		display: flex;

		padding: 10px 20px;
	}

	.switch_wrapper-qVUmV {
		padding-right: 0;
	}

	.switch_control-1151N {
		padding: 0;
	}

	.price_wrapper-3eXFW {
		margin-top: 0;
		margin-left: auto;

		padding-right: 0;
		padding-top: 8px;

		align-items: baseline;
	}
}
