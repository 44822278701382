.wrapper-XVect {
	position: relative;

	width: 78px;
	height: 78px;

	border-radius: 50%;

	color: var(--base);

	background-color: #eaf4ff;
}

.wrapper_pointer-1cejt {
	cursor: pointer;
}

.wrapper-XVect.active-2NiKm {
	color: var(--white);

	background-color: var(--base);
}

.wrapper-XVect > svg {
	position: absolute;
	top: 50%;
	left: 50%;

	width: 100%;
	height: 100%;

	transform: translate(-50%, -50%);

	pointer-events: none;
}

.selected-3KEwK {
	position: absolute;
	top: 0;
	right: 0;

	z-index: 2;

	display: flex;
	justify-content: center;
	align-items: center;
	width: 26px;
	height: 26px;

	border: 3px solid var(--white);
	border-radius: 50%;

	color: var(--white);

	transform: translate(10%, -10%);
	background-color: var(--success);
}

.selected-3KEwK > svg {
	width: 70%;
	height: 70%;

	pointer-events: none;
}

@media screen and (max-width: 600px) {
	.wrapper-XVect {
		width: 58px;
		height: 58px;
	}

	.selected-3KEwK {
		width: 22px;
		height: 22px;
	}
}
