.payments-2tmjX {
	display: flex;
	flex-direction: column;
	width: 100%;
}

.payments__title-2UIJi {
	margin-top: 0;
	margin-bottom: 20px;

	font-size: 24px;
	font-weight: 700;

	color: var(--text);
}

@media screen and (max-width: 600px) {
	.payments__title-2UIJi {
		margin-bottom: 10px;

		font-size: 22px;
	}
}

.payments__content-sPIBb {
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-wrap: wrap;

	padding: 24px;

	border-radius: 9px;

	box-shadow: 4px 4px 33px rgba(0, 0, 0, 0.05);

	background-color: var(--white);
}

@media screen and (max-width: 600px) {
	.payments__content-sPIBb {
		padding: 20px;
		grid-gap: 16px;
		gap: 16px;
	}
}

.payments__contentText-1zHD2 {
	font-size: 16px;
	font-weight: 400;
	line-height: 1.3;

	color: var(--text-mid);
}

button.payments__button-1tAVF {
	padding: 0 24px;
	height: 48px;

	font-size: 18px;

	background-color: var(--light-violet);
}

button.payments__button-1tAVF:hover {
	background-color: var(--bright-violet);
}
