

.popup-3S01g {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 100;

	display: none;

	background: #ffffff;
}

.visible-2NzL9.popup-3S01g {
	display: flex;
	flex-direction: column;
}
