.nasServicesRefund-3oI8v {
	max-width: 1100px;
	margin: 0 auto;
	display: flex;
	flex-direction: column;
	grid-gap: 8px;
	gap: 8px;
}

.nasServicesActions-2AwoK {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	margin-top: 20px;
	grid-gap: 20px;
	gap: 20px;
}

label.agree-3_HgP {
	flex: 1;
	margin: 0;
	max-width: none;

	display: flex;
}

.nasServicesActions-2AwoK button.confirm-UTe2T {
	height: 45px;
	min-height: 45px;

	font-size: 16px;
}

.nasServicesActions-2AwoK button.cancel-1mzGi {
	height: 41px;
	min-height: 41px;
}

.nasServices-1k7Od {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	grid-gap: 8px;
	gap: 8px;
}

span.checkbox-3Sh9r.active-1C6Jc {
	color: var(--base);
}

@media screen and (max-width: 600px) {
	.nasServices-1k7Od {
		grid-template-columns: 1fr;
	}

	.nasServicesActions-2AwoK {
		flex-direction: column;
	}

	.nasServicesActions-2AwoK button {
		flex: 1;
		width: 100%;
	}
}
