.wrapper--og0L {
	display: flex;
	align-items: center;
}

.wrapper--og0L:not(.wrapper_departure-1e-d3) {
	flex-direction: row-reverse;
}

.airport-2KmR- {
	margin: 0 16px;

	color: var(--text-mid);
	font-size: 14px;
}

.airport-2KmR-.airport_departure-LJlcI {
	text-align: right;
}

.airport-2KmR- div:first-of-type {
	margin-bottom: 3px;
}

.airport_terminal-3dNQd {
	white-space: nowrap;
}

.time-c0OJn {
	font-size: 42px;
	font-weight: 700;
	color: var(--text-base);
}

@media screen and (max-width: 600px) {
	.wrapper_mobile-b9WH_ {
		display: flex;
		align-items: center;
	}

	.wrapper_mobile-b9WH_:first-of-type {
		margin-bottom: 7px;
	}

	.wrapper_mobile-b9WH_ .airport-2KmR- {
		margin: 0 0 0 12px;

		font-size: 13px;
	}

	.wrapper_mobile-b9WH_ .time-c0OJn {
		font-size: 31px;
	}

	.wrapper_mobile-b9WH_ .plane-1vpI4 {
		margin-right: 14px;
		margin-left: 2px;
		margin-top: 3px;
	}

	.wrapper_mobile-b9WH_ .plane-1vpI4 svg {
		transform: scale(1.35);
	}

	.wrapper_mobile-b9WH_ .plane-1vpI4 svg path {
		fill: var(--text-pale);
	}
}
