.passengers-1foyw {
	position: relative;
	z-index: 1;

	max-width: 320px;

	opacity: 1;
}

.passengers_recommend-1B4hV {
	z-index: 1002;

	min-width: 320px;
}

.passengers_mobile-1hV6D {
	display: flex;
}

.passenger_fixed-r55G7 {
	position: fixed;
	top: 65px;
	z-index: 1;
}

.passenger_stickToBottom-CCi1f {
	top: auto;
	position: absolute;
	bottom: 20px;
}

.passenger-3GRJ9 {
	z-index: 1;

	display: flex;
	align-items: center;
	max-width: 375px;
	height: 48px;
	margin-top: 8px;
	padding: 0 40px 0 12px;

	border: 1px solid var(--seatMap-passenger-background);

	border-radius: 4px;

	color: var(--seatMap-passenger-color);
	font-weight: 500;
	font-size: 15px;
	line-height: 48px;

	cursor: pointer;
	background: var(--seatMap-passenger-background);

	transition: border-color 0.15s;
}

.passenger__name-1v_eY {
	flex-grow: 1;
}

.free-gzb1S {
	margin-left: 5px;

	font-size: 12px;
}

.passenger__name__value-2TKAt {
	display: block;
	max-width: 130px;
	overflow: hidden;

	white-space: nowrap;
	text-overflow: ellipsis;
}

.passenger__cancel-3KJ16 {
	position: absolute;
	right: 10px;
	z-index: 2;

	width: 24px;
	height: 24px;

	cursor: pointer;
	background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0yIDEyQzIgNi40NyA2LjQ3IDIgMTIgMkMxNy41MyAyIDIyIDYuNDcgMjIgMTJDMjIgMTcuNTMgMTcuNTMgMjIgMTIgMjJDNi40NyAyMiAyIDE3LjUzIDIgMTJaTTE1LjU5IDE3TDE3IDE1LjU5TDEzLjQxIDEyTDE3IDguNDFMMTUuNTkgN0wxMiAxMC41OUw4LjQxIDdMNyA4LjQxTDEwLjU5IDEyTDcgMTUuNTlMOC40MSAxN0wxMiAxMy40MUwxNS41OSAxN1oiIGZpbGw9IiM5QTlBOUEiLz4KPG1hc2sgaWQ9Im1hc2swIiBtYXNrLXR5cGU9ImFscGhhIiBtYXNrVW5pdHM9InVzZXJTcGFjZU9uVXNlIiB4PSIyIiB5PSIyIiB3aWR0aD0iMjAiIGhlaWdodD0iMjAiPgo8cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGNsaXAtcnVsZT0iZXZlbm9kZCIgZD0iTTIgMTJDMiA2LjQ3IDYuNDcgMiAxMiAyQzE3LjUzIDIgMjIgNi40NyAyMiAxMkMyMiAxNy41MyAxNy41MyAyMiAxMiAyMkM2LjQ3IDIyIDIgMTcuNTMgMiAxMlpNMTUuNTkgMTdMMTcgMTUuNTlMMTMuNDEgMTJMMTcgOC40MUwxNS41OSA3TDEyIDEwLjU5TDguNDEgN0w3IDguNDFMMTAuNTkgMTJMNyAxNS41OUw4LjQxIDE3TDEyIDEzLjQxTDE1LjU5IDE3WiIgZmlsbD0id2hpdGUiLz4KPC9tYXNrPgo8ZyBtYXNrPSJ1cmwoI21hc2swKSI+CjwvZz4KPC9zdmc+Cg==) center no-repeat;
}

.passenger__seat-2IeKR {
	margin-left: 5px;

	font-size: 15px;
	color: var(--seatMap-passenger-seat-color);
}

.passenger__price-1oiFb {
	font-size: 15px;
	color: var(--seatMap-passenger-price-color);
	font-weight: 600;
}

.passenger_selected-2P8BU {
	border: 1px solid var(--seatMap-passenger-border-selected);

	color: var(--seatMap-passenger-color-selected);

	background-color: var(--seatMap-passenger-background-selected);
}

.passenger_selected-2P8BU .passenger__price-1oiFb {
	color: var(--seatMap-passenger-price-color-selected);
}

.passenger_selected-2P8BU .passenger__seat-2IeKR {
	color: var(--seatMap-passenger-selected-seat-color);
}

span.disclaimer-1DixM {
	display: inherit;
	grid-gap: inherit;
	gap: inherit;
	flex-direction: inherit;
}

@media screen and (max-width: 600px) {
	.passenger__seat-2IeKR {
		margin-left: 0;
	}

	.passenger_selected-2P8BU .passenger__seat-2IeKR {
		color: var(--seatMap-passenger-seat-color-selected);
	}
}

@media (max-width: 768px) {
	.passengers-1foyw {
		max-width: 100%;
	}
}

.passenger_selected-2P8BU:before {
	position: absolute;
	right: 10px;

	width: 24px;
	height: 24px;

	background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTkuOTk5ODQgNkw4LjU4OTg0IDcuNDFMMTMuMTY5OCAxMkw4LjU4OTg0IDE2LjU5TDkuOTk5ODQgMThMMTUuOTk5OCAxMkw5Ljk5OTg0IDZaIiBmaWxsPSIjMzI5MEZGIi8+Cjwvc3ZnPgo=) center no-repeat;
	content: '';
}

.passenger__icon-1jwQb {
	display: none;
}

.passenger_withoutSeat-ZLlhK {
	display: none;
}

.passenger_selected-2P8BU .passenger__cancel-3KJ16 {
	display: none;
}

.passenger_with_seat-3JW1D.passenger_with_confirmed_seat-2ALfv .passenger__cancel-3KJ16 {
	display: none;
}

.passenger_with_seat-3JW1D:not(.passenger_with_confirmed_seat-2ALfv):hover .passenger__cancel-3KJ16 {
	display: block;
}

.passenger_with_seat-3JW1D:not(.passenger_with_confirmed_seat-2ALfv):hover:before {
	display: none;
}

.passengers__footer-3SW9D {
	width: 320px;
}

.passengers__footer-3SW9D > p {
	margin: 26px 0 0;

	font-weight: 300;
	font-size: 14px;
	line-height: 20px;
}

.passengers__buttons-2pm3t {
	margin-top: 20px;
	display: flex;
	justify-content: space-between;
}

button.passengers__button-AcyHU {
	width: 49%;

	height: 49px;
}

.passengers__button-AcyHU.passengers__button_clear-ukWwC,
.passengers__button-AcyHU.passengers__button_clear-ukWwC:hover {
	font-weight: 500;
}

.passengers__button-AcyHU.passengers__button_clear-ukWwC[disabled] {
	background: var(--half-transparent-gray);
}

button.passengers__button-AcyHU:only-child {
	width: 100%;
}

.info__icon-12Dbx {
	display: block;
	height: 20px;
	width: 20px;
	margin-bottom: 12px;

	background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyMCAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgb3BhY2l0eT0iMC43Ij4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xLjY2Njk5IDkuOTk5OTZDMS42NjY5OSA1LjM5OTk2IDUuNDAwMzMgMS42NjY2MyAxMC4wMDAzIDEuNjY2NjNDMTQuNjAwMyAxLjY2NjYzIDE4LjMzMzcgNS4zOTk5NiAxOC4zMzM3IDkuOTk5OTZDMTguMzMzNyAxNC42IDE0LjYwMDMgMTguMzMzMyAxMC4wMDAzIDE4LjMzMzNDNS40MDAzMyAxOC4zMzMzIDEuNjY2OTkgMTQuNiAxLjY2Njk5IDkuOTk5OTZaTTEwLjgzMzcgOS4xNjY2M1YxNC4xNjY2SDkuMTY2OTlWOS4xNjY2M0gxMC44MzM3Wk0xMC4wMDAzIDE2LjY2NjZDNi4zMjUzMiAxNi42NjY2IDMuMzMzNjUgMTMuNjc1IDMuMzMzNjUgOS45OTk5NkMzLjMzMzY1IDYuMzI0OTYgNi4zMjUzMiAzLjMzMzI5IDEwLjAwMDMgMy4zMzMyOUMxMy42NzUzIDMuMzMzMjkgMTYuNjY3IDYuMzI0OTYgMTYuNjY3IDkuOTk5OTZDMTYuNjY3IDEzLjY3NSAxMy42NzUzIDE2LjY2NjYgMTAuMDAwMyAxNi42NjY2Wk0xMC44MzM3IDUuODMzMjlWNy40OTk5Nkg5LjE2Njk5VjUuODMzMjlIMTAuODMzN1oiIAogICAgZmlsbD0iY3VycmVudENvbG9yIi8+CjwvZz4KPC9zdmc+Cg==) center no-repeat;
}

.disclaimer__paragraph-2TpPv {
	display: block;
}

.recommend-2FVXb {
	display: flex;
	justify-content: center;
	align-items: center;

	margin: 16px 0;

	color: var(--base);
	font-weight: 500;
	font-size: 16px;

	cursor: pointer;
}

.recommend__icon-f4aXF {
	margin-right: 8px;

	color: var(--active);
}

.recommend__wrapper-Nu8Z5 {
	margin-bottom: 20px;
	padding: 20px;

	border-radius: 4px;

	background: #ffffff;
}

.recommend__label-fXMlO {
	display: flex;
	align-items: center;
	margin-bottom: 7px;

	color: var(--gray50);
	font-size: 15px;
}

.recommend__label-fXMlO svg {
	margin-right: 8px;

	color: var(--active);
}

.together-1NT5g {
	margin-bottom: 16px;

	color: var(--gray80);
	font-weight: 500;
	font-size: 21px;
}

.recommend__price-1JGGH {
	margin-top: 16px;

	color: var(--gray80);
	font-weight: 700;
	font-size: 18px;
	text-align: right;
}

.passengers__doubleSeat-wE3Ts {
	margin: 16px 0;
}

.notifications-1JJkn {
	margin-top: 20px;
}

.notification-BBjpw {
	margin-bottom: 12px;
}

.notification-BBjpw:last-child {
	margin-bottom: 0;
}

@media screen and (max-width: 600px) {
	.passengers-1foyw {
		display: flex;
		justify-content: space-between;
		align-items: center;

		width: 100%;
		min-height: 56px;
		padding: 0 10px;

		border-bottom: 1px solid #d9d9d9;

		background-color: #ffffff;
	}

	.passengers__names-3-FXu {
		font-size: 14px;
	}

	.passenger-3GRJ9 {
		flex-direction: column;
		justify-content: center;
		width: unset;
		height: unset;
		margin-bottom: 0;
		margin-left: 10px;

		padding: 0;

		border: none;

		background: inherit;
	}

	.passenger_fixed-r55G7 {
		position: static;
	}

	.passenger__name-1v_eY {
		display: flex;
		margin-bottom: 5px;
		flex-grow: unset;

		color: #bababa;
	}

	.passenger__seat-2IeKR {
		display: flex;
		height: 15px;

		line-height: 1;
	}

	.passenger__cancel-3KJ16 {
		display: none;
	}

	.passenger_selected-2P8BU:before {
		display: none;
	}

	.passenger_selected-2P8BU svg path {
		fill: var(--common-blue);
	}
}

.slider-3W2__ {
	width: 100%;
}

.slider-3W2__ .slick-track {
	display: flex;
}

.slider-3W2__ .slick-list {
	padding-left: 28px;
}

.slider-3W2__ .slick-slide {
	height: inherit;
}

.slider-3W2__ .slick-slide > div {
	height: 100%;
	margin-right: 8px;
}

@media screen and (max-width: 600px) {
	.passenger__name-1v_eY {
		margin-bottom: 0;
	}

	.passengers_recommend-1B4hV {
		opacity: 0;

		pointer-events: none;
	}

	.passengers-1foyw {
		display: block;
		max-width: 100%;

		margin-top: 0;
		padding: 20px 0;

		border-bottom: none;
	}
}
