

.segment-1ip3s {
	display: flex;
	flex-direction: column;
}

label.label__root-2xUmS {
	padding: 8px 16px 8px 2px;
	margin: 0;

	border-radius: 4px;

	background: #f6f6f6;
}

span.label__label-AiS4y {
	display: flex;
	align-items: center;
	flex: 1;
}

span.checkbox-2FATp {
	margin-right: 4px;
}

span.checkbox-2FATp.checkbox__checked-AaWEx {
	color: var(--base);
}

.collapse-4MVvc span.checkbox-2FATp {
	display: none;
}

.collapse-4MVvc label.label__root-2xUmS {
	padding: 16px;
}

.date-3ta1X {
	margin-right: 8px;
	padding-right: 8px;

	border-right: 1px solid #d9d9d9;

	white-space: nowrap;
}

.date-3ta1X,
.info-1hN4z {
	font-size: 16px;
	font-weight: 700;
	line-height: 1.3;
}

.arrow-2Dr22 {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-left: auto;

	color: var(--gray30);

	transform: rotate(90deg);

	transition: transform 0.15s;
}

.arrow-2Dr22.arrow__collapse-20ww1 {
	transform: rotate(-90deg);
}

.wrapperInner-2Nbj_ {
	padding-top: 12px;
}
