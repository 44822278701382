

.container-10Ai- {
	display: flex;
	flex-direction: column;

	width: 100%;

	margin: 0 auto;
	padding: 22px 13px;

	border-bottom: 1px dashed var(--cart-service-border);

	counter-increment: cart-counter;
}

.disabled-2cLux {
	opacity: 0.6;

	pointer-events: none;
}

.header-2rccx {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.header-2rccx svg {
	color: var(--bg-white);
}

.header__title-3qtAl,
.header__price-3lBVY {
	color: var(--bg-white);
	font-weight: 700;
	font-size: 18px;
}

.header__title-3qtAl {
	flex: 1;
}

.header__price-3lBVY {
	margin-left: auto;
}

.header__icon-2DvwR {
	display: flex;
	align-items: center;
	justify-content: center;

	width: 24px;
	height: 24px;
}

.header__icon-2DvwR svg {
	width: 100%;
	height: 100%;
}

.header__icon-2DvwR,
.header__number-1m_5T {
	flex-shrink: 0;
	margin-right: 12px;
}

.header__number-1m_5T {
	position: relative;

	display: none; /* hidden by default, may be used in custom themes */
	width: 28px;
	height: 28px;

	border-radius: 50%;

	color: #ffffff;
	font-weight: 500;
	font-size: 13px;
	line-height: 28px;
	text-align: center;

	background: var(--brand-1--1);
}

.header__number-1m_5T:before {
	content: counter(cart-counter);

	color: currentColor;
}

.content-2qm-m {
	display: flex;
	flex-direction: column;

	margin-top: 12px;
	padding: 12px;

	border-radius: 4px;

	background: var(--bg-white);
}

.footer-3h1Wa {
	display: flex;
	align-items: center;
	justify-content: flex-end;
}

button.button-3Vt_b {
	display: flex;
}

button.button_add-2cJLr {
	color: var(--white);
}
