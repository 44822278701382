.content-5qzMT {
	padding: 28px;
}

.title-2joch {
	margin: 0 0 16px;

	color: var(--dark-gray);
	font-size: 22px;
	line-height: 31px;
}

.routes-5NV5J {
	display: flex;
}

.segment-1Mp2m {
	padding: 19px 0;
	display: flex;
	align-items: center;
}

.segment-1Mp2m:not(:last-of-type) {
	border-top: 1px solid #e4e4e4;
	border-bottom: 1px solid #e4e4e4;
}

.segment-1Mp2m:last-of-type {
	border-bottom: 1px solid #e4e4e4;
}

.iata-3Topn {
	line-height: 19px;
	font-weight: 600;
	color: #292929;
}

.cities-1Hj2l {
	margin-left: 10px;

	color: #727272;
	font-size: 15px;
	line-height: 18px;
}

.controls-3b632 {
	margin-left: auto;
}

@media screen and (max-width: 600px) {
	.content-5qzMT {
		padding: 20px;
	}

	.cities-1Hj2l {
		margin-left: 0;
	}

	.routes-5NV5J {
		flex-direction: column;
	}

	div.scrollBody-2F9NG {
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
	}

	div.paper-3q0xn.paper-3q0xn {
		min-height: auto;

		border-radius: 8px 8px 0 0;
	}
}
