.list-378Ae {
	padding: 0;
	margin: 0;
	max-width: 240px;
	min-width: 240px;
}

.item-10Lae {
	list-style-type: none;
}

.item_FNN-1fa-W {
	display: none;
}

.item-10Lae:before {
	display: none;
}

.item-10Lae:not(:first-of-type) {
	padding-top: 16px;
}

.item-10Lae:not(:last-of-type) {
	padding-bottom: 16px;

	border-bottom: 1px solid #d9d9d9;
}

.withGroupsWrapper-1hRs2 {
	display: flex;
	flex-direction: row;
	grid-gap: 16px;
	gap: 16px;
}

.withGroupsDelimiter-ntmj4 {
	width: 0;
	min-height: 100%;

	border-right: 1px dashed var(--line-separator);
}

.groupsWrapper-1tWXO,
.passengersWrapper-3Grfu {
	display: flex;
	flex-direction: column;
	grid-gap: 16px;
	gap: 16px;
}

.groupsTitle-28qsS,
.passengersTitle-3XVWl {
	color: var(--brand-1-0);
	font: var(--small-desktop-bold);
	text-transform: uppercase;
}

.fareGroups-2uZUK {
	display: flex;
	flex-direction: column;
	grid-gap: 0;
	gap: 0;
	align-items: flex-start;
}

button.fareGroup-3ci6a {
	padding: 12px 16px;

	font: var(--medium--1-desktop-accent);

	color: var(--text-base);
	white-space: nowrap;
}

button.currentGroup-27pFu,
button.currentGroup-27pFu:hover {
	padding: 12px 16px;

	background-color: var(--brand-1-5);
}

.slider-2zjsj {
	max-width: 100%;
}

@media screen and (max-width: 600px) {
	.withGroupsWrapper-1hRs2 {
		display: flex;
		flex-direction: column;
		grid-gap: 16px;
		gap: 16px;
	}

	.fareGroups-2uZUK {
		position: absolute;
		left: 0;
		right: 0;

		margin-top: 30px;
	}

	.withGroupsDelimiter-ntmj4 {
		margin-top: 40px;
	}

	button.fareGroup-3ci6a {
		border-radius: 40px;

		background: var(--bg-fill);
	}

	button.currentGroup-27pFu,
	button.currentGroup-27pFu:hover {
		color: var(--bg-white);

		background-color: var(--brand-1-0);
	}

	div:has(> button.fareGroup-3ci6a) {
		padding: 0 8px 0 8px;

		transform: translateX(20px);
	}

	div:has(> button.fareGroup-3ci6a):first-child {
		padding: 0 8px 0 0;
	}

	.list-378Ae {
		max-width: unset;
		min-width: unset;
	}
}
