

.wrapper-2uPlp {
	position: relative;

	display: inline-flex;
	align-items: center;
	justify-content: center;
}

.wrapper_handbag-3T2UX {
	display: inline-flex;
}

.background-3c2gR {
	display: flex;
	align-items: center;
	justify-content: center;

	color: var(--baggage-bag-weight-color);
}

.wrapper_included-1ZVbX .background-3c2gR {
	color: var(--baggage-bag-weight-color-included);
}

.weight-1ZEBS {
	position: absolute;

	margin-left: -1px;

	text-align: center;
	color: var(--baggage-bag-weight-color);

	-webkit-user-select: none;

	    -ms-user-select: none;

	        user-select: none;
}

.wrapper_included-1ZVbX .weight-1ZEBS {
	color: var(--baggage-bag-weight-color-included);
}

.wrapper_handbag-3T2UX .weight-1ZEBS {
	display: flex;
}

/* Weight block position, depending on bag size and type. */
.wrapper_handbag-3T2UX.wrapper_small-18zpg .weight-1ZEBS {
	bottom: 11px;

	line-height: 22px;
}

.wrapper_handbag-3T2UX.wrapper_medium-2AxWp .weight-1ZEBS,
.wrapper_handbag-3T2UX.wrapper_big-18A5K .weight-1ZEBS {
	bottom: 18px;

	line-height: 24px;
}

.wrapper_bag-1Ft7v.wrapper_small-18zpg .weight-1ZEBS {
	bottom: 16px;
}

.wrapper_bag-1Ft7v.wrapper_medium-2AxWp .weight-1ZEBS {
	bottom: 19px;
}

.wrapper_bag-1Ft7v.wrapper_big-18A5K .weight-1ZEBS {
	bottom: 24px;
}

/* Weight amount */
.weight__amount-1etyW {
	margin: 0;

	font-size: 20px;
	font-weight: 600;
	line-height: 90%;
}

.wrapper_handbag-3T2UX.wrapper_small-18zpg .weight__amount-1etyW {
	line-height: 22px;
}

.wrapper_handbag-3T2UX.wrapper_medium-2AxWp .weight__amount-1etyW,
.wrapper_handbag-3T2UX.wrapper_big-18A5K .weight__amount-1etyW {
	line-height: 24px;
}

/* Weight amount measurement */
.weight__measurement-1k1JA {
	margin: 0;

	font-size: 13px;
}

.wrapper_handbag-3T2UX .weight__measurement-1k1JA {
	position: relative;
	bottom: -2px;

	margin-left: 1px;

	font-size: 16px;
}
