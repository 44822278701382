button.container-2jyAM {
	display: inline-flex;
	height: 34px;
	padding: 0 12px 0 8px;

	border-radius: 4px;

	color: var(--text-light);
	font-size: 18px;

	background-color: var(--bg-fill);
}

button.container-2jyAM:hover {
	background-color: var(--bg-fill);
}

.icon-4Db5B {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 18px;
	min-width: 18px;
	height: 18px;
	margin-left: 10px;
}

.icon-4Db5B svg {
	width: 100%;
	height: 100%;
}

button.success-17yh-,
button.success-17yh-:hover {
	color: var(--success-icon);

	background: var(--success-bg);
}

button.success-17yh- .icon-4Db5B {
	color: var(--success-icon);
}

@media screen and (max-width: 600px) {
	.container-2jyAM {
		margin-top: 6px;
		margin-left: 0;
	}
}
