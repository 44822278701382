.toggle_active-27gr8:after {
	background: var(--success-icon);
}

.toggle__label-1ifMu,
.toggle_active-27gr8 .toggle__label-1ifMu {
	color: #ffffff;

	opacity: 0.7;
}

@media screen and (max-width: 600px) {
	.switchItem-3tCK3 {
		padding: 0;
	}
}
