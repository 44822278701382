.segments-2PlTg {
	flex-basis: 0;
	flex-grow: 1;
}

.segments_multiCity-3XWiR {
	flex-basis: 100%;
	margin-right: 0;
}

@media (max-width: 1024px) {
	.segments_multiCity-3XWiR {
		margin-bottom: 68px;
	}
}

@media screen and (max-width: 600px) {
	.segments-2PlTg {
		margin-right: 0;
		margin-bottom: 0;
	}
}

@media screen and (max-width: 600px) {
	.actions-2Z2kP {
		display: flex;
		margin-top: 6px;
	}

	button.addSegment-1SHnk,
	button.removeSegment-1rqvO {
		width: 100%;
		flex-grow: 1;
	}

	button.removeSegment-1rqvO {
		display: block;
		margin-left: 4px;
	}

	.alternative-3J875 .actions-2Z2kP {
		margin-top: 0;
	}

	.alternative-3J875 button.addSegment-1SHnk,
	.alternative-3J875 button.removeSegment-1rqvO {
		border-radius: 0;
	}
}
