

.wrapper-39vJZ {
	display: flex;
	flex-direction: column;
}

.name-1RMjX {
	color: var(--text-base);
	font-size: 16px;
	font-weight: 500;
	line-height: 1.3;

	text-transform: capitalize;
}

.item-1DKip {
	display: flex;
	align-items: center;
	justify-content: space-between;
	grid-gap: 20px;
	gap: 20px;
}

.item-1DKip:not(:last-child) {
	margin-bottom: 8px;
	padding-bottom: 8px;

	border-bottom: 1px dashed var(--line-separator);
}

button.button-QfMYX {
	grid-gap: 8px;
	gap: 8px;
}
