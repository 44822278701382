

button.segment-2Vzqp {
	position: relative;

	padding: 0 16px;
	height: 41px;
	min-width: unset;
	display: flex;
	align-items: center;

	border-radius: 100px;

	font-size: 16px;
	font-weight: 500;
	line-height: 120%;
	color: var(--text-mid);
	text-transform: none;
	letter-spacing: normal;
	white-space: nowrap;

	background-color: var(--bg-fill);

	transition: all 300ms;
}

button.segment-2Vzqp.segment_active-3Zipt {
	color: var(--white);

	background-color: var(--brand-1-0);
}

button.segment-2Vzqp:not(:last-child) {
	margin-right: 12px;
}

button.segment-2Vzqp.segment_disabled-1G0fH {
	opacity: 0.5;

	cursor: not-allowed;
}

.ellipse-3gKfB {
	display: flex;
	align-items: center;
	margin: 0 6px;

	color: var(--text-pale);
}

.segment_active-3Zipt .ellipse-3gKfB {
	color: var(--white);
}

.checked-1_cHZ {
	position: absolute;
	top: 0;
	left: 50%;
	z-index: 1;

	color: #5fca43;

	transform: translate(-50%, -50%);
}

.count-1aEGP {
	display: flex;
	justify-content: center;
	align-items: center;
	min-width: 21px;
	height: 21px;
	margin-left: 8px;
	padding: 2px;

	border-radius: 100px;

	color: var(--bg-white);
	font-weight: 700;
	font-size: 13px;
	line-height: 21px;

	background: var(--brand-1-0);
}

.segment_active-3Zipt .count-1aEGP {
	color: var(--brand-1-0);

	background: var(--bg-white);
}
