

button.button-1-Aet,
button.button-1-Aet:hover {
	display: inline-flex;
	align-items: center;
}

button.button-1-Aet:not(:last-child) {
	margin-right: 8px;
}
