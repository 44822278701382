.promoCode-39trj {
	color: var(--gray);
	font-weight: 500;
	font-size: 14px;
	line-height: 21px;
}

.promoCode-39trj a.trigger-1lPjr {
	color: inherit;
	text-decoration: none;
}

.content-3mf-M,
.contentWrp-33MO- {
	display: flex;
	align-items: baseline;
}

.promoCode_empty-kXoS_ a.trigger-1lPjr,
.promoCode_notApplied-2cZpe a.trigger-1lPjr {
	opacity: 0.8;
}

.promoCode-39trj .icon-31hIL {
	position: relative;
}

.content-3mf-M {
	position: relative;
}

.promoCode_applied-389mH .icon-31hIL {
	position: absolute;
	left: -30px;
	top: 50%;

	margin-right: 10px;

	color: var(--selected2);

	transform: scale(0.9) translate(0, -50%);
}

.clearWrapper-2f2-w {
	margin-right: 20px;
	margin-left: 12px;

	opacity: 0.8;
}

div.modal__root-2BfVX {
	z-index: 1600 !important;
}

div.modal-1sjoF {
	border-radius: 16px;
}

div.modal-1sjoF > span {
	transform: scale(0.7);
}

.closeIcon-TghkR {
	width: 32px;
	height: 32px;
}

a.clear-2ugWt {
	position: relative;

	display: inline-flex;
	align-items: center;

	color: #ffffff;
	font-weight: 500;
	font-size: 14px;
	text-decoration: none;
}

.icon_notApplied-13zpE {
	position: relative;
	top: 7px;

	color: var(--active);
}

.icon_notApplied-13zpE svg {
	transform: scale(0.7);
}

.clearIcon-28czE {
	position: absolute;
	left: 100%;
	top: 50%;

	transform: scale(0.8) translate(0, -53%);
}

button.closeButton-1AmZ4 {
	position: absolute;
	top: 16px;
	right: 16px;

	padding: 6px;

	color: var(--text-mid);

	background: var(--bg-fill);
}

button.closeButton-1AmZ4:hover {
	color: var(--text-base);
}

@media screen and (max-width: 600px) {
	div.scrollBody-jF13D {
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
	}

	div.scrollBody-jF13D div.modal-1sjoF {
		min-height: auto;

		border-radius: 16px 16px 0 0;
	}
}

@media screen and (max-width: 600px) {
	div.modal__scrollBody-3kbm1 {
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
	}

	div.modal__root-2BfVX div.modal-1sjoF {
		min-height: auto;
	}

	button.closeButton-1AmZ4 {
		top: 14px;
		right: 20px;
	}
}
