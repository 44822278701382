.card-3Acoc {
	display: grid;
	grid-gap: 20px;
	grid-template-columns: auto 1fr;
	padding: 24px;

	border-radius: 4px;

	color: var(--text-light);
	font-size: 14px;

	background-color: var(--white);
}

.card__icon-3h915 {
	width: 32px;
	height: 32px;

	color: var(--brand-1-0);
}

.card__group-2WbIh {
	display: grid;
	grid-template-columns: 1fr auto;
	grid-gap: 10px;
	gap: 10px;
}

.card__group_center-1afZu {
	align-items: center;
}

.card__title-DPME2 {
	margin: 0;

	color: var(--text-base);
	font-weight: 700;
	font-size: 22px;
	line-height: 130%;
}

.card__subTitle-2JuMf {
	margin: 0;

	font-weight: 700;
	font-size: 16px;
	line-height: 20px;
}

.card__body-PQSAG {
	margin-top: 8px;
}

.card__desc-1Y5qw {
	margin: 0;
	grid-column: span 2;
}

.card__group_custom-18ji- .card__desc-1Y5qw {
	grid-column: auto;
}

.card__group_custom-18ji- span {
	order: 1;
}

@media screen and (max-width: 600px) {
	.card-3Acoc {
		padding: 16px;
	}
}
