.controls-1xVUH {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

button.button-2whxk {
	width: 49%;
	height: 49px;
}

button.button-2whxk.back-2FRsy {
	color: var(--gray70);

	background: #ffffff;
}

button.button-2whxk:only-child {
	width: 100%;
}

@media (max-width: 768px) {
	.controls-1xVUH {
		position: fixed;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: 1007;

		height: 69px;
		padding: 0 20px;

		box-shadow: 0 0 16px rgba(0, 0, 0, 0.08);
		background: #ffffff;
	}

	button.button-2whxk.back-2FRsy {
		background: var(--gray05);
	}
}
