

.price-2N_ga {
	display: flex;
	align-items: center;

	color: var(--text-base);
	font-size: 16px;
	font-weight: 600;
	line-height: 1.3;
}

/* stylelint-disable */
.edit-JAKsq {
}
/* stylelint-enable */
button.button-1be8Z {
	height: 34px;

	padding: 0 16px;
}
