.preTagWrapper-2P980 pre {
	padding: 0;

	font-size: 12px;

	line-height: 1.3;
	white-space: pre;

	font-weight: 300;
	color: var(--text-light);
}

.container-3wHVv {
	margin-bottom: 20px;
	padding: 28px;

	border-radius: 4px;

	background-color: var(--white);
	overflow: hidden;
}

.title-3jqlp {
	margin-bottom: 20px;

	color: var(--text-base);
	font-weight: 500;
	font-size: 22px;
	line-height: 1.3;
}

.list-1m0zq {
	display: flex;
	flex-direction: column;
}

.listHeader-2-F0j,
.listItem-2cgjU {
	display: flex;

	border-bottom: 1px solid var(--gray10);
}

.listHeader_item-1j87a {
	flex: 0 1 100%;
	padding: 10px;

	font-weight: 500;
	font-size: 14px;
	line-height: 1.3;
}

.listItem_col-3tUQv {
	flex: 0 1 100%;
	padding: 10px;

	font-size: 14px;
	line-height: 1.3;
}

.listHeader_item-1j87a:first-child,
.listItem_col-3tUQv:first-child {
	flex: 1 1 40%;
}

.listHeader_item-1j87a:nth-child(2),
.listItem_col-3tUQv:nth-child(2) {
	flex: 1 1 200%;
}

.listHeader_item-1j87a:nth-child(3),
.listItem_col-3tUQv:nth-child(3) {
	flex: 1 1 120%;
}

.listHeader_item-1j87a:nth-child(3),
.listHeader_item-1j87a:nth-child(4),
.listItem_col-3tUQv:nth-child(3),
.listItem_col-3tUQv:nth-child(4) {
	text-align: right;
}

.listItem_col-3tUQv:first-child span,
.listItem_col-3tUQv:nth-child(2) span {
	font-weight: 500;
}

.label_accrued-3QY40 {
	color: #29a644;
}

.label_spent-3sdUK {
	color: #c21212;
}

.balance-1gWsm {
	display: inline-block;
	margin-left: 20px;
}

.number-1Az0D {
	white-space: nowrap;
}

.listItem-2cgjU:has(.pre_listItem_col-64Gtn) {
	padding: 0;
}

@media screen and (max-width: 600px) {
	.title-3jqlp {
		padding: 20px 20px 0;

		font-size: 18px;
	}

	.container-3wHVv {
		padding: 0;
	}

	.wrapper-3k4m6 {
		overflow-y: scroll;
	}

	.list-1m0zq {
		width: 1000px;
		padding: 0 20px 20px;
	}
}
