.title-Z3B7Q {
	font-weight: 700;
	font-size: 21px;
	line-height: 30px;
	color: var(--gray80);
}

.description-8SmFD {
	margin-top: 12px;

	font-size: 13px;
	line-height: 1.5;
	color: #727272;
}

.info-wjLR5 {
	display: flex;
	flex-direction: column;
	flex: 1;
	max-width: 33%;
}

.info-wjLR5.equipment-2c8v6 {
	min-width: -moz-fit-content;
	min-width: fit-content;
	margin-left: auto;

	text-align: right;
}

.info_price-1sYWL {
	margin-top: auto;
}

.titleContainer-zLNw3 {
	flex: 1;
}

.baggage_wrapper-u5PFs {
	display: flex;
	flex: 1;
	flex-direction: column;
	justify-content: flex-end;
	align-items: center;
	height: 100%;

	padding: 16px 0;

	border-radius: 4px;

	background: #f6f6f6;
}

.baggage_wrapper-u5PFs:not(:last-child) {
	margin-right: 12px;
}

.label-Vvdch {
	font-weight: 400;
	font-size: 13px;
	line-height: 1.4;
	color: #808080;
}

.price_wrapper-3IU21 {
	margin-top: 6px;

	font-weight: 700;
	font-size: 13px;
	line-height: 1.4;
	color: var(--text);
}

.price_wrapper-3IU21.perFlight-OPOpt {
	color: var(--success);
}

.price_label-1DxGu {
	display: flex;

	color: #262626;
	font-weight: 600;
	font-size: 13px;
	line-height: 130%;
}

.price_label-1DxGu.price_label_withPrice-1TFuk {
	justify-content: flex-end;
}

.price_amount-1MvwO {
	margin-left: 12px;
	padding-left: 8px;

	border-left: 1px solid #d9d9d9;

	font-size: 16px;
}

.row-Wt-LN {
	display: flex;
	flex-wrap: wrap;
	grid-gap: 10px;
	gap: 10px;
}

.baggage__container-2meQY {
	display: flex;
	align-items: center;
	flex: 1;
}

.segmentNotice-JEX22 {
	margin-top: 8px;
	padding: 12px 10px;

	border: 1px solid var(--warning);
	border-radius: 4px;

	color: var(--text-mid);
	font-size: 11px;

	background: var(--warning20);
}

.categories-1F2SY {
	display: grid;
	grid-template-columns: repeat(7, 1fr);
	justify-content: space-between;
	justify-items: center;
	grid-gap: 10px 16px;
	gap: 10px 16px;

	width: 100%;

	margin-bottom: 24px;
}

.categories-1F2SY .categories__item-3VY9c > svg {
	width: 70%;
	height: 70%;
}

.category__wrapper-dIn0k {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.category__price-B-V9T {
	font-size: 16px;
	font-weight: 700;
	color: var(--text);
}

.category_info-26snO {
	display: flex;
	flex-direction: column;
}

.category_title-2OnPb {
	margin-bottom: 4px;

	color: var(--base);
	font-weight: 700;
	font-size: 18px;
	line-height: 1.3;
}

.category_description-3XD1V {
	color: #4d4d4d;
	font-size: 13px;
	line-height: 1.3;
}

.category_description-3XD1V a {
	text-decoration: underline;
	color: var(--base);
}

.promoMessage-2R05P {
	display: none;
}

@media (max-width: 1024px) and (min-width: 601px) {
	.promoMessage-2R05P {
		font-size: 12px;
	}
}

@media screen and (max-width: 600px) {
	.included_baggage-2F_sM {
		display: none;
	}

	.row-Wt-LN {
		display: block;
	}

	.baggage_wrapper-u5PFs {
		margin-bottom: 12px;
		padding-bottom: 12px;

		border-bottom: 1px solid #d9d9d9;

		background: none;
	}

	.toggle__wrapper-_JqjD {
		display: flex;
		flex-direction: column;
		align-items: flex-end;
	}

	.descriptionContainer-3vCy7 {
		display: flex;
		align-items: center;
		width: 100%;
	}

	.description-8SmFD {
		margin-left: 20px;

		color: var(--text-light);
		font-size: 14px;
	}

	.baggageInfo-3VGKe {
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 100%;

		color: var(--brand1);
		font-weight: 500;
		font-size: 14px;
	}

	.price_wrapper-3IU21 {
		margin-top: 0;

		color: var(--text);
		font-size: 16px;
	}

	.price_label-1DxGu {
		margin-bottom: 8px;
	}

	.categories-1F2SY {
		display: block;
		justify-content: unset;
		justify-items: unset;

		width: calc(100% + 24px);
		margin-left: -12px;
	}

	.categories-1F2SY .slick-slide {
		padding-left: 12px;
	}

	.category__wrapper-dIn0k {
		margin-bottom: 12px;
		padding-bottom: 14px;

		border-bottom: 1px solid #d9d9d9;
	}

	.category_description-3XD1V {
		display: none;
	}
}
