

.header-XYEUb {
	position: relative;

	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 56px;
	padding: 0 16px 0 20px;

	color: #ffffff;

	color: var(--mobile-stepbar-header-color, #ffffff);
	font: var(--medium--1-mobile-bold);

	cursor: pointer;
}

/* stylelint-disable */
.header_zeroPrice-3ffO4 {
}
/* stylelint-enable */
.stepIndex-4LKFV {
	display: flex;
}

.stepIndex__label-1yfw5 {
	margin-left: 12px;
}

.price-2f_RU {
	display: flex;
	align-items: center;

	color: inherit;

	color: var(--mobile-stepbar-price-color, inherit);
}

.price__wrapper-ua1jA {
	display: flex;
	align-items: center;
}

.price_title-1tBNF:after {
	content: ':';
}

.expand-1LqPe {
	width: 24px;
	height: 24px;
	margin-left: 8px;

	border-radius: 50%;

	background: rgba(255, 255, 255, 0.3);

	transition: transform 0.3s;
}

.expand_open-2Ft8Q {
	transform: rotate(-180deg);
}
