.wrapper-QLGrW {
	max-width: 1100px;
	margin: 28px auto 20px;

	border-radius: 4px;

	background: var(--warnings-background);
	box-shadow: 0 1px 2px rgba(0, 0, 0, 0.08);
}

.warning-1-uWd {
	display: flex;
	align-items: center;
	padding: 24px 28px;
}

.warning-1-uWd:not(:last-child) {
	border-bottom: 1px solid var(--warnings-warning-border-bottom-color);
}

.warning__text-1XRTS {
	display: flex;
	align-items: center;

	color: var(--warnings-text-color);
	font-size: 14px;
	line-height: 21px;
}

.warning__icon-cN5Aw {
	min-height: 24px;
	min-width: 24px;
	margin-right: 20px;
	display: flex;
	align-items: center;

	color: var(--warnings-icon-color);
}

.icon__text-3iPw7 {
	margin-left: 14px;

	font-size: 15px;
	color: var(--warnings-text-color);
}

@media screen and (max-width: 600px) {
	.wrapper-QLGrW {
		margin-top: 0;
	}

	.warning-1-uWd {
		flex-direction: column;
		align-items: flex-start;
	}

	.warning__icon-cN5Aw {
		margin-bottom: 13px;
	}
}
