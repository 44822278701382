.monthsWrapper-3oO6V,
.monthsList-1sTzU {
	display: flex;
}

.wrapper-nObKv {
	position: relative;

	display: inline-block;
	padding: 20px;

	border: 1px solid #cccccc;
	border-radius: 4px;

	font-size: 13px;

	background: #ffffff;
}

.frameless-2fpPB {
	padding: 0;

	border: unset;
}

.nav_next-32nhc,
.nav_prev-IvTxF {
	position: absolute;

	font-size: 21px;

	cursor: pointer;
}

.nav_next-32nhc {
	right: 20px;
}

@media (min-width: 1024px) and (max-width: 1160px) {
	.wrapper-nObKv {
		min-height: 375px;
	}
}

@media (max-width: 1024px) {
	.monthsList-1sTzU {
		flex-direction: column;
		grid-gap: 45px;
		gap: 45px;
	}
}
