.container-3maaL {
	position: relative;

	display: flex;
	flex-direction: column;
}

.text-a2adH {
	width: 65%;
	margin-bottom: 20px;

	color: var(--text-light);
	font-size: 16px;
	line-height: 1.3;

	white-space: break-spaces;
}

.footer-29GIR {
	display: flex;
	align-items: center;
	margin-top: 20px;
}

button.button-3oJZe {
	flex: 1;

	border-radius: 4px;

	color: var(--brand-1-0);
	font: var(--medium--1-desktop);
	white-space: nowrap;

	background: var(--brand-1-4);
}

button.button-3oJZe:hover {
	background: var(--brand-1-4);
}

button.button-3oJZe:not(:last-child) {
	margin-right: 12px;
}

button.button-3oJZe.button_gray-3Ah2z {
	color: var(--text-light);
	font-weight: 400;

	background: var(--bg-fill);
}

button.button-3oJZe.button_gray-3Ah2z:hover {
	background: var(--grey-line);
}

.header-1agFK {
	display: flex;
	align-items: center;
}

.miles-3QmzJ {
	padding-left: 0;

	color: var(--text-base);
	font-weight: 700;
	font-size: 30px;
}

button.menu-2VHZB {
	margin-left: auto;
}

.miles_currency-1Lfgl {
	margin-left: 8px;

	font-weight: 300;
}

.progress_status-1_5kz {
	margin-top: 16px;
	margin-bottom: 12px;
}

.progress_fares-1Upi9 {
	margin-bottom: 20px;
}

.progress_status-1_5kz,
.progress_fares-1Upi9 {
	color: var(--text-light);
	font-size: 16px;
	line-height: 1.3;
}

div.progress-2j_yp {
	height: 8px;
	margin-bottom: 8px;

	border-radius: 20px;
}

div.progress_bg-3ePA5 {
	background: var(--bg-fill);
}

div.progress_color-3E8_r {
	border-radius: 20px;

	background: var(--brand-1-0);
}

.links-34fhH {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	margin-top: auto;
}

.links_item-34-hy {
	position: relative;

	margin-right: 25px;
	margin-bottom: 4px;

	color: var(--brand-1-0);

	cursor: pointer;
}

.links_item-34-hy:before {
	content: '';

	position: absolute;
	right: -13px;

	width: 1px;
	height: 100%;

	background: var(--line-separator);
}

.links_item-34-hy:last-child {
	margin-right: 0;
}

.links_item-34-hy:last-child:before {
	display: none;
}

.loader-2UcEz {
	display: flex;
	align-items: center;
	margin-top: 12px;

	color: var(--text-light);
	font-weight: 500;
	font-size: 14px;
}

.loader-2UcEz .loader__indicator-3xAg0 {
	margin: 0 12px;
}

.addToAppleWallet-2I0J2 {
	width: 96px;
	margin-top: 20px;
	margin-left: auto;

	cursor: pointer;
}

.progress_text-3r1jX {
	position: static;
}

@media (max-width: 1024px) {
	.text-a2adH {
		width: 100%;
	}
}

@media screen and (max-width: 600px) {
	.footer-29GIR {
		flex-direction: column;
		align-items: inherit;
	}

	.links-34fhH {
		margin-top: 20px;
	}

	button.button-3oJZe {
		margin-right: 0;
		margin-bottom: 10px;
	}

	button.button-3oJZe:last-child {
		margin-bottom: 0;
	}

	button.menu-2VHZB {
		position: absolute;
		right: 20px;
		top: 0;

		transform: translateY(-50%);
	}

	.addToAppleWallet-2I0J2 {
		order: 1;
	}
}
