.chip-3QauK {
	display: inline-flex;
	align-items: center;
	height: 40px;
	padding: 0 12px 0 21px;

	border-radius: 100px;

	color: var(--base-15);

	font-weight: 600;
	font-size: 16px;

	cursor: pointer;
	background: var(--base90);
}

.chip_readonly-15swG {
	padding: 0 21px;

	cursor: default;
}

.chip-3QauK > svg {
	display: none;
}

.close-17tBD {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 24px;
	height: 24px;
	margin-left: 10px;

	border-radius: 50%;

	color: var(--gray30);

	background: #ffffff;
}

@media (max-width: 1024px) {
	.chip-3QauK {
		position: relative;

		width: 100%;
		height: auto;
		margin: 0;
		padding: 16px;

		border-radius: 0;

		color: #ffffff;
		font-weight: 500;
		font-size: 16px;

		background: var(--base);
	}

	.chip-3QauK:first-child {
		border-top-left-radius: 8px;
		border-top-right-radius: 8px;
	}

	.chip-3QauK:last-child {
		border-bottom-left-radius: 8px;
		border-bottom-right-radius: 8px;
	}

	.chip-3QauK:not(:first-child) {
		border-top: 1px solid #6fb1ff;
	}

	.close-17tBD {
		position: absolute;
		right: 16px;

		color: #ffffff;

		background: #5ba6ff;
	}

	.chip-3QauK > svg {
		display: block;
		margin-right: 10px;
	}
}
