

.selectedFare-2DB2Q {
	display: flex;
	flex-direction: column;
	margin-bottom: 4px;

	border-radius: 4px;

	box-shadow: 0 1px 2px rgba(0, 0, 0, 0.08);

	overflow: hidden;
}

.selectedFare__features-1T5np {
	display: flex;
	flex-direction: column;
	padding: 12px;

	background: var(--white);
}

.features__title-2HHaG {
	margin-bottom: 11px;

	font-size: 16px;
	line-height: 150%;
	color: var(--text-base);
	font-weight: 500;
}

.feature-3qzaT {
	display: flex;
	align-items: center;
	margin-bottom: 5px;
}

.feature__icon-2lIMr {
	height: 24px;
	width: 24px;
	display: flex;
	align-items: center;
	margin-right: 9px;

	color: var(--brand-1-0);
}

.feature__icon_external-2gfoi {
	max-width: 24px;
	max-height: 24px;
	object-fit: contain;
}

.feature__text-3SKtv {
	font-size: 13px;
	line-height: 150%;
	color: var(--text-base);
}

.features__info-2lV9W {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-top: 12px;

	color: var(--brand-1-0);
	font-size: 13px;
	font-weight: 400;
	line-height: 15px;
	text-decoration: underline;
}

div.dialog__header-2KJRW {
	max-width: 100%;
	height: 55px;

	border-bottom: none;

	font-weight: 500;

	font-size: 20px;
	line-height: 150%;

	background: var(--white);
	overflow: hidden;
}

div.dialog__close-2ehEA {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 28px;
	height: 28px;

	border-radius: 50%;

	color: var(--text-base);

	background-color: var(--bg-fill);
}

div.dialog__close-2ehEA > svg {
	width: 10px;
	height: 10px;
}

.dialog__header-2KJRW > div > div {
	display: flex !important;
	align-items: center;
}

div.dialog__content-3Hk8Y {
	margin-top: 55px;
}
