@media screen and (max-width: 600px) {
	div.scrollBody-2qaF3 {
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
	}

	div.scrollBody-2qaF3 div.paper-3VanJ {
		min-height: auto;

		border-radius: 8px 8px 0 0;
	}
}
