.wrapper-ksyVS {
	display: flex;
	max-width: 840px;
	margin: 0 auto;

	padding: var(--payment-wrapper-padding);

	border-radius: var(--payment-wrapper-border-radius);

	box-shadow: 2px 1px 40px rgba(0, 0, 0, 0.03);

	background: #ffffff;
	overflow: hidden;
}

.wrapper-ksyVS.oneMethod-khnlF {
	flex-direction: row;
}

.details-3yy04 {
	flex-shrink: 0;
	flex-basis: 300px;
	min-height: 400px;
	padding: var(--payment-wrapper-padding);

	border-radius: var(--payment-wrapper-border-radius);

	background-color: var(--bg-fill);
}

@media (max-width: 1024px) {
	.details-3yy04 {
		max-width: 300px;
		flex-basis: auto;
		flex-shrink: 1;
	}
}

.details__total-1Mg1F {
	margin: 8px 0;

	font-size: 18px;

	white-space: nowrap;
}

.details__parts-1iIia {
	margin: 40px 0 0;
	padding: 0;
}

.content-14Fuy {
	flex-grow: 1;
	padding: 28px 30px;

	display: flex;
	flex-direction: column;
	justify-content: center;
}

.content__title-k5vAk {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin: 0;

	font-weight: 500;
	font-size: 22px;
	color: var(--text-base);
}

div.tooltip-YCj05 {
	margin-bottom: 5px;
}

.content__tooltip-28TxG {
	margin-top: 10px;
	margin-bottom: 20px;

	color: var(--text-light);
	font-size: 14px;
	text-decoration: underline;

	-webkit-user-select: none;

	    -ms-user-select: none;

	        user-select: none;
}

.content__tooltip__text-q_Zrw {
	text-align: center;
}

.oneMethod-khnlF .content__title-k5vAk {
	color: var(--dark-gray);
	font-size: 26px;
	line-height: 150%;
}

div.payment__result-2C9xd {
	max-width: 480px !important;
	min-width: 480px;
	min-height: 375px;
	overflow: visible;
}

div.payment__result-2C9xd > span {
	display: none;
}

button.content__title__back-6Lts1 {
	font-weight: 400;
	font-size: 14px;
}

.gateways-34mNA {
	margin: 0;
	padding: 0;
}

.gateways_hidden-3lmxX {
	display: none;
}

.description-PUTTp {
	margin: 0 0 40px;

	font-size: 15px;
	line-height: 139.45%;
	color: var(--common-gray);
}

.cards-3HjbA {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	max-width: 65%;
}

.card-5jMmx {
	margin-top: 2px;
	margin-right: 10px;
	margin-bottom: 2px;
}

.card-5jMmx:last-child {
	margin-right: 0;
}

.paylate-1BIUS {
	height: 28px;
}

.paylate_asCard-1y3TZ {
	height: 28px;
	display: none;
}

.idram-TC-rC {
	height: 26px;
}

.mastercard-3DjoH {
	height: 16px;
}

.visa-2FDK_ {
	height: 10px;
}

.cards_header-3grf4 {
	position: relative;
}

.sbp-1HkVo {
	display: none;
	height: 18px;
}

.sberpay-qBA6a {
	display: none;
	height: 19px;
}

.mir-2HVvL {
	height: 10px;
}

.applepay-1q99s {
	height: 16px;
}

.googlepay-3ot0y {
	height: 15px;
}

.vakifbank-2mh3- {
	height: 18px;
	display: none;
}

.yandexpay-3Zqjb {
	height: 18px;
	display: none;
}

.unionpay-39gEm {
	height: 24px;
	display: none;
}

.invoicebox-2MPAh {
	height: 20px;

	transform: translateY(4px);
}

.kaspi-2otFd {
	height: 18px;
}

div.timelimit-3eWGT {
	margin-bottom: 20px;
}

.details__totalItem-3mTGE {
	flex-wrap: wrap;
}

button.details__button-1DBe- {
	margin-left: -8px;
}

.qiwi-26BUA {
	width: 70px;
	height: 26px;
}

img.icon-39vdh {
	width: 60px;
	height: 30px;
}

.visible-1YHkM {
	display: block;
}
/* stylelint-disable */
.summaryGroup_order-2Z8iW {
}

.summaryGroup_priceParts-2nU0G {
}

.summaryGroup_total-Vodh8 {
}
/* stylelint-enable */

@media screen and (max-width: 600px) {
	.wrapper-ksyVS {
		display: flex;
		flex-direction: column;
		padding: var(--payment-wrapper-padding) 0 0 0;

		background: unset;
	}

	.oneMethod-khnlF.wrapper-ksyVS {
		flex-direction: column;
	}

	.details-3yy04 {
		min-height: auto;
		flex-basis: unset;
		max-width: 100%;
		padding: 4px 20px 24px;

		background: unset;
	}

	.details__total-1Mg1F {
		text-align: right;

		line-height: 150%;
	}

	.details__parts-1iIia {
		margin: 20px 0 0;
	}

	.content-14Fuy {
		padding: 20px;

		border-radius: 8px 8px 0 0;

		background: #ffffff;
	}

	.content__title-k5vAk {
		justify-content: flex-start;

		font-size: 17px;
		font-weight: 500;
		line-height: 150%;
		color: #292929;
	}

	.oneMethod-khnlF .content__title-k5vAk {
		justify-content: space-between;
	}

	.content__title__back-6Lts1 {
		display: flex;
		margin-right: 16px;

		line-height: 25px;

		transform: rotate(180deg);
	}

	.cards-3HjbA {
		margin-bottom: 15px;
	}

	.cards_noTitle-1e141 {
		position: absolute;

		margin-bottom: 0;
	}

	.paylate-1BIUS {
		position: static;
	}
}
