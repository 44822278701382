.ticket-9UO_M {
	display: flex;
	flex-direction: column;
	margin: 0 auto;
	max-width: 1100px;

	color: var(--gray80);
}

@media screen and (max-width: 600px) {
	div.header-2os9F,
	div.ticket-9UO_M {
		border-top-right-radius: 8px;
		border-top-left-radius: 8px;
	}
}

.transparent-gtqDR {
	background: transparent;
}

.header-2os9F {
	display: flex;
	flex-direction: column;
}

.info-32WdQ {
	display: flex;
	align-items: baseline;
	padding: 16px;
}

@media screen and (max-width: 600px) {
	div.info-32WdQ {
		flex-direction: column;
		align-items: flex-start;
		padding: 10px;
	}

	.notes-1uTtq {
		padding: 0 20px;
	}
}

.title-H4jRG {
	margin: 0;
	padding: 0;

	font-weight: 700;
	font-size: 20px;
	line-height: 20px;
}

@media screen and (max-width: 600px) {
	.title-H4jRG {
		flex: 0 1 50%;

		font-size: 16px;
		font-weight: 500;
	}
}

@media screen and (max-width: 600px) {
	.createPrefix-3XIOX {
		display: none;
	}
}

.time-3BhjY {
	margin: 0 0 0 10px;
	padding: 0 0 0 10px;

	border-left: 1px solid var(--gray20);

	color: var(--gray50);
	font-size: 14px;
	line-height: 150%;
}

@media screen and (max-width: 600px) {
	.time-3BhjY {
		flex: 0 1 50%;
		align-self: flex-end;
		margin: -21px 0 0;
		padding: 0;

		border-left: 0;
	}
}

.status-1UnBo {
	margin-left: auto;
}

@media screen and (max-width: 600px) {
	.status-1UnBo {
		margin-left: 0;
		margin-top: 6px;
		margin-bottom: 6px;
	}
}

.comments-2u6sk {
	padding: 16px;

	border-top: 1px solid var(--gray05);
	border-bottom: 1px solid var(--gray05);
}

@media screen and (max-width: 600px) {
	.comments-2u6sk {
		padding: 10px;
	}
}

.commentsTitle-1QlBk,
.filesTitle-1kEhf {
	margin: 0;

	font-weight: 700;
	font-size: 16px;
	line-height: 20px;
}

.commentsText-25_Wt,
.filesContent-r5DTX {
	margin-top: 8px;
	margin-bottom: 0;

	font-size: 15px;
	line-height: 19px;
}

@media screen and (max-width: 600px) {
	div.breadcrumbs-2RNEQ {
		display: none;
	}
}

.files-pxVRX {
	padding: 16px;
}

@media screen and (max-width: 600px) {
	.files-pxVRX {
		padding: 10px;
	}
}

.prices-2Z3Mb {
	margin: 12px auto;
	max-width: 1100px;
}

@media screen and (max-width: 600px) {
	div.prices-2Z3Mb {
		padding: 10px;
		margin-top: 0;

		border-bottom-right-radius: 8px;
		border-bottom-left-radius: 8px;
	}
}

div.price-25GEh {
	padding: 25px 16px 16px 20px;
	margin: 12px 0;

	box-shadow: none;
}

@media screen and (max-width: 600px) {
	div.price-25GEh {
		margin: 0;
	}
}

.file-2NpD_ {
	display: flex;
	align-items: center;

	font-size: 16px;

	color: var(--base10);
}

@media screen and (max-width: 600px) {
	.file-2NpD_ {
		line-break: anywhere;
	}
}

.fileIcon-2b6W8 {
	margin-right: 7px;
}

.buttons-2JA8S {
	max-width: 1100px;
	margin: 12px auto 0 auto;
}

@media screen and (max-width: 600px) {
	.buttons-2JA8S > div {
		display: flex;
		flex-direction: column;
	}

	.buttons-2JA8S > div button:last-of-type {
		margin-top: 8px;
		margin-left: 0;
	}
}

button.button-Sz2oZ {
	min-width: 250px;
}

button.confirm-36meD {
	margin-left: 12px;
}

button.reject-3ZiT3 {
	color: var(--error);
	font-weight: 400;

	background: #ffffff;
}

/* stylelint-disable */
.refund__request-2If4x {
}
/* stylelint-enable */
@media screen and (max-width: 600px) {
	div.step-zchFA {
		display: block;
		padding-right: 15px;

		border-radius: 0;
	}

	div.step-zchFA:not(:last-of-type) {
		border: 1px solid rgba(255, 255, 255, 0.3);
	}

	.reasonHeader-2WAP1 {
		max-height: 115px;
		display: block;
		overflow: hidden;

		text-overflow: ellipsis;
	}

	.reasonDescr-32YC- {
		margin: 0;

		font-size: 14px;
		line-height: 19px;
		font-weight: 400;
	}
}
