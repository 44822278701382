label.root-3rR0B {
	padding: 12px 0;

	margin: 0;
}

label.root-3rR0B:not(:first-child) {
	border-top: 1px dashed var(--line-dot-line);
}

span.labelContainer-1c4dj {
	display: flex;
	justify-content: space-between;
	width: 100%;
}

.label-3TKDc {
	display: flex;
	justify-content: space-between;
	width: 100%;

	color: var(--text-mid);

	font: var(--medium--1-normal);
}

label:has(.checked-1i4BA) .label-3TKDc {
	color: var(--text-base);

	font: var(--medium--1-desktop-accent);
}

span.radio-1HqYL {
	padding: 9px 9px 9px 0;

	color: var(--line-dot-line);
}

span.radio-1HqYL.checked-1i4BA {
	color: var(--brand-1-0);
}

div.tooltip-3x44H {
	padding: 10px;

	border-radius: 2px;

	font: var(--small-desktop);

	color: var(--bg-white);

	background: var(--brand-1-0);
}

span.tooltip_arrow-3FIs3 {
	left: 50% !important;

	color: var(--brand-1-0);
	font-size: 10px;

	transform: translateX(-50%) !important;
}

div.form-3Gvcn {
	padding: 8px 20px;

	border-radius: 8px;

	background: var(--brand-1-5);
}

.form-3Gvcn.form_error-1reFg:before {
	content: '';

	position: absolute;

	z-index: 1;

	left: 0;
	right: 0;
	top: 0;
	bottom: 0;

	border: var(--brand-1-0) 2px solid;
	border-radius: 8px;

	background: unset;

	pointer-events: none;
}

@media screen and (max-width: 600px) {
	.label-3TKDc {
		flex-direction: column;
	}
}

.price-E0ojl {
	min-width: 150px;
	margin-left: auto;

	transform: translateY(-40%);
}

.singleTravellerInOrder-2hYlB .price-E0ojl {
	transform: none;
}

@media screen and (max-width: 600px) {
	.price-E0ojl {
		min-width: auto;
		margin-left: 0;

		transform: none;
	}

	.price-E0ojl .priceContent-26h5K {
		flex-direction: row;
	}
}

/* stylelint-disable */
label.done-3tKu4 {
}

.labelMute-27SUC {
}
