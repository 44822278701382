

.list-3JHhW {
	margin: 0;
	padding-left: 15px;

	font-weight: 400;
	font-size: 16px;

	list-style-type: none;
}

.list-3JHhW li {
	position: relative;
}

.list-3JHhW a {
	color: var(--common-blue);
}

.list-3JHhW li:before {
	position: absolute;
	top: 50%;
	left: -15px;

	width: 5px;
	height: 5px;

	border-radius: 50%;

	transform: translate(0, -50%);
	background: var(--text-light);
	content: '';
}
