button.button-31RG1 {
	color: var(--brand-1-0);
}

.icon-3TvT3 {
	width: 20px;
	height: 20px;
	margin-left: 6px;
}

.icon-3TvT3 svg {
	width: 100%;
	height: 100%;
}

div.paper-FAQYP {
	border-radius: 20px;
}

button.close-3v7n5 {
	position: absolute;
	top: 32px;
	right: 32px;
	z-index: 1;
}

@media screen and (max-width: 600px) {
	button.close-3v7n5 {
		z-index: 2;
		top: 12px;
		right: 12px;
	}
}
