.wrapper-3MjzN {
	display: flex;
	flex-direction: column;
	padding: 0;

	color: var(--gray80);
}

.header-3DUGw {
	font-weight: 600;
	font-size: 30px;
}

.step-bGhDF {
	margin-top: 40px;
	margin-bottom: 20px;

	font-size: 17px;
}

.step-bGhDF span {
	font-weight: 600;
}

.error-1VMr9 {
	display: flex;
	align-items: center;
	margin-top: 20px;
	padding: 12px;

	border-radius: 4px;

	color: var(--error-20);
	font-size: 16px;

	background: var(--error90);
}

.error-1VMr9 svg {
	margin-right: 12px;
	flex-shrink: 0;
}

.controls-26xkr {
	margin-top: 20px;
	display: flex;
	justify-content: space-between;
	align-items: center;

	font-size: 15px;
}

.controls-26xkr a {
	text-decoration: none;
}

.controls__left-1xOkl {
	display: flex;
	align-items: center;
}

.resend-sEqfW {
	margin-left: 20px;
	padding-left: 20px;

	border-left: 1px solid var(--gray20);

	color: #292929;
	font-size: 14px;
}

.resend__label-2W-eW {
	justify-content: flex-start;

	color: #292929;
	font-size: 16px;
}

.success-1ct4K {
	display: flex;
	align-items: center;
	margin-top: 40px;
	margin-bottom: 30px;

	font-size: 17px;
	line-height: 140%;
}

.success-1ct4K svg {
	margin-right: 12px;
	flex-shrink: 0;
}

div.field-1-38D:not(:first-child) {
	margin-top: 25px;
}

@media screen and (max-width: 600px) {
	.controls-26xkr {
		flex-direction: column;
		align-items: flex-start;
	}

	.controls-26xkr button {
		margin-top: 20px;
		height: 49px;
		width: 100%;

		border-radius: 4px;
	}
}
