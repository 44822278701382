.root-7J-zR {
	padding: 43px 40px 47px;
	max-width: 660px;
}

.title-2A3eH {
	font-size: 30px;
	font-weight: 500;
	color: var(--gray80);
}

.text-22p9C {
	margin-top: 20px;

	color: var(--text-mid);
	font-weight: 400;
	font-size: 18px;
	line-height: 1.3;
}

.row-sGV4F {
	display: flex;
	margin-top: 50px;
}

.input_wrapper-lph-q {
	flex-grow: 1;
}

.button_wrapper-6gjvE {
	margin-left: 20px;
	padding-top: 4px;
}

.social_wrapper-2C92c {
	margin-top: 44px;
}

.social_title-TWr_3 {
	margin-bottom: 14px;

	font-size: 13px;
	color: var(--gray50);
}

.button_continue-2ijuZ {
	margin: 0;
}

.loyaltySwitch_wrapper-3b0fV {
	position: absolute;

	margin-top: -5px;
}

.loyaltySwitch_root-1O007 .loyaltySwitch_label-3CUxA {
	color: var(--text-mid);
}

@media screen and (max-width: 600px) {
	.root-7J-zR {
		width: auto;
		padding: 0;

		/* padding: 47px 28px; */
	}

	.loyaltySwitch_wrapper-3b0fV {
		position: static;

		padding: 0;
	}

	.loyaltySwitch_root-1O007 {
		padding: 0;
	}

	.row-sGV4F {
		flex-direction: column;
		margin-top: 48px;
	}

	.button_wrapper-6gjvE {
		margin-left: 0;
		margin-top: 20px;
		padding-top: 0;
	}
}
