.account__title-lj4-e {
	margin-top: 0;
	margin-bottom: 24px;

	font-size: 28px;
	color: var(--dark-gray);
	font-weight: 500;
}

.account__field-34zAO:not(:last-of-type) {
	margin-bottom: 25px;
}

div.card-3-7Is {
	box-sizing: border-box;
}

div.account__deleteModal-3FdGG {
	overflow: visible;

	min-width: 600px;
}

.account__content-3QWZX {
	border-radius: inherit;
}

.phone__input-2BJDh {
	margin-top: 24px;
}

.social__networks-30GRB {
	display: flex;
	flex-direction: column;
	padding: 20px 40px 60px 40px;

	color: #9a9a9a;
}

.social__buttons-9ari9 {
	display: flex;
}

.social__buttons-9ari9 button {
	position: relative;

	display: flex;
	align-items: flex-end;
	width: 160px;
	height: 44px;

	font-size: 13px;
}

.social__buttons__logo-3JxUI {
	margin-right: 8px;
	display: flex;
	align-items: center;
}

.social__buttons__wrapper-2WIAT {
	position: relative;
}

.social__buttons__wrapper-2WIAT:nth-child(2) {
	margin: 0 30px;
}

.social__buttons__control-2PCmK {
	position: absolute;
	right: -14px;
	top: 7px;

	z-index: 1000;

	height: 28px;

	border-radius: 50%;
	border: 4px solid #ffffff;

	cursor: pointer;

	background-color: #ffffff;
}

.social__buttons__control-2PCmK svg {
	height: 20px;
	width: 20px;
}

.social__buttons-9ari9 button.facebook-3FGJG {
	border-color: #3c5b96;

	color: #ffffff;

	background-color: #3c5b96;
}

.social__buttons-9ari9 button.twitter-3iASn {
	border-color: #59adeb;

	color: #ffffff;

	background-color: #59adeb;
}

.hidden-lRNH8 {
	display: none;
}

.account__offers-1gpzf {
	color: var(--text-base);
}

.account__offers-1gpzf p {
	margin: 0;
}

.account__offers-1gpzf .checkbox-TNyOM {
	display: flex;
	padding: 0;
	margin-right: 11px;
}

.account__offers-1gpzf label {
	margin-left: -2px;
}

span.colorSecondary-3PDFn:hover {
	background-color: var(--brand-1-5);
}

.account__offers-1gpzf .checkbox-TNyOM.checked-13XJx {
	color: var(--cabinet-action);
}

button.deleteButton-FmpTo {
	font-size: 14px;
	font-weight: 400;
	color: var(--text-light);
}

.account__footer-361m- .divider-3WHd2 {
	height: 100%;
	width: 1px;
	margin: 0 12px;

	border-right: 1px solid #d9d9d9;
}

.terms__modal-hEAoe {
	display: flex;
	justify-content: center;
	align-items: center;

	background-color: #ffffff;
}

.loader__wrapper-EReQ9 {
	position: relative;
}

span.mui__label-1t-wB {
	font-family: var(--primary-font-family);
}

.account__subscription-1c-95 {
	margin-bottom: 28px;
}

.subscription__title-273S2 {
	margin-bottom: 6px;

	font-weight: 700;
}

.subscription__value-28LCq {
	color: var(--text-mid);
}

.subscription__loader-3hAQ_ {
	max-height: 24px;
}

@media (max-width: 1024px) {
	.account__field-34zAO {
		padding: 16px 20px;
	}

	.account__field-34zAO:not(:last-of-type) {
		border-bottom: 1px solid var(--line-separator);
	}

	.account__offers-1gpzf {
		padding: 4px 20px 16px;
	}

	.loader__wrapper-EReQ9 {
		padding: 0 20px;
	}
}

@media screen and (max-width: 600px) {
	.loader-2WI70 {
		margin: 16px 20px 0;
	}

	.account__title-lj4-e {
		font-size: 22px;
	}

	.social__networks-30GRB {
		padding: 20px;
	}

	.social__buttons-9ari9 {
		flex-direction: column;
	}

	.account__offers-1gpzf .checkbox-TNyOM {
		margin-right: 12px;
	}

	.account__footer-361m- {
		flex-direction: column;
		padding: 20px;
	}

	.account__subscription-1c-95 {
		margin-bottom: 20px;
	}

	.subscription__title-273S2 {
		margin-bottom: 12px;
	}

	.account__offers-1gpzf .mui__label-1t-wB {
		font-size: 14px;
		line-height: 18px;
	}

	.account__footer-361m- p {
		height: auto;
		margin: 0;
	}

	.account__footer-361m- p:last-child {
		margin-top: 20px;
	}

	.account__footer-361m- svg {
		margin-right: 18px;
	}
}

@media screen and (max-width: 380px) {
	.account__field-34zAO,
	.account__offers-1gpzf {
		padding: 16px;
	}
}
