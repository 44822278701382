

label.formLabel-1yu5_ {
	font-size: 24px;
	line-height: 24px;
	font-weight: 700;

	color: var(--gray80);
}

.notes-2b0Wq {
	max-width: 1100px;
	margin: 0 auto;
}

.wide-39UKr {
	max-width: 1100px;
}

.selectedServices-l1wjl {
	max-width: 1100px;

	margin: 0 auto 20px;
}

.selectedServices-l1wjl .note-2iJll {
	margin-left: 0;
	margin-right: 0;
}

.selectedServices-l1wjl .note-2iJll:first-child {
	margin-top: 0;
}

div.control-2oQYY {
	margin: 10px 0;
}

/* error text */
.control-2oQYY > p {
	bottom: 12px;
}

label.agree-y1A8t {
	max-width: 1100px;
	margin: 21px auto;
	display: flex;
}

span.checkbox-jcndU.active-10y7o {
	color: var(--base);
}

.terms-Q0DAf {
	color: var(--common-blue);
}

div.form-2WDtE {
	max-width: 1100px;
}

/* stylelint-disable */
.wrapper-35HlB {
}

.formName-3Wxdp {
}

.formEmail-3hFPB {
}

.formTel-1BRUz {
}

.formComment-1qX-x {
}
/* stylelint-enable */
