div.modal-Kq8PW {
	max-width: 460px;
}

.content-2ezN7 {
	display: flex;
	align-items: center;
	flex-direction: column;
	padding: 0;

	font-size: 20px;
	line-height: 28px;
	color: var(--gray80);
	text-align: center;
}

.header-3rwiB {
	margin-top: 23px;

	color: var(--text-base);
	font-weight: 700;
	font-size: 24px;
}

.icon-35V8a {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 44px;
	height: 44px;

	border-radius: 50%;

	color: #ffffff;

	background: var(--success);
}

.icon-35V8a svg {
	width: 20px;
	height: 20px;
}

.text-3F2FJ {
	margin: 20px 0;

	color: var(--text-light);
	font-size: 16px;
	line-height: 21px;
	text-align: center;
}

.buttons-3PYeL {
	display: flex;
	flex-direction: column;
	grid-gap: 12px;
	gap: 12px;
}

.text-3F2FJ,
.text-3F2FJ p {
	white-space: pre-wrap;
}

button.button-3-G4Z,
button.button-3-G4Z:hover {
	font-weight: 700;
	font-size: 14px;

	background: var(--active);
}

button.button-3-G4Z.no-37vu3 {
	color: var(--gray50);

	background: var(--gray05);
}

button.button-3-G4Z.no-37vu3:hover,
button.button-3-G4Z.no-37vu3:active,
button.button-3-G4Z.no-37vu3:focus {
	background: var(--gray05);
}

@media screen and (max-width: 600px) {
	div.modal-Kq8PW {
		top: 50%;

		min-height: auto !important;

		transform: translateY(-50%);
	}
}
