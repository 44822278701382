.title-1vf-d {
	margin-top: 0;
	margin-bottom: 24px;

	font-size: 28px;
	color: var(--dark-gray);
	font-weight: 500;
}

.card-3ZOBo {
	display: flex;
	justify-content: space-between;
	padding: 28px;
	max-width: 780px;

	border-radius: 4px;

	background-color: #ffffff;
}

.cardWrapper-31sI_ {
	width: 54%;
}

.miles-2-CGL {
	display: flex;
	flex-direction: column;
	justify-content: center;

	margin-bottom: 17px;
}

.milesStatus__header-1CnaI {
	margin-bottom: 4px;

	color: #727272;
	font-size: 14px;
}

.milesHistory-lGid1 {
	margin-top: 12px;
}

.milesStatus__value-3Pu6- {
	font-size: 27px;
	font-weight: 700;
	line-height: 37px;
	color: #292929;
	text-transform: capitalize;
}

.miles-2-CGL h1 {
	margin: 0;

	color: var(--dark-gray);
	font-size: 31px;
}

.miles-2-CGL [role='progressbar'] {
	height: 8px;
	margin-top: 8px;
	margin-bottom: 8px;

	border-radius: 4px;

	background-color: #ededed;
}

.miles-2-CGL .progress-1rTsf {
	border-radius: 4px;

	background-color: #506dd2;
}

.links-3sBhn {
	display: flex;
	flex-wrap: wrap;
}

.gold-dzlrh .miles-2-CGL .progress-1rTsf {
	background-color: #ff9a01;
}

.silver-3Db__ .miles-2-CGL .progress-1rTsf {
	background-color: #727272;
}

.platinum-1lc5x .miles-2-CGL .progress-1rTsf {
	background-color: #414141;
}

.miles__left-2HVTW,
.miles__total-2DAbN {
	color: #727272;
	font-size: 14px;
}

.miles__left-2HVTW:not(:last-of-type),
.miles__total-2DAbN:not(:last-of-type) {
	padding-bottom: 5px;
}

.miles__left-2HVTW span,
.miles__total-2DAbN span {
	padding-left: 0;
}

.miles__link-XOLyM {
	position: relative;

	height: 20px;
	padding-right: 9px;
	margin-right: 8px;

	font-size: 13px;
	line-height: 200%;

	color: var(--common-blue);
	-webkit-text-decoration-line: underline;
	        text-decoration-line: underline;
	white-space: nowrap;

	cursor: pointer;
}

.miles__link-XOLyM:not(:last-child):after {
	content: '';

	position: absolute;
	top: -2px;

	margin-left: 8px;
	height: 20px;

	border-right: 1px solid #ededed;
}

.divider-1VMzn {
	height: 20px;
	margin: 0 8px;

	border: 1px solid #ededed;
}

.promo-1iI8n {
	margin-top: 12px;
	flex-direction: column;
}

.miles-2-CGL h1 span {
	margin-right: 8px;

	font-size: 49px;
}

.promo__title-2TJ1L {
	margin: 0 0 20px;
	height: 24px;

	font-weight: 500;
	font-size: 22px;
}

.vouchers-p58H9 {
	display: flex;
	flex-wrap: wrap;
}

@media screen and (max-width: 600px) {
	.card-3ZOBo {
		padding: 20px;
	}

	.cardWrapper-31sI_ {
		width: 100%;
	}

	.milesStatus__header-1CnaI {
		color: #292929;
		font-size: 28px;
	}

	.milesStatus__miles-37YWn {
		font-weight: 700;
		font-size: 48px;
	}

	.miles-2-CGL .progress-1rTsf {
		background: linear-gradient(180deg, #ffbb0c 0%, #e57900 100%);
	}

	.miles-2-CGL .miles__left-2HVTW {
		font-size: 14px;
		color: #727272;
	}

	.miles__link-XOLyM {
		font-size: 15px;
		text-decoration: none;
	}

	.card-3ZOBo > div:first-child {
		margin-right: 0;
		display: flex;
		max-width: 100%;
		flex-direction: column;
	}

	.card-3ZOBo > div > div:last-child {
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
	}

	.card-3ZOBo.promo-1iI8n > div:nth-child(2) > div {
		justify-content: flex-start;
	}

	.card-3ZOBo.promo-1iI8n > div:nth-child(2) > div > div > div > div > button > span > span > span > div > div {
		margin-bottom: 0;

		text-transform: none;
		font-weight: 500;
	}

	.card-3ZOBo.promo-1iI8n > .promo__title-2TJ1L {
		margin: 0;
	}

	.miles-2-CGL {
		margin-bottom: 20px;
	}

	.miles-2-CGL h1 {
		display: flex;
		flex-wrap: wrap;
		align-items: baseline;
	}

	.miles-2-CGL h1 span {
		display: flex;
	}

	.vouchers-p58H9 {
		justify-content: center;
		margin-top: 20px;
	}
}

div.loyalty__body-3Sacv {
	display: flex;
	align-items: center;
}

div.loyalty__paper-xEMMG {
	min-width: 420px;
	max-width: 420px;
}

.loyalty__modal-1b8X2 {
	padding: 44px 48px;
}

.loyalty__header-1rRC9 {
	margin-bottom: 28px;

	color: var(--dark-gray);
	font-weight: 500;
	font-size: 20px;
	line-height: 1.6;
}

.loyalty__text-36MPW {
	margin-bottom: 40px;

	color: var(--sureModal-text-color);
	font-size: 17px;
	line-height: 1.7;
}

.loyalty__footer-1AnmL {
	display: flex;
	align-items: center;
	justify-content: flex-end;
}

button.loyalty__footerCancel-2ZPt8 {
	color: var(--sureModal-cancel-color);

	background-color: var(--sureModal-cancel-bg);
}

button.loyalty__footerCancel-2ZPt8:hover {
	background-color: var(--sureModal-cancel-bg);
}

.loyalty__footer-1AnmL button:not(:last-child) {
	margin-right: 12px;
}

/* stylelint-disable-next-line block-no-empty */
.pagination-1hWsD {
}

@media screen and (max-width: 600px) {
	div.loyalty__body-3Sacv {
		flex-direction: column;
		justify-content: flex-end;
	}

	div.loyalty__paper-xEMMG {
		width: 100%;
		min-width: auto;
		min-height: auto;

		border-radius: 8px 8px 0 0;
	}

	.loyalty__modal-1b8X2 {
		padding: 24px 28px;
	}
}
