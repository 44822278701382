.container-3Y43h {
	padding: 16px;
}

@media screen and (max-width: 600px) {
	.container-3Y43h {
		padding-left: 0;
		padding-right: 0;
		padding-top: 0;
	}
}

.vouchersList-3ssPO {
	position: relative;
}

.voucher-1o2-h {
	position: relative;

	display: flex;
	align-items: center;
	margin-bottom: 8px;
}

.voucher__header-PwIor {
	position: relative;

	margin-bottom: 20px;

	color: #9a9a9a;
	font-weight: 500;
	font-size: 19px;
}

.voucher-1o2-h:last-child {
	margin-bottom: 0;
}

.voucher__input-11ppj,
.voucher__read-1wyQP {
	flex-basis: 100%;
	height: 48px;
	padding-left: 12px;

	border: 1px solid var(--cabinet-action);
	border-radius: 2px;

	color: #313131;
	font-weight: 500;
	font-size: 16px;
	text-transform: uppercase;

	background: #ffffff;
}

.voucher__input-11ppj:-ms-input-placeholder {
	text-transform: none;
	color: #9a9a9a;
}

.voucher__input-11ppj::placeholder {
	text-transform: none;
	color: #9a9a9a;
}

.voucher__input-11ppj:focus {
	box-shadow: 0 0 0 1px var(--cabinet-action);
}

.voucher__input_load-1k7Ul {
	border: 2px solid #ededed;
	border-radius: 4px;

	background: #ededed;
}

@media screen and (max-width: 600px) {
	.voucher__input-11ppj,
	.voucher__read-1wyQP {
		border-radius: 4px;
	}
}

.voucher__read-1wyQP {
	display: flex;
	justify-content: space-between;
	padding-right: 10px;

	border: 1px solid #ededed;
	border-radius: 4px;

	color: var(--common-gray);

	line-height: 48px;

	background: #ededed;
	overflow: hidden;
}

.voucher__read-1wyQP span {
	max-width: 100%;
	overflow: hidden;

	text-overflow: ellipsis;
}

.success-2ERq7 {
	position: relative;

	padding-right: 44px;
}

.success-2ERq7:after {
	position: absolute;
	top: 12px;
	right: 12px;

	width: 24px;
	height: 24px;

	background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTQiIHZpZXdCb3g9IjAgMCAxOCAxNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTUuNiAxMC42TDEuNCA2LjRMMCA3LjhMNS42IDEzLjRMMTcuNiAxLjRMMTYuMiAwTDUuNiAxMC42WiIgZmlsbD0iIzJGRDBCRCIvPgo8L3N2Zz4K) center no-repeat;
	content: '';
}

.voucher__right-uinv6 {
	display: flex;
	align-items: center;
}

.voucher__remove-qXqTl {
	width: 24px;
	height: 24px;
	margin-left: 8px;

	cursor: pointer;
	background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyMCAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xLjY2NjAyIDEwLjAwMDNDMS42NjYwMiA1LjM5MTk5IDUuMzkxMDIgMS42NjY5OSA5Ljk5OTM1IDEuNjY2OTlDMTQuNjA3NyAxLjY2Njk5IDE4LjMzMjcgNS4zOTE5OSAxOC4zMzI3IDEwLjAwMDNDMTguMzMyNyAxNC42MDg3IDE0LjYwNzcgMTguMzMzNyA5Ljk5OTM1IDE4LjMzMzdDNS4zOTEwMiAxOC4zMzM3IDEuNjY2MDIgMTQuNjA4NyAxLjY2NjAyIDEwLjAwMDNaTTEyLjk5MSAxNC4xNjdMMTQuMTY2IDEyLjk5MkwxMS4xNzQzIDEwLjAwMDNMMTQuMTY2IDcuMDA4NjZMMTIuOTkxIDUuODMzNjZMOS45OTkzNSA4LjgyNTMyTDcuMDA3NjggNS44MzM2Nkw1LjgzMjY4IDcuMDA4NjZMOC44MjQzNSAxMC4wMDAzTDUuODMyNjggMTIuOTkyTDcuMDA3NjggMTQuMTY3TDkuOTk5MzUgMTEuMTc1M0wxMi45OTEgMTQuMTY3WiIgZmlsbD0iIzlBOUE5QSIvPgo8L3N2Zz4K) center no-repeat;
}

.voucher__remove_hidden-3PUa3 {
	opacity: 0;
	cursor: default;
}

.footer-1QxdZ {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-top: 12px;
}

button.button-mayZG {
	position: relative;

	width: 100%;

	background: var(--common-blue);
}

button.button_loader-1iwGC {
	color: transparent;
}

button.button_disabled-3MEB9 {
	opacity: 0.5;
}

.button_loader-1iwGC:after {
	position: absolute;

	width: 24px;
	height: 24px;
	margin: auto;

	background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAWCAYAAADEtGw7AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAH0SURBVHgBrZXLSltRFIbXSS32Ym1r6c20VCjS0kJLOyq0szoo9DLqwBcRxCfQgVPBuT6CoiCI4sSJTkQUFISo4P1+TTR+v3sFQozBeFzwsZKctb69z95nn0R2SWSz2UrSR3gDtVADFbAL2zAGs1EUrRfrjy4RfoKvcM9l+dz2fMtzP/LekmKkVaQ/8Mgbl2AKUjRvec1LUtIH/+LyDWinZu2CmIb7pF/wEA5gmMIFKxH0aHma4KnL2+hZLRT/c6lm1kfBsV0h6NNyNUMdaL1b6N1P+MUPpLvlSs9nhoTU6jN+Ab/1e8JHfA8ZGChHWiDvtLAvf+XUjGtcmvKCawW9k6RpqIYGiWtdvGjxY9DCU/JZ4koXb1n8mHBxvcR64DPcyo7FDBxL7kvKnrGbDTlNM96T3I9yrMBRb2HGKxJrCdJQZfHjnYVH7ly8aWE5Hlv8+Glhxj0S68WhQ3HHD8u1gl49tg0WZjyWYCdP+LAMys8pSFiZQc8DUpeFjevEuZi7oKNdB281MkRlSKuhF2ZgsFhBBbyC1/DsKjOn5juMQgpGIJm7Vvii16088a/a0EM4ghNuL+s1+qv6AY3wzcISzsF/auaLivMG0Es/d9RF2gVpJ+Pf9f7tsLCu2/mOqMRtaim0w8qneYPMwjgMQXfuL6swzgCq/91tE7S78AAAAABJRU5ErkJggg==) center no-repeat;

	animation: rotation-r6XU4 0.9s infinite linear;
	content: '';
}

.voucher__add-1AHXM {
	position: relative;

	width: 24px;
	height: 24px;

	border-radius: 50%;

	cursor: pointer;
	background: #3290ff;
}

.voucher__add-1AHXM:after {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;

	width: 10px;
	height: 10px;
	margin: auto;

	background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAiIGhlaWdodD0iMTAiIHZpZXdCb3g9IjAgMCAxMCAxMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik05Ljg3NSA1QzkuODc1IDUuMjA3MTEgOS43MDcxMSA1LjM3NSA5LjUgNS4zNzVIMC41QzAuMjkyODkzIDUuMzc1IDAuMTI1IDUuMjA3MTEgMC4xMjUgNVY1QzAuMTI1IDQuNzkyODkgMC4yOTI4OTMgNC42MjUgMC41IDQuNjI1SDkuNUM5LjcwNzExIDQuNjI1IDkuODc1IDQuNzkyODkgOS44NzUgNVY1WiIgZmlsbD0id2hpdGUiLz4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik01IDAuMTI1QzUuMjA3MTEgMC4xMjUgNS4zNzUgMC4yOTI4OTQgNS4zNzUgMC41MDAwMDFMNS4zNzUgOS41QzUuMzc1IDkuNzA3MTEgNS4yMDcxMSA5Ljg3NSA1IDkuODc1VjkuODc1QzQuNzkyODkgOS44NzUgNC42MjUgOS43MDcxMSA0LjYyNSA5LjVMNC42MjUgMC41QzQuNjI1IDAuMjkyODk0IDQuNzkyODkgMC4xMjUgNSAwLjEyNVYwLjEyNVoiIGZpbGw9IndoaXRlIi8+Cjwvc3ZnPgo=) center no-repeat;
	content: '';
}

.voucher__discount-22JSJ {
	color: var(--common-blue);
	line-height: 31px;
}

.voucher__add_notAvailable-Gs98z {
	background: #ededed;
	cursor: default;
}

.voucher__add_notAvailable-Gs98z.voucher__add-1AHXM:after {
	background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAiIGhlaWdodD0iMTAiIHZpZXdCb3g9IjAgMCAxMCAxMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik05Ljg3NSA1QzkuODc1IDUuMjA3MTEgOS43MDcxMSA1LjM3NSA5LjUgNS4zNzVIMC41QzAuMjkyODkzIDUuMzc1IDAuMTI1IDUuMjA3MTEgMC4xMjUgNVY1QzAuMTI1IDQuNzkyODkgMC4yOTI4OTMgNC42MjUgMC41IDQuNjI1SDkuNUM5LjcwNzExIDQuNjI1IDkuODc1IDQuNzkyODkgOS44NzUgNVY1WiIgZmlsbD0iI2NkY2RjZCIvPgo8cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGNsaXAtcnVsZT0iZXZlbm9kZCIgZD0iTTUgMC4xMjVDNS4yMDcxMSAwLjEyNSA1LjM3NSAwLjI5Mjg5NCA1LjM3NSAwLjUwMDAwMUw1LjM3NSA5LjVDNS4zNzUgOS43MDcxMSA1LjIwNzExIDkuODc1IDUgOS44NzVWOS44NzVDNC43OTI4OSA5Ljg3NSA0LjYyNSA5LjcwNzExIDQuNjI1IDkuNUw0LjYyNSAwLjVDNC42MjUgMC4yOTI4OTQgNC43OTI4OSAwLjEyNSA1IDAuMTI1VjAuMTI1WiIgZmlsbD0iI2NkY2RjZCIvPgo8L3N2Zz4K);
}

.info-26HVS {
	position: relative;

	display: inline-block;
	margin-bottom: 10px;
	padding-right: 18px;

	color: #a5a5a5;
	font-weight: 500;
	font-size: 13px;

	cursor: help;
}

.info-26HVS:after {
	position: absolute;
	top: 1px;
	right: 0;

	width: 16px;
	height: 16px;

	background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xLjMzMzAxIDcuOTk5OTJDMS4zMzMwMSA0LjMxOTkyIDQuMzE5NjcgMS4zMzMyNSA3Ljk5OTY3IDEuMzMzMjVDMTEuNjc5NyAxLjMzMzI1IDE0LjY2NjMgNC4zMTk5MiAxNC42NjYzIDcuOTk5OTJDMTQuNjY2MyAxMS42Nzk5IDExLjY3OTcgMTQuNjY2NiA3Ljk5OTY3IDE0LjY2NjZDNC4zMTk2NyAxNC42NjY2IDEuMzMzMDEgMTEuNjc5OSAxLjMzMzAxIDcuOTk5OTJaTTcuOTk5NjcgNy4zMzMyNUM4LjM2Nzg2IDcuMzMzMjUgOC42NjYzNCA3LjYzMTczIDguNjY2MzQgNy45OTk5MlYxMC42NjY2QzguNjY2MzQgMTEuMDM0OCA4LjM2Nzg2IDExLjMzMzMgNy45OTk2NyAxMS4zMzMzVjExLjMzMzNDNy42MzE0OCAxMS4zMzMzIDcuMzMzMDEgMTEuMDM0OCA3LjMzMzAxIDEwLjY2NjZWNy45OTk5MkM3LjMzMzAxIDcuNjMxNzMgNy42MzE0OCA3LjMzMzI1IDcuOTk5NjcgNy4zMzMyNVY3LjMzMzI1Wk03Ljk5OTY3IDEzLjMzMzNDNS4wNTk2NyAxMy4zMzMzIDIuNjY2MzQgMTAuOTM5OSAyLjY2NjM0IDcuOTk5OTJDMi42NjYzNCA1LjA1OTkyIDUuMDU5NjcgMi42NjY1OSA3Ljk5OTY3IDIuNjY2NTlDMTAuOTM5NyAyLjY2NjU5IDEzLjMzMyA1LjA1OTkyIDEzLjMzMyA3Ljk5OTkyQzEzLjMzMyAxMC45Mzk5IDEwLjkzOTcgMTMuMzMzMyA3Ljk5OTY3IDEzLjMzMzNaTTcuOTk5NjcgNC42NjY1OEM4LjM2Nzg2IDQuNjY2NTggOC42NjYzNCA0Ljk2NTA2IDguNjY2MzQgNS4zMzMyNVY1LjMzMzI1QzguNjY2MzQgNS43MDE0NCA4LjM2Nzg2IDUuOTk5OTIgNy45OTk2NyA1Ljk5OTkyVjUuOTk5OTJDNy42MzE0OCA1Ljk5OTkyIDcuMzMzMDEgNS43MDE0NCA3LjMzMzAxIDUuMzMzMjVWNS4zMzMyNUM3LjMzMzAxIDQuOTY1MDYgNy42MzE0OCA0LjY2NjU4IDcuOTk5NjcgNC42NjY1OFY0LjY2NjU4WiIgZmlsbD0iI0NEQ0RDRCIvPgo8L3N2Zz4K) center no-repeat;
	content: '';
}

div.mobileTooltip-20AKK {
	margin-bottom: 0;
	margin-right: 10px;
}

.mobileTooltip-20AKK:after {
	left: auto;
	right: -9px;
	top: 0;
	bottom: 0;

	margin: auto;

	transform: rotate(-90deg);
}

@media screen and (max-width: 600px) {
	.info-26HVS {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;

		width: 24px;
		height: 24px;
		margin: auto;
	}

	.info-26HVS:after {
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;

		width: 20px;
		height: 20px;
		margin: auto;

		background-size: 24px;
	}
}

@keyframes rotation-r6XU4 {
	from {
		transform: rotate(0deg);
	}

	to {
		transform: rotate(359deg);
	}
}
