

.wrapper-2h_da {
	color: var(--text-base);

	background: var(--white);
}

.wrapper_business-mDLaZ {
	background: linear-gradient(180deg, #6a86e6 0%, #3b56b2 100%);
}

.row-1h40G {
	display: flex;
	align-items: center;
	margin: 0 20px;
}

.header-26hEg {
	padding: 20px 0 12px;

	color: var(--text-base);
	font-weight: 700;
	font-size: 18px;
	line-height: 1.3;
	text-transform: uppercase;
}

.header__logo-ydbxf {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 28px;
	height: 28px;
	margin-right: 8px;
}

.header__logo-ydbxf img {
	width: 100%;
	height: 100%;
	object-fit: contain;
}

.info-2yTPU {
	justify-content: space-between;
	padding-bottom: 8px;

	border-bottom: 1px dashed var(--line-dot-line);
}

.info__col-2q-i- {
	flex: 1;
	display: flex;
	flex-direction: column;
}

.info__col-2q-i-:last-child {
	align-items: flex-end;

	text-align: right;
}

.info__iata-3A4c_ {
	color: var(--text-base);
	font-size: 44px;
	font-weight: 700;
	line-height: 1.3;
}

.info__cities-3fzLF {
	display: flex;
	flex-direction: column;

	color: var(--text-light);
	font-size: 13px;
	font-weight: 400;
	line-height: 1.3;
	text-transform: uppercase;
}

.info__time-2cNVH {
	color: var(--text-base);
	font-size: 18px;
	font-weight: 700;
	line-height: 1.3;
}

.disabled-yxGuD .info__iata-3A4c_,
.disabled-yxGuD .info__time-2cNVH {
	color: var(--text-pale);
}

.info__plane-3Kxdx {
	width: 44px;
	min-width: 44px;
	height: 44px;

	color: var(--text-pale);
}

.info__plane-3Kxdx svg {
	width: 100%;
	height: 100%;
}

.arrivalInfo-3FLLT {
	padding: 8px 0 12px;
}

.disabled-yxGuD .arrivalInfo-3FLLT {
	display: none;
}

.arrivalInfo__item-xF9Bl {
	display: flex;
	flex-direction: column;
}

.arrivalInfo__itemLabel-2Bn_C {
	color: var(--text-light);
	font-size: 13px;
	font-weight: 400;
	line-height: 1.3;
	text-transform: uppercase;
}

.arrivalInfo__itemValue-yh4mh {
	color: var(--text-base);
	font-size: 18px;
	font-weight: 700;
	line-height: 1.3;
}
