.root-38Zl3 {
	font-size: 20px;
	line-height: 1.2;
}

.info-1S-kt {
	width: 100%;
	margin-top: 30px;

	font-size: 18px;
	line-height: 1.3;
}

button.copy-uVCB9 {
	margin-left: 10px;
}

@media screen and (max-width: 600px) {
	.root-38Zl3 {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
	}

	button.copy-uVCB9 {
		margin-top: 6px;
		margin-left: 0;
	}
}
