.container-3TZ1x {
	padding: 20px 40px;

	box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.04);
	background: #ffffff;
}

.header-3jUof {
	margin-bottom: 20px;

	display: flex;
	flex-direction: column;

	color: var(--text-base);
	font-size: 22px;
	line-height: 1.3;
	font-weight: 700;
}

.description-1LIcH {
	margin-top: 4px;

	color: var(--text-mid);
	font-size: 16px;
	font-weight: 400;
	line-height: 1.3;
}

.row-32JIG {
	display: flex;
	align-items: center;
}

.row-32JIG:not(:last-child) {
	margin-bottom: 26px;
}

/* stylelint-disable */
.main-2wZsz {
}
/* stylelint-enable */
.field-3_LrE {
	flex: 1;
	width: 100%;
}

.row-32JIG .field-3_LrE:not(:last-child) {
	margin-right: 12px;
}

.controls-114Eq {
	display: flex;
	justify-content: space-between;

	grid-gap: 12px;

	gap: 12px;

	margin-top: 28px;
}

button.submit-34uMM,
button.submit-34uMM:hover {
	color: #ffffff;

	background: var(--active);
}

span.button-27vDo {
	color: #9a9a9a;
}

span.button-27vDo.button_checked-3G1OS {
	color: var(--base);
}

.warningModal-2uX_D a {
	color: var(--text-link);
}

@media screen and (max-width: 600px) {
	.main-2wZsz {
		flex-direction: column;
	}

	.row-32JIG .field-3_LrE:not(:last-child) {
		margin-right: 0;
		margin-bottom: 24px;
	}

	.container-3TZ1x {
		padding: 20px;
	}

	.controls-114Eq {
		flex-direction: column;
	}

	.label-3gkoM {
		font-size: 14px;
	}
}
