.wrapper-33Ylu {
	position: relative;

	width: 100%;

	background-color: var(--quickSearchForm-background);
}

.summary-29fzI {
	max-width: 75%;

	-webkit-user-select: none;

	    -ms-user-select: none;

	        user-select: none;
}

.outer__summary-3hXm3,
.outer__summary_open-jpo3d {
	background: var(--quickSearchForm-background);
}

@media screen and (max-width: 600px) {
	.wrapper-33Ylu {
		height: auto;

		background-color: var(--quickSearchForm-background-mobile);
	}
}

.outer-2I0zS {
	position: relative;
	z-index: 6;
}

.inner-3aHqT {
	position: relative;

	display: flex;
	flex-direction: column;

	width: 100%;
}

.inner__summary-1NaXD {
	display: flex;
	align-items: center;

	max-width: 1100px;
	margin: 0 auto;
}

@media screen and (max-width: 600px) {
	.inner-3aHqT {
		height: 46px;
		display: block;

		box-shadow: none;
	}
}

.form-1r8aF {
	padding-bottom: 20px;

	background: var(--common-blue);
}

.form__wrapper-1piUK {
	max-width: 1100px;
	margin: 0 auto;
	padding: 0;
}

@media screen and (max-width: 600px) {
	.form-1r8aF {
		position: static;

		border-top: none;
	}
}

@media (max-width: 1024px) {
	.summary-29fzI {
		max-width: 100%;
	}

	div.promoCode-2PEr1 {
		margin-left: 6px;
	}

	.form-1r8aF {
		padding-left: 20px;
		padding-right: 20px;
	}
}

.underlay-2wSpU {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;

	cursor: pointer;
	opacity: 0.7;
	background-color: var(--dark-gray);

	transition: opacity 0.2s;
}

.underlay_hidden-2jTRc {
	z-index: -99999;

	opacity: 0;

	pointer-events: none;
}

.children_hidden-33ZaN {
	z-index: -99999;

	opacity: 0;

	animation-duration: 150ms;
	animation-name: hide-31ypt;
}

.promoCode__wrp-37HQQ {
	position: relative;

	display: flex;
	padding: 13px 20px;

	background: var(--quickSearchForm-background-mobile);
}

.promoCode-2PEr1 {
	flex-shrink: 0;
	margin-left: auto;

	color: var(--quickSearchForm-summary-trigger-color);
	font-weight: 400;

	font-size: 14px;

	white-space: nowrap;
}

@media screen and (max-width: 600px) {
	.promoCode-2PEr1 {
		margin: 0 auto;

		color: #ffffff;
	}

	.promoCode__wrp-37HQQ:before {
		position: absolute;
		top: -1px;

		width: calc(100% - 40px);

		border-top: 1px solid #ffffff;
		content: '';
	}

	.form-1r8aF {
		padding: 0;
	}
}

@keyframes hide-31ypt {
	from {
		z-index: 1;

		opacity: 1;
	}

	to {
		z-index: -999999;

		opacity: 0;
	}
}
