button.calendar-24T0l[type='button'] {
	border: none;

	font-weight: 500;
	font-size: 16px;

	color: var(--brand1);

	background-color: transparent;

	background-color: initial;
}

/* stylelint-disable */
.day__day-2FHrP {
}

.day_start-3j23g {
}

.day_end-PsfEn {
}

.day__inside-31NGp {
}

.day_selected-1gjrE {
}
/* stylelint-enable */
.buttons-1Hnql {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	min-height: 50px;
	padding: 0 20px;
}

button.clear-1__tf {
	color: #9a9a9a;
	color: var(--exchange-calendar-clear-button-color, #9a9a9a);

	background-color: #ededed;

	background-color: var(--exchange-calendar-clear-button-bg, #ededed);
}

button.clear-1__tf:hover {
	background-color: #e1e1e1;
	background-color: var(--exchange-calendar-clear-button-hover-bg, #e1e1e1);
}

.buttons-1Hnql button {
	margin-left: 10px;
}

.calendar-24T0l svg {
	margin-left: 6px;
}

div.modal-UQyag {
	border-radius: 12px 12px 0 0;
}

span.closeIcon-2yK1I {
	top: 20px;
	right: 8px;

	width: 32px;
	height: 32px;
	padding: 7px;

	border-radius: 100px;

	color: var(--text-pale);

	background-color: var(--white);
}

.closeIcon-2yK1I:hover {
	background: var(--bg-elements);
}

.closeIcon-2yK1I svg {
	width: 18px;
	height: 18px;

	color: var(--text-pale);
}

/* stylelint-disable */
.day-2YHUV {
	padding: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	background: none;
	color: var(--text-pale);
}

.day_inside-e2q9k {
	width: 36px;
	font-size: 16px;
}

.day_selected-1gjrE .day_inside-e2q9k {
	border-radius: 4px;
}

.day_startPeriod-1qr_R .day_inside-e2q9k {
	border-radius: 4px 0 0 4px;
}

.day_endPeriod-u-0-C .day_inside-e2q9k {
	border-radius: 0 4px 4px 0;
}

.weeklyHeader__dayOfWeekName-1KMxv {
	color: var(--text-pale);
	font-size: 16px;
	font-weight: 500;
}

.day_startPeriod-1qr_R:after,
.day_endPeriod-u-0-C:before {
	display: none;
}

.day_startPeriod-1qr_R .day_inside-e2q9k,
.day_endPeriod-u-0-C .day_inside-e2q9k {
	width: 38px;
}

.day-2YHUV.day_inPeriod-4Trwk .day_inside-e2q9k {
	border-radius: 0;
	width: 100%;
}

.day-2YHUV.day_inPeriod-4Trwk .day_inside-e2q9k:hover {
	border: 1px solid transparent;
	background: var(--brand-1-0);
	color: var(--white);
}

.day-2YHUV.day_inPeriod-4Trwk,
.day-2YHUV.day_weekend-mcsPK.day_inPeriod-4Trwk {
	margin: 0 0.5px;
	background: var(--brand-1-5);
}

.day_notAvailable-JrC5i {
}

.month_month-fFJfy {
	padding-right: 0;
}

div.day_highlight-34cjU:not(.day_notAvailable-JrC5i, .day_inPeriod-4Trwk) {
	border-radius: 4px;
}

.monthHeader__select_month-3wl0h,
.monthHeader__select_year-BrmIu {
	font-size: 18px;
	font-weight: 700;
}

.monthHeader__select_year-BrmIu {
	color: var(--text-pale);
}

div.day_highlight-34cjU > div {
	color: var(--text-base);
	font-weight: 500;
}

.datepicker__paper-25rix {
}

.datepicker__closeIcon-3Ud68 {
}

.datepicker__modal-16Mto {
	margin-bottom: 0;
}

.monthsList-pGD21 {
	display: flex;
	flex-wrap: wrap;
	grid-gap: 40px;
	gap: 40px;
	justify-content: center;
}

@media (max-width: 1024px) {
	button.calendar-24T0l {
		flex: 1;

		height: 48px;

		border-color: var(--base15);
		border-radius: 0;

		color: #ffffff;
		font-weight: 400;
		font-size: 14px;

		background: var(--base15);
	}

	button.calendar-24T0l:focus:hover {
		background: var(--base15);
	}

	.buttons-1Hnql {
		position: absolute;
		bottom: 0;
		z-index: 5;

		justify-content: space-between;
		align-items: center;
		width: 100%;
		height: 48px;
		margin: 0;
		padding: 0 20px;

		background: #ededed;

		background: var(--exchange-calendar-footer-buttons, #ededed);
	}

	button.button-3T48B {
		color: #313131;

		background: transparent;

		background: var(--exchange-calendar-footer-buttons-bg, transparent);
	}

	button.button-3T48B:hover {
		background: transparent;
		background: var(--exchange-calendar-footer-buttons-bg-hover, transparent);
	}

	.monthsWrapper__wrapper-1U_5i nav {
		display: block;
	}
}

@media screen and (max-width: 600px) {
	.datepicker__modal-16Mto {
		padding-bottom: 60px;
	}

	.monthsWrapper__wrapper-1U_5i nav {
		position: relative;
		display: flex;
		align-items: center;
	}

	.monthsWrapper__navPrev-pmiJm,
	.monthsWrapper__navNext-22hjD {
		display: none;
	}

	.monthHeader__header-NQCAc {
		height: 32px;
		line-height: 32px;
	}

	.monthsList-pGD21 {
		flex-direction: column;
	}

	span.closeIcon-2yK1I {
		top: 13px;
		right: 12px;
		background-color: var(--bg-fill);
	}

	.closeIcon-2yK1I svg {
		color: var(--text-light);
	}

	.day-2YHUV {
		font-size: 16px;
	}
}

/* stylelint-enable */
