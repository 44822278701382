.container-fdtlD {
	padding: 24px 48px;

	background: var(--common-blue);
}

.inner-Z7tEN {
	max-width: 1100px;
	margin: 0 auto;
	display: flex;
}

button.submit-WCgOa {
	background: var(--start-search-button);
}

button.submit-WCgOa:hover {
	background: var(--start-search-button-hover);
}

@media screen and (max-width: 600px) {
	.inner-Z7tEN {
		flex-direction: column;
	}

	.container-fdtlD {
		padding: 16px 20px;
	}
}
