.memberItem-3kEGJ {
	position: relative;

	display: flex;
	flex-direction: column;
	align-items: center;
	width: 220px;

	line-height: 1.3;
}

@media screen and (max-width: 600px) {
	.memberItem-3kEGJ {
		display: grid;
		grid-template-columns: 36px 1fr 20px;
		grid-gap: 12px;
		gap: 12px;
		width: 100%;
		padding: 20px;

		border-radius: 6px;

		background-color: var(--white);
	}
}

.memberItem__avatar-VPqC1 {
	display: flex;
	flex-shrink: 0;
	justify-content: center;
	align-items: center;
	width: 120px;
	height: 120px;

	border-radius: 50%;

	font-size: 31px;
	font-weight: 500;

	color: var(--white);

	background-color: var(--soft-violet-2);
}

@media screen and (max-width: 600px) {
	.memberItem__avatar-VPqC1 {
		width: 36px;
		height: 36px;

		font-size: 16px;
		font-weight: 700;
	}
}

.memberItem__avatarSelf-Q6fad {
	background-color: var(--bright-violet);
}

.memberItem__data-2D-W1 {
	display: flex;
	flex-direction: column;
	margin-top: 8px;
	grid-gap: 4px;
	gap: 4px;

	text-align: center;
}

@media screen and (max-width: 600px) {
	.memberItem__data-2D-W1 {
		margin-top: 0;
		grid-gap: 0;
		gap: 0;

		text-align: left;
	}
}

.memberItem__dataName-3VU3x {
	font-size: 18px;
	font-weight: 500;
	line-height: 1.3;

	word-break: break-all;

	color: var(--text);
}

.memberItem_typeAdd-1JP90 .memberItem__dataName-3VU3x {
	color: var(--bright-violet);
}

.memberItem__dataInfo-Cn-5q {
	font-size: 14px;
	font-weight: 400;

	color: var(--text-mid);
}

.memberItem_typeAdd-1JP90 .memberItem__dataInfo-Cn-5q {
	color: var(--light-violet);
}

.memberItem__buttonRemove-3muJq {
	display: flex;
	align-items: center;
	margin-top: 6px;

	cursor: pointer;
}

@media screen and (max-width: 600px) {
	.memberItem__buttonRemove-3muJq {
		margin-top: 0;
	}
}

.memberItem__buttonRemove-3muJq svg {
	width: 20px;
	height: 20px;
	margin-right: 4px;

	color: var(--gray30);
}

@media screen and (max-width: 600px) {
	.memberItem__buttonRemove-3muJq svg {
		margin-right: 0;
	}
}

.buttonRemove__text-1VSuu {
	font-size: 14px;
	font-weight: 400;

	color: var(--text-light);
}

@media screen and (max-width: 600px) {
	.buttonRemove__text-1VSuu {
		display: none;
	}
}
