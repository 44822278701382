.iconWrapper-1Y5J0 {
	margin: 0 auto;

	display: flex;
	align-items: center;
	justify-content: center;

	width: 64px;
	height: 64px;

	border-radius: 50%;

	color: var(--error-text);
	line-height: 0;

	background: var(--error-bg);
}

.iconWrapper-1Y5J0 svg {
	width: 36px;
	height: 36px;
}

div.root-1-oZF {
	max-width: 520px !important;
	padding: 32px 40px;
}

.header-hIOME {
	margin: 16px 0 0;

	text-align: center;
}

.listWrapper-1wx9g {
	display: flex;
	flex-direction: column;
	grid-gap: 16px;
	gap: 16px;

	margin-top: 12px;

	color: var(--text-light);
	font-weight: 400;
	font-size: 18px;
}

.list-3oIdF {
	font-size: 18px;
	font-weight: 400;
}

.listContent-41C2l {
	font-size: 16px;
	white-space: break-spaces;
}

.listContent-41C2l a {
	color: var(--text-link);
}

/* stylelint-disable */
.listContent_main-12x54 {
}
/* stylelint-enable */
button.button-jpK7w {
	min-width: 370px;
	max-width: 370px;
	padding: 14px;
	margin: 0 auto;
}

button.button-jpK7w:first-of-type {
	margin-top: 36px;
}

button.button-jpK7w:last-of-type {
	margin-top: 12px;
}

@media screen and (max-width: 600px) {
	button.button-jpK7w {
		min-width: 100%;
		max-width: 370px;
	}
}
