.wrapper-20elv {
	display: flex;
	flex-direction: column;
}

.card-O9UN7 {
	display: flex;

	box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.04);
}

.locations-1Kdt8 {
	display: flex;
	flex: 1 0 60%;
	flex-direction: column;
}

.location-2M0Mb:first-child {
	border-top-left-radius: 4px;
	border-bottom: 1px solid #d9d9d9;
}

.location-2M0Mb:last-child {
	border-bottom-left-radius: 4px;
}

div.flight-3fhiO {
	border-left: 1px solid #d9d9d9;

	box-shadow: none;
}

div.transfer-VEYBG {
	margin-top: 10px;

	padding: 12px 20px;

	border: 1px solid #d9d9d9;
	border-radius: 4px;

	font-weight: 500;
	font-size: 14px;
	line-height: 1.3;

	background-color: var(--warning20);
}

.isMobile-uE3Cc .card-O9UN7 {
	flex-direction: column;

	border-radius: 4px;

	box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.04);
}

.isMobile-uE3Cc .locations-1Kdt8 {
	flex-direction: row;
}

.isMobile-uE3Cc .location-2M0Mb {
	width: 50%;
}

.isMobile-uE3Cc .location-2M0Mb:first-child {
	border-right: 1px solid #d9d9d9;
	border-bottom: none;
	border-radius: 4px 0 0 0;
}

.isMobile-uE3Cc .location-2M0Mb:last-child {
	border-radius: 0 4px 0 0;
}

.isMobile-uE3Cc .flight-3fhiO {
	border-left: none;
	border-right: none;
	border-radius: 0 0 4px 4px;
}
