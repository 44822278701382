

button.root-1pqWn {
	width: 32px;
	min-width: unset;
	height: 32px;
	min-height: unset;

	padding: 7px;

	border-radius: 50%;
}

button.root-1pqWn svg {
	width: 24px;
	height: 24px;
}

button.root-1pqWn.prev-2gvrU,
button.root-1pqWn.next-1rm_1 {
	color: var(--text-mid);

	box-shadow: 4px 7px 26px rgba(0, 0, 0, 0.1);
	background: var(--white);
}

button.root-1pqWn.prev-2gvrU:hover:not(.disabled-TKwwU),
button.root-1pqWn.next-1rm_1:hover:not(.disabled-TKwwU) {
	color: var(--white);

	box-shadow: 4px 4px 33px rgba(0, 0, 0, 0.05);
	background: var(--brand-1-0);
}

button.root-1pqWn.goTo-3deig {
	color: var(--brand-1-0);

	background: var(--white);
}

button.root-1pqWn.disabled-TKwwU {
	color: var(--line-dot-line);

	background: var(--white);
}
