

.wrapper-35D81 {
	display: flex;
	flex-direction: column;
	overflow: hidden;

	width: 100%;

	border-radius: 4px;

	color: #ffffff;

	background-color: var(--common-blue);
}

.header-3MUBL {
	align-self: stretch;
	padding: 16px 16px 14px 16px;
}

.airport-1WDez {
	display: flex;
	align-items: center;

	opacity: 0.6;
}

.direction-38CxR {
	display: flex;
	align-items: center;
	margin-right: 9px;
}

.direction-38CxR svg {
	width: 36px;
	height: 36px;
}

.airport__iata-1LrhP {
	font-size: 39px;
	font-weight: 600;
	text-transform: uppercase;
}

.airport__name-1jm0u {
	margin-left: 9px;

	font-size: 13px;
	line-height: 130%;
}

.bottom-3rqhL {
	margin-top: 7px;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.when-3ERRJ {
	display: flex;
	align-items: center;

	font-size: 20px;
	font-weight: 600;
	line-height: 120%;
	text-transform: uppercase;
}

.date-sI3Ok {
	margin-left: 6px;
}

.terminal-3rQRc {
	display: flex;
	align-items: flex-end;

	flex-wrap: wrap;

	font-size: 13px;
	line-height: 140%;
}

.terminal__code-2D6uc {
	margin-left: 5px;

	font-size: 20px;
	font-weight: 600;
	line-height: 120%;
	text-transform: uppercase;
}

.footer-LUqi_ {
	position: relative;

	display: flex;
	justify-content: space-between;
	align-items: flex-end;
	flex-grow: 1;

	min-height: 118px;
	padding: 16px;

	background-color: var(--base15);
	background-size: cover;
	background-repeat: no-repeat;
}

.footer__direction-2f22a {
	position: absolute;

	top: 8px;
	left: 50%;

	transform: translate(-50%, 0);
	opacity: 0.4;
}

.footer__direction-2f22a svg {
	width: 72px;
	height: 72px;
}

.weather-1LTsE {
	display: flex;
	align-items: center;
}

.weather-1LTsE svg {
	width: 28px;
	height: 28px;
}

.weather__value-8gURU {
	margin-left: 5px;

	font-size: 20px;
	line-height: 100%;
}

.details-2-dcm {
	display: flex;
	align-items: center;

	font-size: 13px;
	font-weight: 500;
	line-height: 28px;

	cursor: pointer;
}

.details-2-dcm svg {
	margin-left: 4px;
}
