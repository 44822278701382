.container-XZeSb {
	display: flex;
	align-items: center;
	padding: 20px;
}

.info-2D4wj {
	flex: 1;
	display: flex;
	align-items: center;
}

.passenger-ObtMO {
	display: flex;
	flex-direction: column;
}

.passenger__label-32rWP {
	display: none;

	color: var(--text-light);
	font-size: 13px;
	font-weight: 400;
	line-height: 1.3;
	text-transform: uppercase;
}

.passenger__name-1F-Ky {
	color: var(--text-base);
	font-size: 22px;
	font-weight: 700;
	line-height: 1.3;
	text-transform: capitalize;
}

.seats-34j1J {
	display: flex;
	align-items: center;

	margin-right: 20px;
	margin-left: auto;
	padding-right: 20px;

	border-right: 1px solid var(--line-separator);
}

.seats__label-eUGLT {
	display: none;

	color: var(--text-light);
	font-size: 13px;
	font-weight: 400;
	line-height: 1.3;
	text-transform: uppercase;
}

.seats__icon-pFC9i {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 32px;
	min-width: 32px;
	height: 32px;
	margin-right: 4px;

	color: var(--brand-1-0);
}

.seats__text-ywKLd {
	color: var(--text-base);
	font-size: 22px;
	font-weight: 700;
	line-height: 1.3;
}

.controls-3_BQR {
	display: flex;
	align-items: center;
	max-width: 60%;
	margin-left: auto;
}

@media (max-width: 768px) {
	.container-XZeSb {
		flex-direction: column;
		align-items: inherit;
	}

	.info-2D4wj {
		margin-bottom: 16px;
	}

	.seats-34j1J {
		margin-right: 0;
		padding-right: 0;

		border-right: none;
	}

	.controls-3_BQR {
		margin-left: 0;
	}
}

@media screen and (max-width: 600px) {
	.container-XZeSb {
		margin: 0 20px;
		padding: 0;

		border-bottom: 1px dashed var(--line-dot-line);
	}

	.info-2D4wj {
		margin-bottom: 0;
		padding: 12px 0;

		border-bottom: 1px dashed var(--line-dot-line);
	}

	.container-XZeSb.disabled-3zQP6,
	.disabled-3zQP6 .info-2D4wj {
		border-bottom: none;
	}

	.passenger-ObtMO {
		max-width: 80%;
	}

	.disabled-3zQP6 .passenger-ObtMO {
		width: 100%;
		max-width: 100%;
		align-items: center;
	}

	.passenger__label-32rWP {
		display: block;
	}

	.passenger__name-1F-Ky {
		color: var(--brand-1-0);
		font-size: 18px;
		text-transform: uppercase;
	}

	.seats-34j1J {
		flex-direction: column;
		align-items: flex-end;

		text-align: right;
	}

	.seats__label-eUGLT {
		display: block;
	}

	.seats__icon-pFC9i {
		display: none;
	}

	.seats__text-ywKLd {
		font-size: 18px;
	}

	.controls-3_BQR {
		max-width: 100%;
		padding: 12px 0;
	}

	.disabled-3zQP6 .controls-3_BQR {
		display: none;
	}
}
