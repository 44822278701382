.wrapper-XFyJj {
	padding: 44px 48px;
}

.title-eN3UC {
	margin-bottom: 28px;

	color: var(--dark-gray);
	font-weight: 500;
	font-size: 20px;
	line-height: 1.6;
}

.text-1v97m {
	margin-bottom: 40px;

	color: var(--sureModal-text-color);
	font-size: 17px;
	line-height: 1.7;
}

.footer-3R2xS {
	display: flex;
	align-items: center;
	justify-content: flex-end;
}

@media {
	.wrapper-XFyJj {
		padding: 24px 28px;
	}
}
