.loyaltyConnect-W2OIN {
	padding: 0;
	flex-direction: column;
	grid-gap: 20px;
	gap: 20px;
}

.block__header__valid-m_0l4 {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 24px;
	height: 24px;

	border-radius: 24px;

	color: var(--success-icon);
}

.block__header__invalid-yWySt {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 24px;
	height: 24px;

	border-radius: 24px;

	color: var(--error-icon);

	cursor: pointer;
}

.block__header__invalid-yWySt svg {
	width: inherit;
	height: inherit;
}

.createTitle-Nijhz {
	display: flex;
	flex-direction: row;
	grid-gap: 16px;
	gap: 16px;
	align-items: center;
	justify-content: flex-start;
}

.createTitle-Nijhz > div {
	display: flex;
	align-items: center;
}

.header-3TwbQ {
	color: var(--text-base);
	font-size: 28px;
	font-weight: 600;
	line-height: 1.3;
}

.form__wrapper-30-mm {
	display: flex;
	margin-top: 40px;
}

.form__content-3iBJK {
	display: flex;
	grid-gap: 30px;
	gap: 30px;
	flex-direction: column;
	width: 100%;
	padding: 24px 0;
}

.block-2YMB1 {
	display: flex;
	flex-direction: column;
	align-items: center;
	grid-gap: 20px;
	gap: 20px;
}

.block__headerWrapper-5Tb6F {
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	grid-gap: 20px;
	gap: 20px;
	align-self: stretch;
}

.block__header-nlZWH {
	display: flex;
	padding: 12px;
	justify-content: space-between;
	align-items: center;
	grid-gap: 12px;
	gap: 12px;
	align-self: stretch;

	border-radius: 4px;

	background: var(--bg-fill);
}

.block__header__titleWrapper-1rbWB {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	grid-gap: 12px;
	gap: 12px;
}

.block__header__titleNumber-1Xl3y {
	width: 32px;
	height: 32px;
	display: flex;
	align-items: center;
	justify-content: center;

	border-radius: 32px;

	color: var(--text-mid);
	font: var(--medium--1-desktop-bold);

	background: var(--bg-white);
}

.block__header__title-2sapk {
	color: var(--text-base);
	font: var(--medium-desktop-accent);
}

.block__header__subtitle-3UU77 {
	width: 100%;

	color: var(--text-mid);
	font: var(--medium--1-normal);
}

.groupCreate-3RRPH {
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	grid-gap: 8px;
	gap: 8px;
}

.groupCreate__field-1iJMW > div {
	width: 100%;
}

.groupCreate__field-1iJMW {
	position: relative;

	display: flex;
	width: 100%;
	margin-bottom: 0;
	align-self: stretch;
	align-items: center;
	flex-grow: 1;
}

.groupCreate__field-1iJMW.groupCreate__field_switch-20dsY {
	display: flex;
	flex: 0;
	align-items: center;
}

.groupCreate-3RRPH .groupCreate__field-1iJMW p {
	position: absolute;
	bottom: -18px;
	left: 0;

	margin: 0;
	padding: 0;

	width: auto;

	transform: translateY(7%);
}

.group-3E5go {
	display: flex;
	flex-direction: row;
	align-items: center;
	margin-top: 20px;
}

.group__field-1AKmy {
	min-width: 50%;
	margin-bottom: 8px;
	align-self: stretch;
}

.group__field-1AKmy:only-child {
	max-width: 100%;
}

.group-3E5go .group__field-1AKmy:not(:last-child) {
	padding-right: 20px;
}

.group-3E5go .group__field-1AKmy p {
	width: auto;

	margin-left: 0;
	margin-right: 0;
}

.group__field-1AKmy > div {
	width: 100%;
}

.group__field-1AKmy.group__field_switch-3dFBc {
	flex: 0;
	min-width: 120px;
}

.buttons__wrapper-odoWe {
	display: flex;
	margin-top: 20px;
	align-items: center;
	justify-content: space-between;
}

.buttons__wrapper-odoWe a {
	text-decoration: none;
	font-size: 15px;
}

.actions-1YcBu {
	display: flex;
	flex-direction: column;
}

.actions-1YcBu a:not(:last-child) {
	margin-bottom: 10px;
}

.buttons__action-39SV4 {
	display: flex;
	flex-shrink: 0;
	align-items: center;

	color: var(--gray30);
	font-size: 15px;
}

.buttons__action-39SV4 .actions__success-r74WZ {
	margin-right: 15px;
}

.errors-2fr0w {
	margin: 20px 0;

	color: var(--error);
	font-size: 14px;
}

.success-1miR0 {
	margin-top: 40px;

	color: #333333;
	font-size: 16px;
}

.success-1miR0 .buttons__wrapper-odoWe {
	justify-content: flex-end;
}

div.warning__modal-z0hv2 {
	z-index: 1601 !important;
}

/* stylelint-disable */
.login__modal_content-2Mwkz {
}
/* stylelint-enable */

@media screen and (max-width: 600px) {
	.loyaltyConnect-W2OIN {
		padding: 0;
	}

	.header-3TwbQ {
		padding-right: 40px;

		font-size: 24px;
	}

	.group-3E5go {
		margin-top: 20px;
		grid-gap: 15px;
		gap: 15px;
	}

	.group-3E5go .group__field-1AKmy {
		width: 50%;
		min-width: auto;
	}

	.group-3E5go .group__field-1AKmy:not(:last-child) {
		padding-right: 0;
	}

	.group-3E5go .group__field-1AKmy p {
		transform: translateY(140%);
	}

	.buttons__wrapper-odoWe {
		align-items: flex-start;
		flex-direction: column;
		grid-gap: 10px;
		gap: 10px;
	}

	div.button-6ELYw,
	button.button-6ELYw {
		margin-top: 20px;
		margin-left: auto;
	}

	.switch__root-2RFzO {
		margin-top: 0;
		margin-bottom: 0;
	}

	.switch__label-2I0DF {
		padding-left: 28px;
	}
}
