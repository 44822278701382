.header-2VnIK {
	position: relative;

	display: flex;
	align-items: center;
	height: 120px;
	padding: 20px 50% 20px 40px;

	background: var(--seatMap-banner-background);
	background-image: url('https://cdn.websky.aero/content/frontend/images/banner-preselected-service-seats.svg');
	background-repeat: no-repeat;
	background-position: calc(100% + 115px) 20px;
}

.header_mobileFullScreen-7Mp5I {
	background-position: calc(100% + 75px) 20px;
}

@media screen and (max-width: 600px) {
	.header-2VnIK {
		padding-left: 20px;
	}
}

.header-2VnIK h1 {
	position: relative;
	z-index: 1;

	margin: 0;

	color: #ffffff;
	font-size: 24px;
	font-weight: 400;
	line-height: 130%;
}

.text-xN2LJ {
	padding: 24px 40px;

	color: var(--common-gray);
	font-size: 17px;
	line-height: 150%;
}

.body-1nK5l {
	padding: 0 40px;
}

@media screen and (max-width: 600px) {
	.text-xN2LJ {
		padding: 24px 20px;
	}

	.body-1nK5l {
		padding: 0 20px;
	}
}

@media screen and (max-width: 320px) {
	.header-2VnIK h1 {
		font-size: 20px;
	}

	.text-xN2LJ {
		display: none;
	}

	.body-1nK5l {
		padding: 20px 20px 0;
	}
}

.summary-2R4Q6 {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding-bottom: 12px;

	border-bottom: 1px solid #e4e4e4;
}

@media screen and (max-width: 600px) {
	.summary-2R4Q6 {
		padding-bottom: 0;

		border-bottom: none;
	}
}

.summary__seats-vURRX {
	line-height: 48px;
	font-size: 32px;
	font-weight: 500;
}

.summary__price-3kn3P {
	line-height: 30px;
	font-weight: 500;
	font-size: 20px;
	color: var(--common-blue);
}
