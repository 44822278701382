.wrapper-1EY7t {
	position: relative;

	padding: 30px 32px 32px;

	border-radius: 20px;

	background-color: var(--white);
}

.close-S6QMY {
	position: absolute;
	top: 28px;
	right: 32px;

	width: 32px;
	height: 32px;

	border-radius: 50%;

	cursor: pointer;
	background-color: #f6f6f6;
}

.close-S6QMY:before,
.close-S6QMY:after {
	content: '';

	position: absolute;
	top: 50%;
	left: 50%;

	width: 50%;
	height: 1px;

	background-color: #262626;
	transform: translate(-50%, -50%) rotate(-45deg);
}

.close-S6QMY:after {
	transform: translate(-50%, -50%) rotate(45deg);
}

.title-rsJ9P {
	margin-bottom: 22px;

	padding-right: 40px;

	font-weight: 700;
	font-size: 22px;
	line-height: 1.3;
}

.passengers-GecWc {
	display: flex;
	flex-direction: column;
}

.disabilities-3JbuM {
	width: 100%;
	margin-top: 10px;
	padding: 10px 0;

	border-top: 1px dashed var(--line-separator);

	color: var(--text-light);
}

.disabilities__header-226Bk {
	margin-bottom: 10px;

	font-size: 16px;
	font-weight: 700;
}

.item-2XYqJ {
	display: flex;
	flex-direction: column;
	padding: 16px 0;
}

.item-2XYqJ:last-child {
	margin-bottom: 0;
}

.accompanied-5K2Qi {
	width: 100%;
	margin-top: 15px;
}

.accompanies-C3sKO {
	display: flex;
	justify-content: center;
	width: 100%;
	margin-top: 15px;

	border-top: 1px dashed var(--line-separator);

	color: var(--text-light);

	background-color: var(--white);
}

.accompanies__text-mLyHn {
	width: -moz-fit-content;
	width: fit-content;
	margin-top: -10px;
	padding: 0 10px;

	background-color: var(--white);
}

.passenger-2XcTt {
	display: flex;
	align-items: flex-start;
}

.item-2XYqJ .accompanied-5K2Qi:first-child {
	border-top: 1px dashed var(--line-separator);
}

.item_relinking-1d2en {
	margin-bottom: 0;
	padding: 16px 0 0 0;

	border: none;
}

button.confirmRelink-2_pl-,
div.confirmRelink-2_pl- {
	margin-top: 25px;
}

span.passenger__checkbox-yk-Bc {
	margin-right: 10px;
}

.number-2LxCs {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-shrink: 0;
	width: 40px;
	height: 40px;
	margin-right: 16px;

	border-radius: 50%;

	color: var(--white);
	font-weight: 700;
	line-height: 1.3;

	background-color: #5fca43;
	overflow: hidden;
}

.content-eqc7C {
	display: flex;
	align-items: center;
	justify-content: space-between;
	grid-gap: 10px;
	gap: 10px;
	width: 100%;
}

.item_content-1hj10 {
	display: flex;
	flex-direction: column;
	padding-top: 8px;
	flex: 1;
}

.item_relinking-1d2en .item_content-1hj10 {
	padding-top: 0;
}

.name-1osLy {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 4px;

	color: #262626;
	font-weight: 700;
	font-size: 18px;
	line-height: 1.3;
}

.item_relinking-1d2en .name-1osLy {
	cursor: pointer;
}

.name__link-2H0pi {
	color: var(--text-mid);
	font-size: 14px;
	font-weight: 400;
	-webkit-text-decoration-line: underline;
	        text-decoration-line: underline;

	cursor: pointer;
}

.info-1gk8S {
	display: flex;
	flex-direction: column;

	color: var(--gray70);
	font-size: 16px;
	line-height: 1.3;
}

.item_relinking-1d2en .info-1gk8S {
	margin: 5px 0 0 -32px;
}

.info-1gk8S > span {
	margin-bottom: 2px;
}

.info-1gk8S > span:last-child {
	margin-bottom: 0;
}

button.button-2dWGN {
	border: 1px solid var(--brand-1-3);

	color: var(--brand-1-0);

	background: var(--white);
}

button.button-2dWGN:hover {
	border-color: var(--brand-1-4);

	background: var(--brand-1-4);
}

@media screen and (max-width: 600px) {
	.wrapper-1EY7t {
		padding: 20px;

		border-radius: 0;
	}

	.close-S6QMY {
		top: 20px;
		right: 20px;
	}
}
