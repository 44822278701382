/* stylelint-disable */
.container-1j9wp {
}

.mobileStepBar-2Q3Hq {
}

.stepBar-22Kvq {
}
/* stylelint-enable*/
div.saveContactInfo__modal-3neu4 {
	top: 50%;

	max-width: 600px !important;

	margin: 0 auto;
	padding: 28px 28px 40px 28px;

	transform: translateY(-50%);
	overflow: visible;
}

.saveContactInfo__title-3o2Bt {
	display: inline-block;
	margin-bottom: 28px;

	color: var(--dark-gray);
	font-weight: 500;
	font-size: 22px;
	line-height: 31px;
}

.timelimit__wrp-2dpBz {
	margin-top: 40px;
}

@media screen and (max-width: 600px) {
	.timelimit__wrp-2dpBz {
		margin-top: 0;
	}

	.paymentResult-2g9nM {
		min-height: 100vh;

		padding: 20px;

		background: var(--bg-fill);
	}
}
