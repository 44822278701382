.groupOrder-3gAMG {
	width: 100%;
	padding-bottom: 85px;
}

.header-1Grti {
	display: flex;
	align-items: center;
	height: 69px;

	font-weight: 700;
	font-size: 24px;

	box-shadow: 0 7px 20px rgba(0, 0, 0, 0.03);
	background: #ffffff;
}

.container-3ypPJ {
	max-width: 1100px;
	width: 100%;
	margin: 0 auto;
}

.flight-3aXS4 {
	margin-top: 28px;
}

.passengers-36_Sz {
	margin-top: 12px;
}

.passenger-3DKWq,
label.passenger-3DKWq {
	width: 100%;
	min-height: 69px;
	margin-right: 0;
	margin-left: 0;
	padding: 20px;

	border-bottom: 1px dashed #c2c2c2;

	background: #ffffff;
}

.passenger-3DKWq.addPassenger-2Cmjo {
	display: flex;
	align-items: center;
	min-height: 84px;
	padding: 20px;

	color: var(--base);
	font-weight: 700;
	font-size: 16px;

	cursor: pointer;
}

.editPassenger-7rzuS {
	width: 100%;
}

label.passenger-3DKWq.error-YIcUN {
	color: var(--error);
}

span.passenger__label-1n9T_ {
	width: 100%;
	margin-left: 14px;

	font-weight: 700;
	font-size: 22px;
}

.passengerValue-3LMYc {
	display: flex;
	flex-direction: column;
}

span.errorHint-1znBF {
	font-size: 13px;
	font-weight: 400;
}

span.checkbox-2y1jq {
	margin: 0;
}

label.add-5mjjw {
	display: inline-flex;
	align-items: center;
	width: 100%;

	cursor: pointer;
}

.addIcon-15Myl {
	display: inline-flex;
	flex-shrink: 0;
	justify-content: center;
	align-items: center;
	width: 32px;
	height: 32px;
	margin-left: 8px;

	border-radius: 50%;

	color: #ffffff;

	background: var(--base);
}

span.checkboxColor-2sNZQ,
span.checkboxColor-2sNZQ.checked-tXf2J {
	color: var(--base);
}

.notifications-3-KgD {
	margin-top: 12px;
}

.notification__item-3Mtqo:not(:last-child) {
	margin-bottom: 12px;
}

@media screen and (max-width: 600px) {
	.passengers-36_Sz {
		padding: 12px;
		margin-top: 0;
	}

	label.passenger-3DKWq {
		padding: 12px;
	}

	.passenger-3DKWq.addPassenger-2Cmjo {
		border-bottom: 0;
	}

	.addPassenger-2Cmjo > div {
		width: 100%;
	}

	button.addButton-z9Az_ {
		width: 100%;
		padding: 10px;

		border-radius: 4px;

		color: var(--base-15);
		font-weight: 600;
		font-size: 16px;

		background: var(--base80);
	}

	span.passenger__label-1n9T_ {
		font-size: 18px;
	}

	.header-1Grti {
		padding: 0 12px;

		font-size: 22px;
	}

	.flight-3aXS4 {
		padding: 12px;
		margin-top: 0;
	}

	.segment-8rL-e {
		box-shadow: 4px 9px 38px rgba(0, 0, 0, 0.1);
	}
}
