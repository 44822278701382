

label.groupTitle-3dpRx {
	font-weight: 500;
	color: var(--gray80);
}

fieldset.groupWrapper--ZkXW {
	display: block;
}

label.groupTitle-3dpRx.active-2TdPE {
	color: var(--gray80);
}

.reasons-KrjQt .reason-3d3Zy {
	padding: 20px 0;

	border-bottom: 1px solid var(--gray10);
}

.reasons-KrjQt .reason-3d3Zy:last-of-type {
	border-bottom: none;
}
