

.selectedFare-2WNUg {
	display: flex;
}

.image-1eKba {
	width: 280px;

	border-top-left-radius: 16px;
	border-bottom-left-radius: 16px;
}

.content-2KX3y {
	flex: 1;
	padding: 17px 40px 0;

	border-top-right-radius: 16px;
	border-bottom-right-radius: 16px;

	background: var(--white);
}

@media screen and (max-width: 1300px) {
	.content-2KX3y {
		padding: 20px 40px;
	}
}

.title-26znw {
	display: flex;
	justify-content: space-between;
	margin: 0 0 16px;
	padding: 0;

	color: var(--text-base);
	font-weight: 600;
	font-size: 23px;
	line-height: 23px;
}

@media screen and (max-width: 1300px) {
	.title-26znw {
		margin-bottom: 10px;
	}
}

a.link-2bDs2 {
	display: inline-flex;
	align-items: center;

	color: var(--brand-1-0);
	font-size: 15px;
	font-weight: 400;
	text-decoration: none;
}

.features__list-3wpH1 {
	display: grid;
	grid-template: repeat(auto-fill, 30px) / repeat(auto-fill, minmax(230px, 1fr));
	grid-column-gap: 50px;
	margin: 0 0 16px;
	padding: 0;

	color: var(--text-mid);
	font-size: 14px;

	list-style-type: none;
}

.controls-11Ifx {
	margin-left: auto;
}

.controls-11Ifx .link-2bDs2:nth-of-type(2) {
	margin-left: 16px;
	padding-left: 16px;

	border-left: 1px solid var(--gray20);
}

div.fareGroup-1UsHP {
	min-height: auto;
	max-width: 420px;
	margin: 0 auto;
	width: 100%;
}

div.fareGroup-1UsHP button {
	display: none;
}

.features__item-l4KN0 {
	display: flex;
	align-items: center;
	padding: 8px 0;

	border-bottom: 1px solid var(--line-separator);
}

.link__chevron-3haYD {
	width: 24px;
	height: 24px;

	color: var(--gray30);
}

.serviceIcon-FT60j {
	position: relative;
	top: 1px;

	margin-right: 17px;
}

.icon-3FTvu {
	position: relative;
	top: 2px;

	margin-left: auto;
}

.serviceIcon-FT60j,
.icon-3FTvu {
	color: var(--text-light);
}

.icon-3FTvu .icon_included__check {
	color: var(--success-icon);
}

div.popup__close-37pOn {
	position: absolute;
	right: 20px;
	top: 20px;
	z-index: 3;

	background-color: var(--bg-fill);
}

div.popup__paperScrollBody-3Q8lk {
	position: relative;
}
