div.paper-AfOtX {
	max-width: 680px;
	padding: 40px;
}

@media screen and (max-width: 600px) {
	div.paper-AfOtX {
		padding: 20px;
	}
}

.content-JVAMC {
	display: flex;
	flex-direction: column;
	grid-gap: 24px;
	gap: 24px;
	padding: 20px 0;
}

.serviceName-38Cgz {
	font: var(--medium-1-mobile-bold);
}

.serviceDescription-1yBDY {
	padding: 12px 0;

	font: var(--normal-mobile);
}

.heading-3NIbL {
	display: flex;
	align-items: baseline;
	margin-bottom: 12px;
	padding: 8px 12px;

	border-top-left-radius: 4px;
	border-top-right-radius: 4px;

	background: var(--brand-1-5);
}

.date-3c4XZ,
.cost-2U6BE {
	color: var(--text-mid);
	font: var(--normal-mobile);
}

.date-3c4XZ {
	padding-right: 20px;
	margin-right: 20px;

	border-right: 1px solid var(--line-separator);
}

.cost-2U6BE {
	margin-left: auto;
}

.city-VjioL {
	font: var(--medium--1-mobile-bold);
	color: var(--text-base);
}

.passenger-BkP8D {
	display: flex;
	grid-gap: 20px;
	gap: 20px;
	align-items: baseline;
	padding: 0 12px;

	cursor: pointer;
}

.checkbox-3Opa7 {
	position: relative;
	top: 5px;
}

.passenger-BkP8D:not(:last-of-type) {
	margin-bottom: 12px;
	padding-bottom: 12px;

	border-bottom: 1px dashed var(--line-separator);
}

.passengerNumber-3hzRg {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 24px;
	height: 24px;

	border-radius: 50%;

	color: var(--text-light);
	font: var(--normal-desktop-accent);

	background-color: var(--bg-fill);
}

.passengerName-1vMAe {
	color: var(--text-base);
	font: var(--medium--1-mobile);
}

.passengerPrice-M7-cP {
	margin-left: auto;

	color: var(--text-base);
	font: var(--medium--1-desktop-accent);
}

.footer-3TSVa {
	display: flex;
	align-items: center;
}

button.confirm-LtoeH {
	font-family: inherit;
}

@media screen and (max-width: 600px) {
	button.confirm-LtoeH,
	button.cancel-3kDnn {
		padding: 10px 10px;
	}
}

.clear-2F1pz {
	color: var(--brand-1-0);
	font: var(--normal-desktop);

	cursor: pointer;
}

.total-3M410 {
	display: flex;
	align-items: center;
	grid-gap: 12px;
	gap: 12px;
}

.price-1h-jj {
	font: var(--medium-1-desktop-bold);
	color: var(--text-base);
}

.divider-mbeE2 {
	min-height: 30px;
}

.footerControls-1aVP1 {
	margin-left: auto;
	display: flex;
	grid-gap: 12px;
	gap: 12px;
}
