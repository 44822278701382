

.root-Yqnug {
	display: block;
	width: 100%;
}

.root-Yqnug:before {
	content: '';

	position: absolute;
	top: calc(50% - 11px);
	left: calc(50% - 11px);

	display: none;
	width: 21px;
	height: 21px;

	background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACoAAAAqCAMAAADyHTlpAAAAjVBMVEUAAACSkpKRkZGRkZGRkZGQkJCPj4+SkpKPj4+Pj4+QkJCPj4+RkZGRkZGRkZGRkZGQkJCRkZGRkZGQkJCQkJCRkZGQkJCRkZGQkJCRkZGRkZGQkJCRkZGRkZGRkZGQkJCRkZGRkZGQkJCQkJCQkJCRkZGRkZGRkZGRkZGRkZGRkZGRkZGQkJCRkZGQkJCSJmqbAAAAL3RSTlMABjwwK0IcGAoTbA+ADHpSIb+pnYqEc2NcVzbctbCWkSX99enVxk3jo+/QZ8u7SMN3cv0AAAHhSURBVDjLjdTZsqIwFIXhnQBhHgSUSZBjM4no+z9eL6w6FtJC+r/JzVc7hFDQZ0xovnVyHM9KtIB2Etw6nTzPc5zj8RjHx0xsQN0HXNL4crHML5Dxu2VZSwp5qSrfWEs3u7+opak6IzJ0NfNetIpXg800ucP6ghapFmgUxWIlk+SeClqlHEGjSix292eaM/onIwMdKvN9oi5N00Shr3XRMAwX43ebLEtTyO/5oGH6uwmC3CodwjBUaY77WdaxbWpcQB1Cto902kkNw7Kcxyqd73PazSnLMsH67LpO36cqaOiSCamRpPB8PqtUP59PRUYT0DvloLqMaqAO5ZqmuTIaPB6PgTgok1EXtCQNkbQDwlTO/5PmnHP5A7Tt4UwKqCGjddu2EdV5npsyykErCkCFjDrT1Cbk5rmiyOh5miaFmIJcyWWN43TAKkCDfRqN4+hgNVRk7A5tmnGsCQWgwe6tNk0TEZrH1rW5Tb0CVKdXel3Xgb0lraIoGu/9jYFu2VOBDu9XxIIgEOKbtZ2+hxTL/7oQum6yteRTj0aVFjFd6LAu+4DlH9Q3kJ8W0rRtl720raRxf7vNtBW0zjVnahiz/fn5uV6vt9kevx7XXlHYktNGhrukfcxpL/aityKM+Wrrv19TLGLPKFb6AAAAAElFTkSuQmCC) no-repeat;
	background-size: 21px 21px;

	animation: rotation-3Jxpf 0.75s linear infinite;
}

.root-Yqnug.loading-2jTQM .content-2FITd {
	visibility: hidden;
}

.root-Yqnug.loading-2jTQM:before {
	display: block;
}

@keyframes rotation-3Jxpf {
	from {
		transform: rotate(0deg);
	}

	to {
		transform: rotate(359deg);
	}
}
