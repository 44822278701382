

.wrapper-3g9nr {
	display: flex;
	flex-direction: column;
	width: 100%;
	max-width: 375px;
	margin: 0 auto;
	padding: 22px 13px;

	border-bottom: 1px dashed var(--cart-service-border);

	counter-increment: cart-counter;
}

.header-3I_Es {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.content-2_p6z {
	margin-top: 13px;
	padding: 12px;

	border-radius: 4px;

	background: #ffffff;
}

.header-3I_Es svg {
	color: var(--bg-white);
}

.header__title-1WfQk,
.header__price-7x0JA {
	color: var(--bg-white);
	font-weight: 700;
	font-size: 18px;
}

.header__title-1WfQk {
	flex: 1;
}

.header__price-7x0JA {
	margin-left: auto;
}

.header__icon-29QHy,
.header__number-2qHKY {
	flex-shrink: 0;
	margin-right: 12px;
}

.header__number-2qHKY {
	position: relative;

	display: none; /* hidden by default, may be used in custom themes */
	width: 28px;
	height: 28px;

	border-radius: 50%;

	color: #ffffff;
	font-weight: 500;
	font-size: 13px;
	line-height: 28px;
	text-align: center;

	background: var(--brand-1--1);
}

.header__number-2qHKY:before {
	content: counter(cart-counter);

	color: currentColor;
}
