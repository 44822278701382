

.container-2dVsI {
	width: 100%;
	padding-right: 0;
	padding-left: 0;
}

.slider-2WibG {
	max-width: 100%;
	width: 100%;
	min-width: 0;
	overflow: hidden;
}

.inside-2xWDQ {
	position: relative;

	display: inline-flex;
	min-width: 100%;

	transition: 0.3s left ease-in;
}

.elementsWrapper-2z9Za {
	display: flex;
	min-width: 100%;
	align-items: flex-end;
}

.arrow-2kedQ:after {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;

	margin-top: 12px;

	background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDIiIGhlaWdodD0iNDIiIHZpZXdCb3g9IjAgMCA0MiA0MiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgZmlsdGVyPSJ1cmwoI2ZpbHRlcjBfZCkiPgo8cGF0aCBkPSJNMSAxOUMxIDkuMDU4ODggOS4wNTg4OCAxIDE5IDFDMjguOTQxMSAxIDM3IDkuMDU4ODggMzcgMTlDMzcgMjguOTQxMSAyOC45NDExIDM3IDE5IDM3QzkuMDU4ODggMzcgMSAyOC45NDExIDEgMTlaIiBmaWxsPSJ3aGl0ZSIvPgo8cGF0aCBkPSJNMTcuMDAwMSAxM0wxNS41OTAxIDE0LjQxTDIwLjE3MDEgMTlMMTUuNTkwMSAyMy41OUwxNy4wMDAxIDI1TDIzLjAwMDEgMTlMMTcuMDAwMSAxM1oiIGZpbGw9IiM0RDRENEQiLz4KPHBhdGggZD0iTTE5IDM2QzkuNjExMTYgMzYgMiAyOC4zODg4IDIgMTlIMEMwIDI5LjQ5MzQgOC41MDY1OSAzOCAxOSAzOFYzNlpNMzYgMTlDMzYgMjguMzg4OCAyOC4zODg4IDM2IDE5IDM2VjM4QzI5LjQ5MzQgMzggMzggMjkuNDkzNCAzOCAxOUgzNlpNMTkgMkMyOC4zODg4IDIgMzYgOS42MTExNiAzNiAxOUgzOEMzOCA4LjUwNjU5IDI5LjQ5MzQgMCAxOSAwVjJaTTE5IDBDOC41MDY1OSAwIDAgOC41MDY1OSAwIDE5SDJDMiA5LjYxMTE2IDkuNjExMTYgMiAxOSAyVjBaIiBmaWxsPSIjRjJGMkYyIi8+CjwvZz4KPGRlZnM+CjxmaWx0ZXIgaWQ9ImZpbHRlcjBfZCIgeD0iMCIgeT0iMCIgd2lkdGg9IjQyIiBoZWlnaHQ9IjQyIiBmaWx0ZXJVbml0cz0idXNlclNwYWNlT25Vc2UiIGNvbG9yLWludGVycG9sYXRpb24tZmlsdGVycz0ic1JHQiI+CjxmZUZsb29kIGZsb29kLW9wYWNpdHk9IjAiIHJlc3VsdD0iQmFja2dyb3VuZEltYWdlRml4Ii8+CjxmZUNvbG9yTWF0cml4IGluPSJTb3VyY2VBbHBoYSIgdHlwZT0ibWF0cml4IiB2YWx1ZXM9IjAgMCAwIDAgMCAwIDAgMCAwIDAgMCAwIDAgMCAwIDAgMCAwIDEyNyAwIi8+CjxmZU9mZnNldCBkeD0iMiIgZHk9IjIiLz4KPGZlR2F1c3NpYW5CbHVyIHN0ZERldmlhdGlvbj0iMS41Ii8+CjxmZUNvbG9yTWF0cml4IHR5cGU9Im1hdHJpeCIgdmFsdWVzPSIwIDAgMCAwIDAgMCAwIDAgMCAwIDAgMCAwIDAgMCAwIDAgMCAwLjEgMCIvPgo8ZmVCbGVuZCBtb2RlPSJub3JtYWwiIGluMj0iQmFja2dyb3VuZEltYWdlRml4IiByZXN1bHQ9ImVmZmVjdDFfZHJvcFNoYWRvdyIvPgo8ZmVCbGVuZCBtb2RlPSJub3JtYWwiIGluPSJTb3VyY2VHcmFwaGljIiBpbjI9ImVmZmVjdDFfZHJvcFNoYWRvdyIgcmVzdWx0PSJzaGFwZSIvPgo8L2ZpbHRlcj4KPC9kZWZzPgo8L3N2Zz4K) no-repeat center;
	content: '';
}

.arrow_next-DbTU4,
.arrow_prev-2698_ {
	position: absolute;
	top: 0;
	bottom: 0;
	z-index: 2;

	width: 52px;
	height: 52px;
	margin: auto;

	cursor: pointer;
	background: none;
}

.arrow_next-DbTU4 {
	position: absolute;
	right: -27px;
}

.arrow_next-DbTU4:after {
	transform: none;
}

.arrow_prev-2698_ {
	position: absolute;
	left: -27px;
}

.arrow_prev-2698_:after {
	background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDIiIGhlaWdodD0iNDIiIHZpZXdCb3g9IjAgMCA0MiA0MiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgZmlsdGVyPSJ1cmwoI2ZpbHRlcjBfZCkiPgo8cGF0aCBkPSJNMSAxOUMxIDkuMDU4ODggOS4wNTg4OCAxIDE5IDFDMjguOTQxMSAxIDM3IDkuMDU4ODggMzcgMTlDMzcgMjguOTQxMSAyOC45NDExIDM3IDE5IDM3QzkuMDU4ODggMzcgMSAyOC45NDExIDEgMTlaIiBmaWxsPSJ3aGl0ZSIvPgo8cGF0aCBkPSJNMjIuNDEgMTQuNDFMMjEgMTNMMTUgMTlMMjEgMjVMMjIuNDEgMjMuNTlMMTcuODMgMTlMMjIuNDEgMTQuNDFaIiBmaWxsPSIjNEQ0RDREIi8+CjxwYXRoIGQ9Ik0xOSAzNkM5LjYxMTE2IDM2IDIgMjguMzg4OCAyIDE5SDBDMCAyOS40OTM0IDguNTA2NTkgMzggMTkgMzhWMzZaTTM2IDE5QzM2IDI4LjM4ODggMjguMzg4OCAzNiAxOSAzNlYzOEMyOS40OTM0IDM4IDM4IDI5LjQ5MzQgMzggMTlIMzZaTTE5IDJDMjguMzg4OCAyIDM2IDkuNjExMTYgMzYgMTlIMzhDMzggOC41MDY1OSAyOS40OTM0IDAgMTkgMFYyWk0xOSAwQzguNTA2NTkgMCAwIDguNTA2NTkgMCAxOUgyQzIgOS42MTExNiA5LjYxMTE2IDIgMTkgMlYwWiIgZmlsbD0iI0YyRjJGMiIvPgo8L2c+CjxkZWZzPgo8ZmlsdGVyIGlkPSJmaWx0ZXIwX2QiIHg9IjAiIHk9IjAiIHdpZHRoPSI0MiIgaGVpZ2h0PSI0MiIgZmlsdGVyVW5pdHM9InVzZXJTcGFjZU9uVXNlIiBjb2xvci1pbnRlcnBvbGF0aW9uLWZpbHRlcnM9InNSR0IiPgo8ZmVGbG9vZCBmbG9vZC1vcGFjaXR5PSIwIiByZXN1bHQ9IkJhY2tncm91bmRJbWFnZUZpeCIvPgo8ZmVDb2xvck1hdHJpeCBpbj0iU291cmNlQWxwaGEiIHR5cGU9Im1hdHJpeCIgdmFsdWVzPSIwIDAgMCAwIDAgMCAwIDAgMCAwIDAgMCAwIDAgMCAwIDAgMCAxMjcgMCIvPgo8ZmVPZmZzZXQgZHg9IjIiIGR5PSIyIi8+CjxmZUdhdXNzaWFuQmx1ciBzdGREZXZpYXRpb249IjEuNSIvPgo8ZmVDb2xvck1hdHJpeCB0eXBlPSJtYXRyaXgiIHZhbHVlcz0iMCAwIDAgMCAwIDAgMCAwIDAgMCAwIDAgMCAwIDAgMCAwIDAgMC4xIDAiLz4KPGZlQmxlbmQgbW9kZT0ibm9ybWFsIiBpbjI9IkJhY2tncm91bmRJbWFnZUZpeCIgcmVzdWx0PSJlZmZlY3QxX2Ryb3BTaGFkb3ciLz4KPGZlQmxlbmQgbW9kZT0ibm9ybWFsIiBpbj0iU291cmNlR3JhcGhpYyIgaW4yPSJlZmZlY3QxX2Ryb3BTaGFkb3ciIHJlc3VsdD0ic2hhcGUiLz4KPC9maWx0ZXI+CjwvZGVmcz4KPC9zdmc+Cg==);
}

.disabled-IUiZA {
	opacity: 0.5;
	cursor: default;
}
