.wrapper-1HLAW {
	max-width: 1100px;
	margin: 0 auto;

	border-radius: 4px;

	color: var(--text-base);

	background: var(--white);
	box-shadow: 4px 4px 33px rgba(0, 0, 0, 0.05);
}

.row-cpmAC {
	display: flex;
	align-items: center;
	grid-gap: 40px;
	gap: 40px;

	border-bottom: 1px dashed var(--line-dot-line);
}

.row-cpmAC:last-child {
	border-bottom: none;
}

.col-3sNtF {
	flex: 1;
	display: flex;
	align-items: center;
}

.col-3sNtF:last-child {
	margin-right: 0;
	margin-bottom: 0;
}

.header-2vU_r {
	position: relative;

	padding: 20px 20px 8px;
}

.plane__icon-2biD0 {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 26px;
	height: 26px;
	margin: 0 26px;

	color: var(--text-pale);

	transform: rotate(180deg) translateY(-8px);
}

.plane__icon-2biD0 svg {
	width: 100%;
	height: 100%;
}

.city-HxRKc {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}

.city__airport-36kDW {
	color: var(--text-light);
	font-size: 14px;
	font-weight: 400;
	line-height: 1.3;
	text-transform: uppercase;
}

.city__info-3v_9e {
	color: var(--text-base);
	font-size: 42px;
	line-height: 1.3;
}

.city__iata-1bFbU {
	font-weight: 600;
}

.city__time-3mERj {
	margin-left: 8px;

	font-weight: 300;
}

.city__weather-48osq {
	position: absolute;
	bottom: 10px;

	display: flex;
	align-items: center;

	font-size: 14px;
	line-height: 16px;
	font-weight: 400;
}

.weather__icon-1diQG {
	display: flex;
	align-items: center;
	margin-right: 6px;
}

.flightInfo-cvyMv {
	display: flex;
	flex-direction: column;
}

.flightInfo-cvyMv:not(:last-child) {
	margin-right: 40px;
}

.flightInfo__title-1E5Gm {
	color: var(--text-light);
	font-size: 14px;
	line-height: 1.3;
	font-weight: 400;

	text-transform: uppercase;
}

.flightInfo__value-1vkpL {
	font-size: 42px;
	font-weight: 700;
	line-height: 1.3;
	text-transform: uppercase;
}

.flightInfo-cvyMv.flightInfo__departure-rq07y .flightInfo__value-1vkpL {
	font-weight: 300;
}

.footer-3wngV {
	position: relative;

	padding: 20px;
}

.airline__logo-qxWzr {
	display: inline-block;
	height: 28px;
	width: 28px;
	margin-right: 12px;

	background-position: center;
	background-size: cover;
}

.airline__name-1pije {
	color: var(--text-base);
	font-size: 16px;
	font-weight: 500;
	line-height: 1.3;
}

.footer__airline-1XlcY > span:first-child {
	display: flex;
	align-items: center;
}

.footer__item-3nID0 {
	display: flex;
	flex-direction: column;
	margin-right: 28px;
}

.footer__item-3nID0:last-child {
	margin-right: 0;
}

.footer__item-3nID0:not(:only-child):last-child {
	align-items: flex-end;
}

.footer__itemLabel-38b3K {
	color: var(--text-light);
	font-size: 14px;
	font-weight: 400;
	line-height: 1.3;
	text-transform: uppercase;
}

.footer__itemValue-3p8dz {
	color: var(--text-base);
	font-size: 18px;
	font-weight: 700;
	line-height: 1.3;
}

button.button-3Iref {
	margin-top: auto;
	margin-left: auto;

	color: var(--text-light);
	font-weight: 500;
	font-size: 13px;
}

@media screen and (max-width: 820px) {
	.row-cpmAC {
		flex-direction: column;
		align-items: inherit;
		grid-gap: 20px;
		gap: 20px;
	}
}
