.container-Zse-z {
	display: flex;
	flex-direction: column;
}

.passenger-8WLpv {
	position: relative;

	display: flex;
	align-items: flex-end;
	grid-gap: 16px;
	gap: 16px;

	padding: 24px 20px 20px 64px;

	border-bottom: 1px dashed var(--line-dot-line);

	background: var(--white);
}

.passengerContent-2pjG4 {
	flex: 1;
	display: flex;
	flex-direction: column;
	grid-gap: 16px;
	gap: 16px;
}

.icon-3ZCpC {
	position: absolute;
	top: 20px;
	left: 20px;

	display: flex;
	justify-content: center;
	align-items: center;
	width: 32px;
	height: 32px;

	border-radius: 50%;

	color: var(--white);

	background: var(--brand-1-0);
}

.icon-3ZCpC svg {
	width: 58%;
	height: 58%;
}

.passengerName-rzApf {
	color: var(--text-base);
	font-weight: 700;

	font-size: 22px;
	line-height: 1.3;
	text-transform: capitalize;
}

.passengerInfo-XDPrd {
	flex: 1;
	display: flex;
	flex-direction: column;
	grid-gap: 6px;
	gap: 6px;
}

.passengerInfo__item-2D9Se {
	color: var(--text-base);
	font-weight: 400;
	font-size: 16px;
	line-height: 1.3;
}

.passengerInfo__item-2D9Se:last-child {
	margin-bottom: 0;
}

button.button-3c8kd {
	color: var(--text-light);
	font-size: 13px;
	font-weight: 500;
}

.services-1lBk8 {
	padding: 12px;

	background: var(--white);
}

@media screen and (max-width: 600px) {
	.passenger-8WLpv {
		flex-direction: column;
	}
}
