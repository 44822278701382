.header-3nWgS {
	display: block;
}

.header__title-3kJCl {
	margin: 0 0 12px;

	color: var(--text-base);
	font: var(--medium-desktop-bold);
}

.header__desc-AyYSy {
	margin: 0;

	color: var(--text-light);
	font: var(--medium--1-desktop);
}

.form_container-2zozF {
	margin: 24px 0 20px;
}

.traveller-1vTWg {
	display: flex;
	justify-content: space-between;
	grid-gap: 12px;
	gap: 12px;
}

.traveller-1vTWg:not(:last-of-type) {
	margin-bottom: 24px;
}

.traveller__name-3ztvd {
	width: 100%;
	max-width: 160px;
	display: flex;
	align-items: center;

	color: var(--text-base);
	font: var(--medium--1-mobile);
	text-overflow: ellipsis;
	overflow: hidden;
}

.traveller__input-29P4a {
	position: relative;
}

.traveller__fields-C7-g2 {
	display: flex;
	grid-gap: 12px;
	gap: 12px;
}

.traveller__fields-C7-g2,
.traveller__select-1_DFm,
.traveller__input-29P4a {
	flex: 1;
}

.traveller__input-29P4a > .input__root-1Sbrm {
	padding-right: 0;
}

.traveller__select-1_DFm > div:before {
	background: var(--brand-1-5);
}

.traveller__select-1_DFm div.MuiSelect-root {
	padding: 6px 0 8px;
}

.footer-2Y8_9 {
	display: flex;
	justify-content: space-between;
	align-items: center;
	grid-gap: 12px;
	gap: 12px;
	margin-top: 43px;
}

.footer__info-1WChz p {
	margin: 0;

	color: var(--text-light);
	font: var(--medium--1-mobile);
}

.footer__info-1WChz a {
	color: var(--brand-1-0);
	text-decoration: none;
}

.traveller__select-1_DFm div.input-206VJ {
	border: 1px solid var(--brand-1-3);
}

div.input-206VJ.input_disabled-2Ushl {
	color: var(--text-light);
}

p.helperText-3dmzq {
	position: absolute;
	bottom: -20px;
}

@media (max-width: 1024px) {
	.footer-2Y8_9 {
		grid-gap: 20px;
		gap: 20px;
	}

	.traveller-1vTWg {
		flex-direction: column;
	}

	.traveller__name-3ztvd {
		padding-top: 0;
	}
}

@media screen and (max-width: 600px) {
	.traveller__fields-C7-g2 {
		flex-direction: column;
	}

	.traveller__select-1_DFm {
		margin-bottom: 12px;
	}

	.footer-2Y8_9 {
		position: sticky;
		bottom: 0;
		z-index: 1;

		flex-direction: column;

		margin-top: 0;
		padding-top: 43px;

		background: var(--white);
	}
}
