

.icon-2X5ff {
	position: absolute;
	left: 20px;
	top: 16px;
}

.icon-2X5ff.red-1AqKL {
	color: var(--error);
}

.icon-2X5ff.gray-1MB4i {
	color: var(--gray50);
}

.note-fXljX {
	position: relative;

	max-width: 1100px;
	margin: 12px auto;
	padding: 17px 20px 17px 50px;

	border: 1px solid var(--warning);

	color: var(--gray80);

	background: var(--warning20);
}

.text-1Rycq a {
	color: var(--text-link);
}

.title-2ESr1 {
	display: flex;
	margin: 0;

	font-weight: 700;
	font-size: 16px;
	line-height: 20px;
}

.text-1Rycq {
	margin: 0;

	font-size: 15px;
	line-height: 19px;
	white-space: pre-wrap;
}

/* stylelint-disable */
.info-3L2ks {
}

.timelimit-g9jym {
}

.restriction-3N3E8 {
}
