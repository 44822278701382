.container-2Ba3W {
	display: flex;
	flex: 1;
	padding: 8px;
}

.day-23Smt {
	display: flex;
	flex: 1;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 10px;

	border-radius: 8px;

	color: var(--text-pale);
	font-size: 13px;
	line-height: 1;
	text-align: center;

	background: var(--bg-fill);

	pointer-events: none;
}

.day-23Smt:not(:last-child) {
	margin-right: 4px;
}

.active-3STMr {
	color: var(--text-link);

	cursor: pointer;

	background: var(--brand-1-4);

	pointer-events: auto;
}

.weekday-1LdF2 {
	margin-bottom: 4px;

	font-weight: 700;
	text-transform: capitalize;
}

.date-uDSJc {
	font-weight: 400;
}

@media screen and (max-width: 600px) {
	.container-2Ba3W {
		padding: 12px;
	}

	.day-23Smt {
		min-height: 48px;
		padding: 10px;
	}

	.weekday-1LdF2 {
		margin: 0;
	}

	.date-uDSJc {
		display: none;
	}
}
