

.header-1pj6O {
	background-color: var(--common-blue);
}

.header__info-1Wol9 {
	display: flex;

	border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}

.airport-2YBSx {
	display: flex;
	align-items: center;
	max-width: 650px;
	width: 100%;
	padding: 28px 0 25px;

	color: #ffffff;
}

.airport__iata-1D_0k {
	display: flex;
	align-items: center;
	margin-left: 40px;
	margin-right: 16px;
	height: 68px;

	font-size: 82px;
	font-weight: 500;
}

.airport__info-19AUi {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}

.airport__city-33Veq {
	margin-bottom: 3px;

	font-size: 22px;
	font-weight: 500;
	line-height: 26px;
}

.airport__description-3Jr03 {
	max-width: 362px;

	font-size: 15px;
	line-height: 130%;
	color: rgba(255, 255, 255, 0.8);
}

.header__buttons-3nGSj {
	display: flex;
	max-width: 450px;
	width: 100%;
}

button.header__button-2oMNV {
	height: 100%;
	display: flex;
	flex-direction: column;
	flex: 1;
	justify-content: center;
	align-items: center;

	border-radius: 0;
	border-left: 1px solid rgba(255, 255, 255, 0.3);

	font-size: 14px;
	line-height: 150%;
	font-weight: 400;
}

.button__icon-24Dcc {
	height: 24px;
	margin-bottom: 14px;
}

div.header__tabs-1gG20 {
	margin-left: 40px;
	height: 56px;
	margin-top: 0;
}

.header__tabs-1gG20 button.tabs__tab-2Wi2T {
	max-width: 160px;
	width: 100%;
	height: 56px;
	margin: 0;

	color: #ffffff;

	background-color: rgba(255, 255, 255, 0.3);

	opacity: 1;

	transition: background-color, color 150ms;
}

button.tabs__tab-2Wi2T div {
	color: #ffffff;
}

button.tabs__tab-2Wi2T.tabs__tab_selected-35tth {
	background-color: #ffffff;
}

span.indicator-3a2K0,
.tabs__tab-2Wi2T .tab__label-MLQ-K {
	display: none;
}

.tabs__tab-2Wi2T .tab__header-17gTe {
	font-weight: 500;
	font-size: 16px;
	text-transform: none;
}

a.link-13mCb {
	text-decoration: none;
}
