.day-e2YAx {
	width: 40px;
	height: 36px;
	padding: 0 2px;

	color: #b0b0b0;
	font-size: 13px;
	line-height: 36px;

	transition: padding 0.1s ease-in-out;
}

@media screen and (max-width: 600px) {
	.day-e2YAx {
		font-size: 13px;
	}
}

.day_endHover_withPrice-21OFq > div,
.day_startHover_withPrice-2wnLS > div {
	border-radius: 0;

	background: none;
}

.day_fromOtherMonth-3h-Lm {
	opacity: 0;

	pointer-events: none;
}

.day_today-3PFlI,
.day_weekend-2UJ1g.day_today-3PFlI {
	color: var(--common-blue);
	font-weight: 500;
}

.day_startPeriod-1UyI4:after {
	position: absolute;
	top: 0;

	width: calc(40px / 2);
	height: 36px;

	background: var(--datepicker-day-period-background);
	content: '';
}

.day_hasPrice-2Wif3 {
	font-size: inherit;
}

.day_endPeriod-1S1yD:before {
	position: absolute;
	top: 0;
	left: 0;

	width: calc(40px / 2);
	height: 36px;

	background: var(--datepicker-day-period-background);
	content: '';
}

.day_selected-25Viq {
	position: relative;

	background: none;
}

.day-e2YAx.day_selected-25Viq {
	background: none;
}

.day_startPeriod-1UyI4.day_endPeriod-1S1yD:before {
	display: none;
}

.day_endPeriod-1S1yD.day_startPeriod-1UyI4:after {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 1px;
	z-index: 2;

	width: 33.85px;
	height: 33.85px;
	margin: auto;

	background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzQiIGhlaWdodD0iMzQiIHZpZXdCb3g9IjAgMCAzNCAzNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHJlY3QgeD0iMC41NzcxNDgiIHk9IjAuNTc3MTQ4IiB3aWR0aD0iMzIuODQ2MSIgaGVpZ2h0PSIzMi44NDYxIiByeD0iMTYuNDIzMSIgc3Ryb2tlPSJ3aGl0ZSIvPgo8cGF0aCBkPSJNMSAxOUg0TDIgMTFMMSAxNVYxOVoiIGZpbGw9IndoaXRlIi8+CjxwYXRoIGQ9Ik0zMyAxOEgyOS42NjY3TDMxIDI1LjVMMzMgMjJWMThaIiBmaWxsPSJ3aGl0ZSIvPgo8L3N2Zz4K) center no-repeat;
	content: '';
}

.day__inside-2jhSX {
	height: 36px;
	display: flex;
	justify-content: center;
	align-items: center;

	border: 1px solid transparent;
	border-radius: 50%;
}

.day_selected-25Viq .day__inside-2jhSX {
	position: relative;
	z-index: 1;

	width: 36px;
	height: 36px;

	border-radius: 50%;

	color: #ffffff;
	font-weight: 600;

	background: var(--datepicker-day-selected-background);
}

.day-e2YAx.day_inPeriod-3mkU2,
.day-e2YAx.day_weekend-2UJ1g.day_inPeriod-3mkU2 {
	position: relative;

	border-radius: 0;

	color: #b0b0b0;

	background: var(--datepicker-day-period-background);
}

.day_inPeriod-3mkU2:last-child:after {
	display: none;
}

.day_inPeriod-3mkU2:first-child:before {
	display: none;
}

.day_inPeriod-3mkU2.day_highlighted-HN_uK div {
	font-weight: 600;
}

/* calendar with prices */
.day_withPrice-B2_fc {
	display: flex;
	justify-content: center;
	align-items: flex-start;

	width: 44px;
	height: 44px;

	padding-top: 6px;

	border: 1px solid transparent;

	color: #000000;
	font-size: 13px;
	line-height: 16px;

	background: #ffffff;
}

.day__price-3skPE {
	display: block;

	margin-top: 1px;
	padding-left: 0 !important;

	color: #808080;

	font-size: 9px;
	font-weight: 400;
}

.day__inside-2jhSX:hover {
	box-sizing: border-box;
	width: 36px;
	height: 36px;

	border: 1px solid var(--datepicker-day-selected-background);
}

.day_withPrice-B2_fc.day_selected-25Viq {
	padding-top: 13px;

	border-radius: 6px;

	background: var(--common-orange);
}

.day_withPrice-B2_fc:not(.day_endHover_withPrice-21OFq):not(.day_startHover_withprice-28XE1):not(.day_selected-25Viq):hover {
	border: 1px solid var(--common-orange);
}

.day_withPrice-B2_fc.day_selected-25Viq > div {
	color: #ffffff;
}

.day_withPrice-B2_fc.day_selected-25Viq .day__price-3skPE {
	display: none;
}

.day_withPrice-B2_fc.day_inPeriod-3mkU2 {
	position: relative;
}

.day_withPrice-B2_fc.day_startPeriod-1UyI4:after,
.day_withPrice-B2_fc.day_endPeriod-1S1yD:before {
	display: none;
}

.day_withPrice-B2_fc.day_startPeriod-1UyI4 {
	border-radius: 6px 0 0 6px;
}

.day_withPrice-B2_fc.day_endPeriod-1S1yD {
	border-radius: 0 6px 6px 0;
}

.day_withPrice-B2_fc.day_endPeriod-1S1yD.day_inPeriod-3mkU2,
.day_withPrice-B2_fc.day_startPeriod-1UyI4.day_inPeriod-3mkU2 {
	border-radius: 0;
}

.day_withPrice-B2_fc.day_startPeriod-1UyI4.day_withPrice-B2_fc.day_endPeriod-1S1yD {
	border-radius: 6px;
}

.day_withPrice-B2_fc.day__price-3skPE {
	margin-top: 1px;
	padding-left: 0;

	color: var(--common-blue);

	font-size: 10px;
	font-weight: 400;
}

div.day_startHover_withPrice-2wnLS,
div.day_endHover_withPrice-21OFq {
	border: 1px solid var(--common-orange);

	background: var(--datepicker-day-period-background);
}

.day_endHover_withPrice-21OFq:before,
.day_startHover_withPrice-2wnLS:after {
	display: none;
}
