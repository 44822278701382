

div.slide-2NGqG {
	display: flex;
	flex-direction: column;
	max-height: calc(100% - 200px);
	padding: 0;
}

div.slide__wrapper-2c06E {
	background: none;
}

.content-3iclW {
	flex: 1;
	padding: 20px;
	overflow-y: auto;

	background: var(--gray05);
}

.header-3Jr47 {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 20px;

	color: var(--gray70);
	font-weight: 700;
	font-size: 20px;
}

.main-1-yBY {
	display: flex;
	align-items: center;
}

.main__icon-qohe0 {
	margin-right: 8px;

	color: var(--active);
}

.price__wrapper-2Dybk {
	vertical-align: bottom;

	color: var(--base);
	font-weight: 400;
	font-size: 15px;
}

.money-1byjq {
	color: var(--base);
	font-weight: 700;
	font-size: 24px;
}

.controls-2KjcY {
	display: flex;
	align-items: center;
	margin-bottom: 12px;
}

.control-1wReT {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 44px;
	height: 44px;
	margin-right: 12px;

	border-radius: 4px;

	color: var(--selected-color);

	font-weight: 700;
	font-size: 22px;

	cursor: pointer;
	background: var(--selected);
}

.control_selected-1Ly4G {
	color: var(--selected2-color);

	background: var(--selected2);
}

.control_free-1KN8o {
	background: var(--seat-free);
}

.actions-j7Sj8 {
	display: flex;
	align-items: center;
	min-height: 69px;
	padding: 0 20px;

	box-shadow: 0 0 16px rgba(0, 0, 0, 0.08);
	background: #ffffff;
}

.actions-j7Sj8 button {
	flex: 1;
	height: 49px;

	line-height: 49px;
	white-space: nowrap;
}

.actions-j7Sj8 button:not(:last-child) {
	margin-right: 12px;
}

/* stylelint-disable-next-line block-no-empty */
button.cancel-zMOdd {
}

/* stylelint-disable-next-line block-no-empty */
button.confirm-2mzEv {
}
