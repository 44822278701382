

.rememberMe-1ozp8 {
	margin: 20px 0;
	padding: 0;
	align-self: flex-start;
}

.rememberMe-1ozp8:before {
	opacity: unset;
}

.rememberMe-1ozp8:after {
	background: var(--line-separator);
}

.rememberMe-1ozp8.rememberMeIsActive-3HsA_:after {
	background: var(--brand-1-0);
}

.rememberMeLabel-2YvAF {
	color: var(--text-mid);

	font-size: 14px;

	opacity: 1;
}
