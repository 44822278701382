.container-1qNzW {
	margin: 0 auto;
}

.container-1qNzW > div {
	margin-bottom: 12px;
}

.inner-1s1Ny {
	max-width: 866px;
	margin: 0 auto;
	width: 100%;
	padding-bottom: 82px;
}

.header-p02st {
	width: 100%;
	margin: 0 auto;
	padding: 28px 0;

	font-size: 22px;
	font-weight: 700;
	color: var(--text-base);
}

.segments-2gLIB {
	box-shadow: 0 7px 20px rgba(0, 0, 0, 0.03);
}

ul.services-qdJAE {
	margin: 0;
	padding: 0;

	color: var(--text-light);
	font-size: 16px;
	line-height: 130%;

	text-transform: capitalize;

	list-style: none;
}

.notice-2GSdV {
	display: flex;
	align-items: center;
	margin-bottom: 30px;
	padding: 12px;

	border-radius: 12px;

	color: var(--text-mid);
	font-size: 14px;
	line-height: 130%;

	background: var(--bg-fill);

	cursor: pointer;
}

.notice__icon-3d-QF {
	flex: 0;
	margin-right: 8px;
	height: 24px;
	width: 24px;

	display: flex;
	align-items: center;

	color: var(--text-pale);
}

.notice__icon-3d-QF svg {
	margin-bottom: 2px;
}

.notice__link-2OSGe {
	display: flex;
	flex: 0;
	align-items: center;

	color: var(--text-light);
	white-space: nowrap;
}

.notice__notes-3hnm0 {
	margin-right: auto;
}

.notice__link-2OSGe svg {
	margin-left: 9px;

	color: var(--text-pale);
}

.details-3ho6k {
	margin-top: 12px;
	padding-top: 12px;

	border-top: 1px dashed var(--line-dot-line);
}

.details-3ho6k a {
	margin-top: 4px;

	color: var(--text-link);
}

button.details__button-3tsCM {
	padding: 4px 0;

	font-size: 16px;
	font-weight: 400;
}

.travellers-Y5-nZ {
	margin-top: 20px;

	border-top: 1px dashed var(--line-dot-line);
}

.traveller-3YzBO {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	padding: 14px 0;

	border-bottom: 1px dashed var(--line-dot-line);

	font-size: 18px;
}

.traveller__name-AxhN_ {
	font-weight: 700;
	text-transform: capitalize;
}

.button-3KN0S.button_passenger-2phHo {
	margin-left: 12px;
	min-width: 110px;
}

button.button-3KN0S.button_selected-1zgC9 {
	border: 1px solid var(--brand-1-3);

	color: var(--text-link);

	background: none;
}

button.button_passenger-2phHo.button_disabled-3S3EN {
	opacity: 0.7;
	cursor: not-allowed;
}

.traveller__controls-2Q9z_ {
	display: flex;
	align-items: center;
	min-height: 40px;
}

.rules__content-3PzSM {
	max-width: 866px;
	margin: 0 auto;
	padding: 25px;

	font-size: 14px;
}

button.close-2yC-a {
	flex-shrink: 0;
	width: 32px;
	height: 32px;
	margin-right: 12px;

	border-radius: 50%;

	color: var(--text-light);

	background: var(--bg-fill);
}

.refunded-1o9lp {
	color: var(--error-text);
	text-transform: lowercase;
}

@media screen and (max-width: 600px) {
	.inner-1s1Ny {
		padding: 12px 12px 72px;
	}

	.notice-2GSdV {
		align-items: flex-start;
	}
}
