

.wrapper-5NAnu {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	padding: 25px 20px 20px;
	width: 100%;

	border-radius: 12px;

	background-color: var(--white);
}

.header-1emj1 {
	display: flex;
	align-items: center;
	margin-bottom: 18px;
	padding-bottom: 20px;

	border-bottom: 1px dashed #d9d9d9;
}

.header-1emj1.selected-i_g3l {
	padding-bottom: 0;

	border-bottom: none;
}

.title-2J17F {
	margin-bottom: 4px;

	color: var(--text);
	font-weight: 700;
	font-size: 18px;
	line-height: 1.3;
}

.title-2J17F a {
	color: var(--brand-3-0);
}

.icon-sDrom {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 60px;
	height: 60px;
	margin-right: 20px;

	flex-shrink: 0;

	border-radius: 50%;

	color: var(--base);

	background-color: #f6f6f6;
}

.icon-sDrom > svg {
	width: 60%;
	height: 60%;
}

.info-1bfA8 {
	display: flex;
	flex-direction: column;
}

.description-2Zegr {
	font-size: 16px;
	line-height: 1.3;
	color: var(--gray50);
}

.footer-3t8Xb {
	display: flex;
	align-items: center;
	justify-content: flex-end;
}

.price-2gfla {
	margin-right: 12px;

	font-weight: 700;
	font-size: 16px;
	line-height: 1.3;
}

/* stylelint-disable */
.button-3Evat {
}
.button_edit-Z3kwU {
}
/* stylelint-enable */
