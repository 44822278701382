

.card-1hyKx {
	width: 340px;

	border-radius: 8px;

	background: linear-gradient(345.9deg, #f7f7f7 -2.27%, #ffffff 79.43%);
	box-shadow: 1px 1px 0 rgba(0, 0, 0, 0.1);
}

.header-2SO0r {
	display: flex;
	padding: 22px 14px 0;
}

.document-3uAqL {
	margin-left: 12px;
}

.title-3cCpW {
	display: flex;
	align-items: center;
	height: 20px;
	margin: 0;

	color: #9a9a9a;
	font-size: 14px;
	font-weight: 500;
}

.subtitle-2fmj3 {
	display: flex;
	align-items: center;
	height: 25px;
	margin: 0;

	line-height: normal;
	font-size: 15px;
	color: var(--dark-gray);
	text-transform: uppercase;
}

.content-3BcaA {
	display: flex;
	padding: 38px 14px 16px;
}

.content__item-2bp2z {
	display: flex;
	flex-direction: column;
}

.content__item-2bp2z:first-child {
	margin-right: 25px;
}

.content__item__name-n7VQn {
	display: flex;
	align-items: center;
	height: 20px;
	margin: 0;

	font-size: 13px;
	color: #9a9a9a;
}

.content__item__value-1LTQj {
	display: flex;
	align-items: center;
	height: 20px;
	margin: 0;

	color: var(--dark-gray);
	font-weight: 500;
	font-size: 15px;
}

.footer-1-gsz {
	padding: 10px 10px 10px 20px;

	display: flex;
	flex-direction: row-reverse;

	border-top: 1px dashed #d9d9d9;
}

.footer-1-gsz p {
	display: flex;
	align-items: center;
	margin: 0;
	height: 20px;

	font-weight: 500;
	color: var(--common-blue);

	cursor: pointer;
}

.footer-1-gsz > p > span {
	margin-right: 6px;

	color: #9a9a9a;
}

.footer-1-gsz .footer__button-3Ntk9 {
	min-width: 35px;

	font-size: 13px;
	color: var(--cabinet-action);
	text-transform: none;

	font-family: inherit;
}

.footer-1-gsz .footer__button-3Ntk9 .button__label-DRJAG {
	font-family: inherit;
}

.footer__button-3Ntk9:first-child {
	color: #9a9a9a;
}

.card_loyalty-1mQh3 {
	background: var(--loyalty-card-background);
}

.card_loyalty-1mQh3 .header-2SO0r .title-3cCpW,
.card_loyalty-1mQh3 .header-2SO0r .subtitle-2fmj3 {
	color: #ffffff;
}

.card_loyalty-1mQh3 .content-3BcaA .content__item-2bp2z .content__item__name-n7VQn,
.card_loyalty-1mQh3 .content-3BcaA .content__item-2bp2z .content__item__value-1LTQj {
	color: #ffffff;
}

.card_loyalty-1mQh3 .footer-1-gsz p button {
	color: #ffffff;
}

.card_dummy-2tT6M .footer-1-gsz > p > button > span > span > svg > path {
	fill: var(--cabinet-action);
}

.card-1hyKx.card_loyalty-1mQh3 > .footer-1-gsz > p > button > span > span > svg > path {
	fill: #ffffff;
}

.card-1hyKx.card_dummy-2tT6M {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.card_dummy-2tT6M .footer-1-gsz > p > button > span > span {
	display: flex;
	align-items: center;
	margin-left: 6px;
	margin-right: 0;
}

.card_dummy-2tT6M .footer-1-gsz > p > button {
	color: var(--cabinet-action);
}

.card_dummy-2tT6M.card_loyalty-1mQh3 .footer-1-gsz > p > button > span {
	color: #ffffff;
}

.card_loyalty-1mQh3 .footer-1-gsz > p > button {
	color: #ffffff;
}

.card_loyalty-1mQh3 .footer-1-gsz > p > button:first-child {
	color: rgba(255, 255, 255, 0.5);
}

.card_dummy-2tT6M .content-3BcaA .content__item-2bp2z .content__item__value-1LTQj {
	color: #d9d9d9;
}

.card_dummy-2tT6M.card_loyalty-1mQh3 .content-3BcaA .content__item-2bp2z .content__item__value-1LTQj {
	color: rgba(255, 255, 255, 0.5);
}

.card_dummy-2tT6M .footer-1-gsz {
	padding: 10px 14px;
}

div.modal__content-3DaZN {
	padding: 0;
}

.modal__content-3DaZN:first-child {
	padding: 0 !important;
}
