.subscriptionDetail-2O1PC {
	display: grid;
	flex-direction: column;
	grid-template-columns: 100px 1fr;
	grid-gap: 15px;
	gap: 15px;
}

@media screen and (max-width: 600px) {
	.subscriptionDetail-2O1PC {
		display: flex;
		grid-gap: 8px;
		gap: 8px;
	}
}

.subscriptionDetail__label-rnUqp {
	font-size: 16px;
	font-weight: 400;
	line-height: 1.3;

	color: var(--text-mid);
}
