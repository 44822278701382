.toolbar-2O9Y2 {
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;

	z-index: 3;

	min-height: 72px;
	padding: 12px 0;

	background: #ffffff;
	box-shadow: 0 -7px 20px 0 rgba(0, 0, 0, 0.03);
}

.inner-w0y2C {
	max-width: 1100px;
	margin: 0 auto;

	display: flex;
	align-items: center;
	justify-content: space-between;
}

.actions-37F6P {
	display: flex;
	align-items: center;
}

.actions_left-GSvvg {
	flex: 1;
}

.actions_right-3iS9g {
	margin-left: auto;
	min-height: 48px;
}

@media (max-width: 1024px) {
	.inner-w0y2C {
		padding: 0 12px;
	}
}

@media screen and (max-width: 600px) {
	.toolbar-2O9Y2 {
		min-height: 0;
		min-height: initial;
	}

	.actions-37F6P {
		width: 100%;
		justify-content: flex-end;
	}

	button.button-ipSJ- {
		height: auto;
		height: initial;
	}

	.backButton__text-13Djg {
		display: none;
	}
}

.price-2TK4i {
	margin-right: 24px;

	font-weight: 600;
	font-size: 22px;

	text-align: right;
}

.priceInMiles-314_f .currency-2eGbV {
	padding-left: 6px;

	font-weight: 500;
}

.backButton__wrapper-3fjzv {
	display: flex;
	align-items: center;

	color: var(--text-pale);

	cursor: pointer;
}

button.backButton-39T91 {
	flex-shrink: 0;
	margin-right: 10px;
	padding: 0;

	color: var(--text-light);

	background-color: var(--bg-fill);
}

button.backButton-39T91 > svg {
	width: 20px;
	height: 20px;

	transform: rotate(180deg);
}

button.backButton-39T91:hover {
	background-color: var(--gray05);
}

.button-ipSJ- {
	height: 48px;
}

button.button_disabled-2-PBD {
	background-color: var(--light-gray);
}

.miles-3RyCm {
	display: flex;
	align-items: center;
	margin-top: 5px;

	color: #595959;
	font-weight: 400;
	font-size: 14px;

	white-space: nowrap;
}

.miles__money-1tVpM {
	padding-right: 5px;
}

.miles__hint-1Z8zs {
	width: 20px;
	height: 20px;
	margin-bottom: 1px;
	margin-left: 8px;

	font-size: 16px;

	cursor: pointer;
	background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyMCAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xLjY2NjUgOS45OTk4NEMxLjY2NjUgNS4zOTk4NCA1LjM5OTg0IDEuNjY2NSA5Ljk5OTg0IDEuNjY2NUMxNC41OTk4IDEuNjY2NSAxOC4zMzMyIDUuMzk5ODQgMTguMzMzMiA5Ljk5OTg0QzE4LjMzMzIgMTQuNTk5OCAxNC41OTk4IDE4LjMzMzIgOS45OTk4NCAxOC4zMzMyQzUuMzk5ODQgMTguMzMzMiAxLjY2NjUgMTQuNTk5OCAxLjY2NjUgOS45OTk4NFpNMTAuODMzMiA5LjE2NjUxVjE0LjE2NjVIOS4xNjY1VjkuMTY2NTFIMTAuODMzMlpNOS45OTk4MyAxNi42NjY1QzYuMzI0ODMgMTYuNjY2NSAzLjMzMzE2IDEzLjY3NDggMy4zMzMxNiA5Ljk5OTgzQzMuMzMzMTYgNi4zMjQ4MyA2LjMyNDgzIDMuMzMzMTcgOS45OTk4MyAzLjMzMzE3QzEzLjY3NDggMy4zMzMxNyAxNi42NjY1IDYuMzI0ODMgMTYuNjY2NSA5Ljk5OTgzQzE2LjY2NjUgMTMuNjc0OCAxMy42NzQ4IDE2LjY2NjUgOS45OTk4MyAxNi42NjY1Wk0xMC44MzMyIDUuODMzMTdWNy40OTk4M0g5LjE2NjVWNS44MzMxN0gxMC44MzMyWiIgZmlsbD0iI0JBQkFCQSIvPgo8L3N2Zz4K);
}
