.container-NYZNf {
	max-width: 1100px;
	margin: 12px auto;

	border-radius: 12px;

	box-shadow: 4px 4px 18px rgba(0, 0, 0, 0.06);

	background: #ffffff;
}

.header-3xJs7 {
	padding: 20px 40px;

	border-bottom: 1px solid var(--line-separator);
	border-radius: 12px 12px 0 0;

	color: var(--text-base);
	font-weight: 700;
	font-size: 22px;
}

.container-NYZNf .group-2MWKS:not(:last-child) {
	border-bottom: 1px solid var(--line-separator);
}

@media screen and (max-width: 600px) {
	.header-3xJs7 {
		margin-bottom: 16px;
		padding: 16px 0;

		border-bottom: 1px solid var(--line-separator);

		color: var(--text-light);
		font-weight: 500;
		font-size: 18px;
	}

	.container-NYZNf {
		padding: 0 16px;

		box-shadow: none;
		background: none;
	}
}
