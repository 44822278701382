

.content-b-qfI {
	padding: 28px 12px;

	border-radius: 20px;

	text-align: center;
}

.closeIcon-pjpyb {
	position: absolute;
	right: 20px;
	top: 10px;

	background-color: var(--bg-fill);
}

div.sliderRoot-2x2NF {
	border-radius: 20px 20px 0 0;
}

.heading-3XuJy {
	margin: 0;

	color: var(--text-base);
	font-weight: 500;
	font-size: 18px;
	line-height: 23px;
}

.text-3iqzH {
	color: var(--text-light);
	font-size: 16px;
	line-height: 20px;
}

.icons-wK2IP {
	display: flex;
	justify-content: space-between;
}

.icon-_1UK_ {
	width: 160px;
	height: 52px;
}
