.buttons-28mFJ {
	display: flex;
	margin-top: 22px;
}

div.modal-22F9l {
	max-width: 780px;
}

.buttons-28mFJ > button {
	margin-right: 8px;
}

.buttons-28mFJ > button:last-child {
	margin-right: 0;
}

@media screen and (max-width: 600px) {
	.buttons-28mFJ {
		flex-direction: column;
	}

	.modal-22F9l > div:first-of-type {
		font-size: 18px;
	}

	.buttons-28mFJ > button {
		margin-right: 0;
		margin-bottom: 8px;
	}

	.buttons-28mFJ > button:last-child {
		margin-bottom: 0;
	}
}

button.button-bcmxg,
button.button-bcmxg:hover,
button.button-bcmxg:focus,
button.button-bcmxg:active {
	padding: 14px 53px;

	border-radius: 4px !important;

	color: var(--gray70);
	font-weight: 500;

	background: var(--gray05);
}

button.active-3EovG,
button.active-3EovG:focus,
button.active-3EovG:hover {
	color: #ffffff;

	background: var(--active);
}
