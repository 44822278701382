
/* stylelint-disable */
.day__inside-1xHV1 {
}

.day_highlighted-UN1ej {
}

/* stylelint-enable */
.day-2AuOW {
	display: inline-block;
	width: 30px;
	height: 30px;

	line-height: 30px;
	text-align: center;

	cursor: pointer;
}

.day-2AuOW.day_selected-2MbT- {
	color: #ffffff;

	background: #84d2ff;
}

.day_notAvailable-kTGvH {
	opacity: 0.6;
	cursor: default;
}

.day_startPeriod-22ybu,
.day_endPeriod-23k7Z {
	color: #ffffff;

	background: #84d2ff;
}

.day_startHover-2Cj0f > div,
.day_endHover-1HqxP > div {
	position: relative !important;

	z-index: 2;

	border-radius: 50%;

	background: var(--datepicker-day-period-background);
}

.day_startHover-2Cj0f,
.day_endHover-1HqxP {
	position: relative !important;
	z-index: 1;
}

.day_startHover-2Cj0f:after {
	position: absolute;
	top: 0;
	right: 0;

	width: calc(40px / 2);
	height: 36px;

	background: var(--datepicker-day-period-background);
	content: '';
}

.day_endHover-1HqxP:before {
	position: absolute;
	top: 0;
	left: 0;

	width: calc(40px / 2);
	height: 36px;

	background: var(--datepicker-day-period-background);
	content: '';
}

.day_inPeriod-a7bZO {
	color: #ffffff;

	background: #5bc3ff;
}

.day_fromOtherMonth-2NXxV {
	color: #cccccc;
}

.day_weekend-1iF9v {
	color: #84d2ff;
}

.day_today-2XdZo {
	color: #84d2ff;
}

.day_weekend-1iF9v.day_selected-2MbT-,
.day_weekend-1iF9v.day_inPeriod-a7bZO,
.day_weekend-1iF9v.day_today-2XdZo,
.day_selected-2MbT-.day_today-2XdZo {
	color: #ffffff;
}
