.wrapper-jYsKE {
	display: flex;
	flex-direction: column;
	margin-top: 28px;
	padding-top: 28px;

	border-top: 1px dashed var(--line-dot-line);
}

.wrapper__auth-2_SS8 {
	display: flex;
	flex-direction: column;
}

.wrapper__auth_offset-2ZX7F {
	margin-bottom: 30px;

	padding-bottom: 30px;

	border-bottom: 1px dashed var(--line-dot-line);
}

.wrapper__title-2QP8q {
	margin-bottom: 28px;

	color: var(--text-base);
	font-weight: 500;
}

.social-5Mbi1 {
	display: flex;
	align-items: center;
}

.social-5Mbi1:not(:nth-last-of-type(2)) {
	margin-bottom: 25px;
}

.social__icon-1SUNh {
	position: relative;

	margin-right: 16px;
}

.social__name-1uf4K {
	display: flex;

	color: var(--text-base);
	font-weight: 700;
}

.social__name-1uf4K svg {
	margin-left: 8px;
	width: 16px;
	height: 16px;

	color: var(--success-icon);
}

.social__info-19OoX {
	display: flex;
	flex-direction: column;
}

.social__user-2RVA6 {
	margin-top: 4px;

	color: var(--text-mid);
}

.social__user-2RVA6 span {
	margin-left: 16px;

	color: var(--text-light);
}

.social__delete-Yo4U1 {
	display: flex;
	flex-shrink: 0;
	justify-content: center;
	align-items: center;
	width: 30px;
	height: 30px;
	margin-left: auto;

	color: var(--text-pale);

	cursor: pointer;
}

.login-Dyi9B {
	display: flex;
	flex-direction: column;
}

.login__subtitle-d_yV- {
	margin: 28px 0 16px;

	color: var(--text-light);
}

.login__auth-Das5D {
	display: flex;
	align-items: center;
}

.login__auth_icon-1JEvU {
	width: 44px;
	height: 44px;

	cursor: pointer;
}

.login__auth_icon-1JEvU:not(:last-of-type) {
	margin-right: 16px;
}

@media (max-width: 1024px) {
	.wrapper__auth-2_SS8 {
		padding: 0 20px 16px;
		grid-gap: 20px;
		gap: 20px;
	}

	.wrapper__auth-2_SS8 ~ .login-Dyi9B {
		margin-top: 20px;
	}

	.wrapper-jYsKE {
		margin-top: 20px;
	}

	.wrapper__title-2QP8q {
		margin-bottom: 8px;
	}

	.login__title-k-ye8,
	.login__subtitle-d_yV- {
		color: var(--text-light);
		font-size: 14px;
		font-weight: 400;
		line-height: 18px;
	}
}
