

.insurance-4zTJV {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	align-items: center;
}

.logo-2qnuR {
	display: flex;
	align-items: center;
	justify-content: flex-start;

	height: 36px;

	border-radius: 4px;

	background: #ffffff;
}

.logo-2qnuR svg {
	width: 100px;
	height: 20px;
}

.title-3WK6z {
	margin: 0;

	font-weight: 600;
	font-size: 15px;
}

.description-1uLLO {
	margin-top: 10px;

	color: var(--text-base);
	font-size: 16px;
	line-height: 1.3;
}

.description-1uLLO a {
	color: var(--text-link);
}

.priceWrapper-1oQBL {
	display: flex;
	align-items: center;
}

.price-3_Y_I {
	font-weight: 600;
}

span.iconsWrapper-3rXwZ {
	position: relative;
	top: -1px;

	display: inline-flex;
	align-items: center;
	width: 24px;
	height: 24px;
}

.loadingIcon-1QrbR {
	margin: 0 auto;
}

div.loadingIconColor-3CkX_ {
	color: var(--mobileStepbar-extras-color);
}

.price-3_Y_I.insideStepBar-36Fde,
.iconsWrapper-3rXwZ {
	color: var(--mobileStepbar-extras-color);
}

div.popup-129Pz {
	padding: 40px 28px 28px;
	overflow: visible;

	border-radius: 8px;
}

div.downloadPopup-1UQeR {
	padding: 20px;

	border-radius: 16px;
}
