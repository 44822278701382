

.container-3FMsg {
	position: relative;
}

.container-3FMsg:not(:last-child):not(:empty) {
	margin-bottom: 12px;
	padding-bottom: 12px;
}

.container-3FMsg:not(:last-child):not(:empty):after {
	position: absolute;
	right: 0;
	bottom: 0;
	left: 0;

	display: block;

	border-bottom: 1px dashed var(--line-separator);
	content: '';
}
