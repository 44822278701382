.input-stkfK {

	display: none;

	cursor: pointer;
}

.input-stkfK:hover {
	cursor: pointer;
}

.input_focused-i724E {
}

.date__input-S4eRV {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;

	width: 100%;
	height: 100%;
	padding-left: 44px;

	border: none;

	font-weight: 500;
	font-size: 15px;

	background: none;
}

.date__input-S4eRV.date__input_back-2foux {
	padding-left: 12px;
}

.datepicker-UqLoh {
	display: flex;
	width: 100%;
}

.highlightedDate-1wpyY > div {
	width: 36px;
	margin: 0 auto;

	border-radius: 50%;

	color: var(--dark-gray);
	font-weight: 600;
}

.highlightedDate_lite-1H8cf > div {
	color: #3e4a73;
	font-weight: 500;
}

.highlightedDate_withPrice-WOLR_ > div {
	display: flex;
	flex-direction: column;

	line-height: 15px;
	color: var(--dark-gray);
	font-weight: 600;
}

.highlighted_minPrice-SYuEq {
	background: var(--datepicker-day-min-price-bg);
}

.highlighted_minPrice-SYuEq .day__price-26fAT {
	color: var(--datepicker-day-min-price-color);
}

.highlightedDate-1wpyY > div:hover,
.highlightedDate_lite-1H8cf > div:hover {
	border: 1px solid var(--datepicker-day-selected-background);
	border-radius: 50%;
}

.highlightedDate_withPrice-WOLR_ {
	background: #ffffff;
}

.dates-39pqt {
	position: absolute;
	top: 0;
	bottom: 0;

	display: flex;
	width: 100%;
	height: var(--input-height);

	padding: 0;

	color: #ffffff;
	font-weight: 500;
	font-size: 15px;
	line-height: var(--input-height);

	cursor: pointer;

	background: var(--white);

	transition: background-color 0.15s, color 0.15s;
}

.dates-39pqt:not(.dates_focused-t_abR):hover {
	background: var(--white);
}

.dates_focused-t_abR {
	color: var(--datepicker-directions-default-color);

	background: var(--datepicker-directions-background);
}

/* stylelint-disable */
.dates__to_text-3OZOF {
}

/* stylelint-enable */
.date__inputWrapper-3AeDf {
	display: flex;
	align-items: center;
}

.dates__to-3XZui,
.dates__back-2o5DH {
	white-space: nowrap;
	overflow: hidden;

	color: var(--text-base);

	text-overflow: ellipsis;

	-webkit-user-select: none;

	    -ms-user-select: none;

	        user-select: none;
}

.dates__to-3XZui {
	position: relative;

	display: flex;
	align-items: center;

	flex-grow: 1;
	width: 50%;
	padding-left: 16px;

	border-top-left-radius: 2px;
	border-bottom-left-radius: 2px;
}

.dates__to-3XZui .dates__icon-2h5US {
	display: flex;
	margin-right: 8px;
	margin-top: -1px;

	color: var(--text-pale);
}

.dates_focused-t_abR .dates__to-3XZui:after {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;

	height: 24px;
	margin: auto;

	border-left: 1px solid #cdcdcd;
	content: '';
}

.oneDate-vLIa0 .dates__to-3XZui {
	width: 100%;

	border-radius: 2px;
}

.dates__back-2o5DH {
	position: relative;

	flex-grow: 1;
	width: 50%;
	padding-right: 16px;
	padding-left: 12px;

	border-top-right-radius: 2px;
	border-bottom-right-radius: 2px;
}

.dates__to_focused-Mwjwm,
.dates__back_focused-3tNLm {
	color: var(--datepicker-current-selected-date-color);
}

.dates__back-2o5DH:before {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;

	height: 24px;
	margin: auto;

	border-left: 1px solid var(--line-separator);

	content: '';
}

@media (max-width: 1024px) and (min-width: 601px) {
	.dates-39pqt {
		border-radius: 4px 0 0 4px;
	}
}

.dates__current-2rUBQ {
	color: var(--datepicker-directions-current-direction-color);

	background: #ffffff;
}

.dow-1DNRx {
	opacity: 0.7;
}

.dow-1DNRx:before {
	content: ', ';
}

.returnDelete-1qJGb {
	position: absolute;
	top: 0;
	right: 10px;
	bottom: 0;

	display: block;
	width: 20px;
	height: 20px;
	margin: auto;

	color: #ffffff;

	cursor: pointer;
}

.returnDelete-1qJGb svg {
	display: block;
	width: 20px;
	height: 20px;
}

.returnDelete-1qJGb:hover {
	opacity: 0.6;
}

.dates_focused-t_abR .returnDelete-1qJGb {
	color: #cdcdcd;
}

.dates_focused-t_abR .returnDelete-1qJGb:hover {
	color: #777777;
}

.placeholder-2Sj7b {
	display: flex;
	align-items: center;

	color: var(--text-light);
}

@media screen and (max-width: 600px) {
	.alternative-30tYf.dates-39pqt {
		height: 100%;

		line-height: normal;
	}

	.alternative-30tYf .dates__to-3XZui {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		padding: 0;

		font-weight: 600;
		font-size: 17px;
		text-align: center;
	}

	.alternative-30tYf .dow-1DNRx {
		display: none;
	}

	.alternative_dow-4iZvD {
		position: relative;

		font-weight: 400;
		font-size: 12px;

		opacity: 0.7;
	}

	.alternative-30tYf.oneDate-vLIa0 .placeholder-2Sj7b {
		display: none;
	}

	.dates__to-3XZui .dates__icon-2h5US {
		margin-right: 0;

		color: var(--text-light);
	}
}
