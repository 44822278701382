

.icon-1PU6R > svg {
	width: 70%;
	height: 70%;

	transform: translateY(-20%);
}

.flights-1xdW2 {
	display: flex;
	flex-direction: column;
}

.flight-3bYFC {
	display: flex;
	align-items: center;
	margin-bottom: 16px;
	padding-bottom: 16px;

	border-bottom: 1px dashed #d9d9d9;
}

.flight-3bYFC:last-child {
	margin-bottom: 10px;
}

.flight_icon-3E35y {
	flex-shrink: 0;
	width: 44px;
	height: 44px;
	margin-right: 8px;
}

.flight_icon-3E35y > svg {
	width: 60%;
	height: 60%;

	transform: translate(-50%, -70%);
}

.flight_count-2InWF {
	display: flex;
	flex-shrink: 0;
	justify-content: center;
	align-items: center;
	width: 36px;
	height: 36px;
	margin-right: 16px;

	border-radius: 50%;

	color: var(--white);
	font-weight: 500;
	font-size: 16px;
	line-height: 1.3;
	text-align: center;

	background-color: var(--success);
}

.flight_info-2UwTu,
.flight_direction-awEzo {
	font-size: 16px;
	font-weight: 500;
	line-height: 1.3;
}

.flight_info-2UwTu {
	color: var(--text);
}

.flight_direction-awEzo {
	color: var(--success-20);
}
