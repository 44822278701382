.wrapper-3Qxb2 {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	padding: 16px;

	width: 100%;

	border-radius: 4px;

	color: var(--gray50);

	background-color: #ffffff;
	box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.04);
}

.header-1FWLW {
	display: flex;
	justify-content: space-between;
}

.airline-3psb7 {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	width: 140px;

	min-height: 2em;

	text-overflow: ellipsis;
}

.airline__logo-3Un8l {
	width: 24px;
	height: 24px;
	margin-right: 8px;
}

.flight-GllEW {
	display: flex;
	align-items: center;

	color: var(--text);
	font-size: 14px;
	font-weight: 500;
	line-height: 1.3;
}

.flight-GllEW > svg {
	margin-right: 8px;

	color: #c2c2c2;
}

.flight__block-kJiXb:not(:last-of-type) {
	margin-right: 12px;
}

.flight__title-27Chk {
	font-size: 13px;
}

.aircraft-1q8I0 {
	text-align: center;
}

.aircraft__image-2AjXE {
	width: 70%;
}

.aircraft_return-cNCIQ .aircraft__image-2AjXE {
	transform: none;
}

@media screen and (max-width: 600px) {
	.aircraft__image-2AjXE {
		width: 90%;
	}
}

.footer-2H9K9 {
	display: flex;
	align-items: center;
	justify-content: center;
}

.airline__number-19cSD:after {
	content: ',';

	margin-right: 4px;
}

.airline__number-19cSD,
.airline__name-3sTEz {
	white-space: nowrap;
	font-size: 13px;
	font-weight: 400;
	line-height: 1.3;
}

.aircraft__number-2aon3:not(:last-child):after {
	content: ', ';
}

.aircraft__name-2G8xN {
	display: flex;
	align-items: center;

	padding-right: 15px;

	border-right: 1px solid #d9d9d9;

	font-weight: 400;

	font-size: 13px;
	line-height: 1.3;
}

div.aircraft__name_withNoAmenities-pkKQz,
div.aircraft__name_withNoAmenities-pkKQz:after {
	border-right: unset;
}

.aircraft__name_active-1ooV4 {
	color: var(--base);

	cursor: pointer;
}

.info-OtTk2 {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 16px;
	height: 16px;
	margin-left: 4px;

	color: var(--gray30);

	cursor: help;
}

.aircraft__nameTitle-1HDOY {
	max-width: 140px;

	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
}

/* stylelint-disable */
.amenities-27XAY {
}
/* stylelint-enable */
