

button.root-k4wrU {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	min-height: 47px;
	padding: 8px 12px;

	border: 1px solid transparent;
	border-radius: 4px;

	font: var(--medium-desktop-accent);

	background: var(--brand-3-0);
}

button.root-k4wrU:hover {
	background: var(--brand-3--1);
}

button.root-k4wrU:active {
	background: var(--brand-3--2);
}

button.disabled-3c1z_ {
	color: var(--text-pale);

	background: var(--bg-elements);
}

button.disabled-3c1z_ .endIcon-vD86p svg {
	color: var(--text-pale);
}

button.selected-6eCaO,
button.selected-6eCaO:hover,
button.selected-6eCaO.disabled-3c1z_ {
	border-color: var(--brand-1-3);

	color: var(--brand-1-0);

	background: var(--white);

	cursor: default;
}

button.subscription-3FiKm {
	background: var(--subscription-0);
}

button.subscription-3FiKm:hover {
	background: var(--subscription--1);
}

.startIcon-1Cavt {
	margin-top: -2px;
}

.title-1U7Dq {
	margin-right: 8px;
}

.miles-RVkwy,
.money_wrapper-3DhIC {
	display: flex;
	align-items: center;
	margin-left: auto;
}

.money-1xseY,
.miles_amount-17z2U {
	font: var(--big--1-desktop-bold);
}

.money_original-15av8 {
	margin-right: 4px;
	overflow: hidden;

	text-decoration: line-through;
	text-overflow: ellipsis;

	opacity: 0.75;
}

.money_wrapper_center-26Mln {
	margin-left: unset;
	width: 100%;
	justify-content: center;
}

.money_wrapper_center-26Mln .money_original-15av8 {
	font: var(--small-desktop);
}

.subscription-3FiKm .money_original-15av8 {
	color: var(--subscription-3);

	opacity: 1;
}
