

button.button-1UvfU {
	border-color: var(--gray05);

	color: var(--gray70);
	font-weight: 500;

	font-size: 17px;
	line-height: 20px;

	background: var(--gray05);
}

button.button-1UvfU:hover,
button.button-1UvfU:focus,
button.button-1UvfU:active {
	border-color: var(--gray05);

	background: var(--gray05);
}

button.disabled-2eVi6 {
	font-size: inherit;
}

button.inverted-2xC6_ {
	background: #ffffff;
}

button.inverted-2xC6_:hover,
button.inverted-2xC6_:focus,
button.inverted-2xC6_:active {
	background: #ffffff;
}

button.active-2UQUo {
	border-color: var(--base);

	color: #ffffff;

	background: var(--base);
}

button.active-2UQUo:hover,
button.active-2UQUo:focus,
button.active-2UQUo:active {
	border-color: var(--base);

	color: #ffffff;

	background: var(--base);
}

button.uppercase-USR_m {
	text-transform: uppercase;
}

button.icon-3Hmbb {
	padding: 0;

	border-radius: 50%;
}

button.icon-3Hmbb.common-183Ri {
	width: 36px;
	min-width: 36px;
	height: 36px;

	line-height: 36px;
}

button.icon-3Hmbb.small-2B8gr {
	width: 28px;
	min-width: 28px;
	height: 28px;

	line-height: 28px;
}

button.icon-3Hmbb.large-3ZoI- {
	width: 44px;
	min-width: 44px;
	height: 44px;

	line-height: 44px;
}
