

.container-8Osfv {
	position: relative;

	display: inline-flex;
	justify-content: flex-end;
}

div.container-8Osfv {
	flex-grow: 0;
}

.element-2FBde {
	position: relative;

	height: 44px;
	width: 56px;
	padding-top: 4px;
	padding-bottom: 4px;

	border: 1px solid var(--gray10);

	color: var(--gray30);

	background: var(--gray05);

	cursor: pointer;
}

.element-2FBde + .element-2FBde {
	margin-left: 3px;
}

.element-2FBde input {
	position: absolute;

	opacity: 0;
	visibility: hidden;

	pointer-events: none;
}

.element_active-32v88 {
	z-index: 1;

	border-color: var(--base50);

	color: var(--base);

	background: var(--base80);
}

.element-2FBde:first-of-type {
	margin-right: -1px;
	padding-left: 5px;

	border-radius: 22px 0 0 22px;
}

.element-2FBde:last-of-type {
	padding-right: 5px;

	border-radius: 0 22px 22px 0;
}

.element-2FBde svg {
	position: absolute;
	top: 9px;

	display: block;
}

.element-2FBde:first-of-type svg {
	right: 15px;
}

.element-2FBde:last-of-type svg {
	left: 15px;
}

.error-11A3W {
	position: absolute;
	bottom: 0;
	right: -9px;

	width: 100%;
	max-width: 130px;

	margin: 0;

	color: var(--error-icon);

	font: var(--small-desktop-mini);
	line-height: 100%;
	white-space: nowrap;
	text-overflow: ellipsis;
	text-align: center;

	transform: translateY(140%) translateX(0);
	overflow: hidden;
}

.element_error-21yxj {
	border-color: #f44336;
}

.container-8Osfv.disabled-1Z5OM .element-2FBde {
	cursor: default;
}
