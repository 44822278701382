

.group-2gpdn {
	margin: 0 -8px;
	padding: 0 8px;
}

.group-2gpdn:not(:last-of-type) {
	padding-bottom: 6px;

	border-bottom: 1px dashed rgba(255, 255, 255, 0.2);
}

.group-2gpdn:not(:first-of-type) {
	padding-top: 8px;
}

.item-3fvVO {
	display: flex;
	justify-content: space-between;

	list-style-type: none;
}

.label-2ciL2 {
	color: #ffee92;
}

.value-BPenv {
	margin-left: 20px;

	color: #ffffff;
	font-weight: 500;
	text-align: right;
}

.label-2ciL2,
.value-BPenv {
	font-size: 13px;
	line-height: 20px;
}
