.exchange-2dIKG {
	height: 100%;

	background: var(--checkout-background);
}

.close-Rxv-i {
	grid-gap: 8px;
	gap: 8px;
	margin-left: auto;
}

.close__text-1CiaF {
	color: var(--brand-1--1);
	font: var(--small-desktop-Caps);
	white-space: nowrap;
}

.header-2u0uf {
	position: relative;

	display: flex;
	padding: 40px 0 30px;

	font-weight: 600;
	font-size: 34px;

	background: #ffffff;
}

.header__inner-1MNDM {
	max-width: 1100px;
	width: 1100px;
	margin: 0 auto;
	display: flex;
	align-items: center;
}

.header__text-1C87h {
	margin-right: 32px;

	color: var(--gray50);
}

/* stylelint-disable */
.passengerPage-c88GM {
}

.flightPage-NWqYt {
}

.dialog__paymentIcon-2OEcG {
}

.dialog__failedIcon-4lWJ7 {
}

/* stylelint-enable */
.container-11Ebt {
	position: relative;

	max-width: 1100px;
	margin: 0 auto;
}

.services-Xmn0I {
	margin-bottom: 10px;
}

@media (max-width: 1024px) {
	.container-11Ebt {
		width: 100%;
		padding: 20px;
		padding-top: 0;
	}

	.header-2u0uf {
		padding: 20px;

		font-size: 21px;

		background: none;
	}

	.header__text-1C87h {
		margin-bottom: 24px;
	}

	.header__inner-1MNDM {
		flex-direction: column;
		align-items: unset;
	}

	.close__text-1CiaF {
		display: none;
	}

	.closeIcon-DuFTp {
		position: absolute;
		top: 28px;
		right: 28px;
	}
}
