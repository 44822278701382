.toggle-LUiT-:before {
	opacity: 1;
}

.toggle-LUiT-:after {
	background: var(--line-separator);
}

.toggle_active-BJeW1:after {
	background: var(--brand-1-0);
}

.switch__label-1dXd4 {
	color: var(--text-light);
	font-size: 16px;

	opacity: 1;
}

@media (max-width: 1024px) {
	.toggle-LUiT- {
		margin-bottom: 12px;
	}
}

@media screen and (max-width: 600px) {
	.switch__label-1dXd4 {
		padding-left: 28px;
	}
}
