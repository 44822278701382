

button.passenger-20uUq {
	padding: 20px 27px;

	color: var(--base);

	background: var(--gray05);
}

button.passenger-20uUq.active-1Kdxj {
	background: var(--base80);
}

.label-1oCX8 {
	color: var(--gray80);
	font-size: 16px;
	line-height: 24px;
}

.label-1oCX8.active-1Kdxj {
	color: var(--base);
}

span.checkbox-2qM6m.active-1Kdxj {
	color: var(--base);
}
