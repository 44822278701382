.block-2tigN {
	width: 100%;
	margin-top: 12px;
	padding: 20px;

	border-radius: 4px;

	box-shadow: 0 1px 2px rgba(0, 0, 0, 0.08);
}

.passengers-3LXsj {
	margin-top: 12px;
}

.passengers_readonly-2IPpY {
	margin-top: 4px;
}

@media screen and (max-width: 600px) {
	.passengers-3LXsj {
		margin-top: 0;
	}
}
