.form-2XMwU {
	padding: 8px 0;
	display: flex;
	flex-direction: column;
	grid-gap: 20px;
	gap: 20px;
	box-sizing: border-box;
}

div.codeInput-1GPf9 {
	margin-bottom: 12px;
}

button.button-3rOyz {
	margin-right: 12px;
}

.title-21MOG {
	margin-bottom: 20px;

	color: var(--fg-default);
	font: var(--medium-1-desktop-bold);
}

div.button-3rOyz {
	margin-right: 12px;

	background: var(--gray50);
}

/* stylelint-disable-next-line block-no-empty */
button.button_applied-2ICF1 {
}

button.button_notApplied-3S52v {
	background: var(--active30);
}

.button_notApplied-3S52v svg {
	position: relative;
	top: -1px;
}

/* stylelint-disable-next-line block-no-empty */
button.button_empty-2eDaq {
}

.statusMessage-30pXi {
	display: flex;
	align-items: center;
}

.statusMessage_applied-4UgCt {
	color: var(--gray50);
}

.statusMessage_notApplied-2pJIK {
	color: var(--active);
}

.form_additionalFields-2AxLi {
	display: flex;
	flex-direction: column;
	grid-gap: 12px;
	gap: 12px;
}

/* stylelint-disable-next-line block-no-empty */
.statusText-H2wox {
}

@media screen and (max-width: 600px) {
	.form-2XMwU {
		padding: 0;
	}
}
