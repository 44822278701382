

.header-2gv41 {
	margin-bottom: 13px;

	color: var(--fareGroup-header-color);
	font-weight: 500;
	font-size: 24px;
	line-height: 100%;
}

div.recommended-1GShZ {
	box-shadow: 0 0 0 1px var(--fareGroup-recommended-border-color);
}

.container-3lt6T {
	display: flex;
	flex-wrap: wrap;
	margin: 4px -4px 12px auto;
}

.option-yBwuz {
	position: relative;

	display: flex;
	flex: 1 0 48%;

	flex-direction: column;
	justify-content: center;
	align-items: center;

	margin-top: 4px;
	margin-right: 4px;
	padding: 13px 8px 8px;

	border-radius: 2px;
	border: 1px solid var(--brand-1-5);

	font-size: 12px;
	line-height: 1.3;
	text-align: center;

	color: var(--brand-1-0);

	list-style-type: none;

	cursor: help;

	background: var(--brand-1-5);
}

.option-yBwuz.small-1S5hp {
	flex: 1 0 23%;
	padding: 8px;

	border: none;

	font-size: 13px;
	color: var(--white);

	background: var(--brand-1-1);
}

.option-yBwuz.small-1S5hp.paid-2qQFt {
	background: var(--bg-elements);
}

.icon-1rrfy {
	display: flex;
	align-items: center;
	justify-content: center;
	width: var(--fareGroup-option-svg-size);
	height: var(--fareGroup-option-svg-size);

	margin-bottom: 6px;

	color: var(--white);
}

.size-3IKQH {
	font-size: 13px;
}

.availabilityIcon-1go37 {
	display: flex;
	align-items: center;
	justify-content: center;

	color: var(--brand-1-2);
}

.option-yBwuz.paid-2qQFt .availabilityIcon-1go37,
.option-yBwuz.not_available-3QoJw .availabilityIcon-1go37 {
	color: var(--text-pale);
}

.upgradeOffer__text-GbDfv:after {
	content: '?';
}

.upgradeOffer__options-4dv4K > svg {
	margin-right: 4px;
}

/* stylelint-disable */
.upgradeOffer__button-3LZQO {
}
/* stylelint-enable */
.upgradeOffer__options-4dv4K > svg:last-child {
	margin-right: 0;
}

.option-yBwuz:not(.small-1S5hp) .availabilityIcon-1go37 {
	position: absolute;
	top: 10px;
	right: 10px;
}

.option-yBwuz.small-1S5hp:not(.paid-2qQFt) .availabilityIcon-1go37 {
	display: none;
}

.description-qlPxB {
	max-width: 113px;
	max-height: 90px;

	text-overflow: ellipsis;
	overflow: hidden;
}

.option-yBwuz.paid-2qQFt,
.option-yBwuz.not_available-3QoJw {
	border: 1px solid var(--line-separator);

	color: var(--text-pale);

	background: var(--white);
}

.option-yBwuz.not_available-3QoJw {
	opacity: 0.6;
}

.option-yBwuz .icon-1rrfy {
	margin-bottom: 8px;

	color: var(--brand-1-0);
}

.option-yBwuz.small-1S5hp .icon-1rrfy {
	width: 28px;
	height: 28px;

	color: var(--white);
}

.option-yBwuz.paid-2qQFt .icon-1rrfy,
.option-yBwuz.not_available-3QoJw .icon-1rrfy,
.option-yBwuz.small-1S5hp.paid-2qQFt .icon-1rrfy {
	color: var(--text-pale);
}

.option-yBwuz.small-1S5hp .size-3IKQH {
	display: none;
}

.option-yBwuz.small-1S5hp.paid-2qQFt .title-lG16t {
	display: none;
}

.upgradeOffer-2T7Tp {
	display: flex;
	align-items: flex-end;
	margin-bottom: 12px;
	padding: 16px;

	order: 1;

	border-radius: var(--fareGroup-upgrade-option-bdrs);

	cursor: pointer;
	background: var(--fareGroup-upgrade-option-bg);
}

.upgradeOffer__content-2OUB7 {
	display: flex;
	flex-direction: column;
	margin-right: 8px;

	color: var(--brand-1-0);
	font-size: 14px;
	font-weight: 500;
	line-height: 1.3;
}

/* stylelint-disable-next-line block-no-empty */
.upgradeOffer__choose-3KBNg {
}

.upgradeOffer__options-4dv4K {
	display: flex;

	align-items: center;

	margin-top: 4px;

	color: var(--brand-1-0);
}

.upgradeOffer__optionsPlus-3bevk {
	position: relative;

	width: 24px;
	height: 24px;
	margin-right: 6px;
}

.upgradeOffer__optionsPlus-3bevk:before,
.upgradeOffer__optionsPlus-3bevk:after {
	content: '';

	position: absolute;
	top: 50%;
	left: 50%;

	width: 60%;
	height: 2px;

	background: currentColor;
	transform: translate(-50%, -50%);
}

.upgradeOffer__optionsPlus-3bevk:after {
	transform: translate(-50%, -50%) rotate(90deg);
}
