.wrapper-29DzN {
	margin-top: 28px;

	display: flex;
}

.wrapper-29DzN .category__button-3MFMd {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	height: 40px;
	padding: 0 16px;

	border: none;

	border-radius: 4px;

	color: var(--white);

	font: var(--medium-mobile-bold);

	cursor: pointer;

	background-color: var(--brand-1-0);
}

.button__icon-1raaE {
	display: flex;
	align-items: center;
	justify-content: center;
}

.button__icon-1raaE svg {
	width: 26px;
	height: 26px;
}

.category__button-3MFMd .button__icon-1raaE {
	color: var(--brand-1-3);
}

.mobile__header-1-jXS {
	display: flex;
	align-items: center;
	justify-content: space-between;

	margin-bottom: 8px;
}

.mobile__title-2l8lp {
	color: var(--gray80);
	font-weight: 600;
	font-size: 24px;
}

@media screen and (max-width: 600px) {
	.wrapper-29DzN {
		margin-top: 25px;
	}
}
