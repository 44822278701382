

.container-1E5ma {
	position: relative;

	display: flex;
	flex-direction: column;
	width: 114px;
}

.container_row-3kfAk {
	position: absolute;
	bottom: 100%;

	flex-direction: row;
	width: auto;
	padding-left: 112px;

	border: 1px solid #d9d9d9;
}

.container_col-QXnlj {
	flex-direction: column;
}

.wrapper-u751k {
	display: flex;
}

.container_col-QXnlj .wrapper-u751k {
	flex-direction: column;
}

.day-rfB44 {
	width: 114px;
	height: 32px;

	color: var(--dark-gray);
	font-weight: 500;
	font-size: 13px;
	line-height: 32px;
	text-align: center;

	background: #f8f8f8;
}

.container_row-3kfAk .day-rfB44 {
	border: none;
	border-left: 1px solid #d9d9d9;
}

.container_col-QXnlj .day-rfB44 {
	border: 1px solid #d9d9d9;
	border-top: none;
}

.day_dow-3RN2K {
	padding-left: 6px;

	color: var(--light-gray);
	font-weight: 500;
}

.nav-LSlV- {
	position: absolute;

	display: flex;
	align-items: center;
}

.container_row-3kfAk .nav-LSlV- {
	bottom: 100%;

	margin-bottom: 9px;
}

.container_col-QXnlj .nav-LSlV- {
	top: 50px;
	left: -73px;

	flex-direction: row-reverse;

	transform: rotate(-90deg);
}

.label-3sRpa {
	padding: 0 12px;

	font-size: 14px;
	font-weight: 500;
}

.container_col-QXnlj .label-3sRpa {
	padding-right: 0;
}

.container_row-3kfAk .label-3sRpa {
	padding-left: 0;
}

.arrows-10k0l {
	display: flex;
}

.arrow_next-1ZK-h,
.arrow_prev-1pd2S {
	width: 8px;
	height: 12px;
	display: block;

	background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOCIgaGVpZ2h0PSIxMiIgdmlld0JveD0iMCAwIDggMTIiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xLjQxIDBMMCAxLjQxTDQuNTggNkwwIDEwLjU5TDEuNDEgMTJMNy40MSA2TDEuNDEgMFoiIGZpbGw9IiNDRENEQ0QiLz4KPC9zdmc+Cg==);
	cursor: pointer;
}

.arrow_prev-1pd2S {
	transform: rotate(-180deg);
}

.arrow_next-1ZK-h {
	margin-left: 18px;
}
