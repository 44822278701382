.wrapper-163RU {
	border-radius: 4px;

	color: var(--text);

	background-color: var(--white);
}

.header-1Gtqr {
	display: flex;
	align-items: center;
	padding: 20px 20px 8px;

	border-bottom: 1px dashed #c2c2c2;
}

.cities-1emDz {
	display: flex;
	align-items: center;
	margin-left: auto;
}

.plane__icon-1kcsg {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 28px;
	height: 28px;
	margin: 0 26px;

	color: #b3b3b3;

	transform: rotate(180deg) translateY(-8px);
}

.plane__icon-1kcsg > svg {
	width: 100%;
	height: 100%;
}

.city-1m00I {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}

.city__airport-3-u2g {
	color: var(--gray50);
	text-transform: uppercase;
	font-size: 14px;
	font-weight: 400;
	line-height: 1.3;
}

.city__info-3ZVCu {
	font-size: 42px;
	line-height: 1.3;
}

.city__iata-19Clo {
	font-weight: 600;
}

.city__time-7PqhI {
	margin-left: 8px;

	font-weight: 300;
}

.flightInfo-VWX_v {
	display: flex;
	align-items: center;
}

.flightInfo__item-265Au {
	display: flex;
	flex-direction: column;
}

.flightInfo__item-265Au:last-child {
	margin-left: 61px;
}

.flightInfo__title-2JyMT {
	color: var(--gray50);

	text-transform: uppercase;
	font-size: 14px;
	line-height: 1.3;
	font-weight: 400;
}

.flightInfo__value-12FgU {
	font-size: 42px;
	font-weight: 300;
	line-height: 1.3;
}

.flightInfo__value-12FgU.flightInfo__valueBold-3t9Fe {
	font-weight: 700;
}

.footer-1o2z6 {
	display: flex;
	align-items: center;
	padding: 20px;
}

.footer__airline-2JPkb {
	display: flex;
	align-items: center;
}

.airline__logo-s-ckB {
	height: 28px;
}

.airline__name-2uo1J {
	font-size: 16px;
	font-weight: 500;
	line-height: 1.3;
	text-transform: capitalize;
}

.footer__airline-2JPkb > span:first-child {
	display: flex;
	align-items: center;
	grid-gap: 12px;
	gap: 12px;
}

.footerItems-tce7y {
	display: flex;
	margin-left: auto;
}

.footerItems__item-LPLgT {
	display: flex;
	flex-direction: column;
	margin-right: 28px;
}

.footerItems__item-LPLgT:last-child {
	margin-right: 0;
}

.footerItems__title-NRZmF {
	color: var(--gray50);
	font-size: 14px;
	font-weight: 400;
	line-height: 1.3;
	text-transform: uppercase;
}

.footerItems__value-BtM38 {
	font-size: 18px;
	font-weight: 700;
	line-height: 1.3;
	text-transform: uppercase;
}

@media (max-width: 1024px) {
	.header-1Gtqr {
		flex-direction: column;
		align-items: inherit;
	}

	.flightInfo-VWX_v {
		margin-bottom: 20px;
	}

	.cities-1emDz {
		margin-left: 0;
	}
}
