

.wrapper-1WaEB {
	border-radius: 4px;

	background: var(--white);
}

.header-3qS87 {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	align-items: center;
	padding: 15px 24px 15px 20px;

	border-bottom: 1px dashed rgba(255, 255, 255, 0.5);
}

.segmentInfo-2aFob {
	display: flex;
	align-items: center;

	font-size: 24px;
	font-weight: 500;
	line-height: 33px;
}

.segmentInfo__item-1T3uI:not(:last-child) {
	padding-right: 20px;
	margin-right: 20px;

	border-right: 1px solid rgba(255, 255, 255, 0.5);
}

.planeIcon-sVAMb {
	display: flex;
	align-items: center;
	margin-right: 12px;

	transform: rotate(180deg);
}

.fareFamily-3AtxM {
	display: flex;
	align-items: center;

	font-size: 16px;
	font-weight: 500;
}

.fareFamily__img-16UsD {
	display: flex;
	height: 28px;
	width: 28px;
	margin-right: 12px;

	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;
}

.footer-2v_l7 {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 24px 20px 21px;
}

.flightInfo-1wRkJ {
	font-size: 16px;
	font-weight: 500;
	line-height: 19px;
}

.flightInfo__item-3NArJ:not(:last-child) {
	margin-right: 20px;
	padding-right: 20px;

	border-right: 1px solid rgba(255, 255, 255, 0.5);
}
