div.wrapper-12fgf {
	background-color: var(--white);
	box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.04);
}

div.wrapper-12fgf:first-child,
div.wrapper-12fgf:last-child {
	border-radius: 8px;
}

div.wrapper-12fgf.inline-1d-Q5 {
	width: 100%;

	border-radius: 0;

	box-shadow: none;
}

div.header-3JCzq {
	display: flex;
	align-items: center;
	padding: 0 20px;
}

div.header_content-2yxuk {
	display: flex;
	align-items: center;
	width: 100%;
	margin: 20px 0;
}

.header-3JCzq,
.footer-7lT1s {
	padding: 20px;
}

.avatar-3RYsM {
	position: relative;

	display: flex;
	flex-shrink: 0;
	justify-content: center;
	align-items: center;
	width: 52px;
	height: 52px;
	margin-right: 16px;

	border-radius: 50%;

	color: var(--white);

	background-color: var(--base);
}

.avatar-3RYsM span {
	font-size: 16px;
	line-height: 1.2;
	font-weight: 700;
	text-transform: uppercase;
}

.info-3JMtq {
	display: flex;
	flex-direction: column;
}

.name-27BSN {
	color: var(--gray80);
	font-weight: 500;
	font-size: 18px;
	line-height: 1.2;
}

.name-27BSN:not(:only-child) {
	margin-bottom: 4px;
}

.notification-3zWZm {
	color: var(--base);
	font-size: 16px;
	line-height: 1.2;
}

.edit-3yz32 {
	display: flex;
	align-items: center;
	margin-left: auto;

	font-size: 16px;
	line-height: 16px;
	color: var(--base);
}

.edit-3yz32 > svg {
	color: var(--gray30);
}

.companion-2xp14 .avatar-3RYsM {
	width: 38px;
	height: 38px;
}

.companion-2xp14.empty-2alos .name-27BSN {
	color: var(--base);
}

.companion-2xp14.empty-2alos .avatar-3RYsM {
	color: var(--base);

	background-color: var(--brand1-5);
}

@media screen and (max-width: 600px) {
	button.button-AuCE0 {
		width: 100%;
		margin-left: 0;

		border-radius: 4px;
	}
}
