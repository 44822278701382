.switchWrapper-3GaMd {
	z-index: 0;

	margin-left: 20px;
}

.switchBox-1aPnd {
	font-weight: 500;
	font-size: 14px;
	color: #ffffff;
}

div.loyalty__modal-2My9W {
	z-index: 1600 !important;
}

/* stylelint-disable-next-line block-no-empty */
.switchActive-25t49 {
}

@media screen and (max-width: 600px) {
	.switchWrapper-3GaMd {
		display: flex;
		align-items: center;
		margin-left: 0;
	}
}
