

.root-88yfh {
	display: flex;
	flex-direction: column;

	border-radius: 8px;

	background: var(--white);
	overflow: hidden;
}

.header-1W5Mu {
	display: flex;
	grid-gap: 10px;
	gap: 10px;
	justify-content: space-between;
	align-items: center;

	min-height: 100px;

	padding: 0 16px;

	background: var(--brand-1-0);
}

.root_baggage-2nkQT .header-1W5Mu,
.root_baggageDelivery-5k65U .header-1W5Mu {
	background: var(--additional-baggage);
}

.root_meal-2SYmz .header-1W5Mu {
	background: var(--additional-meal);
}

.root_transfer-aKRIK .header-1W5Mu,
.root_animal-3MbUN .header-1W5Mu {
	background: var(--additional-aeroexpress);
}

.root_seats-Vit9O .header-1W5Mu {
	background: var(--additional-seats);
}

.root_businessLounge-10zIh .header-1W5Mu {
	background: var(--additional-business-lounge);
}

.root_priorityBoarding-2OSh- .header-1W5Mu {
	background: var(--additional-priority-boarding);
}

.root_insurance-3fN4w .header-1W5Mu {
	background: var(--additional-insurance);
}

.root_loyalty-r-0bU .header-1W5Mu {
	background: var(--additional-ffp);
}

.root_capsuleHotel-2pGE9 .header-1W5Mu {
	background: var(--additional-capsule-hotel);
}

.root_sms-2DCmw .header-1W5Mu {
	background: var(--additional-sms);
}

.header__title-KQI_7 {
	flex: 1;
	align-items: center;

	color: var(--white);
	font-weight: 700;
	font-size: 16px;
}

.header__icon-2uLcr {
	display: flex;
	align-items: center;
	justify-content: center;

	width: 60%;
	max-height: 100px;
}

.header__icon-2uLcr img {
	width: auto;
	height: 100%;
	object-fit: contain;
}

.content-AlwY0 {
	flex: 1;
	min-height: 100px;

	padding: 16px;

	color: var(--text-mid);
	font-size: 14px;
	line-height: 1.3;

	background: var(--white);
}

.root_loyalty-r-0bU .content-AlwY0 {
	display: flex;
	flex-direction: column;
	grid-gap: 10px;
	gap: 10px;
}

.description-ATkBo {
	color: var(--text-mid);
	font-size: 14px;
	line-height: 1.3;

	white-space: break-spaces;
}

.description-ATkBo a {
	color: var(--text-link);
}

.controls-2v5Iu {
	display: flex;
	justify-content: flex-end;

	margin-bottom: 10px;
	padding: 0 16px;
}

.footer-MrdAL {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	padding: 0 16px 16px;
	grid-gap: 8px;
	gap: 8px;
}

.footer__price-1QgCQ {
	font-size: 16px;
	font-weight: 700;
	color: var(--text-base);
}

/* stylelint-disable */
.button-1M4Qp {
}

.button_add-144Yz {
}

.button_change-3zqWv {
}
