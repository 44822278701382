.additional-2CdeJ {
	position: relative;

	display: flex;
	align-items: center;

	font-size: 14px;
	color: #ffffff;
}

@media screen and (max-width: 600px) {
	.item-1289K {
		display: block;
		padding-top: 8px;
		padding-bottom: 8px;

		border-bottom: 1px solid rgba(255, 255, 255, 0.4);

		line-height: 28px;
	}

	span.item-1289K:not(:last-of-type) {
		margin-right: 0;
	}
}

.voucher-2eDNa {
	position: absolute;
	left: -55px;
	z-index: 1;

	width: 300px;

	margin-top: 4px;

	border-radius: 2px;

	line-height: 20px;

	box-shadow: 0 1px 10px rgba(0, 0, 0, 0.15);

	background-color: #ffffff;
}

.vouchers-2zcLn {
	line-height: 20px;
}

.vouchers__title-rb2p5 {
	opacity: 0.7;
	cursor: pointer;
}

.separator-5kYR2 {
	height: 20px;
	width: 1px;

	margin: 0 16px;

	background-color: rgba(255, 255, 255, 0.3);
}

.miles-2Cn8d {
	line-height: 20px;
}

@media screen and (max-width: 600px) {
	.miles-2Cn8d {
		display: flex;
		align-self: center;
		margin-top: 7px;
		padding-bottom: 8px;
		padding-left: 0;

		line-height: 20px;
	}

	.miles-2Cn8d:after {
		display: none;
	}

	.miles__switch-7eOCg {
		flex-basis: 100%;
	}
}
