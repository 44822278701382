

.icon-sAXHG {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-left: 5px;

	color: inherit;
}

.icon-sAXHG svg * {
	fill: #ffffff;
}
