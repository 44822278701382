.segments-183cz {
	flex: 1;
	padding: 0;

	list-style: none;
}

.segment-2_E6J {
	display: flex;
	margin-bottom: 8px;

	font-size: 19px;
}

.segment-2_E6J:last-child {
	margin-bottom: 0;
}

.segment__info-1NdJj {
	display: flex;
}

.segment__info-1NdJj div {
	text-align: center;
}

.segment__info-1NdJj div:not(:last-child) {
	padding-right: 28px;
}

@media screen and (max-width: 600px) {
	.segment-2_E6J {
		display: block;

		font-size: 16px;
	}

	.segment-2_E6J:not(:first-child) {
		margin-top: 20px;
	}

	.segment__info-1NdJj div:not(:last-child) {
		padding-right: 15px;
	}

	.segment__info-1NdJj {
		padding-top: 5px;
	}
}

.segment__airports-H7SiW {
	flex: 1;

	min-width: 150px;

	font-weight: 600;

	white-space: nowrap;
	text-transform: uppercase;
	text-overflow: ellipsis;
	overflow: hidden;
}

.segment__date-3hUpx {
	font-weight: 600;
}

@media screen and (max-width: 600px) {
	.segment__date-3hUpx {
		font-weight: 400;
	}
}
