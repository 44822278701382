

button.primary-3Ke4q {
	font-weight: 500;
	color: #ffffff;

	background-color: var(--base);
}

button.primary-3Ke4q:hover,
button.primary-3Ke4q:focus {
	background-color: var(--base-15);
}

button.primary-3Ke4q:disabled {
	color: var(--gray30);

	background-color: var(--gray10);
}

button.secondary-1uH3Z {
	font-weight: 500;
	color: var(--gray70);

	background-color: var(--gray05);
}

button.secondary-1uH3Z:hover,
button.secondary-1uH3Z:focus {
	background-color: var(--gray10);
}

button.secondary-1uH3Z:disabled {
	color: var(--gray30);

	background-color: var(--gray10);
}
