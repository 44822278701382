

.sexSelector-2__rJ {
	display: flex;
	flex-direction: column;
}

.sexSelector__container-2pGOi {
	display: flex;
	grid-gap: 1px;
	gap: 1px;
	margin-top: 8px;
}

.sexSelector__helperText-3jI6U {
	margin-top: 4px;

	color: var(--error-icon);
	font-weight: 400;
	font-size: 13px;
	line-height: 1.1;
}

.input-1A5HV {
	display: none;
}

.label-3DJpM {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 52px;
	height: 48px;

	border: 1px solid var(--brand-1-3);

	color: var(--brand-1-0);

	cursor: pointer;

	background: var(--brand-1-5);
}

.label-3DJpM:first-of-type {
	padding-left: 4px;

	border-radius: 100px 0 0 100px;
}

.label-3DJpM:last-of-type {
	padding-right: 4px;

	border-radius: 0 100px 100px 0;
}

.label-3DJpM.checked-1trtZ {
	border-color: var(--brand-1-0);

	color: var(--white);

	background: var(--brand-1-0);
}

.label-3DJpM.error-BPE72 {
	border-color: var(--error-stroke-hover);

	color: var(--error-icon);

	background: var(--error-bg);
}

.label-3DJpM.disabled-2THqV {
	cursor: auto;
	cursor: initial;
}

.icon-sZQu6 {
	display: flex;
	align-items: center;
	justify-content: center;
}
