

.list_reset-2RDoS {
	margin: 0;
	padding: 0;

	line-height: inherit;
}

.container-Bhein {
	max-width: 1100px;
	margin: 20px auto;
}
