

button.root-39rE4 {
	padding-right: 24px;
	padding-left: 24px;

	font-weight: 400;
	line-height: 1.3;
	text-transform: none;
}

span.endIcon-HkVy3 {
	margin-left: 4px;
}

button.secondary-1sulr,
button.action-1yPzc {
	min-height: 52px;
	padding: 12px 24px;
}

/* Secondary / SmallSecondary */
button.smallBrand-I-8q6,
button.smallSecondary-2bn7x,
button.smallPrimary-2Deg1,
button.smallNeitral-2fK3N,
button.smallThird-2D2he {
	min-width: 90px;
	min-height: 39px;
	padding-top: 10px;
	padding-bottom: 10px;

	border-radius: var(--btn-small-brds);

	font: var(--normal-desktop-bold);
}

button.secondary-1sulr {
	min-width: 120px;

	border-radius: var(--btn-secondary-border-radius);

	font: var(--medium-desktop);
}

button.secondary-1sulr,
button.smallSecondary-2bn7x {
	color: var(--text-light);

	background: var(--bg-fill);
}

button.secondary-1sulr:hover,
button.smallSecondary-2bn7x:hover {
	color: var(--text-light);

	background: var(--bg-elements);
}

button.secondary-1sulr.disabled-SSAxj,
button.smallSecondary-2bn7x.disabled-SSAxj {
	color: var(--line-dot-line);

	background: var(--bg-fill);
}

/* SmallBrand  */
button.smallBrand-I-8q6 {
	color: var(--white);

	background: var(--brand-1-0);
}

button.smallBrand-I-8q6:hover {
	background: var(--brand-1-1);
}

button.smallBrand-I-8q6.disabled-SSAxj {
	background: var(--brand-1-3);
}

/* Action / SmallPrimary  */
button.action-1yPzc {
	min-width: -moz-fit-content;
	min-width: fit-content;

	border-radius: var(--btn-action-border-radius);

	font: var(--medium-desktop-bold);
}

button.action-1yPzc,
button.smallPrimary-2Deg1 {
	color: var(--white);
	font-weight: 700;

	background: var(--brand-3-0);
}

button.action-1yPzc:hover,
button.smallPrimary-2Deg1:hover {
	color: var(--white);

	background: var(--brand-3-1);
}

button.action-1yPzc.disabled-SSAxj,
button.smallPrimary-2Deg1.disabled-SSAxj {
	color: var(--white);

	background: var(--brand-3-3);
}

button.action-1yPzc.disabled-SSAxj.loading-2Jlpy,
button.smallPrimary-2Deg1.disabled-SSAxj.loading-2Jlpy {
	background: var(--bg-fill);
}

/* SmallNeitral */
button.smallNeitral-2fK3N {
	color: var(--brand-1-0);
	font-weight: 700;

	background: var(--brand-1-5);
}

button.smallNeitral-2fK3N:hover {
	color: var(--brand-1-0);

	background: var(--brand-1-4);
}

button.smallNeitral-2fK3N.disabled-SSAxj {
	color: var(--text-pale);

	background: var(--bg-fill);
}

/* smallThird */
button.smallThird-2D2he {
	color: var(--white);

	background: var(--brand-1-0);
}

button.smallThird-2D2he:hover {
	background-color: var(--brand-1-1);
}

button.smallThird-2D2he.disabled-SSAxj {
	color: var(--text-pale);

	background: var(--bg-fill);
}

/* OptionEdit */
button.optionEdit-3n4Di,
button.optionAction-2gwMA,
button.optionActionPlusIcon-14xNG {
	min-width: 90px;
	min-height: 34px;
	padding: 8px 16px;

	border-radius: 40px;

	font: var(--normal-desktop-accent);
}

button.optionEdit-3n4Di {
	border: 1px solid var(--brand-1-3);

	color: var(--text-link);

	background: var(--white);
}

button.optionEdit-3n4Di:hover {
	border-color: var(--brand-2-4);

	color: var(--brand-1-0);

	background: var(--brand-2-4);
}

button.optionEdit-3n4Di.disabled-SSAxj {
	border-color: var(--bg-elements);

	color: var(--text-pale);

	background: var(--bg-elements);
}

/* OptionAction */
button.optionAction-2gwMA,
button.optionActionPlusIcon-14xNG {
	color: var(--white);

	background: var(--brand-1-0);
}

/* OptionActionPlusIcon */
button.optionActionPlusIcon-14xNG {
	padding-top: 4px;
	padding-bottom: 4px;
}

button span.startIcon-1rfX1 svg,
button.optionActionPlusIcon-14xNG span.endIcon-HkVy3 svg {
	width: 20px;
	height: 20px;
}

button.optionAction-2gwMA:hover,
button.optionActionPlusIcon-14xNG:hover {
	color: var(--white);

	background: var(--brand-1--1);
}

button.optionAction-2gwMA.disabled-SSAxj,
button.optionActionPlusIcon-14xNG.disabled-SSAxj {
	color: var(--text-pale);

	background: var(--bg-elements);
}

/* text */
button.text-1XilJ {
	min-width: 0;
	height: auto;
	padding: 4px 8px;

	border-radius: 4px;

	color: var(--common-blue);
	line-height: normal;
	font-size: 15px;

	background-color: transparent;
}

button.text-1XilJ:hover {
	background-color: transparent;
}

button.loading-2Jlpy,
button.loading-2Jlpy:hover {
	border-color: transparent;

	background: var(--bg-fill);
}
