.root-2m5a- {
	width: 660px;
	padding: 40px 40px 47px;
}

.title-3fboo {
	font-size: 30px;
	font-weight: 500;
	color: var(--text-base);
}

.message-3oM9Q {
	margin-top: 43px;

	color: var(--text-base);
	font-size: 16px;
	line-height: 1.4;
}

.row-3igkt {
	display: flex;
	align-items: flex-start;

	margin-top: 31px;
}

.input_wrapper-_akN4 {
	flex-grow: 1;
}

.button_wrapper-29iFq {
	margin-left: 20px;
	padding-top: 4px;
}

.resend-keQhT {
	margin-top: 21px;

	font-size: 14px;
	line-height: 20px;
	color: var(--text-mid);
}

.resend__label-2slUk {
	justify-content: flex-start;
}

.resend__label-2slUk a {
	text-decoration: none;
	color: var(--brand-1-0);
}

@media screen and (max-width: 600px) {
	.root-2m5a- {
		width: auto;

		/* padding: 47px 28px; */
		padding: 0;
	}

	.message-3oM9Q {
		margin-top: 38px;
	}

	.row-3igkt {
		flex-direction: column;
		align-items: normal;
		margin-top: 25px;
	}

	.button_wrapper-29iFq {
		margin-left: 0;
		margin-top: 20px;
		padding-top: 0;
	}

	.resend-keQhT {
		font-size: 16px;
		line-height: 24px;
	}
}
