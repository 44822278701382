.wrapper-3U-ST {
	padding: 12px;
	display: flex;
	width: 100%;
	margin: 0 auto;
	align-items: center;
	justify-content: space-between;

	border-radius: var(--service-border-radius);

	color: #ffffff;
	font-weight: 500;
	font-size: 15px;
	line-height: 18px;

	background: #e15c5c;
}

.wrapper-3U-ST div {
	color: var(--error-text);
}

.wrapper-3U-ST .icon-1btOY {
	margin-right: 12px;
	display: flex;
	align-items: center;
}

.wrapper-3U-ST.wrapper_outlined-xcKVM {
	padding: 12px 20px;
	max-height: 48px;

	color: #b70000;
	font-weight: 400;

	background: #ffffff;

	box-shadow: 0 1px 2px rgba(0, 0, 0, 0.08);
}

.info-2pX4M {
	display: flex;
	align-items: center;
}

button.cancelButton-2riUm {
	border-radius: 0;

	color: #9a9a9a;
	font-size: 13px;
	font-weight: 500;
	line-height: 150%;

	background-color: transparent;

	transform: translateX(20px);
}

button.cancelButton-2riUm:hover {
	background-color: transparent;
}

@media screen and (max-width: 600px) {
	div.wrapper-3U-ST,
	.wrapper-3U-ST.wrapper_outlined-xcKVM {
		position: relative;

		display: flex;
		flex-direction: row;
		max-height: unset;

		overflow: hidden;

		border-radius: 0;
	}

	.wrapper-3U-ST.wrapper_outlined-xcKVM:after {
		content: '';

		position: absolute;
		bottom: 0;

		display: block;
		width: 100%;
		height: 10px;

		border-radius: 8px 8px 0 0;

		background-color: var(--checkout-background);
	}

	button.cancelButton-2riUm {
		padding: 10px 0 0;
		margin: 10px 0 20px;
		width: 100%;
		align-self: flex-end;
		justify-content: flex-end;
		height: 20px;

		border-top: 1px solid #e4e4e4;

		text-align: end;

		transform: none;
	}
}
