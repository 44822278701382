.day-jSZrc {

	font-size: 12px;
}

.dayOfWeekName-2cGAq {
	cursor: default;
}

.dayOfWeekName-2cGAq span,
.dayOfWeekName_withPrices-2dKeh span {
	border-bottom: 0;

	color: var(--light-gray);
	font-weight: 400;
}

.dayOfWeekName-2cGAq:nth-child(6n) span,
.dayOfWeekName-2cGAq:nth-child(7n) span,
.dayOfWeekName_withPrices-2dKeh:nth-child(6n) span,
.dayOfWeekName_withPrices-2dKeh:nth-child(7n) span {
	font-weight: 400;
}

.dayOfWeekName_withPrices-2dKeh {
	width: 44px;
	height: 44px;

	cursor: default;
}
