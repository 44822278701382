

button.root-3VGUE {
	width: 40px;
	min-width: unset;
	height: 40px;
	min-height: unset;
	padding: 8px;

	border-radius: 50%;

	color: var(--white);

	background: var(--brand-3-0);
}

button.root-3VGUE:hover {
	background: var(--brand-3--1);
}

button.root-3VGUE.success-t-ApN,
button.root-3VGUE.success-t-ApN:hover {
	background: var(--success-icon);
}

button.root-3VGUE.loading-3qUUS {
	background: var(--line-dot-line);
}

.root-3VGUE span.colorPrimary-1hBmj {
	color: var(--white);
}
