.breadcrumbs-2iSgH {
	display: flex;
	align-items: center;

	grid-gap: 12px;

	gap: 12px;
	max-width: 800px;
	flex-wrap: wrap;
}

.breadcrumb-2zv-9 {
	position: relative;

	display: flex;
	justify-content: space-between;
	max-width: 554px;
	padding: 8px 21px;

	border-radius: 100px;

	color: var(--base-15);
	font-weight: 500;
	font-size: 16px;
	line-height: 150%;
	white-space: nowrap;
	text-overflow: ellipsis;

	background: var(--base90);
	overflow: hidden;
}

.text-2TYtr {
	display: block;
	overflow: hidden;

	text-overflow: ellipsis;
}

@media (max-width: 1024px) {
	.breadcrumbs-2iSgH {
		flex-grow: 1;
	}
}

@media screen and (max-width: 600px) {
	.breadcrumb-2zv-9 {
		display: inline-flex;
	}
}

.breadcrumb-2zv-9.withCloseIcon-iLeju {
	padding-right: 44px;
}

.close-JCvaD {
	display: inline-flex;
	width: 24px;
	height: 24px;
	margin-left: 12px;
	margin-right: -9px;

	border-radius: 50%;

	color: #ffffff;

	cursor: pointer;

	background: #ffffff;
}

@media screen and (max-width: 600px) {
	.close-JCvaD {
		position: static;
		top: 0;

		margin-left: 17px;

		transform: none;
	}
}

.close-JCvaD svg {
	transform: scale(0.6);
}

.close-JCvaD path {
	color: var(--gray80);
}

/* stylelint-disable */
.passengersStep-dK0ea {
}
