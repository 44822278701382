

.segment-2KiUW:not(:first-of-type) {
	margin-top: 16px;
}

.dates-mcq64 {
	display: flex;
	align-items: center;
}

.date-2Sn4P {
	position: relative;

	color: #1f1f1f;
	font-weight: 700;
	font-size: 22px;
	font-family: 'Roboto Condensed', sans-serif;
}

.date-2Sn4P:first-of-type {
	padding-right: 30px;
}

.date-2Sn4P:first-of-type:after {
	position: absolute;
	top: 7px;
	left: 100%;
	z-index: 1;

	width: 7px;
	height: 7px;

	border: 1px solid #a5a5a5;
	border-radius: 50%;

	background: #ffffff;
	content: '';
}

.date-2Sn4P:last-of-type {
	padding-left: 30px;
}

.date-2Sn4P:last-of-type:after {
	position: absolute;
	top: 7px;
	right: 100%;
	z-index: 1;

	width: 7px;
	height: 7px;

	border: 1px solid #a5a5a5;
	border-radius: 50%;

	background: #ffffff;
	content: '';
}

.timeEnRoute-Tq315 {
	position: relative;

	flex-basis: 100%;

	font-size: 14px;
	text-align: center;
}

.timeEnRoute-Tq315:before {
	position: absolute;
	top: 7px;
	right: 0;
	left: 0;

	border-bottom: 1px solid #a5a5a5;
	content: '';
}

.timeEnRoute_text-2hERw {
	position: relative;

	padding: 0 8px;

	font-weight: 500;
	font-size: 15px;

	background: #ffffff;
}

.airports-zTbjg {
	display: flex;
	margin-top: 5px;
	justify-content: space-between;
}

.airport-3NWn9 {
	max-width: 50%;

	color: #1f1f1f;
	font-weight: 500;
	font-size: 14px;
}

.airport__city-3oFla {
	line-height: 20px;
}

.airport__name-3-Pyr {
	font-size: 13px;
	line-height: 16px;
	font-weight: 400;

	opacity: 0.7;
}

.airport-3NWn9:last-child {
	text-align: right;
}

.details-10yuP {
	margin-top: 12px;

	border-top: 1px dashed #d9d9d9;
}

.details__summary-3hl9f {
	font-size: 13px;

	opacity: 0.7;
}

.details__summary-3hl9f span:not(:first-child) {
	padding-left: 5px;
}

.details__summary-3hl9f span:not(:first-child):before {
	content: '\2022';

	padding-right: 5px;
}

.transfer-CBood {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 8px 20px;

	font-size: 13px;
	line-height: 20px;
}

.transfer-CBood span:last-of-type:not(:first-of-type) {
	margin-top: 4px;
	margin-left: 0;
}

.transfer-CBood svg {
	position: relative;
	top: 2px;
}
