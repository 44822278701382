.card-377Wa {
	position: relative;

	padding: 32px 40px;

	color: var(--accent-default);
	text-align: center;
}

.card_minimum-aKjxZ {
	background: var(--white);
}

.card_optimum-3WGYf {
	background: var(--accent-subtle);
}

.card_maximum-IySfa {
	color: var(--white);

	background: linear-gradient(180deg, #aa65e0 0%, var(--accent-default) 100%);
}

.tag-33vor {
	position: absolute;
	top: 42px;
	right: 20px;

	padding: 4px 12px;

	border-radius: 100px;

	font-size: 14px;

	background: var(--white);
}

.icon-2Nt6J svg {
	width: 48px;
	height: 48px;
}

.type-1_VEQ {
	margin: 25px 0;

	font-size: 32px;
}

.services-2Y147 {
	display: inline-block;
	margin: 0 0 25px;
	padding-left: 15px;

	font-weight: 500;
}

.service-1SrlO {
	display: flex;
	align-items: center;

	color: var(--fg-default);

	text-align: left;

	list-style-type: none;
}

.service-1SrlO:not(:last-of-type) {
	margin-bottom: 14px;
}

.service-1SrlO svg {
	margin-right: 5px;
	min-width: 24px;
}

.service-1SrlO svg path {
	fill: var(--accent-default);
}

.price-23EqV,
button.select-A0q3z {
	font-size: 22px;
}

.type-1_VEQ,
.price-23EqV,
button.select-A0q3z,
.tag-33vor {
	font-weight: 600;
}

.card-377Wa button.select-A0q3z {
	max-width: 100%;
	margin-top: 25px;
	padding: 16px 24px;

	border-radius: 6px;

	color: var(--white);
	line-height: 29px;
	white-space: normal;
	text-transform: unset;

	background: var(--accent-default);
}

.card_minimum-aKjxZ .tag-33vor {
	background: var(--accent-subtle);
}

.card_maximum-IySfa .tag-33vor {
	color: var(--accent-default);
}

.card_maximum-IySfa .service-1SrlO {
	color: var(--white);
}

.card_maximum-IySfa .service-1SrlO svg path {
	fill: var(--white);
}

.card_maximum-IySfa button.select-A0q3z {
	background: var(--accent-secondary);
}

@media (max-width: 1024px) {
	.title-2uuFH {
		width: 200px;
		min-width: 200px;
		margin-right: 15px;
	}

	.type-1_VEQ {
		font-size: 22px;
	}

	.services-2Y147 {
		padding-left: 0;
		padding-right: 15px;
	}
}

@media (max-width: 768px) {
	.title-2uuFH {
		width: 130px;
		min-width: 130px;
	}

	.type-1_VEQ {
		overflow: hidden;

		text-overflow: ellipsis;
	}
}

@media (max-width: 1024px) and (min-width: 601px) {
	.card-377Wa {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 32px 24px;
	}

	.card-377Wa:not(:last-of-type) {
		margin-bottom: 30px;
	}

	.type-1_VEQ {
		margin: 16px 0;
	}

	.tag-33vor {
		position: static;
		position: initial;
	}

	.card-377Wa button.select-A0q3z {
		width: 186px;

		font-size: 18px;
	}

	.services-2Y147 {
		margin: 0;
	}
}

@media screen and (max-width: 600px) {
	.card-377Wa {
		padding: 32px 35px;
	}

	.card-377Wa:not(:last-of-type) {
		margin-bottom: 30px;
	}

	.services-2Y147 {
		padding-left: 0;
	}

	.title-2uuFH {
		width: auto;
		min-width: none;
	}
}
