.social__buttons__logo-24Y1X {
	margin-right: 8px;
	display: flex;
	align-items: center;
}

.social__buttons__wrapper-KqNyq {
	position: relative;
}

.social__buttons__wrapper-KqNyq:nth-child(2) {
	margin: 0 30px;
}

.social__buttons__control-7f2nz {
	position: absolute;
	right: -14px;
	top: 7px;

	z-index: 1000;

	height: 28px;

	border-radius: 50%;
	border: 4px solid #ffffff;

	cursor: pointer;

	background-color: #ffffff;
}

.social__buttons__control-7f2nz svg {
	height: 20px;
	width: 20px;
}

.social__buttons__control-7f2nz svg path {
	transition: all 0.2s;

	fill: #cdcdcd;
}

.social__buttons__control-7f2nz:hover svg path {
	fill: #9a9a9a;
}

.social__buttons__wrapper-KqNyq button.google-2NyNf {
	justify-content: center;
}

.social__buttons__wrapper-KqNyq button.facebook-3SRKn {
	border-color: #3c5b96;

	color: #ffffff;

	background-color: #3c5b96;
}

.social__buttons__wrapper-KqNyq > button {
	padding: 7px 17px;
	justify-content: flex-start;
	align-items: center;

	font-weight: 600;
	line-height: 22px;
}

.social__buttons__wrapper-KqNyq > button.twitter-3e_in {
	border-color: #59adeb;

	color: #ffffff;

	background-color: #59adeb;
}

.disabled-2mk8s {
	opacity: 0.5;
}

.social__buttons__control-7f2nz.add__icon-1n66l svg path {
	fill: var(--cabinet-action);
}

.social__buttons__wrapper-KqNyq > button.button__disabled-UnYe6 {
	border: 1px solid rgba(0, 0, 0, 0.23);

	color: var(--dark-gray);
}

.social__buttons__wrapper-KqNyq > button.button__disabled-UnYe6.facebook-3SRKn,
.social__buttons__wrapper-KqNyq > button.button__disabled-UnYe6.twitter-3e_in {
	border-color: inherit;

	color: #ffffff;
}

@media screen and (max-width: 600px) {
	.social__buttons__wrapper-KqNyq:nth-child(2) {
		margin: 20px 0;
	}

	.social__buttons__wrapper-KqNyq button {
		width: 100%;
		justify-content: center;
	}
}
