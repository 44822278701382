
/* stylelint-disable */
.block-1oldP {
	display: flex;
	justify-content: center;
}

.popupWrapper_focused-6f_y2 {
}

/* stylelint-enable */
.popupWrapper--2ZDg {
	z-index: 1;

	width: 100%;
}

.input__wrapper-2oaQ9 {
	position: relative;
}
