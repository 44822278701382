.title-3wxYD {
	color: var(--fg-default);
	font-size: 36px;
}

.slider-29njo .slick-track {
	display: flex;
}

.slider-29njo .slick-slide > div {
	padding: 0 15px;
}

.slider-29njo .slick-list {
	margin: 0 -15px;
}

h2.header-2Ej5J {
	margin-top: 0;
}

@media screen and (max-width: 600px) {
	.title-3wxYD {
		font-size: 28px;
	}
}
