.passengers-3k-An {
	display: flex;
	flex-direction: column;
}

.passenger-svI9J {
	box-shadow: 4px 4px 33px rgba(0, 0, 0, 0.05);
	overflow: hidden;
}

.passengers_started-o-aVt .passenger-svI9J {
	border-bottom: 1px dashed var(--line-dot-line);
}

.passenger-svI9J:first-child {
	border-radius: 4px 4px 0 0;
}

.passenger-svI9J:last-child {
	border-radius: 0 0 4px 4px;
	border-bottom: none;
}

.registeredPassenger-2F9_f {
	margin-bottom: 12px;

	border-radius: 4px;

	box-shadow: 4px 4px 33px rgba(0, 0, 0, 0.05);
	overflow: hidden;
}

.registeredPassenger-2F9_f:last-child {
	margin-bottom: 0;
}

.slider-3woAa .slick-list {
	padding-left: 12px;
	padding-bottom: 40px;
}

.slider-3woAa .slick-slide {
	padding-right: 12px;
}

.slider-3woAa .slick-dots {
	bottom: 10px;
}

.slider-3woAa .slick-dots li {
	width: 16px;
	height: 16px;
	margin: 0;
}

.slider-3woAa .slick-dots li button,
.slider-3woAa .slick-dots li button:before {
	width: 16px;
	height: 16px;

	line-height: 16px;
}

@media screen and (max-width: 600px) {
	.passengers-3k-An {
		padding: 0 12px;
	}

	.passengers-3k-An.passengers_confirmed-2XR9- {
		padding: 0;
	}

	.segment-1xAzE {
		position: relative;
	}

	.segment-1xAzE:after {
		content: '';

		position: absolute;
		left: 20px;
		right: 20px;
		bottom: 0;

		border-bottom: 1px dashed var(--line-dot-line);
	}

	.registeredPassenger-2F9_f {
		border-radius: 12px;

		box-shadow: 4px 9px 38px rgba(0, 0, 0, 0.1);
	}
}
