.sidebar-3i31L {
	max-width: 280px;
	min-width: 280px;
	height: -moz-fit-content;
	height: fit-content;
	padding: 20px;

	border-radius: 4px;

	background-color: var(--account-sidebar-background);
}

.sidebar-3i31L.root-eRWI8 {
	padding: 20px;
}

.sidebar-3i31L .sidebar__item-2hNfI {
	padding: 12px 16px 12px 16px;

	border-radius: 4px;
}

.sidebar-3i31L .sidebar__item-2hNfI:hover,
.sidebar-3i31L div.sidebar__item_selected-1KZC6,
.sidebar-3i31L div.sidebar__item_selected-1KZC6:hover {
	background-color: var(--brand-1-1);
}

.sidebar-3i31L .sidebar__item-2hNfI span {
	color: #ffffff;
	font-size: 14px;
	font-weight: 500;
	font-family: inherit;
}

.title-2TaKP {
	display: none;
	margin-bottom: 10px;

	color: var(--bg-white);
	font-weight: 500;
	font-size: 18px;
	line-height: 1;
}

.sidebar-3i31L .sidebar__item-2hNfI .icon-3msiP {
	margin-right: 0;
	min-width: 42px;

	font-size: 24px;
	color: var(--brand-1-3);
}

.sidebar__item-2hNfI .item__text-3GDJW {
	padding: 0 22px 0 0;

	color: var(--bg-white);
	overflow: hidden;
	text-overflow: ellipsis;
	font-weight: 500;
}

.rotate90-1LB8G {
	transform: rotate(90deg);
}

a {
	text-decoration: none;
}

.svg-84dYC {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 24px;
	min-width: 24px;
	height: 24px;
}

.sidebar__item-2hNfI span.badge-27Cz3 {
	padding-right: 6px;
	padding-left: 6px;

	color: var(--brand-1-0);

	background-color: var(--bg-white);
}

@media (max-width: 1024px) {
	ul.sidebar-3i31L {
		padding-top: 0;
		padding-bottom: 0;
		min-width: 75vw;
		max-width: unset;
	}

	.sidebar-3i31L .sidebar__item-2hNfI {
		padding: 30px 22px;
	}
}
