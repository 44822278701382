

.container-1wefb {
	width: -moz-fit-content;
	width: fit-content;
	padding: 12px;

	color: var(--bright-violet);
	font-weight: 500;
	font-size: 14px;

	background: var(--very-pale-violet);
}

.bottom-1UV4I {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-top: 9px;
}

.link-7MwqX {
	display: flex;
	align-items: center;

	color: var(--bright-violet);
	text-decoration: none;
}

.link-7MwqX svg {
	width: 15px;
	height: 15px;
}

.arrowWrapper-2K5Pd {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 32px;
	height: 32px;
	margin-left: 8px;

	border-radius: 100px;

	background: var(--bg-white);
}

.servicesIcons-3rMre {
	display: flex;
}

.servicesIcons-3rMre svg {
	height: 21px;
}
