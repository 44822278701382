.summary-1F5IY {
	z-index: 3;

	display: flex;
	align-items: center;
	min-height: 56px;
	padding-right: 20px;

	cursor: pointer;
}

@media screen and (max-width: 600px) {
	.summary-1F5IY {
		min-height: 46px;
		padding: 0 20px;
		width: 100%;
		height: 46px;
	}
}

@media (max-width: 1024px) {
	.summary-1F5IY {
		padding: 0 20px;
		width: 100%;
		height: 46px;
	}
}

.route-oMXnI {
	color: var(--quickSearchForm-summary-route-color);

	font-size: 18px;
	font-weight: 500;
	line-height: 1.3;

	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
}

.route__icon-L32KU {
	margin: 0 8px;
}

.route__separator-2B2oK {
	margin: 0 8px;
}

@media screen and (max-width: 600px) {
	.route-oMXnI {
		font-size: 14px;
		line-height: 16px;
		color: #ffffff;
	}
}

.passengers-1ZG2I,
.dates-IKksc,
.editButtonWrapper-1U3eP {
	position: relative;

	margin-left: 12px;
	padding-left: 12px;
}

.passengers-1ZG2I,
.dates-IKksc {
	color: var(--quickSearchForm-summary-passengers-color);
	font-weight: 500;

	font-size: 18px;
	line-height: 1.3;
}

.passengers-1ZG2I {
	max-width: 50%;

	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
}

.dates-IKksc {
	flex-shrink: 0;
}

.passengers-1ZG2I:before,
.dates-IKksc:before,
.editButtonWrapper-1U3eP:before {
	position: absolute;

	top: 0;
	bottom: 0;
	left: 0;

	display: block;

	width: 4px;
	height: 4px;
	margin: auto;

	border-radius: 50%;

	background: var(--quickSearchForm-summary-trigger-border-color);
	content: '';
}

.dates-IKksc span:not(:last-child) {
	margin-right: 5px;
}

.dates-IKksc span:not(:first-child):before {
	content: '- ';
}

@media screen and (max-width: 600px) {
	.passengers-1ZG2I {
		max-width: 100%;

		font-size: 14px;
	}

	.dates-IKksc {
		display: none;
	}

	.editButtonWrapper-1U3eP {
		position: absolute;
		right: 20px;

		margin-left: 0;

		margin-left: initial;
		padding-left: 0;
		padding-left: initial;
	}

	.editButtonWrapper-1U3eP:before {
		content: normal;
		content: initial;
	}
}
