.wrapper-2MIGL {
	position: relative;

	display: flex;
	overflow: hidden;
	padding-top: 12px;
}

.wrapper-2MIGL:after {
	position: absolute;
	top: 0;
	z-index: 1;

	width: 100%;
	height: 98px;

	transform: translateX(100%);
	opacity: 0.7;
	background: linear-gradient(
		to right,
		rgba(255, 255, 255, 0) 0%,
		rgba(255, 255, 255, 0.8) 50%,
		rgba(128, 186, 232, 0) 99%,
		rgba(125, 185, 232, 0) 100%
	);

	animation: slide-3kXmV 1s infinite 0.5s;
	content: '';
}

.item-1W0RW {

	flex: 1;
	margin: 0 1px;

	cursor: default;
}

.item-1W0RW span {
	display: inline-block;

	background-color: rgba(205, 205, 205, 0.5);
}

.item__head-2bTF- {

	text-align: center;
}

.item__head-2bTF- span {
	width: 30px;
	height: 19px;
}

.item__middle-3o36x {

	display: flex;
	align-items: center;
	justify-content: center;
}

.item__middle-3o36x:after {
	border-top: none;
}

.item__middle-3o36x span {
	width: 60px;
	height: 16px;
}

@keyframes slide-3kXmV {
	0% {
		transform: translateX(-100%);
	}

	100% {
		transform: translateX(100%);
	}
}
