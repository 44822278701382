

.wrapper-1Qqds {
	position: fixed;
	left: 0;
	top: 0;
	z-index: 1502;

	width: 100vw;
	height: 100vh;
	display: flex;
	justify-content: center;

	background: rgba(65, 65, 65, 0.7);
	cursor: wait;
}

.loader-37PUE {
	position: relative;
	top: 300px;

	display: flex;
	justify-content: center;
	align-items: center;

	height: 106px;
	width: 106px;

	background: url('https://cdn.websky.aero/content/frontend/images/plane-loader-ellipse.png') center no-repeat;

	animation: rotation-10PM_ 0.75s linear infinite;

	pointer-events: none;
}

.loader-37PUE:after {
	content: '';

	position: absolute;
	bottom: -7px;

	height: 18px;
	width: 18px;

	background: url('https://cdn.websky.aero/content/frontend/images/plane-loader-plane.png') center no-repeat;
}

@keyframes rotation-10PM_ {
	from {
		transform: rotate(0deg);
	}

	to {
		transform: rotate(359deg);
	}
}
