.wrapper-1rAXP {
	padding-bottom: 50px;

	background: var(--seatMap-passenger-background);
}

.wrapper_threeServices-IeUIq {
	min-height: 1660px;
}

.wrapper_fourServices-ilSOC {
	min-height: 2100px;
}

.infoMessage-eeP6T {
	margin: 0 auto;
	max-width: 1100px;
}

div.infoMessage__htmlMessage-18VVF {
	height: 48px;
	width: 100%;
}

@media (max-width: 1024px) {
	.wrapper_threeServices-IeUIq,
	.wrapper_fourServices-ilSOC {
		min-height: auto;
	}

	.infoMessage-eeP6T {
		z-index: 1;

		margin: 8px auto 0 auto;
	}
}

.segments__wrapper-2tcXw {
	display: flex;
	justify-content: center;

	border-bottom: 0;
}

.segmentTabs-2TxGX {
	font-weight: inherit;
}

.segmentTabs_tab-5hzWM {
	font-weight: inherit;
}

.segmentTabs_active-1iBvC {
	font-weight: inherit;
}

.segments__wrapper_sticky-1lZay {
	padding-top: 78px;
}

.unavailable-usOTP {
	align-items: center;
	width: 30vw;
	padding-top: 60px;
	margin: 0 auto;
	display: flex;
	flex-direction: column;
	min-height: 430px;

	text-align: center;
}

.unavailable__header-26usG {
	margin-top: 0;
}

.unavailable__img-3smyI {
	max-width: 50px;
}

.unavailable__title-1JLGU {
	margin: 20px 0;

	font-weight: 500;
	font-size: 21px;
	line-height: 27px;
}

button.unavailable__button-2FeV0 {
	min-height: 40px;
	max-width: 200px;
	padding: 0 15px;
	margin-bottom: 30px;

	border-radius: 0;
}

.map-Ttnnb {
	position: relative;

	min-height: 570px;
	margin: 0 auto 20px auto;
}

.helpers-3gpUJ {
	position: absolute;
	top: 20px;
	right: 0;
	left: 0;
	z-index: 2;

	display: flex;
	justify-content: space-between;
	max-width: 1140px;
	height: 0;
	margin: 0 auto;
}

.helpers_recommend-3szRc .airplane-2DEfM {
	opacity: 0.2;

	transition: opacity 0.2s;
}

.map-Ttnnb div.helpers_mobile-3Jjdv {
	z-index: 1;

	margin: 0;

	border-bottom: 1px solid #d9d9d9;

	box-shadow: none;
}

.map-Ttnnb div.helpers_mobile_expanded-1WB4V:before {
	opacity: 1;
}

.map-Ttnnb .helpers_mobile-3Jjdv:before {
	opacity: 1;
}

.map-Ttnnb .helpers_mobile-3Jjdv > .helpers_mobile__header-uPmBE {
	min-height: 54px;

	padding: 0 20px;
}

.deckTabs-38XCX > div {
	display: flex;
	width: 375px;
	margin: 0 auto;
}

.multideck-2h4Yx > div:first-child {
	border: none;
}

div.seat__select_mobile__header-1RhwA > div:first-child {
	padding-left: 20px;
}

div.seat__select_mobile_filled-1tKMZ > div:last-child {
	opacity: 0.5;
}

div.seat__select_mobile_filled-1tKMZ > div > div:not(:first-child) > div {
	font-weight: 500;
	font-size: 13px;
	color: #313131;
}

.map-Ttnnb .helpers_mobile-3Jjdv > .helpers_mobile__header-uPmBE > div:first-child {
	margin: 20px 0;
}

.map-Ttnnb .helpers_mobile-3Jjdv .helpers_mobile__content-3S3-O {
	display: flex;
	flex-direction: column;
	padding: 16px 20px 28px;

	border-top: 1px dashed #d9d9d9;
}

.sunside__legends-2p00b {
	display: flex;
}

.left-1-pUD {
	position: relative;
}

.right-1fyma {
	position: relative;

	width: 310px;

	transition: opacity 0.2s;
}

.helpers_recommend-3szRc .right-1fyma {
	opacity: 0.2;
}

.left__sun-88i5a {
	transform: translateX(50%);
}

.legends-m-afP {
	padding: 0 10px 20px;
}

.legend-1JBgp {
	display: flex;
	align-items: center;

	font-size: 14px;
	font-weight: 600;
	color: var(--common-gray);
}

.legend_reserved-2j4T3:before {
	width: 32px;
	height: 33px;
	margin-right: 15px;

	background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzIiIGhlaWdodD0iMzMiIHZpZXdCb3g9IjAgMCAzMiAzMyIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPG1hc2sgaWQ9InBhdGgtMS1pbnNpZGUtMSIgZmlsbD0id2hpdGUiPgo8cGF0aCBkPSJNMCA0LjVDMCAyLjI5MDg2IDEuNzkwODYgMC41IDQgMC41SDI4QzMwLjIwOTEgMC41IDMyIDIuMjkwODYgMzIgNC41VjI4LjVDMzIgMzAuNzA5MSAzMC4yMDkxIDMyLjUgMjggMzIuNUg0QzEuNzkwODYgMzIuNSAwIDMwLjcwOTEgMCAyOC41VjQuNVoiLz4KPC9tYXNrPgo8cGF0aCBkPSJNMCA0LjVDMCAyLjI5MDg2IDEuNzkwODYgMC41IDQgMC41SDI4QzMwLjIwOTEgMC41IDMyIDIuMjkwODYgMzIgNC41VjI4LjVDMzIgMzAuNzA5MSAzMC4yMDkxIDMyLjUgMjggMzIuNUg0QzEuNzkwODYgMzIuNSAwIDMwLjcwOTEgMCAyOC41VjQuNVoiIGZpbGw9IiNFREVERUQiLz4KPHBhdGggZD0iTTQgMS41SDI4Vi0wLjVINFYxLjVaTTMxIDQuNVYyOC41SDMzVjQuNUgzMVpNMjggMzEuNUg0VjMzLjVIMjhWMzEuNVpNMSAyOC41VjQuNUgtMVYyOC41SDFaTTQgMzEuNUMyLjM0MzE1IDMxLjUgMSAzMC4xNTY5IDEgMjguNUgtMUMtMSAzMS4yNjE0IDEuMjM4NTggMzMuNSA0IDMzLjVWMzEuNVpNMzEgMjguNUMzMSAzMC4xNTY5IDI5LjY1NjkgMzEuNSAyOCAzMS41VjMzLjVDMzAuNzYxNCAzMy41IDMzIDMxLjI2MTQgMzMgMjguNUgzMVpNMjggMS41QzI5LjY1NjkgMS41IDMxIDIuODQzMTUgMzEgNC41SDMzQzMzIDEuNzM4NTggMzAuNzYxNCAtMC41IDI4IC0wLjVWMS41Wk00IC0wLjVDMS4yMzg1OCAtMC41IC0xIDEuNzM4NTggLTEgNC41SDFDMSAyLjg0MzE1IDIuMzQzMTUgMS41IDQgMS41Vi0wLjVaIiBmaWxsPSIjRDlEOUQ5IiBtYXNrPSJ1cmwoI3BhdGgtMS1pbnNpZGUtMSkiLz4KPHJlY3QgeD0iMC41IiB5PSIxIiB3aWR0aD0iMzEiIGhlaWdodD0iMjQiIHJ4PSIzLjUiIGZpbGw9IiNFREVERUQiIHN0cm9rZT0iI0Q5RDlEOSIvPgo8L3N2Zz4K) center no-repeat;
	content: '';
}

.segments-1d2Bc {
	position: relative;

	margin: 0 !important;

	padding: 18px 0;
}

.segments_sticky-3Dr8o {
	position: fixed !important;
	top: 0;
	z-index: 2;

	width: 100%;
	margin: 0 !important;

	background: #ffffff;
}

.deckTabs-38XCX {
	display: flex;
}

.deckTabs-38XCX .deckTabs__tab-2Qo5c {
	display: flex;
	width: 187px;
	margin: 0;
}

.footer_mobile-2NfeG {
	position: fixed;
	bottom: 0;
	z-index: 3;

	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0 12px;
	height: 48px;
	width: 100vw;

	color: #ffffff;

	background-color: var(--dark-orange);
}

.footer_mobile-2NfeG button > span:first-child {
	color: #ffffff;
	font-size: 17px;
	font-weight: 500;
	text-transform: none;
}

.footer_mobile-2NfeG button:first-child > span:first-child {
	font-weight: 400;

	opacity: 0.7;
}

div.seat__select_mobile_empty-2jC7l {
	display: flex;
	height: 56px;
	margin: 8px 20px;

	border-radius: 4px;

	background: linear-gradient(180deg, #57a4fe 0%, var(--common-blue) 100%);
}

div.seat__select_mobile_filled-1tKMZ {
	border: 1px solid var(--common-blue);

	background: #ffffff;
}

div.seat__select_mobile_filled-1tKMZ > div > div > svg > g {
	opacity: 1;
}

div.seat__select_mobile_filled-1tKMZ > div > div > svg > g > path {
	fill: var(--common-blue);
}

div.seat__select_mobile__content-2PrA5 {
	padding: 0;
	margin-top: 48px;
}

div.seat__select_mobile__header-1RhwA {
	height: 48px;
	padding-left: 20px;

	font-size: 18px;
	font-weight: 600;
	line-height: 48px;
	color: #313131;
	text-align: left;
}

div.header_mobile-12asB {
	position: relative;
	top: -8px;

	margin-bottom: -8px;
	overflow: hidden;

	border-radius: 8px 8px 0 0;
}

.dialogWrapper-364jW {
	min-height: calc(100% - 80px);
}

div.header_mobile-12asB.header_mobile_overlay-2uYUg:after {
	content: '';

	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;

	background: rgba(0, 0, 0, 0.5);
}

.header__doubleSeat-51wOo {
	margin: 0 10px 20px 10px;
	padding: 20px;
}

button.segments__tab-8eh72 {
	max-width: 300px;
}

div.seatsInfo-3nYhA {
	overflow-y: auto;

	background: var(--gray05);
}

.header_passengers-1_LVM {
	min-height: 140px;
}

.seatsInfo_content-8XmK7 {
	padding: 0;
}

.passengers_container-17KUU {
	position: sticky;
	top: 100px;
}

@media (max-width: 1024px) {
	.helpers-3gpUJ {
		left: 5%;
	}

	.header__inner-3mWIp {
		overflow-y: auto;

		justify-content: space-between;
		padding: 0;
	}

	.header__text-1quSD {
		position: relative;
	}
}

@media (max-width: 819px) and (min-width: 601px) {
	.helpers-3gpUJ {
		left: 20px;
	}
}

@media (max-width: 768px) and (min-width: 601px) {
	div.header_fixed-2-MU- {
		position: fixed;
		top: 80px;
		left: 0;

		width: 100%;

		padding: 20px calc((100% - 394px) / 2);

		background: var(--white);
	}

	.header_passengers-1_LVM {
		max-width: 394px;
		min-height: 135px;
		margin: 0 auto;
		padding: 20px 0;

		background: var(--white);
	}
}

@media screen and (max-width: 600px) {
	.wrapper-1rAXP {
		position: relative;
		overflow-x: visible;

		background: #ffffff;
	}

	.sunside__legends-2p00b {
		justify-content: space-between;
	}

	.legends-m-afP {
		padding: 0;
	}

	.map-Ttnnb {
		margin: 0;
		padding-top: 32px;
	}

	div .segments-1d2Bc {
		margin-top: 0;
		padding-top: 6px;
	}

	div .segments-1d2Bc:after,
	div .segments-1d2Bc:before {
		content: '';

		position: absolute;
		z-index: 6;

		height: 61px;
		width: 23px;
	}

	div .segments-1d2Bc:after {
		right: 0;
		top: 0;

		background: linear-gradient(270deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
	}

	div.segments-1d2Bc:before {
		left: 0;
		top: 0;

		background: linear-gradient(90deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
	}

	.segments-1d2Bc .segments__tab-8eh72 {
		margin: 0;
		padding: 6px 16px 0;
	}

	div.passengers_dialog-2cmt0.header_fixed-2-MU- {
		top: 56px;
	}

	div.header_fixed-2-MU- {
		top: 0;

		width: 100%;

		box-shadow: 4px 7px 26px rgba(0, 0, 0, 0.1);
	}

	.segments__tab-8eh72 span > span > span > div > div:first-child {
		font-size: 14px;
		color: #3c3c3c;
	}

	div.header_mobile-12asB {
		box-shadow: 4px 7px 26px rgba(0, 0, 0, 0.1);
	}
}

.seatMap__freeText-1A0Q7 {
	margin-left: 5px;
}

@media (max-width: 1024px) and (min-width: 601px) {
	.header_passengers_wide-2BFTo {
		max-width: 450px;
	}
}

@media (max-width: 1024px) {
	.sunside__legends-2p00b {
		flex-direction: column;
		margin-bottom: 20px;
	}
}

@media screen and (max-width: 600px) {
	.unavailable-usOTP {
		width: 60vw;
		padding-top: 30px;
	}

	div.header_fixed-2-MU- {
		position: fixed;
	}
}
