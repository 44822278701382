div.paper-2EfV5 {
	display: flex;
	flex-direction: column;

	background: transparent;
}

div.paper_dropdown-2JknJ {
	padding: 20px 0;
}

.content-1hDrd {
	overflow: hidden;
}

.content_wrapper-1K_hm {
	width: 100%;
	max-width: 1100px;
	margin: 0 auto;
	padding: 20px 0 40px;
}

.paper_dropdown-2JknJ .content_wrapper-1K_hm {
	padding: 20px 0 0;
}

.paper_pagination-3P7zv .content_wrapper-1K_hm {
	padding-bottom: 20px;
}

.buttons-3emQf {
	display: flex;
	align-items: center;
	width: 100%;
	max-width: 1100px;
	margin: 20px auto 10px;
}

.paper_dropdown-2JknJ .buttons-3emQf {
	order: 1;
	margin: 0;
}

.infoMessage-2UVBd {
	width: auto !important;
	display: flex !important;

	min-width: 256px;
	max-width: 375px;
	height: 100%;

	border-radius: 8px;
	overflow: hidden;
}

.infoMessage__htmlContainer-3H4HU {
	height: 100%;
}

.infoMessage-2UVBd > div {
	border-radius: 8px;
}

.slider-2Z8K8 .slick-list {
	overflow: visible;
}

.slider-2Z8K8 .slick-track {
	display: flex !important;
}

.slider-2Z8K8 .slick-slide {
	height: inherit !important;
}

.slider-2Z8K8 .slick-slide > div {
	height: 100%;
}

.fareGroup__slide-1W5_S {
	height: 100%;
}

.fareInfo-2s2T0 {
	margin-bottom: 10px;
}

.fares-2veRP {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;

	grid-column-gap: 24px;

	column-gap: 24px;
}

button.fareGroup__button-Y_97r {
	border: 1px solid var(--gray10);

	color: var(--gray70);
	font-size: 16px;
	line-height: 19px;

	background: #ffffff;

	transition: none;
}

.infoMessage__htmlMessage-2Y0yL {
	height: 100%;
	width: 100%;
}

.upsaleRightInfoMessage-2ccTr .infoMessage__contentClassName-22nnl {
	justify-content: center;
}

.topInfoMessage--TT3p {
	width: 100%;
	max-width: 1100px;

	margin: 20px auto 0;
}

div.topInfoMessage__htmlMessage-nbf5B {
	width: 100%;
}

@media screen and (max-width: 600px) {
	button.fareGroup__button-Y_97r {
		flex: 1;
	}

	.infoMessage-2UVBd {
		max-width: 100%;
		min-width: 100%;
	}
}

button.fareGroup__button-Y_97r:hover {
	color: #ffffff;
}

.buttons-3emQf button.fareGroup__button-Y_97r {
	margin-right: 12px;
}

.buttons-3emQf button.fareGroup__button-Y_97r:last-child {
	margin-right: 0;
}

@media screen and (max-width: 600px) {
	button.fareGroup__button_business-1vj-Y.fareGroup__button_active-3oCXg {
		border-color: var(--base20);

		color: #ffffff;

		background: var(--base20);
	}

	.buttons-3emQf button.fareGroup__button-Y_97r {
		margin-bottom: 4px;
	}
}

button.fareGroup__button_active-3oCXg {
	color: #ffffff;

	background: var(--base);
}

button.fareGroup__button_right-3CC7d {
	margin-left: auto;
}

.title-3wlj4 {
	margin: 0 0 24px;

	font-size: 30px;
	font-weight: 600;
	line-height: 100%;
	color: var(--gray80);
}

@media screen and (max-width: 600px) {
	.title-3wlj4 {
		font-weight: 700;
		font-size: 20px;
		line-height: 20px;
	}
}

.closeIcon-2t_7L {
	top: 20px;
	right: 20px;

	border-radius: 50%;

	background: #ffffff;
}

.slider__wrp-2pZ_R {
	position: relative;

	margin-right: -18px;
	margin-bottom: 40px;
}

.paper_dropdown-2JknJ .slider__wrp-2pZ_R {
	margin-bottom: 0;
}

@media screen and (max-width: 600px) {
	.slider__wrp-2pZ_R {
		margin-right: 0;
	}
}

.flightInfo-3T8mC {
	padding: 21px;
}

@media screen and (max-width: 600px) {
	.flightInfo-3T8mC {
		padding-top: 10px;
	}
}

.fareGroup_stub-3tKtn {
	position: absolute;
	top: 20px;

	max-width: 280px;
	padding: 0;
}

.fareGroup_stub_small-3une- {
	min-height: 440px;
}

@media (max-width: 1024px) {
	.fareGroup_stub-3tKtn {
		display: none;
	}

	.infoMessage__htmlMessage-2Y0yL {
		margin-right: 0;
	}
}

.fareGroup_stub-3tKtn svg {
	color: inherit;
}

.fareGroup_stub__img-1hTYP {
	width: 100%;

	border-top-left-radius: 8px;
	border-top-right-radius: 8px;
}

.fareGroup_stub__content-2yiw7 {
	padding: 16px;
}

.stubOptions-1kqK4 {
	display: flex;
	justify-content: flex-start;
	max-width: 100px;

	color: var(--base);
}

.compareFaresLink-1pDWN svg {
	transform: translateY(2px);
}

.stubOptions-1kqK4 svg:not(:last-of-type) {
	margin-right: 13px;
}

.fareGroup_stub_business-2ekbu .stubOptions-1kqK4 {
	color: var(--base2);
}

.stubDescription-2J1e_ {
	margin: 15px 0 0;
	padding: 0;

	list-style-type: none;
}

.stubDescription__item-3XSfZ {
	padding: 2px 0;

	font-size: 14px;
	line-height: 18px;
}

.pagination__index-YXexG {
	position: absolute;
	bottom: -5px;
	left: 50%;

	transform: translate(-50%, 0);
}

.paper-2EfV5 .slick-slider {
	padding-bottom: 40px;
}

.paper_dropdown-2JknJ .slick-slider {
	padding-bottom: 20px;
}

.paper_pagination-3P7zv .slick-slider {
	padding-bottom: 30px;
}

.paper-2EfV5 .slick-list {
	padding-top: 20px;
	padding-left: 2px;
	padding-bottom: 2px;
}

.paper_dropdown-2JknJ .slick-list {
	padding-top: 0;
}

@media screen and (max-width: 600px) {
	.paper-2EfV5 .slick-slider {
		padding-bottom: 20px;
	}

	div.swipeable__paper-uHt0P {
		visibility: visible !important;
		overflow: visible;
	}

	.infoMessage-2UVBd {
		margin-top: -68px;
	}

	.content_offset-1fXPt {
		padding-bottom: 100px;
	}
}

@media screen and (max-width: 600px) {
	.slider__wrp_singleItem-3Sz-v .slick-list {
		padding-left: 0;
	}

	.slider__wrp-2pZ_R:not(.slider__wrp_singleItem-3Sz-v) .slick-list {
		margin-left: 20px;
	}

	.slider__wrp_singleItem-3Sz-v .slick-track,
	.slider__wrp_singleItem-3Sz-v .slick-track .slick-slide > div > div {
		margin: 0 auto;
	}
}

.paper-2EfV5 .slick-arrow {
	top: auto;

	bottom: -30px;

	width: 36px;
	height: 36px;

	border: 1px solid var(--gray05);
	border-radius: 50%;

	box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, 0.1);
	background: #ffffff;
}

@media screen and (max-width: 600px) {
	.paper-2EfV5 button.slick-arrow {
		display: none !important;
	}
}

.pagination-3QbO0 {
	max-width: 80px;
	margin: 0 auto;
}

/* @media laptop { */

/*	.paper :global .slick-slider { */

/*		padding-left: 300px; */

/*	} */

/* } */
.paper-2EfV5 .slick-arrow:before {
	display: none;
}

.paper-2EfV5 .slick-prev {
	left: 45%;

	background: #ffffff url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE1LjQxIDcuNDFMMTQgNkw4IDEyTDE0IDE4TDE1LjQxIDE2LjU5TDEwLjgzIDEyTDE1LjQxIDcuNDFaIiBmaWxsPSIjNEQ0RDREIi8+Cjwvc3ZnPgo=) center no-repeat;

	transition: opacity 0.3s;
}

.paper-2EfV5 .slick-next {
	right: 45%;

	background: #ffffff url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTkuOTk5OTcgNkw4LjU4OTk3IDcuNDFMMTMuMTcgMTJMOC41ODk5NyAxNi41OUw5Ljk5OTk3IDE4TDE2IDEyTDkuOTk5OTcgNloiIGZpbGw9IiM0RDRENEQiLz4KPC9zdmc+Cg==) center no-repeat;

	transition: opacity 0.3s;
}

.paper-2EfV5 .slick-prev.slick-disabled,
.paper-2EfV5 .slick-next.slick-disabled {
	opacity: 0.5;

	pointer-events: none;
}

.paper-2EfV5 .slick-prev:hover,
.paper-2EfV5 .slick-prev:focus,
.paper-2EfV5 .slick-prev:active {
	background: #ffffff url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE1LjQxIDcuNDFMMTQgNkw4IDEyTDE0IDE4TDE1LjQxIDE2LjU5TDEwLjgzIDEyTDE1LjQxIDcuNDFaIiBmaWxsPSIjNEQ0RDREIi8+Cjwvc3ZnPgo=) center no-repeat;
}

.paper-2EfV5 .slick-next:hover,
.paper-2EfV5 .slick-next:focus,
.paper-2EfV5 .slick-next:active {
	background: #ffffff url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTkuOTk5OTcgNkw4LjU4OTk3IDcuNDFMMTMuMTcgMTJMOC41ODk5NyAxNi41OUw5Ljk5OTk3IDE4TDE2IDEyTDkuOTk5OTcgNloiIGZpbGw9IiM0RDRENEQiLz4KPC9zdmc+Cg==) center no-repeat;
}

.compareFaresWrp-WUiIJ {
	display: flex;
}

.compareFaresLink-1pDWN {
	display: flex;
	align-items: center;
	margin-left: auto;

	color: var(--gray50);
	font-size: 14px;
	line-height: 1.3;
	text-decoration: none;
}

.compareFaresLink_icon-1hF5s {
	margin-left: 6px;

	color: #b3b3b3;
}

@media screen and (max-width: 600px) {
	.compareFaresWrp-WUiIJ {
		margin: 15px 21px 0;
		padding: 8px 10px;

		border-radius: 4px;

		background: #ffffff;
	}

	.compareFaresLink-1pDWN {
		margin: 0 auto;

		transform: none;
	}

	.compareFaresWrp-WUiIJ svg {
		transform: translateY(4px) scale(0.9);
	}
}

@media (max-width: 1024px) {
	.paper-2EfV5 .slick-prev {
		left: 42%;
	}

	.paper-2EfV5 .slick-next {
		right: 42%;
	}
}

@media (max-width: 768px) {
	.paper-2EfV5 .slick-prev {
		left: 40%;
	}

	.paper-2EfV5 .slick-next {
		right: 40%;
	}
}

div.fareGroup_withoutUpgradeOption-heX_h {
	min-height: 470px;
}

@media screen and (max-width: 600px) {
	.paper-2EfV5 .slick-prev {
		left: 28%;
	}

	.paper-2EfV5 .slick-next {
		right: 28%;
	}
}

.paper-2EfV5 .slick-slide > div > div {
	margin-right: 24px;
}

@media (max-width: 1024px) {
	.buttons-3emQf,
	.content-1hDrd {
		padding: 0 20px;
	}
}

@media screen and (max-width: 600px) {
	.paper-2EfV5 .slick-slide > div > div {
		margin-right: 5px;
	}

	.segments-4SEHW {
		padding: 0 20px;
	}

	.buttons-3emQf {
		flex-wrap: wrap;
		justify-content: center;
	}

	.content-1hDrd {
		padding: 0;
	}

	.topInfoMessage--TT3p {
		width: 87vw;
	}
}

.total_time-3eK6T {
	margin-top: 10px;
	padding: 12px 20px;

	border: 1px solid #d9d9d9;
	border-radius: 4px;

	font-weight: 500;
	font-size: 14px;
	line-height: 1.3;

	background-color: var(--warning20);
}

.content_footer-1-FUM {
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: 12px;
}

button.scroll_up-1VVcB {
	background-color: var(--results-faregroup-button-economy);
}

button.scroll_up-1VVcB:hover {
	background-color: var(--results-faregroup-button-economy-hover);
}

.flight__selected-1YR9L {
	flex: 1;
}

.segments__flight-otV99:not(:first-child) {
	margin-top: 10px;
}

div.aircraft__paper-6i_Lr {
	border-radius: 20px;

	background: transparent;
}

/* stylelint-disable */
.slider_allOnce-2lgzc {
}
