.option-2J6GK {
	padding: 6px 16px;

	cursor: pointer;

	transition: background-color 0.2s;
}

.option-2J6GK:hover {
	background-color: #f6f6f6;
}

.aside-2AV-7 {
	display: flex;
}

.info-1Zmar {
	display: flex;
	justify-content: space-between;
	align-items: center;

	line-height: 20px;
}

.label-3WvSI {
	color: var(--autocomplete-option-label);
	font-weight: 500;
	font-size: 16px;

	text-transform: capitalize;
	text-align: left;
}

.code-11eDc {
	width: 40px;

	color: var(--autocomplete-option-code);
	font-weight: var(--autocomplete-option-code-weight);
	font-size: 12px;

	white-space: nowrap;
	text-align: right;
	text-transform: uppercase;
	text-overflow: ellipsis;
	overflow: hidden;
}

.stops-4kDfe {

	display: none;
	width: auto;

	color: var(--common-blue);

	color: var(--autocomplete-option-stops-color, var(--common-blue));
	text-transform: uppercase;
}

@media screen and (max-width: 600px) {
	.stops-4kDfe {
		display: block;
	}
}

.meta-23FiK {
	margin-top: 4px;
	display: flex;
	justify-content: space-between;
	align-items: center;

	font-size: 12px;
	line-height: 16px;
	white-space: nowrap;
}

.meta_gray-1lYkV .meta__label-3aatP {
	color: var(--dark-gray);
}

.meta_gray-1lYkV .meta__value-1XCBT {
	color: var(--light-gray);
}

.meta_probable-257zJ {
	color: var(--common-blue);
}

@media screen and (max-width: 600px) {
	.option-2J6GK {
		padding: 6px 20px;
	}
}
