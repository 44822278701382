

label.label-1jkU0 {
	display: flex;
	margin-right: 0;
	margin-left: 0;
}

span.labelContent-lyk4F {
	display: flex;
	align-items: center;
	width: 100%;

	font-weight: inherit;
	font-size: inherit;
	line-height: inherit;
}

.input-3yN9N {
	display: none;
}

.icon-35Db2 {
	display: flex;
	align-items: center;
	margin-right: 10px;
	width: 20px;
	height: 20px;
}

.addIcon-Z98zZ {
	color: var(--base);
}

.link-5qXpr {
	text-decoration: none;
}

.addedIcon-1xnJP {
	border-radius: 50%;

	color: #ffffff;

	background: var(--success);
}

.addedIcon-1xnJP > svg {
	transform: scale(0.7) translateY(-1px);
}

.removeIcon-95BpL {
	margin-left: auto;
	display: flex;

	color: #dadada;
}
