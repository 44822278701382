.ticketExample-3z9p7 {
	display: grid;
	grid-template-columns: 1fr 1fr;

	-webkit-user-select: none;

	    -ms-user-select: none;

	        user-select: none;
}

.ticketExample__leftSide-12Qdf {
	display: flex;
	flex-direction: column;
	box-sizing: border-box;
}

.ticketExample__rightSide-125Jw {
	display: flex;
	flex-direction: column;
}

.ticketExample__header-ikpy6 {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0.4rem 0.6rem;
	height: 36px;

	font-size: 0.8rem;
	line-height: 1;

	color: var(--white);

	background-color: var(--brand-1-0);
}

.allocated-QtPUt {
	position: relative;

	background-color: var(--error-stroke--hover);
}

.ticketExample__headerAgent-3nTIE {
	font-size: 0.4rem;
	font-weight: 400;
	line-height: 1.2;
}

body[data-demo-wx='true'] .ticketExample__headerAgent-3nTIE {
	display: none;
}

.ticketExample__headerAgent-3nTIE span {
	display: block;
	margin-top: 0.1rem;

	font-size: 0.5rem;
}

.ticketExample__headerContacts-1FOK7 {
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	grid-gap: 0.1rem;
	gap: 0.1rem;
}

.ticketExample__headerContacts-1FOK7 span {
	font-size: 0.4rem;
}

.ticketExample__leftSide-12Qdf .ticketExample__header-ikpy6 {
	display: flex;
	justify-content: flex-end;

	border-right: 1px dashed var(--gray20);

	font-weight: 700;

	text-transform: uppercase;
}

.ticketExample__rightSide-125Jw .ticketExample__header-ikpy6 {
	display: flex;
	justify-content: space-between;
}

body[data-demo-wx='true'] .ticketExample__rightSide-125Jw .ticketExample__header-ikpy6 {
	justify-content: flex-end;
}

.ticketExample__flightInfo-2tnbN {
	display: flex;
	flex-direction: column;
}

.ticketExample__flightInfo-2tnbN:after {
	content: '';

	height: 3px;
	margin: 0 0.5rem;
	box-sizing: content-box;

	background-color: var(--gray20);
}

.ticketExample__content-2fu-N {
	display: flex;
	justify-content: space-between;
	padding: 0.2rem 0.4rem;
	box-sizing: content-box;
	height: 26px;
}

@media screen and (max-width: 600px) {
	.ticketExample__content-2fu-N {
		flex-direction: column;
		height: auto;
	}
}

.ticketExample__leftSide-12Qdf .ticketExample__content-2fu-N {
	border-right: 1px dashed var(--gray20);
}

.ticketExample__passengerName-Qidm3 {
	display: flex;
	flex-direction: column;
	width: max-content;
	padding: 0.2rem;

	font-size: 0.5rem;
	font-weight: 700;
	line-height: 0.5;
	text-transform: uppercase;

	color: var(--text);
}

@media screen and (max-width: 600px) {
	.ticketExample__passengerName-Qidm3 {
		flex-direction: row;
	}
}

.ticketExample__passengerName-Qidm3 div {
	padding: 0.2rem 0.3rem;
}

@media screen and (max-width: 600px) {
	.ticketExample__passengerName-Qidm3 div:first-child {
		padding-left: 0;
	}
}

.ticketExample__contentGroupItems-2y1kK {
	display: flex;
	grid-gap: 0.2rem;
	gap: 0.2rem;
}

@media screen and (max-width: 600px) {
	.ticketExample__contentGroupItems-2y1kK {
		flex-direction: column;
	}
}

.ticketExample__contentItem-1BbVG {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	padding: 0.2rem;
	grid-gap: 0.2rem;
	gap: 0.2rem;
	width: max-content;
}

.ticketExample__contentItemLabel-QQarQ {
	font-size: 0.45rem;
	line-height: 1;

	color: var(--gray50);
}

.ticketExample__contentItemValue-S4Dec {
	font-size: 0.5rem;
	font-weight: 700;
	line-height: 1;
	text-transform: uppercase;

	color: var(--text);
}

.ticketExample__rightSide-125Jw .ticketExample__contentItemValue-S4Dec {
	font-size: 0.4rem;
}

.ticketExample__paymentInfo-1IGEW {
	display: flex;
	justify-content: space-between;
	padding: 0.3rem 0.5rem;
	grid-gap: 1rem;
	gap: 1rem;

	font-size: 0.4rem;

	opacity: 0.15;
}

.icon-349P6 {
	position: absolute;
	left: 50%;
	bottom: -30px;

	transform: translate(-50%, 0);
}

@media screen and (max-width: 600px) {
	.icon-349P6 {
		left: 100%;
		bottom: -9px;

		transform: translate(0, 0);
	}
}

.icon-349P6 svg {
	width: 18px;

	color: var(--error);

	transform: rotate(90deg);
}

@media screen and (max-width: 600px) {
	.icon-349P6 svg {
		transform: rotate(0);
	}
}
