

.status-1aR2K {
	margin-left: 4px;

	text-transform: lowercase;
}

.status__solved-3LgkD {
	color: var(--text-light);
}

.status__new-3iRfb {
	color: var(--brand-1-0);
}

.status__progress-3cv48 {
	color: var(--seat-choosed-hover);
}

.status__declined-z3xLg {
	color: var(--text-base);
}

.status__awaiting-1y8v8 {
	color: var(--brand-3-0);
}
