.account__form-2VF5h {
	display: flex;
	flex-direction: column;
}

.input-Pi0Bf {
	display: flex;
}

.input-Pi0Bf div:after {
	border-bottom-color: var(--cabinet-action);
}

.input-Pi0Bf .react-phone-number-input {
	flex-grow: 1;
}

div.inputBase-2r9n0 {
	font-family: inherit;
}

.confirm__input__wrapper-3hpWl {
	position: relative;

	margin-top: 24px;
	display: flex;
	flex-direction: column;
}

.confirm__input__wrapper-3hpWl > p {
	position: absolute;
	bottom: -20px;

	margin: 0;

	font-size: 0.75rem;
	color: var(--common-gray);
}

.field__user-4F1Dt span {
	color: var(--text-light);
}

.confirm__input__wrapper-3hpWl > p > span {
	color: var(--cabinet-action);

	cursor: pointer;
}

.confirm__input-3MMVR div:after {
	border-bottom-color: var(--cabinet-action);
}

.confirm__input-3MMVR > p {
	margin-left: 0;

	font-size: 13px;
}

button.input__button-1L5NG {
	margin-left: 10px;
}

.indicator-WgN6Z {
	height: 20px;

	display: flex;
	justify-content: center;
	align-items: center;
}

.loader-14G7z {
	width: 22px;

	background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAJcSURBVHgBrVRdq2FhFF627RyzxcShOeUjQxlx4STJpXLnZ/glfoLiQrhyJ7kWpdy50wxmGDTKoZAZoTG+m7XeOJ05n47mqdfeve9ej7We91lLBE8gGAzyMplMv1qtrpbLpWK32wnb7RZwTTebzR+RSDQIh8O9p2JFDzdCodAHDLxZr9cSJAIkhftPIqbngfh7IpHoPUsYiUTsGPCRAtRqNRgMBjCZTCCXy9n5cDiEfr8PhUIBBoMBI0Y0k8lk8xFhLBaz4AcWenc4HGC1WuElFItFyGazsFgsAKv5kU6nv9G+mH7i8bgOH/aLiwvw+Xyg1+vhNRiNRrDZbFAqlah8JSYxrdVqvzk6lEgkn3ieB7vdDkqlEk6FVqsFv9/PSsdMb7xeLy/OZDK6/X6vFwQBXC4XvBVmsxmq1SqMRiOxVCpdcVj/NWX3mmYvwel0sttHi11xqJtA2ikUCjgXbrebLoZI3/OoH2M6WuMckMUoQ8Q7XiwWw//AwZPAoX4L0pBSPhetVuvYSVPScEYazmYzOBedTueuHTms/RdlSI4/F9gYjBDXgEMj36KOG0p5Pp/DW5FKpVh/o5cXlUqlx6GZN5jhF8pyMpnciXsKGo0GRKNRpj8uNiBY66GPBkjUoffxeHxS+ThhIBAIsCQQzXa7zcbYP+OrXq/TtLGQlS4vL4HakXC0FpWWz+chl8tBuVyGw3edbrf79cjxaMCiBXQ4OC1oeIECSApygUqlYn9Ce7TwnKT6jD08vB8veq6kXq+n4zjuGl8F6iaNRkPkC7IZXsBPj8dzizNx+zDuL+21NUIz0btFAAAAAElFTkSuQmCC) center no-repeat;

	animation: rotation-3aDwI 0.9s infinite linear;

	pointer-events: none;
}

.input_tooltip__wrapper-1GVNm {
	position: relative;

	width: 100%;
}

div.tooltip-25jIy {
	position: relative;

	padding: 12px 0 !important;

	color: var(--text-base);
	font-family: inherit;
}

.tooltip-25jIy h1 {
	margin: 0;

	font-size: 13px;
	line-height: 150%;
	color: var(--dark-gray);
}

.tooltip-25jIy p {
	margin: 0;

	font-size: 13px;
	font-weight: 400;
	line-height: 150%;
	color: var(--common-gray);
}

.field__name-2Nvx8 svg {
	position: absolute;

	width: 16px;
	height: 16px;
	margin-left: 8px;

	color: var(--success-icon);
}

.resendTimer-sImD5 > div > div > svg {
	height: 16px;
	width: 16px;
	margin-right: 4px;
}

.resendTimer-sImD5 > div > div > span > svg {
	height: 16px;
	width: 16px;
	margin-right: 4px;
}

.field-Vvyh_ {
	display: flex;
	align-items: center;
	justify-content: space-between;
	grid-gap: 8px;
	gap: 8px;
}

.field-Vvyh_:not(:last-of-type) {
	margin-bottom: 25px;
}

.field__icon-2cmOr {
	margin-right: 16px;
}

.field__name-2Nvx8 {
	position: relative;

	color: var(--text-base);
	font-weight: 700;
}

.field__info-1mHqj {
	display: flex;
	align-items: center;
}

.field__user-4F1Dt {
	display: flex;
	grid-gap: 16px;
	gap: 16px;
	margin-top: 4px;

	color: var(--text-mid);
}

.field__buttons-1wlzu {
	display: flex;
	align-items: center;
	grid-gap: 8px;
	gap: 8px;
}

.field__delete-1djqx {
	display: flex;
	flex-shrink: 0;
	justify-content: center;
	align-items: center;
	width: 18px;
	height: 18px;
	margin-left: auto;

	color: var(--text-pale);

	cursor: pointer;
}

/* stylelint-disable */
.field__button-2AUp2 {
}

.field__change-1wSRZ {
}

.field__connect-2423y {
}

/* stylelint-enable */
@keyframes rotation-3aDwI {
	from {
		transform: rotate(0deg);
	}

	to {
		transform: rotate(359deg);
	}
}

@media (max-width: 1024px) {
	.field-Vvyh_:not(:last-of-type) {
		margin-bottom: 0;
	}
}

@media screen and (max-width: 600px) {
	.account__form-2VF5h {
		width: 100%;
		flex-direction: column;
	}

	.account__form-2VF5h .input-Pi0Bf {
		margin-right: 0;
		width: 100%;
	}

	.account__form-2VF5h .confirm__input-3MMVR {
		width: 100%;
		margin-top: 10px;
		margin-bottom: 10px;
	}

	.field__buttons-1wlzu {
		grid-gap: 16px;
		gap: 16px;
	}

	button.field__change-1wSRZ {
		padding: 0;
	}

	.field-Vvyh_ {
		align-items: flex-end;
	}

	.field__user-4F1Dt {
		margin-top: 12px;
	}

	.field__value-3nwOS,
	.field__status-1sXqc {
		font-size: 15px;
	}

	.field__value-3nwOS {
		max-width: 210px;

		text-overflow: ellipsis;
		overflow: hidden;
	}

	.field__value-3nwOS + .field__status-1sXqc {
		display: none;
	}
}

@media screen and (max-width: 380px) {
	.field__value-3nwOS {
		max-width: 175px;
	}
}
