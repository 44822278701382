.wrapper-vLKIb {
	display: flex;
	align-items: center;
	padding-left: 20px;

	background-color: var(--white);
}

.info-3Tw1X {
	margin-right: 16px;
}

.info-3Tw1X,
.airport-201hR {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding: 40px 0;
}

.time-9ztvA,
.city-6i1B8 {
	margin-bottom: 4px;

	color: var(--gray90);
	font-weight: 700;
	font-size: 22px;
	line-height: 1.3;
}

.time_iata-16MRP {
	display: none;
}

.date-9k599 {
	color: var(--gray50);
	font-size: 14px;
	line-height: 1.3;
	white-space: nowrap;
}

.date_icon-1CUoD {
	display: none;
}

.airport-201hR {
	flex: 1;

	color: var(--base);
}

.terminal-GG52M {
	line-height: 1.3;
	word-break: break-word;
}

.terminal_city-1dZkr {
	display: none;
}

.terminal_city-1dZkr:not(:last-child):after {
	content: ', ';
}

.terminal_iata-3aaUJ {
	margin-right: 4px;

	text-transform: uppercase;
}

.terminal_icon-sU_Ho {
	display: flex;
	align-items: center;
	margin-left: 4px;

	color: #b3b3b3;
}

.terminal_airport-3MxvX.terminal_airportTerminal-2F8Hy:after {
	content: ', ';
}

.terminal_number-1eyX8 {
	white-space: nowrap;
}

.weather-2klND {
	position: relative;

	flex: 1;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	max-width: 40%;
	height: 100%;

	background-position: center;
	background-size: cover;
}

.weather-2klND.weather_gradient-faD6N:before {
	content: '';

	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;

	background: linear-gradient(0deg, rgba(0, 0, 0, 0.62) 0%, rgba(0, 0, 0, 0) 75%);
	opacity: 0.8;
}

.weather_wrapper-3dj_r {
	position: relative;

	display: flex;
	justify-content: flex-end;
	align-items: center;
	padding: 10px;

	color: var(--white);
}

.weather_wrapper-3dj_r > svg {
	width: 44px;
	height: 44px;
	margin-right: 6px;
}

.weather_temp-1ZhFk {
	font-size: 31px;
	font-weight: 400;
	line-height: 1.3;
}

.wrapper-vLKIb.isMobile-DApL8 {
	flex-direction: column;
	align-items: flex-start;
	padding-left: 0;
}

.isMobile-DApL8 .date-9k599 {
	display: flex;
	align-items: center;
	margin-bottom: 14px;
}

.isMobile-DApL8 .date_icon-1CUoD {
	display: flex;
	margin-right: 10px;
}

.isMobile-DApL8 .time-9ztvA {
	order: 1;
	margin-bottom: 0;
}

.isMobile-DApL8 .time_iata-16MRP {
	display: inline-block;
	margin-left: 8px;

	color: var(--gray50);
	font-weight: 400;
}

.isMobile-DApL8 .info-3Tw1X {
	padding: 20px;
}

.isMobile-DApL8 .airport-201hR {
	padding: 0 20px 20px;
}

.isMobile-DApL8 .info-3Tw1X,
.isMobile-DApL8 .airport-201hR {
	margin: 0;
}

.isMobile-DApL8 .city-6i1B8 {
	display: none;
}

.isMobile-DApL8 .terminal_city-1dZkr {
	display: inline;
}

.isMobile-DApL8 .weather-2klND {
	width: 100%;
	max-width: 100%;
	height: 170px;
	min-height: 170px;
	margin-top: auto;
	margin-left: 0;
}

@media (max-width: 768px) {
	.isMobile-DApL8 .weather-2klND {
		width: 100%;
		max-width: 100%;
		height: 150px;
		min-height: 150px;
	}
}
