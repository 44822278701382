

.fareType__container-2OssT {
	display: flex;
	margin-bottom: 10px;
}

.fareType__container_hidden-UpjVB {
	display: none;
}

.fareType-3NnlG {
	position: relative;

	display: flex;
	flex-basis: 100%;
	flex-direction: column;
	justify-content: space-between;
	padding: 4px 12px 7px;

	border-radius: 2px;

	font-size: 13px;

	cursor: pointer;
	background: #ededed;
}

.fareType_economy-1AToa {
	color: inherit;
}

.fareType_business-3gI6v {
	color: inherit;
}

.fareType_disabled-UNBZA {
	display: none;

	opacity: 0.4;
	cursor: not-allowed;

	-webkit-user-select: none;

	    -ms-user-select: none;

	        user-select: none;
}

.fareType_active-QGQ-e {
	color: #ffffff;

	background: #2290f5;
}

.fareType_active-QGQ-e:after {
	content: '';

	position: absolute;
	bottom: -5px;

	border: 8px solid #2290f5;

	transform: rotate(45deg);
}

.fareType-3NnlG:not(:last-child) {
	margin-right: 7px;
}

.fareType__header-21Mbm {
	line-height: 24px;
	font-size: 13px;
	font-weight: 600;
	text-transform: capitalize;
}

.fareType_active-QGQ-e + .fareType_active-QGQ-e {
	margin-left: 7px;
}

.fareType_active-QGQ-e .fareType__header-21Mbm {
	color: #ffffff;
}

.fareType__bottom-1O1Ja {
	line-height: 24px;
}

.fareType__bottom_disabled-Xigcx {
	font-size: 16px;
	font-weight: 600;
	text-transform: uppercase;
}

.fareType__price-2CcpX {
	font-size: 20px;
	font-weight: 600;
}

.fareType__fromPrice-2Rqkv {
	opacity: 0.6;
}

.fares-3uvf1 {
	display: block;
	margin: 0 -20px -20px -20px;
}

.fares-3uvf1 .slick-track {
	display: flex;
}

.fares-3uvf1 .slick-track .slick-slide {
	height: auto;
	padding-left: 20px;
}

.fares-3uvf1 .slick-track .slick-slide > div {
	display: flex;
	height: 100%;
}

.fares__dots-3mxhO {
	padding-left: 0;

	text-align: center;
}

.fares__dots-3mxhO li {
	display: inline-block;
}

.fares__dots-3mxhO li:not(:last-of-type) {
	margin-right: 8px;
}

.fares__dots-3mxhO li button {
	width: 8px;
	height: 8px;
	padding: 0;

	border: none;
	border-radius: 50%;

	color: transparent;

	opacity: 0.4;

	background-color: var(--common-gray);

	transition: opacity 0.15s;

	appearance: none;
	-webkit-user-select: none;
	    -ms-user-select: none;
	        user-select: none;
}

.fares__dots-3mxhO li.slick-active button {
	opacity: 1;
}
