.container-3nzph {
	display: flex;
	flex-direction: column;

	padding-bottom: 100px;
}

.header-HI8_9 {
	z-index: 1;

	display: flex;
	align-items: center;

	min-height: 72px;

	box-shadow: 0 7px 28px 0 rgba(0, 0, 0, 0.05);
	background: var(--bg-white);
}

.header__wrapper-yrJxv {
	display: flex;
	align-items: center;
	width: 100%;
	grid-gap: 20px;
	gap: 20px;

	max-width: 1100px;
	margin: 0 auto;
	padding: 0 40px;
}

.title-1gNuo {
	color: var(--text-base);
	font-size: 24px;
	font-weight: 700;
	line-height: 1.3;
}

.footer-1ojNW {
	max-width: 1018px;
}

@media screen and (max-width: 600px) {
	.container-3nzph {
		padding-bottom: 80px;
	}

	.title-1gNuo {
		font-size: 20px;
	}

	.header__wrapper-yrJxv,
	.footer-1ojNW {
		padding: 0 12px;
	}
}
