.modal-3bxHY {
	min-width: 620px;
}

.container-Q2ST- {
	padding: 28px 32px;
}

.header-GtUDP {
	margin-bottom: 20px;

	font-size: 22px;
	font-weight: 700;
	color: var(--text-base);
}

.table-3C87g {
	margin: 0;
	padding: 0;
	display: flex;
	flex-direction: column;

	font-size: 16px;
	color: var(--text-base);
}

.tableHeader-3ZqhG {
	font-weight: 500;

	background: var(--brand-1-5);
}

.tableHeader-3ZqhG,
.tableRow-1NKXW {
	display: grid;

	grid-template-columns: 36% 22% 20% 20%;
	grid-gap: 4px;
	gap: 4px;
	align-items: center;
	padding: 16px 12px;

	border-bottom: dashed 1px var(--gray-line);

	list-style: none;
}

.tableHeader-3ZqhG > span {
	display: flex;
}

.tableRow-1NKXW.error-1KiGr {
	color: var(--error-icon);

	background: var(--errorBG);
}

.promoCodeIcon-1kmJu {
	display: flex;
	align-items: center;

	color: var(--text-pale);
}

.promoCodeIcon-1kmJu:hover {
	cursor: pointer;
}

.passengerColumnName-1eq3D {
	justify-self: start;
}

.promoCodeColumnName-pWJZS {
	display: flex;
	align-items: center;
	justify-self: start;

	line-height: 1;
}

.priceColumnName-3GVi3 {
	justify-self: flex-end;
}

.passengerColumn-35ZHE,
.promoCodeColumn-3aJhl {
	display: flex;
	justify-self: start;

	flex-wrap: wrap;
}

.priceColumn-gKoxk {
	justify-self: flex-end;
}

.errorText-2Kub1 {
	margin: 0 0.3em;
}

.total-1BQ_L {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-top: 28px;
}

.totalPriceContainer-2VVfs {
	display: grid;
	grid-template-columns: repeat(3, auto);
	grid-template-rows: repeat(3, auto);
}

.totalPrice-2jRUq {
	grid-area: 1 / 1 / 2 / 2;
	padding-right: 12px;

	border-right: 1px solid var(--gray-line);

	color: var(--error-icon);
	font-weight: 700;
	font-size: 21px;
	text-decoration: line-through;
}

.totalPriceWithDiscountLabel-5L4Z4 {
	grid-area: 1 / 2 / 2 / 3;
	margin-left: 12px;

	color: var(--text-base);
	font-weight: 700;
	font-size: 22px;
}

.totalPriceWithDiscount-1VlEH {
	grid-area: 1 / 3 / 2 / 4;

	color: var(--text-base);
	font-weight: 700;
	font-size: 22px;
}

.flightPriceLabel-3JGSS {
	grid-area: 2 / 2 / 3 / 3;
	margin-left: 12px;

	color: var(--text-pale);
	font-weight: 500;
	font-size: 17px;
	text-transform: lowercase;
	justify-self: start;
}

.flightPrice-2559P {
	grid-area: 2 / 3 / 3 / 4;

	color: var(--text-pale);
	font-weight: 500;
	font-size: 17px;
	justify-self: flex-end;
}

.addServicesPriceLabel-1UhED {
	grid-area: 3 / 2 / 4 / 3;
	margin-left: 12px;

	color: var(--text-pale);
	font-weight: 500;
	font-size: 17px;
	text-transform: lowercase;
	justify-self: start;
}

.addServicesPrice-3RKTj {
	grid-area: 3 / 3 / 4 / 4;

	color: var(--text-pale);
	font-weight: 500;
	font-size: 17px;
	justify-self: flex-end;
}

.container-Q2ST- div.notification-3KN1g {
	margin: 12px 0;

	border: 1px solid var(--error-stroke-hover);
	border-radius: var(--warning-border-radius);

	background: var(--error-bg);
}

div.notification-3KN1g .notificationTitle-SiwLL {
	font-size: 16px;
	font-weight: 700;

	color: var(--error-text);
}

.buttons-3Yxsz {
	justify-self: right;

	align-self: flex-end;
	margin-left: auto;
	display: flex;
	grid-gap: 12px;
	gap: 12px;
	flex-wrap: wrap;
}

.buttons-3Yxsz button {
	padding: 10px 24px;
}

.buttons-3Yxsz button.submit-1Rii4 {
	justify-self: right;

	margin-left: auto;
}

.buttons-3Yxsz button.cancel-1K42Z {
	color: var(--text-light);

	background: var(--bg-fill);
}

.buttons-3Yxsz button.cancel-1K42Z:hover,
.buttons-3Yxsz button.cancel-1K42Z:focus {
	color: var(--text-light);

	background: var(--bg-elements);
}

@media screen and (max-width: 600px) {
	.modal-3bxHY {
		min-width: auto;
		width: 100%;
	}

	.modal-3bxHY .close-WYQAG {
		top: 12px;
		right: 12px;

		display: flex;
		justify-content: center;
		align-items: center;
	}

	.promoCodeIcon-1kmJu svg {
		/* pointer-events: none; */
	}

	.modal-3bxHY .close-WYQAG svg {
		height: 20px;
		width: 20px;
	}

	.container-Q2ST- {
		padding: 0;
	}

	div.header-GtUDP {
		margin: 0;
		padding: 12px;

		font-size: 18px;
	}

	.table-3C87g {
		margin: 0 20px;
	}

	.tableRow-1NKXW {
		display: flex;
		flex-direction: column;
		grid-gap: 8px;
		gap: 8px;
		align-items: stretch;
		padding: 16px 0;

		line-height: 1.3;

		background: unset;
	}

	.tableRow-1NKXW.error-1KiGr {
		background: unset;
	}

	.passengerColumn-35ZHE {
		font-size: 16px;
		font-weight: 700;
	}

	.promoCodeColumn-3aJhl,
	.errorText-2Kub1,
	.priceColumn-gKoxk {
		display: flex;

		font-size: 16px;
	}

	.errorText-2Kub1,
	.priceColumn-gKoxk {
		justify-content: space-between;
	}

	.promoCodeColumn-3aJhl {
		justify-content: start;
	}

	.promoCodeValue-locdm {
		margin-left: auto;
	}

	.total-1BQ_L {
		display: flex;
		flex-direction: column;
		align-items: flex-end;
		justify-content: stretch;
		margin: 16px 20px;
		grid-gap: 8px;
		gap: 8px;
	}

	.totalPrice-2jRUq {
		grid-area: unset;

		padding-right: 0;

		border-right: unset;

		font-size: 20px;
	}

	.totalPriceWithDiscountWrapper-3lUOm {
		display: flex;
		justify-content: flex-end;
		align-self: stretch;
		padding-bottom: 16px;

		border-bottom: 1px dashed var(--gray-line);

		color: var(--text-base);
		font-weight: 700;
		font-size: 22px;
	}

	.flightPriceWrapper-2Z2TL,
	.addServicesPriceWrapper-20mq8 {
		display: flex;
	}

	.flightPrice-2559P,
	.flightPriceLabel-3JGSS,
	.addServicesPrice-3RKTj,
	.addServicesPriceLabel-1UhED {
		font-size: 18px;
		font-weight: 400;
		color: var(--text-light);
	}

	.toolbar-3pmfo {
		padding: 12px;

		background: var(--white);
	}

	button.toolbarContinueButton-X5ZRy {
		padding: 10px 24px;

		font-weight: 700;
		font-size: 14px;
	}
}
