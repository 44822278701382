div.paperRoot-3ghuv {
	overflow: hidden !important;

	box-shadow: none;
}

.loaderWrp-Z_IGR {
	height: 80vh;
	display: flex;
	align-items: center;
	justify-content: center;
}

div.loader-1hcr9 {
	color: var(--text-pale);
}

.header-fdS5l {
	padding: 0 0 25px !important;
}

.heading-3MO7i {
	padding: 40px 40px 0;
	margin: 0 0 25px 0;

	font-size: 36px !important;
	line-height: 42px !important;
	font-weight: 300 !important;
	color: var(--text-light) !important;
}

.tabsWrp-lQ16n {
	display: flex;
	justify-content: space-between;
	padding: 0 40px;
}

.tabsWrp_error-vCGQU {
	margin-left: 0;
}

button.tab-fhn6s {
	min-width: auto;
	max-width: 100%;
	padding: 6px 0;

	text-transform: none;
	color: #9a9a9a;
	font-weight: 500;
	font-size: 13px;
	font-family: inherit;
}

span.tabIndicator-e9Uxq {
	height: 3px;

	background-color: var(--brand-1-0);
}

.tab-fhn6s:not(:last-of-type) {
	margin-right: 20px;
}

button.tab_active-I1GD0 {
	color: var(--brand-1-0);
}

.fareConditionsContent-2P_Nz {
	padding: 0 40px;
	margin-bottom: 10px;
}

.fareConditionsContent-2P_Nz pre {
	white-space: pre-line;
	font-family: inherit;
	font-size: 14px;
	color: var(--text-base);
}

.fareConditionsSegmentHeading-SnrlR {
	padding: 0 40px;
	margin: 25px 0;

	color: var(--brand-1-0);
	font-weight: 300;
	font-size: 31px;
}

.fareConditionsSegmentHeading__inner-2vd-U {
	display: flex;
	align-items: center;
}

.fareConditionsSegmentFareCode-vTAtJ {
	padding: 0 40px;
}

.segmentTabs-2vwwe {
	flex: 70%;
	align-items: start;
}

.translateTabs-1GgvF {
	margin-left: auto;
	flex: 30%;
}

.translateTabsContainer-4Idms {
	justify-content: flex-end;
}

.errorWrp-2qbNG {
	padding: 40px;
}

.errorTitle-1ApJ7 {
	margin: 0;

	color: var(--text-base);
	font-weight: 500;
	font-size: 22px;
	line-height: 26px;
}

.errorText-2QE5i {
	line-height: 22px;
	color: var(--text-light);
}

.select-xQlUM {
	position: relative;
	top: -3px;

	margin-left: 10px;
}

.select-xQlUM > div {
	font-family: inherit;
}

.fareConditionsContent-2P_Nz > div {
	max-height: calc(100vh - 28px);
	overflow-y: scroll;

	margin-top: 28px;
}

.fareConditionsContent-2P_Nz > div::-webkit-scrollbar {
	width: 8px;
}

.fareConditionsContent-2P_Nz > div::-webkit-scrollbar-thumb {
	display: block;

	border-radius: 20px;

	background-color: var(--bg-elements);
}

div.select__root-1MXP7 {
	min-width: 250px;
	padding: 5px 32px 5px 12px;

	border: 1px solid var(--line-separator);
	border-radius: 4px;

	font-size: 14px;
	line-height: 21px;

	background: #f0f0f0;
}

div.select__root-1MXP7:focus {
	border-radius: 4px;
}

input.select__input-1tfDu:focus {
	border-radius: 4px;
}

li.select__item--hMos {
	box-sizing: border-box;

	font-family: inherit;
	font-size: 14px;
}

li.select__item--hMos.select__item_selected-XwpXZ,
li.select__item--hMos.select__item_selected-XwpXZ:hover {
	background: var(--bg-elements);
}

div.select__list-2kEW8 {
	z-index: 1501 !important;
}

div.segmentTabsWrapper-1P2aE {
	display: block;
	padding-left: 40px;
	padding-right: 55px;
}

div.segmentTabsInner-30CE5 {
	justify-content: flex-start;
	padding: 0;
	flex-direction: column;
	align-items: flex-start;
	height: auto;
}

.segmentTabsSegments-26IIj {
	flex-wrap: wrap;
}

div.segmentTabsTitle-1VlXb {
	position: static;

	height: 50px;
	display: flex;
	align-items: flex-end;
	margin: 8px 0 32px;

	font-size: 20px;
	font-weight: 700;
}

button.segmentTabsTab-13RsU {
	padding: 0 !important;

	border: 0;
	border-bottom: 3px solid transparent;

	color: var(--text-light) !important;
	font-size: 14px !important;

	background-color: var(--bg-white) !important;

	transition: none !important;
}

div.segmentTabsWrapper-1P2aE button.segmentTabsTab-13RsU span {
	color: inherit;
}

button.segmentTabsTab-13RsU.segmentTabsTab__active-3W_ST {
	border: none;
	border-bottom: 3px solid var(--brand-1-0);
	border-radius: 0;

	color: var(--brand-1-0) !important;
	font-size: 14px;

	background-color: var(--bg-white) !important;

	transition: none;
}

span.modal__heading-1NmSt {
	color: var(--common-gray);
}

@media screen and (max-width: 600px) {
	.header-fdS5l {
		position: relative !important;

		padding: 12px 40px 12px 24px !important;

		border-bottom: 0;

		background: var(--bg-elements);
	}

	div.segmentTabsTitle-1VlXb {
		display: none;
	}

	.loaderWrp-Z_IGR {
		height: 100vh;
	}

	.heading-3MO7i {
		padding: 0;

		color: var(--text-light) !important;
		font-weight: 500 !important;
		font-size: 20px !important;
		line-height: 23px !important;
	}

	.translateTabIndicator-3oZ7A {
		display: none;
	}

	.tabsWrp-lQ16n {
		margin: 0;
		padding: 0;
		flex-direction: column;
	}

	.translateTabs-1GgvF {
		order: 1;
		margin-left: 0;
	}

	.translateTab-3XADm {
		flex: 1;

		color: var(--text-light);
		font-size: 14px;
		line-height: 16px;
		font-weight: 500;

		background: var(--bg-elements) !important;
		opacity: 1 !important;
	}

	.translateTab_selected-1pLQU {
		background: var(--white) !important;
	}

	.translateTab-3XADm:not(:last-of-type) {
		margin-right: 0;
		padding-right: 20px;
	}

	.segmentTabs-2vwwe {
		order: 2;
		padding: 0 20px;
	}

	.fareConditionsContent-2P_Nz {
		padding: 0 20px;
		max-height: calc(100vh - 136px);
	}

	.fareConditionsSegmentHeading-SnrlR {
		padding: 0 20px;
	}

	.fareConditionsSegmentFareCode-vTAtJ {
		padding: 0 20px;
	}

	.segmentTabsInner-30CE5 {
		flex-direction: column;
		align-items: flex-start;
	}

	div.segmentTabsWrapper-1P2aE {
		padding: 0 20px;
	}

	.segmentTabsSegments-26IIj {
		margin-left: 0;
		margin-top: 15px;
		width: calc(100vw - 25px);
		overflow: scroll;
		scrollbar-width: none;
		flex-wrap: nowrap;
	}

	::-webkit-scrollbar {
		width: 0;

		background: transparent;
	}

	.segmentTabsTitle-1VlXb {
		display: none;
	}

	div.segmentsTabs-5uwJN {
		justify-content: flex-start;
	}
}
