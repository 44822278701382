.helpfulInformation-3_KuT {
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	grid-gap: 20px;
	gap: 20px;
}

@media screen and (max-width: 600px) {
	.helpfulInformation-3_KuT {
		flex-direction: column;
	}
}

.icon-2GnDh {
	width: 20px;
	height: 20px;

	color: var(--text-light);
}

.helpfulInformation__content-3jjAd {
	display: flex;
	flex-direction: column;
	grid-gap: 4px;
	gap: 4px;
}

.helpfulInformation__contentParagraph-1KDkn {
	margin: 0;

	font-size: 14px;
	font-weight: 400;
	line-height: 18px;

	color: var(--text-light);
}

.helpfulInformation__contentParagraph_bold-3JBrr {
	font-weight: 700;
}

.helpfulInformation__contentLink-3kBHP {
	font-size: 14px;
	font-weight: 400;
	line-height: 18px;

	color: var(--text-light);
}
