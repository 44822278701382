.container-39nKD {
	margin-top: 28px;
	display: flex;
	align-items: center;
	justify-content: space-between;

	font-size: 15px;
	color: var(--gray50);
}

button.refuse-3W62y,
button.refuse-3W62y:hover {
	border-color: #ffffff;

	color: var(--error);

	background: #ffffff;
}

button.button-3b-Gq,
button.button-3b-Gq:hover {
	border-color: var(--active);

	color: #ffffff;

	background: var(--active);
}

.container-39nKD button:not(:last-of-type) {
	margin-left: auto;
	margin-right: 15px;
}

div.baggage-23kg0 {
	background-color: red;
}

.dialog__deleteIcon-PCKPk {
	position: relative;
}

@media screen and (max-width: 600px) {
	.container-39nKD {
		flex-direction: column;
	}

	.text-A6yFy {
		margin-bottom: 20px;
	}

	button.button-3b-Gq,
	button.refuse-3W62y {
		width: 100%;
	}
}
