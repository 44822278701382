

.container-1yX7- {
	max-width: 1100px;
	margin: 0 auto;
}

div.container-1yX7-.context-39SDt:not(.info-lGuAV, .warning-314AU) {
	border-color: var(--success-stroke-hover);

	background-color: var(--success-bg);
}

.container-1yX7-:not(.info-lGuAV, .warning-314AU) .icon-1EWAG {
	color: var(--success);
}

.container-1yX7- .title-39wvq {
	margin-bottom: 0;

	color: var(--text-base);
	font-weight: 700;

	font-size: 16px;
	line-height: 1.3;
}

.container-1yX7- .text-2nppn {
	color: var(--text-mid);

	font-size: 14px;
	font-weight: 400;
	line-height: 1.3;
}

.container-1yX7-.warning-314AU .title-39wvq {
	color: var(--error-text);
}

.container-1yX7- button.action-3EaBS {
	height: 36px;

	padding: 0 16px;

	border: none;

	color: var(--white);

	background-color: var(--brand-1-0);
}

.container-1yX7- button.action-3EaBS:hover {
	background-color: var(--brand-1--1);
}

.container-1yX7- button.action-3EaBS svg {
	display: none;
}
