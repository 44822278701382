.container-2Efqb {
	display: flex;
	flex-direction: column;
	grid-gap: 28px;
	gap: 28px;

	width: 100%;
	max-width: 600px;
	padding: 32px;

	border-radius: 20px;

	background: var(--bg-white);
}

.container_checkin-1YXzp {
	padding-top: 68px;
}

.title-2gAYd {
	color: var(--text-base);
	font-size: 24px;
	font-weight: 700;
	line-height: 1.3;
}

.container_checkin-1YXzp .title-2gAYd {
	display: none;
}

.form-1jLHq {
	display: flex;
	flex-direction: column;
	grid-gap: 28px;
	gap: 28px;
}

.form__row-1VwMJ {
	position: relative;
}

.form__input-2ZbXP {
	width: 100%;
}

.form-1jLHq .form__input-2ZbXP label {
	overflow: hidden;

	width: 100%;

	padding-right: 50px;

	white-space: nowrap;
	text-overflow: ellipsis;
}

.form-1jLHq .form__input-2ZbXP label[data-shrink='true'] {
	width: 100%;
	padding-right: 0;
}

.form__row_footer-2wMEY {
	display: flex;
	align-items: center;
	justify-content: flex-end;
}

.button-3C_am {
	background: var(--brand-3-0);
}

button.button-3C_am {
	min-width: 140px;
	height: 50px;

	padding: 0 24px;

	border-radius: 6px;

	font-weight: 700;

	font-size: 18px;

	background: var(--brand-3-0);
}

button.button-3C_am:hover {
	background: var(--brand-3-1);
}

button.button_disabled-FWELe {
	color: var(--text-pale);

	background: var(--bg-fill);
}

.checkinHint-2VVpC {
	flex: 1;
	margin-right: 10px;
}

.tooltip__icon-3uPJZ {
	position: absolute;
	top: 28px;
	right: 12px;
	z-index: 1;

	display: flex;
	justify-content: center;
	align-items: center;
	width: 24px;
	height: 24px;

	color: var(--text-pale);

	transform: translateY(-50%);
}

.notification_context-j-CXG .notification__text-13DzL {
	font-weight: 500;
}

@media screen and (max-width: 600px) {
	.container-2Efqb {
		padding: 20px;

		border-radius: 0;

		box-shadow: none;
	}

	.form-1jLHq {
		margin-top: 15px;
	}

	.checkinHint-2VVpC {
		margin: 0 0 20px 0;
		align-self: flex-start;
	}

	div.tooltip-3bDgK {
		margin-top: -34px !important;
		margin-left: -140px;
	}
}
