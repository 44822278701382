.stepbar-3vgWP {
	position: relative;

	width: 100%;

	height: 40px;

	background-color: var(--stepbar-background-color);
}

.inner-j4iyf {
	max-width: 1100px;
	margin: 0 auto;
	display: flex;

	height: 100%;
}

.content-1AyfB {
	flex: 1;
	display: flex;
	height: 100%;
}

.cart-9IdSY {
	display: flex;
	align-items: center;
	padding: 0 20px;

	height: 100%;

	color: var(--white);
	font: var(--medium--1-desktop-bold);

	cursor: pointer;
	background: var(--cart-button);
}

@media screen and (max-width: 600px) {
	.inner-j4iyf {
		display: none;
	}
}

@media (max-width: 1024px) {
	.inner-j4iyf {
		padding: 0 20px;
	}
}
