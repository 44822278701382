.container-2BH6U {
	display: flex;
	align-items: center;
}

.barcode-3zsIJ {
	display: none;
}

.barcode__disabled-2mkD0 {
	opacity: 0.3;

	filter: grayscale(1);

	pointer-events: none;
}

button.control-1M9uw {
	margin-right: 8px;

	border-radius: 6px;
}

/* stylelint-disable */
.control-1M9uw {
}
/* stylelint-enable */
button.controlDownload-viYh4 {
	width: 32px;
	min-width: 32px;
	height: 32px;
	padding: 0;

	border-radius: 50%;
}

button.controlDownload-viYh4 svg {
	width: 50%;
	height: 50%;
}

.controlImg-12qJO {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 34px;

	cursor: pointer;

	transition: opacity 0.15s;
}

.controlImg-12qJO img {
	height: 100%;
}

.controlImg__disabled-2tUwD {
	opacity: 0.6;

	pointer-events: none;
}

.controlSegment-2ISQ9 {
	display: flex;
	align-items: center;
	margin-left: auto;
}

@media screen and (max-width: 600px) {
	.barcode-3zsIJ {
		display: flex;
		align-items: center;
		justify-content: center;
	}
}
