div.card-3W5BS {
	position: relative;

	width: 335px;
	height: 112px;

	border-radius: 0;

	box-shadow: none;
}

div.card_withoutPanorama-AeC1o {
	background: linear-gradient(180deg, #57a4fe 0%, #3290ff 100%);
}

.card__focusHighlight-vniud {
	color: var(--white);
}

.card__panorama-r8kcj {
	width: 100%;
	height: 112px;

	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
}

div.card__weather-1hzyY {
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	min-width: 93px;
	min-height: 88px;

	border-radius: 0;

	box-shadow: none;
}

.card__content-1jD9H {
	position: absolute;
	left: 0;
	top: 0;

	width: 100%;
	height: 112px;

	display: flex;
	justify-content: space-between;
	padding: 12px;
}

.card__weatherIcon-2FuDw {
	margin: 15px 0 0 12px;
}

.card__temperature-fOAaD {
	padding-right: 8px;

	letter-spacing: -3px;

	font-weight: 300;
	font-size: 36px;
	line-height: 54px;
	text-align: right;
}

.card__airportName-39AMa {
	align-self: flex-end;

	margin-left: auto;

	color: #ffffff;

	font-size: 15px;
	line-height: 22px;
}

.card__airportName_withIATA-4b_OW {
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	padding-left: 12px;
}

.card__airportIATA-2Y9i5 {
	font-weight: 200;
	font-size: 72px;
	line-height: 76px;

	opacity: 0.7;
}

.card__airportLink-2AAOm {
	margin-left: auto;
}

.card__chevronIcon-3SE4A {
	position: relative;
	top: 7px;

	display: none;
}
