

.badge-R7nN3 {
	display: flex;
	justify-content: center;
	align-items: center;
	min-width: 21px;
	height: 21px;
	margin-left: 8px;
	padding: 2px 4px;

	border-radius: 100px;

	color: var(--white);
	font-weight: 700;
	font-size: 13px;
	line-height: 21px;

	background-color: var(--base10);
}

.badge_active-3s-j4 {
	color: var(--brand-1-0);

	background-color: var(--white);
}
