

.wrapper-2QM-3 {
	padding: 20px;

	border-radius: 8px;

	color: var(--text);

	background: var(--white);
}

.flightInfo-2Clqb {
	position: relative;

	display: flex;
	flex-direction: column;
}

.flightInfo__iatas-1puI0 {
	display: flex;
	align-items: center;
	justify-content: space-between;

	font-size: 40px;
	font-weight: 600;
	line-height: 150%;
}

.planeIcon-3FMTa {
	display: flex;
	align-items: center;
	width: 36px;
	height: 36px;

	color: #b3b3b3;

	transform: translateY(50%) rotate(180deg);
}

.planeIcon-3FMTa > svg {
	width: 100%;
	height: 100%;
}

.flightInfo__cities-3eDZT {
	display: flex;
	justify-content: space-between;

	text-transform: uppercase;

	font-size: 13px;
	line-height: 1.3;
}

.flightInfo__cities-3eDZT > span {
	flex-basis: 40%;

	color: var(--gray50);
}

.flightInfo__cities-3eDZT > span:last-child {
	text-align: right;
}

.flightInfo__time-33Kq6 {
	display: flex;
	justify-content: space-between;
	margin-bottom: 8px;
	padding-bottom: 8px;

	border-bottom: 1px dashed #c2c2c2;

	font-weight: 700;

	font-size: 18px;
	line-height: 1.3;
}

.fareGroup-2ycGw {
	display: flex;
	align-items: center;
	justify-content: center;
	margin-bottom: 8px;
}

.fareGroup__img-1qdnQ {
	height: 36px;
	width: 36px;
	margin-right: 8px;

	border-radius: 2px;

	background-position: center;
	background-size: cover;
}

.fareGroup__info-1eB8a {
	text-transform: uppercase;
	font-size: 18px;
	font-weight: 700;
	line-height: 130%;
}

.fareGroup_flight-1CfeJ {
	margin-top: 2px;

	font-size: 16px;
	font-weight: 500;
}

.arrivalInfo-Ichhz {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.arrivalInfo__place-1V_VP {
	display: flex;
}

.arrivalInfo__item-3DQdO {
	display: flex;
	flex-direction: column;

	font-size: 20px;
	line-height: 1.3;
	font-weight: 500;
}

.arrivalInfo__title-3wpZO {
	color: var(--gray50);
	text-transform: uppercase;

	font-size: 13px;
	line-height: 1.3;
	font-weight: 400;
}

.arrivalInfo__place-1V_VP .arrivalInfo__item-3DQdO:first-child {
	margin-right: 20px;
}
