.container-1SjD8 {
	position: relative;

	padding: 16px 0 20px;

	background: var(--brand-1-0);
}

.wrapper-WRZLv {
	max-width: 1100px;
	margin: 0 auto;
}

.title-37Ibv {
	margin-bottom: 20px;

	color: var(--white);
	font-weight: 700;
	font-size: 22px;
	line-height: 1.3;
}

.form-3OPlZ {
	display: flex;
}

.locations-2Gfj8 {
	position: relative;

	flex: 1;
	display: flex;
	align-items: center;
}

.location-1QCkl {
	border-right: 1px solid var(--line-separator);
}

.location-1QCkl.from-1jOHx .input-1OR1C {
	border-radius: 4px 0 0 4px;
}

button.button-2jR_8 {
	min-width: 64px;
	min-height: 48px;

	border-radius: 0 4px 4px 0;

	background: var(--brand-3-0);
}

button.button-2jR_8:hover {
	background: var(--brand-3--1);
}

button.buttonSwitch-ZLWoD {
	position: absolute;
	top: 50%;
	left: 50%;
	z-index: 1;

	display: none;
	width: 32px;
	min-width: 32px;
	height: 32px;
	min-height: 32px;
	padding: 6px;

	border-radius: 50%;

	transform: translate(-50%, -50%);
	background: var(--brand-1-0);
}

button.buttonSwitch-ZLWoD svg {
	transform: rotate(90deg);
}

@media (max-width: 1024px) {
	.container-1SjD8 {
		padding: 20px 20px 40px;
	}
}

@media screen and (max-width: 600px) {
	.container-1SjD8 {
		padding: 16px 20px 40px;
	}

	.title-37Ibv {
		margin-bottom: 14px;

		font-size: 18px;
	}

	.form-3OPlZ {
		flex-direction: column;
	}

	.location-1QCkl {
		flex: 0 1 50%;

		border-right: none;
	}

	.location-1QCkl.from-1jOHx {
		margin-right: 4px;
	}

	.location-1QCkl.from-1jOHx .input-1OR1C {
		padding-right: 24px;

		border-radius: 4px 0 0 0;
	}

	.location-1QCkl.to-1S3ez .input-1OR1C {
		padding-left: 24px;

		border-radius: 0 4px 0 0;
	}

	button.button-2jR_8 {
		width: 100%;
		min-height: 44px;
		margin-top: 4px;

		border-radius: 0 0 4px 4px;
	}

	button.buttonSwitch-ZLWoD {
		display: flex;
	}
}
