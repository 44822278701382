

.wrapper-3ZEBS {
	position: relative;

	display: flex;
	flex-direction: column;
	justify-content: center;
	height: 100%;
	padding: 11px 16px 11px 52px;
}

.wrapper-3ZEBS:hover {
	cursor: pointer;
	background: var(--base90);
}

.fare-1fg5H {
	color: var(--gray50);
	line-height: 21px;
}

.price-2keWN {
	font-weight: 500;
	color: var(--gray50);
	text-decoration: none !important;
}

.fare-1fg5H:hover,
.fare-1fg5H:hover .price-2keWN {
	color: var(--base-15);
}

.code-Eg_Pe {
	position: absolute;
	top: 11px;
	left: 11px;

	display: none;

	color: var(--base50);
	font-size: 44px;
	line-height: 44px;
}

div.tooltip-2Br5d {
	padding: 12px;

	transform: translateY(5px) !important;
}

.tooltip-2Br5d:after {
	top: -14px;
	bottom: auto;

	transform: translate(-50%, calc(100% - 1px)) rotate(180deg);
}

.fare-1fg5H:hover .code-Eg_Pe {
	display: block;
}

.fee-TmvO4 {
	display: flex;
	justify-content: space-between;
	margin-bottom: 6px;

	color: #ffffff;
	font-size: 14px;
	line-height: 18px;
}

.fee-TmvO4.active-3WC_Z {
	color: var(--warning);
}

.name-38W3r {
	display: flex;
	justify-content: space-between;
	align-items: flex-end;

	color: inherit;
	font-weight: 500;
	text-decoration: none;
}

.name-38W3r:hover {
	text-decoration: none;
}

.name-38W3r:hover .name__inner-20dG8 {
	text-decoration: underline;
}

.bold-2tfKV {
	color: var(--gray90);
	font-weight: 700;
	font-size: 24px;
	line-height: 31px;
}
