.wrapper-1rTd_ {
	padding: 40px 28px 28px;
}

.header-27hTA {
	margin-bottom: 10px;

	font-weight: 500;
	font-size: 22px;

	color: var(--dark-gray);
}

.desc-1fLjS {
	margin-top: 10px;

	font-size: 15px;
	color: var(--common-gray);
}

.price-uIWxA.crossed-3rpqg {
	text-decoration: line-through;
}

@media screen and (max-width: 600px) {
	.header-27hTA {
		font-size: 18px;
	}

	.desc-1fLjS {
		margin-top: 16px;

		font-size: 14px;
	}
}

.buttons-1uK0G {
	display: flex;
	justify-content: flex-end;
	margin-top: 28px;
}

button.changeFlight-ICpwe {
	margin-right: 12px;

	color: #777777;

	background: #ededed;
}

button.changeFlight-ICpwe:hover {
	color: #777777;

	background: #ededed;
}

.old_price-3QAt5 {
	color: var(--dark-red);
	font-size: 16px;
	font-weight: 500;
}

.price_lower-1kIg6 .header-27hTA {
	color: #009660;
}

@media screen and (max-width: 600px) {
	.wrapper-1rTd_ {
		padding: 20px;
	}

	.buttons-1uK0G button {
		font-size: 13px;
	}
}
