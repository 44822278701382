

.fields-2V52u {
	flex: 1;
	overflow: auto;
}

.controls-CHmtU {
	display: grid;
	grid-gap: 12px;
	grid-template-columns: 1fr 1fr;
	height: 65px;
	padding: 12px;

	box-shadow: 0 -7px 20px rgba(0, 0, 0, 0.03);
	background: #ffffff;
}

/* stylelint-disable */
.cancel-3QBTj {
}
.confirm-3D1DU {
}
.disabled-1sQa2 {
}
/* stylelint-enable */
