.calendar-3Npo2 {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;

	border-radius: 4px;
}

.calendar-3Npo2:first-child {
	margin-top: 20px;
}

.header-2Itn1 {
	position: relative;

	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	height: 64px;
	padding: 0 calc((100% - 1100px) / 2);

	color: var(--gray80);
}

.title-CK0DU {
	display: flex;
	align-items: center;
}

.title__text-m_4NM {
	display: flex;
	align-items: center;

	font-weight: 600;
	font-size: 24px;
}

.title__cities-3QeTX {
	display: flex;
	align-items: center;

	font-weight: 600;
	font-size: 24px;
}

.title__cities-3QeTX > svg {
	flex-shrink: 0;

	margin: 0 8px;

	color: var(--base);
}

.title__icon-onXT- {
	display: flex;
	align-items: center;

	transform: rotate(180deg);
}

.priceGraph__content-3q5Xn {
	padding: 54px 40px;
}

.priceGraph__closeModal-WlB-f {
	top: 15px;
	right: 10px;
	z-index: 3;

	display: flex;
	align-items: center;
	width: 28px;
	height: 28px;

	border-radius: 50%;

	color: #333333;

	background: var(--gray05);
}

.priceGraph__closeModal-WlB-f svg {
	position: relative;

	width: 28px;
	height: 28px;
}

.priceGraph__closeModal-WlB-f > svg {
	height: 20px;
}

.title__icon_reverse-1O0Pp {
	transform: rotate(0deg);
}

.ffpInfo-bRCtO {
	width: 100%;
	max-width: 1100px;
}

.content-mYXDZ {
	padding: 16px 0;
	max-width: 1100px;
	width: 100%;
	min-height: 132px;
}

.tabs-3d7Mh {
	display: flex;
	align-items: center;

	white-space: nowrap;
}

.tabs__flexContainer-1jm1j {
	height: 100%;
}

.legNumber-3Bsvd {
	flex-shrink: 0;

	width: 32px;
	height: 32px;
	margin-right: 15px;

	border-radius: 50%;

	color: #ffffff;
	font-weight: 600;
	font-size: 17px;
	line-height: 32px;
	text-align: center;

	background: var(--base15);
}

button.diagram-2V3dv {
	display: inline-flex;
	height: 37px;
	padding: 0 16px;

	border-radius: 100px;

	color: var(--common-blue);
	font-weight: 500;
	font-size: 15px;
	line-height: 37px;
}

button.diagram-2V3dv svg {
	margin-left: 6px;

	opacity: 0.5;
}

button.tab-2jlrg {
	min-width: 0;
	padding: 0 12px;

	border-bottom: 3px solid transparent;

	color: #9a9a9a;
	font-family: inherit;
	font-weight: 500;
	font-size: 13px;
	text-transform: none;

	opacity: 1;

	transition: background-color 0.15s, border-bottom-color 0.15s;
}

button.tab_active-2E0U5 {
	color: var(--tab-color-active);
}

span.tab__idicator-3BZ4o {
	height: 3px;

	background-color: var(--tab-color-active);
}

.priceGraph-b8BF4 {
	padding: 0;
}

div.priceGraph__modal-hqvFd {
	z-index: 1600 !important;
}

@media screen and (max-width: 600px) {
	.ffpInfo-bRCtO {
		margin-bottom: 20px;
	}
}

@media (max-width: 1024px) {
	.title__text-m_4NM {
		font-size: 20px;
	}
}
