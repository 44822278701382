.form-12fof,
label.formControl-2qdVO,
span.label-LDxaB {
	width: 100%;
}

label.formControl-2qdVO {
	margin: 0;
}

span.checkbox-3ymsF {
	position: absolute;
	left: -40px;
}

@media screen and (max-width: 600px) {
	span.checkbox-3ymsF {
		left: -30px;
	}
}
