.root-2Ajim {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.icon-bBi1- {
	color: var(--brand-1-0);
}

.name-v8nA1 {
	margin-top: 12px;

	font-weight: 500;
	font-size: 13px;
	line-height: 1.5;

	color: var(--text-mid);
	text-align: center;
}

.size-3N2lc {
	max-width: 150px;
	margin-top: 1px;

	font-size: 12px;
	line-height: 1.5;
	text-align: center;

	color: var(--text-light);
}

.root-2Ajim.selected-UK6cM .icon-bBi1- {
	color: var(--success-icon);
}

.iconWrapper_pointer-2Ltn6 {
	cursor: pointer;
}

@media screen and (max-width: 600px) {
	.size-3N2lc {
		display: none;
	}
}
