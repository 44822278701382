

.container-3_57Z {
	width: 100%;
}

div.tabs-23KAY {
	margin-top: 0;
	padding-top: 16px;

	background: var(--common-blue);
}

div.tabLabel-2mZf4 {
	color: #ffffff;
	text-transform: unset;
}

button.tabSelected-2cuih div.tabLabel-2mZf4 {
	color: #ffffff;
}

div.tabs-23KAY span.indicator-2VeIX {
	display: block;

	background: #ffffff;
}

div.tabs__container-1KWm4 {
	max-width: 1100px;
	margin: 0 auto;
}
