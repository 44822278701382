/* stylelint-disable */
div.root-1VEnR {
}

div.root_transfer-2AxwZ .root__scrollBody-215j3 .root__paper-t-UJI {
	max-width: 840px;

	border-radius: 20px;

	background: transparent;
}

.root_animal-wqFyp {
}

.root_businessLounge-24P1p .header-1ph29,
.root_priorityBoarding-3qCmL .header-1ph29 {
	height: 124px;
	padding-top: 0;

	color: var(--white);
	font-size: 28px;
	line-height: 1.3;

	border-radius: 8px 8px 0 0;
}

.root_businessLounge-24P1p .header-1ph29 {
	background: var(--additional-business-lounge);
}

.root_priorityBoarding-3qCmL .header-1ph29 {
	background: var(--additional-priority-boarding);
}

.root_loyalty-2YRnU {
}

.header__info-1iRAD {
}

.header__icon-VP7av {
}

@media screen and (max-width: 600px) {
	.root_businessLounge-24P1p .header-1ph29,
	.root_priorityBoarding-3qCmL .header-1ph29 {
		font-size: 22px;
		line-height: 1.2;
	}

	/* transfer */
	div.root_transfer-2AxwZ .root__paper-t-UJI {
		border-radius: 0;
	}

	/* insurance */
	div.root_insurance-hKxCj .root__scrollBody-215j3 {
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
	}

	div.root_insurance-hKxCj .root__paper-t-UJI {
		min-height: auto !important;

		border-radius: 8px 8px 0 0;
	}

	div.root_insurance-hKxCj .root__scrollBody-215j3 .root__paper-t-UJI {
		overflow: auto;
	}

	/* baggage-delivery */
	div.root_baggageDelivery-23SKU .root__paper-t-UJI {
		padding: 12px;

		border-radius: 12px 12px 0 0;
	}

	/* capsule-hotel */
	div.root_capsuleHotel-1vQLP {
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
	}

	div.root_capsuleHotel-1vQLP .root__scrollBody-215j3 {
		height: auto;
	}

	/* common */
	.root_businessLounge-24P1p .root__scrollBody-215j3,
	.root_priorityBoarding-3qCmL .root__scrollBody-215j3,
	.root_transfer-2AxwZ .root__scrollBody-215j3,
	.root_loyalty-2YRnU .root__scrollBody-215j3 {
		display: flex;
		justify-content: flex-end;
	}

	.root_businessLounge-24P1p div.root__scrollBody-215j3 .root__paper-t-UJI,
	.root_priorityBoarding-3qCmL div.root__scrollBody-215j3 .root__paper-t-UJI,
	.root_transfer-2AxwZ div.root__scrollBody-215j3 .root__paper-t-UJI,
	.root_loyalty-2YRnU div.root__scrollBody-215j3 .root__paper-t-UJI {
		width: 100%;
		min-height: auto;

		margin: auto 0 0 !important;

		border-radius: 8px 8px 0 0;
	}
}
