.services-1quwh {
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
	grid-gap: 8px;
	gap: 8px;

	width: 100%;
	max-width: 1100px;

	margin: 0 auto;
	padding: 40px 0;
}
