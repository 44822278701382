

.container-2hsnI {
	display: flex;
	flex-direction: column;
	width: 100%;
	max-width: 1100px;

	margin-bottom: 12px;
}

.flight-3CoJm {
	display: flex;
	width: 100%;

	border-radius: 4px;

	box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.04);
	cursor: pointer;
	background: #ffffff;

	transition: box-shadow 0.2s;
}

.flight-3CoJm.selected-3vPLc {
	border: 1px solid var(--gray-line);
	border-radius: 4px;

	box-shadow: none;
}

.flight-3CoJm.flight_opened-2d4aC.flight_dropdown-24cmb.selected-3vPLc {
	border-radius: 4px 4px 0 0;
}

.flight-3CoJm:not(.selected-3vPLc, .flight_readonly-3FBdO):hover {
	box-shadow: 0 1px 8px rgba(0, 0, 0, 0.07), 0 0 25px rgba(0, 0, 0, 0.12);
}

.flight_readonly-3FBdO {
	cursor: default;
}

.segments-iEEpj {
	display: flex;
	flex-direction: column;
	flex: 0 1 875px;
	justify-content: center;
}

.segments_withoutPrices-30fgO {
	flex: 1;
}

.segmentSelector_container-nJ5k5 {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
}

.segmentSelector-Gtq05 {
	width: calc(100% - 40px);

	border: 1px solid var(--brand-1-3);
}

.segmentSelector_container-nJ5k5 .segmentSelector-Gtq05,
.segmentSelector_container-nJ5k5 .showSelected-3rHIC {
	border: 1px solid var(--brand-1-3);
}

.segment_segmentSelector-1jBqu {
	padding: 0 40px;
	display: flex;
	flex-direction: row;
	align-items: center;
}

.segment_segmentSelector_checkbox-1829h {
	width: 0;

	transform: translateX(-80px);
}

.segment_segmentSelector-1jBqu.checked-3CEuT {
	background: var(--brand-1-5);
}

.duration-37pjU {
	display: flex;
	justify-content: center;
	align-items: center;
	min-height: 31px;
	padding: 8px 4px;

	border-top: 1px solid var(--gray10);
}

.flight-3CoJm.flight_canceled-QobbG {
	cursor: inherit;
}

.flight-3CoJm.flight_canceled-QobbG .segments-iEEpj {
	opacity: 0.4;
}

span.modal__close-35hq8 {
	display: none;
}

.flight_select-2ro2N {
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0 60px;

	border-left: 1px dashed var(--gray20);
}

.flight_select-2ro2N button {
	color: var(--base-15);

	background-color: var(--base80);
}

.flight_select-2ro2N button:hover {
	background-color: var(--base80);
}

.dropdown__icon-342od {
	display: flex;
	align-items: center;
	padding: 20px 10px 20px 0;
}

.arrow-2U8mG {
	display: flex;
	justify-content: center;
	align-items: center;

	width: 24px;
	height: 24px;

	border-radius: 50%;

	color: var(--brand-1-0);

	transform: rotate(90deg);
	background: var(--brand-1-5);

	transition: transform 0.15s;
}

.arrow-2U8mG.arrow_opened-2Lpet {
	transform: rotate(-90deg);
}

.fares-1G6_u {
	max-width: 1100px;
	width: 100%;
}

.subStatus-3VkIl {
	display: flex;
	flex: 1;
	justify-content: center;
	align-items: center;

	padding: 20px 16px;

	border-left: 1px solid var(--line-separator);

	color: var(--text-mid);
	font-size: 16px;
	line-height: 1.3;
	text-align: center;
}

.infoMessage-1iNtP {
	width: 100%;

	padding: 20px 16px;
}

.infoMessage__htmlMessage-166Mc {
	width: 100%;
	height: 100%;

	border-top-left-radius: 4px;
}
