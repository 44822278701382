.form-3aJ5D {
	margin-bottom: 12px;
	padding: 28px;

	background: #ffffff;

	transition: box-shadow 0.15s;
}

.form-3aJ5D:hover {
	box-shadow: 0 1px 8px rgba(0, 0, 0, 0.07), 0 0 25px rgba(0, 0, 0, 0.12);
}

/* stylelint-disable */
.label-27evn {
}

.label_lastName-3fO8F {
}

.label_firstName-cotaE {
}

.label_middleName-3rHks {
}

/* stylelint-enable */
@media screen and (max-width: 600px) {
	.form-3aJ5D {
		padding: 0 20px;

		background: none;
	}

	.form-3aJ5D:hover {
		box-shadow: none;
	}
}
