.addMemberFields-14tPN {
	display: grid;
	grid-gap: 25px;
	gap: 25px;
}

@media screen and (max-width: 600px) {
	.addMemberFields-14tPN {
		display: flex;
		flex-direction: column;
	}
}

.addMemberFields__group-2yNkd {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	grid-gap: 25px;
	gap: 25px;
}

@media screen and (max-width: 600px) {
	.addMemberFields__group-2yNkd {
		display: flex;
		flex-direction: column;
		grid-gap: 25px;
		gap: 25px;
	}
}

.fieldInput-2208p input {
	width: 100%;

	border-top: none;

	background-color: var(--very-pale-violet);
}

@media screen and (max-width: 600px) {
	.fieldInput-2208p input {
		background-color: transparent;
	}
}

.fieldText-1ninR {
	display: flex;
	align-items: center;

	font-size: 14px;
	font-weight: 400;

	color: var(--text-mid);
}

@media screen and (max-width: 600px) {
	.fieldText-1ninR {
		margin-top: 20px;
	}
}
