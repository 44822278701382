

.modal__title-RrOmB {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	padding: 28px 40px;

	border-bottom: 1px solid var(--light-gray);

	font-size: 24px;
	font-weight: 500;

	background: var(--half-transparent-gray);
}

.modal__content-dpJ-n {
	display: flex;
	justify-content: space-evenly;
	flex-wrap: wrap;
}

.modal__serviceName-H_zdq {
	font-weight: 600;
}

.modal_content_transfer-1LSbD {
	display: flex;
	flex-direction: column;

	padding: 40px 40px 0;
}

.heading-37Jtk {
	display: flex;
	flex-direction: row;

	box-sizing: content-box;
	padding: 20px 0;

	border-bottom: 1px solid #d9d9d9;

	font-weight: 400;
}

.select__wrp-1a_af {
	display: flex;
	margin-left: 20px;
}

.select__wrp-1a_af > div {
	font-family: inherit;
}

.heading_route_part-jsfDJ {
	font-weight: 500;
}

.heading_date-guGf3 {
	font-weight: 500;
	font-size: 15px;

	color: var(--common-gray);
}

.heading_date-guGf3,
.heading_city-1499c,
.heading_route_part-jsfDJ {
	display: flex;
	align-items: center;
	justify-content: flex-start;

	font-size: 17px;
	font-weight: 500;
}

.heading_city-1499c {
	margin-left: 20px;
}

.icon-1l53w {
	height: 24px;
	margin-left: 10px;
}

.icon-1l53w.button_close-3a2BN {
	transform: rotate(45deg);

	fill: #dadada;
}

.icon-1l53w.button_add-KQE8_ {
	fill: #ffffff;
}

.button_download-ihOBQ {
	margin-left: 3px;

	transform: rotate(90deg) scale(0.7) translate(0, 1px);

	fill: var(--common-blue);
}

button.button_remove-1dAtJ {
	justify-content: space-between;
	padding: 0 12px;
	margin-left: auto;

	width: 190px;

	border-radius: 24px;

	line-height: 19px;

	font-weight: 400;
}

/* loading button */
div.button_remove-1dAtJ {
	margin-left: auto;

	background-color: var(--cabinet-action);
}

.modal-ZFneG {
	max-width: 680px;
}

.modal__close-2iWCt {
	top: 24px;
	right: 24px;

	display: flex;
	flex-shrink: 0;
	justify-content: center;
	align-items: center;
	width: 32px;
	height: 32px;
	margin-left: 12px;

	border-radius: 50%;

	color: var(--text-pale);

	cursor: pointer;
	background: #f6f6f6;
}

.modal__close-2iWCt:hover {
	color: var(--text-light);

	background: #edebeb;
}

.modal__close-2iWCt svg {
	width: 20px;
	height: 20px;
}

.select_disabled-12Nii:before {
	display: none;
}

.terms__conditionsWrap-1Tsoc {
	display: flex;
	align-items: center;
}

.modal__conditionsLink-1gt2S {
	color: var(--common-blue);
	font-size: 13px;
	line-height: 20px;
	font-weight: 400;
}

.modal__conditions-1lRk1 {
	display: flex;
	align-items: center;
	padding: 0 0 0 20px;
}

.modal__infoIcon-PMIkc {
	padding: 6px;

	line-height: 20px;
}

.modal__infoIcon-PMIkc svg {
	width: 17px;
	height: 17px;
}

.modal__infoIcon-PMIkc path:nth-child(2) {
	fill: var(--common-blue);
}

.modal__totalPriceWrp-llcZh {
	position: relative;

	padding: 0 0 0 20px;
	margin-left: 20px;

	font-size: 24px;

	font-weight: 600;
}

.modal__totalPriceWrp-llcZh:before {
	position: absolute;

	top: 50%;
	left: -1px;

	content: '';

	height: 120%;
	width: 1px;

	transform: translate(0, -50%);

	background: var(--light-gray);
}

.modal__price-2g2tD {
	position: relative;
	left: 4px;

	display: flex;
	justify-content: space-between;
	width: 100%;

	color: var(--dark-gray);
	font-weight: 500;
	font-size: 17px;
}

.modal__price_white-2MQax {
	color: #ffffff;
}

svg.select_icon-BFTxH.select_icon_disabled-Y-id5 {
	display: none;
}
