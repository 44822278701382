

.wrapper-2fFPB {
	padding: 0;

	color: #ffffff;
}

.top__bar-2fOOv {
	display: flex;
	justify-content: space-between;
	padding: 17px 12px;

	border-bottom: white 1px solid;
	border-radius: 8px 8px 0 0;

	font-weight: 400;
	font-size: 15px;
	font-style: normal;
	line-height: 120%;

	background: var(--base-15);
}

.date-EeVje {
	white-space: nowrap;
}

.bottom__bar-1jEdy {
	display: flex;
	justify-content: space-around;

	font-weight: 900;
	font-size: 14px;
	font-style: normal;
	line-height: 100%;

	background: var(--base15);
}

button.sort__button-2dZ1H {
	min-width: 0;
	display: flex;
	justify-content: center;
	width: 100%;
	height: 48px;
	padding: 12px 0;

	border-left: white 1px solid;
	border-radius: 0;

	color: white;

	background: var(--base15);
}

button.sort__button-2dZ1H:first-child {
	border-left: none;
}
