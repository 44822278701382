.container-upSPW {

	padding: 60px 0;
}

@media screen and (max-width: 600px) {
	.container-upSPW {
		position: relative;

		padding-top: 40px;
	}
}

.header-1VpJ1 {
	width: 200px;
	height: 24px;
	margin-left: 15px;

	border-radius: 4px;

	background: rgba(205, 205, 205, 0.5);
}

.calendar-16e6b {

	padding-right: 28px;
}

@media screen and (max-width: 600px) {
	.calendar-16e6b {
		padding: 0;
	}
}

.daysWrapper-23uMN {
	position: relative;

	height: 100%;
	overflow: hidden;
}

.daysWrapper-23uMN:after {
	position: absolute;
	top: 0;
	bottom: 0;
	z-index: 1;

	width: 100%;

	transform: translateX(100%);
	opacity: 0.7;
	background: linear-gradient(
		to right,
		rgba(255, 255, 255, 0) 0%,
		rgba(255, 255, 255, 0.8) 50%,
		rgba(128, 186, 232, 0) 99%,
		rgba(125, 185, 232, 0) 100%
	);

	animation: slide-1ilj0 1s infinite 0.5s;
	content: '';
}

.days-AOU-Y {

	margin-top: 15px;
	margin-left: 100px;
}

@media screen and (max-width: 600px) {
	.days-AOU-Y {
		height: 100%;
	}
}

.day-QcJV4 {

	cursor: default;
}

.bar-29X02 {
	height: 100px;

	cursor: default;
}

@media screen and (max-width: 600px) {
	.bar-29X02 {
		height: 100%;
	}
}

.progress-1xG6_ {

	height: 90%;

	opacity: 1;
	background: rgba(205, 205, 205, 0.5);
}

.day-QcJV4:hover .progress-1xG6_ {
	opacity: 1;
	background: rgba(205, 205, 205, 0.5);
}

.day__header-3PHyD {
	right: 0;
	bottom: -23px;
	left: 0;

	width: 14px;
	height: 14px;
	margin: auto;

	border-radius: 4px;

	opacity: 0.5;
	background: rgba(205, 205, 205, 0.5);
}

.legends-349cF {

	left: 70px;
}

.line-q1Z-s {
}

.label-S_AjJ {
	top: -8px;

	width: 50px;
	height: 15px;

	border-radius: 15px;

	background: rgba(205, 205, 205, 0.5);
}

@keyframes slide-1ilj0 {
	0% {
		transform: translateX(-100%);
	}

	100% {
		transform: translateX(100%);
	}
}
