

.wrapper-10EAA {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	padding: 12px 18px 20px;

	background: inherit;
}

.title-ERKwe {
	font-size: 24px;
	color: var(--text-base);
	font-weight: 700;
}

.button-2ZEYa {
	width: 32px;
	height: 32px;
	margin-right: 12px;

	border-radius: 50%;

	background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGNpcmNsZSBvcGFjaXR5PSIwLjMiIGN4PSIxMiIgY3k9IjEyIiByPSIxMiIgZmlsbD0ibm9uZSIvPgo8cGF0aCBkPSJNMTYuNTkgOC41ODk4NEwxMiAxMy4xNjk4TDcuNDEgOC41ODk4NEw2IDkuOTk5ODRMMTIgMTUuOTk5OEwxOCA5Ljk5OTg0TDE2LjU5IDguNTg5ODRaIiBmaWxsPSJ3aGl0ZSIvPgo8L3N2Zz4K) center no-repeat var(--brand-1-0);

	transition: all 0.15s;
}

.button_opened-2U39h {
	transform: rotate(-180deg);
}

.root-BO8Ft .content-1WgUf {
	min-height: 100%;
	width: 94%;
	margin: 0 auto;
	padding: 20px;

	border-radius: 4px;

	background: var(--account-sidebar-background);
}

.root-BO8Ft {
	position: absolute;
	top: 56px;
	right: 0;
	left: 0;
	z-index: 10;

	margin-right: auto;
	margin-left: auto;

	text-align: center;
}

.user-Ni0eY {
	margin-bottom: 16px;
	display: flex;
	align-items: center;
}

.user__info-3zfso {
	display: flex;
	flex-direction: column;

	color: var(--bg-white);
	font-weight: 600;
	font-size: 18px;
}

.content-1WgUf .menu__item-2_kNj {
	padding: 15px;
}

.content-1WgUf div:not(.user__info-3zfso):not(.user-Ni0eY):hover,
.content-1WgUf div.menu__item_selected-LE79j,
.content-1WgUf div.menu__item_selected-LE79j:hover {
	border-radius: 4px;

	background-color: var(--brand-1-1);
}

.menu__item-2_kNj span {
	color: #ffffff;
	font-size: 14px;
	line-height: 22px;
	font-weight: 500;
	font-family: inherit;
}

.content-1WgUf .menu__item-2_kNj .icon-2b3lC > svg {
	width: 20px;
	height: 24px;
}

.menu__item-2_kNj .icon-2b3lC {
	margin-right: 0;
	min-width: 42px;

	font-size: 20px;
	color: #ffffff;

	opacity: 0.7;
}

.menu__item-2_kNj .item__text-2sPdC {
	padding: 0 24px 0 0;
	overflow: hidden;

	text-overflow: ellipsis;
	color: #ffffff;
}

.menu__item-2_kNj .badge-3cLwS {
	padding-right: 6px;
	padding-left: 6px;

	color: var(--brand-1-0);

	background-color: var(--bg-white);
}
