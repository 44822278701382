.field-WcWTA {
	position: relative;
}

.field_swapRight-ndfhZ {
	padding-right: 18px;
}

.field_swapLeft-ZX7Fa {
	padding-left: 18px;
}

.swap-uPAcq {
	position: absolute;
	top: 50%;
	right: -18px;
	z-index: 2;

	display: flex;
	justify-content: center;
	align-items: center;
	width: 24px;
	height: 24px;

	color: var(--text-base);

	transform: translateY(-50%) rotate(90deg);
	cursor: pointer;
}

@media (max-width: 1024px) {
	.field_swapRight-ndfhZ {
		padding-right: 0;
	}

	.field_swapLeft-ZX7Fa {
		padding-left: 0;
	}

	.swap-uPAcq {
		top: auto;
		left: 50%;
		right: auto;
		bottom: 0;

		transform: translate(-50%, 100%);
	}
}

@media screen and (max-width: 600px) {
	.field_swapRight-ndfhZ {
		padding-bottom: 8px;
	}

	.field_swapLeft-ZX7Fa {
		padding-top: 8px;
	}
}
