.wrapper-33Cfa {
	position: relative;

	padding: 30px 32px 32px;

	border-radius: 20px;

	background-color: var(--white);
	overflow: hidden;
}

.close-rKRGX {
	position: absolute;
	top: 30px;
	right: 32px;

	width: 32px;
	height: 32px;

	border-radius: 50%;

	cursor: pointer;
	background-color: #f6f6f6;
}

.close-rKRGX:before,
.close-rKRGX:after {
	content: '';

	position: absolute;
	top: 50%;
	left: 50%;

	width: 50%;
	height: 1px;

	background-color: var(--gray80);
	transform: translate(-50%, -50%) rotate(-45deg);
}

.close-rKRGX:after {
	transform: translate(-50%, -50%) rotate(45deg);
}

.title-2TU0I {
	margin-bottom: 22px;
	padding-right: 40px;

	color: #262626;
	font-weight: 700;
	font-size: 22px;
	line-height: 1.3;
}

.table-3LUL0 {
	display: flex;
	flex-direction: column;
}

.row-3lz2P {
	display: flex;

	border-bottom: 1px solid #d9d9d9;
}

.row-3lz2P:nth-child(even) {
	background-color: #f6f6f6;
}

.column-15ylI {
	flex: 1;
	padding: 12px;
}

.table-3LUL0 .column-15ylI:last-child {
	flex: 2;
}

.tableHead-2hmEx {
	display: flex;
	flex-direction: column;

	color: var(--white);
	font-size: 13px;
	font-weight: 700;
	line-height: 1.3;
}

.tableHead-2hmEx .row-3lz2P {
	background-color: var(--base);
}

@media screen and (max-width: 600px) {
	.wrapper-33Cfa {
		padding: 20px;
	}

	.close-rKRGX {
		top: 20px;
		right: 20px;
	}

	.table-3LUL0 {
		overflow: scroll;
	}

	.row-3lz2P {
		min-width: 780px;
	}
}
