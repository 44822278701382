

.wrapper-2RSw7 {
	padding: 40px;
	display: flex;
	flex-direction: column;
}

.text-q7wpi {
	margin: 0;
	padding-bottom: 30px;
}

button.button-3c85e {
	margin-left: auto;
}
