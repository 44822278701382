.wrapper-2lLcz {
	padding: 44px 48px;
}

.header-1eUFt {
	margin-bottom: 28px;

	color: var(--dark-gray);
	font-weight: 500;
	font-size: 20px;
	line-height: 1.6;
}

.text-3N9gx {
	margin-bottom: 40px;

	color: var(--sureModal-text-color);
	font-size: 17px;
	line-height: 1.7;
}

.footer-1WJSb {
	display: flex;
	align-items: center;
	justify-content: flex-end;
}

button.button-1NviR {
	margin-right: 12px;
}

button.button-1NviR:last-child {
	margin-right: 0;
}

button.button_cancel-2miQa {
	color: var(--sureModal-cancel-color);

	background-color: var(--sureModal-cancel-bg);
}

button.button_cancel-2miQa:hover {
	background-color: var(--sureModal-cancel-bg);
}

@media screen and (max-width: 600px) {
	.wrapper-2lLcz {
		padding: 24px 28px;
	}
}
