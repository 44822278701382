

.passengers_wrapper-1EL9S {
	display: flex;
	justify-content: center;
	padding: 30px 0;
}

div.root-3yYBY {
	background: var(--gray05);
}

.root-3yYBY span.close-1Y3P- {
	display: flex;
	flex-shrink: 0;
	justify-content: center;
	align-items: center;

	background: #ffffff;
}

.contacts-mY8li {
	margin-bottom: 12px;
}
