

.header-2aAsH {
	display: flex;

	cursor: pointer;
}

.header__cell-7VWfU {
	padding: 18px 0 18px 20px;

	display: flex;
	align-items: center;

	flex-basis: 33%;
	flex-grow: 1;

	line-height: 28px;
}

.header__cell-7VWfU:nth-child(1) {
	flex-basis: 364px;
}

.header__cell-7VWfU.header__cell_short-1JjTU {
	padding-right: 20px;
	overflow: hidden;
}

.header__cell-7VWfU:nth-child(2) {
	flex-basis: 432px;
}

div.header__cell-7VWfU:nth-child(2) {
	border-right: none;
}

.header__cell-7VWfU:nth-child(3) {
	flex-basis: 301px;

	color: #ffffff;

	background: #3290ff;
}

.header__cell_wide-2b2c0:nth-child(3) {
	flex-basis: 33%;
}

.header__cell-7VWfU:last-of-type {
	position: relative;

	justify-content: space-between;
	padding-right: 40px;
}

.header__cell-7VWfU:not(:last-of-type) {
	border-right: 1px dashed #cdcdcd;
}

.header__icon-1GvCW {
	margin-right: 14px;
	display: flex;
	align-items: center;

	transform: rotate(180deg);
}

.header__icon_reverse-1Kmhp {
	transform: rotate(0deg);
}

.title-1gNQK {
	font-size: 20px;
	font-weight: 600;
}

.header__cell_short-1JjTU .title-1gNQK {
	max-width: 100%;
	overflow: hidden;

	white-space: nowrap;
	text-overflow: ellipsis;
}

.travelTime-3UkRk {
	margin-left: 12px;
	margin-right: 12px;

	color: #a9a9a9;
	font-size: 20px;
	font-weight: 600;
}

.price-2wY9c {
	font-size: 22px;
	font-weight: 600;
}

.arrowIcon-2t9Js {
	position: absolute;
	right: 20px;

	color: #ffffff;

	transition: transform 0.15s;
}

.arrowIcon_flipped-hHWz3 {
	transform: rotate(-180deg);
}

.fareGroup-dig1g {
	display: flex;
	align-items: center;
}

.fareGroup__logo-19-AN {
	height: 20px;
	width: 20px;
	margin-right: 8px;
}

.fareGroup__logo-19-AN img {
	width: 100%;
	height: 100%;
}

.fareGroup__name-2fR_L {
	color: #ffffff;
	font-size: 14px;
	font-weight: 500;
	line-height: 20px;
}

.details-1rAct {
	color: var(--common-blue);
	font-size: 14px;
	font-weight: 500;
	line-height: 20px;
}
