.wrapper-1c2X8 {
	display: flex;
	align-items: center;
	padding: 18px;

	border: 1px solid #efea6c;
	border-radius: 4px;

	background-color: #fffdcc;
}

.icon-iBR-7 {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-right: 16px;

	color: #b3b3b3;
}

.text-2CnPn {
	font-size: 16px;
	font-weight: 500;
	line-height: 1.3;
}

.accent--_Pce {
	color: #cd202c;
}

.status-1mhXY {
	text-transform: capitalize;
}

.actions-K3zYh {
	display: flex;
	align-items: center;
	margin-left: auto;
}

button.button-38Qr8 {
	position: relative;

	margin-right: 24px;

	color: #cd202c;
	font-weight: 500;
	font-size: 14px;
	line-height: 1.3;

	cursor: pointer;
}

button.button-38Qr8:after {
	content: '';

	position: absolute;
	top: 0;
	right: -13px;

	height: 100%;
	width: 1px;

	background-color: var(--gray30);

	pointer-events: none;
}

button.button-38Qr8:last-child {
	margin-right: 0;
}

button.button-38Qr8:last-child:after {
	display: none;
}

div.modal_paper-1pEnO {
	max-width: 480px;
}

button.modal_button-tNzXW {
	margin-right: 12px;
}

button.modal_button-tNzXW:last-child {
	margin-right: 0;
}

button.modal_button-tNzXW.modal_button_cancel-2LM_O {
	color: var(--sureModal-text-color);

	background-color: var(--sureModal-cancel-bg);
}

button.modal_button-tNzXW.modal_button_cancel-2LM_O:hover {
	color: var(--sureModal-text-color);

	background-color: var(--gray10);
}

@media screen and (max-width: 600px) {
	.wrapper-1c2X8 {
		flex-wrap: wrap;
	}

	.text-2CnPn {
		width: 86%;
	}

	.actions-K3zYh {
		flex-wrap: wrap;
		margin-left: 0;
		margin-top: 16px;
	}

	.button-38Qr8 {
		margin-right: 20px;
	}

	.button-38Qr8:after {
		right: -11px;
	}

	div.modal_paper-1pEnO {
		width: 100%;
		min-width: auto;
		min-height: auto;

		border-radius: 8px 8px 0 0;
	}

	div.modal_body-3NNfC {
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
	}
}
