.dialogContent-325WA {
	padding-bottom: 80px;

	background: var(--bg-fill);
}

.headerInner-lErWv {
	position: static;

	display: flex;
}

.header-3EiGk {
	background: #76a96e;
}

.header-3EiGk:after {
	width: 220px;
	height: 138px;

	background: url('https://cdn.websky.aero/content/frontend/images/services/ZZ/transfer/root-icon.svg') no-repeat
		bottom right;
}

.segmentsTabsWrapper-1G6cM {
	margin: 0 auto;
}

@media screen and (max-width: 600px) {
	div.segmentsTabsWrapper-1G6cM {
		position: absolute;
		left: 0;

		width: 100%;
		margin: 0 auto;
		padding: 0;
	}
}

div.segmentTabs-1KCz6 {
	height: auto;
}
