

.container-2YYN1 {
	display: flex;
	flex-direction: column;
	justify-content: center;

	height: 100%;
	padding: 12px 8px;

	border: 1px solid var(--line-separator);
	border-radius: 4px;

	transition: border 0.15s;
}

.active-32wlK {
	min-height: 55px;

	border-color: var(--brand-1-0);
}

.disabled-3e_HR {
	opacity: 0.6;
}

.passenger-2_rNv {
	height: auto;
	margin-bottom: 0;

	border-bottom: none;
}

.doubleSeat-HopL2 {
	margin-top: 12px;
	padding: 12px 0 0;

	border-top: 1px dashed var(--line-dot-line);
	border-right: none;
	border-bottom: none;
	border-left: none;

	pointer-events: none;
}

.active-32wlK .doubleSeat-HopL2 {
	pointer-events: auto;
}
