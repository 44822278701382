.wrapper-HV6HH {
	position: relative;

	display: flex;
	flex-direction: column;

	width: 100%;
	max-width: 480px;

	min-height: 375px;
	margin: 50px auto;

	border-radius: 8px;

	background: var(--bg-white);

	box-shadow: 0 24px 84px rgba(0, 0, 0, 0.13);
	overflow: hidden;
}

.wrapper_inModal-1na9O {
	margin: 0;
}

div.progress__color-3SK1S {
	width: 100%;
	height: 4px;

	background-color: var(--results-loader-progress-background);

	transform: none;
}

div.progress__bar__color-VIHLl {
	background-color: var(--results-loader-progress-color);
}

.header-1P9FL {
	display: flex;
	flex-direction: column;
	align-items: center;

	width: 100%;
	max-height: 150px;
	height: 100%;

	padding-top: 32px;
}

.success-1IElw {
	background: rgba(43, 180, 106, 0.48);
}

.failed-3da15 {
	background: rgba(183, 0, 0, 0.3);
}

.paymentIcon-3K5Sg {
	width: 100%;
	height: 50px;
}

.paymentIcon_success-dMizE {
	background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTY4IiBoZWlnaHQ9IjQ4IiB2aWV3Qm94PSIwIDAgMTY4IDQ4IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBkPSJNMTM0LjQgNDhIMTM5LjJMMTUxLjIgMjcuNzg5NUgxNjQuNEMxNjYuMzkyIDI3Ljc4OTUgMTY4IDI2LjA5NjggMTY4IDI0QzE2OCAyMS45MDMyIDE2Ni4zOTIgMjAuMjEwNSAxNjQuNCAyMC4yMTA1SDE1MS4yTDEzOS4yIDBMMTM0LjQgMEwxNDAuNCAyMC4yMTA1SDEyNy4yTDEyMy42IDE1LjE1NzlIMTIwTDEyMi40IDI0TDEyMCAzMi44NDIxSDEyMy42TDEyNy4yIDI3Ljc4OTVIMTQwLjRMMTM0LjQgNDhaIiBmaWxsPSJ3aGl0ZSIvPgo8cmVjdCB5PSI2Ljg1NzE4IiB3aWR0aD0iNTUuNzE0MyIgaGVpZ2h0PSIzNC4yODU3IiByeD0iMy40Mjg1NyIgZmlsbD0id2hpdGUiLz4KPHJlY3QgeD0iNC4yODUxNiIgeT0iMTMuNzE0NCIgd2lkdGg9IjQ3LjE0MjkiIGhlaWdodD0iNi44NTcxNCIgZmlsbD0iIzEyQTQ0QyIvPgo8cmVjdCB4PSI0LjI4NTE2IiB5PSIxMy43MTQ0IiB3aWR0aD0iNDcuMTQyOSIgaGVpZ2h0PSI2Ljg1NzE0IiBmaWxsPSJ3aGl0ZSIgZmlsbC1vcGFjaXR5PSIwLjYiLz4KPGNpcmNsZSBjeD0iMTA0LjU3MSIgY3k9IjI0LjAwMDEiIHI9IjQuMjg1NzEiIGZpbGw9IndoaXRlIi8+CjxjaXJjbGUgY3g9IjcwLjI4NTciIGN5PSIyNC4wMDAxIiByPSI0LjI4NTcxIiBmaWxsPSJ3aGl0ZSIvPgo8Y2lyY2xlIGN4PSI4Ny40MjgzIiBjeT0iMjQuMDAwMSIgcj0iNC4yODU3MSIgZmlsbD0id2hpdGUiLz4KPC9zdmc+Cg==) center no-repeat;
}

.paymentIcon_failed-3MmWa {
	background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTY4IiBoZWlnaHQ9IjQ4IiB2aWV3Qm94PSIwIDAgMTY4IDQ4IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBkPSJNMTM0LjQgNDhIMTM5LjJMMTUxLjIgMjcuNzg5NUgxNjQuNEMxNjYuMzkyIDI3Ljc4OTUgMTY4IDI2LjA5NjggMTY4IDI0QzE2OCAyMS45MDMyIDE2Ni4zOTIgMjAuMjEwNSAxNjQuNCAyMC4yMTA1SDE1MS4yTDEzOS4yIDBMMTM0LjQgMEwxNDAuNCAyMC4yMTA1SDEyNy4yTDEyMy42IDE1LjE1NzlIMTIwTDEyMi40IDI0TDEyMCAzMi44NDIxSDEyMy42TDEyNy4yIDI3Ljc4OTVIMTQwLjRMMTM0LjQgNDhaIiBmaWxsPSJ3aGl0ZSIvPgo8cmVjdCB5PSI2Ljg1NzE4IiB3aWR0aD0iNTUuNzE0MyIgaGVpZ2h0PSIzNC4yODU3IiByeD0iMy40Mjg1NyIgZmlsbD0id2hpdGUiLz4KPHJlY3Qgb3BhY2l0eT0iMC4zIiB4PSI0LjI4NTE2IiB5PSIxMy43MTQ0IiB3aWR0aD0iNDcuMTQyOSIgaGVpZ2h0PSI2Ljg1NzE0IiBmaWxsPSIjQjcwMDAwIi8+CjxjaXJjbGUgY3g9IjEwNC41NzEiIGN5PSIyNC4wMDAxIiByPSI0LjI4NTcxIiBmaWxsPSIjQjcwMDAwIi8+CjxjaXJjbGUgY3g9IjcwLjI4NTciIGN5PSIyNC4wMDAxIiByPSI0LjI4NTcxIiBmaWxsPSIjQjcwMDAwIi8+CjxjaXJjbGUgY3g9Ijg3LjQyODMiIGN5PSIyNC4wMDAxIiByPSI0LjI4NTcxIiBmaWxsPSIjQjcwMDAwIi8+Cjwvc3ZnPgo=) center no-repeat;
}

.dividerIcon-3G2q2 {
	display: flex;
	justify-content: center;

	width: 100%;
	min-height: 80px;

	transform: translateY(40px);
}

.dividerIcon_success-530uU {
	background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iODAiIGhlaWdodD0iODAiIHZpZXdCb3g9IjAgMCA4MCA4MCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGNpcmNsZSBjeD0iNDAiIGN5PSI0MCIgcj0iNDAiIGZpbGw9IiMyQkI0NkEiLz4KPHBhdGggZD0iTTI3IDQwLjk4MDRMMzUuMzkxOSA1MEw1NCAzMCIgc3Ryb2tlPSJ3aGl0ZSIgc3Ryb2tlLXdpZHRoPSI0IiBzdHJva2UtbGluZWNhcD0icm91bmQiLz4KPC9zdmc+Cg==) center no-repeat;
}

.dividerIcon_failed-2-P8m {
	background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iODAiIGhlaWdodD0iODAiIHZpZXdCb3g9IjAgMCA4MCA4MCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGNpcmNsZSBjeD0iNDAiIGN5PSI0MCIgcj0iNDAiIGZpbGw9IiNCNzAwMDAiLz4KPHBhdGggZD0iTTI3IDQwLjk4MDRMMzUuMzkxOSA1MEw1NCAzMCIgc3Ryb2tlPSJ3aGl0ZSIgc3Ryb2tlLXdpZHRoPSI0IiBzdHJva2UtbGluZWNhcD0icm91bmQiLz4KPC9zdmc+Cg==) center no-repeat;
}

.content-24mzi {
	flex: 1;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	grid-gap: 30px;
	gap: 30px;

	padding: 60px 20px 40px;
}

.title-3ukKW {
	display: flex;
	justify-content: center;
	align-items: center;

	color: var(--text-base);
	font-size: 24px;
	font-weight: 500;
	line-height: 1.3;
	text-align: center;
}

.buttons-v4Ske {
	display: flex;
	align-items: center;
	grid-gap: 10px;
	gap: 10px;
}

/* stylelint-disable */
button.redirectButton-zT6Gi {
}

button.button_failed-viZei {
}

/* stylelint-enable */

@media screen and (max-width: 600px) {
	.content-24mzi {
		padding: 50px 12px 30px;
	}

	.title-3ukKW {
		font-size: 20px;
	}

	.buttons-v4Ske {
		flex-direction: column;
		align-items: normal;
	}
}
