

.container-DCrHm {
	background: var(--white);
}

.icon-wLQxR {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-right: -12px;

	color: inherit;
}
