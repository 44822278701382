.container---QVO {
	max-width: 560px;
	margin: 0 auto;
	padding-top: 40px;
}

.header-3UyCq {
	display: flex;
	align-items: center;
	margin-bottom: 28px;

	color: var(--gray80);
	font-weight: 600;
	font-size: 24px;
}

.icon-WJGno {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 44px;
	height: 44px;
	margin-right: 16px;

	border-radius: 50%;

	background: var(--base);
}

.buttons-196RY {
	display: flex;
	justify-content: center;
	margin-top: 28px;
}

button.button-3Mnv4 {
	background: var(--brand-3-0);
}

button.button-3Mnv4:hover {
	background: var(--brand-3--1);
}

button.button-3Mnv4[disabled] {
	background: var(--brand-3-2);
}

button.back-3f-b_,
button.back-3f-b_:hover {
	margin-right: 12px;

	color: var(--gray50);

	background: #ffffff;
}

@media screen and (max-width: 600px) {
	.container---QVO {
		width: 100%;
		padding-top: 12px;
	}

	.header-3UyCq {
		margin-bottom: 0;
		padding: 16px;

		border-radius: 8px 8px 0 0;

		color: #ffffff;
		font-weight: 500;
		font-size: 16px;

		background: var(--base);
	}

	.icon-WJGno {
		width: 24px;
		height: 24px;

		background: none;
	}
}
