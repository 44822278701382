.wrapper-xqWUA {
	padding: 40px 0;

	border-top: 1px solid #b6b6b6;

	background: #ededed;
}

.insurances-2Hh8m {
	display: flex;
	overflow-y: auto;
}

.insurances-2Hh8m > div {
	margin-right: 20px;
}

.insurances-2Hh8m > div:first-child {
	margin-left: 40px;
}

.insurances-2Hh8m > div:last-child {
	margin-right: 40px;
}

.insurances-2Hh8m:after {
	content: '';

	min-width: 1px;
	height: 1px;
}

.header-3g7bM {
	display: flex;
	justify-content: space-between;
}

div.dialog-1Nqeh {
	width: 100%;
	max-width: 1100px;
	overflow: visible;
}

div.dialog__header-SqXQf {
	position: relative;
}

a.rules-2vsWE {
	display: flex;
	align-items: center;

	font-weight: 400;
	font-size: 13px;
	line-height: 18px;
	text-decoration: none;
}

a.rules-2vsWE svg {
	padding-right: 4px;
}

.controls-3i2kk {
	padding: 20px 40px;

	display: flex;
	align-items: center;
}

.buttons-12H-F {
	flex: 1;

	text-align: right;
}

.documents-2yRl1 {
	padding: 10px 20px 34px;
	margin: 0 40px;
	margin-top: 20px;

	box-shadow: 0 1px 2px rgba(0, 0, 0, 0.08);
	background: #ffffff;
}

div.mobileWrapper-6T2Hd {
	background: #f0f0f0;
}

button.dialog__button_done-h1j0I,
button.dialog__button_clear-28TOp {
	width: 183px;
	height: 48px;

	font-size: 14px;
}

button.dialog__button_clear-28TOp {
	margin-right: 7px;
}

@media (max-width: 1024px) {
	div.dialog-1Nqeh {
		width: 100%;
	}

	.insurances-2Hh8m > div {
		margin-right: 14px;
	}

	.insurances-2Hh8m > div:first-child {
		margin-left: 20px;
	}

	.insurances-2Hh8m > div:last-child {
		margin-right: 20px;
	}
}

@media screen and (max-width: 600px) {
	.wrapper-xqWUA {
		margin-top: 20px;
		padding: 0;
		padding-bottom: 20px;

		border-top: none;

		background: none;
	}

	.documents-2yRl1 {
		padding: 0 25px;
	}
}
