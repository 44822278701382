.title-25KWx {
	height: 36px;
	padding: 0 32px 0 16px;

	border-radius: 100px;

	line-height: 36px;

	background: #ffffff;
}

@media (max-width: 1024px) {
	.sortings-1KZxt {
		flex: 1;
		width: 50%;
	}

	.title-25KWx {
		display: flex;
		align-items: center;
		height: 48px;

		border-right: 1px solid #ffffff;
		border-radius: 0;

		color: #ffffff;

		font-weight: 400;

		background: var(--base15);
	}

	.title-25KWx:before {
		position: relative;
		top: unset;

		flex-shrink: 0;

		margin-right: 8px;

		transform: none;
		background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjUiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNSAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik03LjgzMDEzIDE5TDEyLjE2MDMgMTMuNzVIOC44MzAxM1Y1SDYuODMwMTNWMTMuNzVIMy41TDcuODMwMTMgMTlaTTE2LjY2OTkgNUwyMS4xNjk5IDEwLjI1SDE3LjcwOTJWMTlIMTUuNjMwN1YxMC4yNUgxMi4xNjk5TDE2LjY2OTkgNVoiIGZpbGw9IndoaXRlIi8+Cjwvc3ZnPgo=);
	}

	.sortBy-Rnov9 {
		display: none;
	}

	.currentSorting-1MfkY {
		display: block;

		color: #ffffff;
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
	}
}
