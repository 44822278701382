

.flight-zvhnB {
	width: 100%;
	max-width: 560px;
	margin-top: 15px;

	border-radius: 4px;

	box-shadow: 0 1px 2px rgba(0, 0, 0, 0.08);

	background: #ffffff;
	overflow: hidden;
}

.flight-zvhnB:not(:last-of-type) {
	margin-bottom: 12px;
}

.segments-3GRg8 {
	padding: 20px 70px 16px;

	cursor: pointer;
}

.closer-3pzb5 {
	position: absolute;
	top: -40px;
	right: 10px;

	width: 32px;
	height: 32px;

	background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzIiIGhlaWdodD0iMzIiIHZpZXdCb3g9IjAgMCAzMiAzMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KCTxwYXRoIGQ9Ik0yNS4zMzMzIDguNTQ2NjlMMjMuNDUzMyA2LjY2NjY5TDE2IDE0LjEyTDguNTQ2NjMgNi42NjY2OUw2LjY2NjYzIDguNTQ2NjlMMTQuMTIgMTZMNi42NjY2MyAyMy40NTM0TDguNTQ2NjMgMjUuMzMzNEwxNiAxNy44OEwyMy40NTMzIDI1LjMzMzRMMjUuMzMzMyAyMy40NTM0TDE3Ljg4IDE2TDI1LjMzMzMgOC41NDY2OVoiIGZpbGw9IndoaXRlIi8+Cjwvc3ZnPgo=) center no-repeat;
}

.closer_info-1NCyR {
	top: -45px;
	right: -38px;
}

div.info__popup-3Ongj {
	width: 100%;
	max-width: 560px;
	max-height: none;

	box-shadow: 0 1px 2px rgba(0, 0, 0, 0.08);
	overflow: visible;
}

.info-26MPZ {
	padding: 40px 70px;
	max-height: 500px;
	overflow-y: auto;
}

.info__footer-NAxRq {
	padding: 20px 0;

	border-top: 1px solid #d9d9d9;

	text-align: center;
}

div.fares__popup-Ufx4m {
	width: 100%;
	max-width: none;
	margin: 0;

	box-shadow: none;
	background: transparent;
	overflow: visible;
}

.prices-u7mUq {
	display: flex;
}

.fares-hCkuY {
	display: block;
}

.fares-hCkuY .slick-track {
	display: flex;
}

.fares-hCkuY .slick-track .slick-slide {
	height: auto;
	padding-left: 20px;
}

.fares-hCkuY .slick-track .slick-slide > div {
	display: flex;
	height: 100%;
}

.fares__dots-qpEtD {
	text-align: center;
}

.fares__dots-qpEtD li {
	display: inline-block;
}

.fares__dots-qpEtD li:not(:last-of-type) {
	margin-right: 8px;
}

.fares__dots-qpEtD li button {
	width: 8px;
	height: 8px;
	padding: 0;

	border: none;
	border-radius: 50%;

	color: transparent;

	opacity: 0.4;

	background-color: #ffffff;

	transition: opacity 0.15s;

	appearance: none;
	-webkit-user-select: none;
	    -ms-user-select: none;
	        user-select: none;
}

.fares__dots-qpEtD li.slick-active button {
	opacity: 1;
}
