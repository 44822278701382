.wrapper-2p9n8 {
	display: flex;
	flex-wrap: wrap;
	grid-gap: 12px;
	gap: 12px;
}

.reasons-2LTxX {
	display: none;
}

@media (max-width: 1024px) {
	.wrapper-2p9n8 {
		grid-gap: 0;
		gap: 0;
	}
}

@media screen and (max-width: 600px) {
	.wrapper-2p9n8 {
		margin-bottom: 20px;
	}
}
