

.wrapper-1bWpS {
	max-width: 600px;
	margin: 0 20px 8px 20px;
	padding: 13px 20px 16px 20px;

	border-radius: 8px;

	color: #414141;

	background: #ffffff;
}

.wrapper_disabled-1HVDV {
	opacity: 0.6;
}

.flightInfo_mobile-3Rm4U {
	display: flex;
	flex-direction: column;
}

.flightInfo__iatas-1tcf- {
	display: flex;
	align-items: center;
	justify-content: space-between;

	font-size: 40px;
	font-weight: 600;
	line-height: 150%;
}

.planeIcon-En1EX {
	display: flex;
	align-items: center;
	margin: 0 8px;

	transform: rotate(180deg);

	overflow: hidden;
}

.planeIcon-En1EX:after,
.planeIcon-En1EX:before {
	max-width: 45%;

	color: #bababa;

	font-size: 18px;
	line-height: 24px;
	letter-spacing: 3px;

	transform: translateY(-6px);
	content: '...............................';
	overflow: hidden;
}

.planeIcon-En1EX svg {
	min-width: 24px;
	margin: 0 4px;

	color: #bababa;

	transform: rotate(180deg) scale(1.2);
}

.flightInfo__cities-2xdGs,
.flightInfo__dates-gFSrf {
	display: flex;
	justify-content: space-between;

	font-size: 13px;
	line-height: 150%;
}

.flightInfo__cities-2xdGs {
	position: relative;
	top: -10px;

	margin-bottom: 4px;

	color: #9a9a9a;
	font-size: 13px;
}

.flightInfo__cities-2xdGs > span,
.flightInfo__dates-gFSrf > span {
	max-width: calc(50% - 8px);
}

.flightInfo__cities-2xdGs > span:last-child,
.flightInfo__dates-gFSrf > span:last-child {
	text-align: right;
}

.flightInfo__time-O9jZT {
	display: flex;
	justify-content: space-between;

	line-height: 150%;
	font-weight: 500;
}

.flightInfo__weather-37nbI {
	display: flex;
	justify-content: space-between;
	margin-bottom: 8px;
}

.weather-29lq2 {
	display: flex;
	align-items: center;

	font-size: 14px;
	line-height: 16px;
}

.weather__icon-Z5Jtp {
	display: flex;
	align-items: center;
	margin-right: 6px;
}

.fareGroup-2gU34 {
	display: flex;
	align-items: center;
	padding: 10px 20px;

	border-top: 1px dashed rgba(255, 255, 255, 0.4);
	border-bottom: 1px dashed rgba(255, 255, 255, 0.4);
}

.fareGroup__img-27nlY {
	height: 36px;
	width: 36px;
	margin-right: 18px;

	border-radius: 2px;

	background-position: center;
	background-size: cover;
}

.fareGroup__info-1lfOD {
	display: flex;
	flex-direction: column;

	font-size: 12px;
	line-height: 150%;
}

.fareGroup__info-1lfOD .flightNumber-2x8M- {
	margin-top: 2px;

	font-size: 16px;
	font-weight: 500;
}

.arrivalInfo-2Tew9 {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 10px 20px 12px;
}

.arrivalInfo__place-SGRlq {
	display: flex;
}

.arrivalInfo__item-1Rgun {
	display: flex;
	flex-direction: column;

	font-size: 20px;
	line-height: 150%;
	font-weight: 500;
}

.arrivalInfo__title-FMBxR {
	margin-bottom: 2px;

	font-size: 12px;
	line-height: 150%;
	font-weight: 400;
}

.arrivalInfo__place-SGRlq .arrivalInfo__item-1Rgun:first-child {
	margin-right: 20px;
}

.mobileSegment__bottom-3Ghbj {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-top: -15px;
	margin-right: -12px;

	color: #727272;
	font-size: 16px;
	line-height: 20px;
}

span.checkbox-2KbaY {
	position: relative;
	top: -1px;
}

span.checkbox_checked-1iVwS.checkbox-2KbaY {
	color: var(--common-blue);
}
