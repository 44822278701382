.container-YOjK2 {
	grid-template-columns: 1fr;
}

.wrapper-1g4Vs {
	display: flex;
	justify-content: flex-start;
}

.toggle-1THL3 {
	margin-bottom: 18px;
}

.toggle-1THL3:before {
	opacity: 1;
}

.toggle-1THL3:after {
	background: #d9d9d9;
}

.toggle-1THL3.toggle_active-neFOC:after {
	background: var(--brand-1-0);
}

.toggle__label-2k4UQ {
	opacity: 1;
}

.label-1QrPJ {
	position: relative;

	color: var(--text-mid);
	font-size: 16px;
	font-weight: 400;
	line-height: 1.3;
}

.description-2lHld {
	position: absolute;

	color: var(--text-light);
	font-size: 14px;
}

label.control-33iyg {
	margin-left: 0;
	margin-right: 0;
}

.control__label-3E0eZ {
	display: flex;
	align-items: center;
}

.input-zQ4GV {
	display: none;
}

.collapse-2Ptnp {
	display: flex;
	align-items: center;

	padding-top: 20px;
}

.collapse__icon-32PLn {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 44px;
	min-width: 44px;
	height: 44px;

	margin-right: 12px;

	border-radius: 50%;

	color: var(--white);

	background: var(--brand-1-0);
}

.collapse__content-LLq3d {
	display: flex;
	flex-direction: column;
	grid-gap: 6px;
	gap: 6px;
}

.collapse__contentInfo-31Iwb {
	display: flex;
	flex-direction: column;
}

.collapse__title-8zqtm {
	display: flex;
	align-items: center;

	color: var(--text-mid);
	font-size: 16px;
	font-weight: 400;
	line-height: 1.3;
}

.collapse__titleWrapper-1Dvx5 {
	display: flex;
	align-items: center;
}

.collapse__titleIcon-2mmer {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 20px;
	height: 20px;

	margin-right: 4px;

	color: var(--success-icon);

	transform: translateY(-2px);
}

.collapse__format-h7teB {
	color: var(--text-light);
	font-size: 13px;
	font-weight: 400;
	line-height: 1.3;
}

.collapse__error-1RmNT {
	color: var(--error-icon);
	font-size: 14px;
}

.collapse__files-3yb-a {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	grid-gap: 6px;
	gap: 6px;
}

.collapse__filesItem-2gVrT {
	display: flex;
	grid-gap: 8px;
	gap: 8px;
	align-items: center;

	height: 26px;

	padding: 0 12px;

	border-radius: 40px;

	font-weight: 500;

	font-size: 14px;

	background: var(--brand-1-5);
}

.collapse__filesItem-2gVrT span {
	max-width: 140px;

	color: var(--brand-1-0);
	line-height: 26px;

	white-space: nowrap;
	text-overflow: ellipsis;

	overflow: hidden;
}

.collapse__filesItemSize-2iuTh {
	margin-bottom: -2px;

	color: var(--text-light);
	font-weight: 400;
	font-size: 13px;
	line-height: 1;
}

.collapse__filesItemIcon-3g-ax {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 16px;
	height: 16px;

	color: var(--text-base);
}

@media (max-width: 1024px) {
	.container-YOjK2 {
		grid-gap: 0;
		gap: 0;
		grid-auto-rows: auto;
		grid-column: 1/3;
	}

	.description-2lHld {
		margin-top: 2px;
	}
}

@media screen and (max-width: 600px) {
	.container-YOjK2 {
		grid-column: unset;
	}

	.toggle-1THL3 {
		padding-right: 0;
	}

	.label-1QrPJ {
		margin-bottom: 30px;
	}

	.collapse-2Ptnp {
		padding-top: 8px;
	}

	.collapse__icon-32PLn {
		margin-right: 16px;
	}

	.collapse__title-8zqtm {
		flex-direction: column;
		align-items: unset;

		font-size: 15px;
	}
}
