

div.container-2nh6t {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 32px 40px;

	color: var(--text);
}

.container-2nh6t h1 {
	margin: 12px 0;

	font-size: 24px;
}

.container-2nh6t h2 {
	font-size: 18px;
}

.table-3-HrJ {
	border-spacing: 20px 10px;
}

.table-3-HrJ thead {
	font-size: 13px;
	color: var(--text-light);
}

.table-3-HrJ th {
	font-weight: 400;
}

.table-3-HrJ th:nth-child(2) {
	color: var(--brand-3-0);
}

.table-3-HrJ th:nth-child(3) {
	color: var(--light-violet);
}

.table-3-HrJ th:nth-child(1) {
	text-align: left;
}

.table-3-HrJ th:nth-child(2),
.table-3-HrJ th:nth-child(3) {
	text-align: center;
}

.buttons-2rhBa {
	display: flex;
	flex-direction: column;
	grid-gap: 12px;
	gap: 12px;
	margin-top: 36px;
}

.buttons-2rhBa button {
	height: 48px;
	padding: 0 24px;

	border-radius: 6px;
}

.buttons-2rhBa button:hover {
	opacity: 0.9;
}

button.buttons__continue-3zZmS,
button.buttons__continue-3zZmS:hover {
	background-color: var(--brand-3-0);
}

button.buttons__add-2DcTl,
button.buttons__add-2DcTl:hover {
	background-color: var(--light-violet);
}

button.buttons__reselect-3Gd8G,
button.buttons__reselect-3Gd8G:hover {
	color: var(--text-light);

	background-color: var(--bg-fill);
}

.description-LLPfC {
	margin-bottom: 36px;

	color: var(--text-light);
	font-size: 18px;
	text-align: center;
}
