.root-2iqki {
	padding-top: 20px;

	border-top: 1px dashed var(--gray20);
}

.root-2iqki:last-child {
	padding-bottom: 20px;

	border-bottom: 1px dashed var(--gray20);
}

.root-2iqki + .root-2iqki {
	margin-top: 20px;
}

.disabled-2u3AG {
	opacity: 0.7;
}

.name_wrapper-zPRHW {
	display: flex;
	align-items: center;
	flex: 1;
	max-width: 33%;
}

.counter_wrapper-iDuDl,
.checkbox_wrapper-3v2_G {
	display: flex;
	align-items: center;
	justify-content: center;
	flex: 1;
}

.counter_wrapper-iDuDl.equipment-34rmp {
	flex: 0 1 auto;
	margin-left: auto;
}

.number-3Ffir {
	padding: 5px 8px;

	border-radius: 50%;

	font-weight: 500;
	font-size: 14px;
	line-height: 1;
	color: var(--gray50);

	background: var(--gray05);
}

.name-5sFqg {
	margin-left: 14px;

	font-weight: 500;
	font-size: 14px;
	line-height: 1.4;
	color: var(--gray80);
}

.row-Oymy2 {
	display: flex;
	grid-gap: 10px;
	gap: 10px;
}

.baggages-2LA_q {
	display: flex;
	flex: 1;

	grid-gap: 12px;

	gap: 12px;
}

.baggages-2LA_q:not(:last-child) {
	margin-right: 12px;
}

span.checkbox_root-2BUH9.checkbox_rootChecked-1KwSb {
	color: var(--base);
}

@media screen and (max-width: 600px) {
	.root-2iqki {
		padding-top: 25px;
	}

	.root-2iqki:last-child {
		padding-bottom: 25px;
	}

	.root-2iqki + .root-2iqki {
		margin-top: 25px;
	}

	.row-Oymy2 {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.name_wrapper-zPRHW,
	.radio_wrapper-1ndru {
		max-width: none;

		border-right: none;
	}

	.counter_wrapper-iDuDl,
	.checkbox_wrapper-3v2_G {
		justify-content: flex-end;
	}
}
