.legends-VwmFG {
	position: absolute;
	right: 0;
	bottom: 43px;
	left: 76px;

	height: 100%;
}

.money-d-yon {
	white-space: nowrap;
}

@media (max-width: 1024px) {
	.legends-VwmFG {
		bottom: 2px;
		left: 0;
	}
}

@media screen and (max-width: 600px) {
	.legends-VwmFG {
		bottom: 0;
	}
}

.legendsWrapper-2Q9EX {
	position: relative;

	height: 100%;
}

.line-3w7U- {
	position: absolute;
	bottom: 39%;

	width: 100%;
	height: 1px;

	border-top: 1px dashed var(--line-separator);
}

.label-3sE9_ {
	position: absolute;
	top: -9px;
	right: 100%;

	padding: 0 8px;

	color: var(--text-light);
	font-size: 12px;

	transform: translateX(-15%);
}

.line-3w7U- .money-d-yon {
	color: var(--text-light);

	font-weight: 500;
	font-size: 12px;
	line-height: 1.3;

	background: var(--bg-fill);
}

.line_min-13bIk .money-d-yon {
	color: var(--success-20);
}

.line_min-13bIk .money-d-yon,
.line_max-lhFQ7 .money-d-yon {
	padding: 2px 4px;

	border-radius: 20px;
}

.line_min-13bIk .label-3sE9_,
.line_max-lhFQ7 .label-3sE9_ {
	padding: 0;
}

@media (max-width: 1024px) {
	.label__text-1XKNA {
		display: none;
	}

	.line_max-lhFQ7 .money-d-yon {
		color: var(--text-light);
	}

	.line_min-13bIk .money-d-yon {
		color: var(--pricegraph-min-price);

		background: var(--brand-3-5);
	}
}

.line_bottom-2ZrUN {
	position: absolute;
	bottom: 1px;

	width: 100%;
	height: 1px;

	border-top: 1px solid var(--line-separator);
}

.zero-1SYE7 {
	bottom: 0;
}

.zero-1SYE7,
.average-1Lu8o,
.max-2Gpht {
	position: absolute;
}
