div.dialogRoot-h8GXB {
	z-index: 1600 !important;
}

.header-DS0xu {
	padding: 28px;

	color: #ffffff;
	font-weight: 600;
	font-size: 24px;

	background: var(--brand-3-0);
}

.content-1KLeG {
	display: flex;
	flex-direction: column;
	padding: 32px 32px 32px 32px;

	background: var(--bg-fill);
}

.checkin__status-OkKRR {
	margin-bottom: 10px;

	color: var(--text-base);

	font-size: 16px;
}

.button-1M9Y0,
button.button-1M9Y0 {
	margin-top: 20px;
	margin-left: auto;
}

@media screen and (max-width: 600px) {
	div.root-4Y8DP {
		top: 0;

		width: 100%;

		transform: none;
	}

	.checkin__status-OkKRR {
		padding: 0 20px;
	}

	.content-1KLeG {
		height: calc(100% - 53px); /* header height */
		padding: 20px 0 20px;
	}

	.header-DS0xu {
		padding: 14px 18px;

		font-size: 20px;
		line-height: 25px;
	}

	.button-1M9Y0,
	button.button-1M9Y0 {
		margin: auto auto 0;
	}

	button.button-1M9Y0 {
		width: 100%;
		min-height: 60px;

		border-radius: 0;

		background: var(--common-orange);
	}

	button.button-1M9Y0[disabled] {
		background: var(--bg-elements) !important;
	}

	button.button-1M9Y0:hover {
		background: var(--dark-orange);
	}
}
