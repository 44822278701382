.wrapper-2MGBo {
	border-radius: 8px;
	overflow: hidden;

	background-color: #ffffff;
}

.wrapper_mobileFullScreen-1Awme {
	border-radius: inherit;
}

@media screen and (max-width: 600px) {
	.wrapper-2MGBo {
		border-radius: 8px 8px 0 0;
		overflow: hidden;
	}

	.wrapper_mobileFullScreen-1Awme {
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: space-between;

		border-radius: 0;
	}
}

.footer-FEYyA {
	display: flex;
	justify-content: flex-end;
	padding: 30px 40px 40px;
}

@media screen and (max-width: 600px) {
	.footer-FEYyA {
		padding: 30px 20px 12px;
	}
}

button.button-35qOX {
	width: 163px;
	padding-right: 10px;
	padding-left: 10px;

	font-weight: 500;
}

button.button-35qOX:not(:last-of-type) {
	margin-right: 15px;
}

@media screen and (max-width: 600px) {
	button.button-35qOX {
		flex-grow: 1;
	}

	button.button-35qOX:not(:last-of-type) {
		margin-right: 8px;
	}
}

@media screen and (max-width: 320px) {
	button.button-35qOX {
		font-size: 13px;
	}
}

button.button_cancel-3uNnv {
	color: #727272;

	background-color: #f0f0f0;

	transition: color 0.15s, background-color 0.15s;
}

button.button_cancel-3uNnv:hover {
	color: #414141;

	background-color: #f0f0f0;
}
