

.content-1Oi4l {
	display: flex;
	flex-direction: column;
	padding-bottom: 65px;
	overflow-y: auto;
}

.schedule-qlVvI {
	padding: 20px;

	border-bottom: 1px solid #e4e4e4;
}

.schedule-qlVvI a {
	display: flex;
	align-items: center;

	font-weight: 500;
	font-size: 15px;
	line-height: 22px;
	text-decoration: none;
}

.schedule-qlVvI a > svg {
	height: 20px;
	width: 20px;
	margin-left: 4px;
}

.service-XQLQR {
	padding: 27px 20px 20px;
}

.service-XQLQR:not(:last-child) {
	border-bottom: 1px solid #e4e4e4;
}

.service__header-32VVD {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 12px;
}

.service__date-2nPrZ {
	font-weight: 600;
	font-size: 17px;
	line-height: 25px;
	color: #292929;
}

.service__header-32VVD .service__tickets-4EZWy {
	display: flex;
	align-items: center;
	padding: 0 8px 0 16px;

	border-radius: 24px;

	color: #ffffff;
	font-weight: 500;
	line-height: unset;

	background: var(--common-blue);
}

.service__header-32VVD .service__tickets-4EZWy:hover {
	background: var(--common-blue);
}

.service__tickets-4EZWy.service__tickets_selected-1loFX {
	color: #292929;

	background: #f0f0f0;
}

.service__header-32VVD .service__tickets_loading-4AUYq {
	padding: unset;
}

.service__tickets-4EZWy.service__tickets_selected-1loFX:hover {
	background: #f0f0f0;
}

.service__info-1pCHA {
	display: flex;
	flex-direction: column;
}

.service__info-1pCHA > div {
	font-family: inherit;
}

.service__info-1pCHA > div svg {
	margin-right: 8px;
}

.service__info-1pCHA > div:not(:last-child) {
	margin-bottom: 12px;
}

.service__direction-1uDfg {
	margin-bottom: 12px;
}

div.service__class-2Oq5b {
	text-transform: capitalize;
}

div.service__direction-1uDfg,
div.service__class-2Oq5b {
	padding: 12px 30px 12px 12px;

	border-radius: 4px;

	background: #f0f0f0;
}

.icon-JLYOv {
	display: inline-block;

	height: 24px;
	margin-left: 8px;
}

.icon-JLYOv.button_close-3TFNq {
	transform: rotate(45deg);

	fill: #9a9a9a;
}

.icon-JLYOv.button_add-2npOb {
	fill: #ffffff;
}

.icon-JLYOv.button_download-3uG5d {
	transform: rotate(90deg) scale(0.7) translate(0, 1px);
}

.footer-Bo1ER {
	position: fixed;
	bottom: 0;

	width: 100%;

	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 11px 20px;
	height: 48px;

	background: var(--common-orange);
}

span.footer__clearButton-xqK46,
span.footer__doneButton-2gKlQ {
	color: #ffffff;
	font-size: 17px;
	line-height: 25px;
	font-weight: 600;

	background-color: transparent;

	cursor: pointer;
}

.footer-Bo1ER span.footer__clearButton-xqK46 {
	font-weight: 400;
}
