.meal-zOabP {
	position: relative;

	height: 376px;

	display: flex;
	flex-direction: column;
	justify-content: space-between;

	padding-bottom: 28px;

	border-radius: var(--baggage-additional-wrapped-border-radius);

	background: #ffffff;
	box-shadow: var(--meal-box-shadow);

	cursor: pointer;

	transition: box-shadow 0.2s;
}

.meal-zOabP:after {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;

	content: '';

	border-radius: var(--baggage-additional-wrapped-border-radius);
	border: var(--meal-selected-border);

	transition: border 0.2s;

	pointer-events: none;
}

.meal-zOabP:hover {
	box-shadow: var(--meal-box-shadow-active);
}

.meal-zOabP:hover:after {
	border-color: var(--base50);
}

.meal_fullSize-zfMc1 {
	height: 376px;

	flex-direction: row;

	padding-bottom: 0;
}

.header-24da6 {
	position: relative;
	z-index: 1;
}

.meal_fullSize-zfMc1 .header-24da6 {
	position: static;

	flex-basis: 550px;
	flex-grow: 1;
}

.image-12e4u {
	height: 200px;

	border-radius: var(--baggage-additional-wrapped-border-radius) var(--baggage-additional-wrapped-border-radius) 0 0;

	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
}

.meal_fullSize-zfMc1 .image-12e4u {
	height: 100%;
	width: 100%;

	border-radius: var(--baggage-additional-wrapped-border-radius) 0 0 var(--baggage-additional-wrapped-border-radius);
}

.marker-3JTQ6 {
	position: absolute;
	top: 0;
	left: 50%;

	padding: 4px 16px;

	border-radius: 100px;

	color: #ffffff;
	font-weight: 600;
	font-size: 16px;
	line-height: 150%;

	background-color: var(--error);

	transform: translate(-50%, -50%);
}

.meal_fullSize-zfMc1 .marker-3JTQ6 {
	position: static;

	display: inline-block;

	transform: none;
}

.title-94Qpm {
	margin-top: 20px;
	padding: 0 16px;

	text-align: center;
}

.meal_fullSize-zfMc1 .title-94Qpm {
	padding: 0;

	text-align: left;
}

.name-41zlr {
	color: var(--gray80);
	font-weight: 600;
	font-size: 16px;
	line-height: 130%;
	text-transform: uppercase;
}

.meal_fullSize-zfMc1 .name-41zlr {
	font-size: 27px;
}

.weight-1TB3y {
	margin-top: 4px;

	color: var(--gray50);
	font-weight: 500;
	font-size: 16px;
	line-height: 130%;
}

/* stylelint-disable block-no-empty */
.hasWeight-1f_JN {
}

/* stylelint-enable */
.meal_fullSize-zfMc1 .weight-1TB3y {
	margin-top: 20px;
}

.footer-D0ZOH {
	margin-top: 20px;
}

.meal_fullSize-zfMc1 .footer-D0ZOH {
	margin-top: 0;
	padding: 50px 40px 40px;
}

.footer__icons-RIy3a {
	position: relative;

	display: flex;
	justify-content: center;
	align-items: center;
}

.meal_fullSize-zfMc1 .footer__icons-RIy3a {
	margin-top: 40px;

	justify-content: flex-start;
}

.price-12LF5 {
	padding: 4px 22px;

	border-radius: 100px;

	font-weight: 600;
	font-size: 22px;
	line-height: 150%;
	color: var(--base-30);

	background-color: var(--base90);

	transition: background-color 0.3s, color 0.3s;
}

.meal-zOabP:hover .price-12LF5 {
	color: #ffffff;

	background-color: var(--base);
}

.oldPrice-AiRPK {
	margin-right: 10px;
	padding-left: 0;

	font-weight: 600;
	font-size: 20px;
	line-height: 150%;

	color: var(--error-icon);
	text-decoration: line-through;
}

.count-3AItq {
	display: flex;
	align-items: center;
	justify-content: center;

	margin-left: 10px;

	width: 32px;
	height: 32px;

	border-radius: 50%;

	color: #ffffff;
	font-weight: 600;
	font-size: 16px;

	background-color: var(--success);
}

div.popup__root-3NKy8 {
	z-index: 1650 !important;
}

div.popup-3ZHkd {
	width: 100%;

	border-radius: var(--baggage-additional-wrapped-border-radius);
}

.popup__close-3jpFL {
	position: absolute;
	right: 20px;
	top: 20px;
	z-index: 1;
}

@media screen and (max-width: 600px) {
	div.popup__scrollPaper-3sp1Y {
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
	}

	div.popup__scrollPaper-3sp1Y div.popup-3ZHkd {
		min-height: auto;

		border-radius: 16px 16px 0 0;
		overflow: hidden;
	}
}
