.wrapper-dhYB2 {
	display: flex;
	align-items: center;
}

.wrapper__inner-1mqzB {
	flex: 1;
	display: flex;
	align-items: center;
}

.image-1JGoI {
	width: 40px;
	height: 40px;
	margin-right: 14px;
}

.icon-2J8Jf {
	display: flex;
	justify-content: center;
	align-items: flex-start;
	margin-right: 14px;

	color: var(--gray50);
}

.content-3GLce {
	display: flex;
	flex-direction: column;

	overflow-wrap: break-word;

	color: var(--gray70);
}

.title-1KuIX {
	margin-bottom: 2px;

	font-size: 16px;
	font-weight: 500;
	line-height: 1.3;
}

.title-1KuIX:only-child {
	margin-bottom: 0;
}

.wrapper-dhYB2.context-xSQpX {
	padding: 12px;

	border: 1px solid #add3ff;
	border-radius: 4px;

	background-color: var(--base90);
}

.wrapper-dhYB2.global-CfzFY {
	padding: 18px;
	margin-top: 12px;

	background-color: var(--white);
}

.context-xSQpX.info-16bCO,
div.global-CfzFY.info-16bCO {
	background-color: #fffdcc;
}

.context-xSQpX.info-16bCO {
	border: 1px solid #efea6c;
}

.context-xSQpX.success-1SEXa {
	border: 1px solid var(--success60);

	background-color: #e7f7e3;
}

div.global-CfzFY.success-1SEXa {
	background-color: var(--success);
}

.context-xSQpX.warning-9LEun {
	border: 1px solid var(--error-stroke-hover);

	background-color: var(--error-bg);
}

div.global-CfzFY.warning-9LEun {
	background-color: var(--error);
}

.context-xSQpX .text-2ZHjx {
	font-size: 14px;
	line-height: 1.3;
}

.context-xSQpX .text-2ZHjx p,
.global-CfzFY .text-2ZHjx p {
	margin: 0;
}

.context-xSQpX .text-2ZHjx a,
.global-CfzFY .text-2ZHjx a {
	color: var(--base);
	text-decoration: underline;
}

.global-CfzFY .text-2ZHjx {
	font-size: 18px;
	font-weight: 500;
	line-height: 1.3;
}

.context-xSQpX.warning-9LEun .title-1KuIX,
.context-xSQpX.success-1SEXa .text-2ZHjx {
	color: var(--success-text);
}

.context-xSQpX.success-1SEXa .icon-2J8Jf {
	color: var(--success-icon);
}

.context-xSQpX.warning-9LEun .title-1KuIX,
.context-xSQpX.warning-9LEun .text-2ZHjx {
	color: var(--error-text);
}

.context-xSQpX.warning-9LEun .icon-2J8Jf {
	color: var(--error-icon);
}

.global-CfzFY.success-1SEXa .content-3GLce,
.global-CfzFY.success-1SEXa .icon-2J8Jf,
.global-CfzFY.warning-9LEun .content-3GLce,
.global-CfzFY.warning-9LEun .icon-2J8Jf {
	color: var(--white);
}

button.action-2w_df {
	margin-left: auto;
}

button.action-2w_df:hover {
	border: 1px solid #add3ff;

	background-color: var(--gray05);
}

button.action-2w_df > svg {
	margin-left: 6px;
}

@media screen and (max-width: 600px) {
	.wrapper-dhYB2 {
		align-items: flex-start;
		flex-direction: column;
		grid-gap: 14px;
		gap: 14px;
	}

	.wrapper-dhYB2.global-CfzFY {
		border: 1px solid #add3ff;
		border-radius: 4px;

		background-color: var(--base90);
	}

	div.global-CfzFY:not(.info-16bCO, .success-1SEXa, .warning-9LEun) .icon-2J8Jf {
		color: var(--selected);
	}

	div.global-CfzFY.info-16bCO {
		border: 1px solid #efea6c;
	}

	button.action-2w_df {
		margin-left: 0;
	}
}
