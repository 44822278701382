

.container-3JyZp {
	padding: 32px;
	max-width: 594px;
}

.header-2c4zA {
	font-size: 24px;
	font-weight: 400;
	line-height: 40px;
	color: var(--gray80);
}

.items-3NvSA {
	margin-top: 20px;
}

.item-3K1sn {
	display: flex;
	margin-bottom: 20px;
	align-items: center;
}

.item__controls-3Be-t {
	display: flex;
	flex: 1;
}

.item__controls-3Be-t input {
	width: 100%;
	height: var(--input-height);
	margin-right: 12px;
	padding: 11px 12px;

	border: 1px solid var(--gray05);

	color: var(--gray90);
	font-size: 17px;

	background: var(--gray05);
}

.autocomplete-3sgLG {
	flex: 1;
	margin-right: 12px;
}

.value-3Iqhg {
	position: relative;

	flex: 1;

	height: var(--input-height);
	margin-right: 12px;

	border: 1px solid var(--gray05);

	color: var(--gray90);
	font-size: 17px;

	background: var(--gray05);

	cursor: pointer;
}

.value_focused-QIVTU {
	border-color: var(--base);
}

.value__dummy-2pqo6 {
	position: absolute;

	display: flex;
	align-items: center;
	width: 100%;
	height: 100%;
	padding: 11px 12px;

	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
}

.value_empty-11I5C {
	color: var(--gray50);
	text-overflow: ellipsis;
	overflow: hidden;
}

.value-3Iqhg input {
	width: 100%;
}

.value__code-3nxAl {
	position: absolute;
	top: 15px;
	right: 16px;

	color: var(--gray50);
	font-size: 17px;
}

.option-2OcDE {
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 48px;
	padding: 0 10px;

	border-bottom: 1px solid var(--gray10);

	color: var(--gray90);
	font-size: 16px;
	white-space: nowrap;

	cursor: pointer;
}

.code-1JPGL {
	color: var(--gray50);
}

input.count-G-oQN {
	width: 151px;

	font-size: 26px;
	font-weight: 500;
}

button.control-1LyeQ {
	display: flex;

	flex-shrink: 0;
	justify-content: center;
	align-items: center;
	width: 36px;
	height: 36px;

	border-radius: 50%;

	color: var(--base);

	background: var(--base80);
}

button.control-1LyeQ svg {
	width: 20px;
	height: 20px;
}

.controls-33_Yp {
	display: flex;
	align-items: center;
	justify-content: flex-end;
}

.controls-33_Yp button:not(:last-child) {
	margin-right: 8px;
}

/* stylelint-disable-next-line block-no-empty */
button.clear-zMAnD {
}

/* stylelint-disable-next-line block-no-empty */
button.done-TwU4o {
}
