

label.label-C0xo2 {
	z-index: 1000;

	margin-right: 0;

	color: var(--gray80);
	line-height: 24px;
}

.label-C0xo2.active-16zW2,
span.radio-2bQ-o.active-16zW2 {
	color: var(--base);
}

span.radio-2bQ-o:hover {
	background: rgba(50, 144, 255, 0.1);
}
