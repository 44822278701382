

.wrapper-2hkAS {
	margin-top: 12px;
	padding: 20px 28px;

	display: flex;
	align-items: center;
	justify-content: space-between;

	border-radius: 0 0 4px 4px;

	background-color: #f9f9f9;
}

.text-EuE9q {
	font-weight: 500;
	font-size: 16px;
}

.signIn-2Aqo_ {
	display: flex;
	align-items: center;
}

.signIn__skip-zDBrn {
	margin-right: 16px;

	font-size: 16px;
}
