.unreadDot-LLbhH {
	display: block;
	width: 8px;
	height: 8px;

	border-radius: 50%;

	background-color: var(--brand-1-0);
	cursor: pointer;
}
