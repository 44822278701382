div.paper-3ksgn {
	max-width: max-content;
}

div.modal-2p3ns {
	padding: 40px;
}

@media screen and (max-width: 600px) {
	div.modal-2p3ns {
		padding: 20px;
	}
}
