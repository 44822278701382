div.root-3_GyB {
	background: var(--bg-canvas);
	box-shadow: none;
}

div.root-3_GyB:before {
	content: none;
}

div.root-3_GyB:not(:last-of-type) {
	margin-bottom: 24px;
}

.root-3_GyB .summary-3wr-l {
	padding: 24px 64px;
}

.root-3_GyB .summary-3wr-l > div {
	margin: 0;
}

.root-3_GyB .details-sSdEq {
	padding: 0 64px 24px;
}

.root-3_GyB .heading-1kF1Q {
	font-weight: 600;
	font-size: 23px;
	line-height: 30px;
}

.root-3_GyB .details-sSdEq,
.root-3_GyB .heading-1kF1Q {
	color: var(--fg-default);
}

.root-3_GyB .expandIcon-2ywVd svg {
	transform: scale(1.3);
}

.root-3_GyB .expandIcon-2ywVd svg path {
	fill: var(--accent-default);
}

@media screen and (max-width: 600px) {
	.root-3_GyB .summary-3wr-l {
		padding: 20px 24px;
	}

	.root-3_GyB .details-sSdEq {
		padding: 0 20px 24px;
	}

	.root-3_GyB .heading-1kF1Q {
		font-size: 20px;
	}
}
