

.sectionWrapper-3DXs2 {
	display: flex;
	flex-direction: column;
	grid-gap: 24px;
	gap: 24px;
}

.sectionWrapper__title-ERtvR {
	color: var(--text);
	font-size: 18px;
	font-weight: 700;
	line-height: 23px;
	word-wrap: break-word;
}

.sectionWrapper__content-3O1aq {
	display: flex;
	flex-direction: column;
	grid-gap: 24px;
	gap: 24px;
}
