.item-2utew {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 8px;
	padding: 12px 8px;

	border-radius: 4px;

	color: var(--gray80);

	box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.04);
	cursor: pointer;
	background: #ffffff;
}

.item_withSeat-3v_9j {
	position: relative;
}

.freeSeat__text-qRU0c {
	margin-left: 8px;

	max-width: 60px;
}

.clear-2H9tw {
	transform: scale(0.9) translateY(2px);
}

.item_recommend-1a3r4 {
	color: #ffffff;

	box-shadow: none;
	background: var(--selected);
}

.item_disabled-2Aehp {
	opacity: 0.6;

	cursor: inherit;
}

.avatar-2I3lf {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 24px;
	height: 24px;
	margin-right: 10px;

	flex-shrink: 0;

	border-radius: 50%;

	color: var(--gray50);
	font-weight: 500;
	font-size: 14px;

	background: var(--gray05);
}

.item_recommend-1a3r4 .avatar-2I3lf {
	color: #ffffff;

	background: rgba(255, 255, 255, 0.2);
}

.main-2zmAA {
	display: flex;
	align-items: center;
}

.passengerName-vx3c0 {
	max-width: 140px;

	font-weight: 500;
}

.freeSeat__text-qRU0c,
.passengerName-vx3c0 {
	overflow: hidden;

	text-overflow: ellipsis;
}

.item-2utew.item_active-1a4Xz {
	color: #ffffff;

	background: var(--selected);
}

.item-2utew.item_recommend-1a3r4.item_active-1a4Xz {
	background: var(--selected2);
}

.item_active-1a4Xz .avatar-2I3lf {
	color: var(--selected);

	background: #ffffff;
}

.item-2utew.item_recommend-1a3r4.item_active-1a4Xz .avatar-2I3lf {
	color: var(--selected2);
}

.payload-37KdP {
	display: flex;
	align-items: center;
	grid-gap: 4px;
	gap: 4px;

	font-weight: 400;
	font-size: 15px;
}

.payload-37KdP svg {
	color: var(--gray30);
}

.numbers-s7YGw {
	white-space: nowrap;
}

.select-3FZKk {
	color: var(--gray30);
}

.money-3YNt6 {
	color: var(--base);
}

.item-2utew.item_recommend-1a3r4 .money-3YNt6,
.item-2utew.item_recommend-1a3r4 .select-3FZKk,
.item-2utew.item_recommend-1a3r4 svg,
.item-2utew.item_active-1a4Xz .money-3YNt6,
.item-2utew.item_active-1a4Xz .select-3FZKk,
.item-2utew.item_active-1a4Xz svg {
	color: #ffffff;
}

button.select-3FZKk {
	background: var(--base);
}

button.selectMobile-25IPm {
	position: relative;
}

button.selectMobile_selected-3ERw6 {
	border: 1px solid var(--active);

	color: var(--active);

	background: #ffffff;
}

/* stylelint-disable */
.seatPrice-18skb {
}
/* stylelint-enable */
@media (max-width: 768px) {
	.item-2utew {
		height: 68px;
		padding: 0;

		border-bottom: 1px solid var(--gray10);
		border-radius: 0;

		box-shadow: none;
		background: none;
	}
}

@media screen and (max-width: 600px) {
	.passengerName-vx3c0,
	.payload-37KdP {
		font-size: 14px;
	}
}
