

.message-__Okl {
	box-shadow: 4px 4px 33px 0 rgba(0, 0, 0, 0.05);
}

.message-__Okl:not(:last-child) {
	margin-bottom: 20px;
}

.message_context-3vdEG .message__title-3A26G {
	margin-bottom: 2px;

	font-weight: 700;
	font-size: 16px;
	line-height: 1.3;
}

.message_context-3vdEG .message__text-3JGx5 {
	font-size: 14px;
	font-weight: 400;
	line-height: 1.3;
}

.message_info-2zewB .message__icon-1cqk8 {
	color: var(--text-pale);
}

.message_info-2zewB .message__title-3A26G {
	color: var(--text-base);
}

.message_info-2zewB .message__text-3JGx5 {
	color: var(--text-mid);
}

.message_context-3vdEG.message_warning-3qI8h .message__icon-1cqk8 {
	color: var(--error-icon);
}

.message_context-3vdEG.message_warning-3qI8h .message__title-3A26G,
.message_context-3vdEG.message_warning-3qI8h .message__text-3JGx5 {
	color: var(--error-text);
}
