.root-eubJM {
	display: flex;
	align-items: center;
	width: 100%;
}

.root-eubJM.type_account-2jFmB {
	color: var(--gray70);
	font-size: 15px;
	font-weight: 500;
}

.icon-3G79W {
	color: #b3b3b3;
}

.root-eubJM.type_account-2jFmB .icon-3G79W {
	color: var(--success);
}

.root-eubJM.type_review-F4DrQ .orderCode-261wv {
	font-weight: 500;
	font-size: 14px;
}

.root-eubJM.type_review-F4DrQ .price-13KM-,
.root-eubJM.type_review-F4DrQ .security_code-36pJv {
	margin-left: 12px;
}

.root-eubJM.type_review-F4DrQ .security_code-36pJv .value-3U9D1,
.root-eubJM.type_review-F4DrQ .orderCode-261wv .value-3U9D1 {
	display: inline-flex;
}

.download_wrapper-BKNFe {
	margin-left: auto;
}

button.download-3517d,
button.download-3517d:hover {
	display: inline-flex;
	align-items: center;

	border: 1px solid var(--brand1-3);

	color: var(--base);

	background: var(--brand1-5);
}

button.download-3517d svg {
	margin-left: 8px;
}

@media (max-width: 768px) {
	.root-eubJM.type_review-F4DrQ span.security_code-36pJv,
	.root-eubJM.type_review-F4DrQ span.price-13KM- {
		display: block;
		margin: 0;
		padding-top: 6px;

		font-weight: 400;
	}
}
