.container-3eqyn {
	display: flex;
	min-height: 200px;
}

.root-1RRSu {
	position: relative;

	display: flex;
	flex: 1;
	flex-direction: column;

	color: var(--gray70);
	font-weight: 400;

	background: var(--white);
}

.container-3eqyn.type_account-3lPb4 {
	margin-bottom: 12px;
}

.container-3eqyn.type_review-A8T42 .root-1RRSu {
	overflow: hidden;

	font-size: 16px;
	line-height: 1.3;
}

.container-3eqyn.type_review-A8T42 button {
	height: 33px;
	margin-top: 12px;
	margin-bottom: 12px;

	line-height: 33px;
}

.container-3eqyn.type_account-3lPb4 .root-1RRSu {
	border-radius: 0 6px 6px 0;

	font-size: 16px;
	line-height: 1.3;

	box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.04);
	overflow: hidden;
}

.preview-3_Cgp {
	width: var(--order-card-preview-width);
}

.container-3eqyn.type_account-3lPb4 .preview-3_Cgp,
.container-3eqyn.type_account-3lPb4 .preview-3_Cgp > div {
	border-radius: 6px 0 0 6px;
}

.status-9fTWy,
.booking-2z__e,
.tickets-SEbsn,
.passengers-37bzW {
	min-width: 0;
	padding: 0 20px;
}

.item-2PmbR {
	display: flex;
	align-items: center;
	min-height: 44px;
}

.item-2PmbR:not(:first-child) {
	border-top: 1px solid #e3e3e3;
}

.status-9fTWy {
	grid-area: status;
}

.booking-2z__e {
	grid-area: booking;
}

.passengers-37bzW {
	grid-area: passengers;
	padding-top: 12px;
	padding-bottom: 12px;
}

.type_review-A8T42 .passengers-37bzW {
	flex: 1;
}

.controls-2YndM {
	margin-top: 24px;
	display: flex;
	justify-content: flex-end;
}

.buttons-18znT {
	flex: 1;
	padding: 16px 12px;
	display: flex;
	align-items: flex-end;
	justify-content: flex-end;
	grid-gap: 16px;
	gap: 16px;

	border-top: 1px solid #e3e3e3;
}

button.openOrder-zYxrT,
button.openOrder-zYxrT:hover {
	color: var(--base);
	text-align: center;
	font-size: 14px;
	font-weight: 700;

	background: var(--brand1-4);
}

/* stylelint-disable */
.orderConfirmed-3k9Ck {
}

/* stylelint-enable */
@media (max-width: 768px) {
	.container-3eqyn {
		flex-direction: column;
	}

	.preview-3_Cgp {
		width: 100%;
		height: 200px;
	}

	.container-3eqyn.type_review-A8T42 .root-1RRSu {
		overflow: visible;
	}

	.container-3eqyn.type_review-A8T42 .root-1RRSu:after {
		position: absolute;
		top: 2px;
		right: 0;
		left: 0;
		z-index: 10;

		height: 14px;

		border-radius: 12px 12px 0 0;

		transform: translateY(-100%);
		background: #ffffff;
		content: '';
	}

	.container-3eqyn.type_account-3lPb4 {
		border-radius: 8px;
	}

	.container-3eqyn.type_account-3lPb4 .root-1RRSu {
		border-radius: 0 0 8px 8px;
	}

	.container-3eqyn.type_account-3lPb4 .preview-3_Cgp {
		border-radius: 8px 8px 0 0;
	}

	.buttons-18znT {
		align-items: center;
		justify-content: space-between;
		grid-gap: 16px;
		gap: 16px;

		border-top: 1px solid #e3e3e3;
	}
}
