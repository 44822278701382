

li.autocomplete__option-wASNH {
	justify-content: flex-start;
	padding: 12px 16px;

	font-weight: 500;
	font-size: 16px;
}

li.autocomplete__option-wASNH:not(:last-child) {
	border-bottom: 1px solid #eeeeee;
}
