div.price__popup-29b5J {
	overflow: visible;

	border-radius: 8px;
}

.price__popup-29b5J .close-1RBV2 {
	position: absolute;
	top: -26px;
	right: -26px;

	width: 24px;
	height: 24px;

	cursor: pointer;
	background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzIiIGhlaWdodD0iMzIiIHZpZXdCb3g9IjAgMCAzMiAzMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTI1LjMzMzIgOC41NDY2M0wyMy40NTMyIDYuNjY2NjNMMTUuOTk5OCAxNC4xMkw4LjU0NjUgNi42NjY2M0w2LjY2NjUgOC41NDY2M0wxNC4xMTk4IDE2TDYuNjY2NSAyMy40NTMzTDguNTQ2NSAyNS4zMzMzTDE1Ljk5OTggMTcuODhMMjMuNDUzMiAyNS4zMzMzTDI1LjMzMzIgMjMuNDUzM0wxNy44Nzk4IDE2TDI1LjMzMzIgOC41NDY2M1oiIGZpbGw9IndoaXRlIi8+Cjwvc3ZnPgo=) center no-repeat;
	content: '';
}

@media screen and (max-width: 600px) {
	.price__popup-29b5J .close-1RBV2 {
		top: -38px;
		right: 8px;
	}
}
