.container-1InvG {
	max-width: 1100px;
	margin: 28px auto auto;

	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-gap: 12px;
	gap: 12px;
}

.conditions-1hDXY {
	padding: 32px 32px 42px;

	border-radius: var(--desktop-win-border-radius);

	background: var(--desktop-win-background);
}

.conditionsButton-1k3YN {
	display: flex;
	align-items: center;

	cursor: pointer;
}

.conditionsButtonTitle-wec_j {
	font-size: 16px;

	color: var(--text-light);
}

.conditionsButtonIcon-3ykH0 {
	margin-left: 6px;
	display: flex;

	color: var(--text-pale);
}

.checkin-1KO6W {
	display: flex;
	flex-direction: column;

	padding: 32px;

	border-radius: var(--desktop-win-border-radius);

	background: var(--desktop-win-background);
}

.checkinTitle-2S2Xj {
	margin: 0;

	color: var(--text-base);
	font-weight: 700;
	font-size: 22px;
}

.form-AgFzM {
	display: flex;
	flex-direction: column;

	grid-gap: 20px;

	gap: 20px;
	margin-top: 20px;
}

.form-AgFzM div.input-1dzxf {
	margin-top: 0;
}

/* input */
.input-1dzxf input {
	height: 56px;
	padding: 15px 12px 0;

	color: var(--text-base);
}

div.input-1dzxf input {
	border: none;
}

div.input-1dzxf:before {
	height: 56px;

	border: solid 1px var(--brand-1-3);
	border-radius: 4px;

	background: var(--brand-1-5);
}

div.input-1dzxf.inputError-1e-MC:not(.inputFocused-1pmIH):before {
	border: solid 1px var(--error-icon);

	background: var(--error-bg);
}

label.inputLabel-14U0H {
	padding-right: 40px;

	color: var(--text-light);
	font-size: 16px;

	transform: translate(12px, 19px) scale(1);
}

label.inputFocusedLabel-RPWkR {
	color: var(--brand-1-0);

	transform: translate(12px, 8px) scale(0.8);
}

label.inputShrinkLabel-3V1ge {
	transform: translate(12px, 8px) scale(0.8);
}

label.inputLabel-14U0H.inputErrorLabel-1JU7y {
	color: var(--text-light);
}

/* input-end */
.buttons-1r-u0 {
	display: flex;
	align-self: end;
}

.buttons-1r-u0 button {
	height: 49px;
	padding: 12px 24px;

	border-radius: 6px;

	font-size: 17px;
}

button.submitButton-2GVa9 {
	color: white;

	font-weight: 700;

	background: var(--brand-3-0);
}

button.submitButton-2GVa9:hover,
button.submitButton-2GVa9:focus {
	background: var(--brand-3-1);
}

button.submitButton-2GVa9:disabled {
	background: var(--brand-3-3);
}

.ticketIcon-1Hzbl {
	color: var(--text-pale);

	cursor: pointer;
}

div.helper-3Z7-O {
	max-width: 400px;
}

.notify-8nFlb {
	display: flex;
	align-items: center;

	padding: 12px;

	border: 1px solid var(--error-stroke-hover);
	border-radius: 4px;

	background: var(--error-bg);
}

.notifyIcon-2SXSc {
	margin-right: 14px;

	color: var(--error-icon);
}

.notifyText-eAhHL {
	color: var(--error-text);

	font-size: 14px;
	font-weight: 500;
}

@media screen and (max-width: 600px) {
	.container-1InvG {
		max-width: unset;
		margin: 0;

		display: grid;
		grid-template-columns: 1fr;
		grid-gap: 0;
		gap: 0;
	}

	div.conditionsModalPaper-AloPd {
		border-radius: 0;
	}

	.conditions-1hDXY {
		padding: 0;

		border-radius: 0;
	}

	.checkin-1KO6W {
		display: grid;
		grid-template-columns: 1fr auto;
		padding: 20px;

		border-radius: 12px 12px 0 0;
	}

	.checkinTitle-2S2Xj {
		grid-area: 1 / 1 / 2 / 2;

		font-size: 18px;
	}

	.form-AgFzM {
		grid-area: 2 / 1 / 3 / 3;
	}

	.notify-8nFlb {
		order: -1;
	}

	.notifyIcon-2SXSc {
		margin-right: 12px;
	}

	/* input */
	.input-1dzxf input {
		padding: 16px 8px 0;

		color: var(--text-base);
	}

	div.input-1dzxf input {
		border: none;
	}

	div.input-1dzxf:before {
		height: 56px;

		border: none;
		border-bottom: solid 1px var(--input-def);
		border-radius: 0;

		background: white;
	}

	div.input-1dzxf.inputFocused-1pmIH:before {
		border: none;
		border-bottom: solid 1px var(--input-focus);
		border-radius: 0;

		background: var(--brand-1-5);
	}

	div.input-1dzxf.inputError-1e-MC:not(.inputFocused-1pmIH):before {
		border: none;
		border-bottom: solid 1px var(--error-icon);

		background: var(--error-bg);
	}

	label.inputShrinkLabel-3V1ge {
		transform: translate(8px, 8px) scale(0.8);
	}

	/* input-end */
	.ticketIcon-1Hzbl > svg {
		width: 20px;
		height: 20px;
	}

	.buttons-1r-u0 {
		align-self: stretch;
		justify-content: space-between;
		grid-gap: 12px;
		gap: 12px;
	}

	.buttons-1r-u0 button {
		width: 100%;

		height: 41px;
		padding: 10px 24px;

		border-radius: 4px;

		font-size: 17px;
	}

	button.cancelButton-2sU-J {
		color: var(--text-light);
		font-weight: 400;

		font-size: 16px;

		background: var(--bg-fill);
	}

	button.cancelButton-2sU-J:hover,
	button.cancelButton-2sU-J:focus {
		background: var(--bg-elements);
	}

	button.cancelButton-2sU-J:disabled {
		width: calc(50% - 12px);

		color: var(--text-pale);

		background: var(--bg-fill);
	}
}
