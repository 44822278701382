.status-2xPCf {
	padding: 16px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	max-width: 1100px;
	margin: 12px auto;

	border-radius: 4px;

	font-weight: 500;
	font-size: 20px;
	line-height: 30px;
}
@media screen and (max-width: 600px) {
	.status-2xPCf {
		position: relative;

		padding: 12px 44px 12px 20px;
		margin: 0;
		flex-direction: column;
		align-items: flex-start;
	}
}

.processing-2x6K9 {
	background: var(--warning);
}

.waiting-30Auz {
	background: var(--active-30);
}

.rejected-16gQt {
	background: var(--error);
}

.status-2xPCf.success-2vRfI {
	border: 1px solid var(--success-stroke-hover);

	background: var(--success-bg);
}

.processing-2x6K9,
.success-2vRfI {
	color: var(--text-base);
}

.waiting-30Auz,
.rejected-16gQt {
	color: #ffffff;
}

@media screen and (max-width: 600px) {
	.right-bsSjp {
		font-size: 16px;
		font-weight: 400;
		line-height: 22px;
	}
}

.title-TfoCw {
	display: flex;
	align-items: center;
}

@media screen and (max-width: 600px) {
	.title-TfoCw {
		line-height: 30px;
	}

	.title-TfoCw.success-2vRfI {
		flex-direction: column;
		align-items: flex-start;
	}
}

.icon-1L7R8 {
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.icon-1L7R8.success-2vRfI {
	display: inline-flex;
	width: 26px;
	height: 26px;
	margin-right: 12px;

	border-radius: 50%;

	color: var(--success);
}

@media screen and (max-width: 600px) {
	.icon-1L7R8.success-2vRfI {
		display: flex;
	}
}

.icon-1L7R8.success-2vRfI svg {
	width: 100%;
	height: 100%;
}

button.button-1zGoO {
	width: 36px;
	min-width: auto;
	height: 36px;
	margin-left: 12px;
	padding: 0;

	border-radius: 50%;

	color: inherit;
}

@media screen and (max-width: 600px) {
	button.button-1zGoO {
		position: absolute;
		top: 50%;
		right: 20px;

		transform: translateY(-50%);
	}
}

button.button-1zGoO.processing-2x6K9 {
	background: rgba(51, 51, 51, 0.2);
}

button.button-1zGoO.waiting-30Auz,
button.button-1zGoO.rejected-16gQt {
	background: rgba(255, 255, 255, 0.2);
}

.button-1zGoO.processing-2x6K9 svg {
	transform: scale(0.6);
}
