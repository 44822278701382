.wrapper-2QLeC {
	border-radius: 8px;

	background: #f0f0f0;
}

.header-UuEx4 {
	margin-bottom: 20px;
	padding: 28px 40px;

	border-top-left-radius: 8px;
	border-top-right-radius: 8px;

	color: #ffffff;
	font-weight: 700;
	font-size: 24px;

	background: #5ba6ff;
}

.buttons-1iq7F {
	margin: 20px 0;
	padding: 0 40px;
	display: flex;
	width: 100%;
	justify-content: space-between;
}

div.modal__paper-1f6YA {
	max-width: 760px;

	border-radius: 8px;

	background: #f0f0f0;
}

/* stylelint-disable */
button.skipVisaButton-1AVJV {
}

button.saveButton-3CFlI {
}

button.saveButton-3CFlI[disabled] {
	opacity: 0.6;
}

@media screen and (max-width: 600px) {
	.header-UuEx4 {
		padding: 20px;

		border-radius: 0;

		font-size: 18px;
	}

	div.modal__paper-1f6YA {
		width: 100%;
	}

	.button-ChmEw {
		padding: 0 20px;
	}
}
