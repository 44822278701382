div.paper-29eOC {
	margin-top: 150px;
}

.header-3MO6A {
	height: 100px;
	padding: 0 40px;

	border-radius: 8px 8px 0 0;
	border-bottom: 1px solid #d9d9d9;

	color: var(--common-gray);
	font-size: 28px;
	line-height: 100px;

	background-color: #f9f9f9;
}

.header-3MO6A:empty {
	height: 0;
	margin-bottom: 55px;

	border: none;
}

.content-Rz958 {
	padding: 32px 40px 40px;

	color: var(--common-gray);
	font-size: 16px;
	line-height: 25px;
	white-space: pre-wrap;
}

.content__text-5MwzX {
	margin-bottom: 15px;
}

.content__text-5MwzX a {
	color: var(--text-link);
}

.content__text_hidden-17byR {
	display: none;
}

.content__error-EpgGS {
	font-style: italic;
}

.buttons-3PjLN {
	margin-top: 32px;

	text-align: right;
}

@media screen and (max-width: 600px) {
	div.scrollBody-3Lx6_ {
		display: flex;
		justify-content: flex-end;
	}

	div.scrollBody-3Lx6_ div.paper-29eOC {
		width: 100%;
		min-height: auto;

		margin: auto 0 0 !important;

		border-radius: 8px 8px 0 0;
		overflow: auto;
	}
}
