
/* end mixins */
div.serviceInfo__slider-2pvLb {
	overflow-y: auto;

	background: var(--gray05);
}

button.single-2srXu {
	width: 100%;
	min-height: 49px;

	font-size: 15px;
}

button.single-2srXu:hover {
	background: var(--active);
}

button.single_cancel-3mgLA {
	border: 1px solid var(--active);

	color: var(--active);

	background: #ffffff;
}

button.clear-3PG_O {
	border: none;

	background: none;
}

.clearContainer-1p_0w {
	text-align: right;
}

.serviceInfo__seat-236ez {
	margin-bottom: 20px;
}

.serviceInfo__seat-236ez:last-child {
	margin-bottom: 0;
}

.serviceInfo__selectable-1_nFn {
	display: flex;
	align-items: center;
}

.serviceInfo__passenger-HBYv8 {
	color: var(--text-base);
	font-size: 16px;
	font-weight: 500;
	line-height: 1.3;
}

.seat-28xl_ {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 44px;
	height: 44px;

	margin-left: auto;

	border-radius: 4px;

	color: var(--gray70);
	font-weight: 500;
	font-size: 14px;

	background: var(--seat-standart);
}

.seat-28xl_.seat_double-OmucE {
	width: 88px;
}
