.field-alJI1 {
	display: flex;
	margin-top: 15px;
	align-items: flex-end;
}

.name-1Xg8T {
	padding-bottom: 5px;
	width: 150px;
	margin-right: 15px;

	font-weight: 600;
	font-size: 18px;
	white-space: nowrap;
	text-transform: capitalize;
	text-overflow: ellipsis;
	overflow: hidden;
}

@media screen and (max-width: 600px) {
	.field-alJI1 {
		display: block;
		margin-bottom: 15px;
		padding: 25px 15px;

		border-radius: 4px;

		background: #ffffff;
	}

	div.input-30Hl3 {
		margin-top: 10px;
	}
}
