

.wrapper-1qAn3 {
	position: relative;

	display: inline-flex;
	align-items: center;
	margin-right: 20px;

	color: var(--gray50);
	font-size: 13px;
	line-height: 22px;
}

.wrapper-1qAn3 svg {
	position: absolute;
	left: 0;
	top: 0;
}

.wrapper-1qAn3 span {
	padding-left: 24px;
}
