

div.saveContactInfo__modal-3jdym {
	top: 50%;

	max-width: 600px !important;

	margin: 0 auto !important;
	padding: 28px 28px 40px 28px;

	transform: translateY(-50%);
	overflow: visible;
}

div.root-q3jyk {
	z-index: 1503 !important;
}

.saveContactInfo__title-3jJF_ {
	display: inline-block;
	margin-bottom: 28px;

	color: var(--dark-gray);
	font-weight: 500;
	font-size: 22px;
	line-height: 31px;
}
