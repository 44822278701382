.container-3euWy {
	display: flex;
	flex-direction: column;
	grid-gap: 8px;
	gap: 8px;

	width: 100%;
	max-width: 1100px;

	margin: 20px auto;
}

.consent-1vF8G {
	padding: 20px 28px;

	background: var(--bg-white);
}

@media screen and (max-width: 600px) {
	.container-3euWy {
		padding: 14px 16px;
	}
}
