.sendAgain-1STXl {
	display: flex;
	align-items: center;
	grid-gap: 12px;
	gap: 12px;

	color: #9a9a9a;
	font-size: 15px;
}

.sendAgain__link-MgZSr {
	padding-left: 5px;

	color: #9a9a9a;
}

.label-3jeAa {
	justify-content: flex-end;
}

@media screen and (max-width: 600px) {
	.sendAgain-1STXl {
		font-size: 14px;
	}

	.label-3jeAa {
		justify-content: flex-start;
	}
}

.timer-oDwvr {
	position: relative;

	display: flex;
	flex-basis: 100%;
	align-items: center;
	padding-left: 30px;

	color: var(--dark-gray);
}

.timer-oDwvr:before {
	position: absolute;
	left: 0;

	width: 24px;
	height: 24px;
	margin: auto;

	background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xMS45OSAyQzYuNDcgMiAyIDYuNDggMiAxMkMyIDE3LjUyIDYuNDcgMjIgMTEuOTkgMjJDMTcuNTIgMjIgMjIgMTcuNTIgMjIgMTJDMjIgNi40OCAxNy41MiAyIDExLjk5IDJaTTEyIDIwQzcuNTggMjAgNCAxNi40MiA0IDEyQzQgNy41OCA3LjU4IDQgMTIgNEMxNi40MiA0IDIwIDcuNTggMjAgMTJDMjAgMTYuNDIgMTYuNDIgMjAgMTIgMjBaTTExIDdIMTIuNVYxMi4yNUwxNyAxNC45MkwxNi4yNSAxNi4xNUwxMSAxM1Y3WiIgZmlsbD0iIzlBOUE5QSIvPgo8L3N2Zz4K) center no-repeat;
	content: '';
}

.timer__time-dWvzZ {
	padding-left: 3px;
}

.item-23YDZ {
	position: relative;

	display: flex;
	align-items: center;
}

.sending-2dKSt {
	color: #9a9a9a;
}

.sending-2dKSt:after {
	position: absolute;
	right: 0;

	width: 24px;
	height: 24px;

	background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAHPSURBVHgBrZXbTsJAEIanlUMwgmdQE030wkS98kZ9BV/Kx/IRTNTExEv1QoinqFEUgoKcnN/9N66kBSn9k6+0Szv/7Ox26kl/JZRFZVLJEF/pKDXlmXyGBfBCxlPKGgP7Dl7PteVeuQoyCjLIK0tKkpm+KBVm3OA9E5xNQVlmnLZyqVz3MygwOMbflZLSkv6C0bqywucuyI/GnBtnxdQbulNuOYNBQgKPDJ4nGHt1DVAOTNXnzU8yvFDKprKgzCk3uLYGGMRU35QHia6yMu1Q9Jl9Tkw5omTeq1MxJcJ6JjEDbMVxMYtakdHVYbx5nPu8wGBV4hMqgdgFWyIYNCU+lWmQ9TnQVb4kPtVokEOv+c9ejyIYdHFAaTCDhMSnGTEvXssadGI2yIqZQRmHOg1SEp9WaVDC4YMGaQlv38MI2W+4BgjecP4cVTtiEkXrrtptipcM/RzrkJbo2lU2xawrWoZYAwS3pcpENNlW9sRkf8Kk/+ycOn+x2Eme4+XrDgiMZPbFfGKR4LFybv8MWtSk/BrgAXTGdo/RlJgPC74hW0wKCR4pZ26wsF3j0cRuApcD57zLX3xaDyWgG4e9XLY3eQ6uOVo7GlpRzGI2QuLIN1JhcOk842ZSAAAAAElFTkSuQmCC) center no-repeat;

	animation: rotation-21akd 0.9s infinite linear;

	content: '';
}

.sent-3VIte {
	color: #18a458;
}

.sent-3VIte:after {
	position: absolute;
	right: 0;

	width: 28px;
	height: 28px;

	background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjUiIHZpZXdCb3g9IjAgMCAyNCAyNSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0yIDEyLjAxOTVDMiA2LjQ5MDUgNi40OCAyLjAwMzIzIDEyIDIuMDAzMjNDMTcuNTIgMi4wMDMyMyAyMiA2LjQ5MDUgMjIgMTIuMDE5NUMyMiAxNy41NDg0IDE3LjUyIDIyLjAzNTcgMTIgMjIuMDM1N0M2LjQ4IDIyLjAzNTcgMiAxNy41NDg0IDIgMTIuMDE5NVpNNSAxMi4wMTk1TDEwIDE3LjAyNzZMMTkgOC4wMTI5N0wxNy41OSA2LjU5MDY3TDEwIDE0LjE5M0w2LjQxIDEwLjYwNzJMNSAxMi4wMTk1WiIgZmlsbD0iIzU1RDU5MCIvPgo8bWFzayBpZD0ibWFzazAiIG1hc2stdHlwZT0iYWxwaGEiIG1hc2tVbml0cz0idXNlclNwYWNlT25Vc2UiIHg9IjIiIHk9IjIiIHdpZHRoPSIyMCIgaGVpZ2h0PSIyMSI+CjxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNMiAxMi4wMTk1QzIgNi40OTA1IDYuNDggMi4wMDMyMyAxMiAyLjAwMzIzQzE3LjUyIDIuMDAzMjMgMjIgNi40OTA1IDIyIDEyLjAxOTVDMjIgMTcuNTQ4NCAxNy41MiAyMi4wMzU3IDEyIDIyLjAzNTdDNi40OCAyMi4wMzU3IDIgMTcuNTQ4NCAyIDEyLjAxOTVaTTUgMTIuMDE5NUwxMCAxNy4wMjc2TDE5IDguMDEyOTdMMTcuNTkgNi41OTA2N0wxMCAxNC4xOTNMNi40MSAxMC42MDcyTDUgMTIuMDE5NVoiIGZpbGw9IndoaXRlIi8+CjwvbWFzaz4KPGcgbWFzaz0idXJsKCNtYXNrMCkiPgo8L2c+Cjwvc3ZnPgo=) center no-repeat;
	content: '';
}

.back-1c3Rq {
	display: none;
	padding-right: 12px;

	border-right: 1px solid var(--line-separator);

	color: var(--brand-1-0);
	font: var(--normal-desktop);

	cursor: pointer;
}

@keyframes rotation-21akd {
	from {
		transform: rotate(0deg);
	}

	to {
		transform: rotate(359deg);
	}
}
