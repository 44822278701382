

.modal__content-37zbU {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	grid-gap: 20px;
	gap: 20px;
	align-self: stretch;

	font: var(--medium--1-desktop);
}

.modal__header-qQD1L {
	color: var(--text-Base);
	font: var(--medium-1-mobile-bold);
}

div.modal__paper-2i11o {
	max-width: 520px;
}

.status-11UU- {
	color: var(--text-mid);
	font: var(--medium-desktop-accent);
}

.status_accent-1ludQ .passenger__segment-HSuRf {
	color: var(--error-text);
}

.services-3Khoc {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	grid-gap: 12px;
	gap: 12px;
	align-self: stretch;
}

.passenger-3fA4y {
	display: flex;
	align-items: flex-start;
	grid-gap: 13px;
	gap: 13px;
	align-self: stretch;
}

.passenger__name-1EikQ {
	min-width: 140px;
}

.notification__text-2eL2w {
	font: var(--medium--1-desktop-accent);
}

.loader-3txZ8 {
	margin: 0 auto;
}

button.removeServices-3_uyF {
	height: 47px;
	align-self: stretch;

	border-radius: 12px;
}
