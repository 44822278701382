

.wrapper-3NDsv {
	width: 100%;
	max-width: 300px;
	padding: 12px 0;

	border: 1px solid var(--line-separator);
	border-radius: 6px;

	box-shadow: 4px 4px 33px 0 rgba(0, 0, 0, 0.05);
	cursor: pointer;
	background: var(--bg-white);
}

.aircraftImage-3rmYo {
	width: 100%;
	padding-top: 12px;

	border-top: 1px dashed var(--line-dot-line);
}

.aircraftStatistics-2haN3 {
	display: flex;
	justify-content: space-between;
	padding: 0 12px 12px;
	margin-bottom: 12px;

	border-bottom: 1px dashed var(--line-dot-line);
}

.aircraftName-2d7uI {
	display: inline-block;
	padding-right: 24px;

	border-right: 1px solid var(--line-separator);

	color: var(--text-base);
	font-size: 13px;
	line-height: 17px;
	text-decoration: none;
}

.wrapper-3NDsv.active-2NJFD,
.wrapper-3NDsv:hover {
	border-color: var(--brand-1-3);

	background: var(--brand-1-5);
}

.content-2kKqq {
	display: flex;
	flex-direction: column;

	color: var(--text-light);
	font-size: 13px;
}

.header-rGqo8 {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-bottom: 7px;
	padding: 0 12px;

	color: var(--brand-1-0);
}

.routeInfo-pEULJ {
	display: flex;
	align-items: center;
	margin-bottom: 12px;
	padding: 0 12px;
}

.airportsInfo-X--oe {
	display: flex;
	padding-top: 6px;

	border-top: 1px dashed var(--line-dot-line);
}

.controls-qy1wF {
	display: flex;
	justify-content: space-between;
	padding: 0 12px;
	align-items: center;
}

.bookLink-GhsA8 {
	text-decoration: none;
	color: var(--brand-1-0);
	font-size: 12px;
	font-style: normal;
	font-weight: 700;
	line-height: 18px;
}

.flightNumber-KOu6Z {
	margin-right: 16px;

	font-weight: 700;
	line-height: 18px;
}

.status-XcqBv {
	padding: 2px 4px;

	border: 1px solid transparent;
	border-radius: 4px;

	line-height: 17px;
}

.departed-1_gmv {
	border-color: var(--brand-1-0);

	color: var(--brand-1-0);
}

.scheduled-1T5Gg {
	border-color: var(--success-20);

	color: var(--success-20);
}

.arrived-K-x3D {
	border-color: var(--text-mid);

	color: var(--text-mid);
}

.cancelled-3UGkf {
	border-color: var(--error-text);

	color: var(--error-text);
}

.dataExpected-8CdaH {
	border-color: var(--brand-3-0);

	color: var(--brand-3-0);
}

/* stylelint-disable */
.date-19CU3 {
}
/* stylelint-enable */
.airportInfo-1XhkU {
	display: flex;
	margin-bottom: 5px;
	align-items: center;
}

.time-1kDH_ {
	margin-right: 4px;
	margin-left: 8px;

	color: var(--text-base);
	font-weight: 700;
	font-size: 16px;
	line-height: 21px;
}

.city-1HFYo {
	flex: 1;

	font-weight: 500;
	line-height: 18px;
	color: var(--text-base);
	font-size: 14px;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
}

button.buyTicket-3Es-J {
	padding: 10px;

	font-size: 16px;
}

.duration-wQNRj {
	position: relative;

	flex: 2;

	text-align: center;
}

.departure-GPIsD,
.arrival-2QJLH {
	display: flex;
	flex-direction: column;
	flex: 1;
}

.departure-GPIsD {
	align-items: flex-start;
	padding-left: 12px;

	border-right: 1px dashed var(--line-dot-line);
}

.arrival-2QJLH {
	padding-right: 12px;
	align-items: flex-end;
}

.durationTime-1YjD5 {
	position: inherit;
	z-index: 2;

	display: inline-block;
	padding: 0 3px;

	background: var(--bg-white);
}

.wrapper-3NDsv.active-2NJFD .durationTime-1YjD5,
.wrapper-3NDsv:hover .durationTime-1YjD5 {
	background: var(--brand-1-5);
}

.durationLine-3dsP5 {
	position: absolute;
	top: 50%;
	left: 5%;
	z-index: 1;

	width: 90%;
	height: 1px;

	transform: translateY(-50%);
	background: var(--line-separator);
	content: '';
}

.durationLine-3dsP5:after {
	right: 0;
}

.durationLine-3dsP5:after,
.durationLine-3dsP5:before {
	position: absolute;
	top: 50%;
	z-index: 3;

	display: block;
	width: 10px;
	height: 10px;

	border: 1px solid var(--line-separator);
	border-radius: 50%;

	transform: translateY(-53%);
	background: var(--bg-white);
	content: '';
}

.wrapper-3NDsv:hover .durationLine-3dsP5:after,
.wrapper-3NDsv:hover .durationLine-3dsP5:before,
.wrapper-3NDsv.active-2NJFD .durationLine-3dsP5:after,
.wrapper-3NDsv.active-2NJFD .durationLine-3dsP5:before {
	background: var(--brand-1-5);
}
