.wrapper-o6YSv {
	display: flex;
	flex-direction: column;
	grid-gap: 12px;
	gap: 12px;

	width: 100%;
	max-width: 1140px;

	margin: 0 auto;
	padding-top: 30px;
	padding-left: 20px;
	padding-right: 20px;
}

.content-3WXVr {
	display: flex;
	grid-gap: 12px;
	gap: 12px;
}

.conditions--klYM,
.form-3ExMa {
	flex: 1;

	border-radius: 20px;
}

div.modal__paper-2ftwR {
	max-width: 1250px;
}

@media screen and (max-width: 600px) {
	.wrapper-o6YSv {
		padding: 12px;
	}

	.content-3WXVr {
		flex-direction: column;
	}

	div.conditions--klYM {
		order: 1;

		border-radius: 20px;
	}

	.hint-mKd-t,
	.hint-mKd-t:hover {
		margin-top: 0;
		padding: 20px;

		border-radius: 0;

		background-color: transparent;
		box-shadow: none;
	}

	div.modal-32qXL {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
	}

	div.modal-32qXL div.modal__paper-2ftwR {
		margin: 0;
		width: 100%;

		border-radius: 0;
	}
}
