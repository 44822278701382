

.step-aR8vE {
	display: flex;
	align-items: center;

	font-weight: 700;
	font-size: 24px;
	line-height: 100%;
}

.step-aR8vE .text-28jZo {
	color: var(--gray80);
	font-weight: 700;
}

.icon---_0f {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 44px;
	height: 44px;
	margin-right: 16px;

	border-radius: 50%;

	color: #ffffff;

	background: var(--base10);
}
