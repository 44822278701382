

.companions-1oq7X p {
	margin: 4px 0 0;

	line-height: 130%;
}

.description-l5-bL {
	margin-bottom: 20px;

	color: var(--text-mid);
	font-weight: 400;
	font-size: 16px;
}
