

.money-15j0H {
	padding-left: 4px;

	white-space: nowrap;
}

.currency_before-3CsK2 {
	margin-right: 1px;
}

/* stylelint-disable */
.currency_after-2XStG {
}
/* stylelint-enable */
