.wrapper-2tGv8 {
	display: flex;
	justify-content: center;

	background-color: var(--white);
}

.wrapper_sticky-142K6 {
	position: sticky;
	top: 0;
	z-index: 10;
}

.inner-3nplm {
	position: relative;

	display: flex;
	align-items: center;
	justify-content: space-between;
	grid-gap: 20px;
	gap: 20px;

	padding: 0 20px;
	max-width: 1140px;
	width: 100%;
	height: 80px;
}

.title-ayPN0 {
	left: 20px;

	color: var(--text-base);
	font-weight: 600;
	font-size: 24px;
	line-height: 100%;
}

.segments-3RsCp {
	display: flex;
	flex: 1;
	justify-content: flex-end;
	align-items: center;
}

.wrapper_sticky-142K6 .segments-3RsCp {
	justify-content: center;
}

.close-1kHtH {
	justify-content: flex-end;
}

.wrapper_center-2XG8_ .title-ayPN0,
.wrapper_center-2XG8_ .close-1kHtH {
	flex-basis: 20%;
}

.wrapper_center-2XG8_ .segments-3RsCp {
	padding-right: calc(20% + 20px);
}

.wrapper_withClose-2iu7m .segments-3RsCp {
	padding-right: 0;
}

.alignmentStub-1fjlN {
	flex: 1;
}

@media (max-width: 1024px) {
	.title-ayPN0 {
		display: none;
	}

	.wrapper_center-2XG8_ .segments-3RsCp {
		padding-right: 0;
	}
}
