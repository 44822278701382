

button.button-1RiGA {
	color: var(--gray50);

	background: #ffffff;
}

button.button-1RiGA:hover,
button.button-1RiGA:focus {
	background-color: #ffffff;
}
