.root-3hjV0 {
	display: flex;
	align-items: center;
	padding: 17px 13px 14px;

	border-radius: 4px;

	font-weight: 700;
	font-size: 16px;
	line-height: 20px;

	background-color: rgba(50, 144, 255, 0.1);
}

.root-3hjV0:not(:first-child) {
	margin-top: 20px;
}

.disabled-2I409 {
	opacity: 0.7;
}

.name-MT2Hg {
	display: flex;
	align-items: center;
	flex: 1;
}

.name_price-1w9t8 {
	margin-left: auto;
}

.root-3hjV0 + .passenger-Clq3v {
	border-top: none;
}

.name-MT2Hg.name_withPrices-17Tb3 {
	max-width: 33%;
}

.prices-t7Ohd {
	display: flex;
	align-items: center;
	flex: 1;
}

.price-1NrN9 {
	flex: 1;

	text-align: center;
}

.price__empty-1NIZl {
	flex: 1;
}

@media screen and (max-width: 600px) {
	.root-3hjV0:not(:first-child) {
		margin-top: 25px;
	}

	.prices-t7Ohd {
		justify-content: flex-end;
	}

	.price-1NrN9 {
		text-align: right;
	}
}
