

div.wrapper-3bZjk {
	background: transparent;
}

.files-2X9IO .file-2eoQp:not(:last-of-type) {
	padding-bottom: 20px;
	margin-bottom: 20px;

	border-bottom: 1px solid var(--gray20);
}

p.info-1TQCp {
	margin-top: 20px;

	font-size: 13px;
	line-height: 17px;
	color: var(--gray50);
	text-align: center;
}

p.hasErrors-23eZq {
	color: var(--error);
	font-weight: 500;
}
