

.wrapper-13InV {
	display: flex;
	flex-direction: column;
	grid-gap: 8px;
	gap: 8px;
	margin-top: 28px;
}

.header-3-QEu {
	display: flex;
	grid-gap: 16px;
	gap: 16px;
	align-items: center;
	padding-bottom: 8px;

	color: var(--text-base);
	font-weight: 600;
	font-size: 24px;
}

.icon-10F3S {
	display: flex;
	flex-shrink: 0;
	justify-content: center;
	align-items: center;
	width: 36px;
	height: 36px;

	border-radius: 50%;

	color: #ffffff;

	background: var(--error-icon);
}

.icon-10F3S svg {
	width: 20px;
	height: 20px;
}
