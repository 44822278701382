.container-OOJgv {
	padding: 40px;

	box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.04);
	background: #ffffff;
}

label.label-1akxf {
	display: flex;
	margin-right: 0;
	margin-bottom: 8px;
	height: 64px;
	padding-left: 13px;

	background: var(--gray05);
}

label.label-1akxf.label_selected-1n65s {
	color: var(--base);

	background: var(--base80);
}

.labelInner-188rA {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	padding-right: 12px;
}

div.labelDate-13CUM {
	opacity: 0.6;

	transition: opacity 0.3s;

	pointer-events: none;
}

label.label-1akxf.label_selected-1n65s div.labelDate-13CUM {
	opacity: 1;

	pointer-events: auto;
}

span.button-2J5bv {
	color: #9a9a9a;
}

span.button-2J5bv.button_checked-2INho {
	color: var(--base);
}

@media screen and (max-width: 600px) {
	.container-OOJgv {
		padding: 0 16px;
	}

	label.label-1akxf {
		margin: 0;
		padding: 0;

		background: none;
	}

	label.label-1akxf:not(:last-child) {
		border-bottom: 1px solid var(--gray10);
	}

	label.label-1akxf.label_selected-1n65s {
		color: var(--base);

		background: none;
	}
}
