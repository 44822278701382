

.segment-3UgrW {
	display: flex;
	flex-direction: column;
}

.label-2SSHk {
	padding: 16px 20px;

	color: var(--text-base);
	font-size: 16px;
	font-weight: 500;
	line-height: 1.3;

	background: var(--bg-fill);
}
