.title__traveller-1JFLW {
	display: flex;
	padding: 14px 0;
	width: 100%;
	align-items: center;
	margin: 0;
}

.title__traveller-1JFLW > p {
	display: flex;
	width: 100%;
	align-items: baseline;
	justify-content: space-between;
}

.title__traveller-1JFLW > p > h1 {
	margin: 0;

	font-size: 18px;
	font-weight: 500;
	color: var(--dark-gray);
}

.title__traveller-1JFLW.add-y0lwi > p > h1 {
	color: var(--cabinet-action);
}

.title__traveller-1JFLW.add-y0lwi > span > svg > path {
	fill: var(--cabinet-action);
}

.title__traveller-1JFLW > span {
	display: flex;
	align-items: center;
	margin-right: 16px;
	margin-left: -8px;
}

.title__traveller-1JFLW > p > span:first-child {
	margin: 0;

	font-size: 18px;
	font-weight: 500;
	color: var(--dark-gray);
}

.title__traveller-1JFLW.add-y0lwi > p > span {
	color: var(--common-blue);
}

.userCard-38pMq div.summary-2UcD_ {
	padding-left: 0;
	padding-right: 0;
}

div.wrapper-2wq3A {
	margin: 0;
	margin-bottom: 8px;

	border-radius: 4px;
}

div.wrapper-2wq3A:before {
	opacity: 0;
}

.wrapper-2wq3A .header-xBUsy {
	min-height: 64px;
}

.wrapper-2wq3A .header-xBUsy > div {
	margin: 0;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.wrapper-2wq3A .header-xBUsy > div p {
	margin: 0;

	font-size: 15px;
	font-weight: 400;
	color: var(--common-gray);
}

.expanded-257bh .header-xBUsy {
	border-bottom: 1px solid #d9d9d9;
}

.wrapper-2wq3A .content__traveller-3z9V4 {
	display: flex;
	justify-content: center;
	padding: 0;
}

.wrapper-2wq3A .content__traveller-3z9V4 > div {
	width: 100%;
}

.header__redirect-2628M svg {
	transform: rotate(90deg);
}

/* stylelint-disable */
.expandIcon-2ejDt {
}
/* stylelint-enable */

@media screen and (max-width: 600px) {
	.popup__header-2NH-o {
		display: flex;
		align-items: center;
		height: 58px;
		padding: 0 12px;

		color: #ffffff;
		font-weight: 500;
		font-size: 20px;

		background: var(--brand1);
	}

	.popup__header-2NH-o svg {
		flex-shrink: 0;

		margin-right: 20px;
	}

	.userCard-38pMq {
		margin: 20px 0;

		border-radius: 4px;
	}

	.userCard-38pMq div.summary-2UcD_ {
		padding-left: 20px;
		padding-right: 20px;
	}
}

@media screen and (max-width: 380px) {
	.userCard-38pMq div.summary-2UcD_ {
		padding: 0 16px;
	}
}
