

.content-BjIwF {
	position: relative;

	display: flex;
	flex-direction: column;
	justify-content: space-between;
	grid-gap: 20px;
	gap: 20px;

	height: calc(100% - 104px);
	padding: 24px 16px;
}

.contentWrapper-2l63z {
	display: flex;
	flex-direction: column;
}

.border-IuYM2 {
	border: 1px solid var(--bg-elements);
}

.shadow-3aTRD {
	box-shadow: 4px 4px 33px 0 rgba(0, 0, 0, 0.05);
}

.banner-bfttP {
	border-top-left-radius: 8px;
	border-top-right-radius: 8px;
}

.title-3G5gi {
	margin-bottom: 6px;

	color: var(--text-base);
	font: var(--medium-desktop-bold);
}

.description-1SAgl,
.options-3U4ce {
	margin-top: 6px;

	color: var(--text-light);
	font: var(--medium--1-desktop);
}

.description-1SAgl a {
	color: var(--text-link);
	text-decoration: none;
}

.footer-RHuWv {
	display: flex;
	flex-direction: column;
	grid-gap: 12px;
	gap: 12px;

	margin-top: auto;
}

.footerPrice-3rlyr {
	padding-top: 12px;

	border-top: 1px dashed var(--line-dot-line);
}

.popular-3Z3lV .footerPrice-3rlyr {
	display: flex;
	align-items: center;
	justify-content: space-between;
	grid-gap: 12px;
	gap: 12px;

	border-top: none;
}

.bottomPrice-3G5V- {
	width: 100%;
}

.popular-3Z3lV .footerPrice-3rlyr .bottomPrice-3G5V- {
	margin-top: 0;
}

/* stylelint-disable */
.price-23Td9 {
}

.formControl-1t907 {
}
/* stylelint-enable */
button.selectButton-1sg7N {
	position: absolute;
	right: 24px;
	top: -24px;

	border: 3px solid var(--bg-white);

	background: var(--text-accent);
}

button.selectButton-1sg7N.confirmed-2AiGH,
button.selectButton-1sg7N.confirmed-2AiGH:hover {
	background: var(--recommend-icon);

	pointer-events: none;
}

button.selectButton-1sg7N:hover {
	background: var(--text-accent);
}

.moreInfo-2Bx63 {
	display: inline-flex;
	align-items: flex-end;

	font-size: 14px;
	text-decoration: none;

	transition: opacity 0.15s;
}

.moreInfo_loading-Zugmk {
	opacity: 0.6;

	pointer-events: none;
}

.infoIcon-3Ah69 {
	width: 18px;
	height: 18px;
	margin-right: 5px;

	color: var(--text-pale);
}

.popular-3Z3lV .moreInfo-2Bx63 {
	margin-top: 0;
}

.linksWrapper-2nxCX {
	display: flex;
	justify-content: space-between;
}
