.startSearch-1RSJ1 {
	display: inline-block;
	height: var(--input-height);

	flex-basis: 128px;
	flex-shrink: 0;
}

.startSearch-1RSJ1:not(.startSearch_iconMode-1d-L-) > svg {
	display: none;
}

.startSearch-1RSJ1 button {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	min-height: 48px;

	font-weight: 500;
	font-size: 16px;
}

.startSearch_multiCity-1IQO3 {
	flex-basis: 230px;
}

@media (max-width: 1024px) {
	.startSearch_multiCity-1IQO3 button {
		border-radius: 0 4px 4px 0;
	}
}

.startSearch_multiCity-1IQO3.startSearch_withCurrency-3fLkP button {
	border-radius: 0 4px 4px 0;
}

.startSearch-1RSJ1:hover button {
	background-color: var(--start-search-button-hover);
}

.startSearch-1RSJ1.startSearch_iconMode-1d-L- {
	flex-basis: 64px;
}

.startSearch-1RSJ1.startSearch_iconMode-1d-L- button {
	min-width: 64px;
	padding: 0 16px;

	border-radius: 0 4px 4px 0;
}

.startSearch-1RSJ1.startSearch_iconMode-1d-L- .text-3WJrc {
	display: none;
}

@media (max-width: 1024px) {
	.startSearch-1RSJ1 {
		flex: 3 0 auto;
	}
}

@media (max-width: 1024px) {
	.startSearch_multiCity-1IQO3 {
		flex-basis: auto;
	}
}

@media screen and (max-width: 600px) {
	.startSearch-1RSJ1 {
		display: block;
		margin-top: 4px;
	}

	.startSearch_advanced-20Att.startSearch-1RSJ1 {
		position: relative;
		left: 0;
	}

	.startSearch_multiCity-1IQO3 button {
		border-radius: 0 0 4px 4px;
	}

	.startSearch-1RSJ1.startSearch_iconMode-1d-L- button {
		border-radius: 0 0 4px 4px;
	}
}
