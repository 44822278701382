.memberItemAdd-2_gmb {
	position: relative;

	display: flex;
	flex-direction: column;
	align-items: center;
	width: 220px;

	line-height: 1.3;

	cursor: pointer;
}

@media screen and (max-width: 600px) {
	.memberItemAdd-2_gmb {
		display: grid;
		grid-template-columns: 36px 1fr 20px;
		grid-gap: 12px;
		gap: 12px;
		width: 100%;
		padding: 20px;

		border-radius: 6px;

		background-color: var(--white);
	}
}

.memberItemAdd__icon-2KADH {
	display: flex;
	flex-shrink: 0;
	justify-content: center;
	align-items: center;
	width: 120px;
	height: 120px;

	border-radius: 50%;

	font-size: 31px;
	font-weight: 500;

	color: var(--bright-violet);

	background-color: var(--very-pale-violet);
}

@media screen and (max-width: 600px) {
	.memberItemAdd__icon-2KADH {
		width: 36px;
		height: 36px;

		font-size: 16px;
		font-weight: 700;
	}
}

button.memberItemAdd__iconAdd-1rbCp {
	width: 120px;
	height: 120px;

	border-radius: 50%;

	color: var(--bright-violet);
}

@media screen and (max-width: 600px) {
	button.memberItemAdd__iconAdd-1rbCp {
		width: 36px;
		height: 36px;
	}
}

.memberItemAdd__iconAdd-1rbCp svg {
	width: 56px;
	height: 56px;
}

@media screen and (max-width: 600px) {
	.memberItemAdd__iconAdd-1rbCp svg {
		width: 24px;
		height: 24px;
	}
}

.memberItemAdd__data-1T7mC {
	display: flex;
	flex-direction: column;
	margin-top: 8px;
	grid-gap: 4px;
	gap: 4px;

	text-align: center;
}

@media screen and (max-width: 600px) {
	.memberItemAdd__data-1T7mC {
		margin-top: 0;
		grid-gap: 0;
		gap: 0;

		text-align: left;
	}
}

.memberItemAdd__dataText-vQ1Xo {
	font-size: 18px;
	font-weight: 500;
	line-height: 1.3;

	word-break: break-all;

	color: var(--bright-violet);
}

.memberItemAdd__dataInfo-2xCXE {
	font-size: 14px;
	font-weight: 400;

	color: var(--light-violet);
}
