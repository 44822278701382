div.card-2IZS2 {
	min-width: 240px;

	border-radius: 0;

	background: transparent;
	overflow: visible;
}

.segment-1rQJC {
	display: flex;
}

.segment-1rQJC.canceled-1IGXR {
	opacity: 0.6;
}

.canceled__warning-2Hprz {
	margin: 20px 0 0;

	color: var(--error);
	font-weight: 600;
	font-size: 14px;
	text-align: center;
}

.title-Ir_iW,
.city-32TUM,
.transfer-MPmoj {
	font-size: 13px;
	line-height: 100%;
	color: var(--text-light);
}

.title-Ir_iW {
	width: 100%;
	margin-bottom: 4px;
	display: flex;
	justify-content: center;
	align-items: center;
	grid-gap: 8px;
	gap: 8px;
}

.title-Ir_iW img {
	width: 28px;
	height: 28px;
}

.totalDuration-1RZB_ {
	width: 100%;
	margin: 8px 0 13px;

	color: var(--error);
	font-weight: 600;
	font-size: 13px;
	text-align: center;
}

.stopPointInfoMessage-3p-Gs {
	position: relative;

	margin-top: 10px;
	padding: 12px;
}

div.stopPointInfoMessage__htmlMessage-1Besg {
	margin-top: 5px;
	width: 100%;
	height: 146px;
}

.stopPointInfoMessage-3p-Gs:before {
	position: absolute;
	top: 0;
	left: -12px;

	display: block;
	width: calc(100% + 22px);
	height: 1px;

	border-top: 1px dashed var(--gray-line);
	content: '';
}

.duration__wrapper-bS0uM {
	display: flex;
	flex: 2;
	justify-content: space-between;
	align-items: center;
	flex-direction: column;
	height: 100%;
}

.duration-2snZl {
	position: relative;

	display: flex;
	flex: 1;
	width: 100%;
	justify-content: space-between;
	align-items: center;
}

.duration-2snZl > span {
	display: flex;
	flex-direction: column;
}

.duration__text--aqQ9 {
	flex: 1;
	padding: 0 7px;

	font-size: 14px;
	font-weight: 500;

	white-space: nowrap;

	text-align: center;
}

@media screen and (max-width: 600px) {
	.duration__text--aqQ9 {
		font-size: 17px;
	}
}

.duration-2snZl:after,
.duration-2snZl:before {
	top: 50%;
	z-index: 1;

	flex: 1;

	border-top: 1px solid var(--gray20);

	background: #000000;
	content: '';
}

.duration-2snZl:before {
	left: 8%;
}

.duration-2snZl:after {
	right: 8%;
}

.duration__point-1hHf8:after,
.duration__point-1hHf8:before {
	position: absolute;
	top: 0;
	bottom: 0;

	left: 100%;

	display: block;
	width: 8px;
	height: 8px;
	margin: auto;

	border: 1px solid var(--gray20);
	border-radius: 50%;
	content: '';
}

.duration__point-1hHf8:before {
	right: 100%;
	left: auto;
}

.time-2oxJd {
	position: relative;

	display: flex;
	flex: 1;
	flex-direction: column;

	line-height: 130%;
	text-align: center;
}

.time_dep-1JdSC {
	padding-right: 20px;
}

.time_arr-3xq5h {
	position: relative;

	padding-left: 20px;
}

.date-2LJiV {
	display: none;

	color: var(--gray80);
	font-weight: 400;
	font-size: 14px;
	line-height: 1.3;
}

.time-2oxJd .time__value-1qqaL {
	color: var(--text);
	font-size: 28px;
	font-weight: 600;
	line-height: 130%;
}

.city-32TUM {
	position: relative;

	margin-top: 2px;

	color: var(--text-light);
	font-weight: 400;
	font-size: 13px;
	line-height: 14px;
}

.transfer-MPmoj {
	position: absolute;
	top: 22px;
	left: 50%;

	color: var(--gray50);
	text-align: center;

	transform: translate(-50%, 0);
}

.flightNumber__route-C0pRf {
	padding-top: 4px;

	color: #808080;
	font-size: 14px;
	text-align: center;
}

.transfer_stops-WtM9I,
.transferCount-2AuVx {
	color: var(--error-20);
	font-size: 14px;
}

.transfer_stops-WtM9I {
	white-space: nowrap;
}

.transferCount-2AuVx {
	position: absolute;
	top: -10px;
	right: -17px;
}

.row-187-y {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0 5px;
}

.row_first-1Ttj8 .time_arr-3xq5h,
.row_last-MZSZT .time_dep-1JdSC {
	color: var(--gray30);
}

.row_first-1Ttj8 .duration__text--aqQ9,
.row_last-MZSZT .duration__text--aqQ9 {
	color: var(--gray50);
	font-weight: 400;
}

@media screen and (max-width: 320px) {
	.row-187-y {
		padding: 0;
	}
}

.row_time-3qwS1 {
	padding-bottom: 3px;
	height: 100%;
}

.row_cities-3li4K {
	margin-top: 3px;
}

.icons-3kJvX {
	position: relative;
}

.icons-3kJvX .recommended,
.icons-3kJvX .selected {
	position: absolute;
	bottom: -45px;
	left: 50%;

	transform: translate(-50%, 0);
}

.icons-3kJvX .recommended__circle {
	color: #ff9e0c;
}

.icons-3kJvX .recommended__like {
	color: #ffffff;
}

.icons-3kJvX .selected__circle {
	color: #5fca43;
}

.icons-3kJvX .selected__check {
	color: #ffffff;
}

.slide-3agIM {
	margin-top: 24px;
	display: flex;
	justify-content: center;

	text-align: center;
}

.flightNumber-3W_RG {
	margin-top: 10px;

	font-weight: 600;
	font-size: 20px;
	line-height: 100%;
}

.aircraftName-1Zgur {
	display: inline-block;
	margin: 10px 0;

	color: var(--base-15);
	font-size: 16px;

	cursor: pointer;
}

.aircraft-RkOwK {
	display: flex;
	flex-direction: column;
	margin-top: 8px;
}

.aircraft-RkOwK .image-1aexA {
	position: relative;

	padding-top: 30%;
	margin-bottom: 20px;
}

.aircraft-RkOwK .image-1aexA img {
	position: absolute;
	top: 0;
	left: 50%;

	width: 64%;
	height: 100%;
	object-fit: contain;

	transform: translateX(-50%);
}

.aircraft-RkOwK .image-1aexA.image_return-3IFvD img {
	left: 50%;

	transform: translateX(-50%) scaleX(-1);
}

.aircraft__details-G6kLm {
	display: flex;
	width: 100%;
	justify-content: center;
	align-items: center;
	grid-gap: 12px;
	gap: 12px;
}

.aircraft__details-G6kLm svg {
	width: 16px;
	height: 16px;
}

.aircraft__name-Iu44a {
	color: var(--text-light);
	font-size: 13px;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
}

.aircraft__amenities-1Rjcb {
	margin-left: 0;
	padding-left: 12px;

	border-left: 1px solid var(--line-separator);
}

div.stop-nHEag {
	position: relative;
	bottom: 0;

	margin-top: 8px;

	transform: none;
}

div.modal-1BzMu {
	z-index: 1502 !important;
}

.aircraft__operatingCarrier-2MyzG {
	margin-top: 4px;

	color: var(--text-light);
	font-weight: 400;
	font-size: 10px;
	line-height: 1.3;
	text-align: center;
}

@media (max-width: 319px) {
	div.card-2IZS2 {
		min-width: 0;
	}

	.time-2oxJd {
		font-size: 20px;
	}

	.duration__wrapper-bS0uM {
		display: none;
	}
}
