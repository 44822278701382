

.wrapper-1VkNU {
	display: flex;
	flex-direction: column;
	height: 100%;

	border-radius: 8px;

	border: 1px solid transparent;

	box-shadow: 4px 10px 38px rgba(0, 0, 0, 0.1);

	transition: border-color 0.3s;
	overflow: hidden;
}

.wrapper-1VkNU.active-2VAr0 {
	border: 1px solid var(--active-30);
}

/* stylelint-disable */
.confirmed-gBaoR {
}
/* stylelint-enable */
.header-2LRfN {
	display: flex;
	align-items: center;
	padding: 12px 16px;
	min-height: 76px;

	color: var(--white);

	background-color: var(--additional-insurance);

	transition: background-color 0.3s;
}

.wrapper-1VkNU.active-2VAr0 .header-2LRfN {
	background-color: var(--active-30);
}

.header_rate-Rql22 {
	font-size: 18px;
	font-weight: 700;
	line-height: 1;
}

.header_description-1yiEt {
	margin-top: 4px;

	font-size: 12px;
	line-height: 1;
}

.header_info-24oFJ {
	display: flex;
	flex-direction: column;
}

.header_price-1oIJk {
	display: flex;
	align-items: center;
	margin-left: auto;

	font-weight: 700;
	font-size: 18px;
}

.header_price-1oIJk > svg {
	margin-left: 8px;
}

.content-QJ-sn {
	display: flex;
	flex-direction: column;
	height: 100%;
	align-items: center;
	padding: 20px 16px;
}

.content-QJ-sn > ul {
	width: 100%;
	margin-top: 0;
	margin-bottom: 14px;
	padding-left: 0;

	list-style: none;
}

.content-QJ-sn > ul > li {
	position: relative;

	margin-bottom: 14px;
	padding-bottom: 14px;
	padding-left: 14px;

	border-bottom: 1px solid var(--gray10);

	color: var(--text-base);

	font-size: 14px;
	line-height: 1.4;
}

.content-QJ-sn > ul > li a {
	color: var(--brand-1-0);
}

.content-QJ-sn > ul > li:before {
	position: absolute;
	top: 6px;
	left: 0;

	width: 5px;
	height: 5px;

	border-radius: 50%;

	background-color: var(--line-separator);
	content: '';
}

.content-QJ-sn > ul > li:last-of-type {
	margin-bottom: 0;

	border-bottom: none;
}

button.button-1WRek,
button.button_reject-2ostF {
	margin-top: auto;

	border-radius: 4px;
}

button.button-1WRek {
	font-weight: 500;
}

button.button_reject-2ostF {
	font-weight: 400;
}
