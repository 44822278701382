.point-14W2X {
	display: flex;
	align-items: flex-end;
	flex-direction: column;
	height: 100%;
	flex: 1;
}

.point_arrival-2VBiE {
	align-items: flex-start;
}

.mobile-3mqoT.point-14W2X,
.mobile-3mqoT.point-14W2X.point_arrival-2VBiE {
	align-items: center;
}

.date__container-3L3Vx {
	display: flex;
	flex-direction: column;
	height: 100%;
	justify-content: space-between;
	align-items: flex-end;
}

.point_arrival-2VBiE .date__container-3L3Vx {
	align-items: flex-start;
}

.time-1i3yw {
	margin-bottom: 3px;

	color: var(--text);
	font-weight: 600;
	font-size: 42px;
}

.point_thin-3g8CZ .time-1i3yw {
	color: var(--gray30);
}

.date-35pos,
.airport-Y5MdZ {
	font-size: 13px;
	color: var(--text-light);
	text-align: right;
}

.airport-Y5MdZ {
	text-transform: capitalize;
}

.point_arrival-2VBiE .airport-Y5MdZ {
	text-align: left;
}

.mobile-3mqoT .time-1i3yw {
	font-size: 28px;
	text-align: center;
}

.mobile-3mqoT .date-35pos,
.mobile-3mqoT .airport-Y5MdZ {
	text-align: center;
}

.mobile-3mqoT .date-35pos {
	margin-bottom: 4px;
}

@media screen and (max-width: 320px) {
	.date-35pos {
		font-size: 12px;
		white-space: nowrap;
	}

	.date__container-3L3Vx,
	.point_arrival-2VBiE .date__container-3L3Vx {
		align-items: center;
	}

	.mobile-3mqoT .time-1i3yw {
		font-size: 21px;
	}
}
