

.container-36Ne1 {
	display: flex;
	align-items: center;
	justify-content: flex-end;
}

button.button-3FOTa {
	min-height: 46px;

	padding: 0 24px;

	border-radius: 6px;

	color: var(--white);
	font-weight: 700;
	font-size: 16px;

	background: var(--brand-3-0);
}

button.button-3FOTa:hover {
	background: var(--brand-3--1);
}

button.button-3FOTa:not(:first-child) {
	margin-left: 8px;
}

button.button_delete-KccJC {
	color: var(--error-text);

	background: var(--white);
}

button.button_delete-KccJC:hover {
	color: var(--error-text);

	background: var(--error-bg);
}
