

.container-3oxIo {
	position: relative;

	max-width: 1100px;

	margin: 0 auto 12px;

	padding: 17px 17px 17px 50px;

	border: 1px solid var(--warning);

	color: var(--gray80);
	font-size: 15px;
	line-height: 130%;

	background: var(--warning20);
}

.html-37GZ8 a {
	color: var(--text-link);
}

/* stylelint-disable */
.reject-3z3ol {
}

.notice-5uiHK {
}

.error--2Hdl {
}

.time-MMaWO {
}

/* stylelint-enable */
.header-bGJJh {
	font-weight: 600;
	font-size: 16px;
	line-height: 130%;
}

.icon-1YwWR {
	position: absolute;
	top: 18px;
	left: 18px;
}
