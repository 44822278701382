.meals-3z4zg {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	grid-gap: 28px;
	flex-grow: 1;

	margin-top: 28px;
}

.meals_singleMeal-2yCOF {
	grid-template-columns: 1fr;
	justify-content: center;
}

.meal-3w_y6 {
	padding-bottom: 80px;
}

.infoMessage-2ytjN {
	margin-top: 28px;
}

div.infoMessage__htmlMessage-3xMGH {
	width: 100%;
	height: 52px;
}

.segmentTabs-yOVr9 {
	margin-right: 10%;
}

/* stylelint-disable */
.segmentTabs_meal-2J10d {
}

.segmentTabs_segments-3rX4X {
}

/* stylelint-enable */
.meals_purchased-2l-tj {
	padding: 20px;
	margin-top: 40px;

	border-radius: var(--baggage-additional-wrapped-border-radius);

	background: #ffffff;
	box-shadow: var(--meal-box-shadow);
}
@media (max-width: 1024px) {
	.meals-3z4zg:not(.meals_singleMeal-2yCOF) {
		grid-template-columns: 1fr 1fr;
	}
}

@media screen and (max-width: 600px) {
	.meals-3z4zg:not(.meals_singleMeal-2yCOF),
	.meals_singleMeal-2yCOF {
		grid-template-columns: 1fr;
		grid-gap: 17px;

		margin-top: 20px;
	}
}
