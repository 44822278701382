.subscriptionInfo-3KrZ8 {
	display: flex;
	flex-direction: column;
	width: 100%;
}

.subscriptionInfo__title-O-8GF {
	margin-top: 0;
	margin-bottom: 20px;

	font-size: 24px;
	font-weight: 700;

	color: var(--text);
}

.subscription-1k8LF {
	display: grid;
	grid-template-columns: 340px 1fr;
	overflow: auto;

	border-radius: 6px;

	filter: drop-shadow(2px 2px 3px rgba(0, 0, 0, 0.04));
}

@media screen and (max-width: 600px) {
	.subscription-1k8LF {
		display: flex;
		flex-direction: column;
	}
}

.subscription__decor-okWOn {
	padding: 32px 30px;

	background: linear-gradient(180deg, var(--soft-violet) 0%, var(--light-violet) 100%);
}

@media screen and (max-width: 600px) {
	.subscription__decor-okWOn {
		display: flex;
		align-items: center;
		grid-gap: 32px;
		gap: 32px;
		padding: 16px;
	}
}

.subscription__decorTitle-3hedy {
	margin-top: 8px;

	font-size: 28px;
	font-weight: 700;
	line-height: 1.3;

	white-space: pre-line;

	color: var(--white);
}

@media screen and (max-width: 600px) {
	.subscription__decorTitle-3hedy {
		margin-top: 0;
	}
}

.subscription__details-BnLdd {
	padding: 20px;

	background-color: var(--white);
}

.subscription__detailsTitle-iR1e6 {
	margin-bottom: 18px;

	font-size: 16px;
	font-weight: 700;
	line-height: 1.3;

	color: var(--text);
}

@media screen and (max-width: 600px) {
	.subscription__detailsTitle-iR1e6 {
		margin-bottom: 16px;
	}
}

.subscription__detailsContent-3t7qL {
	display: flex;
	flex-direction: column;
	grid-gap: 15px;
	gap: 15px;
}

@media screen and (max-width: 600px) {
	.subscription__detailsContent-3t7qL {
		grid-gap: 19px;
		gap: 19px;
	}
}

.subscription__planName-2z6pX,
.subscription__endDate-2ZQ-M {
	font-size: 16px;
	font-weight: 700;
	line-height: 1.3;

	color: var(--text-mid);
}

.subscription__planOptions-CU2o9 {
	display: flex;
	flex-direction: column;
	grid-gap: 8px;
	gap: 8px;
	margin-top: 7px;
	margin-bottom: 0;
	padding: 0;

	list-style: none;
}

.subscription__planOption-26O3m {
	display: flex;
	align-items: flex-start;
	grid-gap: 5px;
	gap: 5px;
	padding-left: 2px;

	font-size: 14px;
	font-weight: 400;
	line-height: 1.3;

	color: var(--text);
}

.subscription__planOption-26O3m svg {
	width: 21px;
	height: 15px;

	color: var(--light-violet);
}
