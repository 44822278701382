.container-2HX_N {
	width: 100%;
	height: 30px;
	overflow: hidden;
}

.list-3OmqH {
	display: flex;
	flex-direction: column;

	transition: transform 0.3s;
}

.item-2DNzv {
	height: 30px;
	min-height: 30px;
}

.item_gone-1C0d_ {
	opacity: 0;

	transition: opacity 0.3s;
}

@media screen and (max-width: 600px) {
	.item-2DNzv {
		min-height: 50px;
	}

	.container-2HX_N,
	.item-2DNzv {
		height: 50px;
	}
}
