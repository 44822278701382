

.root-2lKWj {
	display: flex;
	flex-direction: column;
	align-items: center;
	grid-gap: 8px;
	gap: 8px;
	padding: 12px;
}

.segmentInfo-1wRfG {
	display: flex;
	align-items: center;
	grid-gap: 20px;
	gap: 20px;
}

.segmentInfo__cityName-3hjO0 {
	font-weight: 700;
	font-size: 16px;
	color: var(--text-base);
}

.aircraftInfoAndAmenities-sM1wl {
	display: flex;
	justify-content: space-between;
}

.amenities-2Qrb5 {
	margin-left: 5px;
	padding-left: 5px;

	border-left: 1px solid var(--line-separator);
}

.company-3gpcK {
	display: flex;
	align-items: center;
	grid-gap: 8px;
	gap: 8px;
}

.company__logo-VNeaA {
	width: 28px;
}

.aircraftInfo-xE9fg {
	display: inline-flex;
	align-items: center;

	text-decoration: none;

	cursor: pointer;
}

.aircraftInfo-xE9fg > svg {
	margin-left: 2px;
	width: 16px;
	height: 16px;
}

.aircraftInfo-xE9fg,
.company__name-1q52n,
.segmentInfo__date-3BLsY,
.segmentInfo__airportIATA-3dLbK {
	font-size: 13px;
	color: var(--text-light);
}

.flightNumber-3nfHN {
	z-index: 2;

	padding: 0 5px;
}

div.routeArrow-1YeSK {
	margin: 0 !important; /* :( */
}

div.stopsInfo-2Gybi {
	bottom: 50%;

	transform: translateY(50%);
}

.routeArrow-1YeSK div.route-2hoRT {
	margin-top: 0;
}

.timeInRoute-GEW2B {
	display: none;
}

div.route-2hoRT:before,
div.circle-hHj_A {
	top: 7px;
}
