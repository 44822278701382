.container-14zqL {
	height: 70px;
	margin-bottom: 28px;

	box-shadow: 0 7px 20px rgba(0, 0, 0, 0.03);
	background: var(--white);
}

.wrapper-1tWqN {
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: 100%;
	max-width: 1100px;
	margin: 0 auto;
}

.title-2YuZN {
	color: var(--text-base);
	font-size: 24px;
	font-weight: 700;
	line-height: 1;
}

@media (max-width: 1024px) {
	.container-14zqL {
		padding: 0 20px;
	}
}

@media screen and (max-width: 600px) {
	.container-14zqL {
		height: -moz-fit-content;
		height: fit-content;
		padding: 14px 20px 10px;
		margin-bottom: 12px;
	}

	.wrapper-1tWqN {
		flex-direction: column;
		align-items: flex-start;
		grid-gap: 8px;
		gap: 8px;
	}

	.title-2YuZN {
		font-size: 22px;
	}
}
