

button.button-1-Aet,
button.button-1-Aet:hover {
	display: inline-flex;
	align-items: center;

	border: 1px solid var(--brand1-3);

	color: var(--base);

	background: var(--brand1-5);
}

button.button-1-Aet:not(:last-child) {
	margin-right: 8px;
}
