/* stylelint-disable */
.slider__header-2MSZr,
.modal__heading-3YBz2 {
}
/* stylelint-enable */
.compareFares-3aDdR {
	padding: 40px;
}

.loaderWrp-13H7I {
	height: 100%;
	min-height: 50vh;
	display: flex;
	align-items: center;
	justify-content: center;
}

.loader-1QG9P {
	color: #b7b7b7 !important;
}

.row-34vF0 {
	display: flex;
}

.col-24mvO {
	display: flex;
	flex-direction: column;
}

.headingWrp-10hRq {
	position: relative;

	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: center;
	margin-right: 2px;
}

.selectedFare-3tePi {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	z-index: 1;

	border: 1px solid rgba(50, 144, 255, 0.6);

	background: rgba(50, 144, 255, 0.07);

	transition: height 100ms;
}

.fareHeadingItem-2gsq3 {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	grid-gap: 4px;
	gap: 4px;
	width: 100%;
	min-height: 56px;
	padding: 0 10px;

	color: var(--text-mid);
	font-size: 16px;
	font-weight: 500;
	line-height: 19px;

	background: var(--brand-1-4);
}

.fareHeadingItem__title-2snst {
	display: block;
}

.fareHeadingItem__serviceClass-YgHTW {
	display: block;
}

.fareHeadingItem__serviceClass-YgHTW,
.fareHeadingItem__fareTitle-1-jgK {
	display: block;

	text-align: center;
}

.fareHeadingItem_isKey-3QmKn {
	background: var(--seat-business);
}

.fareHeadingItem_isFirstClass-1A9xh {
	background: var(--seat-premium);
}

.availabilityIcon-21B2V {
	position: relative;
	top: 2px;

	color: var(--success-icon);
}

.title-97L7j {
	flex: 25%;

	min-width: 270px;
}

.tableRow-1Pr7O .title-97L7j {
	position: relative;
	left: 12px;

	display: flex;
	align-items: center;
	min-width: min-content;

	color: var(--text-base);
	font-weight: 700;
	font-size: 13px;
}

.slider-3tnLE {
	min-width: 0;
	flex: 75%;
}

.slider-3tnLE:first-child > div > div {
	overflow-y: visible;
}

.tableRow-1Pr7O .slider-3tnLE .slick-track {
	display: flex;
	align-items: center;
}

.tableRow_fullDescr-2UTmV .slider-3tnLE .slick-list {
	display: flex;
}

.tableRow_fullDescr-2UTmV .slider-3tnLE .slick-track {
	display: flex;
	align-content: flex-start;
}

.tableRow_fullDescr-2UTmV .slider-3tnLE .slick-slide {
	height: auto;
}

.slider-3tnLE .slick-prev svg {
	position: relative;
	left: -1px;
}

.slider-3tnLE .slick-next svg {
	position: relative;
	left: -1px;
}

.slider-3tnLE .slick-prev,
.slider-3tnLE .slick-next {
	top: 30px;
	left: -85px;

	display: flex !important;

	justify-content: center;
	align-items: center;

	width: 32px;
	height: 32px;

	border-radius: 50%;

	color: var(--text-light);

	box-shadow: 4px 7px 26px rgba(0, 0, 0, 0.1);

	background: var(--bg-white);
}

.arrows-2sz2L {
	display: flex;
	justify-content: flex-end;
	margin: 12px 10px 0 0;
}

.chevron-2QaEE {
	display: flex;

	justify-content: center;
	align-items: center;

	width: 32px;
	height: 32px;

	border-radius: 50%;

	cursor: pointer;

	background: #ededed;
}

.chevron_next-292pM {
	margin-left: 15px;

	transform: rotate(180deg);
}

.slider-3tnLE .slick-next {
	left: -42px;

	transform: rotate(180deg) translate(0, 50%);
}

.slider-3tnLE .slick-disabled svg {
	color: var(--text-light);

	opacity: 0.5;
}

.slider-3tnLE .slick-prev:before,
.slider-3tnLE .slick-next:before {
	display: none;
}

.slider-3tnLE .slick-next,
.slider-3tnLE .slick-prev,
.slider-3tnLE .slick-next:hover,
.slider-3tnLE .slick-prev:hover {
	color: #9a9a9a;

	background: #ededed;
}

.fareProps-3u_b3 {
	margin-top: 5px;
}

.value-1hJ_D {
	margin-right: 5px;
}

.fareGroupsWrp-tOZLm {
	margin-top: 200px;
}

.groupTitle-jdoMS {
	min-height: 40px;

	color: var(--brand-1-0);
	font-weight: 300;
	font-size: 24px;
	line-height: 31px;
	text-align: left;
	white-space: pre-line;
}

.groupTitle-jdoMS,
.headingCell-ATREk {
	padding: 30px 0 20px;

	border-bottom: 1px solid #ededed;
}

.headingCell-ATREk {
	min-height: 87px;
}

.cell-2pBNN {
	display: flex;
	justify-content: center;
	align-items: center;

	line-height: 16px;
	font-size: 14px;
	color: var(--dark-gray);
}

/* .cell_notFullFareConditions {
	max-width: 178px;
} */

.cellContent-2_kuP {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0 5px;

	font-size: 13px;

	text-align: center;
}

.cellContent_fullDescr-1N9LZ {
	padding: 0 10px;
}

.cellContent_shortDescr-2TyIy {
	margin-top: 15px;
}

.tableRow-1Pr7O {
	display: flex;
	height: max-content;
	padding: 12px 0;

	border-bottom: 1px solid var(--line-separator);
}

.tableRow-1Pr7O:nth-child(odd) .cellContent-2_kuP {
	font-weight: 700;
}

.tableRow-1Pr7O:nth-of-type(even) {
	background: #f6f6f6;
}

.cell_fareProperty-233t4 {
	justify-content: flex-start;
	padding-left: 12px;

	color: var(--dark-gray);
	font-weight: 500;
	font-size: 14px;
	line-height: 16px;
}

.link-3vwpi {
	margin-top: 45px;
	display: inline-block;

	font-weight: 500;
	text-decoration: none;
	font-size: 16px;
}

.link__wrapper-3gZ3h {
	display: flex;
	align-items: center;
	grid-gap: 4px;
	gap: 4px;
}

.linkIcon-3LvF_ {
	display: flex;
	align-items: center;
	justify-content: center;

	width: 18px;
	height: 18px;

	transform: rotate(180deg);
}

.linkIcon_return-3IR-9 {
	transform: none;
}

.notesHeading-2aNfE {
	margin: 40px 0 20px;

	color: var(--common-blue);
	font-weight: 300;
	font-size: 31px;
	line-height: 36px;
}

.notesList-2Cu6a {
	margin: 0;
	padding: 0;

	color: var(--dark-gray);
	font-size: 14px;
	line-height: 21px;

	list-style-type: none;
}

.notesListItem-5gdl4:not(:last-of-type) {
	margin-bottom: 20px;
}

.errorTitle-2wguf {
	margin: 0;

	color: var(--dark-gray);
	font-weight: 500;
	font-size: 22px;
	line-height: 26px;
}

.errorText-1K7Ch {
	line-height: 22px;
	color: var(--common-gray);
}

.image-1e4aa {
	max-width: 120px;
	height: auto;
}

@media (max-width: 1024px) {
	.headerTitle-dV1Kc {
		display: none;
	}

	div.compareFares-3aDdR {
		padding: 0;
		height: 100%;

		box-shadow: none;
	}

	.fareHeadingItem-2gsq3 {
		height: 66px;

		font-weight: 300;
		font-size: 21px;
		line-height: 25px;
	}

	.slider-3tnLE .slick-prev,
	.slider-3tnLE .slick-next {
		top: 12%;

		width: 40px;
		height: 40px;
	}

	.slider_fullConditions-2DEo_ .slick-prev,
	.slider_fullConditions-2DEo_ .slick-next {
		top: 50%;
	}

	.notesHeading-2aNfE,
	.notesList-2Cu6a {
		padding: 12px;
	}

	.slider-3tnLE .slick-prev {
		z-index: 1;
		left: 12px;
	}

	.slider-3tnLE .slick-next {
		right: 12px;
		left: auto;
	}

	.title-97L7j {
		flex: 50%;

		font-size: 14px;
		font-weight: 600;
	}

	.groupTitle-jdoMS {
		padding-bottom: 10px;
		padding-left: 12px;

		font-size: 24px;
	}

	.image-1e4aa {
		width: auto;
		height: 178px;
		margin: 0 auto;
		margin-top: 20px;
	}

	.link-3vwpi {
		display: flex;
		padding-left: 12px;
	}

	.headingWrp-10hRq {
		margin-right: 0;
	}

	.slider_withoutImages-3wsnu .slick-next,
	.slider_withoutImages-3wsnu .slick-prev {
		top: 28px;

		border: 2px solid rgba(255, 255, 255, 0.2);

		background: transparent;
	}

	.slider_withoutImages-3wsnu .slick-prev {
		left: 10px;
	}

	.slider_withoutImages-3wsnu .slick-next {
		right: 10px;
	}

	.slider_withoutImages-3wsnu .slick-next svg *,
	.slider_withoutImages-3wsnu .slick-prev svg * {
		fill: #ffffff;
	}

	.cell_notFullFareConditions-1G-rb {
		max-width: unset;
	}

	.slider-3tnLE .slick-next:hover,
	.slider-3tnLE .slick-prev:hover {
		background: unset;
	}

	.container-2ytsF {
		padding-bottom: 40px;
	}
}
