/* stylelint-disable */
.graphHeader-TrVIY {
}

/* stylelint-enable */
.container-1aPN9 {

	padding: 28px 30px;
	height: 350px;
}

@media (max-width: 1024px) {
	.container-1aPN9 {
		padding: 0 14px 60px 14px;
		max-width: 450px;
		height: auto;
	}
}

@media screen and (max-width: 600px) {
	.container-1aPN9 {
		max-width: 100%;
	}
}

.calendar-11zQ5 {

	padding-left: 0;
	padding-right: 0;
}

@media (max-width: 1024px) {
	.calendar-11zQ5 {
		padding-left: 50px;
	}
}

.graphHeader-TrVIY:after {
	left: 38px;
}
