.controls-3FnGU {
	position: absolute;
	top: 35px;
	left: 0;
	z-index: 2;

	width: 375px;
	padding: 5px 0;

	border-radius: 12px;

	box-shadow: 4px 4px 33px 10px rgba(0, 0, 0, 0.05);
	background: var(--white);
}

.controls__item-2M_Sn {
	display: flex;
	align-items: flex-end;
	padding: 12px 13px;

	font-size: 14px;

	color: var(--text-base);

	cursor: pointer;

	transition: background-color 0.3s;
}

.controls__item-2M_Sn:hover {
	background: var(--brand-1-5);
}

.controls__item-2M_Sn:not(:last-of-type) {
	border-bottom: 1px solid var(--seat-unavailable);
}

.controls__img-2m_WY {
	width: 20px;
	height: 20px;
	margin-right: 18px;

	color: var(--text-pale);
}

@media screen and (max-width: 600px) {
	.controls-3FnGU {
		bottom: 0;
		left: 0;
		right: 0;
		top: auto;

		width: auto;

		border-radius: 12px 12px 0 0;

		box-shadow: none;
	}

	.controls__item-2M_Sn {
		padding: 16px 13px;

		font-size: 16px;
	}
}
