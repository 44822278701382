.visaWrapper-o4r1R {
	margin: 12px 40px;
	padding: 28px;

	border-radius: 4px;

	box-shadow: 0 1px 8px rgba(0, 0, 0, 0.07);
	background: #ffffff;
}

@media screen and (max-width: 600px) {
	.visaWrapper-o4r1R {
		margin: 0;
	}
}

.passenger-Z0PpS {
	display: flex;
	justify-content: space-between;
}

.passenger__name-2bRV- {
	font-size: 20px;
	color: #292929;
	text-transform: capitalize;
	font-weight: 700;
}

.passenger__birth-1--dv {
	font-size: 20px;
	font-weight: 500;
	color: #bababa;
}

.group-3igay {
	display: flex;
	margin-top: 32px;
}

@media screen and (max-width: 600px) {
	.group-3igay {
		display: block;
		margin: 0;
	}
}

.group__field-2Cpd8 {
	display: flex;
}

.group__field-2Cpd8 > div {
	flex: 1;
}

.group__field-2Cpd8:first-child {
	flex: 1;
	margin-right: 28px;
}

.group__field-2Cpd8:only-child {
	margin-right: 0;
}

@media screen and (max-width: 600px) {
	.visaWrapper-o4r1R {
		margin: 12px 20px;
	}

	.group__field-2Cpd8:first-child {
		margin: 0;
	}

	.group__field-2Cpd8 > div {
		margin-top: 18px;
	}
}
