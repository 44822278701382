

.info-23IGF {
	font-size: 14px;
}

.segments-22e0C {
	padding: 28px 20px;
}

.footer-3CGsS {
	padding: 20px;

	text-align: right;
}

/* stylelint-disable */
button.button_default-3M0EG {
}

button.button-68pN0 {
}

button.button-68pN0:hover {
}
/* stylelint-enable */
