.root-1ItN6 {
	width: 660px;
	padding: 40px 40px 47px;
}

.title-2_cvG {
	font-size: 30px;
	font-weight: 500;
	color: var(--gray80);
}

.icon_wrapper-3c90E {
	position: relative;
	top: 4px;

	margin-right: 10px;
}

.message-13uf- {
	margin-top: 50px;

	color: var(--gray80);
	font-size: 16px;
	line-height: 1.4;
}

.row-2_i2t {
	display: flex;
	align-items: center;
	margin-top: 20px;
}

.input_wrapper-1r54- {
	flex-grow: 1;
}

.label-Cs-1o {
	display: block;
	margin-left: 5px;
}

.error-2jMDv {
	position: absolute;
	top: calc(100% - 7px);
	left: 36px;
}

/* stylelint-disable */
.checkbox-3G1YP {
}

.checked-2JGAZ {
}
/* stylelint-enable */

@media screen and (max-width: 600px) {
	.root-1ItN6 {
		display: flex;
		flex-direction: column;

		width: 100%;
		min-height: 100%;
		padding: 0;

		/* padding: 29px 28px; */
	}

	.title-2_cvG {
		display: flex;
		flex-direction: column;
		margin-top: auto;
	}

	.message-13uf- {
		margin-bottom: auto;
	}

	.icon_wrapper-3c90E {
		top: 0;

		margin-bottom: 25px;
	}

	.row-2_i2t {
		flex-direction: column;
		align-items: normal;
	}

	.button_wrapper-1Lsdj {
		margin-top: 20px;
	}
}
