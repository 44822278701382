

.day-34dxa {
	position: relative;

	min-height: 88px;
	height: 100%;

	display: flex;
	flex-direction: column;

	text-align: center;

	cursor: pointer;
	background: #ffffff;
}

.day_hidePrice-3sStl {
	justify-content: center;
}

.day_notAvailable-wYtBj {
	cursor: default;
}

.day_selected-316OM.day-34dxa {
	border-bottom: 3px solid var(--active);
}

.date-MZiKY {
	padding-top: 8px;

	color: #4d4d4d;
	font-weight: 600;
	font-size: 16px;
}

.day_notAvailable-wYtBj .date-MZiKY {
	color: var(--gray30);
}

.day_selected-316OM .date-MZiKY {
	font-size: 19px;
}

.price__wrapper-24fBY {
	position: relative;

	display: flex;
	flex-direction: column;
	justify-content: center;
	flex-grow: 1;
}

.day_notAvailable-wYtBj .price__wrapper-24fBY {
	width: 100%;

	background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iMTQiIHZpZXdCb3g9IjAgMCAxNCAxNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGNpcmNsZSBjeD0iNyIgY3k9IjciIHI9IjYuMjUiIHN0cm9rZT0iI0NEQ0RDRCIgc3Ryb2tlLXdpZHRoPSIxLjUiLz4KPGxpbmUgeDE9IjIuNDY5NjciIHkxPSIxMS40Njk3IiB4Mj0iMTEuNDY5NyIgeTI9IjIuNDY5NjciIHN0cm9rZT0iI0NEQ0RDRCIgc3Ryb2tlLXdpZHRoPSIxLjUiLz4KPC9zdmc+Cg==) center no-repeat;
}

.date__dow-2-AIr {
	position: relative;

	margin: 14px 25px 0 25px;
	padding-bottom: 8px;

	line-height: 16px;
}

.date__dow-2-AIr:after {
	position: absolute;
	right: 0;
	left: 0;
	z-index: 1;

	display: none;

	width: 76px;
	margin: auto;

	border-top: 1px dashed var(--gray20);
	content: '';
}

.date__info-27Er8 {
	position: absolute;
	top: -7px;
	right: 0;
	left: 0;
	z-index: 2;

	margin: auto;

	font-weight: 500;
	font-size: 12px;
	text-transform: capitalize;
}

.date__info-27Er8 span {
	padding: 0 4px;

	color: var(--gray30);

	background: #ffffff;
}

.day_notAvailable-wYtBj .date__info-27Er8 {
	color: #cdcdcd;
}

.price-30T0E {
	font-weight: 600;
	font-size: 26px;
	color: var(--base-30);
}

.day_selected-316OM .price-30T0E {
	font-size: 31px;
}

.day-34dxa.day_best-1onFj .price-30T0E {
	color: var(--success-20);
}
