

.wrapper-2QG9f {
	height: 100%;
	display: flex;
	flex-direction: column;
	min-width: 375px;
	max-width: 375px;
}

.cart-18YWV {
	flex: 1;
	overflow-y: auto;

	counter-reset: cart-counter;
}

.toolbar-3Jw_4 {
	flex: 0;
}
