.wrapper-2RCR2 {
	margin: 12px auto 0;
	max-width: 1100px;
	display: flex;
}

.wrapper-2RCR2 > div:not(:last-of-type) {
	margin-right: 13px;
}

.footer-1g0xI {
	padding: 20px 24px;
}

button.footer__button-1T6Rc {
	padding: 0 25px;
}

@media screen and (max-width: 600px) {
	.wrapper-2RCR2 {
		margin: 0;
		flex-direction: column;

		background: #ffffff;
	}

	.wrapper-2RCR2 > div:not(:last-of-type) {
		margin-right: 0;
	}
}
