

.container-1uQpV {
	display: flex;
	align-items: center;
	margin-top: 20px;
	margin-bottom: 12px;
	overflow-y: auto;
}

button.selector-3I5nK {
	flex-shrink: 0;
	width: 58px;
	min-width: auto;
	height: 58px;
	padding: 0;

	border-radius: 50%;

	font-size: 16px;
}

button.selector-3I5nK.selected-1nMY3 {
	color: #ffffff;

	background: var(--brand1);
}

button.selector-3I5nK:not(:last-child) {
	margin-right: 20px;
}

.description-3oVJC {
	color: var(--text-light);
	font-size: 14px;
	line-height: 150%;
}

.container-1uQpV.BaggageExcess-1QJr4 .description-3oVJC {
	display: none;
}

.footer-2bHyy {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 12px;
	padding: 12px 0;

	border-bottom: 1px solid #d9d9d9;

	color: var(--brand1);
	font-weight: 500;
	font-size: 14px;
}

.price-GNwjZ {
	color: var(--text);
	font-size: 16px;
	font-weight: 700;
}

.icon-2KwbG {
	width: 40px;
	height: 70px;
	margin-right: 20px;
	flex-shrink: 0;
}

.icon-2KwbG svg {
	width: 40px;
	height: 70px;
}
