.root-3ieQh {
	display: grid;
	grid-template-columns: 1fr max-content;
	align-items: center;
}

.passengers_wrapper-VP_9F {
	display: flex;
	align-items: center;

	min-width: 0;
}

.icon-1JFsG {
	margin-right: 8px;
}

.passengers-2ChWP {
	max-width: calc(100% - 32px);

	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
}

.control-1WooO {
	color: var(--base);
	font-size: 15px;

	cursor: pointer;
}

div.paper-1SgJb {
	max-width: 728px;
}

@media (max-width: 768px) {
	.root-3ieQh.account-MXu1H {
		font-weight: 500;
	}

	div.paper-1SgJb {
		max-height: 100%;
		overflow-y: auto;
	}
}

@media screen and (max-width: 600px) {
	/* stylelint-disable */
	div.bottomDrawer-1M2Vu {
	}
	/* stylelint-enable */
	div.paper-1SgJb {
		border-radius: 0;
	}
}
