.container-hBiX2 {
	display: flex;
	flex-direction: column;
	align-items: center;
	grid-gap: 20px;
	gap: 20px;
	padding: 20px;
	margin-top: 56px;
}

.icon-3VD61 {
	color: var(--base);
}

.icon-3VD61 > svg {
	display: flex;
	width: 100px;
	height: 100px;
}

.description-1ysr4 {
	display: flex;
	flex-direction: column;
	grid-gap: 20px;
	gap: 20px;

	color: var(--text-base);
	font-weight: 500;
	font-size: 20px;
	white-space: pre-line;
	text-align: center;
}

.buttons-GrXdm {
	display: flex;
	grid-gap: 20px;
	gap: 20px;
}

.buttons-GrXdm > button {
	font-size: 15px;
	font-weight: 500;
}

button.refuseBtn-1_UYS {
	color: var(--text-light);

	background: var(--bg-fill);
}

button.refuseBtn-1_UYS:hover,
button.refuseBtn-1_UYS:focus {
	background: var(--gray-line);
}

button.agreeBtn-2GTgB {
	background: var(--brand-3-0);
}

@media screen and (max-width: 600px) {
	.buttons-GrXdm > button {
		padding: 12px 20px;
	}

	div.scrollBody-2C6ip {
		display: flex;
		justify-content: flex-end;
	}

	div.scrollBody-2C6ip div.paper-wLWhq {
		width: 100%;
		min-height: auto;

		margin: auto 0 0 !important;

		border-radius: 8px 8px 0 0;
	}
}
