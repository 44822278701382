

.flights-SniXM {
	max-width: 1100px;
	margin: 28px auto 0;
}

div.flight-144mz:not(:last-child),
div.flight_noMargin-3iGDU {
	margin-bottom: 8px;
}

div.flight-144mz:only-child:not(.flight_noMargin-3iGDU),
div.flight-144mz:last-child:not(.flight_noMargin-3iGDU) {
	margin-bottom: 28px;
}

.wrapper__button-2egK- {
	position: absolute;
	top: 0;
	right: 0;
	left: 0;
	z-index: 1;

	max-width: 1100px;
	width: 100%;
	height: 60px;
	margin: 0 auto;
	display: flex;
	align-items: center;
	justify-content: flex-end;
}

button.button-1HnGq {
	height: 100%;

	border-radius: 0;

	font-weight: var(--toggleableFlights-button-fontWeight);
	font-size: 14px;
	line-height: 20px;
	color: #ffffff;

	background: var(--toggleableFlights-button-background);
}

button.button-1HnGq:hover {
	background: var(--toggleableFlights-button-background-hover);
}

.button__icon-16Hr5 {
	display: flex;
	align-items: center;
	margin-left: 4px;

	transition: transform 0.15s;
}

.button__icon_active-5-qN0 {
	transform: rotate(-180deg);
}

.content-1jB1f {
	background: #ededed;
}

.planeIcon-2rTlj {
	display: var(--toggleableFlights-button-plane-display);
	align-items: center;
	margin-right: 10px;

	transform: rotate(180deg);
}

.planeIcon-2rTlj svg {
	height: 20px;
	width: 20px;
}
