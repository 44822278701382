div.paper-16yIE {
	display: flex;
	flex-direction: column;
	grid-gap: 16px;
	gap: 16px;
}

.header-3Q2V9 {
	width: 100%;
	margin: 0;
	display: flex;
	flex-direction: row;
}

.content-1izyt {
	flex-grow: 1;

	color: var(--text-light);
	font: var(--medium--1-desktop);
	overflow-x: hidden;
}

div.dialogContent_root-1sUG- {
	padding: 0;
}

.title-3kVQs {
	color: var(--text-base);
	font: var(--medium-desktop-bold);
}

.footer-3ne0i {
	width: 100%;

	display: flex;
	align-items: center;
	flex-wrap: wrap;

	grid-gap: 12px;

	gap: 12px;

	margin: 0;
}

.buttonsExtra-3gpXD {
	display: flex;
	grid-gap: 12px;
	gap: 12px;
	align-items: center;
	justify-content: space-between;
	flex-wrap: wrap;
}

.buttons-IK6UE {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	grid-gap: 12px;
	gap: 12px;
	flex-grow: 1;
}

button.cancelButton-V7Qm7 {
	flex-basis: unset;
	padding: 10px 24px;

	border-radius: 4px;

	color: var(--text-light);
	font: var(--normal-desktop);

	text-align: center;

	background: var(--bg-fill);
}

button.cancelButton-V7Qm7:hover {
	color: var(--text-light);

	background: var(--bg-elements);
}

button.cancelButton-V7Qm7.disabled-15svl {
	color: var(--text-pale);

	background: var(--bg-fill);
}

button.confirmButton-2bwQ5 {
	flex-basis: unset;
	padding: 10px 24px;

	border-radius: 4px;

	color: var(--bg-white);
	text-align: center;
	font: var(--normal-desktop-bold);

	background: var(--brand-3-0);
}

button.cancelButton-V7Qm7:not(:only-child),
button.confirmButton-2bwQ5:not(:only-child) {
	flex-grow: 1;
}

button.confirmButton-2bwQ5:hover {
	color: var(--bg-white);

	background: var(--brand-3-1);
}

button.confirmButton-2bwQ5.disabled-15svl {
	color: var(--bg-white);

	background: var(--brand-3-3);
}

div.small-moYhH {
	justify-content: center;
}

div.medium-1Rkkn.buttons-IK6UE,
div.large-1Bcbh.buttons-IK6UE,
div.xlarge-2oWYW.buttons-IK6UE,
div.fullscreen-Avk2B.buttons-IK6UE {
	justify-content: flex-end;
}

div.medium-1Rkkn.buttons-IK6UE button.cancelButton-V7Qm7,
div.medium-1Rkkn.buttons-IK6UE button.confirmButton-2bwQ5,
div.large-1Bcbh.buttons-IK6UE button.cancelButton-V7Qm7,
div.large-1Bcbh.buttons-IK6UE button.confirmButton-2bwQ5,
div.xlarge-2oWYW.buttons-IK6UE button.cancelButton-V7Qm7,
div.xlarge-2oWYW.buttons-IK6UE button.confirmButton-2bwQ5,
div.fullscreen-Avk2B.buttons-IK6UE button.cancelButton-V7Qm7,
div.fullscreen-Avk2B.buttons-IK6UE button.confirmButton-2bwQ5 {
	flex-basis: unset;
	flex-grow: 0;
}

@media screen and (max-width: 600px) {
	div.medium-1Rkkn.buttons-IK6UE button.cancelButton-V7Qm7,
	div.medium-1Rkkn.buttons-IK6UE button.confirmButton-2bwQ5,
	div.large-1Bcbh.buttons-IK6UE button.cancelButton-V7Qm7,
	div.large-1Bcbh.buttons-IK6UE button.confirmButton-2bwQ5,
	div.xlarge-2oWYW.buttons-IK6UE button.cancelButton-V7Qm7,
	div.xlarge-2oWYW.buttons-IK6UE button.confirmButton-2bwQ5,
	div.fullscreen-Avk2B.buttons-IK6UE button.cancelButton-V7Qm7,
	div.fullscreen-Avk2B.buttons-IK6UE button.confirmButton-2bwQ5 {
		flex-basis: unset;
		flex-grow: 1;
	}

	div.buttons-IK6UE {
		flex-wrap: wrap-reverse;
	}
}
