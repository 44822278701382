.wrapper-3PZJK {
	display: flex;
	align-items: center;
}

.content-1pJ2O {
	min-width: 450px;
}

.icon-KLqdQ {
	margin-right: 10px;

	color: var(--gray50);

	transform: translateY(1px);
}

.link-3pK0D {
	flex-basis: 25%;
	margin-left: auto;

	font-weight: 400;
	font-size: 15px;
	text-align: right;
	text-decoration: none;
}

@media screen and (max-width: 600px) {
	.content-1pJ2O {
		min-width: 100%;
	}
}
