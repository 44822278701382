

span.checkbox-2WX5Q {
	width: 18px;
	height: 18px;

	color: var(--text-pale);
}

span.checkbox-2WX5Q.checked-fiHZU,
span.checkbox-2WX5Q.indeterminate-L4Cah {
	color: var(--brand-1-0);
}

span.checkbox-2WX5Q.required-1Jj0w:not(.checked-fiHZU) {
	color: var(--error-stroke-hover);

	background: var(--error-bg);
}

span.checkbox-2WX5Q.disabled-3Q9Yc {
	color: var(--line-dot-line);
}
