

.services-2If7H {
	display: flex;
	margin: 8px 0;
}

.service-1fr2F {
	flex: 1;
}

div.service-1fr2F:last-child {
	margin-right: 0;
}

.service-1fr2F.selected-34p_H {
	background: #ffffff;
	box-shadow: 0 1px 2px rgba(0, 0, 0, 0.08);
}

.selected-34p_H .header-1RIbF {
	color: var(--common-blue);
	text-decoration: underline;
}
