.wrapper-1-raE {
	display: flex;
	width: 100%;
	margin: 84px auto;
	max-width: 1100px;

	color: inherit;
}

.homeUserCard-LAFgZ {
	margin-bottom: 12px;

	border-radius: 8px;

	box-shadow: 4px 4px 33px rgba(0, 0, 0, 0.05);
	cursor: pointer;
	background: #ffffff;
}

.link-1mK1A {
	color: var(--warnings-text-color);
}

div.card-3hA8W {
	box-sizing: border-box;
}

div.warning-1sF5s {
	margin-top: 0;
}

.loginWrapper-3cb7e {
	margin: 100px auto;
	max-width: 660px;
	width: 100%;
	overflow: hidden;

	border-radius: var(--login-wrapper-border-radius);

	background-color: #ffffff;

	box-shadow: 2px 4px 40px rgba(0, 0, 0, 0.05);
}

.active__route-32Cyh {
	margin-left: 40px;
	flex-grow: 1;
	overflow: hidden;
}

.route__title-2_wJh {
	margin: 0 0 24px;

	font-weight: 700;
	font-size: 24px;
	color: var(--text-base);
}

.container__title-2Tclp {
	margin: 40px 0 24px;

	font-weight: 700;
	font-size: 24px;
	color: var(--text-base);
}

.container__content-2joNb {
	padding: 40px;

	border-radius: 9px;

	box-shadow: 4px 4px 33px rgba(0, 0, 0, 0.05);
	background: #ffffff;
}

.footer-3hNA_ {
	margin-top: 40px;
}

/* stylelint-disable */
.orders__wrapper-2CDvq {
}

.profileControls-3_S1w {
}

.container_authMethods-2SXlp {
}

.container_mailSettings-1MbGy {
}
/* stylelint-enable */
.tabs__flexContainer-1rvSQ {
	display: flex;
	grid-gap: 20px;
	gap: 20px;
}

.tabs__flexContainer-1rvSQ .tab-2SdeP {
	width: -moz-fit-content;
	width: fit-content;
	min-width: 0;
	min-width: initial;
	min-height: 30px;

	padding: 0;

	white-space: nowrap;
}

.tabs__flexContainer-1rvSQ .tabLabel-288kB {
	color: var(--text-light);

	text-transform: none;
}

.tabs__flexContainer-1rvSQ .tabSelected-2XJv9 .tabLabel-288kB {
	color: var(--brand-1-0);
}

.orders__header-3d4t9 .tabs-19Wmn {
	margin-top: 0;

	border-bottom: none;
}

.tabs-19Wmn .tab-2SdeP {
	margin: 0;
}

.tabs-19Wmn .tabs__container-28cyk .tabIndicator-2nXb0 {
	display: inline;
	display: initial;

	height: 3px;

	background-color: var(--brand-1-0);
}

.tabs-19Wmn .tabs__container-28cyk {
	min-height: 30px;
}

.noFlightsTitle-1IIRX {
	margin-bottom: 24px;

	font-size: 20px;
}

nav.pagination-1aLAa {
	margin-bottom: 0;
	margin-top: 20px;
}

@media (max-width: 1024px) {
	.active__route-32Cyh {
		position: relative;
		top: -8px;
		z-index: 1;

		margin-left: 0;
		max-width: 100%;
		padding: 16px 20px 0;

		border-radius: 8px 8px 0 0;

		overflow: hidden;

		background: #ededed;
	}

	.wrapper-1-raE {
		flex-direction: column;
		margin-top: 0;
	}

	.container-3DM6d {
		margin-top: 16px;

		border-radius: 9px;

		box-shadow: 4px 4px 33px rgba(0, 0, 0, 0.05);
		background: #ffffff;
	}

	.container__title-2Tclp {
		margin: 0;
		padding: 16px 20px;

		font-size: 18px;
	}

	.container__content-2joNb {
		padding: 0;

		box-shadow: none;
	}

	.route__title-2_wJh {
		display: none;
	}

	.tabs-19Wmn {
		width: 100%;
	}

	.tabs__flexContainer-1rvSQ .tabLabel-288kB {
		padding-bottom: 8px;
	}

	.noFlightsTitle-1IIRX {
		margin-bottom: 20px;

		font-size: 16px;
	}
}

.orderStub-1tZvG {
	margin-bottom: 20px;
}

.orders__header-3d4t9 {
	display: flex;
	justify-content: space-between;
	align-items: center;

	margin-bottom: 20px;
}

.orders__title-2VbBU {
	margin-bottom: 0;
}

@media screen and (max-width: 600px) {
	.account__fullscreen-1N7GA {
		padding: 0;
	}

	.route__title-2_wJh.orders__title-2VbBU {
		font-size: 18px;
	}

	.loginWrapper-3cb7e {
		height: 100vh;
		padding: 47px 28px;
		margin: 0;

		border-radius: 0;
	}
}

@media screen and (max-width: 380px) {
	.container__title-2Tclp {
		padding: 16px;
	}
}
