div.questionAnswerCard-138f5 {
	border-radius: 9px;

	box-shadow: 4px 4px 33px rgba(0, 0, 0, 0.05);
}

div.questionAnswerCard__header-29nig {
	padding: 24px;

	cursor: pointer;
}

@media screen and (max-width: 600px) {
	div.questionAnswerCard__header-29nig {
		padding: 20px;
	}
}

div.questionAnswerCard__headerAction-3E3z2 {
	margin: 0;
}

span.questionAnswerCard__headerTitle-165af {
	font-size: 18px;
	font-weight: 700;
	line-height: 1.3;

	color: var(--text);
}

@media screen and (max-width: 600px) {
	span.questionAnswerCard__headerTitle-165af {
		font-size: 16px;
	}
}

.questionAnswerCard__headerToggle-3zKCJ {
	display: flex;
	align-items: center;
	justify-content: center;

	color: var(--light-violet);

	transform: rotate(90deg);

	transition: transform 0.15s;
}

.questionAnswerCard__headerToggle_active-1_WjD {
	transform: rotate(-90deg);
}

div.questionAnswerCard__content-1zc9T {
	padding: 0 24px 24px;

	font-size: 16px;
	font-weight: 400;
	line-height: 20px;

	white-space: pre-line;

	color: var(--text-mid);
}

@media screen and (max-width: 600px) {
	div.questionAnswerCard__content-1zc9T {
		padding: 0 20px 20px;
	}
}
