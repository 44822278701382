.form-K9ejl {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	min-width: 446px;
}

@media screen and (max-width: 600px) {
	.form-K9ejl {
		min-width: auto;
	}
}

div.email-1rLa7 {
	margin-bottom: 18px;
}

.phone__input-1uMG5 .MuiInput-formControl:hover:before {
	border-bottom-color: var(--base) !important;
}

.email__input-lQLqL:hover:before {
	border-bottom-color: var(--base) !important;
}

div.button-1SPdT,
button.button-1SPdT {
	max-width: 120px;
	margin-left: auto;
	margin-top: 28px;
}
