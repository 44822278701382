

.checkbox-2gIBT {
	display: flex;
	align-items: center;
	padding: 8px;
}

span.checkbox__label-1gc0L {
	color: var(--text-base);
	text-transform: capitalize;
	font-size: 15px;
	line-height: 1.3;
}

span.checkbox__root-3V3sO.checkbox__checked-3WRkC {
	color: var(--base);
}

.checkbox__price-1_wrL {
	margin-left: auto;

	color: var(--text-light);
	font-size: 15px;
	line-height: 1.3;
}

.select-3Y2gl {
	width: 100%;
	margin-bottom: 16px;
}

.select-3Y2gl > svg {
	margin-right: 6px;
}

div.select_root-1Exh8 {
	padding: 18px 20px 18px 10px;

	border-radius: 4px;

	border: 1px solid var(--gray20);
}

.passenger-2Dhuc {
	padding: 6px 0;

	display: flex;
	align-items: center;

	border-bottom: 1px solid var(--line-separator);
}

.passenger-2Dhuc:first-child {
	border-top: 1px solid var(--line-separator);
}

.passenger_vipService-2FNzB {
	border-bottom: 1px dashed var(--line-separator);
}

.passenger_vipService-2FNzB:first-child {
	border-top: 1px dashed var(--line-separator);
}

span.label-2SDVV {
	color: var(--text-base);
	font-weight: 400;
	line-height: 1.3;
	text-transform: capitalize;
}

.price-3oTax {
	margin-left: auto;

	color: var(--text-light);
	font-weight: 400;
	font-size: 16px;
	line-height: 1;
}
