

.counter-3Ohaz {
	position: relative;

	display: flex;
	align-items: center;
	justify-content: space-between;
	min-width: 100px;
}

.onlyOneButton-1mXDM {
	justify-content: center;
}

.button-1kTCj {
	position: relative;

	display: flex;
	align-items: center;
	justify-content: center;

	padding: 0;

	min-width: 28px;
	max-width: 28px;
	min-height: 28px;
	max-height: 28px;

	border: none;
	border-radius: 50%;

	color: var(--base);

	cursor: pointer;
	background-color: var(--base80);

	transition: all 0.15s;
	transition-property: background-color, opacity;
}

.button_disabled-19J1M {
	color: var(--gray20);

	background-color: var(--gray05);

	cursor: default;
}

.button_readonly-2T0FU,
.value_hidden-32_P2 {
	visibility: hidden;
}

.button-1kTCj:not(.button_disabled-19J1M):hover {
	color: #ffffff;

	background-color: var(--base);
}

.value-K2g1c {
	position: absolute;
	left: 50%;

	color: var(--gray80);
	font-size: 20px;
	font-weight: 600;
	text-align: center;

	transform: translateX(-50%);

	transition: opacity 0.15s, color 0.15s;
}

.value_zero-3EgxO {
	color: var(--gray05);
}
