.months__wrapper-2ClOs {
	position: relative;

	border: none;
	border-radius: 4px;

	box-shadow: 0 6px 30px rgba(0, 0, 0, 0.05);
}

.months__wrapper-2ClOs nav {
	position: absolute;
	top: 50%;
	left: 50%;

	width: calc(100% + 30px);

	transform: translateX(-50%);
}

.months__prev-VjFP5 {
	left: 0;
}

.months__next-1So9z {
	right: 0;
}

.months__prev-VjFP5,
.months__next-1So9z {
	top: 50%;

	width: 30px;
	height: 30px;

	border-radius: 50%;

	color: transparent;

	box-shadow: 0 2px 14px rgba(0, 0, 0, 0.08);
	background: var(--white);
}

.months__prev-VjFP5:before,
.months__next-1So9z:before {
	content: '';

	position: absolute;
	top: 50%;
	left: 50%;

	width: 70%;
	height: 70%;

	background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEwIDZMOC41OSA3LjQxTDEzLjE3IDEyTDguNTkgMTYuNTlMMTAgMThMMTYgMTJMMTAgNloiIGZpbGw9IiM0RDRENEQiLz4KPC9zdmc+Cg==) no-repeat 50% 50% / contain;

	transform: translate(-50%, -50%);
}

.months__prev-VjFP5:before {
	transform: translate(-50%, -50%) rotate(180deg);
}

.month__header-1M7Qo {
	color: var(--text-base);
	font-size: 16px;
	font-weight: 500;
}

.month__week-20Mse:not(:last-child) {
	margin-bottom: 4px;
}

.header__day-3GBzO {
	line-height: 40px;
}

.header__day-3GBzO span {
	border-bottom: none;

	color: var(--text-light);
	font-weight: 400;
}

.header__day-3GBzO,
.day__day-2GMiz {
	width: 40px;
	height: 40px;

	font-size: 14px;
}

.day__day-2GMiz {
	display: flex;
	align-items: center;
	justify-content: center;

	color: var(--text-pale);
	line-height: 1.2;
}

.highlightDate-1Wrnb {
	color: var(--text-base);
}

/* stylelint-disable */
.hoverDate-Kkggi {
}

.day__inside-2DOTd {
}

/* stylelint-enable */
.day__today-1JtF4 {
	background: var(--brand-1-5);
}

.day__today-1JtF4:not(.day__selected-RButL):not(.day__inPeriod-2Rf4k) {
	border-radius: 30px;
}

.day__day-2GMiz.day__selected-RButL {
	background: var(--brand-1-0);
}

.day__inPeriod-2Rf4k {
	color: var(--white);

	background: var(--brand-1-0);
}

.day__weekend-3aNm3 {
	color: var(--text-pale);
}

.day__weekend-3aNm3.day__selected-RButL {
	background: var(--brand-1-0);
}

.day__weekend-3aNm3.highlightDate-1Wrnb {
	color: var(--text-base);
}

.day__weekend-3aNm3.day__inPeriod-2Rf4k {
	color: var(--white);
}

.day__start-2QcFZ,
.day__end-TeFk9 {
	color: var(--white);

	background: var(--brand-1-0);
}

.day__start-2QcFZ {
	border-radius: 30px 0 0 30px;
}

.day__end-TeFk9 {
	border-radius: 0 30px 30px 0;
}

@media screen and (max-width: 600px) {
	.months__wrapper-2ClOs {
		box-shadow: none;
	}

	.months__wrapper-2ClOs nav {
		position: relative;
		top: 0;
		left: 0;

		width: 100%;

		transform: none;
	}

	.months__list-34Tr9 {
		display: flex;
		flex-direction: column;
		align-items: center;
		grid-gap: 20px;
		gap: 20px;
	}

	.month-3pYfu {
		padding-right: 0;
	}
}
