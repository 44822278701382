

.passengersForm-TZvGn {
	max-width: 1100px;
	display: flex;
	flex-direction: column;
	margin: 50px auto 0;
}

button.submit-2UUmi {
	margin-top: 24px;
	margin-left: auto;
}

div.passenger-2Gtpf {
	max-width: 100%;
}
