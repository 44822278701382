.card-NcXzd {
	position: relative;

	display: grid;
	grid-template-columns: 1fr 20px;
	grid-gap: 12px;
	gap: 12px;
	align-items: center;
	padding: 24px;

	border: 1px solid var(--white);
	border-radius: 4px;

	color: var(--text-base);

	background-color: var(--white);
}

.card_unread-3ZFQB {
	border-color: var(--brand-1-3);

	background-color: var(--brand-1-5);
}

.card__wrapper-1pLzy {
	display: flex;
	flex-direction: column;
	grid-gap: 8px;
	gap: 8px;
}

.card__group-2qISE {
	display: flex;
	align-items: center;
	justify-content: space-between;
	grid-gap: 4px;
	gap: 4px;
}

.card__group_content-1gxdN {
	grid-gap: 12px;
	gap: 12px;
}

.card__group_content-1gxdN p {
	margin: 0;
}

.card__title-12GGj {
	font-weight: 700;
}

.card__info-iBqT5 {
	font-size: 14px;
	color: var(--text-light);
	line-height: 18px;
	text-align: left;
	font-weight: 400;
}

.card__info_right-1b0Vi {
	margin-left: auto;

	text-align: right;
}

.card__loading-3XTiD {
	position: absolute;
	top: 0;
	left: 0;

	justify-content: center;
	width: 100%;
	height: 100% !important;

	background: rgba(255, 255, 255, 0.4);
}

.card__dot-2-6pn {
	display: flex;
	min-height: 28px;
}

.card__dot-2-6pn span {
	margin: auto;
}

@media screen and (max-width: 600px) {
	.card-NcXzd {
		padding: 16px;
		display: block;
	}
}
