.image-32uxo {
	height: 300px;

	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
}

@media screen and (max-width: 600px) {
	.image-32uxo {
		height: 200px;
	}
}

.details-1qYS9 {
	background-color: #f0f0f0;
}

.details__header-2aJ99 {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 20px 40px;

	font-size: 15px;
}

@media screen and (max-width: 600px) {
	.details__header-2aJ99 {
		padding: 20px;
	}
}

.details__body-C8lya {
	padding: 24px 40px;

	border-top: 1px solid #d9d9d9;

	color: var(--common-gray);
	font-size: 16px;
	line-height: 150%;
}

@media screen and (max-width: 600px) {
	.details__body-C8lya {
		padding: 24px 20px;
	}
}

.allergens-3DAoA {
	margin-top: 12px;
}

.allergens__header-2zjO0 {
	font-weight: 600;
}

.title-15fY1 {
	margin: 0;

	font-size: 15px;
	font-weight: 600;
	line-height: 150%;
	text-transform: uppercase;
}

.price-11ggR {
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	margin-left: 25px;

	line-height: 23px;
}

.price__money-2xD5A {
	padding-left: 0;

	font-weight: 600;
	color: var(--common-blue);
	text-transform: uppercase;
}

.text-1xwA- {
	padding: 24px 40px 12px;

	color: var(--common-gray);
	font-size: 16px;
	line-height: 150%;
}

@media screen and (max-width: 600px) {
	.text-1xwA- {
		padding: 24px 20px;
	}
}
