

.badge-2Q5k6 {
	position: absolute;
	top: -18px;
	right: 10px;

	-webkit-user-select: none;

	    -ms-user-select: none;

	        user-select: none;
}

svg.badge-2Q5k6 {
	color: var(--active);
}

div.tooltip-RruVO {
	margin-top: -7px;
}

.inner-1mZN0 {
	color: inherit;
}

.shadow-1Wwyq {
	color: inherit;
}

.wrapper-2z9jX {
	color: inherit;
}
