.title-2YuQt {
	line-height: 40px;
	font-size: 22px;
}

.text-1q0IR {
	font-size: 18px;
	line-height: 150%;
	color: var(--common-gray);
}

button.button-125-h {
	padding: 0 25px;

	/* margin-left: auto;  -- if modal size === small and only 1 button in footer */
}

@media screen and (max-width: 600px) {
	button.button-125-h {
		flex-grow: 1;
	}
}
