

.wrapper-17Xx_ {
	max-width: 720px;
	margin: 0 auto;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	border-radius: 4px;

	background: #ffffff;
	box-shadow: 0 24px 84px rgba(0, 0, 0, 0.13);
}

.timeImage-6n6la {
	margin: 40px auto 28px;
}

.text-352c3 {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin: 0 20px;

	text-align: center;
}

.text-352c3 > span {
	font-weight: 300;
	font-size: 27px;
	line-height: 37px;
	color: #9a9a9a;
}

.wrapper-17Xx_ button.button-1GVb_ {
	height: 48px;
	margin: 28px 0 40px;

	border-radius: 24px;

	font-size: 17px;
	line-height: 24px;
	font-weight: 500;

	background: #ff9e0c;
}
