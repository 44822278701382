

div.root-B5uXT {
	position: relative;
}

label.textField__root-1xw4_ {
	z-index: 2;

	width: 100%;

	color: var(--text-light);
	font: var(--medium--1-desktop);
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;

	transform: translate(10px, 20px) scale(0.9);

	pointer-events: none;
}

label.label_shrink-zyMFU {
	font: var(--small-desktop);

	transform: translate(0, -1.5px) scale(0.75);
}

label.textField__root-1xw4_.textField-2_Hv5.textField_outlined-2wdHJ:not(.label_error-2Us_v) {
	color: var(--cabinet-action);
}

div.input-3gx0U.disabled-13BuC:before,
div.real_input-2DNLc.disabled-13BuC:before {
	background: var(--input-background-disabled);
}

div.real_input-2DNLc.disabled-13BuC {
	color: var(--gray90);
}

div.input-3gx0U {
	z-index: 1;

	position: relative;

	font-family: inherit;
}

div.input-3gx0U:before {
	content: '';

	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: -1;

	display: block;
	height: 56px;

	background: var(--input-background);
}

div.input-3gx0U.input_filled-1Rwkr:before {
	border-radius: var(--input-border-radius);
}

input.real_input-2DNLc,
div.real_input-2DNLc {
	z-index: 1;

	box-sizing: border-box;
	height: 56px;
	padding: 12px 9px 9px;

	border: 1px solid transparent;

	font-size: 16px;
	line-height: 20px;
	color: var(--gray90);
}

.loader-3XjGe {
	position: absolute;
	right: 0;
	top: 0;
	bottom: 0;

	margin: auto;
}

p.hint-IXbhP {
	margin: 4px 8px 0;

	font: var(--small-desktop-mini);
}

p.hint_error-13fky {
	position: absolute;
	bottom: 0;

	margin-top: 0;
	width: 100%;

	font-family: inherit;
	white-space: nowrap;
	text-overflow: ellipsis;
	letter-spacing: normal;
	line-height: inherit;

	transform: translateY(140%);
	overflow: hidden;
}

p.hint_error-13fky.Mui-error {
	color: var(--error-icon);
}

.input_focused-2z0zd.input-3gx0U:before {
	background: transparent;
}

.input_focused-2z0zd:not(.input_outline-1r-Iu) input.real_input-2DNLc,
.input_focused-2z0zd:not(.input_outline-1r-Iu) div.real_input-2DNLc {
	background: var(--input-background-active);
}

div.textField_outlined-2wdHJ {
	border-radius: var(--input-border-radius);
}

div.input-3gx0U.input_filled-1Rwkr {
	border-radius: var(--input-border-radius);

	background: var(--input-background);
}

div.input-3gx0U.input_filled-1Rwkr .real_input-2DNLc,
div.input-3gx0U.input_standard-WsrtH .real_input-2DNLc {
	padding: 26px 12px 9px;
}

fieldset.input_outline-1r-Iu,
div.input-3gx0U.input_filled-1Rwkr.disabled-13BuC,
div.input-3gx0U.input_filled-1Rwkr .real_input-2DNLc,
div.input-3gx0U.disabled-13BuC .input_outline-1r-Iu {
	border: 1px solid var(--input-outline);
	border-radius: var(--input-border-radius);
}

div.input-3gx0U.input_filled-1Rwkr.disabled-13BuC .real_input-2DNLc {
	border: none;
}

div.input-3gx0U.input_focused-2z0zd .input_outline-1r-Iu,
div.input-3gx0U.input_focused-2z0zd.input_filled-1Rwkr .real_input-2DNLc {
	border: 1px solid var(--input-outline-active);
	border-radius: var(--input-border-radius);
}

label.textField_filled-1v5xc.textField_focused-hrjW3:not(.label_error-2Us_v),
label.textField_filled-1v5xc.textField_focused-hrjW3.label_error-2Us_v,
label.textField_standard-3uHgP.textField_focused-hrjW3 {
	color: var(--input-color);
}

div.input-3gx0U.input_error-EjYBC.input_filled-1Rwkr .real_input-2DNLc,
div.input-3gx0U.input_error-EjYBC.input_filled-1Rwkr.input_focused-2z0zd .real_input-2DNLc {
	border-color: var(--input-error-border);

	background: var(--input-error-background-filled);
}

div.input-3gx0U.input_outline-1r-Iu.input_error-EjYBC fieldset.input_outline-1r-Iu {
	border-color: var(--input-error-border);
}

div.underline-WOzAV:before {
	border-color: var(--input-outline);
}

div.underline-WOzAV:after {
	border-color: var(--input-outline-active);
}

div.input-3gx0U.underline-WOzAV {
	margin-top: 0;
}

label.textField__root-1xw4_.textField_focused-hrjW3.textField_standard-3uHgP,
label.textField_standard-3uHgP.label_shrink-zyMFU {
	z-index: 2;

	transform: translate(12px, 10px) scale(0.75);
}

div.input-3gx0U.input_standard-WsrtH input.real_input-2DNLc,
div.input-3gx0U.input_focused-2z0zd.input_standard-WsrtH input.real_input-2DNLc {
	border-radius: var(--mobile-input-border-radius);

	appearance: none;
}

.valueFilled-1OGY- {
	font-family: inherit;
}

.nationality-3mgOZ label.MuiInputLabel-animated {
	transition: color 200ms cubic-bezier(0, 0, 0.2, 1) 100ms, transform 200ms cubic-bezier(0, 0, 0.2, 1) 100ms;
}

.nationality-3mgOZ .optionLabel-2H0Fw {
	font-size: 16px;
}
