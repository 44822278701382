

.service-2uhIE {
	display: flex;
	align-items: center;
	padding: 16px;

	border-radius: 4px;

	background: var(--brand-1-5);
}

.service__icon-1JmJ- {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 32px;
	min-width: 32px;
	height: 32px;
	margin-right: 8px;

	color: var(--brand-1-0);
}

.service__info-3nRlZ {
	display: flex;
	flex-direction: column;
}

.service__name-2-ows {
	color: var(--text-base);
	font-size: 16px;
	font-weight: 500;
	line-height: 1.3;
}

.service__description-3fl4X {
	color: var(--text-light);
	font-size: 14px;
	font-weight: 400;
	line-height: 1.3;
}
