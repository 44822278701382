.modal__paper-3quC9 {
	width: max-content;
}

div.modal__root-3WtCg {
	z-index: 1501 !important;
}

.root-cKWjX {
	width: 728px;
	padding: 0 34px 40px;
}

.row-1ceox {
	display: flex;
}

.body-2dPJg {
	margin-top: 20px;
}

.modalHeader-vOjUe {
	position: relative;

	width: 100%;
	padding: 30px 35px;

	color: var(--text);
	font-weight: 700;
	font-size: 22px;
}

.close-3_-RA {
	position: absolute;
	top: 28px;
	right: 32px;

	margin: auto;

	cursor: pointer;
}
/* stylelint-disable */
.withTabs-3OpRc {
}
/* stylelint-enable */

@media (max-width: 768px) {
	.modal__paper-3quC9 {
		width: auto;
	}

	.root-cKWjX {
		width: 100%;
	}
}

@media screen and (max-width: 600px) {
	.row-1ceox {
		display: block;
	}

	.root-cKWjX {
		width: 100vw;
		padding: 12px;
	}

	.modalHeader-vOjUe {
		position: relative;

		display: flex;
		align-items: center;
		height: 56px;
		padding: 0 15px;

		color: #262626;
		font-weight: 600;
		font-size: 18px;

		box-shadow: 0 7px 20px rgba(0, 0, 0, 0.03);
	}

	.close-3_-RA {
		position: absolute;
		right: 12px;
		top: 12px;

		cursor: pointer;
	}
}
