.root-2kjAD {
	font-size: 16px;
	font-weight: 500;
}

.root-2kjAD.type_review-3HgPe.online_check_in-ASasQ {
	background: var(--warning);
}

.root-2kjAD.type_review-3HgPe.wait_payment-CP0Ne {
	color: var(--white);

	background: var(--error);
}

.root-2kjAD.type_review-3HgPe.confirmed-vDtff,
.confirmed-vDtff .icon-1EF9y {
	color: var(--success);
}

.root-2kjAD.type_account-17f4n.online_check_in-ASasQ {
	background: var(--warning);
}

.root-2kjAD.type_account-17f4n.wait_payment-CP0Ne {
	color: #ffffff;

	background: var(--error);
}

.root-2kjAD.type_account-17f4n.confirmed-vDtff {
	color: var(--success);
}

.root-2kjAD svg {
	display: block;
}

.content-2ccsw {
	display: flex;
	align-items: center;
	width: 100%;
}

.text-qfAIF {
	margin-left: 8px;

	font-weight: 500;
}

.transactions_wrapper-230jf {
	margin-left: auto;
}

.transactions-3sToO {
	display: none;

	color: var(--base);
	font-size: 15px;

	cursor: pointer;
}

.canceled-20gkG {
	color: var(--error-text);
}

.canceled-20gkG svg {
	color: var(--error-icon);
}

@media (max-width: 768px) {
	.transactions-3sToO {
		font-weight: 400;
	}
}
