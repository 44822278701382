
/* stylelint-disable */
.root-3Rbsk {
}

.root_zeroPrice-1iZ3r {
}

.root__text-ZbYMa {
	display: none;
}

.root__money-3X0dA {
}

.money-DpyXC {
}

.amount-lHS1B {
}

.currency-2N1SR {
}
/* stylelint-enable */
