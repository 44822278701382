

.segment-3POQ9 {
	display: flex;
}

.points-3oSGI {
	width: 35%;
}

.duration-DfhlV {
	margin-left: 4px;

	padding: 12px 0 12px 16px;

	border-left: 2px solid #cdcdcd;

	color: var(--common-gray);
	line-height: 21px;
}

.weather-22-y6 {
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	width: 15%;
	margin-left: 30px;

	font-weight: 500;
}

.weather__row-s6-Or {
	display: flex;
	align-self: center;
	width: 100%;
}

.weather__icon-1m2R4 {
	margin-right: 10px;
	display: inline-flex;
	align-self: center;

	color: var(--common-gray);
}

.weather__temperature-PiCH_ {
	display: inline-flex;
	align-self: center;
}

.flight-3jNHi {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	flex-grow: 1;
}

.flight__infoIcon-6-opm {
	display: flex;
	margin-left: 4px;

	line-height: 20px;

	cursor: help;

	opacity: 0.4;

	transition: opacity 0.15s;
}

.flight__infoIcon-6-opm:hover {
	opacity: 1;
}

.flight__ecoIcon-1nJ7h {
	display: flex;
	margin-left: 4px;

	line-height: 20px;

	cursor: help;
}

.airline-2F9-u {
	display: flex;
	align-items: center;
}

.logo-3xFeE {
	height: 20px;
	width: 20px;
	margin-right: 8px;
}

.logo-3xFeE img {
	width: 100%;
	height: 100%;
}

.plane-IoF-A {
	width: 210px;
	height: 70px;
	overflow: hidden;
}

.plane__image-6cddZ {
	width: 100%;
}

.plane__image__stub-2WGIl {
	width: 210px;
	height: 70px;

	background-image: url('https://cdn.websky.aero/content/frontend/images/mock-plane.png');
	background-repeat: no-repeat;
}

.transfer-mGLo6 {
	margin: 20px 0;
}

.nextDayArrival-3lwua {
	margin-left: 12px;

	color: #c21212;
}

.aircraft__name__link-3GWvU {
	display: flex;
}

.aircraft__name-DINef {
	display: none;
}
