.segment-9Ou1u:not(:last-of-type) {
	margin-bottom: 16px;
}

@media (max-width: 768px) {
	.segment-9Ou1u {
		max-width: 420px;
		margin: 0 auto;
	}
}

.segment__info-1cvsO {
	display: flex;
	justify-content: space-between;
}

@media (max-width: 768px) {
	.segment__info-1cvsO {
		flex-direction: column;
	}
}

.segment__location-32Jfp {
	max-width: 280px;
}

@media (max-width: 768px) {
	.segment__location-32Jfp {
		max-width: 100%;
	}
}

.segment__flight-1yrIm {
	flex-grow: 1;
	margin: 0 12px;
}

@media (max-width: 768px) {
	.segment__flight-1yrIm {
		margin: 13px 0;
	}
}

.segment__transfer-pkflC {
	margin-top: 16px;

	border-radius: 4px;

	font-weight: 500;
	font-size: 16px;

	background-color: #ffffff;
}
