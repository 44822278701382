.container_dropdown-3yPFL {
	border-radius: 0 0 4px 4px;
	border-left: 1px solid var(--line-separator);
	border-right: 1px solid var(--line-separator);
	border-bottom: 1px solid var(--line-separator);

	overflow: hidden;
}

.content-3_W1_ {
	display: flex;
	max-width: 1100px;
	align-items: flex-start;
	margin: 0 auto;
	padding: 20px 0 40px;
}

.container_dropdown-3yPFL .content-3_W1_ {
	align-items: unset;

	padding: 0;
}

.content-3_W1_.content__center-1RxaL {
	justify-content: center;
}

.flight-1nXAT {
	flex: 1 1 auto;
	min-width: 35%;
	max-width: 100%;

	min-height: 534px;
}

.container_dropdown-3yPFL .flight-1nXAT {
	min-width: 42%;

	border-right: 1px solid var(--line-separator);
	border-radius: 0;

	box-shadow: none;
}

.flight-1nXAT.flight_thin-1onC6 {
	flex: 0;
}

.segments-1OijC {
	flex: 0 1 50%;
	display: flex;
	margin-left: 20px;
}

.segments-1OijC.segments__big-2EcEF {
	flex: 0 1 75%;
	width: 75%;
}

.container_dropdown-3yPFL .segments-1OijC {
	flex: 0 1 100%;

	margin-left: 0;
}

.segment__flightWrapper-3J4wr,
.segment__card-2_Rix {
	flex: 1;
}

.segment__flight-3zv4b {
	flex: 1;
	min-height: 534px;
	display: flex;
	flex-direction: column;
	margin-right: 20px;
}

.segment__flight-3zv4b:last-child {
	margin-right: 0;
}

.container_dropdown-3yPFL .segment__flight-3zv4b:not(:last-child) {
	margin-right: 0;

	border-right: 1px solid var(--line-separator);
}

.time-tXBw1 {
	margin-top: 10px;
	padding: 12px 20px;

	border: 1px solid #d9d9d9;
	border-radius: 4px;

	font-weight: 500;
	font-size: 14px;
	line-height: 1.3;

	background-color: var(--warning20);
}

.container_dropdown-3yPFL .time-tXBw1,
.container_dropdown-3yPFL .segment__transfer-vgn2Y {
	margin-top: 0;

	border: none;
	border-top: 1px solid var(--line-separator);
	border-radius: 0;
}

@media screen and (max-width: 600px) {
	.content-3_W1_ {
		flex-direction: column;
		padding: 20px 12px 40px;
	}

	.flight-1nXAT {
		width: 100%;
		margin-bottom: 20px;
		flex: 1;
	}

	.segments-1OijC {
		flex-direction: column;
		margin: 0;
	}

	.segments-1OijC.segments__big-2EcEF {
		flex: 1;
		width: 100%;
	}

	.segment__flight-3zv4b {
		min-height: auto;
		margin-right: 0;
		margin-bottom: 10px;
	}
}
