

.aeroexpress__content-2hZ5p {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	flex: 1;
}

.description-3wzWN {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	flex: 2;

	text-align: justify;
}

.selectedService-2upw4 {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	height: 45%;
	padding: 8px 0;

	border-bottom: 1px dashed #d9d9d9;

	text-align: justify;
}

.selectedService-2upw4:first-of-type {
	padding-top: 0;
}

.selectedService__content-3oe1G {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
}

.selectedService__date-ImrX-,
.selectedService__info-3bSqt {
	font-size: 15px;

	color: var(--dark-gray);
}

.selectedService__date-ImrX- {
	text-transform: lowercase;
}

.selectedService__serviceClass-1GoWK {
	text-transform: capitalize;
}

.selectedService__price-WnVoj {
	font-weight: 600;
	color: var(--common-blue);
}

.description_not_selected-2qv98 {
	font-size: 15px;
}

.schedule-dh3Ol {
	margin-top: 10px;

	font-size: 15px;
}
