

.container-1uq0g {
	position: relative;

	padding-left: 32px;

	color: var(--error-text);
	font-weight: 400;
	font-size: 14px;
	line-height: 1.3;
}

.icon-2Izaw {
	position: absolute;
	top: 0;
	left: 0;

	width: 24px;
	height: 24px;

	display: flex;
	align-items: center;
	justify-content: center;

	color: var(--error-icon);
}
