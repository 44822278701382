.wrapper-2MbJK {
	padding: 40px 28px 28px;
}

.header-1rphn {
	font-weight: 500;
	font-size: 22px;
	line-height: 32px;

	color: var(--dark-gray);
}

.desc-1KG17 {
	font-size: 15px;
	line-height: 28px;
	color: var(--common-gray);
}

@media screen and (max-width: 600px) {
	.header-1rphn {
		font-size: 18px;
	}

	.desc-1KG17 {
		margin-top: 16px;

		font-size: 14px;
	}
}

.buttons-31cmo {
	display: flex;
	justify-content: flex-end;
	margin-top: 28px;
}

@media screen and (max-width: 600px) {
	.wrapper-2MbJK {
		padding: 20px;
	}

	.buttons-31cmo button {
		font-size: 13px;
	}
}
