.travelCompanionFields-1rcvU {
	display: flex;
	width: 100%;
	margin-top: 20px;
}

.travelCompanionFields-1rcvU .formLabel-1R-mS {
	margin-bottom: 21px;

	font-size: 18px;
	font-weight: 400;
	line-height: 1.3;

	color: var(--text-light);
}

@media screen and (max-width: 600px) {
	.travelCompanionFields-1rcvU .formLabel-1R-mS {
		display: none;
	}
}

.travelCompanionFields-1rcvU .formGroup-3AEF_ {
	display: flex;
	width: 100%;
	grid-gap: 22px;
	gap: 22px;
}

@media screen and (max-width: 600px) {
	.travelCompanionFields-1rcvU .formGroup-3AEF_ {
		grid-gap: 10px;
		gap: 10px;
	}
}

.formGroup-3AEF_ .controlLabel-3fHYQ {
	grid-gap: 19px;
	gap: 19px;
	margin-right: 0;
	margin-left: 0;
}

@media screen and (max-width: 600px) {
	.formGroup-3AEF_ .controlLabel-3fHYQ {
		padding: 21px;
		grid-gap: 10px;
		gap: 10px;

		border-radius: 6px;

		background-color: var(--white);
	}
}

.controlLabel-3fHYQ .checkbox-3EaaF ~ span {
	width: 100%;
}

.controlLabel-3fHYQ .checkbox-3EaaF svg {
	fill: var(--text-pale);
}

.controlLabel-3fHYQ .checkbox-3EaaF.checkbox__checked-1vhId {
	color: var(--light-violet);
}

.controlLabel-3fHYQ .checkbox-3EaaF.checkbox__checked-1vhId svg {
	fill: var(--light-violet);
}

.controlLabel-3fHYQ .checkbox-3EaaF.checkbox__error-CAITD svg {
	fill: var(--bright-red);
}

.fieldLabel-3YtPU {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
}

.fieldLabel__name-CRVTj {
	font-size: 18px;
	font-weight: 500;
	line-height: 1.3;

	color: var(--text);
}

.fieldLabel__date-1xkgu {
	font-size: 16px;
	font-weight: 400;
	line-height: 1.3;

	color: var(--text);
}

.travelCompanionFields__errorMessage-22AOv {
	margin: 10px 8px 0;

	font-size: 14px;

	color: var(--bright-red);
}
