.addSelectedMemberForm-3Dbls {
	display: grid;
	margin-top: 20px;
}

@media screen and (max-width: 600px) {
	.addSelectedMemberForm-3Dbls {
		margin-top: 10px;
	}
}
