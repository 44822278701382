.container-13ZQz {
	display: flex;
	min-height: 224px;
}

.card-suYoW {
	flex-shrink: 0;
}

.content-1WBcY {
	flex: 1;
	padding: 20px;

	border-radius: 0 6px 6px 0;

	background-color: var(--white);
}

@media screen and (max-width: 600px) {
	.container-13ZQz {
		flex-direction: column;
	}

	.content-1WBcY {
		border-radius: 0 0 6px 6px;
	}

	.footer-1vNTm {
		flex-direction: column;
		align-items: inherit;
	}

	.links-3jUFf {
		margin-top: 20px;
	}

	button.button-lSOCf {
		margin-right: 0;
		margin-bottom: 10px;
	}

	button.button-lSOCf:last-child {
		margin-bottom: 0;
	}

	div.modal_scrollBody-3MCRd {
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
	}

	div.modal_scrollBody-3MCRd div.modal_paper-lf1VQ,
	div.modal_scrollBody-3MCRd div.modal_recovery_paper-14QQA,
	div.modal_scrollBody-3MCRd div.modal_connect_paper-3MSwq {
		min-height: auto !important;

		border-radius: 0;

		overflow: auto;
	}

	button.menu-2ZgDa {
		position: absolute;
		right: 20px;
		top: 0;

		transform: translateY(-50%);
	}
}
