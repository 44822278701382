.title-bfmBC {
	position: relative;

	width: 100%;
	max-width: 1100px;

	margin: 0 auto 20px;
	padding-left: 52px;

	color: var(--text-base);

	font-size: 24px;
}

.title-bfmBC:before {
	position: absolute;
	top: 50%;
	left: 0;

	display: flex;
	justify-content: center;
	align-items: center;
	width: 36px;
	height: 36px;

	border-radius: 50%;

	transform: translateY(-50%);
	background: var(--success-icon);
	content: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMTUiIHZpZXdCb3g9IjAgMCAyMCAxNSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik05LjU1MzIgMTIuNzMwOUwxOS44NzQgMi40MTAxNEwxOC4xMDYyIDAuNjQyMzc0TDcuNzg1NDQgMTAuOTYzMkwyLjczNTM1IDUuOTEzMDlMMC45Njc1ODMgNy42ODA4NUw2LjAxNzY3IDEyLjczMDlMNi4wMTc1NCAxMi43MzExTDcuNzg1MyAxNC40OTg4TDcuNzg1NDQgMTQuNDk4N0w3Ljc4NTkgMTQuNDk5Mkw5LjU1MzY2IDEyLjczMTRMOS41NTMyIDEyLjczMDlaIiBmaWxsPSJ3aGl0ZSIvPgo8L3N2Zz4K);
}

.wrapper-2hV6d {
	display: flex;
	flex-direction: column;
	margin-bottom: 28px;
	padding: 36px 0 16px;

	box-shadow: 0 7px 20px rgba(0, 0, 0, 0.05);
	background: var(--white);
}

.price-2N0yM {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 12px;
	width: 100%;

	border-radius: 4px;

	font-size: 19px;
	font-weight: 600;
	line-height: 30px;

	background-color: #ffffff;
	box-shadow: 0 1px 2px rgba(0, 0, 0, 0.08);
}

.flight-lEwrQ {
	margin: 0 auto 0;
	max-width: 1100px;
	width: 100%;
}

@media (max-width: 1024px) {
	.title-bfmBC {
		margin-left: 12px;
	}

	.wrapper-2hV6d {
		width: calc(100% + 80px);

		margin-left: -40px;
		padding-left: 40px;
		padding-right: 40px;
	}
}

@media (max-width: 1024px) and (min-width: 601px) {
	.flight-lEwrQ:last-of-type:not(:last-child) {
		margin-bottom: 12px;
	}
}

@media screen and (max-width: 600px) {
	.title-bfmBC {
		margin: 0 0 12px;
		padding-left: 40px;

		color: var(--text-mid);
		font-size: 20px;
	}

	.title-bfmBC:before {
		width: 28px;
		height: 28px;
	}

	.wrapper-2hV6d {
		width: calc(100% + 40px);

		margin: -20px -20px 28px -20px;
		padding: 17px 12px 12px 12px;

		border-radius: 8px 8px 0 0;
	}

	.flight-lEwrQ {
		margin: 10px auto 10px;
	}
}

@media (max-width: 1024px) {
	.wrapper-2hV6d {
		padding: 17px 12px 12px 12px;
	}
}
