.container-9uEII {
	padding: 40px 0;
	max-width: 1100px;
	margin: 0 auto;

	display: grid;

	grid-template: auto / repeat(auto-fill, minmax(280px, 1fr));
	grid-gap: 8px;
	gap: 8px;
}

@media screen and (max-width: 600px) {
	.container-9uEII {
		padding: 40px 20px;
	}
}

@media screen and (max-width: 320px) {
	.container-9uEII {
		padding: 40px 20px;
	}
}
