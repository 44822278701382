

span.toggle-XW4iq {
	height: auto;
	align-items: center;
	padding: 0;
	width: auto;
}

span.thumb-1XWxy {
	width: 14px;
	height: 14px;

	box-shadow: none;
	background: var(--white);
}

span.track-32nDp {
	width: 32px;
	height: 20px;

	border-radius: 10px;

	opacity: 1;
}

span.toggle-XW4iq.normal-8qmPM .track-32nDp {
	background: var(--line-separator);
}

span.toggle-XW4iq.invert-1l3Ho .track-32nDp {
	background: var(--brand-1-1);
}

span.switchBase-2svk_ {
	top: auto;

	padding: 0;

	transform: translateX(2px);
}

span.switchBase-2svk_:hover {
	background: none;
}

span.switchBase-2svk_.checked-30hUz {
	color: var(--white);

	transform: translateX(16px);
}

span.switchBase-2svk_.disabled-3a4EU + .track-32nDp,
span.switchBase-2svk_.checked-30hUz + .track-32nDp {
	opacity: inherit;
}

.normal-8qmPM .switchBase-2svk_.checked-30hUz + .track-32nDp {
	background: var(--brand-1-0);
}

.invert-1l3Ho .switchBase-2svk_.checked-30hUz + .track-32nDp {
	background: var(--success-icon);
}

.toggle-XW4iq.required-2JRWB :not(.checked-30hUz) + .track-32nDp {
	background: var(--error-stroke-hover);
}
