

.disclaimer-3nR9i {
	display: flex;
}

.disclaimer__inner-3c4Fg {
	display: flex;
}

.disclaimer__icon-YMn6R {
	display: block;
	height: 20px;
	width: 20px;
	margin-bottom: 12px;
}

.disclaimer__paragraph-2YcwK {
	display: block;
}
