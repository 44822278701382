.service-33Evm {
	position: relative;

	display: inline-flex;
	flex-grow: 1;
	justify-content: space-between;
	align-items: center;
	height: 56px;
	padding-right: 40px;
	max-width: 345px;

	border-radius: var(--service-border-radius);

	color: #ffffff;
	font-size: 13px;

	cursor: pointer;
	background: var(--service-background-color);
}

.service-33Evm:not(:last-of-type) {
	margin-right: 10px;
}

.service-33Evm svg path {
	fill: #9a9a9a;
}

.service__wrapper-370jf {
	display: inline-flex;
	align-items: center;
	padding-left: 10px;
	height: 100%;
	width: 100%;
}

.service_hasProducts-2Sz86 {
	border: var(--service-hasProduct-border);

	color: var(--service-hasProduct-color);

	background-color: var(--service-hasProduct-background);
}

.service_hasProducts-2Sz86 .service__wrapper-370jf {
	-webkit-text-decoration: var(--service-hasProduct-text-decoration);
	        text-decoration: var(--service-hasProduct-text-decoration);
}

.service-33Evm:not(.service_hasProducts-2Sz86) svg path {
	fill: var(--service-icon-color);
}

.main-3nhR4 {
	margin-left: 12px;
	margin-right: 10px;
}

.header-2Exrv {
	font-weight: 600;
	font-size: 14px;
}

.desc-1eHrQ {
	font-size: 14px;
	color: #ffffff;

	opacity: 0.7;
}

.service_hasProducts-2Sz86 .desc-1eHrQ {
	color: var(--common-blue);

	opacity: 1;
}

.action__icon-q8ddd {
	position: absolute;
	top: 0;
	right: 8px;
	bottom: 0;

	width: 24px;
	height: 24px;
	margin: auto;

	background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0yIDEyQzIgNi40OCA2LjQ4IDIgMTIgMkMxNy41MiAyIDIyIDYuNDggMjIgMTJDMjIgMTcuNTIgMTcuNTIgMjIgMTIgMjJDNi40OCAyMiAyIDE3LjUyIDIgMTJaTTEzIDEzSDE3VjExSDEzVjdIMTFWMTFIN1YxM0gxMVYxN0gxM1YxM1oiIGZpbGw9IndoaXRlIi8+CjxtYXNrIGlkPSJtYXNrMCIgbWFzay10eXBlPSJhbHBoYSIgbWFza1VuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeD0iMiIgeT0iMiIgd2lkdGg9IjIwIiBoZWlnaHQ9IjIwIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0yIDEyQzIgNi40OCA2LjQ4IDIgMTIgMkMxNy41MiAyIDIyIDYuNDggMjIgMTJDMjIgMTcuNTIgMTcuNTIgMjIgMTIgMjJDNi40OCAyMiAyIDE3LjUyIDIgMTJaTTEzIDEzSDE3VjExSDEzVjdIMTFWMTFIN1YxM0gxMVYxN0gxM1YxM1oiIGZpbGw9IndoaXRlIi8+CjwvbWFzaz4KPGcgbWFzaz0idXJsKCNtYXNrMCkiPgo8L2c+Cjwvc3ZnPgo=) center no-repeat;
}

.direction-3MbWZ {
	width: 50%;
	padding-right: 10px;

	color: #777777;
	text-align: left;
}

.values-DV_F1 {
	font-weight: 600;
}

.price-1etHV {
	font-weight: 600;
	color: var(--common-blue);
}

.icon-AY4Kb {
	display: flex;
	align-items: center;

	width: 32px;
	height: 32px;
}

.segment-_embV {
	display: flex;
}

.price__from-18gxZ {
	position: relative;

	color: #ffffff;
	font-size: 16px;
	font-weight: 500;
}

.action-2DKvW {
	display: flex;
	height: 100%;
	align-items: center;
}

.action__text-324IL {
	font-size: 16px;
	color: #ffffff;
}

.service_hasProducts-2Sz86 .action__text-324IL {
	color: var(--baggage-additional-selected-money-color);
}

.service_canAdd-3uUh3 .action__icon-q8ddd {
	background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0yIDEyQzIgNi40OCA2LjQ4IDIgMTIgMkMxNy41MiAyIDIyIDYuNDggMjIgMTJDMjIgMTcuNTIgMTcuNTIgMjIgMTIgMjJDNi40OCAyMiAyIDE3LjUyIDIgMTJaTTEzIDEzSDE3VjExSDEzVjdIMTFWMTFIN1YxM0gxMVYxN0gxM1YxM1oiIGZpbGw9IndoaXRlIi8+CjxtYXNrIGlkPSJtYXNrMCIgbWFzay10eXBlPSJhbHBoYSIgbWFza1VuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeD0iMiIgeT0iMiIgd2lkdGg9IjIwIiBoZWlnaHQ9IjIwIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0yIDEyQzIgNi40OCA2LjQ4IDIgMTIgMkMxNy41MiAyIDIyIDYuNDggMjIgMTJDMjIgMTcuNTIgMTcuNTIgMjIgMTIgMjJDNi40OCAyMiAyIDE3LjUyIDIgMTJaTTEzIDEzSDE3VjExSDEzVjdIMTFWMTFIN1YxM0gxMVYxN0gxM1YxM1oiIGZpbGw9IndoaXRlIi8+CjwvbWFzaz4KPGcgbWFzaz0idXJsKCNtYXNrMCkiPgo8L2c+Cjwvc3ZnPgo=);
}

.service_hasProducts-2Sz86 .action__icon-q8ddd,
.service_disabledAdd-1Pv0d .action__icon-q8ddd {
	position: absolute;
	top: 0;
	right: 8px;
	bottom: 0;

	width: 24px;
	height: 24px;
	margin: auto;

	background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0yIDEyQzIgNi40NyA2LjQ3IDIgMTIgMkMxNy41MyAyIDIyIDYuNDcgMjIgMTJDMjIgMTcuNTMgMTcuNTMgMjIgMTIgMjJDNi40NyAyMiAyIDE3LjUzIDIgMTJaTTE1LjU5IDE3TDE3IDE1LjU5TDEzLjQxIDEyTDE3IDguNDFMMTUuNTkgN0wxMiAxMC41OUw4LjQxIDdMNyA4LjQxTDEwLjU5IDEyTDcgMTUuNTlMOC40MSAxN0wxMiAxMy40MUwxNS41OSAxN1oiIGZpbGw9IiM5QTlBOUEiLz4KPG1hc2sgaWQ9Im1hc2swIiBtYXNrLXR5cGU9ImFscGhhIiBtYXNrVW5pdHM9InVzZXJTcGFjZU9uVXNlIiB4PSIyIiB5PSIyIiB3aWR0aD0iMjAiIGhlaWdodD0iMjAiPgo8cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGNsaXAtcnVsZT0iZXZlbm9kZCIgZD0iTTIgMTJDMiA2LjQ3IDYuNDcgMiAxMiAyQzE3LjUzIDIgMjIgNi40NyAyMiAxMkMyMiAxNy41MyAxNy41MyAyMiAxMiAyMkM2LjQ3IDIyIDIgMTcuNTMgMiAxMlpNMTUuNTkgMTdMMTcgMTUuNTlMMTMuNDEgMTJMMTcgOC40MUwxNS41OSA3TDEyIDEwLjU5TDguNDEgN0w3IDguNDFMMTAuNTkgMTJMNyAxNS41OUw4LjQxIDE3TDEyIDEzLjQxTDE1LjU5IDE3WiIgZmlsbD0id2hpdGUiLz4KPC9tYXNrPgo8ZyBtYXNrPSJ1cmwoI21hc2swKSI+CjwvZz4KPC9zdmc+Cg==) center no-repeat;
}

.service_readonly-2VE2q,
.service_disabledAdd-1Pv0d.service_hasProducts-2Sz86 {
	padding-right: 15px;
}

.service_readonly-2VE2q .action__icon-q8ddd {
	position: absolute;
	top: 0;
	right: 8px;
	bottom: 0;

	width: 24px;
	height: 24px;
	margin: auto;

	background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0yIDEyQzIgNi40OCA2LjQ4IDIgMTIgMkMxNy41MiAyIDIyIDYuNDggMjIgMTJDMjIgMTcuNTIgMTcuNTIgMjIgMTIgMjJDNi40OCAyMiAyIDE3LjUyIDIgMTJaTTEzIDExVjE3SDExVjExSDEzWk0xMiAyMEM3LjU4OTk5IDIwIDMuOTk5OTkgMTYuNDEgMy45OTk5OSAxMkMzLjk5OTk5IDcuNTkgNy41ODk5OSA0IDEyIDRDMTYuNDEgNCAyMCA3LjU5IDIwIDEyQzIwIDE2LjQxIDE2LjQxIDIwIDEyIDIwWk0xMyA3VjlIMTFWN0gxM1oiIGZpbGw9IiMzMjkwRkYiLz4KPC9zdmc+Cg==) center no-repeat;
}

.totalPrice-ISpBy {
	font-size: 16px;
	font-weight: 600;
	color: var(--service-totalPrice-color);
	text-decoration: none;
}

.selectedSeats__prices-2T-m_ {
	margin-left: auto;
	margin-right: 25px;
}

div.selectedSeats__prices-2T-m_ > div.selectedSeats__prices__price-3UjG7 > span {
	color: var(--common-blue);
}

.dialog__content-35gze {
	overflow-y: auto;
	overflow-x: hidden;

	min-width: 820px;
}

@media screen and (max-width: 600px) {
	.service-33Evm {
		width: 100%;
		max-width: unset;
	}

	.service-33Evm:not(:last-child) {
		margin-bottom: 10px;
		margin-right: 0;
	}
}
