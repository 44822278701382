.root-2q816 {
	display: flex;
	align-items: flex-end;
	justify-content: center;
	width: 98px;
	height: 146px;
}

.wrapper-2xBbE {
	position: relative;
}

.wrapper-2xBbE svg {
	display: block;
}

.size-1QwUC {
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
}

.prefix-3uN22 {
	display: inline;

	font-weight: 900;
}

.value-PUpoO {
	font-weight: 900;
	font-size: 24px;
	line-height: 1;
}

.prefix-3uN22,
.measurement-246n3 {
	font-size: 13px;
	line-height: 1;
}

.root-2q816.hand_luggage_excess-2GP1n .wrapper-2xBbE,
.root-2q816.carry_on-23h3r .wrapper-2xBbE {
	padding-bottom: 6px;
}

.root-2q816.hand_luggage_excess-2GP1n .size-1QwUC,
.root-2q816.carry_on-23h3r .size-1QwUC {
	display: flex;
	align-items: center;
	justify-content: center;
}

.root-2q816.hand_luggage_excess-2GP1n .measurement-246n3,
.root-2q816.carry_on-23h3r .measurement-246n3 {
	margin-left: 4px;
}

.root-2q816.checked_baggage-27eMh .size-1QwUC {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.root-2q816.carry_on-23h3r:not(.small-2In4u) .size-1QwUC {
	bottom: 0;
	top: 0;

	margin: auto;
}

.root-2q816.hand_luggage_excess-2GP1n.small-2In4u .size-1QwUC,
.root-2q816.carry_on-23h3r.small-2In4u .size-1QwUC {
	bottom: 22px;
}

.root-2q816.checked_baggage-27eMh.small-2In4u .size-1QwUC,
.root-2q816.checked_baggage-27eMh.middle-2ZS1h .size-1QwUC {
	bottom: 24px;
}

.root-2q816.checked_baggage-27eMh.large-3vDBH .size-1QwUC {
	bottom: 28px;

	display: flex;
	flex-direction: column;
	align-items: center;
}

.root-2q816.real_size-1IqHd {
	width: auto;
	height: auto;
}

.root-2q816.BaggageExcess-22Zgr {
	height: auto;
}

.root-2q816.BaggageExcess-22Zgr .wrapper-2xBbE .size-1QwUC {
	position: relative;
	left: auto;
	right: auto;
	bottom: auto;

	margin-top: 10px;
}

.root-2q816.BaggageExcess-22Zgr .wrapper-2xBbE .size-1QwUC .value-PUpoO {
	position: relative;

	display: inline;

	font-size: 22px;
}

.root-2q816.BaggageExcess-22Zgr .wrapper-2xBbE .size-1QwUC .value-PUpoO:before {
	content: '+';
}

.root-2q816.BaggageExcess-22Zgr .wrapper-2xBbE .size-1QwUC .measurement-246n3 {
	display: inline;
	margin-left: 3px;
}

@media screen and (max-width: 600px) {
	.root-2q816.BaggageExcess-22Zgr {
		width: 58px;
	}

	.root-2q816.BaggageExcess-22Zgr .wrapper-2xBbE > svg {
		width: 58px;
	}

	.root-2q816.BaggageExcess-22Zgr .wrapper-2xBbE .size-1QwUC {
		display: none;
	}
}

.carry_on-23h3r.hand_luggage_excess-2GP1n:not(.real_size-1IqHd) .size-1QwUC,
.carry_on-23h3r.large-3vDBH:not(.real_size-1IqHd) .size-1QwUC {
	top: 0;
}
