.accompanying-sjSIO {
	margin-top: 50px;
}

.accompanying__header-34iMK {
	margin-bottom: 10px;

	font-weight: 700;
	font-size: 17px;
}

.accompanying__info-1EzR7 {
	display: flex;
	align-items: center;

	color: var(--text-light);
}

.accompanying__info-1EzR7 svg {
	width: 35px;
	min-width: 24px;
	height: 35px;
	margin-right: 10px;
}

span.formControl__label-1YeYh {
	color: var(--text-base);
	font-family: inherit;
}

.accompanying-sjSIO span.formControl__checkbox-UlENO.checkbox__checked-uG8KA {
	color: var(--common-blue);
}

.fields-b-mMO {
	display: flex;
	grid-gap: 15px;
	gap: 15px;
	margin-bottom: 25px;
	margin-top: 10px;
}

.error-2b15M {
	position: absolute;

	color: var(--error-icon);
	font-size: 12px;
}

.checkbox-3pW30 {
	position: relative;
}

@media screen and (max-width: 600px) {
	.fields-b-mMO {
		flex-direction: column;
	}

	.accompanying-sjSIO {
		margin-top: 15px;
		padding: 0 12px 20px;
	}
}
