.promoCode-MQQiP {
	margin-left: auto;
}

@media screen and (max-width: 600px) {
	.promoCode-MQQiP {
		margin-left: 0;
	}

	.promoCode-MQQiP[class*='applied'] {
		padding-left: 28px;
	}
}
