.wrapper-1iKVF {
	display: flex;
	flex-grow: 1;

	border-right: 1px solid var(--line-separator);
}

.withMultiCity-3gcWk {
	position: relative;

	border-right: none;
}

.withMultiCity-3gcWk .datepicker_value-mH81k {
	border-radius: 0 4px 4px 0;
}

@media (max-width: 1024px) {
	.wrapper-1iKVF {
		flex-wrap: wrap;

		border-right: none;
	}

	.withMultiCity-3gcWk .datepicker_value-mH81k {
		border-radius: 4px;
	}
}

.segment-1QWAC {
	display: flex;
}

.segment_advanced-1994s {
	position: relative;
}

.segment-1QWAC:not(:first-of-type) {
	margin-top: 4px;
}

@media screen and (max-width: 600px) {
	.segment-1QWAC {
		display: block;
	}

	.segment-1QWAC:not(:first-of-type) {
		margin-top: 24px;
	}

	.withMultiCity-3gcWk .datepicker_value-mH81k {
		border-radius: 0 4px 4px 0;
	}
}

.cell-x2dMO {
	display: flex;
	flex-grow: 1;
	flex-basis: 226px;
}

.input-28qFz {
	width: 100%;
	height: var(--input-height);

	padding: 0 16px;

	border: none;
	border-radius: 0;

	color: var(--text-base);
	font-weight: 500;

	font-size: 15px;
	line-height: var(--input-height);
	text-align: left;

	background-color: var(--white);

	transition: background-color 0.15s, color 0.15s;

	appearance: none;
}

.input-28qFz:-ms-input-placeholder {
	color: var(--text-light);
}

.input-28qFz::placeholder {
	color: var(--text-light);
}

.input_focused-1xStI,
.input-28qFz:focus {
	color: var(--dark-gray);

	background-color: #ffffff;
}

.cell-x2dMO:first-child .input-28qFz,
.cell-x2dMO:first-child .location__value-N0OQC {
	border-radius: 4px 0 0 4px;
}

@media (max-width: 1024px) and (min-width: 601px) {
	.cell-x2dMO {
		flex-basis: 50%;
	}

	.cell-x2dMO:nth-child(2) .input-28qFz,
	.cell-x2dMO:nth-child(2) .location__value-N0OQC {
		border-radius: 0 4px 4px 0;
	}

	.wrapper-1iKVF:not(.withMultiCity-3gcWk) .cell-x2dMO:last-child {
		border-right: 1px solid var(--line-separator);
	}
}

.cell_location-1alFt {
	flex-grow: 2.5;

	border-right: 1px solid var(--line-separator);
}

.cell_location_arrival-acybk {
	display: flex;
}

.cell_date-2mGsc {
	flex-grow: 3;
	flex-basis: 272px;
}

@media (max-width: 1024px) {
	.cell_date-2mGsc {
		flex-grow: 0;
		flex-basis: 50%;
		height: 48px;
		margin-top: 4px;
	}

	.cell_location-1alFt:nth-child(2) {
		border-right: none;
	}

	.withMultiCity-3gcWk .cell_date-2mGsc {
		flex-basis: 100%;
	}

	div.control-3a_Kf:hover {
		background: var(--brand-1-1);
	}
}

@media screen and (max-width: 600px) {
	.cell_date-2mGsc {
		flex-grow: 1;
		flex-basis: 100%;
		margin-top: 0;
		padding-right: 0;

		border-right: none;
	}

	.cell_location-1alFt {
		border-right: none;
	}

	.cell-x2dMO:first-child .input-28qFz,
	.cell-x2dMO:first-child .location__value-N0OQC {
		border-radius: 4px 0 0 0;
	}

	.cell-x2dMO:nth-child(2) .input-28qFz {
		border-radius: 0 4px 0 0;
	}

	.withMultiCity-3gcWk .cell-x2dMO:first-child .input-28qFz,
	.withMultiCity-3gcWk .cell-x2dMO:first-child .location__value-N0OQC {
		border-radius: 4px 0 0 4px;
	}

	.withMultiCity-3gcWk .cell-x2dMO:nth-child(2) .input-28qFz {
		border-radius: 0;
	}
}

.cell_fixed-3oWQN {
	flex-basis: 230px;
	flex-grow: 0;
}

@media screen and (max-width: 600px) {
	.cell-x2dMO:not(:last-of-type) {
		flex-basis: 100%;
		margin-right: 0;
		margin-bottom: 4px;
	}
}

@media screen and (max-width: 600px) {
	.input_text-kQ6Do {
		line-height: normal;
	}

	.input_focused-1xStI,
	.input-28qFz:focus {
		color: #ffffff;

		background-color: rgba(255, 255, 255, 0.2);
	}
}

.input_focused-1xStI:-ms-input-placeholder, .input-28qFz:focus:-ms-input-placeholder {
	color: var(--dark-gray);
}

.input_focused-1xStI::placeholder,
.input-28qFz:focus::placeholder {
	color: var(--dark-gray);
}

.departureInputWrapper-2fj3T {
	position: relative;

	width: 100%;
}

.control-3a_Kf {
	position: absolute;
	right: -43px;

	display: flex;
	justify-content: center;
	align-items: center;

	width: 36px;
	height: 36px;
	margin-top: 5px;

	border-radius: 100px;

	color: #ffffff;

	cursor: pointer;
	background: var(--brand-1-1);

	transition: opacity 0.15s;
}

.control-3a_Kf:hover {
	background: var(--brand-1-2);
}

.control-3a_Kf svg {
	width: 20px;
	height: 20px;
}

@media screen and (max-width: 600px) {
	.input_text-kQ6Do {
		line-height: normal;
	}

	.alternative-LHYGX .cell_location-1alFt {
		flex-basis: 50%;
		flex-grow: 2;
	}

	.alternative-LHYGX .cell_location-1alFt:first-of-type {
		padding-right: 2px;
	}

	.alternative-LHYGX div.cell_location-1alFt:nth-child(2) {
		padding-left: 2px;
	}

	.alternative-LHYGX .cell_location-1alFt .input-28qFz {
		height: 72px;

		line-height: 72px;
		text-align: center;
	}

	.alternative-LHYGX .cell_date-2mGsc {
		height: 72px;
	}

	.alternative-LHYGX .withMultiCity-3gcWk {
		flex-wrap: nowrap;
	}

	.alternative-LHYGX.segment-1QWAC:not(:first-of-type) {
		margin-top: 0;
	}

	.alternative-LHYGX .withMultiCity-3gcWk .actions-79vyt {
		margin-top: 0;
	}

	.alternative-LHYGX .withMultiCity-3gcWk .cell_location-1alFt {
		flex-basis: 100%;
		height: 52px;
	}

	.alternative-LHYGX .withMultiCity-3gcWk .cell_location-1alFt:first-of-type {
		padding-right: 0;
	}

	.alternative-LHYGX .withMultiCity-3gcWk .cell_location-1alFt:nth-child(2) {
		padding-left: 0;
	}

	.alternative-LHYGX .withMultiCity-3gcWk .cell_location-1alFt .input-28qFz {
		display: flex;
		align-items: center;
		justify-content: center;
		height: 52px;

		line-height: 52px;
	}

	.alternative-LHYGX .withMultiCity-3gcWk .cell_date-2mGsc {
		height: 52px;
	}

	.alternative-LHYGX .withMultiCity-3gcWk .input-28qFz .input_text-kQ6Do {
		display: none;
	}
}
