

.head-3KM68 {
	background: linear-gradient(180deg, #ff9255 0%, #6c75c4 100%);
}

div.header-1-w32 {
	padding: 24px 34px 0 34px;
}

.head-3KM68 svg {
	position: relative;
	right: -20px;

	width: 150px;
	height: 113px;

	opacity: 0.8;
}

.description-IYLij {
	white-space: pre;
}
