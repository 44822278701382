

.root-3PtLC.additional_class-3zXii {
	padding: 0;

	color: var(--radio-color);
}

.root-3PtLC.additional_class-3zXii.checked-j-woc {
	color: var(--radio-checked-color);
}

.root-3PtLC.additional_class-3zXii.disabled-2UCqL {
	color: var(--radio-disabled-color);
}

.root-3PtLC.additional_class-3zXii:hover,
.root-3PtLC.additional_class-3zXii.checked-j-woc:hover {
	background: none;
}
