

.banner-2YzSL {
	display: grid;
	grid-template-columns: minmax(100px, max-content)
		max-content
		minmax(120px, max-content)
		1fr
		minmax(min-content, 1fr);
	align-items: center;
	max-width: 1100px;
	height: 120px;
	padding: 0 20px;

	color: var(--bg-elements);
	font-size: 22px;

	background: linear-gradient(180deg, var(--soft-violet) 0%, var(--light-violet) 100%), var(--white);
}

.icon-2nG8k {
	margin: 0 25px;
}

button.viewOffers-3dw5e,
button.viewOffers-3dw5e:hover {
	height: 49px;
	padding: 0 22px;

	font-weight: 700;
	font-size: 18px;

	background-color: var(--brand-3-0);
}

.arrow-3iuw5 {
	height: 24px;
	margin-left: 15px;
	margin-right: 55px;
}
