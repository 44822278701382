

.transfer-31r2f {
	display: flex;
	flex-direction: column;
	padding: 21px 27px;

	border-radius: 2px;

	background-color: #ededed;
}

.transfer__layover-1Nu5n {
	color: var(--gray80);
}

.warning-VIb7Z {
	display: flex;
	align-items: center;
	margin-top: 4px;

	color: var(--error-icon);
	font-weight: 400;
	font-size: 14px;
	line-height: 1.3;
}

.warning__icon-zeZo1 {
	width: 16px;
	height: 16px;
	margin: 0 4px;
}

.warning__icon-zeZo1 > svg {
	width: 100%;
	height: 100%;
}

.warning-VIb7Z:only-child {
	margin-left: 0;
}

.warning_red-3H8N7 {
	color: var(--error-20);
}
