.day-8d81y {
	position: relative;

	display: flex;
	flex-basis: 100%;
	flex-flow: wrap;
	min-width: 23px;
	margin-right: 5px;
}

.day-8d81y:hover {
	cursor: pointer;
}

@media (max-width: 1024px) {
	.day-8d81y {
		width: 17px;
		min-width: 17px;
	}
}

.progress-3WIC8 {
	position: absolute;
	bottom: 0;

	width: 100%;
	height: 100px;

	background-color: var(--pricegraph-day);
	opacity: 0.6;
}

.day_notAvailable-13VLt .progress-3WIC8 {
	height: 10px;

	border: 1px solid var(--line-separator);
	border-bottom: none;

	background: none;
}

@media (max-width: 1024px) {
	.day_notAvailable-13VLt .progress-3WIC8 {
		height: 3px;

		opacity: 1;
	}
}

.minPrice-1wY3E .progress-3WIC8 {
	background: var(--pricegraph-day);
}

@media (min-width: 1024px) {
	.day-8d81y:hover .progress-3WIC8 {
		opacity: 1;
		background: var(--brand-1-0);
	}
}

.header-3HXXw {
	position: absolute;
	top: 100%;

	display: flex;
	flex-direction: column;
	width: 100%;
	padding-top: 5px;
	padding-bottom: 5px;

	font-size: 13px;
	text-align: center;
}

.day_notAvailable-13VLt:hover .header-3HXXw {
	background: none;
}

.number-3GXi8 {
	font-size: 14px;
	font-weight: 500;
	line-height: 17px;
	color: var(--text-light);
}

.dow-3ydce {
	display: none;

	color: var(--text-pale);
	font-size: 10px;
}

.bar-3kO9Y {
	position: relative;

	min-height: 100px;
	height: 100%;
	width: 100%;
}

@media (max-width: 1024px) {
	.bar-3kO9Y {
		height: 300px;
	}
}

@media screen and (max-width: 600px) {
	.bar-3kO9Y {
		height: 100%;
	}
}

.day_notAvailable-13VLt.day-8d81y:hover {
	cursor: default;
	background: none;
}

.day_notAvailable-13VLt:hover .progress-3WIC8 {
	background: none;
}

.price-1lkEF {
	position: absolute;
	bottom: calc(101% + 45px);
	left: -51%;
	z-index: 3;

	display: none;
	width: calc(100% * 2);

	color: var(--dark-violet);
	font-size: 12px;
	white-space: nowrap;
	text-align: center;

	background: #ffffff;
}

.day-8d81y:first-child .price-1lkEF {
	left: 0;

	width: auto;
}

.day-8d81y:last-child .price-1lkEF {
	right: 0;

	width: auto;
}

.day-8d81y:hover .price-1lkEF {
	display: block;
}

.monthName-1vq4p {
	position: absolute;
	top: -30px;

	display: none;
	padding-left: 8px;

	border-left: 1px solid;

	color: var(--text-light);
	font-weight: 500;
	font-size: 12px;
	line-height: 18px;
	white-space: nowrap;
	text-transform: uppercase;
}

@media (max-width: 1024px) {
	.monthName-1vq4p {
		display: block;
	}
}

.monthName_priceLabel-3GvLz:before {
	content: ', ';
}

.monthName_price-3u2HN {
	color: var(--bright-blue);
}

@media (min-width: 1024px) {
	.outDate-2Fsmj.day-8d81y .progress-3WIC8,
	.backDate-1WLxm.day-8d81y .progress-3WIC8 {
		background: var(--brand-1-0);
		opacity: 1;
	}

	.outDate-2Fsmj.day-8d81y:after,
	.backDate-1WLxm.day-8d81y:after {
		position: absolute;
		right: 0;
		bottom: -42px;
		left: 0;

		width: 16px;
		height: 17px;
		margin: auto;

		background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTciIHZpZXdCb3g9IjAgMCAxOCAxNyIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTUuNjY2MzQgMTYuMzMzM0g3LjMzMzAxTDExLjQ5OTcgOS42NjY2N0gxNi4wODNDMTYuNzc0NyA5LjY2NjY3IDE3LjMzMyA5LjEwODMzIDE3LjMzMyA4LjQxNjY3QzE3LjMzMyA3LjcyNSAxNi43NzQ3IDcuMTY2NjcgMTYuMDgzIDcuMTY2NjdIMTEuNDk5N0w3LjMzMzAxIDAuNUw1LjY2NjM0IDAuNUw3Ljc0OTY3IDcuMTY2NjdIMy4xNjYzNEwxLjkxNjM0IDUuNUgwLjY2NjM0TDEuNDk5NjcgOC40MTY2N0wwLjY2NjM0IDExLjMzMzNMMS45MTYzNCAxMS4zMzMzTDMuMTY2MzQgOS42NjY2N0g3Ljc0OTY3TDUuNjY2MzQgMTYuMzMzM1oiIGZpbGw9IiMzMjkwRkYiLz4KPC9zdmc+Cg==);
		content: '';
	}

	.backDate-1WLxm.day-8d81y:after {
		transform: rotate(180deg);
	}
}

div.tooltip-vf6Lo {
	padding: 8px;
	margin-top: 12px;

	background-color: var(--brand-1-0);
}

div.tooltip__text-16lSa:after {
	position: absolute;
	bottom: 120%;
	left: 50%;

	width: 0;
	height: 0;

	border-width: 0 6px 8px 6px;
	border-style: solid;
	border-color: transparent transparent var(--brand-1-0) transparent;

	transform: translate(-50%);
	content: '';
}

div.popper-5qJyN {
	z-index: 1601 !important;

	opacity: 1;
}

.tooltip__text-16lSa {
	position: relative;

	padding-left: 30px;

	font-size: 14px;
	font-weight: 500;
	color: #ffffff;
}

.tooltip__text-16lSa:before {
	position: absolute;
	top: 0;
	right: auto;
	bottom: 0;
	left: 6px;

	width: 16px;
	height: 17px;
	margin: auto;

	transform: rotate(180deg);
	opacity: 0.6;
	background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTciIHZpZXdCb3g9IjAgMCAxOCAxNyIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEyLjMzMzcgMTYuMzMzM0gxMC42NjdMNi41MDAzMyA5LjY2NjY3SDEuOTE2OTlDMS4yMjUzMyA5LjY2NjY3IDAuNjY2OTkyIDkuMTA4MzMgMC42NjY5OTIgOC40MTY2N0MwLjY2Njk5MiA3LjcyNSAxLjIyNTMzIDcuMTY2NjcgMS45MTY5OSA3LjE2NjY3SDYuNTAwMzNMMTAuNjY3IDAuNUwxMi4zMzM3IDAuNUwxMC4yNTAzIDcuMTY2NjdIMTQuODMzN0wxNi4wODM3IDUuNUgxNy4zMzM3TDE2LjUwMDMgOC40MTY2N0wxNy4zMzM3IDExLjMzMzNMMTYuMDgzNyAxMS4zMzMzTDE0LjgzMzcgOS42NjY2N0gxMC4yNTAzTDEyLjMzMzcgMTYuMzMzM1oiIGZpbGw9IndoaXRlIi8+Cjwvc3ZnPgo=) center no-repeat;
	content: '';
}

.tooltip__date-1SexY {
	opacity: 0.6;
}

/* stylelint-disable block-no-empty */

/* overridden in airline theme */
.tooltip__price-3pyXn {
}

/* stylelint-enable */
.tooltip__text_return-2CMSL:before {
	transform: rotate(0deg);
}

.tooltip__money-1CH62 {
	padding-left: 0;
}

.day-8d81y.day_focused-2CWxD:not(.day_notAvailable-13VLt) .progress-3WIC8 {
	background: var(--brand-1-0);
	opacity: 1;
}

.day-8d81y.day_focused-2CWxD:not(.day_notAvailable-13VLt):after {
	content: '';

	position: absolute;
	top: -44px;
	bottom: 0;
	left: 0;
	right: 0;

	margin: auto;
	width: 1px;

	background: var(--brand-1-0);
}
