

div.status-3tfxL {
	display: inline-flex;
	align-items: center;
	padding: 4px 7px 4px 4px;
	max-height: 30px;

	border-radius: 100px;

	color: #ffffff;
	font-size: 14px;
	line-height: 21px;
	font-weight: 700;
}

div.waiting-24Xdu {
	background: var(--active-30);
}

div.rejected-2Wbz0 {
	background: var(--error);
}

div.processing-3xNiX {
	color: var(--gray80);

	background: var(--warning);
}

.icon-36KJ- {
	display: flex;
	margin-right: 5px;

	color: #ffffff;
}

.processing-3xNiX .icon-36KJ- {
	color: inherit;
}
