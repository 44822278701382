.wrapper-34nA2 {
	padding: 20px;

	border-radius: 0 0 4px 4px;

	background: var(--white);
}

button.addButton-3PdHC {
	width: 100%;
}

@media (min-width: 1024px) {
	button.addButton-3PdHC {
		width: auto;

		margin-left: -6px;
		padding: 6px 10px;

		letter-spacing: normal;

		background: transparent;
	}

	button.addButton-3PdHC:hover {
		background: transparent;
	}
}

.addButton__icon-9HwN9 {
	display: flex;
	flex-shrink: 0;
	justify-content: center;
	align-items: center;

	width: 32px;
	height: 32px;

	padding: 0;

	border-radius: 50%;

	color: var(--white);

	background: var(--brand-1-0);
}

button.addButton-3PdHC .addButton__icon-9HwN9 svg {
	width: 24px;
	height: 24px;
}

.form__wrapper-13viS {
	display: flex;
	flex-wrap: nowrap;
	align-items: center;
}

.form-2Zsyq {
	flex: 1;
	display: flex;
	align-items: flex-start;
	grid-gap: 20px;
	gap: 20px;

	width: 100%;
}

.fields-N7xTy {
	flex: 1;
	display: flex;
	grid-gap: 20px;
	gap: 20px;
}

.form__input-2vrIn {
	flex: 1;
}

button.submitButton-1Byiv {
	margin-top: 10px;
}

.form__title-1gogX {
	display: flex;
	align-items: flex-start;
	flex-direction: column;

	font: var(--medium-1-desktop-bold);
}

.passengerIcon-1wUo5 {
	display: flex;
	align-items: center;
	justify-content: center;

	margin-top: 16px;
}

button.conditionButton-1kehE {
	margin-left: -6px;

	color: var(--text-light);
}

.notification-3i-Hs.notification_context-314eu .notification__text-3k8g7 {
	font: var(--medium--1-desktop-accent);
}

@media screen and (max-width: 600px) {
	.wrapper-34nA2 {
		position: relative;
	}

	.fields-N7xTy {
		width: 100%;
		flex-direction: column;
		grid-gap: 24px;
		gap: 24px;
	}

	.form__wrapper-13viS {
		flex-direction: column;
		align-items: normal;

		grid-gap: 20px;

		gap: 20px;
	}

	.form-2Zsyq {
		flex-direction: column;
		align-items: normal;
		grid-gap: 36px;
		gap: 36px;
	}

	.form__title-1gogX {
		padding-bottom: 10px;
	}

	.addButton-3PdHC {
		justify-content: center;

		min-height: 40px;
		margin: 0;

		border-radius: 4px;

		color: var(--brand-1-0);
		font-weight: 700;
		font-size: 14px;

		background: var(--brand-1-4);
	}

	.addButton__icon-9HwN9 {
		display: none;
	}

	button.submitButton-1Byiv {
		margin-top: 0;
	}
}
