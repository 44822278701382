.container-14xg2 {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	width: 100%;
	padding: 16px 20px;
}

button.button-34Psn {
	margin-right: 4px;

	color: var(--text-link);
	font-size: 13px;
}

button.button-34Psn:last-child {
	margin-right: 0;
	margin-bottom: 0;
}

.button__icon-3O0OD {
	display: none;
}

button.button-34Psn.cancel-27ytB {
	color: var(--text-light);
}

button.sendToEmailButton-3CXWh {
	margin-left: auto;
}

button.sendToEmailButton_disabled-hgTsu {
	opacity: 0.5;
	cursor: not-allowed;
}

@media screen and (max-width: 600px) {
	.container-14xg2 {
		align-items: flex-start;
		flex-direction: column;
		grid-gap: 4px;
		gap: 4px;
	}

	.container-14xg2.disabled-17nkB {
		align-items: center;
	}

	button.button-34Psn {
		margin-right: 0;

		color: var(--text-mid);
		font-weight: 400;
		font-size: 14px;
	}

	.disabled-17nkB button.button-34Psn {
		border-radius: 4px;

		color: var(--white);
		font-weight: 700;

		background: var(--brand-3-0);
	}

	.button__icon-3O0OD {
		display: flex;
		justify-content: center;
		align-items: center;

		width: 24px;
		min-width: 24px;
		height: 24px;

		margin-right: 16px;

		color: var(--text-pale);
	}

	button.button-34Psn.cancel-27ytB {
		order: 1;

		color: var(--error-icon);
	}

	button.button-34Psn.cancel-27ytB .button__icon-3O0OD {
		color: var(--error-icon);
	}

	div.scrollBody-KMy1w {
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
	}

	div.paper-1HAsE {
		min-height: auto;

		border-radius: 8px 8px 0 0;
	}
}
