.row-1ddsU {
	display: flex;
	margin-top: 45px;
	justify-content: space-between;
}

.buttons_row-s2H4p {
	display: flex;
	flex: 1;
}

.price_block-1TwDV {
	display: flex;
	align-items: center;
}

.price_block__delimiter-3ueJb {
	width: 1px;
	height: 24px;
	margin: 0 12px;

	background: var(--gray20);
}

.price_wrapper-Ypv51 {
	font-weight: 700;
	font-size: 22px;
	line-height: 1.4;
}

.clear-2kQwr {
	font-weight: 500;
	font-size: 15px;
	line-height: 1.4;
	color: var(--base);

	cursor: pointer;
}

.root-21gmK button.cancel_btn-1QzgE {
	margin-left: auto;
}

.root-21gmK button.confirm_btn-2rm-0 {
	margin-left: 12px;
}

@media screen and (max-width: 600px) {
	.row-1ddsU {
		flex-direction: column;
		align-items: flex-end;
		margin-top: 15px;
	}

	.buttons_row-s2H4p {
		margin-top: 20px;
		width: 100%;
	}

	.root-21gmK button.cancel_btn-1QzgE,
	button.confirm_btn-2rm-0 {
		flex: 1;
		width: 100%;
	}

	.price_block-1TwDV {
		margin-top: 15px;
	}
}
