.root-25384 {
	justify-content: space-around;
	width: 100%;
}

.root-25384 .slick-arrow {
	z-index: 2;

	display: flex !important;
	justify-content: center;
	align-items: center;
	width: 34px;
	height: 34px;

	border-radius: 50%;

	box-shadow: 4px 7px 26px rgba(0, 0, 0, 0.1);
	background: #ffffff;
}

.root-25384 .slick-arrow:hover {
	background: #ffffff;
}

.root-25384 .slick-next {
	right: -11px;
}

.root-25384 .slick-prev {
	left: -11px;
}

.root-25384 .slick-arrow:before {
	display: block;
	width: 24px;
	height: 24px;

	opacity: 1;
	background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEwIDZMOC41OSA3LjQxTDEzLjE3IDEyTDguNTkgMTYuNTlMMTAgMThMMTYgMTJMMTAgNloiIGZpbGw9IiM0RDRENEQiLz4KPC9zdmc+Cg==) center no-repeat;
	background-size: cover;
	content: '';
}

.root-25384 .slick-arrow.slick-prev:before {
	transform: rotate(180deg);
}

.root-25384 .slick-arrow:hover:before {
	filter: invert(54%) sepia(50%) saturate(6252%) hue-rotate(200deg) brightness(108%) contrast(102%);
}

.root-25384 .slick-arrow.slick-disabled {
	display: none !important;
}

.root-25384 .slick-slide > div {
	padding: 0 5px;
}

.root-25384 .slick-list,
.root-25384 .slick-track,
.root-25384 .slick-slide,
.root-25384 .slick-slide > div {
	height: 100%;
}

.item-3IVVY {
	display: flex;
	flex: 1;
	flex-direction: column;
	justify-content: flex-end;
	width: 100%;

	min-width: 160px;

	height: 100%;

	padding: 16px 8px;

	border-radius: 4px;

	text-align: center;

	background: var(--gray-bg);
}

.item-3IVVY + .item-3IVVY {
	margin-left: 8px;
}

.baggage_count-3kf2D {
	margin-top: 6px;
}

.baggage_direction-21Cu5 {
	margin-top: 2px;
}

.price-1d37n {
	margin-top: 6px;

	color: var(--text);
	font-weight: 700;
	font-size: 13px;
}

.counts-bN9Zg {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
}

.count-w0B58 {
	display: flex;
	flex-direction: column;
	align-items: center;

	padding: 0 10px;
}

.footer-2dF7I {
	display: flex;
	flex-direction: column;
	align-items: center;

	margin-top: 8px;
}

.footer_empty-20rZr {
	visibility: hidden;
}

button.add-3ua51 {
	width: 32px;
	min-width: 32px;
	min-height: 32px;
	height: 32px;

	padding: 6px;

	border-radius: 50%;
}

button.add-3ua51 svg {
	width: 100%;
	height: 100%;
}

@media screen and (max-width: 600px) {
	.root-25384 {
		display: flex;
	}

	.root-25384.slick-initialized {
		padding-bottom: 20px;
	}

	.root-25384 .slick-dots {
		bottom: -6px;
	}

	.item-3IVVY {
		height: auto;
		height: initial;
	}

	.item-3IVVY + .item-3IVVY {
		margin-left: 0;
	}

	.footer-2dF7I.footer_empty-20rZr {
		display: none;
	}
}
