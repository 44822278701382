

.plane-Y36dR {
	position: relative;

	width: 100%;

	padding-top: 28%;
}

.plane-Y36dR img {
	position: absolute;
	top: 0;
	left: 50%;

	width: 80%;
	height: 100%;
	object-fit: contain;

	transform: translateX(-50%);
}

.plane_return-VV0iM img {
	left: 10%;

	transform: scaleX(-1);
}
