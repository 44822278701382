button.button-3KO0s {
	font-size: 14px;
	font-weight: 400;
}

.icon-1Xy_k {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-right: 4px;

	color: var(--text-pale);
}

div.close-1dLZR {
	top: 8px;
	right: 8px;

	width: 32px;
	height: 32px;

	color: var(--text-pale);

	background: var(--bg-fill);
}

div.close-1dLZR svg {
	width: 20px;
	height: 20px;
}

div.close-1dLZR:hover {
	color: var(--text-light);

	background: var(--bg-elements);
}

div.paper-33dTr {
	border-radius: 20px;
}

.conditions-2d-Dc {
	padding: 0;
}

@media screen and (max-width: 600px) {
	button.button-3KO0s {
		margin-left: -8px;
	}

	div.scrollBody-2-uBv {
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
	}

	div.paper-33dTr {
		min-height: auto !important;

		border-radius: 8px 8px 0 0;
	}

	div.scrollBody-2-uBv .paper-33dTr {
		overflow: auto;
	}
}
