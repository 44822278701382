.container-3B3Uz {
	display: flex;
	flex-direction: column;
	padding: 12px;

	border: 1px solid var(--warning-icon);
	border-radius: 4px;

	background: var(--white);
}

.switch__wrapper-3KQrY {
	display: flex;
	align-items: center;
}

.switch__icon-O54rU {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 20px;
	min-width: 20px;
	height: 20px;
	margin-right: 8px;
}

.switch__control-3CPpe {
	flex: 1;
	margin: 0;
}

.switch__control-3CPpe:before {
	opacity: 1;
}

.switch__control-3CPpe:after {
	background: var(--line-separator);
}

.switch__control-3CPpe.switch__active-10Y9i:after {
	background: var(--brand-1-1);
}

.switch__label-35DQ_ {
	color: var(--brand-1-0);

	opacity: 1;
}

.description-2LWEM {
	margin-top: 16px;

	color: var(--text-light);
	font-weight: 400;
	font-size: 13px;
	line-height: 1.3;
}

.switch__infoIcon-x9oka {
	display: inline-flex;
	justify-content: center;
	align-content: center;
	width: 16px;
	min-width: 16px;
	height: 16px;
	margin-left: 2px;

	color: var(--text-light);

	transform: translateY(4px);
	cursor: pointer;
}

.switch__infoIcon-x9oka svg {
	width: 100%;
	height: 100%;
}

@media (max-width: 1024px) {
	.switch__control-3CPpe {
		padding-left: 0;
	}
}
