.header-3sDLw {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin: 40px 0;
}

.header__name-2vhgH {
	display: flex;
	align-items: center;

	font-weight: 600;
	font-size: 24px;
	color: var(--gray80);
}

.header__name-2vhgH svg {
	color: #5fca43;
}

.header__icon-1jkW7 {
	margin-right: 16px;
	display: flex;
}

.change-3pE1w {
	font-weight: 500;
	font-size: 15px;
	color: var(--base);

	cursor: pointer;
}

.flights-ja3CL {
	display: flex;
	flex-direction: column;
	width: 100%;
}

.flights-ja3CL > div {
	margin: 0;
	max-width: 100%;
}

.flights-ja3CL > div:not(:last-child) {
	border-bottom: 1px dashed var(--gray20);
}

.flight__container-2t703 {
	display: flex;
	margin-bottom: 8px;

	border-radius: 4px;

	box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.04);
	background: #ffffff;
}

.price-19f8s {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-end;
	min-width: 200px;
	padding: 0 12px;

	border-left: 1px dashed var(--gray20);

	color: var(--base-30);
	font-size: 14px;
}

.money-3L2Qc {
	margin-top: 8px;

	font-weight: 600;
	font-size: 23px;
}

.charge-1smZK {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 8px;
	padding: 21px 16px;

	color: var(--base-30);
	font-size: 14px;

	box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.04);
	background: #ffffff;
}

.charge__money-2Prg7 {
	margin-top: 0;
}

.total-3SUtw {
	display: flex;
	align-items: flex-end;

	color: var(--base-30);
	font-size: 19px;
}

.total__money-1ndrH {
	margin-top: 0;
	margin-left: 12px;

	font-size: 36px;
}

.container_flat-1tNja .flight-3Vrqp {
	margin-bottom: 0;

	border-bottom: 1px solid #f4f4f4;
}

.container_flat-1tNja .charge-1smZK:not(:last-of-type) {
	margin-bottom: 0;

	border-bottom: 1px solid #f4f4f4;
}

@media screen and (max-width: 600px) {
	.header-3sDLw {
		margin-top: 0;
		margin-bottom: 16px;
	}

	.header__name-2vhgH {
		font-size: 20px;
	}

	.header__name-2vhgH svg {
		display: none;
	}

	.flight__container-2t703 {
		flex-direction: column;

		background: none;
		box-shadow: none;
	}

	.flights-ja3CL > div:not(:last-of-type) {
		margin: 0;

		box-shadow: none;
	}

	.price-19f8s {
		flex-direction: row;

		justify-content: flex-end;
		align-items: center;
		padding: 18px 12px;

		border-left: none;

		border-radius: 4px;

		background: #ffffff;
	}

	.money-3L2Qc {
		margin-top: 0;
	}

	.total-3SUtw {
		font-size: 18px;
	}

	.total__money-1ndrH {
		font-size: 30px;
	}

	.flight__container-2t703 .flights-ja3CL > div {
		margin-bottom: 8px;
	}

	.flights-ja3CL > div:not(:last-child) {
		border-bottom: 1px solid var(--gray-line);
	}
}
