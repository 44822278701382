label.control-KY9zR {
	grid-gap: 12px;
	gap: 12px;

	margin-left: 0;
	margin-right: 0;
}

span.label-3CJpP {
	font-family: inherit;
	font-size: 16px;
	line-height: 1.3;
}

span.checkbox-3biR6.checkbox_checked-nu6W5 {
	color: var(--common-blue);
}

.label-3CJpP a,
.label-3CJpP span[data-type] {
	color: var(--text-link);
}

.label-3CJpP span:not(:last-child):after {
	content: ', ';
}

div.popper-3JC4W {
	z-index: 3;
}

@media screen and (max-width: 600px) {
	span.label-3CJpP {
		font-size: 15px;
	}
}
