.container-3rHBC {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr [content-end] 1.2fr [grid-end];
	grid-column-gap: 12px;
}

.container-3rHBC:not(:last-child) {
	margin-bottom: 24px;
}

.container-3rHBC.withIcons-31f33 {
	grid-template-columns: 40px 1fr 1fr 1fr [content-end] 1.2fr [grid-end];
	align-items: center;
}

div.field_birthdate-NUv6_ {
	z-index: 1;

	grid-column-start: content-end;
	grid-row-start: 1;
	width: 55%;
}

.field_sex-2x5Bt {
	z-index: 0;

	grid-column-start: content-end;
	grid-row-start: 1;
	align-items: center;
}

/* stylelint-disable */
.documents-2KyOI {
}

.field_middleName-fgm4q {
}
/* stylelint-enable */

@media (max-width: 1024px) {
	.container-3rHBC {
		display: grid;
		grid-template-columns: 1fr;
		grid-auto-rows: 56px;
		grid-gap: 25px 28px;
	}

	.container-3rHBC.withIcons-31f33 {
		grid-template-columns: 1fr;
		align-items: initial;
	}

	.icon-2qa0x {
		display: none;
	}

	.container-3rHBC.identity-FBTpc {
		grid-template-rows: 1fr 1fr 1fr [row-content-end] 1fr;
	}

	.container-3rHBC:not(:last-child) {
		margin-bottom: 25px;
	}

	div.field_sex-2x5Bt,
	div.field_birthdate-NUv6_ {
		grid-column: 1;
		grid-row: row-content-end;
	}
}

@media screen and (max-width: 600px) {
	.container-3rHBC {
		grid-auto-rows: initial;
	}
}
