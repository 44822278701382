.wrapper-2_LrF {
	position: relative;

	min-width: 100px;
	align-items: flex-start;
	display: flex;
	flex-direction: column;
}

.dropdownWrapper-5dF_i {
	position: relative;

	top: 35px;

	padding: 20px;

	border: 1px solid #cccccc;
	border-radius: 4px;

	background: var(--bg-white);
}

button.confirmButton-3_BAx {
	width: 100%;
	padding: 12px 24px;
	margin-top: 12px;

	border-radius: 6px;

	color: var(--bg-white);

	font: var(--normal-desktop-bold);

	background: var(--brand-3-0);
}

button.confirmButton-3_BAx:hover {
	background: var(--brand-3-1);
}

button.confirmButton-3_BAx:disabled {
	color: var(--bg-white);

	background: var(--brand-3-3);
}

.item_wrapper-18Dpk {
	display: flex;
	flex-direction: row;
	grid-gap: 5px;
	gap: 5px;
	justify-content: center;
	align-items: center;
	width: 100%;
	padding: 10px 10px;

	border-radius: 100px;

	color: var(--bg-white);

	font: var(--small-desktop);
	line-height: 1;
	white-space: nowrap;

	cursor: pointer;

	background: var(--brand-1-0);
}

.item_wrapper__data-2s7hL {
	display: flex;
	flex-direction: row;
	grid-gap: 3px;
	gap: 3px;
}

.popup_wrapper-2BjQo {
	position: absolute;
	z-index: 100;
}

div > button.closeButton-3Z20j {
	width: 10px;
	height: 10px;
	padding: 0;

	color: var(--bg-white);

	background: unset;
}

div > button.closeButton-3Z20j svg {
	width: inherit;
	height: inherit;
}

div > button.closeButton-3Z20j:hover,
div > button.closeButton-3Z20j:active {
	background: unset;
}

.item_wrapper__clear-2tR5o {
	display: flex;
	flex-direction: row;
	align-items: center;
}

@media screen and (max-width: 600px) {
	.item_wrapper-18Dpk {
		grid-gap: 5px;
		gap: 5px;

		padding: 10px 20px;
	}
}
