.iframe-3HNKc {
	width: 420px;

	border: 0;
	overflow: hidden;
}

@media (max-width: 1024px) {
	.iframe-3HNKc {
		width: 100%;
	}
}
