.wrapper-3vZxG {
	position: relative;

	display: flex;
	flex: 1;
	justify-content: center;
	align-items: center;

	min-width: auto;

	border-left: 1px dashed var(--gray20);

	border-left: var(--flight-select-wrapper-bdl, 1px dashed var(--gray20));

	font-size: 14px;
}

button.button-Lc3Lg {
	color: var(--base-15);
	color: var(--flight-select-button-color, var(--base-15));
	font-weight: 500;
	font-size: 13px;
	font-size: var(--flight-select-button-fz, 13px);

	background: var(--base80);

	background: var(--flight-select-button-bg, var(--base80));
}

.button-Lc3Lg:hover {
	color: #ffffff;
	color: var(--flight-select-hover-bg, #ffffff);
}

@media screen and (max-width: 600px) {
	.wrapper-3vZxG {
		padding: 16px 0;
	}
}
