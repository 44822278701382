

.root-3WCuM {
	display: flex;
	align-items: center;
	justify-content: flex-end;
}

/* stylelint-disable */
button.control-2TD0T {
	margin-left: 12px;
}
.control__edit-3Q-qv {
}
/* stylelint-enable */
.price-2XoMB {
	font-weight: 700;
	font-size: 18px;
	line-height: 1;
	color: var(--base);
}

.price__selected-1yFPJ {
	color: var(--gray80);
}
