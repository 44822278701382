
/* stylelint-disable */
.monthHeader__select_month-2jG12 {
}

.monthHeader__select_year-3KIvU {
}

/* stylelint-enable */
.monthHeader-6QpEY {
	height: 30px;

	line-height: 30px;
	text-align: center;
}

.monthHeader__select-CdvF6 {
	display: inline-block;
}

.monthHeader__select_asPlainText-1omR7 {
	padding-right: 5px;
}
