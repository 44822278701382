.additional-2z3AK {
	display: flex;
	flex-basis: 358px;
	flex-direction: column;
	max-width: 358px;

	border-radius: var(--additional-services-card-border-radius);

	box-shadow: 0 1px 8px rgba(0, 0, 0, 0.07);

	overflow: hidden;
}

.additional-2z3AK.additional_horizontal-1hjRt {
	flex-basis: auto;
	flex-direction: row;
	max-width: unset;
	width: 100%;
}

.additional-2z3AK:not(:last-child) {
	margin-right: 13px;
}

.head-1WJ78 {
	position: relative;

	min-height: 180px;
	padding: 24px 28px;

	overflow: hidden;
}

.head-1WJ78.head_horizontal-2xtEz {
	min-height: unset;
	min-width: 180px;
}

.insurance-2SB48 {
	background: var(--additional-services-insurance-head);
}

.transfer-3jgTM {
	background: var(--additional-services-transfer-head);
}

.logo__wrapper-3HGEA {
	display: flex;
	justify-content: center;
	align-items: center;
	max-width: 120px;
	margin-bottom: 8px;
	padding: 5px 0;

	border-radius: 4px;

	background: #ffffff;
}

body[data-demo-wx='true'] .logo__wrapper-3HGEA:not([data-locale='ru']) {
	display: none;
}

.whiteLines-1WJxW {
	position: absolute;
	top: 0;
	left: 0;
	z-index: 1;

	display: var(--additional-services-lines-display);
	align-items: stretch;
	height: 100%;
	width: 100%;

	opacity: 0.14;
}

.whiteLine_narrow-GnClF {
	position: absolute;

	width: 13px;
	height: 200%;

	background: #ffffff;
	transform: rotate(30deg) translate(85px, -110px);
}

.whiteLine_wide-1wzzp {
	position: absolute;

	width: 70px;
	height: 200%;

	background: #ffffff;
	transform: rotate(30deg) translate(9px, -110px);
}

.additional_horizontal-1hjRt .whiteLine_wide-1wzzp {
	width: 50px;

	transform: rotate(30deg) translate(-5px, -40px);
}

.additional_horizontal-1hjRt .whiteLine_narrow-GnClF {
	width: 10px;

	transform: rotate(30deg) translate(50px, -80px);
}

.icon-1GksD {
	position: absolute;
	bottom: -4px;
	right: 0;
}

.additional_horizontal-1hjRt .icon-1GksD {
	bottom: 0;
	top: auto;

	height: 135px;
	width: 135px;
}

.additional_horizontal-1hjRt .icon-1GksD svg {
	height: 135px;
	width: 135px;
}

.additional_horizontal-1hjRt .icon-1GksD svg path {
	opacity: 0.8;
}

.header-26nrs {
	z-index: 2;

	color: #ffffff;
	font-weight: 600;
	font-size: 36px;
}

.content-2u1UY {
	display: flex;
	flex-direction: column;
	flex: 1;
	padding: 20px 24px;

	color: var(--dark-gray);
	font-size: 15px;
	line-height: 23px;

	background: #ffffff;
}

.additional_horizontal-1hjRt .content-2u1UY {
	padding: 20px 28px;
}

.title-1BuMb {
	margin-bottom: 2px;

	font-size: 24px;
	font-weight: 500;
	line-height: 150%;

	color: var(--dark-gray);
}

.subheader-1TaKW {
	margin-bottom: 24px;

	color: var(--dark-gray);
	font-weight: 600;
	font-size: 20px;
}

.additional_horizontal-1hjRt .subheader-1TaKW {
	margin-bottom: 0;

	font-size: 15px;
	line-height: 19px;
	font-weight: 400;
}

.footer-3wZuz {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 20px 24px;

	border-top: 1px dashed #d9d9d9;

	background: #ffffff;
}

.additional_horizontal-1hjRt .footer-3wZuz {
	justify-content: flex-end;

	border-top: 0;
}

button.buttonBase-2z5jR {
	border-radius: 24px !important;

	color: #ffffff;
	font-size: 13px;
	line-height: 18px;

	background-color: var(--additional-services-add-button);
}

.additional_horizontal-1hjRt button.buttonBase-2z5jR {
	height: 48px;
	width: 120px;
}

.additional_horizontal-1hjRt .price-1dLYJ {
	margin-right: 24px;
}

button.buttonBase-2z5jR.remove-3QFWj {
	font-weight: 600;

	color: #353535;

	background-color: #ededed;
}

.button-1p82_ {
	display: inline-flex;
	align-items: center;
	height: 48px;
	padding: 0 20px;

	border-radius: 24px;

	color: var(--additional-services-add-button-color);

	cursor: pointer;
	background: var(--additional-services-add-button);
}

.button_from-P4xzv {
	height: 100%;
	margin-right: 20px;
	padding-right: 20px;

	border-right: 1px dashed rgba(255, 255, 255, 0.4);

	font-weight: 600;
	font-size: 15px;
	line-height: 48px;
}

span.price-1dLYJ {
	padding-left: 0;

	font-size: 20px;
	font-weight: 600;
	color: var(--baggage-additional-selected-money-color);
	color: var(--checkin-additional-price-selected-color, var(--baggage-additional-selected-money-color));
}

span.price_notSelected-3q0Io {
	color: var(--cabinet-action);
}

@media screen and (max-width: 600px) {
	.additional-2z3AK {
		width: 100%;
		max-width: unset;
		flex-basis: unset;
	}

	.additional-2z3AK:not(:last-child) {
		margin-right: 0;
		margin-bottom: 13px;
	}

	.head-1WJ78 .logo__wrapper-3HGEA {
		display: none;
	}
}
