

.autocomplete-VQ_8S {
	position: relative;

	width: 100%;
}

.autocomplete-VQ_8S > div {
	width: 100%;
}

.input-1dtxU {
	display: inline-block;
}
