.content-1nmSK {
	padding: 16px;
	max-width: 375px;
}

.header-2lMDC {
	margin: 0 0 12px;
}

.helperText-11UlT {
	position: absolute;
	top: 100%;
}

@media screen and (max-width: 600px) {
	.content-1nmSK {
		max-width: 100%;
	}
}

.input-2qD_R {
	width: 100%;
}

.iconText-p2KG_ {
	display: none;
}

div.slideBottom-Sgu5Q {
	padding: 0;
}

.warningContent-3NGpf {
	padding: 12px;

	font-weight: 500;
	font-size: 16px;

	background: var(--errorBG);
}

.warningText-MpaPE,
.warningIcon-TDxCg {
	color: var(--error-text);
}

@media screen and (max-width: 600px) {
	div.warningContent-3NGpf {
		flex-direction: row;
		align-items: center;
	}

	.warningIcon-TDxCg {
		margin-bottom: 0;
	}
}

@media (max-width: 768px) {
	div.paper-30nI0 {
		border-radius: 0;
	}
}

.form-1YtnA {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
}

button.button-XtlvK {
	width: 100%;
	margin-top: 35px;

	font-size: 14px;
}

.controls-kQS-j {
	justify-content: space-between;
	margin-top: 16px;
	display: none;
}
