

.root-3WCuM {
	display: flex;
	align-items: center;
	justify-content: flex-end;
}

button.control-2TD0T {
	min-width: 0;
	height: 32px;
	margin-left: 12px;
	padding: 0 22px;

	font-weight: 700;
	font-size: 16px;
	line-height: 1;
}

button.control-2TD0T.control__edit-3Q-qv {
	color: var(--base);

	background: var(--gray05);
}

button.control-2TD0T.control__edit-3Q-qv:hover {
	opacity: 0.8;
}

.price-2XoMB {
	font-weight: 700;
	font-size: 18px;
	line-height: 1;
	color: var(--base);
}

.price__selected-1yFPJ {
	color: var(--gray80);
}
