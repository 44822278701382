

.segment-Ch9Jg {
	display: block;
}

div.aircraftModal__root-Y2Mf2 {
	z-index: 1502 !important;
}

.dates-1Bxgt {
	display: flex;
	align-items: center;
}

.date-4ouid {
	position: relative;

	display: flex;
	align-items: center;

	color: #1f1f1f;
	font-weight: 700;
	font-size: 24px;
	line-height: 150%;
	font-family: 'Roboto Condensed', sans-serif;
}

.segment_filled-2hGwp .date-4ouid {
	color: #ffffff;
}

.segment_filled-2hGwp div.date-4ouid:after {
	border: 1px solid #ffffff;

	background: transparent;
}

.date-4ouid:first-of-type:after {
	margin-left: 10px;
}

.date-4ouid:last-of-type {
	flex-direction: row-reverse;
}

.date-4ouid:last-of-type:after {
	margin-right: 10px;
}

.date-4ouid:first-of-type:after,
.date-4ouid:last-of-type:after {
	display: flex;

	width: 7px;
	height: 7px;

	border: 1px solid #a5a5a5;
	border-radius: 50%;

	background: #ffffff;
	content: '';
}

.date_nextDayArrival-21Wb4 {
	position: absolute;
	top: -10px;
	right: -11px;

	color: var(--dark-red);
	font-weight: 600;
	font-size: 12px;
	line-height: 20px;
}

.timeEnRoute-2VGMO {
	position: relative;

	display: flex;
	align-items: center;

	flex-basis: 100%;

	font-size: 14px;
	line-height: 1.15;
	text-align: center;
}

.timeEnRoute-2VGMO:before,
.timeEnRoute-2VGMO:after {
	display: flex;
	flex: 1;
	height: 1px;

	background: #a5a5a5;
	content: '';
}

.segment_filled-2hGwp .timeEnRoute-2VGMO:before,
.segment_filled-2hGwp .timeEnRoute-2VGMO:after {
	background: #ffffff;
}

.timeEnRoute_text-16bU5 {
	position: relative;

	padding: 0 8px;

	font-weight: 500;

	background: #ffffff;
}

.segment_filled-2hGwp .timeEnRoute_text-16bU5 {
	color: #ffffff;

	background: transparent;
}

.short-2OS6N {
	padding-top: 8px;

	color: #777777;
	font-size: 14px;
	text-align: center;
}

.segment_filled-2hGwp .short-2OS6N {
	color: #ffffff;
}

.airports-3woYB {
	display: flex;
	margin-top: 5px;
	justify-content: space-between;
}

.airport-3Kzw3 {
	max-width: 50%;

	color: #1f1f1f;
	font-weight: 500;
	font-size: 14px;
}

.airport__city-QuF51 {
	line-height: 20px;
}

.airport__name-2JSd7,
.airport__terminal-3ha_G {
	font-size: 13px;
	line-height: 16px;
	font-weight: 400;

	opacity: 0.7;
}

.airport-3Kzw3:last-child {
	text-align: right;
}

.details-1SvXo {
	margin-top: 12px;

	border-top: 1px dashed #d9d9d9;
}

.details__summary-3-xTp {
	display: flex;
	align-items: center;

	color: var(--common-gray);
	font-size: 13px;
	line-height: 1.15;

	opacity: 1;
}

.details__logo-3n31J,
.details__eco-akRB6 {
	display: inline-flex;
	align-items: center;
}

.details__logo-3n31J {
	width: 20px;
	height: 20px;
}

.details__logo-3n31J img {
	width: 100%;
	height: 100%;
}

.details__summary-3-xTp span:not(:first-child) {
	padding-left: 5px;
}

.details__summary-3-xTp span:not(:first-child):before {
	content: '\2022';

	padding-right: 5px;
}

.details__short-3Zuiv {
	position: relative;

	display: flex;
	justify-content: space-between;
	align-items: center;
	min-height: 40px;
	margin-bottom: 10px;

	cursor: pointer;
}

.details__arrow-I7gmK {
	width: 24px;
	height: 24px;

	background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTMiIGhlaWdodD0iOCIgdmlld0JveD0iMCAwIDEzIDgiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xMi42ODM2IDEuNDFMMTEuMTk5MSAwTDYuMzY2NzYgNC41OEwxLjUzNDM5IDBMMC4wNDk5MzMgMS40MUw2LjM2Njc2IDcuNDFMMTIuNjgzNiAxLjQxWiIgZmlsbD0iIzlBOUE5QSIvPgo8L3N2Zz4K) center no-repeat;

	transition: transform 0.15s;
}

.opened-3UifG .details__arrow-I7gmK {
	transform: rotate(180deg);
}

.details__full-14p8Z {
	display: block;
	margin-bottom: 20px;
}

.statistics-2msoV {
	margin: 0 0 16px;
	padding: 8px 12px;

	border-radius: 3px;

	background-color: #ededed;
}

.statistics__item-1rBfU {
	display: flex;
	justify-content: space-between;

	font-size: 13px;
	line-height: 20px;
}

.statistics__item__title-1Ib0H {
	color: var(--common-gray);
}

.statistics__item__value-17E1e {
	color: #464646;
}

button.aircraft-2YmSp {
	display: block;
	width: 100%;

	text-align: left;
}

.aircraft__name-3MOKl {
	color: #1f1f1f;
	font-size: 16px;
	font-weight: 500;
}

.aircraft__image-fy7Sc {
	height: auto;
	max-width: 85vw;
	margin: 10px auto 20px;
}

.aircraft__features-uEOxR {
	width: 100%;

	display: flex;
	justify-content: space-between;
	align-items: flex-end;
}

.aircraft__features__moreInfo-3nCt8 {
	position: relative;

	padding-right: 30px;

	color: var(--common-gray);
	font-size: 14px;

	cursor: pointer;
}

.aircraft__features__moreInfo-3nCt8:after {
	position: absolute;
	top: -4px;
	right: 0;

	display: block;
	width: 24px;
	height: 24px;

	background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgY2xpcC1wYXRoPSJ1cmwoI2NsaXAwKSI+CjxjaXJjbGUgcj0iMTIiIHRyYW5zZm9ybT0ibWF0cml4KDAgLTEgLTEgMCAxMiAxMikiIGZpbGw9IndoaXRlIi8+CjxwYXRoIGQ9Ik0xMC40MSA2TDkgNy40MUwxMy41OCAxMkw5IDE2LjU5TDEwLjQxIDE4TDE2LjQxIDEyTDEwLjQxIDZaIiBmaWxsPSIjNzc3Nzc3Ii8+CjwvZz4KPGRlZnM+CjxjbGlwUGF0aCBpZD0iY2xpcDAiPgo8cmVjdCB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIGZpbGw9IndoaXRlIi8+CjwvY2xpcFBhdGg+CjwvZGVmcz4KPC9zdmc+Cg==) center no-repeat;
	content: '';
}

.airport__info__name-2YsKm {
	margin-top: 25px;
	margin-bottom: 10px;

	color: #1f1f1f;
	font-weight: 500;
	font-size: 16px;
}

.airport__info-2_9z2 {
	position: relative;

	width: 100%;
	min-width: 93px;

	display: flex;

	background-color: var(--common-blue);
}

button.airport__info__image-ZBBEM {
	position: relative;

	flex-grow: 1;

	color: #ffffff;

	background-image: url('https://cdn.websky.aero/content/frontend/images/mock-airport.png');
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;

	cursor: pointer;
}

.airport__info__icon-3miic {
	position: absolute;
	right: 10px;
	bottom: 10px;

	display: flex;
	align-items: center;
	justify-content: center;
}

.weather-1Kh1_ {
	position: relative;

	height: 100%;
	width: 102px;
}

.weather__value-12ChW {
	position: absolute;
	right: 8px;
	bottom: 5px;

	color: #ffffff;
	font-weight: 300;
	font-size: 40px;
}

.weather__icon-IjDBW {
	position: absolute;
	top: 6px;
	left: 5px;

	display: flex;
	align-items: center;
	justify-content: center;

	color: #ffffff;
}
