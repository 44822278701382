.logo-3Vqy_ {
	display: flex;
	align-items: center;
	justify-content: flex-start;

	height: 36px;

	border-radius: 4px;

	background: #ffffff;
}

.logo-3Vqy_ svg {
	width: 100px;
	height: 20px;
}

button.button-1M5Ao {
	min-width: 120px;
	height: 44px;

	border-radius: 24px;

	line-height: 44px;
}

div.popup-13UZ2 {
	padding: 40px 28px 28px;
	overflow: visible;

	border-radius: 8px;
}

.stepbar__actions-3Xdtx {
	display: flex;
	align-items: center;
}

.stepbar__action-3tUFI {
	display: inline-block;

	width: 24px;
	height: 24px;

	margin-left: 6px;

	cursor: pointer;
}

.stepbar__action_close-2VdWb {

	background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyMCAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0wIDEwQzAgNC40NyA0LjQ3IDAgMTAgMEMxNS41MyAwIDIwIDQuNDcgMjAgMTBDMjAgMTUuNTMgMTUuNTMgMjAgMTAgMjBDNC40NyAyMCAwIDE1LjUzIDAgMTBaTTEzLjU5IDE1TDE1IDEzLjU5TDExLjQxIDEwTDE1IDYuNDFMMTMuNTkgNUwxMCA4LjU5TDYuNDEgNUw1IDYuNDFMOC41OSAxMEw1IDEzLjU5TDYuNDEgMTVMMTAgMTEuNDFMMTMuNTkgMTVaIiBmaWxsPSIjOUE5QTlBIi8+Cjwvc3ZnPgo=) center no-repeat;
}

.stepbar__action_openPolicy-2TL5W {
	position: relative;
	top: 1px;

	color: #9a9a9a;

	transform: rotate(-90deg) scale(0.85);
	background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGNpcmNsZSBjeD0iMTIiIGN5PSIxMiIgcj0iMTIiIGZpbGw9IiM5YTlhOWEiLz4KPHBhdGggZD0iTTE2LjU5IDguNTkwMDlMMTIgMTMuMTcwMUw3LjQxIDguNTkwMDlMNiAxMC4wMDAxTDEyIDE2LjAwMDFMMTggMTAuMDAwMUwxNi41OSA4LjU5MDA5WiIgZmlsbD0id2hpdGUiLz4KPC9zdmc+Cg==);
}

.popup__title-16aYs {
	margin: 0;

	font-weight: 500;
	font-size: 22px;
	line-height: 32px;
}

.popup__text-3u1NS {
	margin: 10px 0 0 0;

	font-size: 15px;
	line-height: 20px;
	color: var(--common-gray);
}

.popup__buttons-2W8Oj {
	margin-top: 28px;

	display: flex;
	justify-content: flex-end;
}

.popup__buttons-2W8Oj button:first-of-type {
	margin-right: 12px;

	color: var(--common-gray);
	font-weight: 400;

	background-color: #ededed;
}

.slide__content-32NJQ {
	padding: 16px 20px 12px;
}

div.paper-1L3b6 {
	max-width: 900px;
}

div.paper_confirm-22gCG,
div.paper_download-2N81r {
	max-width: 600px;

	padding: 32px;
}

@media screen and (max-width: 600px) {
	.popup__title-16aYs,
	.popup__text-3u1NS {
		text-align: center;
	}

	.popup__title-16aYs {
		font-size: 18px;
		line-height: 27px;
	}

	.popup__text-3u1NS {
		line-height: 22px;
	}

	.popup__buttons-2W8Oj {
		flex-direction: column-reverse;
		align-items: center;
	}

	.popup__buttons-2W8Oj button {
		width: 100%;
	}

	.popup__buttons-2W8Oj button:first-of-type {
		margin-right: 0;
		margin-top: 12px;
	}

	div.scrollBody-3jk6I {
		display: flex;
		justify-content: flex-end;
	}

	div.scrollBody-3jk6I div.paper-1L3b6 {
		width: 100%;
		min-height: auto;

		margin: auto 0 0 !important;

		border-radius: 20px 20px 0 0;
	}

	div.paper_confirm-22gCG,
	div.paper_download-2N81r {
		padding: 48px 20px 20px;
	}
}
