

.container-1AVUI {
	display: flex;
	flex-shrink: 0;
	justify-content: center;
	align-items: center;
	width: 16px;
	height: 16px;
	margin-right: 5px;

	color: #b3b3b3;
}

.container-1AVUI > svg {
	width: 100%;
	height: 100%;
}
