div.modal-3mqVF {
	max-width: 675px;
	padding: 40px;
}

.header-2J9HS {
	margin-bottom: 53px;

	color: #353535;
	font-weight: 600;
	font-size: 24px;
}

.header-2J9HS span {
	text-transform: capitalize;
}

.container-PL7-s {
	display: flex;
	align-items: flex-end;
}

.container-PL7-s svg {
	flex-shrink: 0;
	margin-right: 20px;
	margin-bottom: 5px;
}

.input__wrapper-UxYkH {
	display: flex;
	align-items: flex-end;
	flex: 1;
}

div.input-26vwa {
	margin-right: 20px;
}

button.button-3gWBX {
	border: none;

	color: #ffffff;

	background: var(--base);
}

button.button-3gWBX:hover {
	background: var(--base);
}

/* stylelint-disable-next-line block-no-empty */
.modal__scroll-fGhDW {
}

@media screen and (max-width: 600px) {
	.container-PL7-s {
		display: block;
	}

	div.input-26vwa {
		margin-right: 0;
		margin-bottom: 12px;
	}

	button.button-3gWBX {
		width: 100%;
	}

	.input__wrapper-UxYkH {
		align-items: center;
	}
}
