

.step-1qMvZ {
	display: flex;
	flex: 1;
	justify-content: center;
	align-items: center;
	height: 100%;
	padding: 5px 10px;

	border: 1px solid var(--brand-1-1);
	border-top: 0;
	border-right: 0;

	color: var(--brand-1-3);
	font-weight: 700;
	font-size: 13px;

	white-space: nowrap;
	text-transform: uppercase;

	cursor: default;
}

.step-1qMvZ:last-child {
	padding-right: 0;
	margin-right: 0;
}

.id-5Xtjt {
	margin-right: 8px;
}

.arrow-3T5Ti {
	display: flex;
	margin-left: 15px;
}

.step-1qMvZ:last-child .arrow-3T5Ti {
	display: none;
}

.step_active-1qbxi {
	border: 0;

	color: var(--brand-1-0);

	background: var(--bg-white);
}

.step-1qMvZ.step_passed-13QVV {
	color: var(--bg-white);
}

.step-1qMvZ.step_disabled-7Cqqx {
	border: none;

	color: var(--brand-1-2);
}

.step-1qMvZ.step_passed-13QVV.step_clickable-UoDCT:hover .label-2n3oX,
.step-1qMvZ.step_active-1qbxi.step_clickable-UoDCT:hover .label-2n3oX {
	text-decoration: underline;
}

.step_clickable-UoDCT {
	cursor: pointer;
}
