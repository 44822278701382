

.wrapper-3xoqP {
	max-width: 1100px;
	margin: 0 auto;
}

.title-1e5NY {
	margin: 0;

	font-weight: 500;
	font-size: 20px;
	line-height: 24px;
}

.content-y2w-X {
	margin-bottom: 0;
	margin-top: 8px;

	font-size: 15px;
	line-height: 18px;
}

.content__text-37vmi {
	white-space: break-spaces;
}

.inner-e7Q-U {
	position: relative;

	display: flex;
	flex-direction: column;
	padding: 20px 20px 20px 100px;

	color: #353535;

	background: #ffffff;
}

.icon-33YME {
	position: absolute;
	left: 6px;
	top: 50%;

	transform: translate(50%, -50%);
}

.links-1TmqT {
	display: block;
}

.link-ziuSN {
	display: inline-block;
	margin-top: 6px;

	font-size: 12px;
	line-height: 14px;
	font-weight: 500;
	text-decoration: none;
}
