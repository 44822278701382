.root-28pss {
	border-radius: unset;
}

div.paper-2kSqX {
	position: relative;

	padding: 0;

	display: flex;
	flex-direction: column;

	border-radius: var(--modal-radius);

	background: var(--bg-white);
}

div.paper-2kSqX.paper__fullScreen-1kvR8 {
	max-height: unset;
	justify-content: space-between;

	border-radius: 0;
}

div.paper_paperScrollBody-1qWNd {
	margin-left: auto;
	margin-right: auto;
}

div.fullscreen-1zvae {
	padding: 40px;
	max-height: 100%;
	grid-gap: 24px;
	gap: 24px;
}

div.small-1duhU.paper__noPadding-2BRsW,
div.medium-3sXSX.paper__noPadding-2BRsW,
div.large-3jxAT.paper__noPadding-2BRsW,
div.xlarge-1Wj3q.paper__noPadding-2BRsW,
div.fullscreen-1zvae.paper__noPadding-2BRsW {
	padding: 0;
}

div.small-1duhU {
	max-width: 460px;
	padding: 32px;
	grid-gap: 24px;
	gap: 24px;
}

div.medium-3sXSX {
	max-width: 640px;
	padding: 40px;
	grid-gap: 24px;
	gap: 24px;
}

div.large-3jxAT {
	max-width: 800px;
	padding: 40px;
	grid-gap: 24px;
	gap: 24px;
}

div.xlarge-1Wj3q {
	max-width: 1000px;
	padding: 40px;
	grid-gap: 24px;
	gap: 24px;
}

.top_wrapper-2r_Fp {
	display: flex;
	flex-direction: column;
	grid-gap: 12px;
	gap: 12px;
	padding: 0;
	max-height: 100%;
	overflow-y: hidden;
}

.content-4e6p3 {
	overflow-y: auto;
}

div.dialog_container-B4v-G:after {
	height: 0;
}

button.closeButton-vaQvO {
	position: absolute;
	top: 8px;
	right: 8px;
	z-index: 1;

	width: 32px;
	height: 32px;

	color: var(--text-pale);

	background: var(--bg-fill);
}

.swipeBlock-1JiC3 {
	position: absolute;
	top: -40px;
	left: 0;
	right: 0;

	height: 40px;
	display: flex;
	align-items: center;
	justify-content: center;

	background: transparent;
}

.swipeBlock_line-7oF-4 {
	display: block;
	width: 70px;
	height: 6px;

	border-radius: 10px;

	background: var(--text-pale);
}

@media screen and (max-width: 600px) {
	div.paper-2kSqX {
		position: absolute;
		left: 0;
		right: 0;
		bottom: 0;

		width: 100%;
		max-width: 100%;
		min-width: 100%;
		max-height: 90vh;
		margin: 0;

		overflow-y: visible;

		border-radius: var(--modal-mobile-radius);
	}

	div.small-1duhU,
	div.medium-3sXSX,
	div.large-3jxAT,
	div.xlarge-1Wj3q,
	div.fullscreen-1zvae {
		max-width: 100%;
		padding: 20px;
		grid-gap: 24px;
		gap: 24px;
	}

	div.paper-2kSqX.paper_paperScrollBody-1qWNd {
		position: relative;
		top: 20vh;
		bottom: unset;

		width: 100%;
		max-width: 100%;
		max-height: unset;
	}

	div.paper-2kSqX.paper__fullScreen-1kvR8 {
		position: relative;
		top: unset;
		bottom: unset;

		max-height: unset;

		border-radius: 0;
	}

	div.paper-2kSqX.paper_paperScrollBody-1qWNd.paper__fullScreen-1kvR8 {
		height: auto;
	}
}

/* @media (max-width: 507.95px) {
	div.paper_paperScrollBody {
		width: 100%;
		max-width: 100% !important;
	}
} */
