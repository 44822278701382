

div.popper-2v2WP {
	z-index: 1600;

	opacity: 1;
}

.freeSeat-2ctBO {
	font-size: inherit;
}

div.tooltip-2J0Vo {
	position: relative;

	max-width: 400px;

	margin-bottom: 0;
	padding: 0;

	border-radius: 4px;

	box-shadow: 0 2px 24px rgba(0, 0, 0, 0.15);
	background: var(--base);
}

div.tooltip-2J0Vo.tooltip_recommend-1Ek-- {
	background: var(--selected2);
}

div.tooltip_freeSeat-1lybl {
	font-size: inherit;
}

.tooltip-2J0Vo:after {
	position: absolute;
	right: 0;
	bottom: 0;
	left: 0;

	width: 0;
	height: 0;
	margin: auto;

	border: 5px solid transparent;
	border-top: 5px solid var(--base);

	transform: translateY(100%);
	content: '';
}

.tooltip-2J0Vo.tooltip_recommend-1Ek--:after {
	border-top-color: var(--selected2);
}

.tooltip_bottom-3bmS4:after {
	top: -10px;
	bottom: auto;

	transform: rotate(180deg);
}

.tooltip_withInfo-3MfF8.tooltip-2J0Vo:after {
	border-top-color: var(--base15);
}

.container-1oueg {
	display: flex;
	align-items: center;

	padding: 10px 16px;
}

.number-2OJ5G {
	margin-right: 16px;
	padding-right: 16px;

	border-right: 1px dashed var(--brand-1-2);

	color: var(--white);
	font-weight: 700;

	font-size: 31px;
	line-height: 1.3;
}

.details-2U9LD {
	display: flex;
	flex-direction: column;

	font-weight: 500;
	font-size: 14px;
}

.details__price-2seNP {
	padding-left: 0;

	color: var(--white);
	font-weight: 700;

	font-size: 16px;
	line-height: 1.3;
}

.details__seatName-1ZITq {
	color: var(--brand-1-4);

	font-size: 13px;
	font-weight: 400;
	line-height: 1.3;
}

div.info-pl8zT {
	padding: 8px;

	border-radius: 0 0 4px 4px;

	color: #ffffff;

	background: var(--base15);
}
