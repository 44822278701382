

@keyframes planeSpinner-3Lw8A {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}

.loader-icV_H {
	width: 119px;
	height: 120px;

	animation: planeSpinner-3Lw8A 2s linear infinite;
	overflow: hidden;
}

.wrapper-3waZs {
	overflow: hidden;

	color: black;
}
