

button.wrapper-3VULf {
	display: flex;
	justify-content: space-between;
	width: 100%;
	min-width: 260px;
	padding: 16px 20px;

	border-left: 1px solid #d9d9d9;
}

button.wrapper_arrowed-Z8tP5 {
	position: relative;

	border-left: none;

	transition: background-color 0.15s;
}

.wrapper_arrowed-Z8tP5:before {
	content: '';

	position: absolute;
	left: 0;

	width: 1px;
	height: var(--results-flight-price-separator-height);

	background-color: var(--results-flight-price-separator-background);
}

.wrapper_arrowed-Z8tP5:after {
	position: absolute;
	bottom: 0;
	left: 0;

	width: 100%;
	height: 4px;

	opacity: 0;
	background-color: var(--results-flight-price-underline-background);

	transition: opacity 0.15s;

	pointer-events: none;
	content: '';
}

.wrapper_arrowed-Z8tP5.wrapper_business-3VmDe:after {
	background-color: var(--results-flight-price-business-underline-background);
}

.wrapper_active-7843I.wrapper_arrowed-Z8tP5:after,
.wrapper_arrowed-Z8tP5:hover:after {
	opacity: 1;
}

button.wrapper_economy-1QjnP {
	color: var(--results-flight-price-economy-group);

	background: var(--results-flight-price-economy-group-color);
}

button.wrapper_business-3VmDe {
	color: var(--results-flight-price-business-group);

	background: var(--results-flight-price-business-group-color);
}

button.wrapper_economy-1QjnP.wrapper_active-7843I {
	background: var(--results-flight-price-economy-group-opened);
}

button.wrapper_business-3VmDe.wrapper_active-7843I {
	color: var(--results-flight-price-business-group-color-opened);

	background: var(--results-flight-price-business-group-opened);
}

button.wrapper_flat-m9tbk {
	border-left: none;

	color: #ffffff;
}

.wrapper_flat-m9tbk.wrapper_economy-1QjnP {
	background-color: var(--common-blue);
}

.wrapper_flat-m9tbk.wrapper_business-3VmDe {
	background-color: var(--dark-violet);
}

.wrapper_arrowed-Z8tP5.wrapper_economy-1QjnP:hover {
	background-color: rgba(50, 144, 255, 0.1);
}

.wrapper_arrowed-Z8tP5.wrapper_business-3VmDe:hover {
	background-color: rgba(80, 109, 210, 0.1);
}

button.wrapper_singleFare-2a06N {
	min-width: 210px;
	max-width: 210px;
}

.titles-Xfee0 {
	display: flex;
	justify-content: center;
	flex-direction: column;

	text-align: left;
}

.title-Q8GQT {
	font-size: 18px;
	font-weight: 600;
	line-height: 24px;
	text-transform: capitalize;
}

.title_singleFare-3fG9X {
	display: none;
}

.prefix-2BVej {
	margin-top: 8px;

	color: var(--results-flight-price-prefix);
	font-size: 14px;
	line-height: 16px;

	opacity: 0.7;
}

.prefix_singleFare-3i1Au {
	margin-top: 0;
}

.prefix_noSeats-1CXUx {
	font-size: 20px;
	text-transform: uppercase;
	font-weight: 600;
	color: inherit;
}

.wrapper_flat-m9tbk .prefix-2BVej {
	color: rgba(255, 255, 255, 0.7);
}

.price-3x3Ak {
	position: relative;
	top: 5px;

	display: flex;
	align-items: center;

	align-self: flex-end;

	font-weight: 600;
	font-size: 34px;
	white-space: nowrap;
}

.price_singleFare-1ayRq {
	position: static;

	align-self: center;
}

.price__icon-1VaHi {
	display: flex;
	align-items: center;
	margin-left: 10px;

	color: #ffffff;

	transition: transform 0.15s;
}

.wrapper_active-7843I .price__icon-1VaHi {
	transform: rotate(-180deg);
}

.price_arrowed-A5huE {
	font-size: 32px;
}

.price_RUB-1H-Zr {
	font-size: 25px;
}

button.wrapper_disabled-2aKac {
	cursor: not-allowed;
}

button.wrapper_arrowed-Z8tP5.wrapper_disabled-2aKac:hover {
	background-color: inherit;
}

button.wrapper_disabled-2aKac:after {
	display: none;
}

button.wrapper_disabled-2aKac .titles-Xfee0 {
	opacity: 0.4;
}
