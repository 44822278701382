

.wrapper-1PPeR {
	position: fixed;
	bottom: 0;
	left: 0;
	z-index: 90;

	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	padding: 12px;
	grid-gap: 12px;
	gap: 12px;

	background: var(--bg-white);
	box-shadow: 0 -4px 40px 0 rgba(0, 0, 0, 0.08);
}

button.button-2DAWG {
	min-width: 0;
	padding: 10px 24px;

	color: var(--white);
	text-transform: none;
}

button.button_back-3H-WB {
	color: var(--text-light);

	font: var(--normal-desktop);
}

button.button_back-3H-WB svg {
	width: 20px;
	height: 20px;
}

button.button_continue-3WkaF {
	margin-left: auto;

	font: var(--normal-desktop-bold);
}

button.button_back-3H-WB.button_empty-3g7yR {
	width: 40px;
	height: 40px;
	padding: 0;

	border-radius: 50%;
}
