.dialog-1Xlti {
	/* z-index: 1501 !important; */
	z-index: inherit;
}

.dialog__content-MNInF {
	padding: 0;
}

.header__title-3XQiy {
	max-width: 500px;

	color: var(--text-base);
	font-weight: 700;
	font-size: 22px;
	line-height: 1.3;

	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
}

.header__title-3XQiy > span {
	max-width: 100%;
	overflow: hidden;

	text-overflow: ellipsis;
}

.header__rulesPrice-afJDX {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	min-width: 412px;
	max-width: 412px;

	white-space: nowrap;
}

.price_withRules-eK7rw {
	margin-left: 20px;
	padding-left: 20px;
	height: 32px;

	border-left: 1px solid #b6b6b6;

	line-height: 32px;
}

button.dialog__button_done-tVuMr,
button.dialog__button_clear-1AEyj {
	width: 183px;
	height: 48px;

	border-radius: 2px;

	font-size: 14px;
}

button.dialog__button_clear-1AEyj {
	margin-right: 7px;

	color: #9a9a9a;

	background-color: #ededed;
}

button.dialog__button_clear-1AEyj:hover {
	background-color: #dadada;
}

.popupHeader-1e-Qv {
	height: 84px;

	border-radius: 8px 8px 0 0;

	color: #1f1f1f;
	font-weight: 600;

	font-size: 24px;
	line-height: 84px;
	text-align: center;

	background: #ededed;
}

div.paper-2NWqD {
	max-width: 100%;
	overflow: hidden;
}
