

span.radioButton-3VYn9 {
	color: var(--text-pale);
}

span.radioButton-3VYn9.checked-1afa8 {
	color: var(--brand-1-0);
}

span.radioButton-3VYn9.disabled-2a7_Z {
	color: var(--gray20);
}
