

.container-2yChE.fares-1rzSf {
	margin: 70px 0;

	background: var(--bg-canvas);
}

.content-1sPai {
	max-width: 1100px;
	margin: 0 auto;

	font-family: 'Montserrat', 'Roboto', sans-serif;
}

.content-1sPai:not(.content_fullWidth-14wOG) {
	padding: 0 20px;
	max-width: 1140px;
}

.content-1sPai button {
	font-family: 'Montserrat', 'Roboto', sans-serif;
}

.container-2yChE.fares-1rzSf .content-1sPai {
	padding: 70px 20px;
}
