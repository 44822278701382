.flight-1RDad {

	margin-bottom: 8px;
}

.flight-1RDad:first-of-type {
	margin-top: 20px;
}

.flight-1RDad:last-of-type {
	margin-bottom: 20px;
}

.flight-1RDad:hover,
.flight_open-1A74E {
	box-shadow: 0 2px 13px rgba(0, 0, 0, 0.12);
}

.info-1ret5 {
	border-top: 1px solid #d9d9d9;
}

.features-1NyDX {
	display: flex;
	justify-content: space-between;
	align-self: center;
	margin: 0 20px;
	padding: 12px 16px;

	border-radius: 4px;

	color: var(--summary-flight-features-color);

	background-color: var(--summary-flight-features);
}

.features__items-1C2L1 {
	display: flex;
}

.features__links-2bH2C {
	display: flex;
	align-items: center;

	font-size: 13px;
}

.features__links-2bH2C a:not(:last-of-type) {
	margin-right: 20px;
}

.feature-8h2wg {
	display: flex;
	align-items: center;
}

.feature-8h2wg:not(:last-of-type) {
	margin-right: 20px;
}

.feature__icon-25kXy {
	position: relative;
	top: -1px;

	display: flex;
	align-items: center;

	color: #9a9a9a;
}

.feature__text-xtmSv {
	margin-left: 8px;

	font-size: 13px;
}

.modal-1XRMI {
	z-index: 1501 !important;
}
