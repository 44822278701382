.rules-11YHl {
	padding: 40px 60px;
}

.rules-11YHl .rules_1-1:after {
	display: none;
}

.rules__title-21u5P {
	margin: 0;

	font-size: 20px;
	line-height: 28px;
}

.rules__text-3d_WM {
	margin: 24px 0;

	font-size: 14px;
	line-height: 21px;
}

.rules__footer-1qS6y {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

/* stylelint-disable */
.rules__button-2caf9 {
}
/* stylelint-enable */
span.rules__label-15iBF {
	padding-right: 60px;

	font-size: 17px;
	font-family: inherit;
	line-height: 24px;
	color: var(--common-gray);
}

span.rules__label-15iBF a {
	color: var(--text-link);
}

span.rules__checkbox-12c_E.rules__checkbox_checked-2DCm2 {
	color: var(--common-blue);
}

@media screen and (max-width: 600px) {
	.rules-11YHl {
		padding: 20px 40px 80px 20px;
	}

	.rules__footer-1qS6y {
		display: block;
	}

	span.rules__label-15iBF {
		padding-right: 0;
	}

	button.rules__button-2caf9 {
		position: fixed;
		bottom: 0;
		left: 0;
		right: 0;

		width: 100%;
		height: 60px;

		border-radius: 0;

		font-size: 17px;
	}
}
