div.input__wrapper-1J14a {
	margin-right: 6px;
	flex: 1;
}

div.input__wrapper-1J14a > div {
	width: 100%;
}

.input__inner-1C1DZ {
	width: 100%;
	height: var(--input-height);
	padding: 0 16px;

	border: none;
	border-radius: 2px;

	color: #ffffff;
	font-weight: 500;
	font-size: 15px;
	line-height: var(--input-height);
	text-align: left;

	background-color: rgba(255, 255, 255, 0.2);

	transition: background-color 0.15s, color 0.15s;

	appearance: none;
}

div.input__inner-1C1DZ:before {
	background: none;
}

label.label-2vUNH {
	color: #ffffff;

	transform: translate(16px, 18px) scale(0.9);
}

label.label-2vUNH.label_shrink-2QhyO {
	z-index: 2;

	transform: translate(16px, 1px) scale(0.75);
}

label.label-2vUNH + div.input__inner-1C1DZ {
	margin-top: 0;
}

div.input__inner-1C1DZ input {
	padding: 0;

	color: #ffffff;
}

div.input_focused-3_URd.input__inner-1C1DZ {
	background-color: #ffffff;
	opacity: 1;
}

div.input_focused-3_URd.input__inner-1C1DZ input {
	border: none;

	color: var(--dark-gray);
}

@media screen and (max-width: 600px) {
	div.input__wrapper-1J14a {
		margin-bottom: 6px;
		margin-right: 0;
	}
}

/* stylelint-disable */
.label_error-1Zbcd {
}
/* stylelint-enable */
