.header-2gEi2 {
	background: var(--bg-white);
}

.inner-2MaEm {
	display: flex;
	align-items: center;
	padding: 28px 20px;
	justify-content: space-between;
}

@media screen and (max-width: 600px) {
	.inner-2MaEm {
		flex-direction: column;
	}
}

.listItem-3uPqK:first-of-type {
	margin-top: 12px;
}

.searchIcon-3I-vG {
	position: relative;
	top: 5px;

	color: var(--brand-1-0);

	transform: scale(1.3);
}

.filters-3jhr6 {
	display: flex;
	justify-content: space-between;
	margin-bottom: 24px;
}

.filter-3m2Bp {
	display: inline-flex;
	flex: 1;
	align-items: center;

	text-decoration: none;
	color: var(--text-light);
}

.filter-3m2Bp:not(:last-of-type) {
	padding-right: 27px;

	margin-right: 27px;

	border-right: 1px solid var(--line-separator);
}

.filter-3m2Bp svg {
	margin-right: 8px;

	color: var(--text-light);
}

.filter-3m2Bp.active-2TBaQ {
	color: var(--brand-1-0);
}

div.closeIcon-2OurO svg {
	color: var(--text-pale);

	cursor: pointer;
}

.filter-3m2Bp.active-2TBaQ svg {
	color: var(--brand-1-0);
}

div.collapse-3FXJT {
	width: 100%;
}

.collapseActive-3C6eC {
	padding-top: 20px;
}

.listItem-3uPqK:not(:last-of-type) {
	margin-bottom: 12px;
}

.title-3x8xQ {
	font-size: 24px;
	font-weight: 700;
	line-height: 24px;
	color: #333333;
}

/* stylelint-disable */
.right-1IKxO {
}
/* stylelint-enable */
label.label-2JI2V {
	font-size: 14px;
}

div.drawerContainer-3JIbf,
div.drawerPaper-1WHKj {
	top: var(--map-status-header-height);

	height: calc(100vh - var(--map-status-header-height));
}

.drawerPaper-1WHKj.scroll-2OaLy {
	padding-right: 30px;
}

.delimiter-1sVwF {
	position: relative;

	display: inline-block;
	margin-bottom: 12px;

	color: var(--text-light);
	text-align: center;
	font-size: 14px;
	line-height: 18px;
}

.delimiterText-2uBh5 {
	position: relative;

	display: inline;
	padding: 0 10px;

	background: #ffffff;
}

.delimiter-1sVwF:before {
	position: absolute;
	top: 50%;
	left: 0;

	display: inline-block;
	content: '';
	width: 100%;
	height: 1px;

	background: #b5bdcb;
	transform: translateY(-50%);
}

.delimiter-1sVwF:last-of-type {
	margin-bottom: 0;
}

div.drawerContainer-3JIbf {
	pointer-events: none;
}

div.drawerPaper-1WHKj {
	padding: 20px;

	box-shadow: none;

	pointer-events: all;
}

.sliderContainer-1A0m- {
	position: fixed;
	bottom: 0;
	left: 0;
	z-index: 1;

	width: 100vw;
}

.sliderContainer-1A0m- .slick-list {
	padding-left: 12px;
}

.sliderContainer-1A0m- .slick-track {
	display: flex;
	align-items: flex-end;
}

@media screen and (max-width: 600px) {
	div.searchAdornment-2U1S_ {
		display: none;
	}
}

div.inputWrapper-2n5J9 {
	width: 320px;
	margin-right: 12px;
}

.mobileFilters-VvRXD {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	width: 100%;
	max-width: 140px;
	margin-left: auto;
	margin-right: 15px;
	padding-right: 15px;

	border-right: 1px solid var(--line-separator);

	color: var(--base);
	font-weight: 400;
	font-size: 15px;
	line-height: 15px;
}

.mobileFiltersTrigger-1gRYm {
	display: flex;
	align-items: center;
}

li.mobileFiltersItem-Cf3Dd {
	display: flex;
	grid-gap: 8px;
	gap: 8px;
}

.rightWrapper-2jTOt {
	display: flex;
	align-items: center;
}

.lastUpdate-22Hug {
	margin: 0 30px;

	color: var(--text-light);
	font-size: 16px;
	line-height: 20px;
}

@media screen and (max-width: 600px) {
	div.inputWrapper-2n5J9 {
		width: 100%;
		margin-right: 0;
	}
}

.mobileHeaderContent-V8Mp9 {
	display: flex;
	justify-content: space-between;
	width: 100%;
	align-items: center;
}
