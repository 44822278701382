

.modal__content-ASi_P {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	padding: 28px;
}

.modal__content_insideSlideBottom-2KrxU {
	padding: 0;
}

.modal__text-26lUW {
	margin: 22px 0;

	font-size: 18px;
	font-weight: 500;
	line-height: 26px;
	text-align: center;
}

button.modal__button-2iETm {
	min-width: 200px;
}

.modal__button-2iETm:first-of-type {
	margin-bottom: 12px;
}

/* stylelint-disable */
.modal__button_decline-214Kp {
}
/* stylelint-enable */
