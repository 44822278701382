.container-2km-w {
	position: relative;

	padding: 40px 0;
}

.content-29cus {
	display: flex;
	flex-direction: column;
	grid-gap: 24px;
	gap: 24px;

	max-width: 1100px;
	margin: 0 auto;
}

.controls-3ryYY {
	z-index: 3;

	display: flex;
	align-items: center;
}

.flights-24HjZ {
	display: flex;
	flex-direction: column;
}

div.error-24Lsq {
	padding-top: 0;
	padding-bottom: 0;
	margin-top: 0;
}

.disclaimer-1UopH {
	grid-gap: 8px;
	gap: 8px;

	color: var(--text-pale);
}

.disclaimer__text-3nqP- {
	color: var(--text-light);
	font: var(--normal-mobile);
}

@media (max-width: 1024px) {
	.container-2km-w {
		padding: 40px 20px;
	}
}

@media screen and (max-width: 600px) {
	.container-2km-w {
		padding: 20px 12px 40px;
	}
}
