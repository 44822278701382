span.checkbox_checked-1uw_3.checkbox-yo6xj {
	color: var(--common-blue);
}

span.label-3VRid {
	font-family: inherit;
}

.rules-1FZSt {
	padding: 0 20px 20px;

	background-color: #ffffff;
}

div.tooltip-1mPI4 {
	z-index: 15;
}

@media screen and (max-width: 600px) {
	.rules-1FZSt {
		padding: 20px;
	}

	label.root-DD5zH {
		padding-left: 20px;
	}
}

.rules__label-2uAwD {
	padding: 12px;

	border-radius: 4px;

	background-color: #f0f0f0;
}

div.rules__tooltip-3sBe2 {
	z-index: 80;
}

.terms-1BduN {
	color: var(--common-blue);
}

.toolbarCheckbox__wrapper-3EYYa {
	font-family: inherit;
}
