.container-28UWM {
	position: relative;
}

.header-2Jz9F {
	display: flex;
	align-items: center;
	grid-gap: 10px;
	gap: 10px;

	padding: 0 34px;
	margin-bottom: 12px;

	color: var(--text-base);
	font-weight: 700;
	font-size: 21px;
	line-height: 140%;
}

.header__info-V_veG {
	flex: 1;
	display: flex;
	flex-direction: column;
}

.header__icon-24uQf {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 40%;
}

.header__icon-24uQf img {
	width: auto;
	height: 100%;
	object-fit: contain;
}

.subheader-1FQte {
	padding: 0 34px 24px;

	color: var(--text-base);
	font-size: 15px;
	line-height: 150%;

	white-space: break-spaces;
}

.subheader-1FQte > span:not(:last-child):after {
	content: ', ';
}

.content-1eosN {
	display: flex;
	flex-direction: column;

	padding: 0 34px;
	grid-gap: 10px;
	gap: 10px;
}

.switch__wrapper-1cbor {
	display: flex;
	justify-content: flex-end;
}

div.switch__container-2PlIp {
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 68px;
	margin: 0;

	border-top: 1px dashed var(--line-separator);
}

.segment-1Yjwy:not(:last-child) {
	margin-bottom: 20px;
}

.onlySegment__switch-3jp33 {
	opacity: 1;
}

div.switch__container-2PlIp:after,
div.onlySegment__switch-3jp33:after {
	background: var(--bg-elements);
}

div.switch__container-2PlIp:before,
div.onlySegment__switch-3jp33:before {
	background: var(--white);
	opacity: 1;
}

div.switch__container-2PlIp.switch__container_active-WCm7m:after,
div.onlySegment__switch-3jp33.switch__container_active-WCm7m:after {
	background: var(--brand-1-0);
}

div.switch__container-2PlIp.switch__container_disable-1pQz2:before,
div.onlySegment__switch-3jp33.switch__container_disable-1pQz2:before {
	background: var(--text-pale);
}

div.switch__container-2PlIp.switch__container_disable-1pQz2:after,
div.onlySegment__switch-3jp33.switch__container_disable-1pQz2:after {
	background: var(--bg-elements);
}

div.switch__container-2PlIp:last-child {
	border-bottom: 1px dashed var(--line-separator);
}

div.label-2_0va {
	opacity: 1;
}

.passenger__wrapper_counter-293tb {
	display: flex;
	justify-content: space-between;
}

.segment-1Yjwy .passenger__wrapper_counter-293tb {
	padding: 16px 0;
}

.passenger__wrapper_counter-293tb:not(:last-child) {
	margin-bottom: 16px;
}

.counter__root-2rSCN {
	margin-left: 10px;
	flex-shrink: 0;
}

button.counter-1dX0C {
	min-height: 24px;
	min-width: 24px;
	max-height: 24px;
	max-width: 24px;
}

.counter__value-2owRh {
	font-size: 15px;
	font-weight: 500;
	color: var(--gray80);
}

span.checkbox-2AAm9.checkbox_checked-3vnyq {
	color: var(--brand-1-0);
}

span.checkbox_label-1FCXG {
	color: var(--text-base);
	font-weight: 400;
	line-height: 1.3;
	text-transform: capitalize;
}

.passenger-9eSxJ {
	display: flex;
	align-items: center;

	font-size: 15px;
	color: var(--gray80);
	font-weight: 500;
	text-transform: capitalize;
}

.number-20zIP {
	flex-shrink: 0;
	width: 24px;
	height: 24px;
	margin-right: 14px;

	border-radius: 50%;

	color: var(--gray50);
	line-height: 24px;
	text-align: center;

	background: var(--gray05);
}

.direction-3ctod {
	height: 52px;
	padding: 0 10px;

	color: var(--gray80);
	font-weight: 700;
	font-size: 16px;
	line-height: 52px;

	background: #ebf4ff;
}

.controls-U6umy {
	padding: 30px 34px 40px;

	display: flex;
	justify-content: flex-end;
	align-items: center;
}

.footer-iKLGL {
	padding: 30px 34px 0;

	display: flex;
	flex-direction: column;
}

.onlySegment-1gJ30 {
	font-weight: 500;
	color: var(--base);
	font-size: 15px;

	opacity: 1;
}

button.cancel-2DWFC,
button.cancel-2DWFC:hover,
button.cancel-2DWFC:focus {
	margin-right: 12px;

	color: var(--gray70);
}

button.confirm-2rHVX {
	font-weight: 500;

	background: var(--base);
}

.total-39sh- {
	margin-top: 10px;
	margin-left: auto;

	color: var(--text-base);

	font-size: 20px;
	font-weight: 700;
}

.price_from-Cxr7J {
	font-size: 20px;
	font-weight: 500;
	line-height: 1.2;
}

@media screen and (max-width: 600px) {
	.container-28UWM {
		padding: 0;
	}

	.controls-U6umy {
		justify-content: space-between;
	}

	button.cancel-2DWFC,
	button.confirm-2rHVX {
		width: 49%;
	}

	.header__icon-24uQf img {
		width: 100%;
		height: auto;
	}
}
