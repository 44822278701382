.root-1oTfv {
	width: 660px;
	padding: 40px 40px 50px;
}

.title-3FiEB {
	font-size: 30px;
	font-weight: 500;
	color: var(--gray80);
}

.icon_wrapper-3x9A0 {
	position: relative;
	top: 4px;

	margin-right: 10px;
}

@media screen and (max-width: 600px) {
	.root-1oTfv {
		display: flex;
		flex-direction: column;

		width: 100%;
		min-height: 100vh;
		padding: 29px 28px;
	}

	.title-3FiEB {
		display: flex;
		flex-direction: column;

		margin-top: 133px;
	}

	.icon_wrapper-3x9A0 {
		top: 0;

		margin-bottom: 25px;
	}
}
