.stepTitle-1o67y {
	max-width: 550px;
	margin: 0 auto 38px;
}

.buttons-2AdTV {
	margin-top: 28px;
}

div.prices-DE8pW {
	margin-bottom: 12px;
}

div.wide-3qQ4f {
	max-width: 1100px;
}

button.backButton-2dMTM {
	position: relative;
}

.nasServicesNotice-I6q1S {
	text-align: left;
}

.warningModalContent-35bOx a {
	color: var(--text-link);
}

div.scrollBody-1sCIV div.paper-sCir- {
	border-radius: 4px;
}

@media screen and (max-width: 600px) {
	.wrapper-wzrIL .mobileStep-32P24:not(:first-of-type):not(.passed-1XunU) {
		margin-top: 8px;
	}

	div.withoutTopBorderRadius-2Yp-a {
		border-top-left-radius: 0;
		border-top-right-radius: 0;
	}

	div.withoutBottomBorderRadius-2q3bP {
		border-bottom-left-radius: 0;
		border-bottom-right-radius: 0;
	}

	div.withTopBorder-rrb8Y {
		border-top: 1px solid rgba(255, 255, 255, 0.3);
	}

	.reasonDescr-2h_vx {
		margin: 0;

		font-size: 14px;
		line-height: 19px;
		font-weight: 400;
	}

	div.prices-DE8pW {
		padding: 8px 12px 0;

		border-radius: 0 0 8px 8px;
	}
}
