.preferences-1N9hq {
	margin-top: 28px;
	padding: 20px;

	border-radius: 4px;

	background-color: #f9f9f9;
}

.title-2gx9X {
	margin: 0;

	font-size: 14px;
	color: var(--dark-gray);
}

.selects-2eCub {
	display: flex;
	justify-content: space-between;
	margin-top: 12px;
}

.selects-2eCub > div {
	flex: 1;
}

.selects-2eCub > div:first-child {
	margin-right: 10px;
}

.selects-2eCub > div:last-child {
	margin-left: 10px;
}

.selects-2eCub > div > div:after {
	border-bottom: 2px solid var(--common-blue);
}

.selects-2eCub .label-2nLAW.focused-ZxfGT {
	color: var(--common-blue);
}

@media screen and (max-width: 600px) {
	.selects-2eCub {
		flex-direction: column;
	}

	.selects-2eCub > div:first-child {
		margin-right: 0;
	}

	.selects-2eCub > div:last-child {
		margin-left: 0;
		margin-top: 10px;
	}
}
