.header-2MubE {
	padding: 16px;

	color: var(--text-base);
	font-weight: 700;
	font-size: 20px;
}

div.tabHeader-1en1E {
	font-size: 14px;
	text-transform: unset;
}

div.tabs-38vfl span.indicator-2BnfS {
	display: inline-block;
}

.downloadContainer-2ltFj {
	display: block;
	padding: 32px 16px;
}

.downloadContainer-2ltFj > div {
	width: 100%;
}

.buttons-3dQ4Q {
	display: flex;
	justify-content: flex-end;
}

button.button-3Y9pB,
button.button-3Y9pB:hover {
	background: var(--brand-3-0);
}

div.select-3ZmyZ {
	margin-bottom: 16px;
}

div.resend_wrapper-1JvEY {
	min-width: 0;
	width: 100%;
}

@media screen and (max-width: 600px) {
	div.slideUpWrapper-1R8a8 {
		z-index: 1000;
	}

	div.slideUp-3oEgQ {
		padding: 0;
	}

	div.slideUp__header-3ItnH {
		padding-left: 16px;
	}

	.sendTicketsContainer-2l21J,
	.downloadContainer-2ltFj {
		padding: 32px 16px;
	}

	.header-2MubE {
		padding: 16px 0;
	}
}
