

.withoutLabel-1YiMd input {
	padding-top: var(--input-underline-withoutLabel-pd);
	padding-bottom: var(--input-underline-withoutLabel-pd);
}

/* LabelIn / LabelOut */
.labelIn-2eu1r .label-qipNn.shrink-1-6Nj {
	transform: var(--label-labelIn-shrink);
}

.labelOut-1Ct-D .label-qipNn.shrink-1-6Nj {
	transform: var(--label-labelOut-shrink);
}

.labelIn-2eu1r .notchedOutline-2MGJ4,
.labelOut-1Ct-D .notchedOutline-2MGJ4 {
	top: 0;
}

.labelIn-2eu1r .notchedOutline-2MGJ4 legend,
.labelOut-1Ct-D .notchedOutline-2MGJ4 legend {
	display: none;
}

.labelOnLine-2LaUL label.label-qipNn.shrink-1-6Nj:before {
	position: absolute;
	top: -2px;
	right: -3px;
	bottom: -2px;
	left: -3px;
	z-index: -1;

	background: var(--white);
	content: '';
}

/* Label */
label.label-qipNn {
	font-size: 16px;
	font-weight: 400;
	color: var(--text-light);
}

label.label-qipNn.focused-ahW-N {
	color: var(--brand-1-0);
}

label.label-qipNn.disabled-3F_Tl {
	color: var(--text-light);
}

label.label-qipNn.error-2uolS {
	color: var(--text-light);
}

/* Input */
div.input-2DpqW,
div.input-2DpqW:hover {
	background: transparent;
}

.labelIn-2eu1r div.input-2DpqW {
	padding: 0;
}

div.input-2DpqW.focused-ahW-N {
	background: var(--brand-1-5);
}

div.input-2DpqW.disabled-3F_Tl {
	background: var(--bg-fill);
}

div.input-2DpqW.error-2uolS {
	background: var(--error-bg);
}

div.input-2DpqW.valid-Swvgf {
	background: var(--success-bg);
}

div.input-2DpqW input {
	font-size: 16px;
	font-weight: 400;
	color: var(--text-base);
}

div.labelIn-2eu1r:not(.withoutLabel-1YiMd) input,
div.labelIn-2eu1r:not(.withoutLabel-1YiMd) input[aria-autocomplete] {
	padding: var(--input-labelIn-pd);
}

div.input-2DpqW.focused-ahW-N input {
	color: var(--text-base);
}

div.input-2DpqW.disabled-3F_Tl input {
	color: var(--text-light);
}

div.input-2DpqW.error-2uolS input {
	color: var(--text-mid);
}

/* Outlined */
div.outlined-2InHu,
div.outlined-2InHu:hover {
	background: var(--brand-1-5);
}

div.outlined-2InHu.focused-ahW-N {
	background: transparent;
}

div.outlined-2InHu.disabled-3F_Tl {
	background: var(--bg-fill);
}

div.outlined-2InHu.error-2uolS {
	background: var(--error-bg);
}

div.outlined-2InHu.valid-Swvgf {
	background: var(--success-bg);
}

div.outlined-2InHu .notchedOutline-2MGJ4,
div.outlined-2InHu:hover .notchedOutline-2MGJ4 {
	border-color: var(--brand-1-3);
}

div.outlined-2InHu.focused-ahW-N .notchedOutline-2MGJ4 {
	border-color: var(--brand-1-1);
	border-width: 1px;
}

div.outlined-2InHu.disabled-3F_Tl .notchedOutline-2MGJ4 {
	border-color: var(--line-separator);
}

div.outlined-2InHu.error-2uolS .notchedOutline-2MGJ4 {
	border-color: var(--error-icon);
}

div.outlined-2InHu.valid-Swvgf .notchedOutline-2MGJ4 {
	border-color: var(--success-stroke-hover);
}

/* Underline */
.underline-SUTAs div.input-2DpqW {
	border-radius: var(--input-underline-brds);
}

.underline-SUTAs div.input-2DpqW:before,
.underline-SUTAs div.input-2DpqW:hover:before {
	border-color: var(--line-separator);
}

.underline-SUTAs div.input-2DpqW:after {
	display: none;
}

.underline-SUTAs div.input-2DpqW.focused-ahW-N:before {
	border-color: var(--brand-1-0);
}

.underline-SUTAs div.input-2DpqW.error-2uolS:before {
	border-color: var(--input-error);
}

.underline-SUTAs div.input-2DpqW.disabled-3F_Tl:before {
	border-color: transparent;
}

.underline-SUTAs div.input-2DpqW.valid-Swvgf:before {
	border-color: var(--success-stroke-hover);
}

/* Helper Text */
p.helperText-2M8pg {
	margin-top: 4px;

	color: var(--text-light);
	font-weight: 400;
	font-size: 13px;
	line-height: 1.1;
}

p.helperText-2M8pg.focused-ahW-N {
	color: var(--text-light);
}

p.helperText-2M8pg.error-2uolS {
	color: var(--error-icon);
}

p.helperText-2M8pg.disabled-3F_Tl {
	color: var(--text-light);
}

/* Valid Icon */
.valid_icon-3B8az {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-left: 4px;

	color: var(--seat-hover-choosed);
}
