

button.container-3jo7- {
	height: 48px;
	min-height: 48px;
	padding: 6px 16px;

	border-radius: 4px;

	background: var(--white);

	transition: background-color 0.3s, opacity 0.3s;
}

button.container-3jo7-:hover {
	background: var(--brand-1-0);
}

button.container-3jo7-.disabled-3z9BY {
	opacity: 0.6;
}

.wrapper-WHaGa {
	display: flex;
	align-items: center;
	width: 100%;
}

.icon-31hLt {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-right: 14px;

	color: var(--line-separator);
}

.content-2mYNM {
	display: flex;
	flex-direction: column;
}

.row-1FJZm {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.city-2KK59 {
	color: var(--text-link);
	text-transform: uppercase;
	font-size: 14px;
	font-weight: 700;
	line-height: 1.4;
}

.country-ra_H5 {
	color: var(--text-pale);
	font-size: 11px;
	font-weight: 400;
	line-height: 1.4;
}

.time-3NO-_ {
	color: var(--text-light);
	font-size: 13px;
	font-weight: 500;
	line-height: 1.4;
}

.distance-QchzU {
	color: var(--text-pale);
	font-size: 13px;
	font-weight: 400;
	line-height: 1.4;
}

button.container-3jo7-:hover .icon-31hLt {
	color: var(--brand-1-3);
}

button.container-3jo7-:hover .city-2KK59,
button.container-3jo7-:hover .country-ra_H5 {
	color: var(--white);
}
