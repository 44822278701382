div div.paper-3szab,
.header-1xQ_a,
.title-1-JhT,
.contentWrapper-2kOyq,
.footer-16kMl,
.buttons-3gWkH,
.closeButton-zL1h1,
.cancelButton-3XtD3,
.confirmButton-Myr-x,
.dialogContent_root-1fQYD {
	padding: 0;
}
