.form-28n_y {
	position: relative;

	display: grid;
	grid-gap: 12px 16px;
	grid-template-columns: 1fr 1fr;
	margin-bottom: 24px;
	padding-right: 46px;
}

.form__item-1ritx > div > div,
.form__item-1ritx input {
	font-size: 14px;
	font-weight: 500;

	font-family: inherit;
}

.form__item-1ritx > p {
	font-family: inherit;
}

.row__inputs-2YZ4b > div:first-child {
	margin-right: 33px;
}

.row__inputs-2YZ4b > div:last-child {
	max-width: 200px;
}

.form-28n_y form > div:last-child {
	margin-bottom: 0;
}

.form-28n_y form > div:first-child > div {
	font-family: inherit;
}

.form-28n_y .form__item-1ritx:after {
	border-bottom: 2px solid var(--common-blue);
}

.form-28n_y div.form__item-1ritx:before {
	border-bottom-color: #cdcdcd;
}

.row__inputs-2YZ4b {
	display: flex;
	flex-direction: row;
}

.remove-AeQBe {
	position: absolute;
	right: 0;
	top: 15px;

	cursor: pointer;
}

li.select__item-3Cn6D {
	font-size: 14px;
	font-weight: 500;
	font-family: inherit;
	text-transform: capitalize;
}

.footer-2JUFb {
	display: flex;
	flex-direction: row-reverse;
}

.form-28n_y .select-30qbT.select__label__focused-1wLne {
	color: var(--common-blue);
}

@media screen and (max-width: 600px) {
	.form-28n_y {
		display: flex;
		flex-direction: column;
		padding-right: 0;
	}

	.remove-AeQBe {
		position: relative;

		width: 100%;

		text-align: right;
		color: var(--error);
		font-size: 16px;
	}
}
