.flight-2hg59 {
	position: relative;

	margin-left: 40px;

	border: 1px solid var(--brand-1-3);

	border-radius: 4px;
}

.flight-2hg59:empty {
	border: 0;
}

@media screen and (max-width: 600px) {
	.flight-2hg59 {
		margin-left: 20px;
		display: flex;
		flex-direction: column;
		grid-gap: 20px;
		gap: 20px;
	}

	.flight-2hg59.transfer-YM_TJ {
		grid-gap: 0;
		gap: 0;
	}
}

.segment-13GNn {
	border-radius: 4px;
}

.segment-13GNn.transfer-YM_TJ.first-ooDJQ {
	border-bottom: 1px dashed var(--line-dot-line);
}

.segment-13GNn.transfer-YM_TJ.last-17uGt {
	border-top: 0;
}

.selected-3X6ZH {
	background: var(--brand-1-5);
}

.flight-2hg59.selected-3X6ZH {
	margin-left: 0;
	width: 100%;
}

.segment-13GNn,
.flightNumber-3S2sq {
	background: var(--gray);
}

.segment-13GNn.selected-3X6ZH,
.flightNumber-3S2sq.selected-3X6ZH {
	background: var(--brand-1-5);
}

.stopsInfo__stop-3TXlw {
	bottom: -16px;
}

.stopsInfo__label-2G-2K {
	border-radius: 20px;
}
