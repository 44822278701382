.loyalty_switch-1obLo {
	display: flex;
	align-items: center;
	grid-column: 1 / grid-end;

	color: var(--text-light);
	font-size: 16px;
}

.loyalty__desc-rOWLf {
	margin: 0;
	padding-left: 12px;
	margin-left: 12px;

	border-left: 1px solid var(--line-dot-line);
}

.loyalty__desc-rOWLf a {
	text-decoration: none;
}

.loyalty_switch_open-3CgbB {
	grid-column: 1 / 3;
}

.loyalty-1dLQC {
	grid-column: 3 / grid-end;
}

.container_loyalty-eyJMX {
	min-height: 56px;
}

div.container_loyalty-eyJMX.withIcons-3RtGk {
	grid-template-columns: 40px 1fr 2fr;
	align-items: center;
	padding-left: 0;
}

@media (max-width: 1024px) {
	div.container_loyalty-eyJMX {
		grid-column: 1;
		display: block;

		min-height: auto;
	}

	.loyalty_switch-1obLo {
		display: block;
	}

	.loyalty__desc-rOWLf {
		padding-left: 0;
		margin-left: 0;

		border-left: none;
	}
}
