

.month-21Ywu {
	display: inline-block;
	padding-right: 15px;
}

.month-21Ywu:last-child {
	padding-right: 0;
}

.week-WGw_o {
	display: flex;
}
