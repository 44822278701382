.container-1t7Q- {
	padding: 32px;
}

.header-1AIpq {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.header__text-2SIXk {
	font-size: 22px;
	font-weight: 700;
	color: var(--text-base);
}

button.closeButton-2b0QM {
	width: 28px;
	height: 28px;
	padding: 0;
}

.flight-YtvEK {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-top: 16px;
	padding: 12px;

	border-radius: 4px;

	color: var(--text-base);
	font-size: 16px;

	background: var(--brand-1-5);
}

.flight__direction-2gHdr {
	display: flex;
	flex: 1;
	align-items: center;

	font-weight: 700;
}

.flight__direction-2gHdr svg {
	flex-shrink: 0;
	margin-right: 12px;

	color: var(--text-pale);
}

.passenger-38gSx {
	display: flex;
	align-items: center;
	margin: 20px 0;

	color: var(--text-base);
	font-weight: 700;
	font-size: 16px;
}

.passenger-38gSx svg {
	flex-shrink: 0;
	margin-right: 12px;

	color: var(--text-pale);
}

button.closeButton-2b0QM svg {
	width: 16px;
	height: 16px;
}

.notification-15v2Y {
	border: none;
}

.notification__title-ODMxs {
	font-size: 16px;
	font-weight: 700;
	color: var(--error-text);
}

.buttons-8lb01 {
	width: 100%;
	margin-top: 16px;
	display: flex;
}

.buttons-8lb01 button:not(:last-child) {
	margin-right: 16px;
}

button.newSearch-2hczn,
button.newSearch-2hczn:hover {
	width: 100%;

	color: var(--brand-1-0);
	font-weight: 700;
	font-size: 14px;

	background: var(--brand-1-5);
}

button.continueButton-3w1S8,
button.continueButton-3w1S8:hover {
	width: 100%;

	color: var(--bg-white);
	font-weight: 700;
	font-size: 14px;

	background: var(--brand-3-0);
}

@media screen and (max-width: 600px) {
	.buttons-8lb01 {
		display: block;
	}

	.buttons-8lb01 button:not(:last-child) {
		margin-right: 0;
		margin-bottom: 16px;
	}
}
