

.wrapper-1eCIR {
	width: 100%;
	max-width: 351px;
	margin: 0 auto;
}

.wrapper-1eCIR:not(:last-child) {
	padding-bottom: 16px;
}

.wrapper__icon-1Uvoq {
	padding: 12px 12px 12px 40px;
}

.header-ojUKE {
	position: relative;

	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	margin-bottom: 6px;

	font-size: 14px;
}

.header-ojUKE > svg {
	position: absolute;
	top: 0;
	left: -2px;

	max-height: 17px;

	color: var(--cart-icon-color);
}

.header__title-4FU8H {
	flex: 1;
	display: flex;
	align-items: center;

	color: var(--text-base);
	font-weight: 700;
}

.unconfirmedServices-3u5uE .header-ojUKE {
	min-height: 24px;

	padding-right: 32px;
}

.header__icon-3GJi8 {
	position: absolute;
	top: 0;
	right: 0;

	width: 24px;
	height: 24px;

	display: flex;
	align-items: center;
	justify-content: center;
}

.unconfirmedServices-3u5uE .header__icon-3GJi8 {
	color: var(--error-icon);
}

.header-ojUKE > svg ~ .header__title-4FU8H {
	padding-left: 28px;
}

.header__direction-W5wVj {
	color: var(--text-base);
	font-weight: 700;
}

.notification-1laoc {
	margin: 16px 0 5px 0;

	color: var(--text-base);
	font-weight: 400;
	font-size: 14px;
	line-height: 1.3;
}

.unconfirmedServices-3u5uE .notification_unconfirmed-31YLk {
	color: var(--error-text);
}

.options-2aUie {
	display: flex;
	flex-direction: column;
}

.option-2N8a6 {
	position: relative;

	display: flex;
	align-items: center;
	grid-gap: 8px;
	gap: 8px;
}

.option-2N8a6:not(:last-child) {
	margin-bottom: 6px;
}

.option_requested-34wuR {
	opacity: 0.4;
}

.option_booked-HT-TX,
.option_included-AD35a {
	justify-content: space-between;
}

.option__name-1RiXo {
	max-width: 85%;

	color: var(--text-mid);
	font-size: 14px;
	font-weight: 400;
	line-height: 1.3;
}

.option__description-2nFJb {
	margin-left: auto;
}

.option_rejected-26JW- .option__name-1RiXo,
.option_canceled-_9wIW .option__name-1RiXo,
.option_problematic-2YtI- .option__name-1RiXo {
	color: var(--error-icon);
	font-weight: 700;
}

.option_icon-2qwyl .option__name-1RiXo {
	padding-left: 28px;
}

.option__price-1SaNl {
	display: flex;
	align-items: center;
	margin-left: auto;

	color: var(--text-base);
	font-size: 14px;
	font-weight: 400;
	line-height: 1.3;
}

.option__price_money-28RNR {
	display: flex;
	justify-content: center;
	align-items: center;
	grid-gap: 2px;
	gap: 2px;
}

.option__money-3VYn6 {
	padding-left: 0;
}

.option_rejected-26JW- .option__money-3VYn6,
.option_canceled-_9wIW .option__money-3VYn6,
.option_problematic-2YtI- .option__money-3VYn6 {
	display: none;
}

.option__price_icon-2XMwi {
	display: flex;
	align-items: center;
	justify-content: center;

	color: var(--error-icon);

	cursor: pointer;
}

.option__price_icon-2XMwi > svg {
	width: 16px;
	height: 16px;
}

.option__icon-21cNM {
	position: absolute;
	left: 4px;

	width: 20px;
	height: 20px;

	color: var(--cart-icon-color);
}

.option__icon-21cNM svg {
	width: 20px;
	height: 20px;
}

.option__controls-WLIDQ {
	display: flex;
	align-items: center;
	grid-gap: 6px;
	gap: 6px;
}

.option__download-2HECY,
.option__iconIncluded-1mPJS {
	display: flex;
	align-items: center;
	justify-content: center;

	width: 20px;
	height: 20px;

	color: var(--text-pale);
}

.option__download-2HECY > svg,
.option__iconIncluded-1mPJS > svg {
	width: 100%;
	height: 100%;
}

.unconfirmedServices-3u5uE .header-ojUKE > svg {
	top: 2px;
}
