.form-2XMwU {
	padding: 64px 20px 24px;
	min-height: 196px;
}

button.button-3rOyz {
	margin-right: 12px;
}

/* loading */
div.button-3rOyz {
	margin-right: 12px;

	background: var(--gray50);
}

/* stylelint-disable-next-line block-no-empty */
button.button_applied-2ICF1 {
}

button.button_notApplied-3S52v {
	background: var(--active30);
}

.button_notApplied-3S52v svg {
	position: relative;
	top: -1px;
}

/* stylelint-disable-next-line block-no-empty */
button.button_empty-2eDaq {
}

.statusMessage-30pXi {
	margin-top: 25px;
	display: flex;
	align-items: center;
}

.statusMessage_applied-4UgCt {
	color: var(--gray50);
}

.statusMessage_notApplied-2pJIK {
	color: var(--active);
}

.form_additionalFields-2AxLi {
	display: flex;
	flex-direction: column;
	margin-top: 20px;
}

/* stylelint-disable */
.statusText-H2wox {
}
/* stylelint-enable */

@media screen and (max-width: 600px) {
	.form-2XMwU {
		padding: 56px 20px 28px;
	}
}
