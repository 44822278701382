.members-3qsbq {
	display: flex;
	flex-direction: column;
	width: 100%;
}

.members__header-2aFM4 {
	display: flex;
	align-items: baseline;
	margin-bottom: 20px;
}

@media screen and (max-width: 600px) {
	.members__header-2aFM4 {
		margin-bottom: 10px;
	}
}

.members__subHeader-1qW27 {
	margin-bottom: 16px;

	font-size: 18px;
	font-weight: 400;
	line-height: 1.3;

	color: var(--text-light);
}

@media screen and (max-width: 600px) {
	.members__subHeader-1qW27 {
		display: none;
	}
}

.members__title-2G-0T {
	margin: 0;

	font-size: 24px;
	font-weight: 700;

	color: var(--text);
}

@media screen and (max-width: 600px) {
	.members__title-2G-0T {
		font-size: 22px;
	}
}

.members__counter-RrB9v {
	margin-left: 8px;

	font-size: 18px;
	font-weight: 400;

	color: var(--text-mid);
}

.members__items-18ERa {
	display: flex;
	flex-wrap: wrap;
	grid-gap: 20px;
	gap: 20px;
}

@media screen and (max-width: 600px) {
	.members__items-18ERa {
		justify-content: center;
		grid-gap: 12px;
		gap: 12px;
	}
}

.addMemberForm__actions-2pr1L {
	margin-top: 24px;

	display: flex;
	justify-content: flex-end;
}
