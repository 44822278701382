.container-1ogOE {
	position: relative;

	display: flex;
	align-items: center;
	grid-gap: 12px;
	gap: 12px;
}

button.prev-1vspG,
button.next-1kxZU {
	color: var(--text-mid);

	box-shadow: var(--shdw-chevron);
	background-color: var(--white);
}

button.prev-1vspG:hover,
button.next-1kxZU:hover {
	color: var(--white);

	box-shadow: var(--shdw-chevron-hover);
	background-color: var(--brand-1-0);
}

button.prev-1vspG:disabled,
button.next-1kxZU:disabled {
	color: var(--line-dot-line);

	cursor: default;
	background-color: var(--white);
}

button.selector-1loHx {
	color: var(--white);
	font-size: 16px;
	font-weight: 500;
}

.icon-1ouV7 {
	display: flex;
	align-items: center;
	justify-content: center;

	margin-right: 8px;
}

.weekpicker-htn6t {
	z-index: 10;

	position: absolute;
	top: 100%;
	left: 0;

	transform: translateY(10px);
}

@media screen and (max-width: 600px) {
	.container-1ogOE {
		width: 100%;
		justify-content: space-between;
	}

	.weekpicker-htn6t {
		z-index: 0;

		position: relative;
		top: 0;

		padding-top: 45px;

		transform: none;
	}

	div.scrollBody-cM5Tr div.paper-hQxZN {
		border-radius: 0;
	}
}
