/* stylelint-disable */
.monthHeader-1X_Oa {
	margin-bottom: 10px;

	font-size: 14px;
	text-align: center;
}

@media screen and (max-width: 600px) {
	.monthHeader-1X_Oa {
		font-size: 16px;
	}
}

/* stylelint-enable */
.monthHeader__select_month-3oY14 {
	padding-right: 8px;

	color: var(--dark-gray);
	font-weight: 600;
}

.monthHeader__select_year-34UNE {
	color: var(--light-gray);
	font-weight: 500;
}
