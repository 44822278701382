.results-3lBp5 {
	padding: 0 0 100px;
}

@media screen and (max-width: 600px) {
	div.results_withPromocodes-2YcJ6 {
		margin-top: 47px;

		border-radius: 12px 12px 0 0;
	}
}

button.toolbar__backButton-2IwrG {
	flex-shrink: 0;
	height: 48px;

	font-size: 15px;
	font-weight: 700;

	color: var(--order-backButton-color);
}

@media (min-width: 1025px) {
	.promoMessages-26dAp {
		margin-bottom: -6px;
	}
}

@media (max-width: 1024px) {
	.promoMessages__wrapper-i5LKt {
		margin-bottom: 20px;
	}
}

/* stylelint-disable block-no-empty */
button.selectedFare__button-31hfA {
}

button.selectFare__button-XdObm {
}

button.toolbar__nextButton-2pBrL {
}

/* stylelint-enable */
.toolbar-2-A2F {
	flex-grow: 1;
	display: flex;
	align-items: center;
}

.header-3NbBv {
	display: flex;
	flex-direction: column;

	box-shadow: 0 7px 20px rgba(0, 0, 0, 0.05);
}

.header__stepbar-s2459 {
	border-top: 1px solid var(--brand-1-1);
}

button.share-1f-Nx {
	position: relative;

	display: flex;
	height: auto;
	margin-bottom: 4px;
	padding: 12px 44px 12px 66px;

	font-size: 15px;
	line-height: 18px;
	white-space: normal;
	text-align: left;
}

.share-1f-Nx:before,
.share-1f-Nx:after {
	position: absolute;
	top: 50%;

	display: inline-block;

	transform: translate(0, -50%);
	content: '';
}

.share-1f-Nx:before {
	left: 15px;

	width: 33px;
	height: 30px;

	color: #ffffff;

	background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzYiIGhlaWdodD0iMzYiIHZpZXdCb3g9IjAgMCAzNiAzNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgb3BhY2l0eT0iMC42Ij4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik00LjUgM0gzMS41QzMzLjE1IDMgMzQuNSA0LjM1IDM0LjUgNlYyNEMzNC41IDI1LjY1IDMzLjE1IDI3IDMxLjUgMjdIMjFMMjQgMzEuNVYzM0gxMlYzMS41TDE1IDI3SDQuNUMyLjg1IDI3IDEuNSAyNS42NSAxLjUgMjRWNkMxLjUgNC4zNSAyLjg1IDMgNC41IDNaTTQuNSAyMUgzMS41VjZINC41VjIxWiIgZmlsbD0iI2ZmZmZmZiIvPgo8L2c+Cjwvc3ZnPgo=) center no-repeat;
}

.share-1f-Nx:after {
	right: 12px;

	width: 24px;
	height: 24px;

	background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iMTgiIHZpZXdCb3g9IjAgMCAxMiAxOCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KCTxwYXRoIGQ9Ik0yLjc3NDA1IDAuNjAwMDk4TDAuODAwMDQ5IDIuNTc0MUw3LjIxMjA1IDkuMDAwMUwwLjgwMDA0OSAxNS40MjYxTDIuNzc0MDUgMTcuNDAwMUwxMS4xNzQgOS4wMDAxTDIuNzc0MDUgMC42MDAwOThaIiBmaWxsPSJ3aGl0ZSIvPgo8L3N2Zz4K) center no-repeat;
	content: '';
}

.inner-14SjD {
	margin: 0 auto;
	padding-bottom: 80px;
}

@media (max-width: 1160px) and (min-width: 1025px) {
	div.inner-14SjD {
		padding: 0 30px;
	}

	div.header__stepbar-s2459 {
		padding: 0 20px;
	}
}

.outer-360NV {
	display: flex;
	margin: 0 auto;
	max-width: 1100px;
	justify-content: space-between;
}

.outer_bottom-1VpsM {
	display: flex;
	justify-content: space-between;
	max-width: 1100px;
	width: 100%;
	margin: 8px auto 0;
}

.messages-1EYoB {
	padding: 0 calc((100% - 1100px) / 2);
	margin-top: 20px;
	margin-bottom: 20px;
}

div.messages__htmlMessage-1_Ifn {
	width: 100%;
}

.messages-1EYoB > div:last-child {
	margin-bottom: 0;
}

.messages_item-u_MOK {
	margin-bottom: 20px;
}

.messages_item-u_MOK:last-child {
	margin-bottom: 0;
}

@media (max-width: 1024px) {
	.inner-14SjD {
		padding: 0 40px 80px;
	}

	.messages-1EYoB {
		margin-bottom: 20px;
	}
}

@media screen and (max-width: 600px) {
	.outer-360NV {
		display: none;
	}

	.messages-1EYoB {
		margin-top: 0;
	}

	button.toolbar__backButton-2IwrG {
		padding: 0 20px;
		justify-content: flex-start;
		min-width: auto;

		font-size: 17px;
		font-weight: 400;
	}

	button.toolbar__backButton-2IwrG,
	button.toolbar__backButton-2IwrG:hover {
		background: transparent;
	}

	.outer_bottom-1VpsM {
		margin-top: 16px;
		flex-direction: column;
	}

	.outer_bottom_miniMargin-hQzj8 {
		margin-top: 4px;
	}

	.results-3lBp5 {
		position: relative;

		padding: 0;

		background-color: var(--common-blue);
	}

	.results_hasErrors-1FWq2 {
		padding-bottom: 0;

		background-color: transparent !important;
	}

	.inner-14SjD {
		position: relative;

		z-index: 3;

		max-width: 100% !important;

		margin-top: 0;
		padding: 20px 20px 100px;

		border-radius: 8px 8px 0 0;

		background: #ededed;
	}

	.error-Eatkg {
		position: relative;

		top: -3px;
		z-index: 4;

		margin-top: 0 !important;
	}
}

@media screen and (max-width: 320px) {
	.inner-14SjD {
		padding: 12px 12px 100px;
	}
}
