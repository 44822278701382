.header__wrapper-Y01VM {
	display: flex;
	justify-content: space-between;
	margin-top: 32px;
	margin-bottom: 36px;
}

.header-3uvdM {
	display: flex;
	align-items: center;

	color: var(--gray80);
	font-weight: 600;
	font-size: 24px;
}

.header-3uvdM svg {
	margin: 0 10px;

	color: var(--base);
}

.header__cities-e6G8M {
	display: flex;
	align-items: center;
}

.leg-3APn0 {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 32px;
	height: 32px;
	margin-right: 14px;

	border-radius: 50%;

	color: #ffffff;
	font-weight: 600;
	font-size: 17px;

	background: #51a1ff;
}

.weekCalendar-e0YEf {
	margin-bottom: 24px;
}

.notices-3J0Oz {
	padding: 25px 0;
}

@media (max-width: 1024px) {
	.header-3uvdM {
		margin-bottom: 16px;

		color: var(--gray80);
		font-size: 20px;
	}

	.route-gUtuF {
		display: flex;
		justify-content: space-between;
		align-items: center;
		height: 50px;
		padding: 0 12px;

		border-bottom: 1px solid #ffffff;
		border-radius: 8px 8px 0 0;

		color: #ffffff;
		font-weight: 500;
		font-size: 15px;

		background: #2a7ad9;
	}

	.controls-UA2H1 {
		height: 50px;
		display: flex;
	}
}

.resultsError-2tnVn {
	display: block;
}
