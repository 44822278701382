.questionsAnswers-pgiLy {
	display: flex;
	flex-direction: column;
	width: 100%;
}

.questionsAnswers__title-8pzFG {
	margin-top: 0;
	margin-bottom: 20px;

	font-size: 24px;
	font-weight: 700;

	color: var(--text);
}

@media screen and (max-width: 600px) {
	.questionsAnswers__title-8pzFG {
		margin-bottom: 10px;

		font-size: 22px;
	}
}

.questionsAnswers__content-arVFN {
	display: flex;
	flex-direction: column;
	grid-gap: 16px;
	gap: 16px;
}
