

.flight__header-b1feR {
	display: flex;
}

.flightInfo-2VcDa {
	position: relative;

	flex-grow: 1;

	color: var(--results-flight-info-header-primary-color);
	overflow: hidden;
}

.flightInfo__inner-1yrTH {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 16px 20px;
}

.flightInfo-2VcDa:after {
	width: 100%;
}

.flightInfo__col-2oLHC {
	flex-basis: 100px;
}

.flightInfo__col-2oLHC:first-of-type {
	flex-basis: var(--results-flight-left-col-flex-basis);
}

.flightInfo__col-2oLHC.flightInfo__col_time-2PtxV.flightInfo__col_singleFare-2ZtKO {
	flex-basis: 300px;
}

.flightInfo__col-2oLHC:last-of-type {
	flex-basis: 170px;
}

.flightInfo__col__head-3S1Is {
	line-height: 25px;
	font-size: 20px;
	white-space: nowrap;
}

.flightInfo__col_head_duration-3vM6f {
	flex-basis: 100px;
}

.flightInfo__col__sub-xo6Ar {
	color: var(--results-flight-info-header-secondary-color);
	line-height: 21px;
	font-size: 14px;
	white-space: nowrap;
}

.badge-1dLT0 {
	position: absolute;
	top: 0;
	left: 20px;
}

.col-30OQ7 {
	cursor: pointer;

	transition: background-color 0.15s;
}

.col-30OQ7:after {
	position: absolute;
	bottom: 0;
	left: 0;

	width: 100%;
	height: 4px;

	opacity: 0;
	background-color: var(--results-flight-header-underline-background);

	transition: opacity 0.15s;

	pointer-events: none;
	content: '';
}

.col_active-20iwn:after,
.col-30OQ7:hover:after {
	opacity: 1;
}

.date-1rhg_ {
	position: relative;

	font-weight: 600;
	font-size: 22px;
}

.date-1rhg_.date_singleFare-3TDQp {
	font-size: 20px;
}

.dates__separator-1aXRb {
	margin: 0 5px;

	font-weight: 600;
	line-height: 28px;
	font-size: 22px;
}

.date__nextDayHint-3By1y {
	position: absolute;
	top: 0;

	color: var(--dark-red);
	font-weight: 600;
	font-size: 14px;
	line-height: 20px;
	text-align: right;

	transform: translate(3px, -25%);
}

.detailsLink-3osW0 {
	position: relative;

	display: inline;
}

.detailsLink-3osW0:after {
	position: absolute;
	top: 50%;
	right: 0;

	display: block;

	width: 10px;
	height: 20px;

	transform: translate(15px, -50%);
	background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAiIGhlaWdodD0iNSIgdmlld0JveD0iMCAwIDEwIDUiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+Cgk8cGF0aCBkPSJNMCAwTDUgNUwxMCAwTDAgMFoiIGZpbGw9IiNBNUE1QTUiLz4KPC9zdmc+Cg==) center no-repeat;

	transition: transform 0.15s;
	content: '';
}

.detailsLink_active-29sJ-:after {
	transform: translate(15px, -50%) rotate(-180deg);
}

.prices-2mBxN {
	display: flex;
}

.flightInfo__inner_singleFare-3iM4V {
	height: 100%;
}

.flightInfo__col_singleFare-2ZtKO {
	display: flex;
	align-items: flex-end;
}

.flightInfo__col_singleFare-2ZtKO > div:first-child {
	font-weight: 600;
	line-height: 25px;
}

.flightInfo__col_time-2PtxV > div:last-child {
	margin-left: 30px;
}

.flightInfo__col_duration-1JHJ6 {
	flex-basis: 200px;
}

.flightInfo__col_duration-1JHJ6 > div:last-child {
	margin-left: 12px;
}

.flightInfo__col_singleFare-2ZtKO.flightInfo__col_details-3pBxx {
	flex-basis: 300px;
	display: flex;
	justify-content: space-between;
	margin-right: 20px;
}

.flightInfo__col_singleFare-2ZtKO.flightInfo__col_details-3pBxx > div:last-child {
	flex: 1;
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-left: 12px;
}
