.main-3FGFM:before {
	opacity: 1;
}

.label-1H-XI {
	opacity: 1;
}

.primary-27K98.main-3FGFM:after {
	background: var(--gray20);
}

.primary-27K98.active-28L04:after {
	background: var(--base);
}

.secondary-37jvI.main-3FGFM:after {
	background: var(--gray90);
	opacity: 0.2;
}

.secondary-37jvI.active-28L04:after {
	background: var(--white);
	opacity: 1;
}

.secondary-37jvI.active-28L04:before {
	background: var(--base);
}

@media (max-width: 1024px) {
	.main-3FGFM {
		padding: 0;
	}
}
