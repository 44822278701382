.wrapper-7aT1f {
	display: flex;
	flex-direction: column;
	max-width: 1100px;
	margin: 0 auto;
}

.cards-DT-RA {
	display: flex;
	justify-content: space-between;
	margin-bottom: 20px;
}

.title-mFBt8 {
	margin: 28px 0 24px;

	font-weight: 500;
	font-size: 27px;
	line-height: 40px;
	color: var(--dark-gray);
}

.baggage__dialogContent-2MrQm {
	overflow: visible;
	overflow: initial;
}

.baggagePage__baggage-12eyv {
	min-height: 100%;
	height: auto;
}

.meal__dialogContent-1PgYw {
	min-height: 100%;
	height: auto;
	padding-bottom: 80px;

	background: #ededed;
}

.meal__dialogContent-1PgYw > div > div {
	border-radius: 0;
}

@media screen and (max-width: 600px) {
	.wrapper-7aT1f {
		background-color: #ffffff;
	}

	.cards-DT-RA {
		flex-wrap: wrap;
		margin-bottom: 0;
	}
}
