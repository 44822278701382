.Dialog-3Hozd {
	position: relative;

	display: flex;
	flex-direction: column;

	padding: 40px 48px 32px 48px;
}

[role='document'] > div {
	overflow-y: visible;
}

.title-NbN7R {
	height: 40px;

	margin: 0 0 18px;

	font-size: 28px;
	font-weight: 500;
	color: var(--dark-gray);
}

.text-2jt6N {
	margin-bottom: 40px;
}

.actions-3cTEn {
	display: flex;
	flex-direction: row-reverse;
}

.Dialog-3Hozd .actions-3cTEn button {
	background-color: var(--dark-red);
}

.Dialog-3Hozd .actions-3cTEn button:hover {
	background-color: var(--dark-red);
}

.closeButton-23sDQ {
	position: absolute;
	top: -32px;
	right: -32px;

	color: #ffffff;

	cursor: pointer;
}

.disabled-2FfO3 {
	opacity: 0.5;
}

.Dialog-3Hozd .actions-3cTEn button.close__slide__button-34urm {
	margin-right: 14px;

	color: var(--common-gray);

	background-color: #ededed;
}

@media screen and (max-width: 600px) {
	.Dialog-3Hozd {
		padding: 0;
	}

	.content-Z4WuA {
		display: none;
	}
}
