

.personalInfoSection-1XMVt {
	display: flex;
	flex-direction: row;
	grid-gap: 16px;
	gap: 16px;
	width: 100%;
}

.input-1hdaD {
	width: 100%;
}
