

div.segment-y1iHy {
	padding: 0 0 0 36px;
	min-height: 75px;
}

.multiple-3QAMw {
	min-height: 60px;
}

.flight_number-4Ap3p {
	display: flex;
	align-self: stretch;

	font-size: 15px;
	text-transform: uppercase;
}

.flight_number__inner-3iJwB {
	display: flex;
	align-items: center;

	color: var(--gray90);
	font-weight: 500;
	font-size: 15px;
}

div.amenities-3tUBj {
	justify-content: center;
	padding-right: 34px;
	padding-left: 19px;

	border: 1px dashed #cccccc;
	border-top: none;
	border-bottom: none;
}

.amenities-3tUBj > div {
	margin-bottom: 3px;

	color: var(--gray90);
	font-weight: 500;
}

div.plane-32_kb {
	display: none;
}

div.time-1q6qJ {
	color: var(--gray90) !important;
	line-height: 25px;
	font-size: 25px;
}

.duration-17q2r,
div.row_last-1JMof .duration-17q2r,
div.row_first-30c9m .duration-17q2r {
	color: var(--gray80);
	font-weight: 500;
}

.city-3wLix,
.route_flight-3OEs5 {
	display: none;
}

div.stop-hwwtR,
div.total_duration-15DM4 {
	bottom: 8px;
}
