

.contactsSection-1Pqfq {
	display: flex;
	flex-direction: row;
	grid-gap: 16px;
	gap: 16px;
	width: 100%;
}

.input-1aQDZ {
	width: 100%;
}

.contactsSection-1Pqfq div.phoneInput-1Yn5h > div > div > div {
	background: var(--brand-1-5);
}

.contactsSection-1Pqfq div.phoneInput-1Yn5h .phoneInputInput-apnzQ > div > input {
	border: 1px solid var(--brand-1-3);
}

.contactsSection-1Pqfq div.phoneInput-1Yn5h > div > div > label[class*='focus'] {
	color: var(--brand-1-0);
}

.contactsSection-1Pqfq div.phoneInput-1Yn5h > div > div > div[class*='focus'] > input {
	background: var(--white);
}
