.wrapper-3N0_a {
	display: flex;
}

button.button-2JU4C {
	height: 36px;
	width: 36px;

	display: flex;
	align-items: center;
	justify-content: center;

	border-radius: 50%;
}

button.button_square-3yLzw {
	flex-grow: 1;
	width: auto;
	height: 48px;

	border-radius: 4px;
}

button.button-2JU4C:not(:last-of-type) {
	margin-right: 12px;
}

@media screen and (max-width: 600px) {
	button.button-2JU4C {
		width: 44px;
		height: 44px;
	}

	button.button_square-3yLzw {
		flex-grow: 0;
		width: auto;
		height: 48px;

		border-radius: 4px;
	}

	button.button-2JU4C:not(:last-of-type) {
		margin-right: 16px;
	}

	button.button_square-3yLzw:not(:last-of-type) {
		margin-right: 14px;
	}
}

button.button_light-15oBE {
	color: var(--white);
}

button.button_google-3XwBS {
	background-color: var(--white);
}

button.button_facebook-22CeD {
	background-color: #3c5b96;
}
