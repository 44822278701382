.container-1Cgig {
	display: flex;

	grid-gap: 20px;

	gap: 20px;

	padding: 0 12px;
}

.label-fTEOk {
	flex: 0 1 50%;
	display: flex;
	align-items: center;

	color: var(--text-base);
	font-size: 16px;
	font-weight: 400;
	line-height: 1.3;
}

.controls-_n1uC {
	flex: 1;
	display: flex;
	align-items: center;

	grid-gap: 14px;

	gap: 14px;
}

.control-20L92 {
	flex: 1;
	display: flex;
	align-items: center;
	justify-content: center;
}

@media (max-width: 1024px) {
	.label-fTEOk {
		flex: 0 1 35%;
		min-width: 35%;
	}
}

@media screen and (max-width: 600px) {
	.container-1Cgig {
		grid-gap: 10px;
		gap: 10px;
	}

	.label-fTEOk {
		flex: 0 1 40%;
		min-width: 40%;
	}

	.controls-_n1uC {
		grid-gap: 8px;
		gap: 8px;
	}
}
