.voucher-3oi2Z {
	display: flex;
	flex: 0 0 32%;
	flex-direction: column;
	margin-bottom: 12px;

	max-width: 233px;

	border: 1px solid var(--common-green);
	border-radius: 5px;
}

.voucher-3oi2Z:nth-child(3n + 2) {
	margin: 0 2% 12px;
}

.header-ZjUIq {
	display: flex;
	justify-content: space-between;
	padding: 12px 14px;

	border-bottom: 1px dashed var(--common-green);

	background-color: rgba(85, 213, 144, 0.2);
}

.info-3-aSs {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 12px;
}

.title-3ckC9 {
	display: flex;
	align-items: center;
	height: 17px;
	min-width: 100px;

	color: var(--common-blue);
	font-weight: 500;
	font-size: 12px;
}

.number-1D5Dt {
	display: flex;
	align-items: center;
	height: 17px;

	font-weight: 500;
	font-size: 14px;
	color: var(--dark-gray);
}

.value-qWfn_ {
	display: flex;
	align-items: center;
	height: 20px;

	font-weight: 500;
	font-size: 19px;
	color: var(--dark-gray);
}

.expires-3ZvCP {
	display: flex;
	align-items: center;
	height: 20px;

	font-size: 12px;
	color: var(--common-gray);
}

.expired-lhSwT.voucher-3oi2Z {
	border-color: #d9d9d9;
}

.expired-lhSwT .header-ZjUIq {
	border-color: #d9d9d9;

	background-color: #ededed;
}

.expired-lhSwT .title-3ckC9,
.number-1D5Dt,
.expires-3ZvCP,
.value-qWfn_ {
	color: #9a9a9a;
}

@media screen and (max-width: 600px) {
	.voucher-3oi2Z {
		flex: 1;
	}
}
