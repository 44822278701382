.fareGroup-350uB {
	position: relative;

	display: flex;
	flex-shrink: 0;
	flex-direction: column;
	justify-content: flex-start;
	min-width: 256px;
	max-width: 380px;
	padding: 22px 16px 16px 16px;
	overflow: visible;

	border-radius: 4px;
}

.fareGroup_unavailable-1gKJ7 {
	opacity: 0.6;
	cursor: not-allowed;
}

.fareGroup_recommended-3Ouzs {
	border: none;
}

.fareGroup_Economy-3fZIC {
	background: var(--results-faregroup-economy);
}

.fareGroup_Business-275BZ {
	background: var(--results-faregroup-business);
}

.fareGroup_white-1pjqK {
	border-radius: 8px;

	background-color: var(--white);
}

.title-1TdkP {
	color: var(--results-faregroup-economy-title);
	font-size: 21px;
	line-height: 28px;
	font-weight: 600;
	white-space: nowrap;
}

.fareGroup_white-1pjqK .title-1TdkP {
	font-size: 19px;
	color: var(--gray80);
}

.info-29GVH {
	position: absolute;

	display: inline-flex;
	margin-left: 5px;

	cursor: pointer;
}

.fareGroup_white-1pjqK .info-29GVH {
	color: var(--common-blue);
}

@media screen and (max-width: 600px) {
	.fareGroup_white-1pjqK .info-29GVH svg {
		color: var(--gray80);
	}
}

.keyFeatures-3PByQ {
	margin: 12px 0 16px;
	min-height: 60px;

	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr;

	grid-gap: 4px;

	gap: 4px;
}

.fareGroup_white-1pjqK .keyFeatures-3PByQ {
	margin-bottom: 0;
	margin-top: 7px;
}

.keyFeature-26z25 {
	position: relative;

	min-height: 68px;

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	border-radius: 2px;

	cursor: help;

	background-color: rgba(255, 255, 255, 0.3);
}

.fareGroup_white-1pjqK .keyFeature-26z25 {
	background-color: var(--base);
}

.fareGroup_white-1pjqK.fareGroup_Business-275BZ .keyFeature-26z25 {
	background: #61579f;
}

.fareGroup-350uB .chevron {
	position: relative;
	top: 8px;
	left: -4px;

	color: var(--base);

	transform: scale(0.9);
}

.keyFeature_unavailable-Gc7z5 {
	opacity: 0.6;
}

.fareGroup_white-1pjqK .keyFeature_unavailable-Gc7z5 {
	background: var(--gray30);
	opacity: 1;
}

.keyFeature_paid-3BoLe {
	opacity: 1;
}

.keyFeature__icon-1PzFQ {
	margin-bottom: 16px;

	color: var(--white);

	transform: scale(0.9);
}

.keyFeature__icon_external-2gnjw {
	max-width: 33px;
	max-height: 33px;
	object-fit: contain;
}

.keyFeature__value-2g_cI {
	position: absolute;
	bottom: 0;

	display: flex;
	align-items: center;
	margin-bottom: 5px;

	color: #ffffff;
	font-weight: 600;
	font-size: 11px;
	line-height: 16px;
}

.keyFeature__value-2g_cI svg {
	transform: scale(0.9);
}

.fareGroup_white-1pjqK > svg {
	color: var(--white);
}

.fareGroup_white-1pjqK .keyFeature_paid-3BoLe {
	color: var(--gray30);

	background: #f0f0f0;
}

.fareGroup_white-1pjqK .keyFeature__icon-1PzFQ {
	color: var(--white);
}

.fareGroup_white-1pjqK .keyFeature__value-2g_cI {
	color: var(--white);
}

.fareGroup_white-1pjqK .keyFeature_paid-3BoLe .keyFeature__icon-1PzFQ,
.fareGroup_white-1pjqK .keyFeature_paid-3BoLe .keyFeature__value-2g_cI {
	color: var(--gray30);
}

.options-25l4P {
	margin: 0 -20px;
	padding: 16px 20px 0;

	border-top: 1px dashed rgba(255, 255, 255, 0.5);
}

.fareGroup_white-1pjqK .options-25l4P {
	padding-top: 0;

	border-top: none;
}

.option-ms5Rl {
	display: flex;
	align-items: center;

	line-height: 20px;

	list-style-type: none;
}

.fareGroup_white-1pjqK .option-ms5Rl {
	margin: 0;
	padding: 13px 0;

	color: var(--gray70);
}

.option-ms5Rl:not(:first-of-type) {
	margin-top: 3px;
}

.fareGroup_white-1pjqK .option-ms5Rl:not(:first-of-type) {
	margin: 0;
}

.fareGroup_white-1pjqK .option-ms5Rl:not(:last-of-type) {
	border-bottom: 1px solid #e4e4e4;
}

.miles_notification-1M7pz {
	margin-top: 12px;
}

div.miles_notification-1M7pz svg {
	color: var(--gray50);
}

.option_free-2yINz {
	opacity: 1;
}

.option__title-3O8m7 {
	color: var(--results-faregroup-option-color);
	font-size: 13px;
	white-space: nowrap;
}

.fareGroup_white-1pjqK .option__title-3O8m7 {
	font-size: 14px;
	color: var(--base);
}

.option__value-i2UHf {
	display: flex;
	align-items: center;
	margin-left: auto;

	color: var(--results-faregroup-option-color);
	font-weight: 500;
	font-size: 13px;
	text-align: right;

	cursor: help;
}

.fareGroup_white-1pjqK .option__icon-39F3q,
.fareGroup_white-1pjqK .option__value-i2UHf {
	color: var(--base);
}

.fareGroup_white-1pjqK .option_unavailable-15kIg .option__title-3O8m7,
.fareGroup_white-1pjqK .option_unavailable-15kIg .option__icon-39F3q,
.fareGroup_white-1pjqK .option_unavailable-15kIg .option__value-i2UHf {
	color: var(--gray50);
}

.option__tooltip-25kZo {
	position: relative;
	top: -5px;
}

.upgradeOffer-3k9bm {
	padding: 8px 12px;

	border-radius: 2px;

	color: var(--gray70);
	font-size: 14px;
	line-height: 18px;

	cursor: pointer;

	background: var(--success90);
}

.upgradeOffer__link-2TLZw {
	display: block;
	margin-top: 5px;

	color: var(--base);
	font-weight: 600;
	line-height: 14px;
	text-decoration: none;
}

div.dialog__header-3hmdv {
	height: 55px;
	padding-left: 0;

	font-size: 20px;
	font-weight: 500;
	line-height: 150%;
}

.dialog__header-3hmdv > div > div {
	display: flex !important;
	align-items: center;
}

div.dialog__content-1QN14 {
	margin-top: 55px;
}

.taxes-3MoXn {
	display: flex;
	justify-content: flex-end;
	margin-top: 12px;

	color: var(--gray50);
	font-size: 14px;
	line-height: 1.3;
}

/* stylelint-disable block-no-empty */
button.button-rrK1o {
	margin-top: auto;
}

.upgradeOptionWrapper-1h8xt {
}

@media only screen and (max-width: 360px) {
	.fareGroup-350uB {
		width: 278px;
	}

	.fareGroup__keyFeature-1EUm0 {
		width: 80px;
	}
}
