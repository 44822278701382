

.wrapper-1x_FD {
	padding: 30px;
}

.title-3W6eJ {
	margin: 0;

	font-size: 28px;
	line-height: 35px;
}

.sub-1gMYm {
	margin-top: 15px;
	padding-right: 30px;

	color: var(--light-gray);
	font-size: 18px;
	line-height: 130%;
}

.social-1Clar {
	margin-top: 35px;
}

.email-36va4 {
	margin-top: 20px;
	margin-bottom: 0;

	color: var(--link-color);
	font-size: 15px;
	line-height: 22px;

	cursor: pointer;
}

button.button-oBaoZ {
	margin-top: 40px;
	height: 64px;
	width: 100%;
}
