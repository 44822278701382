.wrapper-28BZn {
	position: relative;

	padding-bottom: 30px;

	border-radius: 12px;

	background: var(--white);
}

button.tabs__tab-2dneB.tabs__selectedTab-3KlAx {
	border-bottom: 3px solid var(--base);
}

button.tabs__tab-2dneB {
	max-width: none;

	border-bottom: 3px solid transparent;

	opacity: inherit;

	transition: 0.2s;
}

button.tabs__tab-2dneB .tabs__tab__label-28sqQ {
	margin-bottom: 6px;
}

button.tabs__tab-2dneB .tabs__tab__header-fVnAL {
	font-weight: 700;
	font-size: 22px;
	text-transform: none;
	text-transform: initial;
	line-height: normal;
}

button.tabs__tab-2dneB:not(.tabs__selectedTab-3KlAx) .tabs__tab__header-fVnAL {
	color: var(--text-base);
}

div.stickyTabs-2uZWn {
	top: 0;
	right: 0;
	left: 0;
	z-index: 4;

	padding-top: 12px;

	box-shadow: 4px 4px 29px rgba(0, 0, 0, 0.09);
	background: #ffffff;
}

.tabs__container-24uXq {
	padding: 0 24px;
}

div.stickyTabs-2uZWn .tabs__container-24uXq {
	margin: auto;
	max-width: 1100px;

	border-bottom: none;
}

@media screen and (max-width: 600px) {
	.wrapper-28BZn {
		padding-bottom: 0;

		border-radius: 0;

		background: none;
	}

	.tabs__container-24uXq {
		padding: 0;
	}

	.tabs__tab-2dneB {
		margin-left: -4px;
	}

	button.tabs__tab-2dneB span {
		margin-bottom: -22px;
	}

	button.tabs__tab-2dneB .tabs__tab__header-fVnAL {
		font-size: 16px;
	}

	div.flexTabsContainer-3Y9kh:after {
		content: '';

		min-width: 1px;
		height: 1px;
		display: block;
	}
}
