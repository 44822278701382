

.container-2QX1W {
	display: flex;
	grid-gap: 20px;
	gap: 20px;
}

.notifications-23ESK {
	max-width: 1100px;

	margin: 0 auto;
}

.selectedServices--5fMQ {
	display: grid;
	grid-gap: 5px;
	grid-template: repeat(auto-fill, minmax(270px, 1fr)) / repeat(auto-fill, minmax(300px, 1fr));

	margin-bottom: 12px;
}
