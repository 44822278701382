

.container-85O0W {
	margin-top: 40px;
}

.timelimit-3ZcX1 {
	display: flex;
	align-items: center;
	margin-bottom: 20px;
	padding: 15px;

	border: 1px solid var(--error60);
	border-radius: 4px;

	color: var(--error-20);
	font-weight: 500;
	font-size: 15px;

	background: var(--error90);
}

.timelimit-3ZcX1 svg {
	margin-right: 14px;
	flex-shrink: 0;
}
