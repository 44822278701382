.list_reset-332qH {
	margin: 0;
	padding: 0;

	list-style-type: none;
}

button.seat-4Ec3j {
	position: relative;

	z-index: 1;

	flex: 1;

	box-sizing: content-box !important;
	min-width: 29px;
	max-width: 47px;
	height: 44px;

	margin-right: 8px;

	border-right: 2px;

	border-radius: 4px;

	color: #ffffff;

	background: var(--seat-standart);
}

/* unavailable */
button.seat-4Ec3j.seat_unavailable-1Pkr8 {
	background: var(--seat-busy);

	pointer-events: none;
}

.seat-4Ec3j.seat_unexistent-gLthW {
	max-width: 48px;

	opacity: 0;

	pointer-events: none;
}

/* regular */
.seat-4Ec3j {
	background: var(--seat-standart);
}

.seat-4Ec3j.seat_recommend-3RMZz {
	z-index: 1002;

	background: var(--selected);
}

.seat-4Ec3j.seat_recommend-3RMZz.seat_recommendCurrent-3htRe {
	z-index: 1002;

	background: var(--selected2);
}

.seat-4Ec3j.seat_free-2SsAz {
	background: var(--seat-free);
}

/* business */
button.seat_business-2Ymjx {
	background: var(--seat-business);
}

button.seat_preferred-2SDIw {
	background: var(--seat-extra);
}

button.seat-4Ec3j.seat_occupied-3JpHn,
button.seat_preferred-2SDIw.seat_occupied-3JpHn,
button.seat_preferred-2SDIw.seat_occupied-3JpHn.seat_unavailable-1Pkr8,
button.seat_business-2Ymjx.seat_occupied-3JpHn,
button.seat_business-2Ymjx.seat_occupied-3JpHn.seat_unavailable-1Pkr8 {
	background: var(--seat-occupaied);
}

.seat-4Ec3j:hover,
.seat_business-2Ymjx:hover {
	background: var(--seat-hover);
}

.seat-4Ec3j.seat_customColor-3BtDR:hover {
	background: var(--seat-hover) !important;
}

.seat-4Ec3j.seat_occupied-3JpHn:hover {
	background: var(--seat-occupaied-hover);
}

.seat-4Ec3j.seat_customColor-3BtDR.seat_occupied-3JpHn,
.seat-4Ec3j.seat_customColor-3BtDR.seat_occupied-3JpHn:hover {
	background: var(--seat-occupaied-hover) !important;
}

.span-2k5nZ.ripple-2eTBj {
	background-color: #ffffff;
}

.seat-4Ec3j:last-child {
	margin-right: 0;
}

.number-1N-Qb {
	position: absolute;
	left: 50%;

	font-weight: 500;
	font-size: 14px;
	line-height: 14px;

	transform: translate(-50%, 0);

	transition: opacity 0.15s;
}

.seat-4Ec3j .number-1N-Qb {
	color: var(--gray70);
}

.seat_occupied-3JpHn .number-1N-Qb {
	color: #ffffff;
	font-size: 22px;
	font-weight: 700;
}

.seat_occupied-3JpHn .number-1N-Qb.number_name-1ld4P {
	font-size: 14px;
	font-weight: 500;
}

.seat-4Ec3j.seat_recommend-3RMZz .number-1N-Qb {
	color: #ffffff;
}

button.seat-4Ec3j.seat_combinedLeft-nDC8G .number-1N-Qb,
button.seat-4Ec3j.seat_combinedRight-1YFo7 .number-1N-Qb {
	color: var(--white);
}

.seat_occupied-3JpHn.seat-4Ec3j:hover .number-1N-Qb {
	opacity: 0;
}

.seat-4Ec3j:not(.seat_unexistent-gLthW):not(.seat_unavailable-1Pkr8):not(.seat_occupied-3JpHn):hover .number-1N-Qb {
	color: #ffffff;
}

.seat_occupied-3JpHn.seat-4Ec3j:not(.seat_confirmed-iqb50):hover:after {
	position: absolute;
	top: -7px;
	right: 0;
	bottom: 0;
	left: 0;

	width: 16px;
	height: 16px;

	margin: auto;

	background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTciIHZpZXdCb3g9IjAgMCAxNiAxNyIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEyLjY2NjMgNC43NzMyNUwxMS43MjYzIDMuODMzMjVMNy45OTk2NyA3LjU1OTkyTDQuMjczMDEgMy44MzMyNUwzLjMzMzAxIDQuNzczMjVMNy4wNTk2NyA4LjQ5OTkyTDMuMzMzMDEgMTIuMjI2Nkw0LjI3MzAxIDEzLjE2NjZMNy45OTk2NyA5LjQzOTkyTDExLjcyNjMgMTMuMTY2NkwxMi42NjYzIDEyLjIyNjZMOC45Mzk2NyA4LjQ5OTkyTDEyLjY2NjMgNC43NzMyNVoiIGZpbGw9IndoaXRlIi8+Cjwvc3ZnPgo=) center no-repeat;
	content: '';
}

.male-kpYmA.seat-4Ec3j.seat_unavailable-1Pkr8:not(.seat_unexistent-gLthW):after,
.female-DIf-y.seat-4Ec3j.seat_unavailable-1Pkr8:not(.seat_unexistent-gLthW):after {
	position: absolute;

	top: 0;
	right: 0;
	bottom: 0;
	left: 0;

	width: 24px;
	height: 24px;
	margin: auto;

	background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjUiIHZpZXdCb3g9IjAgMCAyNCAyNSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTExLjk5OTcgMTkuMzU3MUMxNS43ODk5IDE5LjM1NzEgMTguODU2OSAxNi4yODY4IDE4Ljg1NjkgMTIuNUMxOC44NTY5IDguNzEzMTYgMTUuNzg5OSA1LjY0MjgyIDExLjk5OTcgNS42NDI4MkM4LjIxMjg2IDUuNjQyODIgNS4xNDI1OCA4LjcxMzE2IDUuMTQyNTggMTIuNUM1LjE0MjU4IDE2LjI4NjggOC4yMTI5MiAxOS4zNTcxIDExLjk5OTcgMTkuMzU3MVpNMTAuMjg1NCA5LjA3MTM5QzExLjI3MTUgMTAuMDU5OSAxMS45OTk3IDEyLjUgMTcuMTQyNiAxMi41QzE3LjE0MjYgMTUuMzM1OSAxNC44MzU3IDE3LjY0MjggMTEuOTk5NyAxNy42NDI4QzkuMTYzODEgMTcuNjQyOCA2Ljg1Njg2IDE1LjMzNTkgNi44NTY4NiAxMi41QzYuODU2ODYgMTIuNSA4Ljg1MjQgMTEuMjkyOSAxMC4yODU0IDkuMDcxMzlaIiBmaWxsPSIjQTVBNUE1Ii8+Cjwvc3ZnPgo=) center no-repeat;
	content: '';
}

.female-DIf-y.seat-4Ec3j.seat_unavailable-1Pkr8:not(.seat_unexistent-gLthW):after {
	background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjUiIHZpZXdCb3g9IjAgMCAyNCAyNSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTYuODU2NzYgMTkuMzU3MUgxNy4xNDI3QzE4LjA5MDEgMTkuMzU3MSAxOC44NTY3IDE4LjU4OTUgMTguODU2NyAxNy42NDI5VjEyLjVDMTguODU2NyA4LjcxMzIgMTUuNzg2NyA1LjY0MjgyIDExLjk5OTcgNS42NDI4MkM4LjIxMjg0IDUuNjQyODIgNS4xNDI1OCA4LjcxMzIgNS4xNDI1OCAxMi41VjE3LjY0MjlDNS4xNDI1OCAxOC41ODk1IDUuOTA5MjggMTkuMzU3MSA2Ljg1Njc2IDE5LjM1NzFaTTExLjk5OTcgNy4zNTcxOEMxMS45OTk3IDcuMzU3MTggMTEuOTk5NyAxMi41NTM2IDE3LjE0MjYgMTIuNTAwMUMxNy4xNDI2IDE1LjM0MDIgMTQuODM5MiAxNy42NDMgMTEuOTk5NyAxNy42NDNDOS4xNjAzMiAxNy42NDMgNi44NTY3NiAxNS4zNDAyIDYuODU2NzYgMTIuNTAwMUM2Ljg1Njc2IDEyLjUgMTEuOTk5NyAxMi41NTM2IDExLjk5OTcgNy4zNTcxOFoiIGZpbGw9IiNBNUE1QTUiLz4KPC9zdmc+Cg==);
}

button.seat-4Ec3j.seat_combinedLeft-nDC8G {
	padding-left: 4px;

	border-top-left-radius: 0;
	border-bottom-left-radius: 0;

	background: var(--seat-hover);
}

button.seat-4Ec3j.seat_combinedRight-1YFo7 {
	margin-right: 0;
	padding-right: 4px;

	border-top-right-radius: 0;
	border-bottom-right-radius: 0;

	background: var(--seat-hover);
}

button.seat_small-2GaP4 .number-1N-Qb span {
	display: block;
}

button.seat_small-2GaP4 .number-1N-Qb span:last-child {
	margin-top: 5px;
}

button.seat-4Ec3j.seat_small-2GaP4:not(.seat_business-2Ymjx) {
	max-width: 30px;
}

@media (min-width: 1024px) {
	button.seat-4Ec3j.seat_standart-3xC_G:not(.seat_small-2GaP4) {
		max-width: 48px;
	}
}

@media only screen and (max-width: 420px) {
	button.seat_small-2GaP4 {
		margin: 1px;

		width: 25px;
		min-width: 25px;
	}
}
