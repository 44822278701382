.documentFieldsItem-3kB-- {
	display: flex;
	flex-direction: row;
	grid-gap: 16px;
	gap: 16px;
}

.documentFieldsItem__remove-2Ib_7 {
	margin-top: 12px;

	cursor: pointer;
}

button.buttonRemove-2TPxK {
	display: flex;
	align-items: center;
	flex-shrink: 0;
	justify-content: center;
	width: 30px;
	height: 30px;

	border-radius: 50%;
}

.input-1wBzB {
	width: 100%;
}

.dropdown-2q5dW {
	height: 56px;
}

.documentFieldsItem__controls-1qDoK {
	display: flex;
	align-items: flex-start;
}

@media screen and (max-width: 600px) {
	.documentFieldsItem-3kB-- {
		flex-direction: column;
	}

	.documentFieldsItem__controls-1qDoK {
		justify-content: flex-end;
	}

	.documentFieldsItem__remove-2Ib_7 {
		color: var(--error-text);
	}
}
