.title-1nNR2 {
	margin-bottom: 16px;

	color: var(--gray70);
	font-weight: 700;
	font-size: 22px;
	line-height: 1.3;
}

.title-1nNR2 > span {
	display: none;
}

.container-23LHP {
	display: flex;
	flex-wrap: wrap;
}

div.fare-30CRG {
	border-radius: 4px;

	box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.04);
}

.rules-3L1Ig {
	display: flex;
	justify-content: space-between;
	margin-top: 12px;
}

/* stylelint-disable */
.button__rules-2t5R6 {
}
/* stylelint-enable */
.options-13ccG {
	margin: 6px 0;
	padding: 0;
}

div.keyFeature-j1FgM {
	padding: 8px 4px;
	justify-content: space-between;
	align-items: center;
}

div.keyFeature-j1FgM span {
	position: relative;

	text-align: center;
	font-size: 14px;
	font-weight: 400;
}

.fare-30CRG .option-38qbM {
	display: flex;
	align-items: center;
	padding: 12px 0;

	font-size: 14px;
	line-height: 1.3;
}

.fare-30CRG .option-38qbM:not(:last-of-type) {
	border-bottom: 1px dashed #d9d9d9;
}

.fare-30CRG .option-38qbM > span {
	color: var(--gray50);
}

div.keyFeature-j1FgM span:first-child {
	margin-bottom: 6px;
	display: flex;
	align-items: center;
	justify-content: center;
	flex: 1;
}

.fareConditions__closeButton-1IUjn {
	z-index: 11;
}
/* stylelint-disable */
button.button-2T_QX {
}
/* stylelint-enable */

@media screen and (max-width: 600px) {
	div.fare-30CRG {
		width: 100%;
	}

	.rules-3L1Ig {
		flex-direction: column;
	}
}
