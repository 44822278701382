

button.button-2IGP1 {
	margin-left: 8px;

	border-color: transparent;
}

.button_loading-l14QV {
	margin-top: 7px;
	margin-bottom: 7px;
}

.root-3cC5y {
	color: #b3b3b3;

	background: var(--warningBG);
}

/* stylelint-disable */
.button_checkin-37_WR {
}
