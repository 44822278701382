.title-1iP6a {
	margin-top: 0;
	margin-bottom: 20px;

	font-size: 24px;
}

.list-4eqNt {
	display: flex;
	flex-direction: column;
	grid-gap: 12px;
	gap: 12px;
}

button.btn-1fwzv {
	display: flex;
	grid-gap: 10px;
	gap: 10px;
	align-items: center;

	font-weight: 500;
}

button.btn_back-hXnGu {
	color: var(--text-light);
}

button.btn_back-hXnGu svg {
	width: 24px;
	height: 24px;
}

div.btn__loader-3sbT_,
button.btn_read-JKnEf svg {
	width: 22px;
	height: 22px;
}

.btn__loader-3sbT_ > div {
	width: auto;
	height: auto;
	margin-right: 0;
}

.btn__loader-3sbT_ .btn__loaderSpinner-1AfX3 {
	width: 20px;
	height: 20px;

	background-size: 20px;
}

.empty-39Eb5 {
	color: var(--text-light);
}

@media screen and (max-width: 600px) {
	.title-1iP6a {
		font-size: 18px;
	}
}
