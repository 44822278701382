.content-3jYLp {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.title-2h74T {
	text-align: center;
}

.text-2LHX0 {
	display: flex;
	margin-bottom: 20px;

	font: var(--medium-desktop);
	text-align: center;

	color: var(--text-base);
}

.text-2LHX0.linked-24tsx {
	flex-direction: column;
	align-items: center;

	font-size: 16px;
	font-weight: 400;
}

.text-2LHX0.linked-24tsx ul {
	display: flex;
	flex-direction: column;
	align-items: center;

	padding-left: 0;
}

.text-2LHX0.linked-24tsx li {
	text-transform: capitalize;
}

@media screen and (max-width: 600px) {
	.title-2h74T {
		padding-top: 16px;
	}
}
