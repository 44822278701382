.flight-oFbHZ {
	min-height: 84px;

	border-radius: 12px;

	box-shadow: 4px 4px 33px rgba(0, 0, 0, 0.05);
	cursor: auto;
}

.flight-oFbHZ:not(:last-child) {
	margin-bottom: 12px;
}

.flightSegments-1nw-G {
	flex: 0 1 600px;
}

.segmentInfo-Hgcy7 {
	flex: 1;
	display: flex;
	flex-direction: column;
	justify-content: center;
	max-width: 22%;
	height: 100%;
	padding-right: 20px;

	border-right: 1px dashed var(--line-dot-line);
}

.segmentInfo__flightNumber-2RX52 {
	margin-bottom: 2px;

	color: var(--text-base);
	font-weight: 700;
	font-size: 14px;
	line-height: 1.3;
}

.segmentInfo__operating-12_-V {
	color: var(--text-light);
	font-size: 13px;
	font-weight: 400;
	line-height: 1.3;
}

div.segmentInfo__segment-2e7gy {
	padding-left: 20px;
}

div.segment__planeContainer-lsShw {
	flex: 1;
	max-width: 25%;
	align-items: flex-start;
	justify-content: center;

	border-right: 1px dashed var(--line-dot-line);
}

.segment__routeInfo-jkIuP {
	min-width: 50%;
	padding: 12px 20px;

	border-right: 1px dashed var(--line-dot-line);
}

.totalDuration-1KlkE {
	color: var(--error-text);
	font-size: 13px;
	font-weight: 400;
	line-height: 1.3;

	transform: translateY(100%);
}

.totalDuration__decorator-3ctor {
	border-right: 1px dashed var(--line-dot-line);
}

.routeArrow__container-32aNX {
	min-width: 100px;
	max-width: 120px;
	justify-content: center;
}

.routeArrow__flightNumber-2RwvQ:not(.routeArrow__stop-6FlES) {
	display: none;
}

.routeArrow__route-YoLGq:before {
	top: 50%;
	bottom: unset;

	transform: translateY(-50%);
}

.routeArrow__circle-GhotM {
	top: 0;
}

.routeArrow__timeInRoute-1DuZp {
	top: 0;

	font-size: 14px;
	font-weight: 500;
	line-height: 1.2;
}

.point__time-3MqGn {
	margin: 0;

	font-size: 24px;
	line-height: 1.3;
}

.point__dateContainer-q9Qza {
	justify-content: center;
}

.stop__label-3GQ2_ {
	color: var(--error-text);
	font-size: 13px;
	font-weight: 400;
	line-height: 1.3;
}

.planeInfo-36Fuk {
	display: flex;
	flex-direction: column;
	padding: 0 12px;
}

.aircrafts-265yT {
	margin-bottom: 2px;

	color: var(--text-light);
	font-weight: 400;
	font-size: 13px;
	line-height: 1.2;
}

.amenities-2P-7c {
	margin-left: 0;
}

.mobileSegment__aircraft-2yXaT {
	display: none;
}

.mobileFlight__flight-7yqMX:not(.mobileFlight__readonly-32Z0l, .mobileFlight__expanded-3MwnC) .mobileFlight__segments-anR6U {
	border-bottom: none;
}

.mobileFlight__flight-7yqMX.mobileFlight__expanded-3MwnC .mobileFlight__segments-anR6U {
	margin-bottom: 16px;
}

.mobileFlight__expand-1Ko_H {
	position: relative;

	margin-bottom: -14px;

	transform: none;
}

.companyName-3Cz_Y {
	margin-bottom: 4px;

	color: var(--text-light);
	font-size: 13px;
	line-height: 1.2;
	text-align: center;
}

@media (max-width: 1024px) {
	.flight-oFbHZ {
		flex-direction: column;
	}

	.flightSegments-1nw-G {
		flex: 1;

		border-bottom: 1px dashed var(--line-dot-line);
	}

	.segment__routeInfo-jkIuP {
		border-right: none;
	}

	div.stop-1eIDJ,
	div.totalDuration-1KlkE {
		bottom: -6px;
	}

	.point__date-1Wh7f {
		display: none;
	}
}

@media screen and (max-width: 600px) {
	.routeArrow__container-32aNX {
		justify-content: space-between;
	}
}
