

.stepbar-AFWmI {
	width: 100%;
	padding-bottom: 5px;

	background-color: var(--mobileStepbar-background);
}

div.stepbar__cartWrapper-8ysXn {
	max-width: 100%;
	min-width: auto;
}
