.input_wrapper-1rd44 {
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
}

.input-1vkWp {
	width: 100%;
	min-height: 48px;
	padding: 14px 12px;

	border: none;

	line-height: 1.2;

	background: var(--white);
}

.input-1vkWp:-ms-input-placeholder {
	color: var(--text-light);
}

.input-1vkWp::placeholder,
div.input-1vkWp {
	color: var(--text-light);
}

div.input-1vkWp {
	display: flex;
	align-items: center;
}

.clear-2-SgQ {
	position: absolute;

	right: 6px;

	display: flex;
	align-items: center;
	justify-content: center;
	width: 24px;
	height: 24px;
	padding: 2px;

	border-radius: 24px;

	color: var(--text-pale);

	background: var(--bg-white);
	cursor: pointer;
}

.clear-2-SgQ:hover {
	color: var(--text-pale);

	background: var(--bg-elements);
}

.clear-2-SgQ svg {
	width: 100%;
	height: 100%;
}

@media screen and (max-width: 600px) {
	.input-1vkWp {
		min-height: 68px;
	}

	.clear-2-SgQ {
		right: 18px;
	}
}
