.container-oM5kZ {
	display: flex;
	align-items: center;
	padding-top: 15px;
}

.inputs-2HBlu {
	display: flex;
	margin-right: 18px;
}

.action-3vja4 {
	color: #ffffff;
	font-size: 15px;
	font-weight: 500;

	cursor: pointer;
}

@media screen and (max-width: 600px) {
	.container-oM5kZ {
		flex-direction: column;
		align-items: flex-start;
	}

	.inputs-2HBlu {
		margin-right: 0;
		margin-bottom: 12px;
		width: 100%;
	}

	.inputs-2HBlu > div {
		flex: 1;
	}

	.inputs-2HBlu input {
		width: 100%;
		max-width: 100%;
		min-width: 0;
	}
}
