.wrapper-LfKp5 {
	width: 100%;
}

.image-3RPry {
	position: relative;

	height: 350px;

	border-radius: var(--baggage-additional-wrapped-border-radius) var(--baggage-additional-wrapped-border-radius) 0 0;

	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
}

.marker-WCm2V {
	position: absolute;
	top: 20px;
	left: 20px;

	padding: 4px 16px;

	border-radius: 100px;

	color: var(--white);
	font-size: 16px;
	line-height: 1.3;
	font-weight: 600;

	background: var(--error);
}

.row-I8gfv {
	display: flex;
	justify-content: space-between;
	align-items: center;
	grid-gap: 12px;
	gap: 12px;
}

.row-I8gfv:not(:first-of-type) {
	margin-top: 16px;
	padding-top: 16px;

	border-top: 1px dashed var(--line-dot-line);
}

.name-3-APp {
	color: var(--text-base);
	font: var(--medium-1-desktop-bold);
	text-transform: uppercase;
}

.weight-2KCuG {
	margin-left: 40px;

	color: var(--text-light);
	font: var(--medium--1-desktop);

	white-space: nowrap;
}

.content-j2xJn {
	display: flex;
	flex-direction: column;

	padding: 20px 32px 12px;

	background: var(--bg-fill);
}

.trigger-PCHRA {
	display: flex;
	align-items: center;

	color: var(--text-mid);
	font: var(--medium--1-desktop-bold);

	cursor: pointer;
}

.trigger__icon-Q7IOR {
	display: flex;
	align-items: center;

	margin-left: 4px;

	color: var(--text-pale);

	transition: transform 0.15s;
}

.trigger__icon_rotated-3YyvO {
	transform: rotate(180deg);
}

.priceWrapper-bueKG {
	display: flex;
	align-items: center;
}

.price-1KCWM {
	color: var(--text-base);
	font: var(--medium-1-desktop-bold);
}

.oldPrice-1DGOu {
	margin-right: 8px;

	color: var(--error-icon);
	font-weight: 600;
	font-size: 18px;
	line-height: 170%;
	text-decoration: line-through;
}

.description-2Rmc0 {
	display: flex;
	flex-direction: column;
	grid-gap: 16px;
	gap: 16px;

	margin-top: 16px;
	padding-top: 16px;

	border-top: 1px dashed var(--line-dot-line);

	color: var(--text-mid);
	font: var(--normal-desktop);
}

.description__row-JnuK- {
	display: flex;
	flex-direction: column;
}

.description__header-JQLoS {
	font: var(--medium--1-desktop-bold);
}

.selector-2hw13 {
	display: flex;
	flex-direction: column;
	grid-gap: 24px;
	gap: 24px;

	padding: 8px 32px 32px;
}

.counters-1K3b_ {
	display: flex;
	flex-direction: column;
}

.counter-3BPKH {
	display: flex;
	align-items: center;
	justify-content: space-between;

	padding: 16px 0;

	border-bottom: 1px dashed var(--line-separator);
}

.passenger-bVa84 {
	display: flex;
	align-items: center;
	grid-gap: 12px;
	gap: 12px;

	color: var(--text-mid);
	font: var(--medium--1-mobile);
}

.passenger__index-UC6-L {
	display: flex;
	align-items: center;
	justify-content: center;

	width: 24px;
	height: 24px;

	border-radius: 50%;

	color: var(--text-light);
	font: var(--normal-desktop-accent);

	background: var(--bg-fill);
}

/* stylelint-disable-next-line block-no-empty */
button.button-1hghT {
}

button.button_empty-34wnv {
	opacity: 0.6;
}

button.button_empty-34wnv:hover,
button.button_empty-34wnv:focus,
button.button_empty-34wnv:active {
	opacity: 1;
}

.footer-27aI_ {
	display: flex;
	justify-content: space-between;
	align-items: center;
	grid-gap: 20px;
	gap: 20px;
}

.footer__price-2zNwo {
	display: flex;
	align-items: center;
}

.footer__price_visible-1nogy {
	opacity: 1;
}

.totalPrice-2Bo4x {
	display: flex;
	align-items: baseline;
}

.total-3RUC_ {
	padding-left: 0;
}

.total-3RUC_,
.totalText-3b7z6 {
	color: var(--text-base);
	font: var(--medium-1-desktop-bold);
}

.clear-yFORl {
	margin-left: 12px;
	padding-left: 12px;

	border-left: 1px solid var(--gray10);

	color: var(--brand-1-0);
	font: var(--normal-desktop-accent);

	cursor: pointer;
}

.addForAll-1qZPB {
	margin-left: 0;
	padding-left: 0;

	border-left: none;
}

button.disabled-jnAU8 {
	opacity: 0.6;

	pointer-events: none;
}

@media screen and (max-width: 600px) {
	.wrapper-LfKp5 {
		min-height: 100%;
	}

	.image-3RPry {
		height: 250px;

		border-radius: 0;
	}

	.content-j2xJn {
		padding: 20px 20px 12px;
	}

	.selector-2hw13 {
		padding: 8px 20px 32px;
	}

	.counters-1K3b_ {
		max-height: 182px;
		overflow-y: auto;
	}
}

/* buttons wrap for small mobile screens */
@media (max-width: 424px) {
	.footer-27aI_ {
		grid-gap: 5px;
		gap: 5px;
	}

	.footer__price-2zNwo {
		display: inline;
		display: initial;
	}

	.totalPrice-2Bo4x {
		margin-bottom: 10px;
	}

	.clear-yFORl {
		margin-left: 0;
		padding-left: 0;

		border: medium none currentColor;

		border: initial;
	}
}

@media screen and (max-width: 320px) {
	.image-3RPry {
		height: 150px;
	}

	.name-3-APp {
		font-size: 18px;
	}
}
