

.content-1I-Fv {
	background: var(--bg-fill);
}

.payment-4PZAX {
	max-width: 840px;
	margin: 0 auto;
}

.paymentIcon_failed-kowgv {
	display: flex;
	justify-content: center;
	align-items: center;

	border-radius: 50%;

	color: var(--white);

	background: var(--error-icon);
}

.paymentIcon_failed-kowgv svg {
	width: 70%;
	height: 70%;
}
