

.wrapper-_E017 {
	display: flex;
	align-items: center;
}

.title-2ndzf {
	margin-right: 4px;

	color: var(--gray50);
	font-size: 14px;
	line-height: 14px;
}

button.button-16nRI {
	margin: 0 4px;

	border-color: #ffffff;
}

.filter_wrapper-20X0U {
	position: relative;
}

.input_wrapper-34r51 {
	position: absolute;
	bottom: -86px;
	left: 100%;
	z-index: 3;

	width: 360px;
	max-width: 360px;
	padding: 16px;

	border-radius: 8px;

	box-shadow: 0 0 24px rgba(0, 0, 0, 0.15);
	transform: translateX(-100%);
	background: #ffffff;
}

.button-16nRI.active-X2IWw,
.button-16nRI.active-X2IWw:hover {
	border-color: var(--base);

	color: #ffffff;

	background: var(--base);
}

div.text_field-1b74e > div:before,
div.text_field-1b74e > div:after {
	display: none;
}

div.adornment-2cd_x {
	margin-left: -16px;

	transform: translate(-10px, 1px);

	cursor: pointer;
}

.close-1_Uts svg {
	width: 17px;
	height: 17px;
}

button.input_trigger-30OBD {
	width: 130px;
	min-width: 130px;
	max-width: 150px;
}

.filter_text-15XpM {
	display: inline-block;
	overflow: hidden;

	text-overflow: ellipsis;
	white-space: nowrap;
}

button.with_input-2-PG4 {
	position: relative;

	padding-right: 25px;

	text-transform: uppercase;
}

.close-1_Uts.in_button-1Q7Fb {
	position: absolute;
	right: 5px;
	top: 50%;

	transform: translateY(-45%);
}

div.text_field-1b74e input {
	padding: 11px 30px 11px 11px;

	border: 1px solid var(--base);

	color: var(--gray80);
	font-weight: 500;
	font-size: 14px;
	text-transform: uppercase;

	background: var(--gray05);
}

div.text_field-1b74e input:-ms-input-placeholder {
	text-transform: none;
}

div.text_field-1b74e input::placeholder {
	text-transform: none;
}
