.container-2sEBr {
	display: flex;
	flex-direction: column;
	width: 100%;
	min-width: 120px;
	max-width: 150px;
	margin: 0 12px;
	justify-content: space-between;
	align-items: center;
}

.flightNumber-N4bOB {
	color: var(--base);
	font-weight: 700;
	font-size: 13px;
}

.flightNumber-N4bOB.stop-37pX5 {
	color: var(--error);
}

.route-_RtJs {
	position: relative;

	display: flex;
	flex: 0 1 0;
	justify-content: center;
	align-items: center;
	width: 100%;
}

.__value__mobile__0-2kCom .route-_RtJs {
	margin-top: 13px;
}

.circle-nedNw {
	position: absolute;
	top: 35px;
	bottom: 0;
	left: 0;
	z-index: 2;

	width: 10px;
	height: 10px;
	margin: auto;

	border: 1px solid var(--gray20);
	border-radius: 50%;

	background: #ffffff;
}

.circle_right-2Qxy5 {
	left: auto;
	right: 0;
}

.route-_RtJs:before {
	position: absolute;
	top: 35px;
	bottom: 0;
	z-index: 1;

	width: 100%;
	height: 1px;
	margin: auto;

	border-top: 1px solid var(--gray20);
	content: '';
}

.timeInRoute-1zO3U {
	z-index: 2;
	position: relative;
	top: 16px;

	padding: 0 5px;

	color: var(--text);
	font-weight: 600;
	font-size: 16px;
	line-height: 10px;

	background: var(--bg-white);
}

.__value__mobile__0-2kCom .timeInRoute-1zO3U {
	font-size: 14px;
	font-weight: 500;
}

.__value__mobile__0-2kCom.container-2sEBr {
	margin: 0 8px;
}

@media screen and (max-width: 600px) {
	.container-2sEBr {
		min-width: 80px;
	}
}

@media screen and (max-width: 320px) {
	.timeInRoute-1zO3U {
		font-size: 14px;
	}
}
